import fetch from 'isomorphic-fetch';

let ENDPOINT_URL = process.env.VUE_APP_ACTUALIZAR_ASISTENCIA;
async function actualizarAsistencia(asistencia) {
  const bodyParams = {
    doi: asistencia.doi.toString(),
    fecha_asistencia: asistencia.fecha_asistencia,
    tipo_jornada: asistencia.tipo_jornada,
    hora_programada: asistencia.hora_programada,
    hora_inicio_jornada: asistencia.hora_inicio_jornada,
    hora_fin_jornada: asistencia.hora_fin_jornada,
    hora_inicio_almuerzo: asistencia.hora_inicio_almuerzo,
    hora_fin_almuerzo: asistencia.hora_fin_almuerzo,
    latlng_inicio_jornada: asistencia.latlng_inicio_jornada,
    latlng_fin_jornada: asistencia.latlng_fin_jornada,
    jornadas: asistencia.jornadas,
    token: localStorage.getItem('token'),
  };

  let body = JSON.stringify(bodyParams);

  return await fetch(ENDPOINT_URL, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  }).then((item) => item.json());
}
export { actualizarAsistencia };
