<template>
  <v-container fluid>
    <v-row class="d-flex flex-column">
      <v-col class="top-bar-buttons-container">
        <v-row no-gutters row>
          <v-col>
            <v-btn-toggle
              v-model="toogle"
              color="teal darken-1"
              mandatory
              rounded
            >
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    @click="verAsistencia()"
                    v-on="on"
                    :disabled="
                      !validarAccesoPorRol([
                        ...rolesDelSistema.GESTOR_PERSONAL,
                        ...rolesDelSistema.GESTOR_OPERACIONES,
                        ...rolesDelSistema.GERENTE,
                      ])
                    "
                  >
                    <v-icon color="green">mdi-calendar-import</v-icon>
                  </v-btn>
                </template>
                <span>Registro de asistencia</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    @click="verDescansosMedicos()"
                    v-on="on"
                    :disabled="
                      !validarAccesoPorRol([
                        ...rolesDelSistema.GESTOR_PERSONAL,
                        ...rolesDelSistema.GESTOR_OPERACIONES,
                        ...rolesDelSistema.GERENTE,
                      ])
                    "
                  >
                    <v-icon color="blue">mdi-medical-bag</v-icon>
                  </v-btn>
                </template>
                <span>Descansos médicos</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    @click="verLicencias()"
                    v-on="on"
                    :disabled="
                      !validarAccesoPorRol([
                        ...rolesDelSistema.GESTOR_PERSONAL,
                        ...rolesDelSistema.GESTOR_OPERACIONES,
                        ...rolesDelSistema.GERENTE,
                      ])
                    "
                  >
                    <v-icon color="purple darken-2"
                      >mdi-calendar-clock-outline</v-icon
                    >
                  </v-btn>
                </template>
                <span>Licencias/Permisos</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    @click="verPrestamos()"
                    v-on="on"
                    :disabled="
                      !validarAccesoPorRol([
                        ...rolesDelSistema.GESTOR_CREDITOS,
                        ...rolesDelSistema.GESTOR_PERSONAL,
                        ...rolesDelSistema.GERENTE,
                      ])
                    "
                  >
                    <v-icon color="red lighten-1">mdi-cash-check</v-icon>
                  </v-btn>
                </template>
                <span>Créditos</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    @click="verSanciones()"
                    v-on="on"
                    :disabled="
                      !validarAccesoPorRol([
                        ...rolesDelSistema.GESTOR_PERSONAL,
                        ...rolesDelSistema.GESTOR_OPERACIONES,
                        ...rolesDelSistema.GERENTE,
                      ])
                    "
                  >
                    <v-icon color="teal"
                      >mdi-badge-account-alert-outline</v-icon
                    >
                  </v-btn>
                </template>
                <span>Sanciones/Amonestaciones</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    @click="verVacaciones()"
                    v-on="on"
                    :disabled="
                      !validarAccesoPorRol([
                        ...rolesDelSistema.GESTOR_PERSONAL,
                        ...rolesDelSistema.GESTOR_OPERACIONES,
                        ...rolesDelSistema.GERENTE,
                      ])
                    "
                  >
                    <v-icon color="orange">mdi-beach</v-icon>
                  </v-btn>
                </template>
                <span>Vacaciones</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    @click="exportarExcel('Registro')"
                    v-on="on"
                    :disabled="
                      !validarAccesoPorRol([
                        ...rolesDelSistema.GESTOR_PERSONAL,
                        ...rolesDelSistema.GESTOR_OPERACIONES,
                        ...rolesDelSistema.GERENTE,
                      ])
                    "
                  >
                    <v-icon color="green darken-4">mdi-file-excel</v-icon>
                  </v-btn>
                </template>
                <span>Exportar a Excel</span>
              </v-tooltip>
            </v-btn-toggle>
          </v-col>
          <v-spacer></v-spacer>
          <v-col>
            <v-dialog
              ref="dialog"
              v-model="modal"
              :return-value.sync="date"
              persistent
              width="290px"
              v-if="asistencia"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="dateText()"
                  label="Mes de asistencia"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                type="month"
                scrollable
                color="teal"
                locale="es-ES"
              >
                <v-spacer></v-spacer>
                <v-btn text color="blue" @click="modal = false">
                  Cancelar
                </v-btn>
                <!-- <v-btn text color="primary" @click="$refs.dialog.save(date)"> -->
                <v-btn
                  text
                  color="success"
                  @click="cambiarMesAsistencia(), $refs.dialog.save(date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <Asistencia v-if="asistencia" />
        <DescansoMedico v-if="descansoMedico" />
        <Licencia v-if="licencia" />
        <Prestamo v-if="prestamo" />
        <Sanciones v-if="sanciones" />
        <Vacaciones v-if="vacaciones" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { excelExport } from '@/plugins/excelExport.js';
import moment from 'moment';

import Asistencia from './gestion/Asistencia';
import DescansoMedico from './gestion/DescansoMedico';
import Licencia from './gestion/Licencia';
import Prestamo from './gestion/Prestamo';
import Sanciones from './gestion/Sanciones';
import Vacaciones from './gestion/Vacaciones';
import validarAccesoPorRol from '@/utils/validarAccesoPorRol.js';
import { mapState } from 'vuex';
import { getPersons } from '@/api/persons/getPersons.js';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';

export default {
  data: () => ({
    modal: false,
    date: moment().format('YYYY-MM'),
    toogle: 0,
    search: '',
    prestamo: false,
    asistencia: true,
    descansoMedico: false,
    licencia: false,
    vacaciones: false,
    sanciones: false,
  }),
  components: {
    Asistencia,
    DescansoMedico,
    Licencia,
    Prestamo,
    Sanciones,
    Vacaciones,
  },
  computed: {
    ...mapState(['rolesDelSistema', 'fechaAsistencia']),
  },
  mounted() {
    if (this.fechaAsistencia && Object.keys(this.fechaAsistencia).length > 0) {
      this.date = moment(this.fechaAsistencia).format('YYYY-MM');
    }
    this.obtenerPersonalCompleto();
  },
  methods: {
    async obtenerPersonalCompleto() {
      const { error, personal, tokenExpired } = await getPersons();
      if (personal) {
        this.personal = personal;
        this.personal = this.personal.map((persona) => {
          persona.nombre = `${persona.datos_laborales.apellido_paterno} ${persona.datos_laborales.apellido_materno}, ${persona.nombre}`;
          return persona;
        });

        //todo el personal
        this.$store.state.personalCompleto = [];
        this.$store.state.personalCompleto.push(...this.personal);

        //filtro aplicado al personal para solo obtener personal activo para ser utilizado en el proceso de planillas
        this.$store.state.personalActivo = this.personal.filter(
          (persona) => persona.datos_laborales.situacion !== 'Cesado'
        );

        //considerar al personal "Cesado" en el proceso de planilla de liquidación
        this.$store.state.personalCesado = this.personal.filter(
          (persona) => persona.datos_laborales.situacion === 'Cesado'
        );
      }
      if (error) {
        //do something
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
    },
    validarAccesoPorRol(rolesNecesarios) {
      const token = localStorage.getItem('token');
      return validarAccesoPorRol(token, rolesNecesarios);
    },
    cambiarMesAsistencia() {
      this.$store.state.fechaAsistencia = this.date;
    },
    dateText() {
      return moment(this.date)
        .format('MMMM-YYYY')
        .toUpperCase();
    },
    exportarExcel(services) {
      //services es el nombre del sheet wn excel
      excelExport(this.$store.state.tablaActivaGestionPersonal, services);
    },
    verAsistencia() {
      this.asistencia = true;
      this.descansoMedico = false;
      this.licencia = false;
      this.vacaciones = false;
      this.sanciones = false;
      this.prestamo = false;
    },
    verVacaciones() {
      this.asistencia = false;
      this.descansoMedico = false;
      this.licencia = false;
      this.vacaciones = true;
      this.sanciones = false;
      this.prestamo = false;
    },
    verSanciones() {
      this.asistencia = false;
      this.descansoMedico = false;
      this.licencia = false;
      this.vacaciones = false;
      this.sanciones = true;
      this.prestamo = false;
    },
    verPrestamos() {
      this.asistencia = false;
      this.descansoMedico = false;
      this.licencia = false;
      this.vacaciones = false;
      this.sanciones = false;
      this.prestamo = true;
    },
    verLicencias() {
      this.asistencia = false;
      this.descansoMedico = false;
      this.licencia = true;
      this.vacaciones = false;
      this.sanciones = false;
      this.prestamo = false;
    },
    verDescansosMedicos() {
      this.asistencia = false;
      this.descansoMedico = true;
      this.licencia = false;
      this.vacaciones = false;
      this.sanciones = false;
      this.prestamo = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/main.scss';
.search-right {
  text-align: end !important;
  max-width: 450px;
}
.top-bar-buttons-container {
  text-align: left !important;
}
.flex {
  display: flex;
  justify-content: space-between;
}
.nav-bar {
  height: 50px;
}
</style>
