var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"  absolute"},[_c('FormularioContactoSoporte'),_vm._l((_vm.modulesAdmin),function(item,i){return _c('div',{key:i},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",class:item.class,attrs:{"fab":"","large":"","elevation":_vm.elevation,"width":"70px","height":"70px"},on:{"click":function($event){return _vm.goToRoute(item)}}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"width":"30px","height":"30px","src":item.urlImg,"contain":""}})],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.msj))])])],1)})],2),_c('div',{attrs:{"id":"mapa"}},[_c('v-img',{attrs:{"height":"100vh","cover":"","src":require('@/assets/img/mapa.png')}})],1),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-end__session",on:{"click":_vm.endSession}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"width":"10px","src":require('@/assets/menu-icons/off.svg'),"contain":""}})],1)]}}])},[_c('span',[_vm._v("Cerrar Sesión")])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-support",on:{"click":_vm.contactarSoporte}},'v-btn',attrs,false),on),[_c('v-img',{attrs:{"width":"3.25vw","src":require('@/assets/menu-icons/support-icon.png')}})],1)]}}])},[_c('span',[_vm._v("Contactar con soporte técnico")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }