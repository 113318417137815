<template>
  <div>
    <div class="flex">
      <v-btn class="btn-new" @click="open = !open">Nuevo</v-btn>
      <div class="relative">
        <v-text-field v-model="inputSearch" />
        <v-btn>
          <v-img
            max-width="20px"
            cover
            :src="require('@/assets/menu-icons/search.svg')"
          />
        </v-btn>
      </div>
    </div>
    <v-divider horizontal />
    <div v-if="open">
      <nuevo />
      <v-btn @click="open = false" class="btn-close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <div class="container ">
      <v-card
        v-for="(item, i) in units"
        :key="i"
        @click="
          overlay = !overlay;
          handleEmit(item);
        "
        :class="[
          'info-unidades',
          item.disponible == true ? 'active' : 'inactive',
        ]"
      >
        <div v-if="item.tipo_unidad">
          <div v-for="(img, i) in unidades" :key="i">
            <v-img
              class="responsive-content"
              width="150px"
              v-if="item.tipo_unidad == img.title"
              :src="img.url"
            />
          </div>
        </div>
        <div>
          <v-card-title>
            {{ item.placa }}
          </v-card-title>
          <v-card-subtitle v-if="item.disponible == true">
            Disponible
          </v-card-subtitle>
          <v-card-subtitle v-else-if="item.disponible == false">
            No Disponible
          </v-card-subtitle>
        </div>
      </v-card>
    </div>
    <div v-if="overlay">
      <edit :info="item" />
      <v-btn @click="overlay = false" class="btn-close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
import { readUnits } from '@/api/flota/getUnits';
import Nuevo from './Nuevo';
import Edit from './Edit';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';

export default {
  data: () => ({
    units: '',
    data: '',
    inputSearch: '',
    open: false,
    overlay: false,
    unidades: [
      {
        url: require('@/assets/flota/moto.png'),
        title: 'Moto',
      },
      {
        url: require('@/assets/flota/minivan.png'),
        title: 'Minivan',
      },
      {
        url: require('@/assets/flota/bus.png'),
        title: 'Bus',
      },
      {
        url: require('@/assets/flota/semitrailer.png'),
        title: 'Semitrailer',
      },
      {
        url: require('@/assets/flota/camión.png'),
        title: 'Camión',
      },
    ],
  }),
  components: {
    Nuevo,
    Edit,
  },
  watch: {
    inputSearch() {
      this.search();
    },
  },
  created() {
    this.getUnits();
  },
  methods: {
    async getUnits() {
      const { error, flota, tokenExpired } = await readUnits();
      if (error) {
        //do something
      }
      if (flota) {
        this.data = flota;
        this.units = this.data;
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
    },
    handleEmit(item) {
      this.item = item;
    },
    search() {
      const word = this.inputSearch.toLowerCase();
      this.units = this.data;
      let newArray = [];
      for (let i in this.units) {
        if (this.units[i].placa.toLowerCase().includes(word)) {
          newArray.push(this.units[i]);
        }
      }
      this.units = newArray;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/main.scss';
.btn-new {
  margin-top: 12px;
  margin-bottom: 20px;
}
.btn-close {
  position: absolute;
  top: 100px;
  left: 30px;
  color: white !important;
  box-shadow: none !important;
  background-color: #1e1e1e !important;
  border-radius: 50%;
  min-width: 25px !important;
  padding: 10px !important;
  z-index: 6;
}
.flex {
  display: flex;
  height: 70px;
  justify-content: space-between;
}
.relative {
  .v-btn {
    right: -71px;
    top: -56px;
    position: relative;
    box-shadow: none !important;
    background-color: transparent !important;
  }
}
.responsive-content {
  top: 30px !important;
}
.container {
  height: 70vh;
  overflow: scroll;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.v-card__title {
  padding: 0px !important;
  margin: 20px;
}
.inactive {
  opacity: 0.7 !important;
  background-color: darkgray !important;
}
.info-unidades,
.active {
  margin: 15px;
  height: 250px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 200px;
}
.container::-webkit-scrollbar {
  width: 4px;
  background: #efeff6;
}

.container::-webkit-scrollbar-thumb {
  background: $main;
  border-radius: 10px;
}
</style>
