<template>
  <v-dialog
    v-model="verHorasLaboradasPorSemanaDialog"
    max-height="50vh"
    max-width="75vw"
    persistent
  >
    <v-card>
      <!-- encabezado -->
      <v-card-title
        class="text-h5 justify-center"
        style="color: teal; font-weight: bold"
      >
        <div v-html="obtenerNombre"></div>
      </v-card-title>
      <!-- numero de semana a visualizar -->
      <v-card-subtitle style="color: teal;" class="text-h5">
        {{ `${this.obtenerNroDeSemana}` }}
      </v-card-subtitle>
      <div class="scrollable-horizontal">
        <v-row class="no-wrap">
          <v-col
            v-for="(asistencia, index) in asistenciasARenderizar"
            :key="index"
            cols="2"
            class="custom-col"
          >
            <div class="negrita">
              {{ obtenerDiaAsistenciaText(asistencia) }}
            </div>

            <div class="negrita">
              {{ obtenerHorasLaboradasText(asistencia) }}
            </div>

            <!-- si es jornada intermitente -->
            <div
              v-if="asistencia.datos_laborales.switchSujetoAJornadaIntermitente"
            >
              <v-row
                v-for="(marcacion, index) in asistencia.jornadas"
                :key="index + marcacion.hora_inicio_jornada"
              >
                <!-- Columna izquierda para inicios de jornada -->
                <v-col>
                  <v-text-field
                    :label="`Inicio de jornada ${index + 1}`"
                    :value="marcacion.hora_inicio_jornada"
                    readonly
                  />
                </v-col>

                <!-- Columna derecha para fines de jornada -->
                <v-col>
                  <v-text-field
                    :label="`Fin de jornada ${index + 1}`"
                    :value="marcacion.hora_fin_jornada"
                    readonly
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </div>

            <!-- si no es jornada intermitente -->
            <div v-else>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Inicio de jornada"
                    :value="
                      asistencia.hora_inicio_jornada
                        ? asistencia.hora_inicio_jornada
                        : '-'
                    "
                    readonly
                  >
                  </v-text-field>
                  <v-text-field
                    label="Inicio de almuerzo"
                    :value="
                      asistencia.hora_inicio_almuerzo
                        ? asistencia.hora_inicio_almuerzo
                        : '-'
                    "
                    readonly
                  />
                </v-col>

                <v-col>
                  <v-text-field
                    label="Fin de jornada"
                    :value="
                      asistencia.hora_fin_jornada
                        ? asistencia.hora_fin_jornada
                        : '-'
                    "
                    readonly
                  >
                  </v-text-field>
                  <v-text-field
                    label="Fin de almuerzo"
                    :value="
                      asistencia.hora_fin_almuerzo
                        ? asistencia.hora_fin_almuerzo
                        : '-'
                    "
                    readonly
                  />
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </div>
      <!-- botón cancelar -->
      <v-card-actions class="justify-center">
        <v-btn color="blue darken-1" text @click="closeDialog">Cancelar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import { mapState } from 'vuex';

export default {
  name: 'VerDetalleHorasLaboradasPorSemana',
  data() {
    return {
      asistenciasARenderizar: [],
    };
  },
  props: {
    asistencia: Object,
    indiceSemana: Number,
  },
  computed: {
    ...mapState([
      'verHorasLaboradasPorSemanaDialog',
      'reporteHorasLaboradasFecha',
    ]),
    obtenerNombre() {
      if (this.asistencia.nombre)
        return `Horas laboradas ${this.asistencia.fechaAsistencia} <br />
        ${this.asistencia.nombre.toUpperCase()}`;
      return '';
    },
    obtenerNroDeSemana() {
      return `Semana ${this.indiceSemana + 1}`;
    },
  },
  methods: {
    closeDialog() {
      this.$store.state.verHorasLaboradasPorSemanaDialog = false;
    },
    obtenerHorasLaboradasText(asistencia) {
      if (asistencia.cantidadMinutosLaborados === 0) return '0';

      let hours = Math.floor(asistencia.cantidadMinutosLaborados / 60);
      let mins = asistencia.cantidadMinutosLaborados % 60;

      // Asegurarse de que los minutos sean presentados siempre con dos digitos
      let formattedMins = mins < 10 ? '0' + mins : '' + mins;

      return `${hours}:${formattedMins} horas laboradas`;
    },
    obtenerDiaAsistenciaText(asistencia) {
      if (asistencia.fechaAsistencia) {
        let fecha = moment(asistencia.fechaAsistencia);

        let nombreDia = fecha
          .tz('America/Lima')
          .locale('es')
          .format('dddd');
        let numeroDia = fecha
          .tz('America/Lima')
          .locale('es')
          .format('D');
        return `${_.capitalize(nombreDia)} ${numeroDia}`;
      }
      return '';
    },
  },
  watch: {
    asistencia() {
      this.asistenciasARenderizar = this.asistencia[
        `semana${this.indiceSemana}`
      ].jornadasPorDias;
    },
    indiceSemana() {
      this.asistenciasARenderizar = this.asistencia[
        `semana${this.indiceSemana}`
      ].jornadasPorDias;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/main.scss';
.scrollable-horizontal {
  padding: 10px;
  height: 500px;
  overflow-x: auto;
}
.no-wrap {
  flex-wrap: nowrap;
}
.negrita {
  font-weight: bolder !important;
}
.custom-col {
  flex: 0 0 500px !important;
}
</style>
