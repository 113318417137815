<template>
  <v-data-table
    :headers="headers"
    :items="feriados"
    class="elevation-1"
    no-data-text="No hay feriados registrados aún..."
    fixed-header
    height="65vh"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-toolbar-title class="top-bar-title">Feriados</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn @click="agregarFeriado" v-on="on">
              <v-icon color="blue">mdi-calendar-plus</v-icon>
            </v-btn>
          </template>
          <span>Agregar feriado</span>
        </v-tooltip>
        <v-spacer></v-spacer>

        <v-dialog
          v-model="datePickerDialog"
          max-width="400px"
          max-height="400px"
        >
          <v-date-picker v-model="date" scrollable color="teal" locale="es-ES">
            <v-row class="d-flex flex-column">
              <v-col>
                <v-textarea
                  v-model="observaciones"
                  outlined
                  auto-grow
                  label="Observaciones"
                  clearable
                  clear-icon="mdi-close-circle"
                ></v-textarea>
              </v-col>
              <v-spacer></v-spacer>
              <v-col>
                <v-row class="justify-btn">
                  <v-btn text color="blue" @click="datePickerDialog = false">
                    Cancelar
                  </v-btn>
                  <v-btn
                    text
                    color="success"
                    @click="save(), (datePickerDialog = false)"
                  >
                    OK
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-date-picker>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon color="red" class="ml-2" @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>
<script>
import { mapState } from 'vuex';

import { updateClient } from '@/api/configuration/updateClient.js';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';

export default {
  name: 'Feriados',
  data: () => ({
    observaciones: '',
    dialog: false,
    headers: [
      {
        text: 'Fecha (AA-MM-DD)',
        value: 'fecha',
      },
      { text: 'Observaciones', value: 'observaciones' },
      { text: 'Acciones', value: 'actions' },
    ],
    feriados: [],
    editedItem: {
      fecha: '',
      observaciones: 0,
    },
    defaultItem: {
      fecha: '',
      observaciones: 0,
    },
    date: '',
    datePickerDialog: false,
  }),

  computed: {
    // formTitle() {
    //   return this.editedItem.fecha === '' ? 'Nuevo feriado' : 'Editar feriado';
    // },
    ...mapState(['cliente']),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    agregarFeriado() {
      //abrir datepicker para seleccionar el día feriado
      this.observaciones = '';
      this.date = '';
      this.datePickerDialog = true;
    },
    async updateClient() {
      const { error, tokenExpired, message } = await updateClient(this.cliente);
      if (error) {
        //do something
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (message) {
        this.showSuccessAlert();
      }
    },
    initialize() {
      this.feriados =
        !!this.cliente.datos_empresa.feriados &&
        this.cliente.datos_empresa.feriados.length > 0
          ? this.cliente.datos_empresa.feriados
          : [];
    },

    deleteItem(item) {
      this.feriados = this.feriados.filter(
        (feriado) => feriado.fecha !== item.fecha
      );

      this.cliente.datos_empresa.feriados = this.feriados;
      this.updateClient();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.observaciones = '';
        this.date = '';
      });
    },

    save() {
      this.feriados.push({
        fecha: this.date,
        observaciones: this.observaciones,
      });

      this.cliente.datos_empresa.feriados = this.feriados;
      this.updateClient();
      this.close();
    },
    showSuccessAlert() {
      this.$swal.fire({
        title: `Operación realizada correctamente!`,
        text: '',
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/main.scss';

.justify-btn {
  display: block !important;
  justify-content: space-around;
}
</style>
