<template>
  <v-row justify="center">
    <v-dialog
      v-model="selectorPlanillasDialog"
      height="500px"
      width="1000px"
      hide-overlay
      transition="dialog-bottom-transition"
      persistent
      :id="tipoReportePlanilla"
    >
      <v-card>
        <v-toolbar dark color="teal">
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Seleccione la planilla</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-toolbar-items>
            <v-btn dark text @click="seleccionarPersonasAlert">
              Guardar
              <v-icon>mdi-check-bold</v-icon>
            </v-btn>
          </v-toolbar-items> -->
        </v-toolbar>
        <v-data-table
          no-results-text="No se encontraron registros..."
          id="tablePlanillas"
          :headers="headers"
          height="500px"
          fixed-header
          :loading="tableLoading"
          loading-text="Cargando planillas... Espere..."
          :items="planillas"
          class="elevation-1"
          :search="search"
          hide-default-footer
          disable-pagination
          no-data-text="No hay planillas aún..."
          item-key="_id"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-row>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon
                    color="blue"
                    class="ml-2"
                    @click="verPlanilla(item)"
                    v-on="on"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <span>Ver planilla</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon
                    color="red"
                    class="ml-2"
                    @click="eliminarPlanillaAlert(item)"
                    v-on="on"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Eliminar planilla</span>
              </v-tooltip>
            </v-row>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import moment from 'moment';
import { mapState } from 'vuex';

import { eliminarPlanilla } from '@/api/payments/eliminarPlanilla.js';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';
import { editarCreditos } from '@/api/persons/editUserInfo.js';

export default {
  name: 'selectorPlanillasDialog',
  data() {
    return {
      search: '',
      tableLoading: true,
      headers: [
        {
          text: 'Periodo',
          value: 'periodo',
        },
        {
          text: 'Tipo de proceso',
          value: 'tipo_proceso',
          width: '275px',
        },
        {
          text: 'Fecha de inicio',
          value: 'fecha_inicio',
        },

        {
          text: 'Fecha de fin',
          value: 'fecha_fin',
        },
        {
          text: 'Periodicidad',
          value: 'periodicidad',
        },
        {
          text: 'Acciones',
          value: 'actions',
        },
      ],
    };
  },
  computed: {
    ...mapState([
      'selectorPlanillasDialog',
      'tipoReportePlanilla',
      'planillas',
    ]),
  },
  props: {
    // planillas: Array,
  },
  methods: {
    verPlanilla(planilla) {
      //se debe colocar la planilla seleccionada en un objeto del store y abrirlo desde TablaReportePlanilla
      this.$store.state.planillaSeleccionada = planilla;
      this.closeDialog();
    },
    traducirMes(mes) {
      const meses = {
        Enero: 'January',
        Febrero: 'February',
        Marzo: 'March',
        Abril: 'April',
        Mayo: 'May',
        Junio: 'June',
        Julio: 'July',
        Agosto: 'August',
        Septiembre: 'September',
        Octubre: 'October',
        Noviembre: 'November',
        Diciembre: 'December',
      };
      return meses[mes];
    },
    eliminarPlanillaAlert(planilla) {
      this.$swal
        .fire({
          title: `Atención!`,
          text: `Está seguro que desea eliminar esta planilla?`,
          icon: 'warning',
          showCancelButton: true,
          cancelButtonColor: '#d33',
          cancelButtonText: 'No',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Sí',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.eliminarPlanilla(planilla);
          }
        });
    },
    async eliminarPlanilla(planilla) {
      let mesAño = planilla.periodo.split('-');
      let mesIngles = this.traducirMes(mesAño[0]);

      let fechaPlanilla = moment(
        `01-${mesIngles}-${mesAño[1]}`,
        'DD-MMMM-YYYY'
      ).endOf('month');
      let fechaPlanillaFormat = fechaPlanilla.format('YYYY-MM-DD');

      planilla.personal.map(async (persona) => {
        let actualizarCreditos = false;
        if (persona.creditos && persona.creditos.length > 0) {
          persona.creditos.map((credito) => {
            let fechaCreditoFormat = `${credito.periodo_inicio_descuento}-01`;

            let fechaCredito = moment(fechaCreditoFormat, 'YYYY-MM-DD');
            let mesesDiferencia =
              fechaPlanilla.diff(fechaCredito, 'months') + 1;

            // si el mismo periodo y el tipo de planilla a descontar es igual al tipo actual
            // y si hay cuotas suficientes para descontar
            if (
              moment(fechaPlanillaFormat, 'YYYY-MM-DD').isSameOrAfter(
                fechaCredito
              ) &&
              credito.planilla_descuento_cuota.find(
                (planillaQueDescuenta) =>
                  planillaQueDescuenta === planilla.tipo_proceso
              ) &&
              mesesDiferencia <= Number(credito.total_cuotas)
            ) {
              credito.pagado = 'No';
              credito.cuotas_pagadas =
                Number(credito.cuotas_pagadas) - 1 < 0
                  ? 0
                  : Number(credito.cuotas_pagadas) - 1;
              actualizarCreditos = true;
            }
            return credito;
          });
        }
        if (actualizarCreditos) {
          let creditosAEditar = {
            _id: persona._id,
            creditos: persona.creditos,
          };
          await editarCreditos(creditosAEditar);
        }
        return persona;
      });
      const { error, tokenExpired, message } = await eliminarPlanilla(planilla);
      if (error) {
        //do something
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (message) {
        this.showSuccessAlert();
        this.closeDialog();
      }
    },
    closeDialog() {
      this.$store.state.selectorPlanillasDialog = false;
    },

    showSuccessAlert() {
      this.$swal.fire({
        title: `Operación realizada correctamente!`,
        text: '',
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
  },
  mounted() {
    if (this.planillas.length > 0) {
      this.planillas.map((planilla) => {
        planilla.fecha_inicio = moment(planilla.fecha_inicio).format(
          'DD-MM-YYYY'
        );
        planilla.fecha_fin = moment(planilla.fecha_fin).format('DD-MM-YYYY');
        return planilla;
      });
      this.tableLoading = false;
    }
  },
};
</script>
<style lang="scss" scoped>
@import 'src/styles/main.scss';
.v-data-table::v-deep th {
  font-size: 16px !important;
  font-weight: bold !important;
  color: teal !important;
}
#tablePlanillas {
  overflow-y: auto;
  overflow-x: auto;
  width: 100%;
  margin: auto;
  td,
  th {
    border: 1px solid rgba(97, 92, 92, 0.253);
  }
  tr:nth-child(even) {
    cursor: pointer;
    background-color: $secondary;
  }
  th {
    height: 30px;
    text-align: justify;
  }
  td {
    height: 30px;
    padding: 0px 10px;
    text-align: justify;
  }
}
#tablePlanillas::-webkit-scrollbar {
  height: 10px;
  width: 4px;
  background: $main;
}
#tablePlanillas::-webkit-scrollbar-thumb {
  background: #313d35;
  border-radius: 10px;
}
</style>
