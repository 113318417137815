import moment from 'moment';

const obtenerVacacionesCumplidasYProgramadas = (persona) => {
  //se buscarán las vacaciones que tienen el estado aprobado = 'si' para obtener el cálculo
  let vacacionesCumplidasYProgramadas = 0;

  let vacacionesAprobadas = persona.vacaciones.filter(
    (vacaciones) => vacaciones.aprobado === 'Sí'
  );

  for (let item in vacacionesAprobadas) {
    let cantidadDias = moment(vacacionesAprobadas[item].fecha_fin).diff(
      moment(vacacionesAprobadas[item].fecha_inicio),
      'days'
    );

    vacacionesCumplidasYProgramadas =
      vacacionesCumplidasYProgramadas + cantidadDias + 1;
  }

  persona.vacacionesCumplidasYProgramadas = vacacionesCumplidasYProgramadas;
  return persona;
};

export default obtenerVacacionesCumplidasYProgramadas;
