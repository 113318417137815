<template>
  <div>
    <h2 style="color: teal">
      Actualmente cuenta con {{ personalActivo.length }} usuarios activos y
      {{ personalCesado.length }} cesados
    </h2>
    <KickOffPlans :nuevoCliente="false" :v-if="showKickOffPlans" />
  </div>
</template>
<script>
import { mapState } from 'vuex';

import KickOffPlans from '@/components/landingPage/plans/KickOffPlans';
// import KickOffPlans from '@/components/landing/container/plans/KickOffPlans';
import { getPersons } from '@/api/persons/getPersons.js';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';

export default {
  name: 'Suscripcion',
  components: {
    KickOffPlans,
  },
  computed: {
    ...mapState(['showKickOffPlans', 'personalActivo', 'personalCesado']),
  },
  mounted() {
    this.$store.state.nuevoRegistro = false;
    this.$store.state.showKickOffPlans = true;
    this.obtenerPersonalCompleto();
  },
  methods: {
    async obtenerPersonalCompleto() {
      const { error, personal, tokenExpired } = await getPersons();
      if (personal) {
        // this.personal = personal;
        personal.map((persona) => {
          persona.nombre = `${persona.datos_laborales.apellido_paterno} ${persona.datos_laborales.apellido_materno}, ${persona.nombre}`;
          return persona;
        });

        //todo el personal
        this.$store.state.personalCompleto = [];
        this.$store.state.personalCompleto.push(...personal);

        //filtro aplicado al personal para solo obtener personal activo para ser utilizado en el proceso de planillas
        this.$store.state.personalActivo = personal.filter(
          (persona) => persona.datos_laborales.situacion !== 'Cesado'
        );

        //considerar al personal "Cesado" en el proceso de planilla de liquidación
        this.$store.state.personalCesado = personal.filter(
          (persona) => persona.datos_laborales.situacion === 'Cesado'
        );
      }
      if (error) {
        //do something
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
    },
  },
};
</script>
