var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex flex-column"},[_c('v-col',{staticClass:"top-bar-buttons-container"},[_c('v-row',{attrs:{"no-gutters":"","row":""}},[_c('v-col',[_c('v-btn-toggle',{attrs:{"color":"teal darken-1","mandatory":"","rounded":""},model:{value:(_vm.toogle),callback:function ($$v) {_vm.toogle=$$v},expression:"toogle"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":!_vm.validarAccesoPorRol(_vm.rolesDelSistema.GESTOR_PERSONAL.concat( _vm.rolesDelSistema.GESTOR_OPERACIONES,
                      _vm.rolesDelSistema.GERENTE ))},on:{"click":function($event){return _vm.verAsistencia()}}},on),[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-calendar-import")])],1)]}}])},[_c('span',[_vm._v("Registro de asistencia")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":!_vm.validarAccesoPorRol(_vm.rolesDelSistema.GESTOR_PERSONAL.concat( _vm.rolesDelSistema.GESTOR_OPERACIONES,
                      _vm.rolesDelSistema.GERENTE ))},on:{"click":function($event){return _vm.verDescansosMedicos()}}},on),[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-medical-bag")])],1)]}}])},[_c('span',[_vm._v("Descansos médicos")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":!_vm.validarAccesoPorRol(_vm.rolesDelSistema.GESTOR_PERSONAL.concat( _vm.rolesDelSistema.GESTOR_OPERACIONES,
                      _vm.rolesDelSistema.GERENTE ))},on:{"click":function($event){return _vm.verLicencias()}}},on),[_c('v-icon',{attrs:{"color":"purple darken-2"}},[_vm._v("mdi-calendar-clock-outline")])],1)]}}])},[_c('span',[_vm._v("Licencias/Permisos")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":!_vm.validarAccesoPorRol(_vm.rolesDelSistema.GESTOR_CREDITOS.concat( _vm.rolesDelSistema.GESTOR_PERSONAL,
                      _vm.rolesDelSistema.GERENTE ))},on:{"click":function($event){return _vm.verPrestamos()}}},on),[_c('v-icon',{attrs:{"color":"red lighten-1"}},[_vm._v("mdi-cash-check")])],1)]}}])},[_c('span',[_vm._v("Créditos")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":!_vm.validarAccesoPorRol(_vm.rolesDelSistema.GESTOR_PERSONAL.concat( _vm.rolesDelSistema.GESTOR_OPERACIONES,
                      _vm.rolesDelSistema.GERENTE ))},on:{"click":function($event){return _vm.verSanciones()}}},on),[_c('v-icon',{attrs:{"color":"teal"}},[_vm._v("mdi-badge-account-alert-outline")])],1)]}}])},[_c('span',[_vm._v("Sanciones/Amonestaciones")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":!_vm.validarAccesoPorRol(_vm.rolesDelSistema.GESTOR_PERSONAL.concat( _vm.rolesDelSistema.GESTOR_OPERACIONES,
                      _vm.rolesDelSistema.GERENTE ))},on:{"click":function($event){return _vm.verVacaciones()}}},on),[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-beach")])],1)]}}])},[_c('span',[_vm._v("Vacaciones")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"disabled":!_vm.validarAccesoPorRol(_vm.rolesDelSistema.GESTOR_PERSONAL.concat( _vm.rolesDelSistema.GESTOR_OPERACIONES,
                      _vm.rolesDelSistema.GERENTE ))},on:{"click":function($event){return _vm.exportarExcel('Registro')}}},on),[_c('v-icon',{attrs:{"color":"green darken-4"}},[_vm._v("mdi-file-excel")])],1)]}}])},[_c('span',[_vm._v("Exportar a Excel")])])],1)],1),_c('v-spacer'),_c('v-col',[(_vm.asistencia)?_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.date,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.dateText(),"label":"Mes de asistencia","prepend-icon":"mdi-calendar","readonly":""}},'v-text-field',attrs,false),on))]}}],null,false,2959967721),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"type":"month","scrollable":"","color":"teal","locale":"es-ES"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"blue"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":"","color":"success"},on:{"click":function($event){_vm.cambiarMesAsistencia(), _vm.$refs.dialog.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1):_vm._e()],1)],1)],1),_c('v-col',[(_vm.asistencia)?_c('Asistencia'):_vm._e(),(_vm.descansoMedico)?_c('DescansoMedico'):_vm._e(),(_vm.licencia)?_c('Licencia'):_vm._e(),(_vm.prestamo)?_c('Prestamo'):_vm._e(),(_vm.sanciones)?_c('Sanciones'):_vm._e(),(_vm.vacaciones)?_c('Vacaciones'):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }