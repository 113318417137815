<template>
  <v-container fluid class="pa-0 ma-0">
    <v-row class="main-row">
      <v-col class="left-section">
        <v-row class="d-flex flex-column" no-gutters>
          <v-col class="index-1">
            <router-link to="/dashboard" class="index-1">
              <v-btn @click="exitRoutesModule()">
                <v-img width="20px" src="@/assets/menu-icons/home.svg" />
              </v-btn>
            </router-link>
          </v-col>

          <v-col class="index-1" v-if="!open">
            <v-btn @click="open = !open">
              <v-img src="@/assets/menu-icons/next.svg" />
            </v-btn>
          </v-col>

          <v-col v-else class="index-1">
            <v-row no-gutters>
              <v-col>
                <GestionRoutes />
              </v-col>
              <v-col class="col-button">
                <v-btn @click="(open = false), cleanMap()" class="btn-close">
                  <v-img src="@/assets/menu-icons/next.svg" />
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>

      <div id="mapa"></div>

      <v-col class=" right-section">
        <v-row class="d-flex flex-column" no-gutters>
          <v-col class="index-1" v-if="!slides">
            <v-btn @click="slides = !slides" class="btn-rotate">
              <v-img src="@/assets/menu-icons/next.svg" />
            </v-btn>
          </v-col>

          <v-col v-else class="index-1">
            <v-row no-gutters>
              <v-col class="col-button">
                <v-btn @click="slides = false">
                  <v-img src="@/assets/menu-icons/next.svg" />
                </v-btn>
              </v-col>

              <v-col>
                <IndexSlides />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- <v-col v-if="open">
      <GestionRoutes />
      <v-btn @click="open = false" class="btn-close border-left">
        <v-img src="@/assets/menu-icons/next.svg" />
      </v-btn>
    </v-col> -->
  </v-container>
</template>
<script>
import { mapState } from 'vuex';
import GestionRoutes from './GestionRoute';
import IndexSlides from './IndexSlides';
import gmapsInit from '@/plugins/gmaps.js';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';
import obtenerRolesDelToken from '@/utils/obtenerRolesDelToken.js';

export default {
  data: () => ({
    open: false,
    slides: false,
    right: false,
    bottom: false,
  }),
  computed: {
    ...mapState([
      'googleMap',
      'directionsRenderer',
      'usuarioLogueado',
      'marcadoresEnMapa',
      'temporizadorMostrarGPSDeRuta',
    ]),
  },
  components: {
    GestionRoutes,
    IndexSlides,
  },
  async mounted() {
    //se detiene el timer para renderizar la ruta en mapa y se borran los marcadores del mapa (en caso se haya quedado abierto el sistema)
    this.detenerTemporizadorGPSDeRuta();
    this.eliminarMarcadoresEnMapa();

    const token = localStorage.getItem('token');
    if (!token) {
      routeToLoginIfTokenIsExpired(this);
      return;
    }
    if (!this.usuarioLogueado || !this.usuarioLogueado._id) {
      this.$store.state.usuarioLogueado = obtenerRolesDelToken(token);
      return;
    }
    try {
      this.$store.state.googleMap = await gmapsInit();
      let directionsService = new this.googleMap.maps.DirectionsService();
      let directionsRenderer = new this.googleMap.maps.DirectionsRenderer();
      let map = new this.googleMap.maps.Map(document.getElementById('mapa'), {
        disableDefaultUI: true, // cuando está en false muestra más botones de acción como street view por ejemplo
        center: {
          lat: -12.04318,
          lng: -77.02824,
        },
        zoom: 14,
      });
      directionsRenderer.setMap(map);
      this.$store.state.mapaActual = map;
      this.$store.state.directionsRenderer = directionsRenderer;
      this.$store.state.directionsService = directionsService;
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    detenerTemporizadorGPSDeRuta() {
      // clearTimeout(this.$store.state.temporizadorMostrarGPSDeRuta);
      this.$store.state.temporizadorMostrarGPSDeRuta.map((timer) => {
        clearTimeout(timer);
        return timer;
      });
    },
    eliminarMarcadoresEnMapa() {
      this.$store.state.marcadoresEnMapa.map((marcador) => {
        marcador.setMap(null);
        return marcador;
      });
    },
    cleanMap() {
      if (!this.open) {
        this.directionsRenderer.setMap(null);
        this.detenerTemporizadorGPSDeRuta();
        this.eliminarMarcadoresEnMapa();
      }
    },
    exitRoutesModule() {
      this.detenerTemporizadorGPSDeRuta();
      this.eliminarMarcadoresEnMapa();
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'src/styles/main.scss';
.col-button {
  max-width: 2vw;
}
.main-row {
  justify-content: space-between !important;
}
.left-section {
  text-align: initial !important;
  max-width: 27vw;
}
.right-section {
  margin-top: 30px;
  text-align: end !important;
  max-width: 27vw;
}
#mapa {
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  .gmnoprint {
    display: none !important;
  }
}
.absolute {
  max-height: 100vh;
  position: absolute;
  z-index: 1;
}
.index-1 {
  // max-height: 100vh;
  // max-width: 30vw;
  z-index: 1;
}
.theme--light {
  background-color: $secondary !important;
}
// button.btn-right.v-btn:not(.v-btn--round).v-size--default {
//   position: absolute;
//   top: 0px;
//   right: 0px;
//   .v-image {
//     transform: rotate(180deg);
//   }
// }
.btn-close {
  .v-image {
    transform: rotate(180deg);
  }
}
.btn-rotate {
  .v-image {
    transform: rotate(180deg);
  }
}
button.v-btn:not(.v-btn--round).v-size--default {
  background-color: $secondary;
  width: 30px;
  min-width: 30px;
}
.col {
  .row {
    .v-btn:nth-child(1) {
      margin-bottom: 2px;
    }
    button {
      .resize {
        width: 20px;
      }
    }
  }
}
.v-image {
  margin: 5px;
}
.flex {
  display: flex;
}
.under {
  bottom: 0px;
}
// .bottom {
//   margin: auto;
//   bottom: 0px;
//   left: 45%;
//   right: 45%;
//   width: fit-content !important;
//   .v-image {
//     transform: rotate(270deg);
//   }
// }
.custom {
  flex-direction: row-reverse;
  top: 0px;
  right: 0px;
}
.reverse {
  flex-direction: column-reverse;
}
.convert-arrow {
  width: fit-content !important;
  margin: auto;
  .v-image {
    transform: rotate(90deg);
  }
}
.mt-50 {
  margin-top: 50px;
}
// .border-right {
//   border-radius: 5px 0px 0px 5px;
// }
.border-left {
  border-radius: 0px 5px 5px 0px;
}
</style>
