<template>
  <v-tabs vertical>
    <v-tab>
      Admin
    </v-tab>
    <!-- <v-tab>
      Usuarios
    </v-tab> -->
    <v-tab>
      Sanciones
    </v-tab>
    <v-tab>
      Suscripción
    </v-tab>
    <div class="btn-group">
      <router-link to="/dashboard">
        <v-btn>
          <v-img
            max-width="20px"
            cover
            :src="require('@/assets/menu-icons/home.svg')"
          />
          Inicio
        </v-btn>
      </router-link>
      <router-link to="/">
        <v-btn>
          <v-img
            max-width="20px"
            cover
            :src="require('@/assets/menu-icons/endsession.svg')"
          />
          Salir
        </v-btn>
      </router-link>
    </div>
    <v-tab-item class="m-20">
      <v-card flat>
        <Negocio />
      </v-card>
    </v-tab-item>
    <!-- <v-tab-item class="m-20">
      <v-card flat>
        <Usuario />
      </v-card>
    </v-tab-item> -->
    <v-tab-item class="m-20">
      <v-card flat>
        <PlantillaSanciones />
      </v-card>
    </v-tab-item>
    <v-tab-item class="m-20">
      <v-card flat>
        <Suscripcion />
      </v-card>
    </v-tab-item>
  </v-tabs>
</template>
<script>
import Negocio from './Negocio';
import PlantillaSanciones from './PlantillaSanciones';
import Suscripcion from './Suscripcion';
// import Usuario from './Usuario';
export default {
  components: {
    Negocio,
    PlantillaSanciones,
    Suscripcion,
    // Usuario,
  },
};
</script>
<style lang="scss" scoped>
@import 'src/styles/main.scss';
.v-tabs {
  margin-top: 20px;
  margin-left: 45px;
}
.v-tab--active {
  background-color: $main;
  color: #fff !important;
}
a {
  text-decoration: none !important;
  color: $main !important;
}
.btn-group {
  justify-content: space-around;
  flex-direction: column;
  display: flex;
  position: absolute;
  bottom: 50px;
  .v-btn {
    background-color: transparent;
    box-shadow: none;
    display: flex;
    justify-content: flex-start;
  }
  .v-image {
    margin-right: 10px;
  }
}

.m-20 {
  right: 24px;
  height: 92vh;
  background: #fff;
  padding: 10px 20px;
  border-radius: 0px 5px 5px 5px;
  border: 3px solid $main;
  overflow: scroll;
}
.m-20::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background: #efeff6;
}

.m-20::-webkit-scrollbar-thumb {
  background: $main;
  border-radius: 10px;
}

@media (max-width: 735px) {
  .m-20 {
    width: 70vw;
    margin: 40px 0px 5px;
  }
}
</style>
