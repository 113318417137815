<template>
  <v-container v-if="renderEditRoutes" :key="containerKey">
    <v-row class="d-flex flex-column">
      <v-col v-for="(ruta, i) in this.programacionDeRutas.rutas" :key="i">
        <v-col>
          <v-row>
            <div :class="`new_route ${!rutaInactiva || ''}`">
              <v-checkbox v-model="val" :value="ruta" />
              <!-- <v-checkbox
                :disabled="rutaInactiva"
                v-model="val"
                :value="ruta"
              /> -->
              <v-text-field
                :disabled="!val || ruta.codigo_ruta !== val.codigo_ruta"
                readonly
                label="Ruta:"
                v-model="ruta.codigo_ruta"
              />
              <v-text-field
                :disabled="!val || ruta.codigo_ruta !== val.codigo_ruta"
                label="Hora inicio:"
                v-model="ruta.hora_programada"
                type="time"
              />
              <div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      :disabled="!val || ruta.codigo_ruta !== val.codigo_ruta"
                      v-on="on"
                      fab
                      x-small
                      @click="editarRuta(ruta)"
                      color="cyan"
                      >mdi-pencil</v-icon
                    >
                  </template>
                  <span>Editar</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      v-on="on"
                      fab
                      x-small
                      @click="showDeleteRouteAlert(ruta.codigo_ruta)"
                      color="red"
                      >mdi-delete</v-icon
                    >
                  </template>
                  <span>Eliminar</span>
                </v-tooltip>
              </div>
            </div>
          </v-row>
        </v-col>

        <v-col>
          <!-- <div
            class="m_20"
            v-if="ruta.codigo_ruta === codigoRutaSeleccionada ? true : false"
          > -->
          <!-- <v-expand-transition> -->
          <v-card
            v-show="
              !!val && ruta.codigo_ruta === val.codigo_ruta ? true : false
            "
            class="mx-auto "
          >
            <!-- <v-card
            v-show="
              ruta.codigo_ruta === codigoRutaSeleccionada && ruta.codigo_ruta !== ''
                ? true
                : false
            "
            class="mx-auto "
          > -->
            <v-card-text>
              <!-- Punto de origen -->
              <v-row>
                <v-col>
                  <v-row class="justify-space-between">
                    <h4 class="d-flex pa-2">Punto de origen:</h4>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-on="on" v-bind="attrs">
                          <v-switch
                            v-model="ruta.ruta_optimizada"
                            @click="optimizarRuta(ruta)"
                            color="primary"
                            hide-details
                          />
                        </div>
                      </template>
                      <span>Optimizar ruta?</span>
                    </v-tooltip>
                  </v-row>
                  <v-select
                    :items="programacionDeRutas.sucursales"
                    v-model="puntoDeOrigen"
                  />
                </v-col>
                <!-- Servicios/Clientes -->
                <v-col>
                  <!-- <v-col v-show="!rutaInactiva"> -->
                  <drop
                    :accepts-data="(item) => item.latlng"
                    @drop="checkServiceInRoute($event, ruta)"
                    class="servicios_drop"
                    mode="cut"
                    ><h4>Clientes:</h4>

                    <template v-for="(item, i) in ruta.servicios">
                      <v-list-item :key="i">
                        <v-row no-gutters>
                          <!-- iconos para cambiar el orden de servicios en la ruta -->
                          <!-- <v-col> -->
                          <!-- <v-row class="d-flex"> -->
                          <!-- flecha abajo -->
                          <v-tooltip right>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                fab
                                small
                                @click="cambiarOrdenDelServicio(item, ruta, 1)"
                                v-on="on"
                                class="mr-1 mt-3"
                                v-if="
                                  ruta.servicios && ruta.servicios.length > 1
                                "
                              >
                                <v-icon color="blue"
                                  >mdi-arrow-down-thin-circle-outline</v-icon
                                >
                              </v-btn>
                            </template>
                            <span
                              >Cambiar el orden de atención del cliente</span
                            >
                          </v-tooltip>
                          <!-- flecha arriba -->
                          <v-tooltip right>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                fab
                                small
                                @click="cambiarOrdenDelServicio(item, ruta, -1)"
                                v-on="on"
                                class="mr-1 mt-3"
                                v-if="
                                  ruta.servicios && ruta.servicios.length > 1
                                "
                              >
                                <v-icon color="blue"
                                  >mdi-arrow-up-thin-circle-outline</v-icon
                                >
                              </v-btn>
                            </template>
                            <span
                              >Cambiar el orden de atención del cliente</span
                            >
                          </v-tooltip>
                          <!-- </v-row> -->
                          <!-- </v-col> -->
                          <!-- <v-spacer></v-spacer> -->
                          <!-- datos de la ruta -->
                          <!-- <v-col> -->
                          <v-list-item-content class="ml-2">
                            <v-list-item-title class="item-list-width">
                              {{ i + 1 }}.- {{ item.nombre }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ item.direccion }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <!-- </v-col> -->
                          <!-- <v-spacer></v-spacer> -->
                          <!-- icono para eliminar la ruta -->
                          <!-- <v-col> -->
                          <v-tooltip right>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                class="red--text ml-1 mt-3"
                                fab
                                x-small
                                @click="deleteServices(item, ruta)"
                                v-on="on"
                              >
                                <v-icon>mdi-close-thick</v-icon>
                              </v-btn>
                            </template>
                            <span>Eliminar cliente de la ruta</span>
                          </v-tooltip>
                          <!-- </v-col> -->
                        </v-row>
                      </v-list-item>
                    </template>
                    <!-- considerar en la anterior eliminar el servicio de la ruta y devovlerlo a la "derecha", con los servicios disponibles, sino se activa el drop también -->
                  </drop>
                </v-col>
              </v-row>
              <!-- Tripulantes -->
              <v-row>
                <v-col>
                  <!-- <v-col v-if="!rutaInactiva"> -->
                  <drop
                    @drop="verificarResponsableDeRuta($event, ruta)"
                    :accepts-data="(item) => item.datos_laborales"
                    class="personal_drop"
                    mode="cut"
                    ><h4>Tripulantes:</h4>
                    <template v-for="(item, i) in ruta.tripulacion">
                      <v-list-item :key="i">
                        <v-list-item-content>
                          <v-list-item-title
                            >{{ item.nombre.toUpperCase() }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            >Cargo:
                            {{ item.cargo }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            {{
                              item.responsable ? 'Responsable de ruta' : null
                            }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              class="red--text"
                              fab
                              x-small
                              @click="removePersonal(item, ruta)"
                              v-on="on"
                            >
                              <v-icon>mdi-close-thick</v-icon>
                            </v-btn>
                          </template>
                          <span>Eliminar tripulante de la ruta</span>
                        </v-tooltip>
                      </v-list-item>
                    </template>
                  </drop>
                </v-col>
              </v-row>
              <!-- Unidades -->
              <v-row>
                <v-col>
                  <!-- <v-col v-if="!rutaInactiva"> -->
                  <drop
                    @drop="addUnit($event, ruta)"
                    :accepts-data="(item) => item.placa"
                    class="flota_drop"
                    mode="cut"
                  >
                    <h4>Unidad:</h4>
                    <template v-for="(item, i) in ruta.flota">
                      <v-list-item :key="i">
                        <v-list-item-content>
                          <v-list-item-title
                            >{{ item.tipo_unidad }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            >{{ item.placa }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="red--text"
                              fab
                              x-small
                              @click="deleteUnit(item, ruta)"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon>mdi-close-thick</v-icon>
                            </v-btn>
                          </template>
                          <span>Eliminar unidad de la ruta</span>
                        </v-tooltip>
                      </v-list-item>
                    </template>
                  </drop>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <!-- </v-expand-transition> -->
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState } from 'vuex';
import { Drop } from 'vue-easy-dnd';
import { addUnit } from '@/api/route/addUnit.js';
import { addServices } from '@/api/route/addServices.js';
import { addPersonnel } from '@/api/route/addPersonnel.js';
import { editPuntoOrigen, editRoute } from '@/api/route/editRoute.js';
import moment from 'moment';
import { actualizarAsistencia } from '@/api/persons/actualizarAsistencia.js';
import { getByDate } from '@/api/route/getxDate.js';
import { deleteServices } from '@/api/route/deleteServices.js';
import { deletePersonnel } from '@/api/route/deletePersonnel.js';
import { deleteUnit } from '@/api/route/deleteUnit.js';
import { deleteRoute } from '@/api/route/deleteRoute.js';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';

export default {
  data: () => ({
    expand: false,
    puntoDeOrigen: '',
    containerKey: 0,
    val: false,
    service: [],
    personal: [],
    flota: [],
    dataServices: [],
    rutas: [],
    picker: localStorage.getItem('Picker'),
    checkbox: [],
    mostrar: true,
    ocultar: false,
    hour: '',
    name_ruta: '',
    codigoRutaSeleccionada: '',
    // waypts: [],
    rutaInactiva: true,
  }),
  // props: {
  //   // programacion: Object,
  //   // disabled: Boolean,
  // },
  computed: {
    ...mapState([
      'renderEditRoutes',
      'googleMap',
      'directionsRenderer',
      'directionsService',
      'programacionDeRutas',
      'mapaActual',
      'marcadoresEnMapa',
      'temporizadorMostrarGPSDeRuta',
    ]),
  },
  components: {
    Drop,
  },
  mounted() {
    //si existe una propiedad del objeto significa q está cargado el mapa sino hay q volver a cargarlo
    if (!this.directionsRenderer.bound) {
      this.$router.push({
        path: '/dashboard',
      });
    }
  },
  watch: {
    containerKey() {
      //reordenar el orden de los servicios en las rutas
      this.programacionDeRutas.rutas = this.programacionDeRutas.rutas.map(
        (ruta) => {
          ruta.servicios.sort((a, b) => a.orden - b.orden);
          return ruta;
        }
      );
      this.programacionDeRutas.rutas = this.programacionDeRutas.rutas.map(
        (ruta) => {
          ruta.servicios.forEach((servicio, i) => {
            servicio.orden = i + 1;
          });
          return ruta;
        }
      );
    },
    puntoDeOrigen() {
      const codigo_ruta = this.codigoRutaSeleccionada;

      const ruta = this.programacionDeRutas.rutas.find(
        (item) => item.codigo_ruta === codigo_ruta
      );
      // console.log(ruta);
      if (!ruta.punto_origen || ruta.punto_origen !== this.puntoDeOrigen)
        this.editarPuntoDeOrigen(ruta);
    },
    val(ruta) {
      // console.log(this.val, ruta, this.codigoRutaSeleccionada);
      this.codigoRutaSeleccionada = '';
      this.detenerTemporizador();
      // this.$store.state.marcadoresEnMapa.map((marcador) => {
      //   marcador.setMap(null);
      //   return marcador;
      // });
      if (this.val && this.programacionDeRutas.sucursales) {
        // console.log(ruta);
        this.rutaInactiva = false;
        this.codigoRutaSeleccionada = ruta.codigo_ruta;
        this.renderRouteInMap(ruta);
      }
      if (this.val && !this.programacionDeRutas.sucursales) {
        this.showNoAddressesAlert();
      }
      if (!this.val) {
        // console.log(this.$store.state.marcadoresEnMapa);
        this.rutaInactiva = true;
        this.directionsRenderer.setMap(null);
        this.$store.state.marcadoresEnMapa.map((marcador) => {
          marcador.setMap(null);
          return marcador;
        });
      }
    },
  },
  methods: {
    async optimizarRuta(ruta) {
      //se actualiza la ruta con el valor del switch luego del click
      this.renderRouteInMap(ruta);
    },
    async cambiarOrdenDelServicio(item, ruta, orden) {
      let servicioEncontrado = ruta.servicios.find(
        (servicio) => servicio.orden === item.orden + orden
      );
      if (servicioEncontrado) {
        servicioEncontrado.orden = item.orden;
        ruta.servicios.map((servicio) => {
          if (servicio.direccion === item.direccion) {
            servicio.orden = item.orden + orden;
          }
          return servicio;
        });

        await this.editarRuta(ruta);

        // this.containerKey++;
        await this.renderRouteInMap(ruta);
      }
    },
    async deleteServices(item, ruta) {
      const { error, message, tokenExpired } = await deleteServices(
        moment(this.picker).tz('America/Lima'),
        ruta.codigo_ruta,
        item._id
      );
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (error) {
        //do something
      }
      if (message) {
        //esta lógica no está funcionando al 100% en parte por el "drop" que tiene el mismo objeto q contiene a la ruta
        this.programacionDeRutas.rutas.map((name, rutas) => {
          if (name.codigo_ruta == ruta.codigo_ruta) {
            this.programacionDeRutas.rutas[rutas].servicios.splice(
              this.programacionDeRutas.rutas[rutas].servicios.findIndex(
                (element) => element === item
              ),
              1
            );
          }
        });
        this.containerKey += 1;
        this.renderRouteInMap(ruta);
      }
    },
    async removePersonal(index, ruta) {
      const { error, message } = await deletePersonnel(
        moment(this.picker).tz('America/Lima'),
        ruta.codigo_ruta,
        index._id
      );
      // console.log(index, ruta);
      if (error) {
        //do something
      }
      if (message) {
        // si se ha eliminado correctamente un tripulante se debe actualizar su hora programada aquí
        let tripulante = index;
        tripulante.hora_programada = '';
        tripulante.tipo_jornada = tripulante.tipo_jornada
          ? tripulante.tipo_jornada
          : 'Descanso';
        tripulante.fecha_asistencia = moment(this.picker).tz('America/Lima');
        const {
          error: errorActualizandoAsistencia,
        } = await actualizarAsistencia(tripulante);
        if (errorActualizandoAsistencia) {
          //do something
        }
      }
      this.programacionDeRutas.rutas.map((name, rutas) => {
        if (name.codigo_ruta == ruta.codigo_ruta) {
          this.programacionDeRutas.rutas[rutas].tripulacion.splice(
            this.programacionDeRutas.rutas[rutas].tripulacion.findIndex(
              (element) => element === index
            ),
            1
          );
        }
      });
    },
    async deleteUnit(index, ruta) {
      const { error } = await deleteUnit(
        moment(this.picker).tz('America/Lima'),
        ruta.codigo_ruta,
        index._id
      );
      if (error) {
        //do something
      }
      this.programacionDeRutas.rutas.map((name, rutas) => {
        if (name.codigo_ruta == ruta.codigo_ruta) {
          this.programacionDeRutas.rutas[rutas].flota.splice(
            this.programacionDeRutas.rutas[rutas].flota.findIndex(
              (element) => element === index
            ),
            1
          );
        }
      });
    },
    showSuccessAlert() {
      this.$swal.fire({
        title: 'Operación realizada!',
        text: 'Datos actualizados!',
        icon: 'success',
        timer: 1500,
      });
    },
    showEsResponsableDeRutaAlert(tripulante, ruta) {
      this.$swal
        .fire({
          title: `${tripulante.nombre} ¿Es el responsable de la ruta?`,
          text: '',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: 'No',
          confirmButtonText: 'Sí',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.addPersonnel(
              moment(this.picker).tz('America/Lima'),
              ruta.codigo_ruta,
              tripulante,
              true,
              ruta.hora_programada
            );
            // this.rutas.map((name, rutas) => {
            //   if (name.codigo_ruta == ruta.codigo_ruta) {
            //     this.rutas[rutas].tripulacion.push(tripulante);
            //   }
            // });
            // console.log(tripulante);
            this.programacionDeRutas.rutas.map((name, rutas) => {
              if (name.codigo_ruta == ruta.codigo_ruta) {
                this.programacionDeRutas.rutas[rutas].tripulacion.push({
                  _id: tripulante._id,
                  doi: tripulante.doi,
                  cargo: tripulante.datos_laborales.cargo,
                  nombre: tripulante.datos_laborales.nombres,
                  responsable: true,
                });
              }
            });
            return;
          }
          this.addPersonnel(
            moment(this.picker).tz('America/Lima'),
            ruta.codigo_ruta,
            tripulante,
            false,
            ruta.hora_programada
          );
          // this.rutas.map((name, rutas) => {
          //   if (name.codigo_ruta == ruta.codigo_ruta) {
          //     this.rutas[rutas].tripulacion.push(tripulante);
          //   }
          // });
          // console.log(tripulante);
          this.programacionDeRutas.rutas.map((name, rutas) => {
            if (name.codigo_ruta == ruta.codigo_ruta) {
              this.programacionDeRutas.rutas[rutas].tripulacion.push({
                _id: tripulante._id,
                doi: tripulante.doi,
                cargo: tripulante.datos_laborales.cargo,
                nombre: tripulante.datos_laborales.nombres,
                responsable: false,
              });
            }
          });
        });
    },
    async verificarResponsableDeRuta(event, ruta) {
      let existeResponsableDeRuta = ruta.tripulacion.find(
        (tripulante) => tripulante.responsable
      );
      if (!existeResponsableDeRuta) {
        this.showEsResponsableDeRutaAlert(event.data, ruta);
        return;
      }
      // this.personal.push(event.data);
      // this.addPersonnel(this.picker, ruta.codigo_ruta, event.data, false);
      const { error, tokenExpired, message } = await addPersonnel(
        moment(this.picker).tz('America/Lima'),
        ruta.codigo_ruta,
        event.data,
        false
      );
      if (error) {
        //do something
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (message) {
        // si se ha agregado correctamente un tripulante "no encargado de ruta" se debe actualizar su hora programada aquí
        let tripulante = event.data;
        tripulante.hora_programada = ruta.hora_programada;
        tripulante.tipo_jornada = tripulante.tipo_jornada
          ? tripulante.tipo_jornada
          : 'Descanso';
        tripulante.fecha_asistencia = moment(this.picker).tz('America/Lima');
        const {
          error: errorActualizandoAsistencia,
        } = await actualizarAsistencia(tripulante);
        if (errorActualizandoAsistencia) {
          //do something
        }
        // this.rutas.map((name, rutas) => {
        //   if (name.codigo_ruta == ruta.codigo_ruta) {
        //     this.rutas[rutas].tripulacion.push(event.data);
        //   }
        // });
        // console.log(event.data);
        this.programacionDeRutas.rutas.map((name, rutas) => {
          if (name.codigo_ruta == ruta.codigo_ruta) {
            this.programacionDeRutas.rutas[rutas].tripulacion.push({
              _id: event.data._id,
              doi: event.data.doi,
              cargo: event.data.datos_laborales.cargo,
              nombre: event.data.datos_laborales.nombres,
              responsable: false,
            });
          }
        });
        // console.log(message, this.programacionDeRutas);
        this.containerKey++;
      }
    },
    showDeleteRouteAlert(codigoRuta) {
      this.$swal
        .fire({
          title: `Atención!`,
          text: `Está seguro que desea eliminar la ruta: ${codigoRuta}`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: 'No',
          confirmButtonText: 'Sí',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.deleteRoute(codigoRuta);
          }
        });
    },
    showNoAddressesAlert() {
      this.$swal.fire({
        title: `Error`,
        text:
          'Aún no hay sucursales/direcciones agregadas. Debe ir al módulo de Configuración!',
        icon: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    checkServiceInRoute(event, ruta) {
      let servicio = ruta.servicios.find(
        (item) => item.direccion === event.data.direccion
      );
      if (!servicio) {
        this.addService(event, ruta);
      }
    },
    async addService(event, ruta) {
      // console.log(ruta);
      const { error, message, tokenExpired } = await addServices(
        moment(this.picker)
          .tz('America/Lima')
          .format(),
        ruta.codigo_ruta,
        event.data,
        ruta.servicios.length + 1 //verificar el orden correcto de cada elemento, por ejemplo tiene que poder distinguir si un elemento se inserta en el medio del array
      );
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (error) console.error(error);
      if (message) {
        // this.rutas.map((item) => {
        //   if (item.codigo_ruta == ruta.codigo_ruta)
        //     item.servicios.push(event.data);
        // });
        this.programacionDeRutas.rutas.map((item) => {
          if (item.codigo_ruta == ruta.codigo_ruta)
            item.servicios.push(event.data);
        });
        this.containerKey++;
        this.renderRouteInMap(ruta);
      }
    },
    async addPersonnel(
      fecha,
      ruta,
      tripulante,
      responsableDeRuta,
      horaProgramada
    ) {
      const { error, message } = await addPersonnel(
        fecha,
        ruta,
        tripulante,
        responsableDeRuta
      );
      if (error) {
        //do something
      }
      // si se agrega el tripulante entonces agregar la hora programada en la asistencia
      if (message) {
        // let tripulante = event.data
        tripulante.hora_programada = horaProgramada;
        tripulante.tipo_jornada = tripulante.tipo_jornada
          ? tripulante.tipo_jornada
          : 'Descanso';
        tripulante.fecha_asistencia = fecha;
        // console.log(tripulante, ruta);
        const {
          error: errorActualizandoAsistencia,
        } = await actualizarAsistencia(tripulante);
        if (errorActualizandoAsistencia) {
          //do something
        }
      }
    },
    async addUnit(event, ruta) {
      // console.log(event.data, ruta);
      const { error, message } = await addUnit(
        moment(this.picker)
          .tz('America/Lima')
          .format(),
        ruta.codigo_ruta,
        event.data
      );
      if (message) {
        this.programacionDeRutas.rutas.map((name, rutas) => {
          if (name.codigo_ruta == ruta.codigo_ruta) {
            this.programacionDeRutas.rutas[rutas].flota.push(event.data);
          }
        });
      }
      if (error) {
        // console.log(error, message);
      }
    },
    async deleteRoute(codigoRuta) {
      const { error, message, tokenExpired } = await deleteRoute(
        moment(this.picker)
          .tz('America/Lima')
          .format(),
        codigoRuta
      );
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (error) {
        // console.log('Lógica para siguiente acción en caso de error');
      }
      if (message) {
        this.programacionDeRutas.rutas = this.programacionDeRutas.rutas.filter(
          (item) => item.codigo_ruta !== codigoRuta
        );
        this.$swal.fire({
          title: 'La ruta ha sido eliminada con éxito',
          text: '',
          icon: 'info',
          timer: 1500,
        });
        this.containerKey += 1;
        this.directionsRenderer.setMap(null);
      }
    },
    async editarRuta(ruta) {
      const { error, tokenExpired, message } = await editRoute(
        this.programacionDeRutas.fecha_programacion,
        ruta
      );
      if (error) {
        //do something
      }
      if (tokenExpired) {
        //do something
      }
      if (message) {
        // console.log(message);
        this.showSuccessAlert();
        // this.renderRouteInMap(ruta);
        //do something
      }
    },
    async editarPuntoDeOrigen(ruta) {
      const { error, tokenExpired, message } = await editPuntoOrigen(
        this.programacionDeRutas.fecha_programacion,
        this.codigoRutaSeleccionada,
        this.puntoDeOrigen
      );
      if (error) {
        //do something
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (message) {
        ruta.punto_origen = this.puntoDeOrigen;

        await this.renderRouteInMap(ruta);
        // this.puntoDeOrigen = ruta.punto_origen;
        //do something
      }
    },
    colocarMarcadorEnRuta(ruta) {
      //reemplazar estas coordenadas por lo obtenido de la versión móvil
      const sucursal = this.programacionDeRutas.sucursales.find(
        (sucursal) => sucursal.text === this.puntoDeOrigen
      );
      let latlng = {
        lat: sucursal.latlng.lat,
        lng: sucursal.latlng.lng,
      };
      if (ruta.latlng) {
        latlng.lat = Number(ruta.latlng.lat);
        latlng.lng = Number(ruta.latlng.lng);
      }
      this.actualizarUbicacionDeRutaEnMapa(ruta);
      let imagenUnidad = '';
      if (ruta.flota[0]) {
        switch (ruta.flota[0].tipo_unidad) {
          case 'Camión':
            imagenUnidad =
              'https://cti-roads-assets.s3.us-west-2.amazonaws.com/cami%C3%B3n.png';
            break;
          case 'Minivan':
            imagenUnidad =
              'https://cti-roads-assets.s3.us-west-2.amazonaws.com/minivan.png';
            break;
          case 'Moto':
            imagenUnidad =
              'https://cti-roads-assets.s3.us-west-2.amazonaws.com/moto.png';
            break;
          case 'Bus':
            imagenUnidad =
              'https://cti-roads-assets.s3.us-west-2.amazonaws.com/bus.png';
            break;
          case 'Semitrailer':
            imagenUnidad =
              'https://cti-roads-assets.s3.us-west-2.amazonaws.com/semitrailer.png';
            break;

          default:
            break;
        }
      }

      // const image =
      //   'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png';
      const image = {
        // url: require('@/assets/flota/minivan.png'),
        url: imagenUnidad,
        // This marker is 20 pixels wide by 32 pixels high.
        // size: new this.googleMap.maps.Size(40, 40),
        animation: this.googleMap.maps.Animation.DROP,

        // size: new this.googleMap.maps.Size(175, 130),
        origin: new this.googleMap.maps.Point(0, 0),
        anchor: new this.googleMap.maps.Point(17, 34),
        scaledSize: new this.googleMap.maps.Size(85, 55),
        // The origin for this image is (0, 0).
        // origin: new this.googleMap.maps.Point(0, 0),
        // The anchor for this image is the base of the flagpole at (0, 32).
        // anchor: new this.googleMap.maps.Point(0, 32),
      };
      // console.log(image);
      // const shape = {
      //   coords: [1, 1, 1, 20, 18, 20, 18, 1],
      //   type: 'poly',
      // };
      this.$store.state.marcadoresEnMapa.map((marcador) => {
        marcador.setMap(null);
        return marcador;
      });
      this.$store.state.marcadoresEnMapa = [];
      this.$store.state.marcadoresEnMapa.push(
        new this.googleMap.maps.Marker({
          position: latlng,
          map: this.mapaActual,
          title: 'Ubicación actual del responsable de ruta',
          // shape,
          // label: 'LABEL',
          icon: image,
        })
      );
      // console.log(beachMarker);
    },
    async renderRouteInMap(ruta) {
      this.$store.state.marcadoresEnMapa.map((marcador) => {
        marcador.setMap(null);
        return marcador;
      });
      // let ruta = this.programacionDeRutas.rutas.find(
      //   (item) => item.codigo_ruta === codigo_ruta
      // );
      let waypoints = [];
      this.directionsRenderer.setMap(null);
      if (!this.rutaInactiva) {
        // if (!ruta.punto_origen) {
        //   this.editarPuntoDeOrigen(ruta);
        // }
        this.puntoDeOrigen = ruta.punto_origen
          ? ruta.punto_origen
          : this.programacionDeRutas.sucursales[0].text;
        const servicios = ruta.servicios;
        servicios.sort((a, b) => {
          return a.orden - b.orden;
        });
        if (servicios.length >= 1 && this.puntoDeOrigen !== '') {
          for (let i in servicios) {
            waypoints.push({
              location: servicios[i].direccion,
              stopover: true,
              // orden: servicios[i].orden,
            });
          }

          await this.calculateAndDisplayRoute(waypoints, ruta);
        }
      }
    },
    async calculateAndDisplayRoute(waypoints, ruta) {
      // let parent = this;
      let request = {};
      // console.log(waypoints);
      if (waypoints.length >= 2) {
        const { location: destination } = waypoints.pop();
        // console.log(destination);
        request = {
          origin: this.puntoDeOrigen,
          // destination: this.waypts[this.waypts.length - 1].location,
          destination,
          waypoints,
          optimizeWaypoints: ruta.ruta_optimizada, //si está en true no respeta el orden de los waypoints en relación al orden de atención de clientes de la ruta
          travelMode: this.googleMap.maps.TravelMode.DRIVING,
        };
      } else {
        request = {
          origin: this.puntoDeOrigen,
          destination: waypoints[0].location,
          optimizeWaypoints: ruta.ruta_optimizada,
          travelMode: this.googleMap.maps.TravelMode.DRIVING,
        };
      }

      let directionsService = new this.googleMap.maps.DirectionsService();

      await directionsService.route(request, async (response, status) => {
        // await this.directionsService.route(request, async (response, status) => {
        // console.log(response, status);
        if (status === 'OK' && response) {
          // console.log(this.directionsRenderer);
          this.colocarMarcadorEnRuta(ruta);

          // si la ruta es "optimizada" hay que actualizar la lista de atención de servicios
          const { routes } = response;
          let rutaCambio = false;
          // let serviciosReOrdenados = []
          routes[0].legs.map((leg, i) => {
            let servicioEnRuta = ruta.servicios.find(
              (servicio) => servicio.direccion === leg.end_address
            );
            if (servicioEnRuta) {
              if (servicioEnRuta.orden !== i + 1) {
                rutaCambio = true;
                servicioEnRuta.orden = i + 1;
              }
            }
            return leg;
          });
          // ruta.servicios = []
          // ruta.servicios.push(...serviciosReOrdenados)
          if (rutaCambio) {
            await this.editarRuta(ruta);
            this.containerKey++;
          }
          // await parent.directionsRenderer.setDirections(null);
          this.directionsRenderer.setMap(this.mapaActual);
          // await directionsRenderer.setDirections(response);
          await this.directionsRenderer.setDirections(response);
        } else {
          this.$swal.fire({
            title: `Directions request failed due to ${status}`,
            text: '',
            icon: 'error',
            timer: 1500,
          });
          // window.alert('Directions request failed due to ' + status);
        }
      });
    },
    async actualizarUbicacionDeRutaEnMapa(ruta) {
      this.detenerTemporizador();
      const cantidadSegundosParaRefrescoDeCoordenadas = 8;
      await this.obtenerProgramacionDeRutas();
      const { codigo_ruta } = ruta;

      const rutaEncontrada = this.programacionDeRutas.rutas.find(
        (item) => item.codigo_ruta === codigo_ruta
      );
      let existeEncargadoDeRuta = false;
      if (rutaEncontrada) {
        existeEncargadoDeRuta = rutaEncontrada.tripulacion.find(
          (tripulante) => tripulante.responsable
        );
      }
      if (
        moment(this.picker)
          .tz('America/Lima')
          .format('DD-MM-YYYY') ===
          moment()
            .tz('America/Lima')
            .format('DD-MM-YYYY') &&
        rutaEncontrada &&
        existeEncargadoDeRuta
      ) {
        this.$store.state.temporizadorMostrarGPSDeRuta.push(
          setTimeout(() => {
            //eliminar marcadores anteriores
            this.$store.state.marcadoresEnMapa.map((marcador) => {
              marcador.setMap(null);
              return marcador;
            });
            this.colocarMarcadorEnRuta(rutaEncontrada);
            this.actualizarUbicacionDeRutaEnMapa(rutaEncontrada);
          }, cantidadSegundosParaRefrescoDeCoordenadas * 1000)
        );
      }
    },
    detenerTemporizador() {
      this.$store.state.temporizadorMostrarGPSDeRuta.map((timer) => {
        clearTimeout(timer);
        return timer;
      });
      this.$store.state.temporizadorMostrarGPSDeRuta = [];
    },
    async obtenerProgramacionDeRutas() {
      const { error, programacion, tokenExpired } = await getByDate(
        moment(this.picker)
          .tz('America/Lima')
          .format()
      );
      if (error) {
        this.showAlert();
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (programacion) {
        this.$store.state.programacionDeRutas = programacion;
        this.containerKey++;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'src/styles/main.scss';
// .test {
//   background-color: $secondary !important;
// }
.item-list-width {
  width: 300px !important;
}
.m_20 {
  margin-top: 20px;
}
.new_route {
  border: 1px rgba(82, 95, 88, 0.678) solid;
  border-radius: 5px;
  margin: auto;
  justify-content: space-between;
  display: flex;
  padding: 10px;
  .v-text-field {
    max-width: 30% !important;
  }
  .v-btn {
    display: flex;
    flex-direction: row;
    background: transparent !important;
    min-width: 20px !important;
    box-shadow: none !important;
  }
}
.servicios_drop.drop-allowed {
  border-radius: 5px;
  padding: 5px;
  border: 1px solid rgba(18, 163, 13, 0.466);
  // width: 280px;
}
.personal_drop.drop-allowed {
  border-radius: 5px;
  border: 1px solid rgba(83, 67, 13, 0.787);

  padding: 5px;
  // width: 150px;
}
.flota_drop.drop-allowed {
  border-radius: 5px;
  padding: 5px;
  border: 1px solid rgba(16, 47, 87, 0.466);
  // width: 150px;
}
.servicios_drop,
.personal_drop,
.flota_drop {
  text-align: start;
  padding: 1px;
  width: 100%;
  min-height: 100%;
  .v-list-item {
    text-align: left;
    width: 90%;
  }
  .v-btn {
    background-color: transparent !important;
    box-shadow: none;
    width: 20px !important;
  }
}
.align-center {
  text-align: center;
}
.p-10 {
  padding: 10px;
}
.column {
  flex-direction: row;
}
.bg-secondary {
  background: $secondary;
}
.block {
  display: block;
}
div {
  .theme--light.v-card {
    background-color: $secondary;
  }
  .v-card {
    margin: auto;
    max-width: fit-content;
  }
}
</style>
