<template>
  <div>
    <div class="  absolute">
      <FormularioContactoSoporte />
      <div v-for="(item, i) in modulesAdmin" :key="i">
        <!-- <router-link :to="item.path"> -->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              :class="item.class"
              class="mx-2"
              fab
              large
              :elevation="elevation"
              width="70px"
              height="70px"
              @click="goToRoute(item)"
            >
              <!-- {{ item.title }} -->
              <v-img width="30px" height="30px" :src="item.urlImg" contain />
            </v-btn>
          </template>
          <span>{{ item.msj }}</span>
        </v-tooltip>
        <!-- </router-link> -->
      </div>
    </div>
    <div id="mapa">
      <v-img height="100vh" cover :src="require('@/assets/img/mapa.png')" />
    </div>
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click="endSession"
          class="btn-end__session"
          v-bind="attrs"
          v-on="on"
        >
          <v-img
            width="10px"
            :src="require('@/assets/menu-icons/off.svg')"
            contain
          />
        </v-btn>
      </template>
      <span>Cerrar Sesión</span>
    </v-tooltip>
    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click="contactarSoporte"
          class="btn-support"
          v-bind="attrs"
          v-on="on"
        >
          <v-img
            width="3.25vw"
            :src="require('@/assets/menu-icons/support-icon.png')"
          />
        </v-btn>
      </template>
      <span>Contactar con soporte técnico</span>
    </v-tooltip>
  </div>
</template>
<script>
import FormularioContactoSoporte from './FormularioContactoSoporte.vue';
import obtenerRolesDelToken from '@/utils/obtenerRolesDelToken.js';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';
import obtenerPlanVigenteActual from '@/utils/obtenerPlanVigenteActual.js';
import { getClientData } from '@/api/configuration/getClientData.js';
import { mapState } from 'vuex';

export default {
  name: 'Dashboard',
  data: () => ({
    elevation: 6,
  }),
  components: {
    FormularioContactoSoporte,
  },
  mounted() {
    this.storeClientData();
    this.$store.state.botonPersonalSeleccionado = 'activo';
    this.$store.state.verPersonalActivo = true;
    this.$store.state.verNuevoPersonal = false;
    this.$store.state.verPersonalHistorico = false;
    this.$store.state.editPerson = false;
  },
  computed: {
    ...mapState(['usuarioLogueado', 'rolesDelSistema', 'cliente']),
    modulesAdmin() {
      const modulesAdmin = [
        {
          urlImg: require('@/assets/menu-icons/delivered.svg'),
          path: '/services',
          class: 'btn_servicios',
          msj: 'CLIENTES',
        },
        {
          urlImg: require('@/assets/menu-icons/report.svg'),
          path: '/report',
          class: 'btn_personal',
          msj: 'REPORTES',
        },
        {
          urlImg: require('@/assets/menu-icons/routes.svg'),
          path: '/route',
          class: 'btn_ruta',
          msj: 'RUTA',
        },
        {
          urlImg: require('@/assets/menu-icons/incidence.svg'),
          path: '/incidence',
          class: 'btn_flota',
          msj: 'INCIDENCIA',
        },
        {
          urlImg: require('@/assets/menu-icons/bus.svg'),
          path: '/flota',
          class: 'btn_reporte',
          msj: 'FLOTA',
        },
        {
          urlImg: require('@/assets/menu-icons/personal.svg'),
          title: 'Incidencia',
          path: '/persons',
          class: 'btn_incidencia',
          msj: 'PERSONAL',
        },
        {
          urlImg: require('@/assets/menu-icons/config.svg'),
          title: 'Configuración',
          path: '/settings',
          class: 'btn_config',
          msj: 'CONFIGURACIÓN',
        },
      ];
      return modulesAdmin;
    },
  },
  methods: {
    async storeClientData() {
      const { error, tokenExpired, client } = await getClientData();
      if (error) {
        //do something
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (client) {
        this.$store.state.cliente = client;
      }
    },
    contactarSoporte() {
      this.$store.state.formularioContactoSoporte = true;
    },
    goToRoute(item) {
      const token = localStorage.getItem('token');
      if (!token) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (!this.usuarioLogueado._id) {
        this.$store.state.usuarioLogueado = obtenerRolesDelToken(token);
      }
      const { roles, isSuperadmin } = this.usuarioLogueado;

      if (isSuperadmin) {
        if (!this.planHabilitadoParaModulo(item.msj)) {
          this.moduleNotAvailableAlert();
          return;
        }
        this.$router.push({
          path: item.path,
        });
        return;
      }

      //si sólo tiene el rol de acceso al app se le deniega todo el acceso web
      if (
        this.rolesDelSistema.ACCESO_APP.find(
          (rol) => rol === roles[0] && roles.length === 1
        ) ||
        !roles
      ) {
        this.rolNoPermitidoAlert();
        return;
      }

      // ACCESO A MODULO REPORTES
      if (item.msj === 'REPORTES') {
        // this.moduleNotAvailableAlert();
        // return;
      }
      // ACCESO A MODULO INCIDENCIA
      if (item.msj === 'INCIDENCIA') {
        this.moduleNotAvailableAlert();
        return;
      }
      // ACCESO A MODULO CLIENTES
      if (
        item.msj === 'CLIENTES' &&
        !this.rolesDelSistema.GESTOR_OPERACIONES.find(
          (rol) => rol === roles.find((rolDelUsario) => rolDelUsario === rol)
        ) &&
        !this.rolesDelSistema.GERENTE.find(
          (rol) => rol === roles.find((rolDelUsario) => rolDelUsario === rol)
        )
      ) {
        this.rolNoPermitidoAlert();
        return;
      }
      // ACCESO A MODULO RUTA
      if (
        item.msj === 'RUTA' &&
        !this.rolesDelSistema.GESTOR_OPERACIONES.find(
          (rol) => rol === roles.find((rolDelUsario) => rolDelUsario === rol)
        ) &&
        !this.rolesDelSistema.GERENTE.find(
          (rol) => rol === roles.find((rolDelUsario) => rolDelUsario === rol)
        )
      ) {
        this.rolNoPermitidoAlert();
        return;
      }
      // ACCESO A MODULO PERSONAL
      if (
        item.msj === 'PERSONAL' &&
        !this.rolesDelSistema.GESTOR_PERSONAL.find(
          (rol) => rol === roles.find((rolDelUsario) => rolDelUsario === rol)
        ) &&
        !this.rolesDelSistema.GESTOR_OPERACIONES.find(
          (rol) => rol === roles.find((rolDelUsario) => rolDelUsario === rol)
        ) &&
        !this.rolesDelSistema.GESTOR_CREDITOS.find(
          (rol) => rol === roles.find((rolDelUsario) => rolDelUsario === rol)
        ) &&
        !this.rolesDelSistema.GERENTE.find(
          (rol) => rol === roles.find((rolDelUsario) => rolDelUsario === rol)
        )
      ) {
        this.rolNoPermitidoAlert();
        return;
      }
      // ACCESO A MODULO CONFIGURACIÓN
      if (item.msj === 'CONFIGURACIÓN' && !isSuperadmin) {
        this.rolNoPermitidoAlert();
        return;
      }
      // ACCESO A MODULO FLOTA
      if (
        item.msj === 'FLOTA' &&
        !this.rolesDelSistema.GESTOR_OPERACIONES.find(
          (rol) => rol === roles.find((rolDelUsario) => rolDelUsario === rol)
        ) &&
        !this.rolesDelSistema.GERENTE.find(
          (rol) => rol === roles.find((rolDelUsario) => rolDelUsario === rol)
        )
      ) {
        this.rolNoPermitidoAlert();
        return;
      }

      if (!this.planHabilitadoParaModulo(item.msj)) {
        this.moduleNotAvailableAlert();
        return;
      }

      this.$router.push({
        path: item.path,
      });
    },
    moduleNotAvailableAlert() {
      this.$swal.fire({
        title: `Antención!`,
        text: 'Módulo no disponible en el plan actual contratado',
        icon: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    rolNoPermitidoAlert() {
      this.$swal.fire({
        title: `Error!`,
        text: 'El rol de tu usuario no permite el acceso',
        icon: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    endSession() {
      localStorage.clear();
      this.$router.push({
        path: '/login',
      });
    },
    planHabilitadoParaModulo(modulo) {
      const planVigenteActual = obtenerPlanVigenteActual(this.cliente, this);
      this.$store.state.planVigenteActual = planVigenteActual;
      switch (modulo) {
        case 'CONFIGURACIÓN':
          return true; //sin restricción por plan contratado
        case 'PERSONAL':
          return true; //sin restricción por plan contratado
        case 'RUTA':
          if (
            planVigenteActual.tipo_plan === 'CTIRoads' &&
            planVigenteActual.plan_vigente
          ) {
            return true;
          }
          return false;
        case 'INCIDENCIA':
          // if (planVigenteActual.tipo_plan === 'CTIRoads') {
          //   return true;
          // }
          return false;
        case 'REPORTES':
          // if (planVigenteActual.tipo_plan === 'CTIRoads') {
          //   return true;
          // }
          return true;
        case 'CLIENTES':
          if (
            planVigenteActual.tipo_plan === 'CTIRoads' &&
            planVigenteActual.plan_vigente
          ) {
            return true;
          }
          return false;
        case 'FLOTA':
          if (
            planVigenteActual.tipo_plan === 'CTIRoads' &&
            planVigenteActual.plan_vigente
          ) {
            return true;
          }
          return false;
        default:
          return false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'src/styles/main.scss';

.btn_servicios,
.btn_personal,
.btn_ruta,
.btn_flota,
.btn_reporte,
.btn_incidencia,
.btn_config {
  position: relative !important;
}
.btn_servicios {
  top: 220px;
  background: rgb(246, 168, 90) !important;
}
.btn_personal {
  top: 210px;
  right: 100px;
  background: rgb(163, 190, 235) !important;
}
.btn_ruta {
  top: 140px;
  left: 100px;
  background: rgb(106, 207, 92) !important;
}
.btn_flota {
  top: 180px;
  right: 100px;
  background: rgb(233, 65, 65) !important;
}
.btn_reporte {
  top: 180px;
  background: rgb(88, 189, 158) !important;
}
.btn_incidencia {
  top: 40px;
  left: 100px;
  background: rgb(199, 136, 84) !important;
}
.btn_config {
  top: -80px;
}
#mapa {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  .v-image {
    filter: blur(4px);
  }
}
.absolute {
  position: relative;
  z-index: 2;
}

.btn-end__session {
  border: none !important;
  width: 50px !important;
  height: 60px !important;
  bottom: 33px;
  right: 22px;
  position: absolute;
  border-radius: 100%;
}

.btn-support {
  border: none !important;
  width: 50px !important;
  height: 60px !important;
  bottom: 100px;
  right: 22px;
  position: absolute;
  border-radius: 100%;
}
</style>
