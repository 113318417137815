<template>
  <v-dialog
    v-model="editarDireccionClienteDialog"
    max-width="60vw"
    max-height="50vh"
    persistent
    class="dialog-container"
  >
    <v-card>
      <v-card-title
        class="text-h5 justify-center"
        style="color: teal; font-weight: bold"
      >
        {{ nuevaDireccion ? 'Agregar nueva direccion' : `Editar dirección` }}
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation :key="mapContainer">
          <v-row>
            <v-col>
              <v-flex>
                <v-text-field
                  label="Dirección"
                  placeholder="Dirección, distrito, provincia"
                  v-model="direccion.text"
                  @keypress.enter.prevent
                  :rules="direccionRules"
                >
                  <template slot="append-outer">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" @click="loadMapByAddress"
                          >mdi-map-marker</v-icon
                        >
                      </template>
                      <span>Ver dirección en mapa</span>
                    </v-tooltip>
                  </template>
                </v-text-field>
                <v-text-field
                  label="Nombre referencial"
                  placeholder="Oficina principal, oficina norte, oficina sur..."
                  v-model="direccion.nombre_referencial"
                  :rules="direccionRules"
                />
              </v-flex>
            </v-col>

            <v-col class="mapa-servicio">
              <div ref="map"></div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDialog">Cancelar</v-btn>
        <v-btn color="success" text @click="validate">Guardar</v-btn>
        <v-btn
          color="red darken-1"
          text
          @click="showDeleteAlert"
          v-if="!nuevaDireccion"
          >Eliminar</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import gmapsInit from '@/plugins/gmaps.js';

import { updateClient } from '@/api/configuration/updateClient.js';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';
import { getPersons } from '@/api/persons/getPersons.js';
import { editUserInfo } from '@/api/persons/editUserInfo.js';

export default {
  name: 'EditarDireccionClienteDialog',
  direccionOriginal: '',
  nombreReferencialOriginal: '',
  data() {
    return {
      mapContainer: 0,
      google: {},
      geocoder: {},
      direccion: {
        text: '',
        nombre_referencial: '',
        latlng: {
          lat: -12.04318,
          lng: -77.02824,
        },
      },
      direccionRules: [(v) => !!v || 'Datos requeridos'],
      direccionValida: false,
      valid: false,
      personal: [],
    };
  },
  computed: {
    ...mapState([
      'direccionClienteAEditar',
      'editarDireccionClienteDialog',
      'cliente',
    ]),
  },
  props: {
    nuevaDireccion: Boolean,
  },
  mounted() {
    this.obtenerPersonalCompleto();
  },
  methods: {
    async obtenerPersonalCompleto() {
      const { error, personal, tokenExpired } = await getPersons();
      if (personal) {
        this.personal = personal;
        // this.personal = this.personal.map((persona) => {
        //   persona.nombre = `${persona.datos_laborales.apellido_paterno} ${persona.datos_laborales.apellido_materno}, ${persona.nombre}`;
        //   return persona;
        // });
      }
      if (error) {
        //do something
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
    },
    showDeleteAlert() {
      this.$swal
        .fire({
          title: `Atención!`,
          text: `Va a eliminar la sede ${this.direccion.nombre_referencial}?`,
          icon: 'warning',
          showCancelButton: true,
          cancelButtonColor: 'teal',
          cancelButtonText: 'Cancelar',
          confirmButtonColor: 'red',
          confirmButtonText: 'Eliminar',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.deleteDireccion();
          }
        });
    },
    showSuccessAlert() {
      this.$swal.fire({
        title: `Operación realizada correctamente!`,
        text: '',
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    showErrorAlert() {
      this.$swal.fire({
        title: `Error`,
        text: 'La dirección debe ser ubicada en el mapa',
        icon: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    async deleteDireccion() {
      this.cliente.datos_empresa.direccion = this.cliente.datos_empresa.direccion.filter(
        (direccionCliente) => direccionCliente.text !== this.direccion.text
      );
      this.actualizarSedeDeUsuarios('eliminar');
      // console.log(this.cliente.datos_empresa);
      const { error, message, tokenExpired } = await updateClient(this.cliente);
      if (error) {
        //do something
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (message) {
        this.showSuccessAlert();
        this.$store.state.direccionClienteAEditar = {};
        this.$store.state.cliente = this.cliente;
        this.$store.state.editarDireccionClienteDialog = false;
      }
    },
    async updateClient() {
      if (!this.cliente.datos_empresa.direccion) {
        this.cliente.datos_empresa.direccion = [];
      }
      if (this.nuevaDireccion) {
        this.cliente.datos_empresa.direccion.push(this.direccion);
      }
      this.actualizarSedeDeUsuarios('actualizar');
      const { error, message, tokenExpired } = await updateClient(this.cliente);
      if (error) {
        //do something
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (message) {
        this.showSuccessAlert();
        this.$store.state.editarDireccionClienteDialog = false;
        this.$store.state.cliente = this.cliente;
        this.$store.state.direccionClienteAEditar = {};
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        if (!this.direccionValida) {
          this.showErrorAlert();
          return;
        }
        this.updateClient();
      }
    },
    closeDialog() {
      this.$store.state.editarDireccionClienteDialog = false;
    },

    async getMap() {
      const google = await gmapsInit();
      const geocoder = new google.maps.Geocoder();
      this.google = google;
      this.geocoder = geocoder;
    },

    async loadMapByAddress() {
      try {
        let parent = this;
        if (map) this.google.maps.setMap(null);
        const map = await new this.google.maps.Map(this.$refs.map, {
          disableDefaultUI: true,
          center: this.direccion.latLng,
          zoom: 16,
        });

        this.geocoder.geocode(
          { address: this.direccion.text },
          (results, status) => {
            if (status === 'OK') {
              if (results[0]) {
                map.setCenter(results[0].geometry.location);
                parent.direccionValida = true;
                let newLatLng = {
                  lat: results[0].geometry.location.lat(),
                  lng: results[0].geometry.location.lng(),
                };

                (parent.$options.direccionOriginal = ''),
                  (parent.$options.direccionOriginal =
                    results[0].formatted_address),
                  (parent.direccion.latlng = newLatLng),
                  (parent.direccion.text = results[0].formatted_address);
                // parent.direccionValida = true;

                const marker = new parent.google.maps.Marker({
                  map,
                  draggable: true,
                  position: results[0].geometry.location,
                });
                const handleEvent = (event) => {
                  const latlng = {
                    lat: event.latLng.lat(),
                    lng: event.latLng.lng(),
                  };
                  this.geocoder.geocode(
                    { location: latlng },
                    (results, status) => {
                      if (status === 'OK') {
                        if (results[0]) {
                          (parent.$options.direccionOriginal =
                            results[0].formatted_address),
                            (parent.direccion.latlng.lat = latlng.lat),
                            (parent.direccion.latlng.lng = latlng.lng),
                            (parent.direccion.text =
                              results[0].formatted_address);
                          // parent.direccionValida = true;
                        }
                      }
                    }
                  );
                };
                marker.addListener('dragend', handleEvent);
              }
            }
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
    async actualizarSedeDeUsuarios(tipoDeActualizacion) {
      if (tipoDeActualizacion === 'eliminar') {
        this.personal.map(async (persona) => {
          if (
            persona.datos_laborales.sede ===
            this.$options.nombreReferencialOriginal
          ) {
            persona.datos_laborales.sede = '';
            const { error } = await editUserInfo(persona);
            if (error) {
              //do something
            }
          }
          return persona;
        });
      }
      if (tipoDeActualizacion === 'actualizar') {
        this.personal.map(async (persona) => {
          if (
            (persona.datos_laborales.sede ===
              this.$options.nombreReferencialOriginal &&
              this.direccion.nombre_referencial !==
                this.$options.nombreReferencialOriginal) ||
            !persona.datos_laborales.sede
          ) {
            persona.datos_laborales.sede = this.direccion.nombre_referencial;
            const { error } = await editUserInfo(persona);
            if (error) {
              //do something
            }
          }
          return persona;
        });
      }
    },
  },

  created() {
    this.getMap();
  },
  watch: {
    'direccion.text'() {
      if (this.direccion.text !== this.$options.direccionOriginal) {
        this.direccionValida = false;
      }
      if (this.direccion.text === this.$options.direccionOriginal) {
        this.direccionValida = true;
      }
    },
    async direccionClienteAEditar() {
      this.direccionValida = false;
      if (
        this.direccionClienteAEditar.text &&
        this.direccionClienteAEditar.text !== '' &&
        this.direccionClienteAEditar.text.length > 0
      ) {
        this.direccionValida = true;
        this.direccion = this.direccionClienteAEditar;
      } else {
        this.direccion = {
          text: '',
          nombre_referencial: '',
          latlng: {
            lat: -12.04318,
            lng: -77.02824,
          },
        };
      }
      this.$options.direccionOriginal = this.direccion.text;
      this.$options.nombreReferencialOriginal = this.direccion.nombre_referencial;

      this.$nextTick(() => {
        this.loadMapByAddress();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/main.scss';
.mapa-servicio {
  display: grid !important;
  max-height: 100%;
  max-width: 50vw;
  height: 450px !important;
}
.dialog-container {
  overflow: scroll;
}
</style>
