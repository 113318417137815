<template>
  <v-overlay class="super">
    <v-card>
      <v-row>
        <v-col cols="5" class="align-form">
          <v-row>
            <v-col data-app>
              <v-select
                label="¿Disponible?"
                v-model="editInfo.disponible"
                :items="dropdown1"
              ></v-select>
            </v-col>
            <v-col>
              <v-text-field v-model="editInfo.placa" label="Placa :" />
            </v-col>
            <v-col data-app>
              <v-select
                v-model="editInfo.tipo_unidad"
                :items="dropdown"
                label="Tipo"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                type="date"
                class="datapicker_input"
                v-model="dates.dateoptional"
                label="MTTO preventivo (inicio)"
              />
            </v-col>
            <v-col>
              <v-text-field
                type="date"
                class="datapicker_input"
                v-model="dates.date1optional"
                label="MTTO preventivo (fin)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                type="date"
                class="datapicker_input"
                v-model="dates.date2optional"
                label="MTTO correctivo (inicio)"
              />
            </v-col>
            <v-col>
              <v-text-field
                type="date"
                class="datapicker_input"
                v-model="dates.date3optional"
                label="MTTO correctivo (fin)"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="editInfo.observaciones"
                label="Observaciones"
              />
            </v-col>
          </v-row>
          <v-row class="justify-btn">
            <v-col>
              <v-btn @click="update(editInfo, dates)">
                Actualizar
              </v-btn>
            </v-col>
            <v-col>
              <v-btn @click="deleteUnit(editInfo)">
                Eliminar
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <div v-if="editInfo.tipo_unidad">
            <div v-for="(item, i) in dropoptions" :key="i">
              <v-img
                width="420px"
                v-if="editInfo.tipo_unidad == item.title"
                :src="item.url"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-overlay>
</template>
<script>
import { deleteUnit } from '@/api/flota/deleteUnit';
import { updateUnit } from '@/api/flota/putUnit';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';

export default {
  data: () => ({
    dropdown1: ['Disponible', 'No disponible'],
    dropdown: ['Moto', 'Minivan', 'Bus', 'Semitrailer', 'Camión'],
    editInfo: '',
    dates: {
      dateoptional: '',
      date1optional: '',
      date2optional: '',
      date3optional: '',
    },
  }),
  props: {
    info: Object,
  },
  created() {
    (this.editInfo = this.info), (this.dates.date3optional = this.date3);
    this.dates.date2optional = this.date2;
    (this.dates.dateoptional = this.date),
      (this.dates.date1optional = this.date1);
  },
  methods: {
    showSuccessAlert(msj) {
      this.$swal.fire({
        icon: 'success',
        title: msj,
        showConfirmButton: false,
        timer: 3000,
      });
    },
    async update(item, dates) {
      if (item.disponible == 'Disponible') {
        item.disponible = true;
      } else {
        item.disponible = false;
      }
      const { error, message, tokenExpired } = await updateUnit(item, dates);
      if (error) {
        //do something
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (message) {
        this.showSuccessAlert('¡Actualizaste la unidad con éxito!');
        setTimeout(function() {
          window.location.reload();
        }, 1500);
        //do something
      }
    },
    async deleteUnit(item) {
      const { error, message, tokenExpired } = await deleteUnit(item);
      if (error) {
        //do comething
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (message) {
        this.showSuccessAlert('¡Eliminaste la unidad con éxito!');
        setTimeout(function() {
          window.location.reload();
        }, 1500);
      }
    },
  },
  computed: {
    date() {
      const fecha = this.info.fecha_mtto_preventivo_inicio
        ? new Date(this.info.fecha_mtto_preventivo_inicio)
        : null;
      if (fecha) {
        var todayDate = new Date(fecha).toISOString().slice(0, 10);
        return todayDate;
      }
      return fecha;
    },
    date1() {
      const fecha = this.info.fecha_mtto_preventivo_fin
        ? new Date(this.info.fecha_mtto_preventivo_fin)
        : null;
      if (fecha) {
        var todayDate = new Date(fecha).toISOString().slice(0, 10);
        return todayDate;
      }
      return fecha;
    },
    date2() {
      const fecha = this.info.fecha_mtto_correctivo_inicio
        ? new Date(this.info.fecha_mtto_correctivo_inicio)
        : null;
      if (fecha) {
        var todayDate = new Date(fecha).toISOString().slice(0, 10);
        return todayDate;
      }
      return fecha;
    },
    date3() {
      const fecha = this.info.fecha_mtto_correctivo_fin
        ? new Date(this.info.fecha_mtto_correctivo_fin)
        : null;
      if (fecha) {
        var todayDate = new Date(fecha).toISOString().slice(0, 10);
        return todayDate;
      }
      return fecha;
    },
    dropoptions() {
      const dropoptions = [
        {
          url: require('@/assets/flota/moto.png'),
          alt: '',
          title: 'Moto',
        },
        {
          url: require('@/assets/flota/minivan.png'),
          alt: '',
          title: 'Minivan',
        },
        {
          url: require('@/assets/flota/bus.png'),
          alt: '',
          title: 'Bus',
        },
        {
          url: require('@/assets/flota/semitrailer.png'),
          alt: '',
          title: 'Semitrailer',
        },
        {
          url: require('@/assets/flota/camión.png'),
          alt: '',
          title: 'Camión',
        },
      ];
      return dropoptions;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/main.scss';
.v-card {
  width: 70vw;
  height: 470px;
  overflow: scroll;
  overflow-x: hidden;
  margin: 50px auto;
  padding: 20px;
  .align-form {
    display: flex;
    height: fit-content;
    padding: 20px;
    align-self: center;
    flex-direction: column;
  }
  .datapicker_input::-webkit-inner-spin-button,
  ::-webkit-calendar-picker-indicator {
    filter: drop-shadow(16px 16px 20px rgb(255, 255, 255)) invert(100%);
  }
}

.justify-btn {
  display: flex;
  justify-content: space-around;
}
.v-image {
  margin: 50px auto;
}
.v-card::-webkit-scrollbar {
  width: 4px;
  background: #efeff6;
}
.v-card::-webkit-scrollbar-thumb {
  background: $main;
  border-radius: 10px;
}
</style>
