<template>
  <!-- <div class="bg-container"> -->
  <v-card class="container-card" elevation="2">
    <v-row>
      <v-col class="d-flex justify-center">
        <a href="/">
          <v-img
            :src="require('@/assets/img/nuevoLogo.svg')"
            contain
            width="100%"
          />
        </a>
      </v-col>
    </v-row>
    <v-form v-model="valid">
      <v-text-field
        v-model.trim="user.username"
        label="Usuario"
        required
      ></v-text-field>
      <v-text-field v-model.trim="user.ruc" label="RUC" required></v-text-field>
      <v-text-field
        type="password"
        v-model.trim="user.password"
        label="Contraseña"
        required
      ></v-text-field>
      <div class="captcha_container">
        <vue-recaptcha
          @verify="onVerify"
          @expired="onExpired"
          :sitekey="sitekey"
        >
        </vue-recaptcha>
      </div>
      <!-- <div class="g-recaptcha" :data-sitekey="sitekey"></div> -->
      <div class="container-iniciar_sesion">
        <span>*Si olvidaste tu contraseña, comunícate con soporte</span>
        <v-btn
          class="ma-2"
          outlined
          color="indigo"
          @click="login(user)"
          :disabled="!loginVerified"
        >
          Iniciar sesión
        </v-btn>
      </div>
    </v-form>
  </v-card>
  <!-- </div> -->
</template>
<script>
import VueRecaptcha from 'vue-recaptcha';
import { login } from '@/api/auth/login.js';
import moment from 'moment';
import verificarExistenciaAsistenciasMes from '@/utils/verificarExistenciaAsistenciasMes.js';
import obtenerRolesDelToken from '@/utils/obtenerRolesDelToken.js';

const recaptchaKey = process.env.VUE_APP_RECAPTCHA;

export default {
  name: 'Login',
  data: () => ({
    loginVerified: false,
    valid: false,
    user: {
      ruc: '',
      username: '',
      password: '',
    },
    nameRules: [
      (v) => !!v || 'Campo requerido',
      (v) => v.length >= 5 || 'Mínimo 5 caracteres',
    ],
    emailRules: [
      (v) => !!v || 'Email es requerido',
      (v) => /.+@.+/.test(v) || 'Email debe ser válido',
    ],
  }),
  components: {
    VueRecaptcha,
  },
  computed: {
    sitekey() {
      return recaptchaKey;
    },
  },
  methods: {
    onVerify() {
      this.loginVerified = true;
    },
    onExpired() {
      this.loginVerified = false;
    },
    async login(user) {
      let url = localStorage.getItem('path');
      const { error, message, token } = await login(user);
      if (error) {
        this.$swal.fire({
          title: error,
          text: '',
          icon: 'error',
          timer: 1500,
        });
      }
      if (message && token) {
        localStorage.setItem('token', token);
        this.$store.state.usuarioLogueado = obtenerRolesDelToken(token); // cambiar nombre de la función, devuelve un token decodificado, no solo los roles
        if (!url || url === '/login') {
          this.$router.push({
            path: '/dashboard',
          });
        } else {
          this.$router.push({
            path: url,
          });
        }
        verificarExistenciaAsistenciasMes(
          moment()
          // this
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'src/styles/main.scss';
.captcha_container {
  display: inline-table !important;
}
.bg-container {
  background-size: 100%;
}
.container-card {
  width: 450px;
  margin: calc(50vh - 300px) auto 0px auto;
  padding: 20px;
  .container-iniciar_sesion {
    position: relative;
    top: 40px;
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    span {
      margin: 5px;
      color: $main;
      line-height: 2;
    }
    .v-btn {
      width: 250px;
      background: $tables-bg;
      margin: auto;
      border: 2px solid $main;
      border-radius: 5px;
      color: $main !important;
      font-weight: bold;
    }
  }
}
@media (max-width: 1440px) {
  #bg-video {
    display: none;
  }
  .container-card {
    position: inherit;
    margin: calc(50vh - 200px) auto 100px auto;
  }
}
</style>
