import fetch from 'isomorphic-fetch';

let ENDPOINT_URL = process.env.VUE_APP_FLOTA_READ_ALL;

async function readUnits() {
  const bodyParams = {
    token: localStorage.getItem('token'),
  };

  let body = JSON.stringify(bodyParams);

  return await fetch(ENDPOINT_URL, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  }).then((item) => item.json());
}
export { readUnits };
