<template>
  <v-card class="card_container" :key="renderCard">
    <v-row class="d-flex flex-column justify-center" no-gutters>
      <v-col class="input_search">
        <v-text-field v-model="inputSearch"></v-text-field>
        <v-btn>
          <v-img
            max-width="20px"
            cover
            :src="require('@/assets/menu-icons/search.svg')"
          />
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="drag-container justify-left">
      <v-col cols="12">
        <drag
          v-for="(index, i) in cards"
          :key="i"
          :data="index"
          @cut="moveItem(index)"
        >
          <v-card class="card-servicio justify-left">
            <v-card-title> {{ index.nombre }} </v-card-title
            ><v-card-subtitle class="d-flex">
              {{ index.direccion }}
            </v-card-subtitle>
          </v-card>
        </drag>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { Drag } from 'vue-easy-dnd';
import { getService } from '@/api/services/getServices.js';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';

export default {
  name: 'Servicios',
  components: {
    Drag,
  },
  data: () => ({
    renderCard: 0,
    cards: [],
    inputSearch: '',
    data: {},
    card: [],
  }),
  created() {
    this.getServices();
  },
  watch: {
    inputSearch() {
      this.searchByName();
    },
  },
  methods: {
    moveItem(index) {
      this.cards = this.cards.filter((e) => e !== index);
    },
    async getServices() {
      const { error, services, tokenExpired } = await getService();
      if (error) {
        //do something
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (services) {
        this.data = services;
        this.cards = this.data;
        this.renderCard++;
      }
    },
    // deleteServices(event) {
    //   this.cards.push(event.data);
    // },
    searchByName() {
      const word = this.inputSearch.toLowerCase();
      this.cards = this.data;
      let newArray = [];
      for (let i in this.cards) {
        if (
          this.cards[i].nombre.toLowerCase().includes(word) ||
          this.cards[i].direccion.toLowerCase().includes(word)
        ) {
          newArray.push(this.cards[i]);
        }
      }
      this.cards = newArray;
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'src/styles/main.scss';
.card_container {
  background-color: $secondary !important;
  padding: 10px;
}
.drag-container {
  margin-top: 10px;
  max-height: 72vh;
  overflow: scroll;
  overflow-x: hidden;
  background-color: $secondary !important;
}
.drag-container::-webkit-scrollbar {
  width: 4px;
  background: $secondary;
}

.drag-container::-webkit-scrollbar-thumb {
  background: $main;
  border-radius: 10px;
}
.card-servicio {
  text-align: center;
  margin: 10px;
  width: '100%';
  border: 1px solid $secondary;
}
.input_search {
  position: relative;
  width: 180px;
  left: 150px;
  height: 50px;
  padding-bottom: 15px;
  margin-bottom: 5px;
}
button.v-btn {
  position: relative;
  top: -60px;
  right: -78px;
  box-shadow: none;
  background-color: transparent !important;
  .v-image {
    width: 10px;
  }
}
</style>
