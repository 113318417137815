<template>
  <v-data-table
    :headers="headers"
    :items="parametros"
    class="elevation-1"
    no-data-text="No hay parámetros por configurar..."
    fixed-header
    height="80vh"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.concepto"
                      label="Concepto"
                      readonly
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.monto"
                      label="Monto"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.porcentaje"
                      label="Porcentaje"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancelar
              </v-btn>
              <v-btn color="success" text @click="save">
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.monto`]="{ item }"> S/. {{ item.monto }} </template>
    <template v-slot:[`item.porcentaje`]="{ item }">
      {{ item.porcentaje * 100 }}%
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
    </template>
  </v-data-table>
</template>
<script>
import { mapState } from 'vuex';

import { updateClient } from '@/api/configuration/updateClient.js';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';

export default {
  name: 'Parametros',
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'Concepto',
        value: 'concepto',
      },
      { text: 'Monto', value: 'monto' },
      { text: 'Porcentaje', value: 'porcentaje' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    parametros: [],
    editedIndex: -1,
    editedItem: {
      concepto: '',
      monto: 0,
      porcentaje: 0,
    },
    defaultItem: {
      concepto: '',
      monto: 0,
      porcentaje: 0,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Nuevo parámetro' : 'Editar parámetro';
    },
    ...mapState(['cliente']),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    async updateClient() {
      const { error, tokenExpired, message } = await updateClient(this.cliente);
      if (error) {
        //do something
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (message) {
        this.showSuccessAlert();
      }
    },
    initialize() {
      this.parametros = this.cliente.datos_empresa.parametros;
    },

    editItem(item) {
      this.editedIndex = this.parametros.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.parametros.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.parametros.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.parametros[this.editedIndex], this.editedItem);
      } else {
        this.parametros.push(this.editedItem);
      }
      //validar q los parámetros se guarden como números
      this.parametros.map((parametro) => {
        parametro.monto = Number(parametro.monto);
        parametro.porcentaje = Number(parametro.porcentaje);
        return parametro;
      });
      this.cliente.datos_empresa.parametros = this.parametros;
      this.updateClient();
      this.close();
    },
    showSuccessAlert() {
      this.$swal.fire({
        title: `Operación realizada correctamente!`,
        text: '',
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
  },
};
</script>
