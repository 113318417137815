var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"pt-2",attrs:{"min-height":"400px"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"no-results-text":"No se encontraron registros...","fixed-header":"","height":"83vh","id":"horasLaboradas","headers":_vm.headers,"items":_vm.personal,"loading":_vm.tableLoading,"custom-sort":_vm.customSort,"loading-text":"Cargando reporte de horas laboradas... Espere...","no-data-text":"No hay data aún...","search":_vm.searchPerson},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-toolbar-title',{staticClass:"top-bar-title"},[_vm._v("Horas laboradas")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar persona...","single-line":"","hide-details":""},model:{value:(_vm.searchPerson),callback:function ($$v) {_vm.searchPerson=$$v},expression:"searchPerson"}}),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","origin":"center center","transition":"scale-transition","rounded":"b-xl"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":""}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',{staticClass:"boton-txt",attrs:{"color":"green darken-4","large":""}},[_vm._v("mdi-file-excel")])],1)]}}],null,true)},[_c('span',[_vm._v("Descargar reportes")])])]}}])},[_c('v-list',_vm._l((_vm.tiposReportes),function(tipoReporte,index){return _c('v-list-item',{key:index,attrs:{"link":""},on:{"click":function($event){return _vm.exportarExcel(tipoReporte.value)}}},[_c('v-list-item-title',[_vm._v(_vm._s(tipoReporte.title))])],1)}),1)],1),_c('v-spacer'),_c('v-dialog',{ref:"fechaDialog",attrs:{"return-value":_vm.date,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.dateText(),"label":"Mes de reporte","prepend-icon":"mdi-calendar","readonly":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.fechaModal),callback:function ($$v) {_vm.fechaModal=$$v},expression:"fechaModal"}},[_c('v-date-picker',{attrs:{"type":"month","scrollable":"","color":"teal","locale":"es-ES"},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"blue"},on:{"click":function($event){_vm.fechaModal = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":"","color":"success"},on:{"click":function($event){_vm.cambiarMesReporte(), _vm.$refs.fechaDialog.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1)],1)]},proxy:true},_vm._l((_vm.headers),function(h){return {key:("header." + (h.value)),fn:function(ref){
var header = ref.header;
return [_c('v-row',{key:h.value},[_c('v-row',{staticClass:"d-flex flex-column justify-start",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"align-header"},[_vm._v(" "+_vm._s(header.text.toUpperCase())+" ")]),_c('v-col',{staticClass:"align-header"},[_vm._v(" "+_vm._s(header.value > 0 ? header.value : null)+" ")])],1)],1)]}}}),_vm._l((_vm.semanas),function(semana,l){return {key:("item.semana" + l + ".sumaMinutosTotales"),fn:function(ref){
var item = ref.item;
return [(item[("semana" + l)])?_c('div',{key:l},[_c('v-tooltip',{key:semana[l],attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({on:{"click":function($event){return _vm.verDetalleDeHorasDeUnaSemana(l, item)}}},on),[_vm._v(" "+_vm._s(item[("semana" + l)] ? _vm.formatMinutos(item[("semana" + l)].sumaMinutosTotales) : '')+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(("Ver el detalle de horas de la semana " + (l + 1))))])])],1):_vm._e()]}}}),{key:"item.sumaMinutosTotalesMes",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.sumaMinutosTotalesMes ? _vm.formatMinutos(item.sumaMinutosTotalesMes) : ''))])]}}],null,true)}),_c('VerDetalleHorasLaboradasPorSemana',{attrs:{"asistencia":this.asistenciaADetallar,"indiceSemana":this.indiceSemana}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }