<template>
  <v-card>
    <template>
      <v-tabs v-model="model" centered class="tabs-container">
        <v-tab v-for="n in tabTitles" :key="n">
          {{ n }}
        </v-tab>
      </v-tabs>
    </template>

    <v-tabs-items v-model="model">
      <v-tab-item>
        <Flota />
      </v-tab-item>
      <v-tab-item>
        <Personal />
      </v-tab-item>
      <v-tab-item>
        <Servicios />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import Flota from './Unidad';
import Personal from './Personal';
import Servicios from './Servicios';

export default {
  name: 'IndexSlides',
  data() {
    return {
      tabTitles: ['Flota', 'Personal', 'Clientes'],
      model: 'tab-2',
    };
  },
  components: {
    Flota,
    Personal,
    Servicios,
  },
};
</script>
<style lang="scss" scoped>
@import 'src/styles/main.scss';
.tabs-container {
  background-color: $secondary !important;
}
</style>
