<template>
  <v-container>
    <div class="content">
      <img
        class="logo"
        :src="require('@/assets/img/logoCTIRoads512x512.png')"
      />
      <h1>Formulario de Eliminación de Cuenta</h1>
      <p>
        En la presente solicitud (accesible desde ctiroads.com/eliminar-cuenta)
        se realiza una solicitud al área de soporte técnico para eliminar la
        cuenta y/o los datos que se tengan registrados. Los tipos datos que se
        eliminarán y los tiempos de retención de los mismos, de ser el caso, se
        revisarán en coordinación entre el área de soporte de la empresa y el
        cliente
      </p>
      <p>
        Por favor, rellene el siguiente formulario para solicitar la eliminación
        de su cuenta y sus datos.
      </p>
      <v-form ref="formEliminarCuenta" v-model="valid" lazy-validation>
        <!-- <label for="email">Correo electrónico:</label><br /> -->
        <v-text-field
          type="email"
          id="email"
          name="email"
          required
          label="Email"
          v-model="textoEmail"
          :rules="textoEmailRules"
        /><br />
        <v-text-field
          type="text"
          id="ruc"
          label="RUC de cliente"
          name="ruc"
          required
          v-model="textoRuc"
          :rules="textoRucRules"
        /><br />
        <v-textarea
          id="detalle"
          label="Detalles de la solicitud"
          name="detalle"
          v-model="textoDetalle"
          :rules="textoDetalleRules"
        ></v-textarea
        ><br />
        <input type="hidden" id="uuid" name="uuid" />
        <input type="button" value="Solicitar Eliminación" @click="validate" />
      </v-form>
    </div>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';

import { getClientData } from '@/api/configuration/getClientData.js';
import { crearIssueSoporte } from '@/api/jira/crearIssueSoporte.js';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';

export default {
  name: 'FormularioEliminarCuenta',
  data() {
    return {
      textoEmail: '',
      textoEmailRules: [
        (v) => !!v || 'Email es requerido',
        (v) => /.+@.+/.test(v) || 'Email debe ser válido',
      ],
      textoDetalle: '',
      textoDetalleRules: [(v) => !!v || 'Campo obligatorio'],
      textoRuc: '',
      textoRucRules: [(v) => !!v || 'Campo obligatorio'],
      valid: false,
    };
  },
  computed: {
    ...mapState(['cliente']),
  },
  mounted() {
    this.storeClientData();
  },
  methods: {
    showSuccessAlert(issueID) {
      this.$swal.fire({
        title: `Operación realizada correctamente!`,
        text: `El código de atención generado es: ${issueID}`,
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    async enviarCorreoSoporte() {
      //generar un nro random de atención/ticket de 10 caracteres
      const issueID = Math.random()
        .toString(36)
        .slice(2);

      const { ruc } = this.$store.state.cliente;
      console.log(
        this.textoDetalle,
        this.textoRuc,
        this.textoEmail,
        ruc,
        issueID
      );
      //generar JIRA card con los detalles del contacto
      const response = await crearIssueSoporte(this.textoDetalle, ruc, issueID);

      if (response) {
        this.showSuccessAlert(issueID);
        this.textoEmail = '';
        this.textoRuc = '';
        this.textoDetalle = '';
        this.$router.push({
          path: '/dashboard',
        });
      } else {
        this.showErrorAlert();
      }
    },
    showEliminarCuentaAlert() {
      this.$swal
        .fire({
          title: `Atención!`,
          text: `Va a enviar su requerimiento al área de soporte técnico`,
          icon: 'warning',
          showCancelButton: true,
          cancelButtonColor: 'grey',
          cancelButtonText: 'Cancelar',
          confirmButtonColor: 'success',
          confirmButtonText: 'Enviar',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.enviarCorreoSoporte();
          }
        });
    },
    showErrorAlert() {
      this.$swal
        .fire({
          title: `Atención!!`,
          text: `Este mensaje se muestra porque no se ha iniciado sesión en la aplicación. 
          En la presente solicitud (accesible desde ctiroads.com/eliminar-cuenta) se realiza una solicitud 
          al área de soporte técnico para eliminar la cuenta y/o los datos que se tengan registrados. Los 
          tipos datos que se eliminarán y los tiempos de retención de los mismos, de ser el caso, se 
          revisarán en coordinación entre el área de soporte de la empresa y el cliente`,
          icon: 'warning',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok',
          allowOutsideClick: false,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$router.push({
              path: '/login',
            });
          }
        });
    },
    async storeClientData() {
      const { error, tokenExpired, client } = await getClientData();
      if (error) {
        this.showErrorAlert();
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (client) {
        this.$store.state.cliente = client;
      }
    },
    validate() {
      if (this.$refs.formEliminarCuenta.validate()) {
        //al ser un metodo que elimina una cuenta de usuario se deben tratar los usuarios por separado del usuario principal
        //es por ello que se genera una solicitud al area de soporte y así poder tomar una decisión en coordinación con el cliente
        this.showEliminarCuentaAlert();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/styles/main.scss';

body {
  font-family: Arial, sans-serif;
  background-color: #f4f7fa;
}

.content {
  max-width: 60%;
  margin: auto;
  text-align: center;
}

h1,
p {
  color: #fff;
}

form {
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}

label {
  display: block;
  margin-bottom: 10px;
  color: #555;
  text-align: left;
}

input[type='text'],
input[type='email'],
textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box; /* added line */
}

textarea {
  resize: vertical;
}

input[type='button'] {
  display: block;
  width: 100%;
  padding: 10px;
  color: #fff;
  background-color: #0056a3;
  border: none;
  border-radius: 5px;
}

.logo {
  width: 150px;
  margin-bottom: 20px;
}

@media screen and(max-width: 800px) {
  .row {
    flex-direction: column !important;
    align-items: center !important;
  }
  .col-3 {
    max-width: 100%;
  }
}
@media screen and(min-width: 1441px) {
  .row {
    flex-direction: column !important;
    align-items: center !important;
  }
}
</style>
