<template>
  <v-card height="50vh">
    <v-progress-circular
      v-if="showLoadingDialog"
      :size="180"
      :width="7"
      color="teal"
      indeterminate
    ></v-progress-circular>
    <v-tabs vertical v-else>
      <!-- tab 1 -->
      <v-tab>
        <v-icon left>
          mdi-account
        </v-icon>
        Ingresos
      </v-tab>

      <!-- tab 2 -->
      <v-tab>
        <v-icon left>
          mdi-lock
        </v-icon>
        Conceptos
      </v-tab>

      <!-- tab 3 -->
      <v-tab @click="siguientePaso">
        <v-icon left color="teal">
          mdi-checkbox-marked-outline
        </v-icon>
        Continuar
      </v-tab>

      <!-- tab 4 -->
      <v-tab @click="volverAlPasoAnterior">
        <v-icon left color="red">
          mdi-undo
        </v-icon>
        Anterior
      </v-tab>

      <!-- tab-item 1 -->
      <v-tab-item>
        <v-card flat :key="renderTable">
          <v-card-text v-if="esPlanillaRemuneracion">
            <!-- <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-toolbar-title>
                <v-btn color="teal" dark>Editar jornada</v-btn>
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar> -->
            <v-row>
              <v-spacer></v-spacer>
              <apexchart
                type="bar"
                height="320"
                :options="chartOptionsHorasLaboradas"
                :series="seriesHorasLaboradasTotalesPorPeriodos"
              ></apexchart>
              <v-spacer></v-spacer>
              <apexchart
                type="bar"
                height="320"
                :options="chartOptionsRemuneracion"
                :series="seriesRemuneracionPorPeriodos"
              ></apexchart>
              <v-spacer></v-spacer>
              <apexchart
                type="bar"
                height="320"
                :options="chartOptionsAsignacionFamiliar"
                :series="seriesAsignacionFamiliarPorPeriodos"
              ></apexchart>
              <v-spacer></v-spacer>
            </v-row>
          </v-card-text>

          <v-card-text v-else>
            <v-data-table
              no-results-text="No se encontraron registros..."
              :headers="headers"
              :loading="tableLoading"
              loading-text="Cargando conceptos... Espere..."
              :items="conceptosTabla"
              class="elevation-1"
              :search="search"
              no-data-text="No hay ingresos aún..."
              item-key="codigo"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-spacer></v-spacer>
                  <v-toolbar-title class="top-bar-title"
                    >Ingresos</v-toolbar-title
                  >
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar concepto..."
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-spacer></v-spacer>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- tab-item 2 -->
      <v-tab-item>
        <v-row no-gutters>
          <v-col>
            <v-card flat max-width="475px">
              <v-card-title class="text-h5 justify-center">
                Agregar ingresos por concepto
              </v-card-title>
              <!-- <v-card-subtitle
                ></v-card-subtitle
              > -->
              <v-card-text>
                <v-row class="d-flex flex-column" no-gutters>
                  <!-- <v-spacer></v-spacer> -->
                  <v-col>
                    <v-card-title
                      class="text-subtitle-1 justify-center"
                      style="color: teal; font-weight: bold"
                    >
                      Importar masivamente desde Excel?
                    </v-card-title>
                  </v-col>
                  <v-col>
                    <v-row>
                      <v-spacer></v-spacer>
                      <v-switch
                        v-model="importaDesdeExcel"
                        label="Sí"
                      ></v-switch>
                      <v-spacer></v-spacer>
                    </v-row>
                  </v-col>
                  <!-- <v-spacer></v-spacer> -->
                </v-row>
              </v-card-text>
              <v-card-text v-if="importaDesdeExcel">
                <v-row class="d-flex flex-column" no-gutters>
                  <v-col>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          @click="descargarFormato"
                          v-on="on"
                          fab
                          class="mb-4"
                        >
                          <v-icon color="success"
                            >mdi-cloud-download-outline</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Descargar formato de carga masiva</span>
                    </v-tooltip>
                  </v-col>
                  <v-col>
                    <v-file-input
                      v-model="file"
                      label="Seleccione el archivo de carga masiva"
                      outlined
                      dense
                    ></v-file-input>
                  </v-col>
                  <v-col>
                    <v-btn @click="procesarFile">
                      <v-icon color="success">mdi-check-bold</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text v-else>
                <v-row class="d-flex flex-column" no-gutters>
                  <v-col>
                    <v-autocomplete
                      label="Seleccione el concepto"
                      v-model="conceptoSeleccionado"
                      :items="conceptosIngresos"
                      item-text="nombre"
                      return-object
                    />
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="resumenPersonalSeleccionado"
                      readonly
                      placeholder="Selección del personal a procesar"
                      label="Personal a procesar"
                      @click="abrirSelectorPersonasDialog"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="montoIngresoMasivo"
                      label="Ingrese el monto"
                      outlined
                      type="number"
                      min="0"
                    />
                  </v-col>
                  <v-col>
                    <v-btn @click="procesarIngresos">
                      <v-icon color="success">mdi-check-bold</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

                <SelectorPersonas v-if="selectorPersonas" />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card flat>
              <v-data-table
                no-results-text="No se encontraron registros..."
                :headers="headers"
                :loading="tableLoading"
                loading-text="Cargando conceptos... Espere..."
                :items="conceptosTabla"
                class="elevation-1"
                :search="search"
                no-data-text="No hay ingresos aún..."
                item-key="codigo"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-spacer></v-spacer>
                    <v-toolbar-title class="top-bar-title"
                      >Ingresos</v-toolbar-title
                    >
                    <v-spacer></v-spacer>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Buscar concepto..."
                      single-line
                      hide-details
                    ></v-text-field>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>

      <!-- tab-item 3 -->
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>
              Continuar al siguiente paso...
            </p>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- tab-item 4 -->
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>
              VOLVER AL PASO ANTERIOR
            </p>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';

import { excelToJSON } from '@/plugins/excelToJSON.js';
import { getAssistance } from '@/api/persons/getAssistance.js';
import { getPlanillaPorPeriodoYPorTipo } from '@/api/payments/getPlanillaPorPeriodoYPorTipo.js';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';
import obtenerVacacionesCumplidasYProgramadas from '@/utils/obtenerVacacionesCumplidasYProgramadas.js';
import obtenerVacacionesPendientes from '@/utils/obtenerVacacionesPendientes.js';
import { editarVacaciones } from '@/api/persons/editUserInfo.js';
// import { createUser } from '@/api/persons/createUser.js';
import { setAssistance } from '@/api/persons/newAssistance.js';
import VueApexCharts from 'vue-apexcharts';
import SelectorPersonas from './SelectorPersonas.vue';

export default {
  name: 'Paso2',
  data: () => ({
    // mostrarImportacionDesdeExcel: false,
    asistenciasAEvaluar: [],
    cantidadDiasAsistenciaNoGenerados: 0,
    renderTable: 0,
    tableLoading: false,
    montoIngresoMasivo: 0,
    conceptoSeleccionado: '',
    file: {},
    importaDesdeExcel: false,
    personalEditado: [],
    showLoadingDialog: true,
    dates: [],
    headers: [
      {
        text: 'Código',
        value: 'codigo',
        width: '100px',
      },
      {
        text: 'Concepto',
        value: 'nombre',
      },
      {
        text: 'Monto',
        value: 'montoTotal',
      },
    ],
    selection: 2,
    search: '',
    montoRemuneracionTotalPeriodoActual: 0,
    montoAsignacioTotalPeriodoActual: 0,
    totalHorasLaboradasPeriodoActual: 0,
    seriesHorasLaboradasTotalesPorPeriodos: [],
    seriesRemuneracionPorPeriodos: [],
    seriesAsignacionFamiliarPorPeriodos: [],
    parametroAsignacionFamiliar: {},
    parametroEsSalud: {},
    parametroEsSaludEPS: {},
    porcentajeDescuentoPorEPS: 0,
    periodicidad: 0,
    mesesDelAnio: [
      {
        mes: 'Enero',
        numeroMes: 1,
      },
      {
        mes: 'Febrero',
        numeroMes: 2,
      },
      {
        mes: 'Marzo',
        numeroMes: 3,
      },
      {
        mes: 'Abril',
        numeroMes: 4,
      },
      {
        mes: 'Mayo',
        numeroMes: 5,
      },
      {
        mes: 'Junio',
        numeroMes: 6,
      },
      {
        mes: 'Julio',
        numeroMes: 7,
      },
      {
        mes: 'Agosto',
        numeroMes: 8,
      },
      {
        mes: 'Septiembre',
        numeroMes: 9,
      },
      {
        mes: 'Octubre',
        numeroMes: 10,
      },
      {
        mes: 'Noviembre',
        numeroMes: 11,
      },
      {
        mes: 'Diciembre',
        numeroMes: 12,
      },
    ],
    actualizarPersona: false, // variable para saber si se debe llamar al endpoint de actualización de una persona
  }),
  components: {
    apexchart: VueApexCharts,
    SelectorPersonas,
  },
  computed: {
    esPlanillaRemuneracion() {
      return this.nuevaPlanillaCreada.tipo_proceso === 'Remuneración';
    },
    ...mapState([
      'procesoPlanilla',
      'personalActivo',
      'nuevaPlanillaCreada',
      'cliente',
      'planillaAdelantoDelPeriodo',
      'planillasRemuneracionDelAnioDelPeriodo',
      'planillasVacacionesDelAnioDelPeriodo',
      'conceptosSunat',
      'personalParaProceso',
      'selectorPersonas',
    ]),
    chartOptionsHorasLaboradas() {
      let configuracionGraficos = {
        chart: {
          type: 'bar',
          height: 350,
          width: 360,
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: '16px',
            fontFamily: 'Branding-Medium',
          },
          x: {
            show: true,
          },
          fixed: {
            enabled: true,
            position: 'topRight',
            offsetX: 0,
            offsetY: 0,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '35%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        title: {
          text: 'Horas laboradas',
          align: 'left',
        },
        xaxis: {
          categories: ['Horas laboradas'],
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#008FFB',
            },
            labels: {
              style: {
                colors: '#008FFB',
              },
            },
            title: {
              text: 'Horas totales laboradas',
              style: {
                color: '#008FFB',
              },
            },
          },
        ],
      };
      return configuracionGraficos;
    },
    chartOptionsRemuneracion() {
      let configuracionGraficos = {
        chart: {
          type: 'bar',
          height: 350,
          width: 360,
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: '16px',
            fontFamily: 'Branding-Medium',
          },
          x: {
            show: true,
          },
          y: {
            formatter: function(value) {
              var formatter = new Intl.NumberFormat('es-PE', {
                style: 'currency',
                currency: 'PEN',
              });

              return `${formatter.format(value)}`;
            },
          },
          fixed: {
            enabled: true,
            position: 'topRight',
            offsetX: 0,
            offsetY: 0,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '35%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        title: {
          text: 'Remuneración mensual',
          align: 'left',
        },
        xaxis: {
          categories: ['Total remuneración'],
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: 'green',
            },
            labels: {
              style: {
                colors: 'green',
              },
            },
            title: {
              text: 'Total remuneración (S/.)',
              style: {
                color: 'green',
              },
            },
          },
        ],
      };
      return configuracionGraficos;
    },
    chartOptionsAsignacionFamiliar() {
      let configuracionGraficos = {
        chart: {
          type: 'bar',
          height: 350,
          width: 360,
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: '16px',
            fontFamily: 'Branding-Medium',
          },
          x: {
            show: true,
          },
          y: {
            formatter: function(value) {
              var formatter = new Intl.NumberFormat('es-PE', {
                style: 'currency',
                currency: 'PEN',
              });

              return `${formatter.format(value)}`;
            },
          },
          fixed: {
            enabled: true,
            position: 'topRight',
            offsetX: 0,
            offsetY: 0,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '35%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        title: {
          text: 'Asignación familiar',
          align: 'left',
        },
        xaxis: {
          categories: ['Asignación familiar'],
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: 'red',
            },
            labels: {
              style: {
                colors: 'red',
              },
            },
            title: {
              text: 'Total asignación familiar (S/.)',
              style: {
                color: 'red',
              },
            },
          },
        ],
      };
      return configuracionGraficos;
    },
    conceptosIngresos() {
      let conceptosIngresos = this.cliente.datos_empresa.conceptos_sunat.filter(
        (concepto) =>
          (Number(concepto.codigo) >= 100 && Number(concepto.codigo) < 600) ||
          (Number(concepto.codigo) >= 900 && Number(concepto.codigo) < 1000)
      );
      return conceptosIngresos;
    },
    resumenPersonalSeleccionado() {
      if (this.personalParaProceso.length > 0) {
        return `Total trabajadores seleccionados: ${this.personalParaProceso.length}`;
      }
      return `Total trabajadores seleccionados: 0`;
    },
    conceptosTabla() {
      let arrayIngresos = [];

      // Se consulta si el tipo de planilla es locación o adelanto porque se generan códigos únicos internos para estos procesos
      if (
        this.nuevaPlanillaCreada.tipo_proceso === 'Locación de servicio' ||
        this.nuevaPlanillaCreada.tipo_proceso === 'Adelanto de sueldo'
      ) {
        this.nuevaPlanillaCreada.personal.map((persona) => {
          persona.ingresos_por_concepto.map((ingreso) => {
            let ingresoEncontrado = arrayIngresos.find(
              (ingresoArray) => ingresoArray.codigo === ingreso.codigo
            );
            if (ingresoEncontrado) {
              ingresoEncontrado.montoTotal =
                ingresoEncontrado.montoTotal + ingreso.monto;
            } else {
              arrayIngresos.push({
                nombre:
                  this.nuevaPlanillaCreada.tipo_proceso ===
                  'Locación de servicio'
                    ? 'Locación de servicio'
                    : 'Adelanto de sueldo',
                codigo: ingreso.codigo,
                montoTotal: ingreso.monto,
              });
            }
            return ingreso;
          });
        });
      } else {
        this.nuevaPlanillaCreada.personal.map((persona) => {
          persona.ingresos_por_concepto.map((ingreso) => {
            let ingresoEncontrado = arrayIngresos.find(
              (ingresoArray) => ingresoArray.codigo === ingreso.codigo
            );
            if (ingresoEncontrado) {
              ingresoEncontrado.montoTotal =
                ingresoEncontrado.montoTotal + ingreso.monto;
            } else {
              let nombreConcepto = this.conceptosSunat.find(
                (conceptoSunat) => conceptoSunat.codigo === ingreso.codigo
              );
              if (nombreConcepto) {
                arrayIngresos.push({
                  nombre: nombreConcepto.nombre,
                  codigo: ingreso.codigo,
                  montoTotal: ingreso.monto,
                });
              }
            }
            return ingreso;
          });

          return persona;
        });
      }

      arrayIngresos.map((ingreso) => {
        ingreso.montoTotal = Math.round(ingreso.montoTotal * 100) / 100;
        return ingreso;
      });

      return arrayIngresos;
    },
  },
  mounted() {
    this.parametroAsignacionFamiliar = this.cliente.datos_empresa.parametros.find(
      (parametro) => parametro.concepto === 'ASIGNACION FAMILIAR'
    );
    this.parametroEsSalud = this.cliente.datos_empresa.parametros.find(
      (parametro) => parametro.concepto === 'ESSALUD'
    );
    this.parametroEsSaludEPS = this.cliente.datos_empresa.parametros.find(
      (parametro) => parametro.concepto === 'ESSALUD - EPS'
    );

    this.porcentajeDescuentoPorEPS =
      this.parametroEsSalud.porcentaje - this.parametroEsSaludEPS.porcentaje;

    this.periodicidad = 7;
    if (this.nuevaPlanillaCreada.periodicidad === 'quincenal') {
      this.periodicidad = 15;
    }
    if (this.nuevaPlanillaCreada.periodicidad === 'mensual') {
      this.periodicidad = 30;
    }

    //obtener todas las planillas de remuneración del año para comparar cuadros y poder calcular 5ta categoría
    //siempre y cuando no se esté procesando la planilla de locación de servicio

    switch (this.nuevaPlanillaCreada.tipo_proceso) {
      case 'Locación de servicio':
        this.procesarPlanillaLocacionDeServicio();
        break;

      case 'Adelanto de sueldo':
        this.procesarPlanillaAdelantoDeSueldo();
        break;

      default:
        this.obtenerPlanillasRemuneracionDelAnioDelPeriodo();
        break;
    }
  },
  methods: {
    abrirSelectorPersonasDialog() {
      this.$store.state.selectorPersonas = true;
    },
    procesarIngresos() {
      // validar si es planilla de adelanto con que codigo se agregan estos ingresos
      if (this.personalParaProceso.length === 0) {
        return;
      }
      this.personalParaProceso.map((personaProceso) => {
        let personaEncontrada = this.nuevaPlanillaCreada.personal.find(
          (persona) => persona.doi.toString() === personaProceso.doi.toString()
        );
        if (personaEncontrada) {
          let ingresoEncontrado = personaEncontrada.ingresos_por_concepto.find(
            (ingreso) => ingreso.codigo === this.conceptoSeleccionado.codigo
          );
          if (ingresoEncontrado) {
            ingresoEncontrado.monto =
              ingresoEncontrado.monto + Number(this.montoIngresoMasivo);
          } else {
            personaEncontrada.ingresos_por_concepto.push({
              // codigo: this.conceptoSeleccionado.codigo, revisar para locación cuando sea agregar masivamente por el nombre del concepto
              codigo: this.conceptoSeleccionado.codigo,
              monto: Number(this.montoIngresoMasivo),
            });
          }
        }

        return personaProceso;
      });
      this.showSuccessAlert();
      this.montoIngresoMasivo = 0;
      this.conceptoSeleccionado = '';
      this.$store.state.personalParaProceso = [];
    },
    descargarFormato() {
      window.open(
        'https://cti-roads-assets.s3.us-west-2.amazonaws.com/Formato_carga_masiva_ingresos.xlsx'
      );
    },
    showSuccessAlert() {
      this.$swal.fire({
        title: `Operación realizada correctamente!`,
        text: '',
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    async procesarFile() {
      // validar si es planilla de adelanto con que codigo se agregan estos ingresos
      if (this.file.size > 0) {
        this.showLoadingDialog = true;
        let conceptosIngresosFiltrados = this.conceptosSunat.filter(
          (concepto) =>
            (Number(concepto.codigo) >= 100 && Number(concepto.codigo) < 600) ||
            (Number(concepto.codigo) >= 900 && Number(concepto.codigo) < 1000)
        );
        const arrayFilasExcel = await excelToJSON(this.file);
        arrayFilasExcel.map((filaExcel) => {
          let existeCodigoIngreso = conceptosIngresosFiltrados.find(
            (concepto) => concepto.codigo === '0' + filaExcel.Codigo_concepto
          );
          if (existeCodigoIngreso) {
            let personaEncontrada = this.nuevaPlanillaCreada.personal.find(
              (trabajador) =>
                trabajador.doi.toString() === filaExcel.No_Documento.toString()
            );
            if (personaEncontrada) {
              //filtrar concpeto de ingreso y añadir el monto
              let ingresoEncontrado = personaEncontrada.ingresos_por_concepto.find(
                (ingreso) => ingreso.codigo === '0' + filaExcel.Codigo_concepto
              );
              if (ingresoEncontrado) {
                ingresoEncontrado.monto =
                  ingresoEncontrado.monto + Number(filaExcel.Monto);
              } else {
                personaEncontrada.ingresos_por_concepto.push({
                  codigo: '0' + filaExcel.Codigo_concepto,
                  monto: Number(filaExcel.Monto),
                });
              }
            }
          }
          return filaExcel;
        });
        this.showLoadingDialog = false;
        this.showSuccessAlert();
        this.file = {};
      }
    },
    async obtenerPlanillasRemuneracionDelAnioDelPeriodo() {
      const anioPeriodo = this.nuevaPlanillaCreada.periodo.split('-')[1];
      const {
        error,
        planillas,
        tokenExpired,
      } = await getPlanillaPorPeriodoYPorTipo(anioPeriodo, 'Remuneración');
      if (error) {
        //do something
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (planillas) {
        // console.log(planillas);
        this.$store.state.planillasRemuneracionDelAnioDelPeriodo = planillas;
        switch (this.nuevaPlanillaCreada.tipo_proceso) {
          case 'Remuneración':
            this.procesarPlanillaRemuneracion();
            break;
          case 'CTS':
            this.procesarPlanillaCTS();
            break;
          case 'Gratificación':
            this.procesarPlanillaGratificacion();
            break;
          case 'Liquidación':
            this.procesarPlanillaLiquidacion();
            break;
          // case 'Utilidades':
          //   this.procesarPlanillaUtilidades();
          //   break;
          case 'Vacaciones':
            this.procesarPlanillaVacaciones();
            break;
          default:
            this.showErrorAlert(
              'No se ha seleccionado un tipo de planilla válido'
            );
            break;
        }
      }
    },
    async obtenerPlanillaAdelantoDelPeriodo() {
      const {
        error,
        planillas,
        tokenExpired,
      } = await getPlanillaPorPeriodoYPorTipo(
        this.nuevaPlanillaCreada.periodo,
        'Adelanto de sueldo'
      );
      if (error) {
        //do something
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (planillas) {
        this.$store.state.planillaAdelantoDelPeriodo = planillas;
      }
    },
    async obtenerPlanillasVacacionesDelAnioDelPeriodo() {
      const anioPeriodo = this.nuevaPlanillaCreada.periodo.split('-')[1];
      const {
        error,
        planillas,
        tokenExpired,
      } = await getPlanillaPorPeriodoYPorTipo(anioPeriodo, 'Vacaciones');
      if (error) {
        //do something
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (planillas) {
        // console.log(planillas);
        this.$store.state.planillasVacacionesDelAnioDelPeriodo = planillas;
      }
    },
    verificarExistenciaDeAsistencia(fecha) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            title: `No existe asistencia creada para el día ${fecha.format(
              'DD/MM/YYYY'
            )} aún. Desea generarla en este momento?`,
            text: `Si no se genera este registro de asistencia, este día no se considera como parte del cálculo`,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonColor: 'grey',
            cancelButtonText: 'Cancelar',
            confirmButtonColor: 'success',
            confirmButtonText: 'Generar',
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              const obs = `Asistencia del día: ${moment(fecha)
                .tz('America/Lima')
                .locale('es')
                .format('L')}`;
              const { asistencia, tokenExpired } = await setAssistance(
                moment(fecha).tz('America/Lima'),
                obs
              );
              if (tokenExpired) {
                routeToLoginIfTokenIsExpired(this);
              }
              if (asistencia) {
                this.asistenciasAEvaluar.push(asistencia);
              }
            } else {
              //si no se genera la asistencia, se deben descontar la cantidad de días que NO se generaron
              this.cantidadDiasAsistenciaNoGenerados++;
            }
            resolve('resolved');
          });
      });
    },
    async procesarPlanillaRemuneracion() {
      // se obtiene la planilla de adelanto de este periodo si existe para considerar el descuento por el adelanto
      await this.obtenerPlanillaAdelantoDelPeriodo(); // considerar el ingreso por remuneracion y descontar el concepto adelanto, tmb verificar
      await this.obtenerPlanillasVacacionesDelAnioDelPeriodo();

      const planillasRemuneracionDeEstePeriodo = this.planillasRemuneracionDelAnioDelPeriodo.filter(
        (planilla) => planilla.periodo === this.nuevaPlanillaCreada.periodo
      );
      const fechaInicio = moment(this.procesoPlanilla.fechaInicio).tz(
        'America/Lima'
      );
      const fechaFin = moment(this.procesoPlanilla.fechaFin).tz('America/Lima');

      let fechaPlanilla = moment(this.procesoPlanilla.fechaInicio).tz(
        'America/Lima'
      );

      this.asistenciasAEvaluar = [];

      //evaluar la asistencia en el rango de fechas seleccionado al crear la planilla
      const cantidadDiasPeriodoPlanilla =
        fechaFin.diff(fechaInicio, 'days') + 1;

      //variables para cálculo de totales sumados dentro del loop por persona
      let montoRemuneracionTotalPeriodo = 0;
      let montoAsignacionFamiliarTotalPeriodo = 0;
      let totalMinutosLaborados = 0;
      //-----------------------------------------------

      for (let dia = 1; dia <= cantidadDiasPeriodoPlanilla; dia++) {
        // let fechaPlanilla = fechaInicio;
        // console.log('fechaPlanilla: ', fechaPlanilla.format());

        const { error, tokenExpired, asistencia } = await getAssistance(
          fechaPlanilla.format()
        );
        if (error) {
          await this.verificarExistenciaDeAsistencia(fechaPlanilla);
        }
        if (tokenExpired) {
          routeToLoginIfTokenIsExpired(this);
        }
        if (asistencia) {
          this.asistenciasAEvaluar.push(asistencia);
        }
        fechaPlanilla = fechaPlanilla.add(1, 'days');
      }

      this.nuevaPlanillaCreada.personal.map((persona) => {
        //inicializar el registro de días
        if (!persona.info_dias) persona.info_dias = [];

        const montoRemuneracionDiaria =
          Number(persona.remuneracion_mensual) / 30;

        // const montoAsignacionFamiliarDiaria =
        //   this.parametroAsignacionFamiliar.monto / 30;

        if (persona.ingresos_por_concepto.length === 0) {
          persona.ingresos_por_concepto = [
            {
              codigo: '0121',
              monto:
                this.asistenciasAEvaluar.length > 0
                  ? (persona.remuneracion_mensual / 30) * this.periodicidad
                  : 0,
            },
          ];
          // se debe crear con el monto total pero validar que ésta no sea la segunda planilla q se procesa en el mismo periodo
          if (
            this.cliente.datos_empresa.regimen_laboral !== 'MICRO EMPRESA' &&
            planillasRemuneracionDeEstePeriodo.length === 0
          ) {
            if (persona.asignacion_familiar === 'SI') {
              persona.ingresos_por_concepto.push({
                codigo: '0201',
                monto: this.parametroAsignacionFamiliar.monto,
                // this.asistenciasAEvaluar.length > 0
                //   // ? this.parametroAsignacionFamiliar.monto
                //   (this.parametroAsignacionFamiliar.monto / 30) *
                //   this.periodicidad
                // : 0,
              });
            }
          }
          //descontar la cantidad de días en los q no existe una planilla creada
          if (this.asistenciasAEvaluar.length > 0) {
            persona.cantidad_dias_efectivos = cantidadDiasPeriodoPlanilla;
            persona.cantidad_dias_efectivos_beneficios_sociales = this.periodicidad;
          } else {
            persona.cantidad_dias_efectivos = 0;
            persona.cantidad_dias_efectivos_beneficios_sociales = 0;
          }
        }

        //validar si existen asistencias NO generadas y descontar según corresponda (para adelantar asistencias)
        if (this.cantidadDiasAsistenciaNoGenerados > 0) {
          persona.cantidad_dias_efectivos =
            persona.cantidad_dias_efectivos -
            this.cantidadDiasAsistenciaNoGenerados;
          persona.cantidad_dias_efectivos_beneficios_sociales =
            persona.cantidad_dias_efectivos_beneficios_sociales -
            this.cantidadDiasAsistenciaNoGenerados;
          persona.ingresos_por_concepto.map((ingreso) => {
            if (ingreso.codigo === '0121') {
              ingreso.monto =
                ingreso.monto -
                montoRemuneracionDiaria *
                  this.cantidadDiasAsistenciaNoGenerados;
              if (ingreso.monto < 0) {
                ingreso.monto = 0;
              }
            }
            //solo se descontaría toda al AF si no existen días efectivos laborados (más adelante en este loop)
            // if (
            //   this.cliente.datos_empresa.regimen_laboral !== 'MICRO EMPRESA'
            // ) {
            //   if (ingreso.codigo === '0201') {
            //     if (persona.asignacion_familiar === 'SI') {
            //       // validar que el monto se convierta en 0 solo sí no existen dias efectivos
            //       ingreso.monto =
            //         ingreso.monto -
            //         montoAsignacionFamiliarDiaria *
            //           this.cantidadDiasAsistenciaNoGenerados;
            //       if (ingreso.monto < 0) {
            //         ingreso.monto = 0;
            //       }
            //     }
            //   }
            // }
            return ingreso;
          });
        }

        this.asistenciasAEvaluar.map((asistencia) => {
          let personaEncontrada = asistencia.personal.find(
            (asistenciaPersona) =>
              asistenciaPersona.doi.toString() === persona.doi.toString()
          );

          const esHoyFeriadoODescansoPagado = this.consultarFeriadoODescansoPagado(
            asistencia.fecha_asistencia
          );

          if (personaEncontrada) {
            if (
              moment(persona.fecha_ingreso) <=
              moment(asistencia.fecha_asistencia)
            ) {
              //si se coloca un bloqueo en las asistencias (q no se puedan agregar personas cesadas a las asistencias) entonces lo sgte es doble seguridad
              if (
                !!persona.fecha_cese &&
                moment(persona.fecha_cese) < moment(asistencia.fecha_asistencia)
              ) {
                persona.cantidad_dias_efectivos--;

                persona.cantidad_dias_efectivos_beneficios_sociales--;

                persona.ingresos_por_concepto.map((ingreso) => {
                  if (ingreso.codigo === '0121') {
                    ingreso.monto = ingreso.monto - montoRemuneracionDiaria;
                    if (ingreso.monto < 0) {
                      ingreso.monto = 0;
                    }
                  }
                  //se calculará al final del loop si no existen diás efectivos laborados
                  // if (
                  //   this.cliente.datos_empresa.regimen_laboral !==
                  //   'MICRO EMPRESA'
                  // ) {
                  //   if (ingreso.codigo === '0201') {
                  //     if (persona.asignacion_familiar === 'SI') {
                  //       ingreso.monto =
                  //         ingreso.monto - montoAsignacionFamiliarDiaria;
                  //       if (ingreso.monto < 0) {
                  //         ingreso.monto = 0;
                  //       }
                  //     }
                  //   }
                  // }
                  return ingreso;
                });

                return persona;
              }

              //considerar descanso por defecto si no hay marcaciones (tipo_jornada)
              if (
                !personaEncontrada.tipo_jornada ||
                personaEncontrada.tipo_jornada === 'D'
              ) {
                personaEncontrada.tipo_jornada = 'Descanso';
              }

              //Registro de horas si existen
              let registroPlanillaPorDia = {
                tipo_jornada: personaEncontrada.tipo_jornada,
                fecha: moment(asistencia.fecha_asistencia)
                  .tz('America/Lima')
                  .format(),
              };

              if (
                personaEncontrada.hora_inicio_jornada &&
                personaEncontrada.hora_fin_jornada &&
                personaEncontrada.hora_inicio_jornada !== '' &&
                personaEncontrada.hora_fin_jornada !== ''
              ) {
                let prefijoFecha = asistencia.fecha_asistencia.substring(0, 11);

                let totalMinutos = moment(
                  `${prefijoFecha}${personaEncontrada.hora_fin_jornada}:00.000Z`
                ).diff(
                  moment(
                    `${prefijoFecha}${personaEncontrada.hora_inicio_jornada}:00.000Z`
                  ),
                  'minutes'
                );

                let minutosAlmuerzo = '';
                if (
                  personaEncontrada.hora_inicio_almuerzo &&
                  personaEncontrada.hora_fin_almuerzo &&
                  personaEncontrada.hora_inicio_almuerzo !== '' &&
                  personaEncontrada.hora_fin_almuerzo !== ''
                ) {
                  minutosAlmuerzo = moment(
                    `${prefijoFecha}${personaEncontrada.hora_fin_almuerzo}:00.000Z`
                  ).diff(
                    moment(
                      `${prefijoFecha}${personaEncontrada.hora_inicio_almuerzo}:00.000Z`
                    ),
                    'minutes'
                  );
                }

                (registroPlanillaPorDia.horas_laboradas = {
                  hora_programada: personaEncontrada.hora_programada,
                  hora_inicio_jornada: personaEncontrada.hora_inicio_jornada,
                  hora_fin_jornada: personaEncontrada.hora_fin_jornada,
                  hora_inicio_almuerzo: personaEncontrada.hora_inicio_almuerzo,
                  hora_fin_almuerzo: personaEncontrada.hora_fin_almuerzo,
                }),
                  (registroPlanillaPorDia.total_minutos_laborados =
                    totalMinutos - minutosAlmuerzo);
              } else {
                registroPlanillaPorDia.total_minutos_laborados = 0;
              }

              persona.info_dias.push(registroPlanillaPorDia);

              //Verificar si la persona asistió en día feriado o descanso pagado
              if (
                (personaEncontrada.tipo_jornada === 'Asistencia' ||
                  personaEncontrada.tipo_jornada === 'A' ||
                  personaEncontrada.tipo_jornada === 'Tardanza') &&
                esHoyFeriadoODescansoPagado
              ) {
                // console.log('feriado', moment(asistencia.fecha_asistencia));
                // agregar monto equivalente a un día de remuneración por concepto Sunat 0308
                let ingresoEncontrado = persona.ingresos_por_concepto.find(
                  (ingreso) => ingreso.codigo === '0308'
                );
                if (ingresoEncontrado) {
                  ingresoEncontrado.monto =
                    ingresoEncontrado.monto + montoRemuneracionDiaria;
                } else {
                  persona.ingresos_por_concepto.push({
                    codigo: '0308',
                    monto: montoRemuneracionDiaria,
                  });
                }
              }

              //Calcular dias efectivos y de beneficios sociales basados en los tipos de jornada
              if (
                personaEncontrada.tipo_jornada !== 'Asistencia' &&
                personaEncontrada.tipo_jornada !== 'A' &&
                personaEncontrada.tipo_jornada !== 'Tardanza' &&
                personaEncontrada.tipo_jornada !== 'Descanso'
              ) {
                if (this.nuevaPlanillaCreada.tipo_calculo === 'horas') {
                  //agregar cálculo por horas usando tmb el concepto de tardanza
                }
                persona.cantidad_dias_efectivos--;

                persona.ingresos_por_concepto.map((ingreso) => {
                  if (ingreso.codigo === '0121') {
                    ingreso.monto = ingreso.monto - montoRemuneracionDiaria;
                    if (ingreso.monto < 0) {
                      ingreso.monto = 0;
                    }
                  }
                  //no se descontará la AF por día, solo se descuenta todo si no existe ningún dia efectivo laborado
                  // if (
                  //   this.cliente.datos_empresa.regimen_laboral !==
                  //   'MICRO EMPRESA'
                  // ) {
                  //   if (ingreso.codigo === '0201') {
                  //     if (persona.asignacion_familiar === 'SI') {
                  //       ingreso.monto =
                  //         ingreso.monto - montoAsignacionFamiliarDiaria;
                  //       if (ingreso.monto < 0) {
                  //         ingreso.monto = 0;
                  //       }
                  //     }
                  //   }
                  // }
                  return ingreso;
                });

                if (personaEncontrada.tipo_jornada === 'Descanso médico') {
                  // agregar monto de un día a descansos médicos porque ya se descontó de remuneración
                  let ingresoEncontrado = persona.ingresos_por_concepto.find(
                    (ingreso) => ingreso.codigo === '0916'
                  );
                  if (ingresoEncontrado) {
                    ingresoEncontrado.monto =
                      ingresoEncontrado.monto + montoRemuneracionDiaria;
                  } else {
                    persona.ingresos_por_concepto.push({
                      codigo: '0916',
                      monto: montoRemuneracionDiaria,
                    });
                  }
                }
                if (
                  personaEncontrada.tipo_jornada === 'Licencia por maternidad'
                ) {
                  //915
                  let ingresoEncontrado = persona.ingresos_por_concepto.find(
                    (ingreso) => ingreso.codigo === '0915'
                  );
                  if (ingresoEncontrado) {
                    ingresoEncontrado.monto =
                      ingresoEncontrado.monto + montoRemuneracionDiaria;
                  } else {
                    persona.ingresos_por_concepto.push({
                      codigo: '0915',
                      monto: montoRemuneracionDiaria,
                    });
                  }
                }
                if (
                  personaEncontrada.tipo_jornada ===
                    'Licencia por paternidad' ||
                  personaEncontrada.tipo_jornada ===
                    'Licencia con goce de haber' ||
                  personaEncontrada.tipo_jornada ===
                    'Licencia por fallecimiento'
                ) {
                  //907
                  let ingresoEncontrado = persona.ingresos_por_concepto.find(
                    (ingreso) => ingreso.codigo === '0907'
                  );
                  if (ingresoEncontrado) {
                    ingresoEncontrado.monto =
                      ingresoEncontrado.monto + montoRemuneracionDiaria;
                  } else {
                    persona.ingresos_por_concepto.push({
                      codigo: '0907',
                      monto: montoRemuneracionDiaria,
                    });
                  }
                }
                //estos conceptos se utilizarán para desconectar de los días efectivos para generar cálculos de  CTS, Gratificación
                if (
                  personaEncontrada.tipo_jornada === 'Falto' ||
                  personaEncontrada.tipo_jornada === 'F' ||
                  personaEncontrada.tipo_jornada === 'S' ||
                  personaEncontrada.tipo_jornada === 'Suspensión' ||
                  // personaEncontrada.tipo_jornada === 'V' ||
                  // personaEncontrada.tipo_jornada === 'Vacaciones' ||
                  personaEncontrada.tipo_jornada ===
                    'Permiso sin goce de haber' ||
                  personaEncontrada.tipo_jornada ===
                    'Licencia por fallecimiento'
                ) {
                  //aqui se debe descontar del total de dias computables para beneficios sociales
                  persona.cantidad_dias_efectivos_beneficios_sociales--;
                }
              }
            }
          } else {
            //si la persona no se encuentra en la asistencia evaluada
            // console.log(persona);
            // evaluar tambien si no se encuentra por el hecho de estar cesado
            if (!persona.info_dias) persona.info_dias = [];

            // const montoRemuneracionDiaria =
            //   Number(persona.remuneracion_mensual) /
            //   cantidadDiasPeriodoPlanilla;

            // const montoAsignacionFamiliarDiaria =
            //   this.parametroAsignacionFamiliar.monto /
            //   cantidadDiasPeriodoPlanilla;

            // persona.cantidad_dias_efectivos = persona.cantidad_dias_efectivos--;
            persona.cantidad_dias_efectivos--;

            persona.cantidad_dias_efectivos_beneficios_sociales--;

            persona.ingresos_por_concepto.map((ingreso) => {
              if (ingreso.codigo === '0121') {
                ingreso.monto = ingreso.monto - montoRemuneracionDiaria;
                if (ingreso.monto < 0) {
                  ingreso.monto = 0;
                }
              }
              //esta validación se hace al final del loop
              // if (
              //   this.cliente.datos_empresa.regimen_laboral !== 'MICRO EMPRESA'
              // ) {
              //   if (ingreso.codigo === '0201') {
              //     if (persona.asignacion_familiar === 'SI') {
              //       ingreso.monto =
              //         ingreso.monto - montoAsignacionFamiliarDiaria;
              //       if (ingreso.monto < 0) {
              //         ingreso.monto = 0;
              //       }
              //     }
              //   }
              // }
              return ingreso;
            });
          }

          return asistencia;
        });

        //lógica cuando no existan días efectivos laborados
        if (persona.cantidad_dias_efectivos <= 0) {
          persona.cantidad_dias_efectivos = 0;
          //limpiar ingresos por rem y asign si existen

          persona.ingresos_por_concepto.map((ingreso) => {
            if (ingreso.codigo === '0121') {
              ingreso.monto = 0;
            }
            if (
              this.cliente.datos_empresa.regimen_laboral !== 'MICRO EMPRESA'
            ) {
              if (ingreso.codigo === '0201') {
                if (persona.asignacion_familiar === 'SI') {
                  ingreso.monto = 0;
                }
              }
            }
            return ingreso;
          });
        }
        if (persona.cantidad_dias_efectivos_beneficios_sociales < 0) {
          persona.cantidad_dias_efectivos_beneficios_sociales = 0;
        }
        //calcular montos de ingresos fijos
        // agregar ingreso por concepto de ingreso fijo codigos 100 y 900
        let cantidadDeDiasAMultiplicar =
          persona.cantidad_dias_efectivos > this.periodicidad
            ? this.periodicidad
            : cantidadDiasPeriodoPlanilla === 31
            ? persona.cantidad_dias_efectivos - 1
            : persona.cantidad_dias_efectivos;
        if (
          Number(persona.monto_ingreso_fijo1) > 0 &&
          persona.concepto_ingreso_fijo1 != ''
        ) {
          let codigoIngreso = this.conceptosSunat.find(
            (ingreso) => ingreso.nombre === persona.concepto_ingreso_fijo1
          );
          if (codigoIngreso) {
            let ingresoEncontrado = persona.ingresos_por_concepto.find(
              (ingreso) => ingreso.codigo === codigoIngreso.codigo
            );
            let montoIngresoFijo1 = 0;
            if (persona.switchIngresoFijo1) {
              montoIngresoFijo1 =
                (Number(persona.monto_ingreso_fijo1) / 30) *
                cantidadDeDiasAMultiplicar;
            } else {
              montoIngresoFijo1 =
                (Number(persona.monto_ingreso_fijo1) / 30) * this.periodicidad;
            }
            if (ingresoEncontrado) {
              ingresoEncontrado.monto =
                ingresoEncontrado.monto + montoIngresoFijo1;
            } else {
              persona.ingresos_por_concepto.push({
                codigo: codigoIngreso.codigo,
                monto: montoIngresoFijo1,
              });
            }
          }
        }
        if (
          Number(persona.monto_ingreso_fijo2) > 0 &&
          persona.concepto_ingreso_fijo2 != ''
        ) {
          let codigoIngreso = this.conceptosSunat.find(
            (ingreso) => ingreso.nombre === persona.concepto_ingreso_fijo2
          );
          if (codigoIngreso) {
            let ingresoEncontrado = persona.ingresos_por_concepto.find(
              (ingreso) => ingreso.codigo === codigoIngreso.codigo
            );
            let montoIngresoFijo2 = 0;
            if (persona.switchIngresoFijo2) {
              montoIngresoFijo2 =
                (Number(persona.monto_ingreso_fijo2) / 30) *
                cantidadDeDiasAMultiplicar;
            } else {
              montoIngresoFijo2 =
                (Number(persona.monto_ingreso_fijo2) / 30) * this.periodicidad;
            }
            if (ingresoEncontrado) {
              ingresoEncontrado.monto =
                ingresoEncontrado.monto + montoIngresoFijo2;
            } else {
              persona.ingresos_por_concepto.push({
                codigo: codigoIngreso.codigo,
                monto: montoIngresoFijo2,
              });
            }
          }
        }
        if (
          Number(persona.monto_ingreso_fijo3) > 0 &&
          persona.concepto_ingreso_fijo3 != ''
        ) {
          let codigoIngreso = this.conceptosSunat.find(
            (ingreso) => ingreso.nombre === persona.concepto_ingreso_fijo3
          );
          if (codigoIngreso) {
            let ingresoEncontrado = persona.ingresos_por_concepto.find(
              (ingreso) => ingreso.codigo === codigoIngreso.codigo
            );
            let montoIngresoFijo3 = 0;
            if (persona.switchIngresoFijo3) {
              montoIngresoFijo3 =
                (Number(persona.monto_ingreso_fijo3) / 30) *
                cantidadDeDiasAMultiplicar;
            } else {
              montoIngresoFijo3 =
                (Number(persona.monto_ingreso_fijo3) / 30) * this.periodicidad;
            }
            if (ingresoEncontrado) {
              ingresoEncontrado.monto =
                ingresoEncontrado.monto + montoIngresoFijo3;
            } else {
              persona.ingresos_por_concepto.push({
                codigo: codigoIngreso.codigo,
                monto: montoIngresoFijo3,
              });
            }
          }
        }

        let remuneracion = persona.ingresos_por_concepto.find(
          (ingreso) => ingreso.codigo === '0121'
        );
        if (remuneracion) {
          montoRemuneracionTotalPeriodo =
            montoRemuneracionTotalPeriodo + remuneracion.monto;
        }

        let asignacion = persona.ingresos_por_concepto.find(
          (ingreso) => ingreso.codigo === '0201'
        );
        if (asignacion) {
          montoAsignacionFamiliarTotalPeriodo =
            montoAsignacionFamiliarTotalPeriodo + asignacion.monto;
        }

        if (!persona.info_dias) {
          persona.info_dias = [];
        }
        persona.info_dias.map((dia) => {
          totalMinutosLaborados =
            totalMinutosLaborados + dia.total_minutos_laborados;
          return dia;
        });

        return persona;
      });

      // console.log(this.nuevaPlanillaCreada);
      // console.log(montoRemuneracionTotalPeriodo);
      this.montoRemuneracionTotalPeriodoActual =
        Math.round(montoRemuneracionTotalPeriodo * 100) / 100;

      this.montoAsignacioTotalPeriodoActual =
        Math.round(montoAsignacionFamiliarTotalPeriodo * 100) / 100;

      //calcular el total de horas laboradas diviendo la cantidad de minutos / 60
      this.totalHorasLaboradasPeriodoActual = totalMinutosLaborados / 60;
      this.totalHorasLaboradasPeriodoActual =
        Math.round(this.totalHorasLaboradasPeriodoActual * 100) / 100;

      // let restoDeMinutos = totalMinutosLaborados % 60;

      // console.log(
      //   this.totalHorasLaboradasPeriodoActual,
      //   restoDeMinutos,
      //   totalMinutosLaborados
      // );

      this.prepararGraficos();
    },
    async procesarPlanillaCTS() {
      // console.log('procesar planilla CTS');
      //1.- Validar si es Mayo o Noviembre para poder identificar que periodos buscar
      const mesPeriodo = this.nuevaPlanillaCreada.periodo.split('-')[0];
      const anioPeriodo = this.nuevaPlanillaCreada.periodo.split('-')[1];
      // crear el array con las 6 planillas de remuneracion
      let periodosPlanilla = [];

      let fechaMinimaIngreso = moment();

      if (mesPeriodo === 'Mayo') {
        //lógica para buscar planillas basado en mayo
        fechaMinimaIngreso = moment({
          year: Number(anioPeriodo),
          month: 3,
          day: 1,
        })
          .tz('America/Lima')
          .startOf('month'); // 01/04/2021
        //buscar de Noviembre a Abril por rango de fechas
        for (let index = 4; index > 0; index--) {
          // inicializar el contador de meses en Abril u Octubre

          const mesDelAnio = this.mesesDelAnio.find(
            (mes) => mes.numeroMes === index
          );

          const planillasEnUnPeriodo = this.planillasRemuneracionDelAnioDelPeriodo.filter(
            (planilla) => planilla.periodo.split('-')[0] === mesDelAnio.mes
          );

          if (planillasEnUnPeriodo.length > 0) {
            let periodoEditado = {
              personal: [],
              periodo: '',
              fecha_inicio: '',
              fecha_fin: '',
            };

            planillasEnUnPeriodo.map((planilla) => {
              if (periodoEditado.personal.length === 0) {
                (periodoEditado.personal = planilla.personal),
                  (periodoEditado.periodo = planilla.periodo),
                  (periodoEditado.fecha_inicio = planilla.fecha_inicio),
                  (periodoEditado.fecha_fin = planilla.fecha_fin);
              } else {
                planilla.personal.map((persona) => {
                  const personaEncontrada = periodoEditado.personal.find(
                    (trabajador) =>
                      trabajador.doi.toString() === persona.doi.toString()
                  );

                  if (personaEncontrada) {
                    personaEncontrada.ingresos_por_concepto.map(
                      (personaEncontradaIngreso) => {
                        let ingresoEncontrado = persona.ingresos_por_concepto.find(
                          (ingreso) =>
                            ingreso.codigo === personaEncontradaIngreso.codigo
                        );

                        if (ingresoEncontrado) {
                          personaEncontradaIngreso.monto =
                            personaEncontradaIngreso.monto +
                            Number(ingresoEncontrado.monto);
                        } else {
                          personaEncontrada.ingresos_por_concepto.push(
                            ingresoEncontrado
                          );
                        }
                        return personaEncontradaIngreso;
                      }
                    );

                    personaEncontrada.cantidad_dias_efectivos_beneficios_sociales =
                      personaEncontrada.cantidad_dias_efectivos_beneficios_sociales +
                      persona.cantidad_dias_efectivos_beneficios_sociales;
                  } else {
                    periodoEditado.personal.push(persona);
                  }

                  return persona;
                });
                periodoEditado.periodo = planilla.periodo;
                if (periodoEditado.fecha_inicio === '') {
                  periodoEditado.fecha_inicio = planilla.fecha_inicio;
                }
                periodoEditado.fecha_fin = planilla.fecha_fin;
              }

              return planilla;
            });

            periodosPlanilla.push(periodoEditado);
          }
        }
        // console.log(periodosPlanilla);
        const anioPasado = Number(anioPeriodo) - 1;
        //buscar la planilla de remuneración de Diciembre del anioPeriodo - 1
        const Diciembre = this.mesesDelAnio.find((mes) => mes.numeroMes === 12);
        const {
          planillas: planillaDiciembreAnioPasado,
        } = await getPlanillaPorPeriodoYPorTipo(
          Diciembre.mes + '-' + anioPasado,
          'Remuneración'
        );
        if (planillaDiciembreAnioPasado) {
          //si vienen más de 1 planilla de REMUNERACION entonces aplicar el mismo cálculo q el paso anterior (plkanillas de Abril a Enero)
          periodosPlanilla.push(...planillaDiciembreAnioPasado);
        }
        //buscar la planilla de remuneración de Noviembre del anioPeriodo - 1
        const Noviembre = this.mesesDelAnio.find((mes) => mes.numeroMes === 11);
        const {
          planillas: planillaNoviembreAnioPasado,
        } = await getPlanillaPorPeriodoYPorTipo(
          Noviembre.mes + '-' + anioPasado,
          'Remuneración'
        );
        if (planillaNoviembreAnioPasado) {
          //si vienen más de 1 planilla de REMUNERACION entonces aplicar el mismo cálculo q el paso anterior (plkanillas de Abril a Enero)
          periodosPlanilla.push(...planillaNoviembreAnioPasado);
        }
        //buscar la planilla de gratificación de Diciembre del anioPeriodo - 1
        const {
          planillas: planillaGratificacionDiciembreAnioPasado,
        } = await getPlanillaPorPeriodoYPorTipo(
          Diciembre.mes + '-' + anioPasado,
          'Gratificación'
        );
        if (planillaGratificacionDiciembreAnioPasado) {
          periodosPlanilla.push(...planillaGratificacionDiciembreAnioPasado);
        }
      }
      // console.log(this.nuevaPlanillaCreada);

      if (mesPeriodo === 'Noviembre') {
        fechaMinimaIngreso = moment({
          year: Number(anioPeriodo),
          month: 9, //octubre
          day: 1,
        })
          .tz('America/Lima')
          .startOf('month');
        for (let index = 10; index > 4; index--) {
          // inicializar el contador de meses en Abril u Octubre

          const mesDelAnio = this.mesesDelAnio.find(
            (mes) => mes.numeroMes === index
          );

          const planillasEnUnPeriodo = this.planillasRemuneracionDelAnioDelPeriodo.filter(
            (planilla) => planilla.periodo.split('-')[0] === mesDelAnio.mes
          );

          if (planillasEnUnPeriodo.length > 0) {
            let periodoEditado = {
              personal: [],
              periodo: '',
              fecha_inicio: '',
              fecha_fin: '',
            };

            planillasEnUnPeriodo.map((planilla) => {
              if (periodoEditado.personal.length === 0) {
                (periodoEditado.personal = planilla.personal),
                  (periodoEditado.periodo = planilla.periodo),
                  (periodoEditado.fecha_inicio = planilla.fecha_inicio),
                  (periodoEditado.fecha_fin = planilla.fecha_fin);
              } else {
                planilla.personal.map((persona) => {
                  const personaEncontrada = periodoEditado.personal.find(
                    (trabajador) =>
                      trabajador.doi.toString() === persona.doi.toString()
                  );

                  if (personaEncontrada) {
                    personaEncontrada.ingresos_por_concepto.map(
                      (personaEncontradaIngreso) => {
                        let ingresoEncontrado = persona.ingresos_por_concepto.find(
                          (ingreso) =>
                            ingreso.codigo === personaEncontradaIngreso.codigo
                        );

                        if (ingresoEncontrado) {
                          personaEncontradaIngreso.monto =
                            personaEncontradaIngreso.monto +
                            Number(ingresoEncontrado.monto);
                        } else {
                          personaEncontrada.ingresos_por_concepto.push(
                            ingresoEncontrado
                          );
                        }
                        return personaEncontradaIngreso;
                      }
                    );

                    personaEncontrada.cantidad_dias_efectivos_beneficios_sociales =
                      personaEncontrada.cantidad_dias_efectivos_beneficios_sociales +
                      persona.cantidad_dias_efectivos_beneficios_sociales;
                  } else {
                    periodoEditado.personal.push(persona);
                  }

                  return persona;
                });
                periodoEditado.periodo = planilla.periodo;
                if (periodoEditado.fecha_inicio === '') {
                  periodoEditado.fecha_inicio = planilla.fecha_inicio;
                }
                periodoEditado.fecha_fin = planilla.fecha_fin;
              }

              return planilla;
            });

            periodosPlanilla.push(periodoEditado);
          }
        }

        const {
          planillas: planillaGratificacionJulio,
        } = await getPlanillaPorPeriodoYPorTipo(
          'Julio-' + anioPeriodo,
          'Gratificación'
        );
        if (planillaGratificacionJulio) {
          periodosPlanilla.push(...planillaGratificacionJulio);
        }
      }
      // console.log(periodosPlanilla);
      this.nuevaPlanillaCreada.personal.map((persona) => {
        //inicializar el contador total de días computables para CTS (el máximo es cantidadDiasPeriodoPlanilla)
        persona.cantidad_dias_efectivos_total = 0;
        //verificar si existe el array con los ingresos afectos a Essalud obtenidos dentro de los últimos 6 períodos
        if (!persona.ingresosAfectosAEsSaludPorPeriodo) {
          persona.ingresosAfectosAEsSaludPorPeriodo = [];
        }
        // inicializar la remuneración computable por persona
        persona.remuneracionComputable = 0;
        //si la fecha de ingreso de esta persona es menor al 01-04 entonces pasar a la sgte persona
        if (
          moment(persona.fecha_ingreso).tz('America/Lima') <= fechaMinimaIngreso
        ) {
          // console.log('es menor');
          //remuneracion basica
          persona.remuneracionComputable =
            persona.remuneracionComputable +
            Number(persona.remuneracion_mensual);
          if (this.cliente.datos_empresa.regimen_laboral !== 'MICRO EMPRESA') {
            if (persona.asignacion_familiar === 'SI') {
              //asignacion familiar
              persona.remuneracionComputable =
                persona.remuneracionComputable +
                Number(this.parametroAsignacionFamiliar.monto);
              persona.montoPorAsignacionFamiliar = Number(
                this.parametroAsignacionFamiliar.monto
              );
            }
          }
          //promedio de ingresos adicionales (distintos a la remuneracion basica y asignacion familiar)
          // console.log(periodosPlanilla);
          periodosPlanilla.map((planilla) => {
            // console.log(planilla);
            // agregar el monto y los conceptos de cada ingreso por cada periodo a cada persona

            const personaEncontrada = planilla.personal.find(
              (personalPlanilla) =>
                personalPlanilla.doi.toString() === persona.doi.toString()
            );
            // console.log(personaEncontrada);
            if (personaEncontrada) {
              if (planilla.tipo_proceso === 'Gratificación') {
                //obtener planilla de gratificacion para este calculo
                //calcular la grati según las reglas para CTS
                //ultima grati - bonif extraord / 6
                //buscar ingreso por concepto de grati sin la bonif, dividirlo entre 6 y sumarlo a la remuneración computable
                const ingresoGratiEncontrado = personaEncontrada.ingresos_por_concepto.find(
                  (ingreso) => ingreso.codigo === '0406'
                );
                if (ingresoGratiEncontrado) {
                  persona.remuneracionComputable =
                    persona.remuneracionComputable +
                    ingresoGratiEncontrado.monto / 6;
                  persona.sextoRemuneracion = ingresoGratiEncontrado.monto / 6;
                }
              } else {
                persona.cantidad_dias_efectivos_total =
                  persona.cantidad_dias_efectivos_total +
                  personaEncontrada.cantidad_dias_efectivos_beneficios_sociales;

                //buscar ingresos afectos y considerar la remuneración para la suma
                personaEncontrada.ingresos_por_concepto.map((ingreso) => {
                  //buscar ingresos afectos
                  let ingresoAfectoAEsSalud = this.conceptosSunat.find(
                    (concepto) =>
                      concepto.codigo === ingreso.codigo &&
                      concepto.afectacion_empleador.essalud_seguro === 'SI' &&
                      ingreso.codigo !== '0121' &&
                      ingreso.codigo !== '0201'
                  );
                  if (ingresoAfectoAEsSalud) {
                    //buscar en el array persona.ingresosAfectosAEsSaludPorPeriodo si existe este ingreso,
                    //si existe -> aumentar en 1 la cantidad de "cantidadDePeriodos" para ese ingreso (luego se promedia y divide entre la cantidadDePlanillasDeRemuneracion)
                    let ingresoAfectoEncontrado = persona.ingresosAfectosAEsSaludPorPeriodo.find(
                      (ingresoAfecto) => ingresoAfecto.codigo === ingreso.codigo
                    );
                    if (ingresoAfectoEncontrado) {
                      ingresoAfectoEncontrado.montoTotal =
                        ingresoAfectoEncontrado.montoTotal +
                        Number(ingreso.monto);
                      ingresoAfectoEncontrado.cantidadPeriodos++;
                    } else {
                      persona.ingresosAfectosAEsSaludPorPeriodo.push({
                        codigo: ingreso.codigo,
                        montoTotal: Number(ingreso.monto),
                        cantidadPeriodos: 1,
                      });
                    }
                  }
                  return ingreso;
                });
              }
            }
            return planilla;
          });
        }
        return persona;
      });

      //sumar totales
      this.nuevaPlanillaCreada.personal.map((persona) => {
        //buscar ingresos afectos en mas de un periodo para promediar y sumar al monto total de remuneracion computable
        persona.ingresosAfectosAEsSaludPorPeriodo.map((ingresoAfecto) => {
          if (ingresoAfecto.cantidadPeriodos > 2) {
            let promedioIngresoAfecto = ingresoAfecto.montoTotal / 6;
            // ingresoAfecto.montoTotal / cantidadDiasPeriodoPlanilla;
            persona.remuneracionComputable =
              persona.remuneracionComputable + promedioIngresoAfecto;
            persona.montoIngresoAfecto = promedioIngresoAfecto;
          }
          return ingresoAfecto;
        });
        //dividir la rem total computable luego entre 180 y multiplicar por la cantidad de dias efectivos totales / 2
        persona.montoAPagarPorCTS =
          ((persona.remuneracionComputable / 180) *
            // ((persona.remuneracionComputable / cantidadDiasPeriodoPlanilla) *
            persona.cantidad_dias_efectivos_total) /
          2;

        //si la empresa tiene el regimen laboral de PEQUEÑA EMPRESA entonces se divide el resultado a la mitad
        if (
          this.cliente.datos_empresa.regimen_laboral === 'PEQUEÑA EMPRESA' &&
          persona.montoAPagarPorCTS > 0
        ) {
          persona.montoAPagarPorCTS = persona.montoAPagarPorCTS / 2;
        }

        //luego de obtener el monto a pagar por concepto de CTS, se debe agregar este monto como ingresos_por_concepto 0904
        let ingresoEncontrado = persona.ingresos_por_concepto.find(
          (ingreso) => ingreso.codigo === '0904'
        );
        if (ingresoEncontrado) {
          ingresoEncontrado.monto =
            ingresoEncontrado.monto + persona.montoAPagarPorCTS;
        } else {
          persona.ingresos_por_concepto.push({
            codigo: '0904',
            monto: persona.montoAPagarPorCTS,
          });
        }
        return persona;
      });

      this.showLoadingDialog = false;
    },
    async procesarPlanillaGratificacion() {
      //obtener los periodos de planillas de vacaciones generadas en este año para el cálculo de 5ta categoría en el siguiente paso
      await this.obtenerPlanillasVacacionesDelAnioDelPeriodo();
      const mesPeriodo = this.nuevaPlanillaCreada.periodo.split('-')[0];
      const anioPeriodo = this.nuevaPlanillaCreada.periodo.split('-')[1];
      // crear el array con las 6 planillas de remuneracion
      let periodosPlanilla = [];
      // let cantidadDiasPeriodoPlanilla = 0;
      let indiceMesInicio = 6;
      let indiceMesFin = 0;

      if (mesPeriodo === 'Diciembre') {
        indiceMesInicio = 12;
        indiceMesFin = 6;
      }
      //buscar planillas de remuneración por meses/períodos
      for (let index = indiceMesInicio; index > indiceMesFin; index--) {
        // console.log(index, this.planillasRemuneracionDelAnioDelPeriodo);
        const mesDelAnio = this.mesesDelAnio.find(
          (mes) => mes.numeroMes === index
        );

        const planillasEnUnPeriodo = this.planillasRemuneracionDelAnioDelPeriodo.filter(
          (planilla) => planilla.periodo.split('-')[0] === mesDelAnio.mes
        );

        if (planillasEnUnPeriodo.length > 0) {
          let periodoEditado = {
            personal: [],
            periodo: '',
            fecha_inicio: '',
            fecha_fin: '',
          };

          planillasEnUnPeriodo.map((planilla) => {
            if (periodoEditado.personal.length === 0) {
              (periodoEditado.personal = planilla.personal),
                (periodoEditado.periodo = planilla.periodo),
                (periodoEditado.fecha_inicio = planilla.fecha_inicio),
                (periodoEditado.fecha_fin = planilla.fecha_fin);
            } else {
              planilla.personal.map((persona) => {
                const personaEncontrada = periodoEditado.personal.find(
                  (trabajador) =>
                    trabajador.doi.toString() === persona.doi.toString()
                );

                if (personaEncontrada) {
                  personaEncontrada.ingresos_por_concepto.map(
                    (personaEncontradaIngreso) => {
                      let ingresoEncontrado = persona.ingresos_por_concepto.find(
                        (ingreso) =>
                          ingreso.codigo === personaEncontradaIngreso.codigo
                      );

                      if (ingresoEncontrado) {
                        personaEncontradaIngreso.monto =
                          personaEncontradaIngreso.monto +
                          Number(ingresoEncontrado.monto);
                      } else {
                        personaEncontrada.ingresos_por_concepto.push(
                          ingresoEncontrado
                        );
                      }
                      return personaEncontradaIngreso;
                    }
                  );

                  personaEncontrada.cantidad_dias_efectivos_beneficios_sociales =
                    personaEncontrada.cantidad_dias_efectivos_beneficios_sociales +
                    persona.cantidad_dias_efectivos_beneficios_sociales;
                } else {
                  periodoEditado.personal.push(persona);
                }

                return persona;
              });
              periodoEditado.periodo = planilla.periodo;
              if (periodoEditado.fecha_inicio === '') {
                periodoEditado.fecha_inicio = planilla.fecha_inicio;
              }
              periodoEditado.fecha_fin = planilla.fecha_fin;
            }

            return planilla;
          });

          periodosPlanilla.push(periodoEditado);
        }
      }

      this.nuevaPlanillaCreada.personal.map((persona) => {
        //inicializar el contador total de días computables para Gratificación (el máximo es 180)
        persona.cantidad_dias_efectivos_total = 0;
        //verificar si existe el array con los ingresos afectos a Essalud obtenidos dentro de los últimos 6 períodos
        if (!persona.ingresosAfectosAEsSaludPorPeriodo) {
          persona.ingresosAfectosAEsSaludPorPeriodo = [];
        }

        persona.montoAPagarPorGratificacion = 0;
        persona.cantidadDePlanillasDeRemuneracion = 0; // cantidad de meses de remuneracion
        // }

        //############# AQUI INICIA EL LOOP DENTRO DE CADA PLANILLA MENSUAL ######################
        periodosPlanilla.map((planilla) => {
          // console.log(planilla);
          // agregar el monto y los conceptos de cada ingreso por cada periodo a cada persona
          const personaEncontrada = planilla.personal.find(
            (personalPlanilla) =>
              personalPlanilla.doi.toString() === persona.doi.toString()
          );
          // console.log(personaEncontrada);
          if (personaEncontrada) {
            //se considera SOLO personal activo

            //calcular cantidad total de días efectivos para el cálculo
            //si ingresa el 2 o más de un mes del cálculo no se considera ese mes para el cálculo

            const mesPeriodoAEvaluar = planilla.periodo.split('-')[0];
            const mesPlanilla = this.mesesDelAnio.find(
              (mes) => mes.mes === mesPeriodoAEvaluar
            );

            //si la fecha de ingreso de esta persona es menor al 01 del mes en evaluación, entonces se considera el mes para el cálculo, de lo contrario se pasa al sgte mes
            const fechaMinimaIngreso = moment(
              `${Number(anioPeriodo)}-${mesPlanilla.numeroMes}-01`,
              'YYYY-MM-DD'
            );

            if (moment(persona.fecha_ingreso) <= fechaMinimaIngreso) {
              if (
                (!!persona.fecha_cese &&
                  moment(persona.fecha_cese).tz('America/Lima') >=
                    moment(planilla.fecha_fin).tz('America/Lima')) ||
                !persona.fecha_cese
              ) {
                // console.log(persona.nombre);
                //si la persona ha ingresado antes del 01 de planilla.periodo[mes] se considera como mes completo
                persona.cantidadDePlanillasDeRemuneracion++;
                // persona.cantidadMesesCompletosTrabajados++

                //verificar si se incluirá vacaciones y/o DMs entre otros
                persona.cantidad_dias_efectivos_total =
                  persona.cantidad_dias_efectivos_total +
                  personaEncontrada.cantidad_dias_efectivos_beneficios_sociales;
                //promedio de ingresos adicionales (distintos a la remuneracion basica y asignacion familiar)
                //buscar ingresos afectos y considerar la remuneración para la suma
                personaEncontrada.ingresos_por_concepto.map((ingreso) => {
                  //buscar ingresos afectos
                  let ingresoAfectoAEsSalud = this.conceptosSunat.find(
                    (concepto) =>
                      concepto.codigo === !!ingreso &&
                      ingreso.codigo &&
                      concepto.afectacion_empleador.essalud_seguro === 'SI' &&
                      ingreso.codigo !== '0121' &&
                      ingreso.codigo !== '0201'
                  );
                  if (ingresoAfectoAEsSalud) {
                    //buscar en el array persona.ingresosAfectosAEsSaludPorPeriodo si existe este ingreso,
                    //si existe -> aumentar en 1 la cantidad de "cantidadDePeriodos" para ese ingreso (luego se promedia y divide entre la cantidadDePlanillasDeRemuneracion)
                    let ingresoAfectoEncontrado = persona.ingresosAfectosAEsSaludPorPeriodo.find(
                      (ingresoAfecto) => ingresoAfecto.codigo === ingreso.codigo
                    );
                    if (ingresoAfectoEncontrado) {
                      ingresoAfectoEncontrado.montoTotal =
                        ingresoAfectoEncontrado.montoTotal +
                        Number(ingreso.monto);
                      ingresoAfectoEncontrado.cantidadPeriodos++;
                    } else {
                      persona.ingresosAfectosAEsSaludPorPeriodo.push({
                        codigo: ingreso.codigo,
                        montoTotal: Number(ingreso.monto),
                        cantidadPeriodos: 1,
                      });
                    }
                  }
                  return ingreso;
                });
              }
            }

            // }
          }
          return planilla;
        });

        //calcular totales
        // calcular grati-sueldo remuneracion básica
        if (
          persona.cantidadDePlanillasDeRemuneracion > 0 &&
          persona.cantidad_dias_efectivos_total > 0
        ) {
          const montoPorRemuneracion =
            (persona.remuneracion_mensual / 180) *
            persona.cantidad_dias_efectivos_total;
          persona.montoAPagarPorGratificacion =
            persona.montoAPagarPorGratificacion + montoPorRemuneracion;
          // console.log(montoPorRemuneracion);
          // calcular grati-asignacion_familiar
          if (this.cliente.datos_empresa.regimen_laboral !== 'MICRO EMPRESA') {
            if (persona.asignacion_familiar === 'SI') {
              const montoPorAsignacionFamiliar =
                (Number(this.parametroAsignacionFamiliar.monto) / 180) *
                persona.cantidad_dias_efectivos_total;
              persona.montoAPagarPorGratificacion =
                persona.montoAPagarPorGratificacion +
                montoPorAsignacionFamiliar;
              persona.montoPorAsignacionFamiliar = montoPorAsignacionFamiliar;
            }
          }
          // console.log(montoPorAsignacionFamiliar);
          //calcular grati-conceptos_afectos_essalud
          //buscar ingresos afectos en mas de un periodo para promediar y sumar al monto total de remuneracion computable
          persona.ingresosAfectosAEsSaludPorPeriodo.map((ingresoAfecto) => {
            if (ingresoAfecto.cantidadPeriodos > 2) {
              const montoIngresoAfecto =
                (ingresoAfecto.montoTotal /
                  ingresoAfecto.cantidadPeriodos /
                  180) *
                persona.cantidad_dias_efectivos_total;
              persona.montoAPagarPorGratificacion =
                persona.montoAPagarPorGratificacion + montoIngresoAfecto;
            }
            return ingresoAfecto;
          });
        }
        //si la empresa tiene el regimen laboral de PEQUEÑA EMPRESA entonces se divide el resultado a la mitad
        if (
          this.cliente.datos_empresa.regimen_laboral === 'PEQUEÑA EMPRESA' &&
          persona.montoAPagarPorGratificacion > 0
        ) {
          persona.montoAPagarPorGratificacion =
            persona.montoAPagarPorGratificacion / 2;
        }
        //agregar concepto de gratificacion fiestas patrias y navidad
        let ingresoGratificacion = persona.ingresos_por_concepto.find(
          (ingreso) => ingreso.codigo === '0406'
        );
        if (ingresoGratificacion) {
          ingresoGratificacion.monto =
            ingresoGratificacion.monto + persona.montoAPagarPorGratificacion;
        } else {
          persona.ingresos_por_concepto.push({
            codigo: '0406',
            monto: persona.montoAPagarPorGratificacion,
          });
        }
        //calcular grati-bonif_extraordinaria
        let montoGratificacionBonificacionExtraordinaria;
        if (persona.tipo_seguro && persona.tipo_seguro === 'ESSALUD') {
          //si es Essalud se calcula el 9%, si es EPS el 6.75%
          montoGratificacionBonificacionExtraordinaria =
            persona.montoAPagarPorGratificacion *
            Number(this.parametroEsSalud.porcentaje);
        }
        if (persona.tipo_seguro && persona.tipo_seguro === 'EPS') {
          //si es EPS restar el parametro de essalud - parametro essalud eps
          montoGratificacionBonificacionExtraordinaria =
            persona.montoAPagarPorGratificacion *
            Number(this.porcentajeDescuentoPorEPS);
        }

        // console.log(Number(this.parametroEsSalud.porcentaje));
        // console.log(montoGratificacionBonificacionExtraordinaria);
        //agregar concepto de bonificacion extraordinaria
        let ingresoPorBonificacion = persona.ingresos_por_concepto.find(
          (ingreso) => ingreso.codigo === '0407'
        );
        if (ingresoPorBonificacion) {
          ingresoPorBonificacion.monto =
            ingresoPorBonificacion.monto +
            montoGratificacionBonificacionExtraordinaria;
        } else {
          persona.ingresos_por_concepto.push({
            codigo: '0407',
            monto: montoGratificacionBonificacionExtraordinaria,
          });
        }

        persona.montoAPagarPorGratificacion =
          persona.montoAPagarPorGratificacion +
          montoGratificacionBonificacionExtraordinaria;
        // }
        return persona;
      });

      //calcular totales
      // this.nuevaPlanillaCreada.personal.map((persona) => {

      //   return persona;
      // });

      this.showLoadingDialog = false;
    },
    async procesarPlanillaLiquidacion() {
      //solo para personal cesado
      this.nuevaPlanillaCreada.personal.map((persona) => {
        //vacaciones truncas
        //calcular si son indemnizables o no (es decir, mayor a 30 dias de deuda si es regimen general, 15 si es mype)
        //mismas reglas q el proceso de quinta y demás
        this.calcularVacacionesTruncas(persona);
        //aplicar reglas de regimen de la empresa
        if (this.cliente.datos_empresa.regimen_laboral !== 'MICRO EMPRESA') {
          //gratificacion trunca
          //bonif extraordinaria
          //calculo igual al de grati normal pero hasta la fecha de cese (periodos completos)
          //mismas reglas q el proceso de quinta y demás
          this.calcularGratificacionTrunca(persona);
          //cts trunca
          //calculo igual al de CTS pero hasta la fecha de cese
          this.calcularCTSTrunca(persona);
          //otros ingresos
        }
        return persona;
      });
      setTimeout(() => {
        this.showLoadingDialog = false;
      }, 3000);
    },
    // async procesarPlanillaUtilidades() {
    //   console.log('procesar planilla Utilidades');
    // },
    async procesarPlanillaVacaciones() {
      this.tableLoading = true;
      await this.obtenerPlanillasVacacionesDelAnioDelPeriodo();
      //luego se debe procesar la planilla de vacaciones como la de remuneracion
      //primero calcular con los periodos generados en este año, luego se debe ampliar la búsqueda de períodos hasta máximo 12 períodos anteriores
      const fechaInicio = moment(this.nuevaPlanillaCreada.fecha_inicio).tz(
        'America/Lima'
      );
      const fechaFin = moment(this.nuevaPlanillaCreada.fecha_fin).tz(
        'America/Lima'
      );

      let fechaPlanilla = moment(this.procesoPlanilla.fechaInicio).tz(
        'America/Lima'
      );

      this.asistenciasAEvaluar = [];

      // crear el array con las 6 últimas planillas de remuneración
      let periodosPlanillaRemuneracion = await this.obtenerSeisUltimasPlanillasDeRemuneracion();

      const cantidadDiasPeriodoPlanilla =
        fechaFin.diff(fechaInicio, 'days') + 1;

      for (let dia = 1; dia <= cantidadDiasPeriodoPlanilla; dia++) {
        const { error, tokenExpired, asistencia } = await getAssistance(
          fechaPlanilla.format()
        );
        if (error) {
          await this.verificarExistenciaDeAsistencia(fechaPlanilla);
        }
        if (tokenExpired) {
          routeToLoginIfTokenIsExpired(this);
        }
        if (asistencia) {
          this.asistenciasAEvaluar.push(asistencia);
        }
        fechaPlanilla = fechaPlanilla.add(1, 'days');
      }

      this.nuevaPlanillaCreada.personal.map(async (persona) => {
        //variable para saber si hay que actualizar las vacaciones de una persona si ha sido cargada por carga masiva
        this.actualizarPersona = false;
        persona.cantidadDiasVacaciones = 0;
        persona.cantidadDePlanillasDeRemuneracion = 0;

        //obtener cantidad de días programado como Vacaciones según asistencia
        this.asistenciasAEvaluar.map((asistencia) => {
          let personaEncontrada = asistencia.personal.find(
            (asistenciaPersona) =>
              asistenciaPersona.doi.toString() === persona.doi.toString()
          );

          if (personaEncontrada) {
            if (
              moment(persona.fecha_ingreso) <=
              moment(asistencia.fecha_asistencia)
            ) {
              if (
                personaEncontrada.tipo_jornada === 'V' ||
                personaEncontrada.tipo_jornada === 'Vacaciones'
              ) {
                // si hay asistencias se pueden considerar las vacaciones ya gozadas, de lo contrario agregarlas desde su modulo
                let vacacionesEncontradas = persona.vacaciones.find(
                  (vacaciones) =>
                    moment(asistencia.fecha_asistencia).tz('America/Lima') >=
                      moment(vacaciones.fecha_inicio).tz('America/Lima') &&
                    moment(asistencia.fecha_asistencia).tz('America/Lima') <=
                      moment(vacaciones.fecha_fin).tz('America/Lima')
                );

                if (!vacacionesEncontradas) {
                  let objetoVacaciones = {
                    periodo: 'Carga masiva',
                    fecha_inicio: moment(asistencia.fecha_asistencia)
                      .tz('America/Lima')
                      .format('YYYY-MM-DD'),
                    fecha_fin: moment(asistencia.fecha_asistencia)
                      .tz('America/Lima')
                      .format('YYYY-MM-DD'),
                    aprobado: 'SI',
                    tipo_vacaciones: 'Gozado',
                    observaciones: '',
                  };
                  persona.vacaciones.push(objetoVacaciones);
                  this.actualizarPersona = true;
                }
                //se agrega un día de vacaciones si la asistencia así lo indica
                persona.cantidadDiasVacaciones++;
              }
            }
          }

          return asistencia;
        });

        // if (persona.cantidadDiasVacaciones < 0) {
        //   persona.cantidadDiasVacaciones = 0;
        // }

        //verificar si existe el array con los ingresos afectos a Essalud obtenidos dentro de los últimos 6 períodos
        if (!persona.ingresosAfectosAEsSaludPorPeriodo) {
          persona.ingresosAfectosAEsSaludPorPeriodo = [];
        }

        //############# AQUI INICIA EL LOOP DENTRO DE CADA PLANILLA DE LAS 6 DE REMUNERACION ######################
        periodosPlanillaRemuneracion.map((planilla) => {
          // agregar el monto y los conceptos de cada ingreso por cada periodo a cada persona

          const personaEncontrada = planilla.personal.find(
            (personalPlanilla) =>
              personalPlanilla.doi.toString() === persona.doi.toString()
          );
          // console.log(personaEncontrada);
          if (personaEncontrada) {
            //si la persona ha ingresado antes del 01 de planilla.periodo[mes] se considera como mes completo
            persona.cantidadDePlanillasDeRemuneracion++;

            //buscar ingresos afectos y considerar la remuneración para la suma
            personaEncontrada.ingresos_por_concepto.map((ingreso) => {
              //buscar ingresos afectos
              let ingresoAfectoAEsSalud = this.conceptosSunat.find(
                (concepto) =>
                  concepto.codigo === ingreso.codigo &&
                  concepto.afectacion_empleador.essalud_seguro === 'SI' &&
                  ingreso.codigo !== '0121' &&
                  ingreso.codigo !== '0201'
              );
              if (ingresoAfectoAEsSalud) {
                //buscar en el array persona.ingresosAfectosAEsSaludPorPeriodo si existe este ingreso,
                //si existe -> aumentar en 1 la cantidad de "cantidadDePeriodos" para ese ingreso (luego se promedia y divide entre la cantidadDePlanillasDeRemuneracion)
                let ingresoAfectoEncontrado = persona.ingresosAfectosAEsSaludPorPeriodo.find(
                  (ingresoAfecto) => ingresoAfecto.codigo === ingreso.codigo
                );
                if (ingresoAfectoEncontrado) {
                  ingresoAfectoEncontrado.montoTotal =
                    ingresoAfectoEncontrado.montoTotal + Number(ingreso.monto);
                  ingresoAfectoEncontrado.cantidadPeriodos++;
                } else {
                  persona.ingresosAfectosAEsSaludPorPeriodo.push({
                    codigo: ingreso.codigo,
                    montoTotal: Number(ingreso.monto),
                    cantidadPeriodos: 1,
                  });
                }
              }
              return ingreso;
            });
            // }
            // }

            // }
          }
          return planilla;
        });

        // console.log('persona con ingresosAfectos si existen', persona);

        persona.montoAPagarPorVacaciones = 0;
        persona.montoPomedioIngresoAfecto = 0;
        //buscar ingresos afectos en mas de un periodo para promediar y sumar al monto total de remuneracion computable
        persona.ingresosAfectosAEsSaludPorPeriodo.map((ingresoAfecto) => {
          if (ingresoAfecto.cantidadPeriodos > 2) {
            const montoIngresoAfecto =
              (ingresoAfecto.montoTotal / 6 / 30) *
              persona.cantidadDiasVacaciones;
            persona.montoAPagarPorVacaciones =
              persona.montoAPagarPorVacaciones + montoIngresoAfecto;
            persona.montoPomedioIngresoAfecto = montoIngresoAfecto;
          }
          return ingresoAfecto;
        });

        const montoPorRemuneracion =
          (Number(persona.remuneracion_mensual) / 30) *
          persona.cantidadDiasVacaciones;

        const montoPorAsignacionFamiliar = this.parametroAsignacionFamiliar
          .monto;
        // (this.parametroAsignacionFamiliar.monto / 30) *
        // persona.cantidadDiasVacaciones;

        let ingresoRemuneracionEncontrado = persona.ingresos_por_concepto.find(
          (ingreso) => ingreso.codigo === '0118'
        );
        if (ingresoRemuneracionEncontrado) {
          ingresoRemuneracionEncontrado.monto =
            ingresoRemuneracionEncontrado.monto +
            montoPorRemuneracion +
            persona.montoPomedioIngresoAfecto;
        } else {
          persona.ingresos_por_concepto.push({
            codigo: '0118',
            monto: montoPorRemuneracion + persona.montoPomedioIngresoAfecto,
          });
        }

        // solo considerar el pago de AF si ha salido 30 días o más en el mismo periodo
        // buscar periodos de vacaciones del mismo periodo
        const planillasVacacionesDeEstePeriodo = this.planillasVacacionesDelAnioDelPeriodo.filter(
          (planilla) => planilla.periodo === this.nuevaPlanillaCreada.periodo
        );
        // sumar la cantidad de dias de vacaciones de cada persona por periodo de planilla de vacaciones encontrado
        let cantidadDiasDeVacacionesTotalDeEstePeriodo = 0;
        planillasVacacionesDeEstePeriodo.map((planillaVacaciones) => {
          planillaVacaciones.personal.map((personalConVacaciones) => {
            if (
              personalConVacaciones.doi.toString() === persona.doi.toString()
            ) {
              cantidadDiasDeVacacionesTotalDeEstePeriodo =
                cantidadDiasDeVacacionesTotalDeEstePeriodo +
                persona.cantidadDiasVacaciones +
                personalConVacaciones.cantidadDiasVacaciones;
            }
            return personalConVacaciones;
          });
          return planillaVacaciones;
        });
        // calcular si hay más de 30 días de cantidad de vacaciones en total para pagar AF completa sino no se considera
        if (
          (this.cliente.datos_empresa.regimen_laboral !== 'MICRO EMPRESA' &&
            cantidadDiasDeVacacionesTotalDeEstePeriodo >= 30) ||
          (this.cliente.datos_empresa.regimen_laboral !== 'MICRO EMPRESA' &&
            persona.cantidadDiasVacaciones >= 30)
        ) {
          if (persona.asignacion_familiar === 'SI') {
            let ingresoAsignacionEncontrado = persona.ingresos_por_concepto.find(
              (ingreso) => ingreso.codigo === '0201'
            );
            if (ingresoAsignacionEncontrado) {
              ingresoAsignacionEncontrado.monto = montoPorAsignacionFamiliar;
            } else {
              persona.ingresos_por_concepto.push({
                codigo: '0201',
                monto: montoPorAsignacionFamiliar,
              });
            }
          }
        }

        if (
          Number(persona.monto_ingreso_fijo1) > 0 &&
          persona.concepto_ingreso_fijo1 != ''
        ) {
          let codigoIngreso = this.conceptosSunat.find(
            (ingreso) =>
              ingreso.nombre === persona.concepto_ingreso_fijo1 &&
              ingreso.afectacion_empleador.essalud_seguro === 'SI'
          );
          if (codigoIngreso) {
            let ingresoEncontrado = persona.ingresos_por_concepto.find(
              (ingreso) => ingreso.codigo === codigoIngreso.codigo
            );
            let montoIngresoFijo1 =
              (Number(persona.monto_ingreso_fijo1) / 30) *
              persona.cantidadDiasVacaciones;

            if (ingresoEncontrado) {
              ingresoEncontrado.monto =
                ingresoEncontrado.monto + montoIngresoFijo1;
            } else {
              persona.ingresos_por_concepto.push({
                codigo: codigoIngreso.codigo,
                monto: montoIngresoFijo1,
              });
            }
          }
        }
        if (
          Number(persona.monto_ingreso_fijo2) > 0 &&
          persona.concepto_ingreso_fijo2 != ''
        ) {
          let codigoIngreso = this.conceptosSunat.find(
            (ingreso) =>
              ingreso.nombre === persona.concepto_ingreso_fijo2 &&
              ingreso.afectacion_empleador.essalud_seguro === 'SI'
          );
          if (codigoIngreso) {
            let ingresoEncontrado = persona.ingresos_por_concepto.find(
              (ingreso) => ingreso.codigo === codigoIngreso.codigo
            );
            let montoIngresoFijo2 =
              (Number(persona.monto_ingreso_fijo2) / 30) *
              persona.cantidadDiasVacaciones;
            if (ingresoEncontrado) {
              ingresoEncontrado.monto =
                ingresoEncontrado.monto + montoIngresoFijo2;
            } else {
              persona.ingresos_por_concepto.push({
                codigo: codigoIngreso.codigo,
                monto: montoIngresoFijo2,
              });
            }
          }
        }
        if (
          Number(persona.monto_ingreso_fijo3) > 0 &&
          persona.concepto_ingreso_fijo3 != ''
        ) {
          let codigoIngreso = this.conceptosSunat.find(
            (ingreso) =>
              ingreso.nombre === persona.concepto_ingreso_fijo3 &&
              ingreso.afectacion_empleador.essalud_seguro === 'SI'
          );
          if (codigoIngreso) {
            let ingresoEncontrado = persona.ingresos_por_concepto.find(
              (ingreso) => ingreso.codigo === codigoIngreso.codigo
            );
            let montoIngresoFijo3 =
              (Number(persona.monto_ingreso_fijo3) / 30) *
              persona.cantidadDiasVacaciones;
            if (ingresoEncontrado) {
              ingresoEncontrado.monto =
                ingresoEncontrado.monto + montoIngresoFijo3;
            } else {
              persona.ingresos_por_concepto.push({
                codigo: codigoIngreso.codigo,
                monto: montoIngresoFijo3,
              });
            }
          }
        }

        if (this.actualizarPersona) {
          //llamar al endpoint para agregar "papeletas" de vacaciones basado en la asistencia
          await editarVacaciones(persona);
        }

        return persona;
      });

      this.$store.state.nuevaPlanillaCreada.personal = this.nuevaPlanillaCreada.personal.filter(
        (persona) => persona.cantidadDiasVacaciones > 0
      );

      if (this.$store.state.nuevaPlanillaCreada.personal.length > 0) {
        this.showLoadingDialog = false;
        this.tableLoading = false;
        this.renderTable++;
      } else {
        this.showErrorAlert(
          'No hay personal con vacaciones en el período seleccionado'
        );
      }
    },
    async procesarPlanillaLocacionDeServicio() {
      // se obtiene la planilla de adelanto de este periodo si existe para considerar el descuento por el adelanto
      await this.obtenerPlanillaAdelantoDelPeriodo();
      this.tableLoading = true;
      const fechaInicio = moment(this.procesoPlanilla.fechaInicio).tz(
        'America/Lima'
      );
      const fechaFin = moment(this.procesoPlanilla.fechaFin).tz('America/Lima');

      let fechaPlanilla = moment(this.procesoPlanilla.fechaInicio).tz(
        'America/Lima'
      );

      this.asistenciasAEvaluar = [];

      //evaluar la asistencia en el rango de fechas seleccionado al crear la planilla
      const cantidadDiasPeriodoPlanilla =
        fechaFin.diff(fechaInicio, 'days') + 1;

      //variables para cálculo de totales sumados dentro del loop por persona
      let montoRemuneracionTotalPeriodo = 0;
      let totalMinutosLaborados = 0;
      //-----------------------------------------------

      for (let dia = 1; dia <= cantidadDiasPeriodoPlanilla; dia++) {
        const { error, tokenExpired, asistencia } = await getAssistance(
          fechaPlanilla.format()
        );

        if (error) {
          await this.verificarExistenciaDeAsistencia(fechaPlanilla);
        }

        if (tokenExpired) {
          routeToLoginIfTokenIsExpired(this);
        }

        if (asistencia) {
          this.asistenciasAEvaluar.push(asistencia);
        }
        fechaPlanilla = fechaPlanilla.add(1, 'days');
      }

      this.nuevaPlanillaCreada.personal.map((persona) => {
        //inicializar el registro de días
        if (!persona.info_dias) persona.info_dias = [];
        const montoRemuneracionDiaria =
          Number(persona.remuneracion_mensual) / 30;

        if (persona.ingresos_por_concepto.length === 0) {
          persona.ingresos_por_concepto = [
            {
              codigo: 'LOCADOR',
              monto:
                this.asistenciasAEvaluar.length > 0
                  ? persona.remuneracion_mensual
                  : 0,
            },
          ];

          if (this.asistenciasAEvaluar.length > 0) {
            persona.cantidad_dias_efectivos = 30;
          } else {
            persona.cantidad_dias_efectivos = 0;
          }
        }

        //validar si existen asistencias NO generadas y descontar según corresponda (para adelantar asistencias)
        if (this.cantidadDiasAsistenciaNoGenerados > 0) {
          persona.cantidad_dias_efectivos =
            persona.cantidad_dias_efectivos -
            this.cantidadDiasAsistenciaNoGenerados;

          //se resta el monto por los días que no existe asistencia aún
          persona.ingresos_por_concepto.map((ingreso) => {
            if (ingreso.codigo === 'LOCADOR') {
              ingreso.monto =
                ingreso.monto -
                montoRemuneracionDiaria *
                  this.cantidadDiasAsistenciaNoGenerados;
              if (ingreso.monto < 0) {
                ingreso.monto = 0;
              }
            }

            return ingreso;
          });
        }

        this.asistenciasAEvaluar.map((asistencia) => {
          let personaEncontrada = asistencia.personal.find(
            (asistenciaPersona) =>
              asistenciaPersona.doi.toString() === persona.doi.toString()
          );

          if (personaEncontrada) {
            if (
              moment(persona.fecha_ingreso) <=
              moment(asistencia.fecha_asistencia)
            ) {
              //si se coloca un bloqueo en las asistencias (q no se puedan agregar personas cesadas a las asistencias) entonces lo sgte es doble seguridad
              if (
                !!persona.fecha_cese &&
                moment(persona.fecha_cese) < moment(asistencia.fecha_asistencia)
              ) {
                persona.cantidad_dias_efectivos--;

                persona.ingresos_por_concepto.map((ingreso) => {
                  if (ingreso.codigo === 'LOCADOR') {
                    ingreso.monto = ingreso.monto - montoRemuneracionDiaria;
                    if (ingreso.monto < 0) {
                      ingreso.monto = 0;
                    }
                  }

                  return ingreso;
                });

                return persona;
              }

              //considerar descanso por defecto si no hay marcaciones (tipo_jornada)
              if (
                !personaEncontrada.tipo_jornada ||
                personaEncontrada.tipo_jornada === 'D'
              ) {
                personaEncontrada.tipo_jornada = 'Descanso';
              }

              //Registro de horas si existen
              let registroPlanillaPorDia = {
                tipo_jornada: personaEncontrada.tipo_jornada,
                fecha: moment(asistencia.fecha_asistencia)
                  .tz('America/Lima')
                  .format(),
              };

              if (
                personaEncontrada.hora_inicio_jornada &&
                personaEncontrada.hora_fin_jornada &&
                personaEncontrada.hora_inicio_jornada !== '' &&
                personaEncontrada.hora_fin_jornada !== ''
              ) {
                let prefijoFecha = asistencia.fecha_asistencia.substring(0, 11);

                let totalMinutos = moment(
                  `${prefijoFecha}${personaEncontrada.hora_fin_jornada}:00.000Z`
                ).diff(
                  moment(
                    `${prefijoFecha}${personaEncontrada.hora_inicio_jornada}:00.000Z`
                  ),
                  'minutes'
                );

                let minutosAlmuerzo = '';
                if (
                  personaEncontrada.hora_inicio_almuerzo &&
                  personaEncontrada.hora_fin_almuerzo &&
                  personaEncontrada.hora_inicio_almuerzo !== '' &&
                  personaEncontrada.hora_fin_almuerzo !== ''
                ) {
                  minutosAlmuerzo = moment(
                    `${prefijoFecha}${personaEncontrada.hora_fin_almuerzo}:00.000Z`
                  ).diff(
                    moment(
                      `${prefijoFecha}${personaEncontrada.hora_inicio_almuerzo}:00.000Z`
                    ),
                    'minutes'
                  );
                }

                (registroPlanillaPorDia.horas_laboradas = {
                  hora_programada: personaEncontrada.hora_programada,
                  hora_inicio_jornada: personaEncontrada.hora_inicio_jornada,
                  hora_fin_jornada: personaEncontrada.hora_fin_jornada,
                  hora_inicio_almuerzo: personaEncontrada.hora_inicio_almuerzo,
                  hora_fin_almuerzo: personaEncontrada.hora_fin_almuerzo,
                }),
                  (registroPlanillaPorDia.total_minutos_laborados =
                    totalMinutos - minutosAlmuerzo);
              } else {
                registroPlanillaPorDia.total_minutos_laborados = 0;
              }

              persona.info_dias.push(registroPlanillaPorDia);

              //Calcular dias efectivos y de beneficios sociales basados en los tipos de jornada
              if (
                personaEncontrada.tipo_jornada !== 'Asistencia' &&
                personaEncontrada.tipo_jornada !== 'A' &&
                personaEncontrada.tipo_jornada !== 'Tardanza' &&
                personaEncontrada.tipo_jornada !== 'Descanso'
              ) {
                persona.cantidad_dias_efectivos--;

                persona.ingresos_por_concepto.map((ingreso) => {
                  if (ingreso.codigo === 'LOCADOR') {
                    ingreso.monto = ingreso.monto - montoRemuneracionDiaria;
                    if (ingreso.monto < 0) {
                      ingreso.monto = 0;
                    }
                  }

                  return ingreso;
                });
              }
            }
          } else {
            //si la persona no se encuentra en la asistencia evaluada
            // evaluar tambien si no se encuentra por el hecho de estar cesado
            if (!persona.info_dias) persona.info_dias = [];

            persona.cantidad_dias_efectivos--;

            persona.ingresos_por_concepto.map((ingreso) => {
              if (ingreso.codigo === 'LOCADOR') {
                ingreso.monto = ingreso.monto - montoRemuneracionDiaria;
                if (ingreso.monto < 0) {
                  ingreso.monto = 0;
                }
              }

              return ingreso;
            });
          }

          return asistencia;
        });

        //lógica cuando no existan días efectivos laborados
        if (persona.cantidad_dias_efectivos <= 0) {
          persona.cantidad_dias_efectivos = 0;

          persona.ingresos_por_concepto.map((ingreso) => {
            if (ingreso.codigo === 'LOCADOR') {
              ingreso.monto = 0;
            }
            return ingreso;
          });
        }

        let remuneracion = persona.ingresos_por_concepto.find(
          (ingreso) => ingreso.codigo === 'LOCADOR'
        );
        if (remuneracion) {
          montoRemuneracionTotalPeriodo =
            montoRemuneracionTotalPeriodo + remuneracion.monto;
        }

        if (!persona.info_dias) {
          persona.info_dias = [];
        }
        persona.info_dias.map((dia) => {
          totalMinutosLaborados =
            totalMinutosLaborados + dia.total_minutos_laborados;
          return dia;
        });

        return persona;
      });

      this.showLoadingDialog = false;
      this.tableLoading = false;
      this.renderTable++;
    },
    async procesarPlanillaAdelantoDeSueldo() {
      this.tableLoading = true;

      //variables para cálculo de totales sumados dentro del loop por persona
      let montoRemuneracionTotalPeriodo = 0;

      const fechaInicio = moment(this.procesoPlanilla.fechaInicio).tz(
        'America/Lima'
      );
      const fechaFin = moment(this.procesoPlanilla.fechaFin).tz('America/Lima');

      let fechaPlanilla = moment(this.procesoPlanilla.fechaInicio).tz(
        'America/Lima'
      );

      this.asistenciasAEvaluar = [];

      //evaluar la asistencia en el rango de fechas seleccionado al crear la planilla
      const cantidadDiasPeriodoPlanilla =
        fechaFin.diff(fechaInicio, 'days') + 1;
      //variables para cálculo de totales sumados dentro del loop por persona
      // let montoRemuneracionTotalPeriodo = 0;
      // let montoAsignacionFamiliarTotalPeriodo = 0;
      // let totalMinutosLaborados = 0;
      //-----------------------------------------------

      for (let dia = 1; dia <= cantidadDiasPeriodoPlanilla; dia++) {
        // let fechaPlanilla = fechaInicio;
        // console.log('fechaPlanilla: ', fechaPlanilla.format());

        const { error, tokenExpired, asistencia } = await getAssistance(
          fechaPlanilla.format()
        );
        if (error) {
          await this.verificarExistenciaDeAsistencia(fechaPlanilla);
        }
        if (tokenExpired) {
          routeToLoginIfTokenIsExpired(this);
        }
        if (asistencia) {
          this.asistenciasAEvaluar.push(asistencia);
        }
        fechaPlanilla = fechaPlanilla.add(1, 'days');
      }

      this.nuevaPlanillaCreada.personal.map((persona) => {
        // Inicializar el registro de días
        if (!persona.info_dias) persona.info_dias = [];
        let montoAdelanto = 0;

        // Inicializar la cantidad máxima de días efectivos (computables)
        persona.cantidad_dias_efectivos = 15;

        // obtener monto de adelanto dependiendo si es por porcentaje o monto fijo
        // Monto en el caso de adelanto de sueldo por porcentaje
        if (
          !!persona.monto_porcentaje_adelanto &&
          persona.monto_porcentaje_adelanto.length > 1
        ) {
          montoAdelanto =
            (persona.remuneracion_mensual *
              Number(persona.monto_porcentaje_adelanto)) /
            100;
        }

        // Monto en el caso de adelanto de sueldo por monto fijo
        if (
          !!persona.monto_fijo_adelanto &&
          persona.monto_fijo_adelanto.length > 1
        ) {
          montoAdelanto = Number(persona.monto_fijo_adelanto);
        }

        if (persona.ingresos_por_concepto.length === 0) {
          persona.ingresos_por_concepto = [
            {
              codigo: 'ADELANTO',
              monto: montoAdelanto,
            },
          ];
        }

        // Obtener el monto diario por concepto de adelanto
        const montoRemuneracionDiaria = montoAdelanto / 15;

        this.asistenciasAEvaluar.map((asistencia) => {
          let personaEncontrada = asistencia.personal.find(
            (asistenciaPersona) =>
              asistenciaPersona.doi.toString() === persona.doi.toString()
          );

          // const esHoyFeriadoODescansoPagado = this.consultarFeriadoODescansoPagado(
          //   asistencia.fecha_asistencia
          // );

          if (personaEncontrada) {
            if (
              moment(persona.fecha_ingreso) <=
              moment(asistencia.fecha_asistencia)
            ) {
              if (
                !!persona.fecha_cese &&
                moment(persona.fecha_cese) < moment(asistencia.fecha_asistencia)
              ) {
                persona.cantidad_dias_efectivos--;

                persona.ingresos_por_concepto.map((ingreso) => {
                  if (ingreso.codigo === 'ADELANTO') {
                    ingreso.monto = ingreso.monto - montoRemuneracionDiaria;
                    if (ingreso.monto < 0) {
                      ingreso.monto = 0;
                    }
                  }
                  return ingreso;
                });

                return persona;
              }

              //considerar descanso por defecto si no hay marcaciones (tipo_jornada)
              if (
                !personaEncontrada.tipo_jornada ||
                personaEncontrada.tipo_jornada === 'D'
              ) {
                personaEncontrada.tipo_jornada = 'Descanso';
              }

              //Registro de horas si existen
              let registroPlanillaPorDia = {
                tipo_jornada: personaEncontrada.tipo_jornada,
                fecha: moment(asistencia.fecha_asistencia)
                  .tz('America/Lima')
                  .format(),
              };

              if (
                personaEncontrada.hora_inicio_jornada &&
                personaEncontrada.hora_fin_jornada &&
                personaEncontrada.hora_inicio_jornada !== '' &&
                personaEncontrada.hora_fin_jornada !== ''
              ) {
                let prefijoFecha = asistencia.fecha_asistencia.substring(0, 11);

                let totalMinutos = moment(
                  `${prefijoFecha}${personaEncontrada.hora_fin_jornada}:00.000Z`
                ).diff(
                  moment(
                    `${prefijoFecha}${personaEncontrada.hora_inicio_jornada}:00.000Z`
                  ),
                  'minutes'
                );

                let minutosAlmuerzo = '';
                if (
                  personaEncontrada.hora_inicio_almuerzo &&
                  personaEncontrada.hora_fin_almuerzo &&
                  personaEncontrada.hora_inicio_almuerzo !== '' &&
                  personaEncontrada.hora_fin_almuerzo !== ''
                ) {
                  minutosAlmuerzo = moment(
                    `${prefijoFecha}${personaEncontrada.hora_fin_almuerzo}:00.000Z`
                  ).diff(
                    moment(
                      `${prefijoFecha}${personaEncontrada.hora_inicio_almuerzo}:00.000Z`
                    ),
                    'minutes'
                  );
                }

                (registroPlanillaPorDia.horas_laboradas = {
                  hora_programada: personaEncontrada.hora_programada,
                  hora_inicio_jornada: personaEncontrada.hora_inicio_jornada,
                  hora_fin_jornada: personaEncontrada.hora_fin_jornada,
                  hora_inicio_almuerzo: personaEncontrada.hora_inicio_almuerzo,
                  hora_fin_almuerzo: personaEncontrada.hora_fin_almuerzo,
                }),
                  (registroPlanillaPorDia.total_minutos_laborados =
                    totalMinutos - minutosAlmuerzo);
              } else {
                registroPlanillaPorDia.total_minutos_laborados = 0;
              }

              persona.info_dias.push(registroPlanillaPorDia);

              //Verificar si la persona asistió en día feriado o descanso pagado
              // if (
              //   (personaEncontrada.tipo_jornada === 'Asistencia' ||
              //     personaEncontrada.tipo_jornada === 'A' ||
              //     personaEncontrada.tipo_jornada === 'Tardanza') &&
              //   esHoyFeriadoODescansoPagado
              // ) {
              //   // console.log('feriado', moment(asistencia.fecha_asistencia));
              //   // agregar monto equivalente a un día de remuneración por concepto Sunat 0308
              //   let ingresoEncontrado = persona.ingresos_por_concepto.find(
              //     (ingreso) => ingreso.codigo === '0308'
              //   );
              //   if (ingresoEncontrado) {
              //     ingresoEncontrado.monto =
              //       ingresoEncontrado.monto + montoRemuneracionDiaria;
              //   } else {
              //     persona.ingresos_por_concepto.push({
              //       codigo: '0308',
              //       monto: montoRemuneracionDiaria,
              //     });
              //   }
              // }

              //Calcular dias efectivos y de beneficios sociales basados en los tipos de jornada
              if (
                personaEncontrada.tipo_jornada === 'Falto' ||
                personaEncontrada.tipo_jornada === 'F' ||
                personaEncontrada.tipo_jornada === 'S' ||
                personaEncontrada.tipo_jornada === 'Suspensión' ||
                // personaEncontrada.tipo_jornada === 'V' ||
                // personaEncontrada.tipo_jornada === 'Vacaciones' ||
                personaEncontrada.tipo_jornada ===
                  'Permiso sin goce de haber' ||
                personaEncontrada.tipo_jornada === 'Licencia por fallecimiento'
              ) {
                // if (this.nuevaPlanillaCreada.tipo_calculo === 'horas') {
                //   //agregar cálculo por horas usando tmb el concepto de tardanza
                // }
                persona.cantidad_dias_efectivos--;

                persona.ingresos_por_concepto.map((ingreso) => {
                  if (ingreso.codigo === 'ADELANTO') {
                    ingreso.monto = ingreso.monto - montoRemuneracionDiaria;
                    if (ingreso.monto < 0) {
                      ingreso.monto = 0;
                    }
                  }
                  return ingreso;
                });
              }
            }
          } else {
            //si la persona no se encuentra en la asistencia evaluada
            // console.log(persona);
            if (!persona.info_dias) persona.info_dias = [];

            persona.cantidad_dias_efectivos--;

            persona.ingresos_por_concepto.map((ingreso) => {
              if (ingreso.codigo === 'ADELANTO') {
                ingreso.monto = ingreso.monto - montoRemuneracionDiaria;
                if (ingreso.monto < 0) {
                  ingreso.monto = 0;
                }
              }
              return ingreso;
            });
          }

          return asistencia;
        });

        //lógica cuando no existan días efectivos laborados
        if (persona.cantidad_dias_efectivos <= 0) {
          persona.cantidad_dias_efectivos = 0;
          //limpiar ingresos por rem y asign si existen

          persona.ingresos_por_concepto.map((ingreso) => {
            if (ingreso.codigo === 'ADELANTO') {
              ingreso.monto = 0;
            }
            return ingreso;
          });
        }

        let remuneracion = persona.ingresos_por_concepto.find(
          (ingreso) => ingreso.codigo === 'ADELANTO'
        );
        // Se redondea el monto
        remuneracion.monto = Math.round(remuneracion.monto * 100) / 100;

        if (remuneracion) {
          montoRemuneracionTotalPeriodo =
            montoRemuneracionTotalPeriodo + remuneracion.monto;
        }

        return persona;
      });

      this.showLoadingDialog = false;
      this.tableLoading = false;
      this.renderTable++;
    },
    async obtenerSeisUltimasPlanillasDeRemuneracion() {
      let periodosPlanillaRemuneracion = [];
      //obtener las 6 últimas planillas de remuneración a partir de este periodo de planilla para buscar ingresos afectos a EsSalud > 2 periodos
      const mesPeriodoActual = this.nuevaPlanillaCreada.periodo.split('-')[0];
      const anioPeriodo = this.nuevaPlanillaCreada.periodo.split('-')[1];

      // console.log(this.procesoPlanilla, mesPeriodoActual, anioPeriodo);
      const mesDelAnio = this.mesesDelAnio.find(
        (mes) => mes.mes === mesPeriodoActual
      );
      let indiceMesInicio = mesDelAnio.numeroMes - 1; // para iniciar la búsqueda de las 6 planillas de rem el mes anterio al periodo actual
      let indiceMesFin = indiceMesInicio - 6;

      for (let index = indiceMesInicio; index > indiceMesFin; index--) {
        let anioABuscarPlanilla = Number(anioPeriodo);
        let mes = '';
        if (index <= 0) {
          anioABuscarPlanilla = anioABuscarPlanilla - 1;
          switch (index) {
            case 0:
              mes = 'Diciembre';
              break;
            case -1:
              mes = 'Noviembre';
              break;
            case -2:
              mes = 'Octubre';
              break;
            case -3:
              mes = 'Septiembre';
              break;
            case -4:
              mes = 'Agosto';
              break;
            case -5:
              mes = 'Julio';
              break;

            default:
              break;
          }
        } else {
          const mesDelPeriodo = this.mesesDelAnio.find(
            (mes) => mes.numeroMes === index
          );
          mes = mesDelPeriodo.mes;
        }
        let periodoABuscar = `${mes}-${anioABuscarPlanilla}`;
        let periodoEncontrado = this.planillasRemuneracionDelAnioDelPeriodo.filter(
          (planilla) => planilla.periodo === periodoABuscar
        );
        // console.log(periodoABuscar);
        if (periodoEncontrado.length > 0) {
          //lógica por si hay varias planillas
          let periodoEditado = {
            personal: [],
            periodo: '',
            fecha_inicio: '',
            fecha_fin: '',
          };

          periodoEncontrado.map((planilla) => {
            if (periodoEditado.personal.length === 0) {
              (periodoEditado.personal = planilla.personal),
                (periodoEditado.periodo = planilla.periodo),
                (periodoEditado.fecha_inicio = planilla.fecha_inicio),
                (periodoEditado.fecha_fin = planilla.fecha_fin);
            } else {
              planilla.personal.map((persona) => {
                const personaEncontrada = periodoEditado.personal.find(
                  (trabajador) =>
                    trabajador.doi.toString() === persona.doi.toString()
                );

                if (personaEncontrada) {
                  personaEncontrada.ingresos_por_concepto.map(
                    (personaEncontradaIngreso) => {
                      let ingresoEncontrado = persona.ingresos_por_concepto.find(
                        (ingreso) =>
                          ingreso.codigo === personaEncontradaIngreso.codigo
                      );

                      if (ingresoEncontrado) {
                        personaEncontradaIngreso.monto =
                          personaEncontradaIngreso.monto +
                          Number(ingresoEncontrado.monto);
                      } else {
                        personaEncontrada.ingresos_por_concepto.push(
                          ingresoEncontrado
                        );
                      }
                      return personaEncontradaIngreso;
                    }
                  );

                  // personaEncontrada.cantidad_dias_efectivos_beneficios_sociales =
                  //   personaEncontrada.cantidad_dias_efectivos_beneficios_sociales +
                  //   persona.cantidad_dias_efectivos_beneficios_sociales;
                } else {
                  periodoEditado.personal.push(persona);
                }

                return persona;
              });
              periodoEditado.periodo = planilla.periodo;
              if (periodoEditado.fecha_inicio === '') {
                periodoEditado.fecha_inicio = planilla.fecha_inicio;
              }
              periodoEditado.fecha_fin = planilla.fecha_fin;
            }

            return planilla;
          });

          periodosPlanillaRemuneracion.push(periodoEditado);
        } else {
          //buscar por API
          const { planillas } = await getPlanillaPorPeriodoYPorTipo(
            periodoABuscar,
            'Remuneración'
          );
          // console.log(planillas);
          if (planillas.length > 0) {
            //lógica por si hay varias planillas
            let periodoEditado = {
              personal: [],
              periodo: '',
              fecha_inicio: '',
              fecha_fin: '',
            };

            planillas.map((planilla) => {
              if (periodoEditado.personal.length === 0) {
                (periodoEditado.personal = planilla.personal),
                  (periodoEditado.periodo = planilla.periodo),
                  (periodoEditado.fecha_inicio = planilla.fecha_inicio),
                  (periodoEditado.fecha_fin = planilla.fecha_fin);
              } else {
                planilla.personal.map((persona) => {
                  const personaEncontrada = periodoEditado.personal.find(
                    (trabajador) =>
                      trabajador.doi.toString() === persona.doi.toString()
                  );

                  if (personaEncontrada) {
                    personaEncontrada.ingresos_por_concepto.map(
                      (personaEncontradaIngreso) => {
                        let ingresoEncontrado = persona.ingresos_por_concepto.find(
                          (ingreso) =>
                            ingreso.codigo === personaEncontradaIngreso.codigo
                        );

                        if (ingresoEncontrado) {
                          personaEncontradaIngreso.monto =
                            personaEncontradaIngreso.monto +
                            Number(ingresoEncontrado.monto);
                        } else {
                          personaEncontrada.ingresos_por_concepto.push(
                            ingresoEncontrado
                          );
                        }
                        return personaEncontradaIngreso;
                      }
                    );

                    // personaEncontrada.cantidad_dias_efectivos_beneficios_sociales =
                    //   personaEncontrada.cantidad_dias_efectivos_beneficios_sociales +
                    //   persona.cantidad_dias_efectivos_beneficios_sociales;
                  } else {
                    periodoEditado.personal.push(persona);
                  }

                  return persona;
                });
                periodoEditado.periodo = planilla.periodo;
                if (periodoEditado.fecha_inicio === '') {
                  periodoEditado.fecha_inicio = planilla.fecha_inicio;
                }
                periodoEditado.fecha_fin = planilla.fecha_fin;
              }

              return planilla;
            });

            periodosPlanillaRemuneracion.push(periodoEditado);
            // periodosPlanillaRemuneracion.push(...planillas);
          }
        }
      }
      return periodosPlanillaRemuneracion;
    },
    async procesarDatosDelosGraficos(planillas) {
      let totalHorasLaboradasPeriodoAnterior = 0;
      let totalMontoRemuneracionPeriodoAnterior = 0;
      let totalMontoAsignacionPeriodoAnterior = 0;
      let periodoPlanillaAnterior = '';

      planillas.map((planillaPeriodoAnterior) => {
        periodoPlanillaAnterior = planillaPeriodoAnterior.periodo;

        planillaPeriodoAnterior.personal.map((persona) => {
          let remuneracion = persona.ingresos_por_concepto.find(
            (ingreso) => ingreso.codigo === '0121'
          );
          let montoRemuneracion = 0;
          if (remuneracion) {
            montoRemuneracion = remuneracion.monto;
          }

          totalMontoRemuneracionPeriodoAnterior =
            totalMontoRemuneracionPeriodoAnterior + montoRemuneracion;

          let asignacion = persona.ingresos_por_concepto.find(
            (ingreso) => ingreso.codigo === '0201'
          );
          let montoAsignacion = 0;
          if (asignacion) {
            montoAsignacion = asignacion.monto;
          }

          totalMontoAsignacionPeriodoAnterior =
            totalMontoAsignacionPeriodoAnterior + montoAsignacion;

          persona.info_dias.map((dia) => {
            totalHorasLaboradasPeriodoAnterior =
              totalHorasLaboradasPeriodoAnterior + dia.total_minutos_laborados;
            return dia;
          });

          return persona;
        });

        totalMontoRemuneracionPeriodoAnterior =
          Math.round(totalMontoRemuneracionPeriodoAnterior * 100) / 100;

        totalMontoAsignacionPeriodoAnterior =
          Math.round(totalMontoAsignacionPeriodoAnterior * 100) / 100;

        totalHorasLaboradasPeriodoAnterior =
          totalHorasLaboradasPeriodoAnterior / 60;

        totalHorasLaboradasPeriodoAnterior =
          Math.round(totalHorasLaboradasPeriodoAnterior * 100) / 100;

        return planillaPeriodoAnterior;
      });

      const nuevoGraficoHorasLaboradas = {
        name: periodoPlanillaAnterior,
        type: 'column',
        data: [totalHorasLaboradasPeriodoAnterior],
      };
      const nuevoGraficoRemuneracion = {
        name: periodoPlanillaAnterior,
        type: 'column',
        data: [totalMontoRemuneracionPeriodoAnterior],
      };
      const nuevoGraficoAsignacionFamiliar = {
        name: periodoPlanillaAnterior,
        type: 'column',
        data: [totalMontoAsignacionPeriodoAnterior],
      };

      this.seriesHorasLaboradasTotalesPorPeriodos.unshift(
        nuevoGraficoHorasLaboradas
      );
      this.seriesRemuneracionPorPeriodos.unshift(nuevoGraficoRemuneracion);
      this.seriesAsignacionFamiliarPorPeriodos.unshift(
        nuevoGraficoAsignacionFamiliar
      );
    },
    async prepararGraficos() {
      const mesPeriodoActual = this.nuevaPlanillaCreada.periodo.split('-')[0];
      const anioPeriodoActual = this.nuevaPlanillaCreada.periodo.split('-')[1];

      const mesDelAnio = this.mesesDelAnio.find(
        (mes) => mes.mes === mesPeriodoActual
      );

      if (mesDelAnio.numeroMes === 1) {
        //Enero
        const noviembre = this.mesesDelAnio.find((mes) => mes.numeroMes === 11);
        const diciembre = this.mesesDelAnio.find((mes) => mes.numeroMes === 12);
        const anioAnterior = Number(anioPeriodoActual) - 1;

        //Buscar planilla de remuneración de Noviembre del año anterior al evaluado
        const {
          planillas: planillaNoviembre,
          tokenExpired,
        } = await getPlanillaPorPeriodoYPorTipo(
          `${noviembre.mes}-${anioAnterior}`,
          'Remuneración'
        );
        if (tokenExpired) {
          routeToLoginIfTokenIsExpired(this);
        }
        if (planillaNoviembre) {
          this.$store.state.planillasNoviembreAnioPasado = planillaNoviembre;
          this.procesarDatosDelosGraficos(planillaNoviembre);
        }

        //Buscar planilla de remuneración de Diciembre del año anterior al evaluado
        const {
          planillas: planillaDiciembre,
        } = await getPlanillaPorPeriodoYPorTipo(
          `${diciembre.mes}-${anioAnterior}`,
          'Remuneración'
        );
        if (planillaDiciembre) {
          this.$store.state.planillasDiciembreAnioPasado = planillaDiciembre;
          this.procesarDatosDelosGraficos(planillaDiciembre);
        }
      }
      if (mesDelAnio.numeroMes === 2) {
        //Febrero
        const enero = this.mesesDelAnio.find((mes) => mes.numeroMes === 1);
        const diciembre = this.mesesDelAnio.find((mes) => mes.numeroMes === 12);
        const anioAnterior = Number(anioPeriodoActual) - 1;

        //Buscar la planilla de remuneración de Enero
        const planillasEnero = this.planillasRemuneracionDelAnioDelPeriodo.filter(
          (planilla) => planilla.periodo === `${enero.mes}-${anioPeriodoActual}`
        );

        if (planillasEnero.length > 0) {
          this.procesarDatosDelosGraficos(planillasEnero);
        }

        //Buscar planilla de remuneración de Diciembre del año anterior al evaluado
        const {
          error,
          planillas,
          tokenExpired,
        } = await getPlanillaPorPeriodoYPorTipo(
          `${diciembre.mes}-${anioAnterior}`,
          'Remuneración'
        );
        if (error) {
          //do something
        }
        if (tokenExpired) {
          routeToLoginIfTokenIsExpired(this);
        }
        if (planillas) {
          this.$store.state.planillasDiciembreAnioPasado = planillas;
          this.procesarDatosDelosGraficos(planillas);
        }
      }
      if (mesDelAnio.numeroMes > 2) {
        //Resto de meses y filtro para buscar únicamente los dos periodos anteriores al actual
        for (let index = 1; index < 3; index++) {
          const periodoAnterior = this.mesesDelAnio.find(
            (mes) => mes.numeroMes === mesDelAnio.numeroMes - index
          );

          const planillasEncontradas = this.planillasRemuneracionDelAnioDelPeriodo.filter(
            (planilla) =>
              planilla.periodo === `${periodoAnterior.mes}-${anioPeriodoActual}`
          );

          if (planillasEncontradas.length > 0) {
            this.procesarDatosDelosGraficos(planillasEncontradas);
          }
        }
      }

      this.seriesHorasLaboradasTotalesPorPeriodos.push({
        name: this.nuevaPlanillaCreada.periodo,
        type: 'column',
        data: [this.totalHorasLaboradasPeriodoActual],
      });

      this.seriesRemuneracionPorPeriodos.push({
        name: this.nuevaPlanillaCreada.periodo,
        type: 'column',
        data: [this.montoRemuneracionTotalPeriodoActual],
      });

      this.seriesAsignacionFamiliarPorPeriodos.push({
        name: this.nuevaPlanillaCreada.periodo,
        type: 'column',
        data: [this.montoAsignacioTotalPeriodoActual],
      });

      this.showLoadingDialog = false;
    },
    siguientePaso() {
      if (this.nuevaPlanillaCreada.tipo_proceso === 'CTS') {
        this.$store.state.procesoPlanilla.pasoDeProcesoActual = 5;
        return;
      }
      this.$store.state.procesoPlanilla.pasoDeProcesoActual = 3;
    },
    borrarDatosDeEstePaso() {
      this.nuevaPlanillaCreada.personal.map((persona) => {
        persona.ingresos_por_concepto = [];
        persona.info_dias =
          !!persona.info_dias && persona.info_dias.length > 0 ? [] : null;
        persona.remuneracionComputable =
          !!persona.remuneracionComputable && persona.remuneracionComputable > 0
            ? 0
            : null;
        persona.ingresosAfectosAEsSaludPorPeriodo =
          !!persona.ingresosAfectosAEsSaludPorPeriodo &&
          persona.ingresosAfectosAEsSaludPorPeriodo.length > 0
            ? []
            : null;
        persona.montoAPagarPorCTS =
          !!persona.montoAPagarPorCTS && persona.montoAPagarPorCTS > 0
            ? 0
            : null;
        persona.cantidad_dias_efectivos = 0;
        persona.cantidad_dias_efectivos_beneficios_sociales = 0;
        persona.montoAPagarPorGratificacion =
          !!persona.montoAPagarPorGratificacion &&
          persona.montoAPagarPorGratificacion > 0
            ? 0
            : null;
        persona.cantidadDePlanillasDeRemuneracion =
          !!persona.cantidadDePlanillasDeRemuneracion &&
          persona.cantidadDePlanillasDeRemuneracion > 0
            ? 0
            : null;
        persona.cantidadDiasVacaciones =
          !!persona.cantidadDiasVacaciones && persona.cantidadDiasVacaciones > 0
            ? 0
            : null;
        persona.montoPorVacacionesTruncas =
          !!persona.montoPorVacacionesTruncas &&
          persona.montoPorVacacionesTruncas > 0
            ? 0
            : null;
        persona.vacacionesPendientes =
          !!persona.vacacionesPendientes && persona.vacacionesPendientes > 0
            ? 0
            : null;
        persona.dias_vacaciones_indemnizables =
          !!persona.dias_vacaciones_indemnizables &&
          persona.dias_vacaciones_indemnizables > 0
            ? 0
            : null;
        persona.dias_vacaciones_truncas =
          !!persona.dias_vacaciones_truncas &&
          persona.dias_vacaciones_truncas > 0
            ? 0
            : null;
        return persona;
      });
    },
    volverAlPasoAnterior() {
      this.borrarDatosDeEstePaso();
      this.$store.state.procesoPlanilla.pasoDeProcesoActual = 1;
    },
    showErrorAlert(text) {
      this.$swal
        .fire({
          title: `Error`,
          text,
          icon: 'error',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok',
        })
        .then(() => {
          // if (result.isConfirmed) {
          //   console.log('se presionó OK');
          // }
          this.$store.state.procesoPlanilla.pasoDeProcesoActual = 1;
        });
    },
    async calcularVacacionesTruncas(persona) {
      persona = obtenerVacacionesCumplidasYProgramadas(persona);
      persona = obtenerVacacionesPendientes(
        persona,
        moment(persona.fecha_cese),
        this.cliente
      );
      persona.montoPorVacacionesTruncas = 0;
      const cantidadAniosLaborados = moment(persona.fecha_cese).diff(
        moment(persona.fecha_ingreso),
        'years',
        true
      );

      //vacaciones truncas
      const cantidadDiasVacacionesTruncas =
        (Number(cantidadAniosLaborados)
          .toFixed(2)
          .split('.')[1] /
          100) *
        30;

      let vacacionesIndemnizables = 0;

      if (
        Number(cantidadAniosLaborados)
          .toFixed(2)
          .split('.')[0] >= 2
      ) {
        vacacionesIndemnizables =
          persona.vacacionesPendientes -
            Math.round(cantidadDiasVacacionesTruncas) -
            30 <=
          0
            ? 0
            : persona.vacacionesPendientes -
              Math.round(cantidadDiasVacacionesTruncas) -
              30;
      }

      const vacacionesTruncas =
        persona.vacacionesPendientes - vacacionesIndemnizables;
      // console.log(persona);
      // console.log(vacacionesTruncas);
      //se calcula el monto a pagar (remuneración mensual entre 30 por días * dias pendientes de goce (duplicado si hay vacaciones indemnizables))

      if (vacacionesIndemnizables > 0) {
        persona.montoPorVacacionesTruncas =
          (persona.remuneracion_mensual / 30) * vacacionesIndemnizables * 2;
      }

      persona.montoPorVacacionesTruncas =
        persona.montoPorVacacionesTruncas +
        (persona.remuneracion_mensual / 30) * vacacionesTruncas;

      //buscar y sumar los ingresos afectos a EsSalud de los últimos 6 meses
      persona.cantidadDePlanillasDeRemuneracionVacacionesTruncas = 0;
      // crear el array con las 6 últimas planillas de remuneración unificadas
      let periodosPlanillaRemuneracion = await this.obtenerSeisUltimasPlanillasDeRemuneracion();
      //verificar si existe el array con los ingresos afectos a Essalud obtenidos dentro de los últimos 6 períodos
      if (!persona.ingresosAfectosAEsSaludPorPeriodo) {
        persona.ingresosAfectosAEsSaludPorPeriodo = [];
      }
      //############# AQUI INICIA EL LOOP DENTRO DE CADA PLANILLA DE LAS 6 DE REMUNERACION ######################
      periodosPlanillaRemuneracion.map((planilla) => {
        // agregar el monto y los conceptos de cada ingreso por cada periodo a cada persona

        const personaEncontrada = planilla.personal.find(
          (personalPlanilla) =>
            personalPlanilla.doi.toString() === persona.doi.toString()
        );
        // console.log(personaEncontrada);
        if (personaEncontrada) {
          //si la persona ha ingresado antes del 01 de planilla.periodo[mes] se considera como mes completo
          persona.cantidadDePlanillasDeRemuneracionVacacionesTruncas++;

          //buscar ingresos afectos y considerar la remuneración para la suma
          personaEncontrada.ingresos_por_concepto.map((ingreso) => {
            //buscar ingresos afectos
            let ingresoAfectoAEsSalud = this.conceptosSunat.find(
              (concepto) =>
                concepto.codigo === ingreso.codigo &&
                concepto.afectacion_empleador.essalud_seguro === 'SI' &&
                ingreso.codigo !== '0121' &&
                ingreso.codigo !== '0201'
            );
            if (ingresoAfectoAEsSalud) {
              //buscar en el array persona.ingresosAfectosAEsSaludPorPeriodo si existe este ingreso,
              //si existe -> aumentar en 1 la cantidad de "cantidadDePeriodos" para ese ingreso (luego se promedia y divide entre la cantidadDePlanillasDeRemuneracion)
              let ingresoAfectoEncontrado = persona.ingresosAfectosAEsSaludPorPeriodo.find(
                (ingresoAfecto) => ingresoAfecto.codigo === ingreso.codigo
              );
              if (ingresoAfectoEncontrado) {
                ingresoAfectoEncontrado.montoTotal =
                  ingresoAfectoEncontrado.montoTotal + Number(ingreso.monto);
                ingresoAfectoEncontrado.cantidadPeriodos++;
              } else {
                persona.ingresosAfectosAEsSaludPorPeriodo.push({
                  codigo: ingreso.codigo,
                  montoTotal: Number(ingreso.monto),
                  cantidadPeriodos: 1,
                });
              }
            }
            return ingreso;
          });
          // }
          // }

          // }
        }
        return planilla;
      });

      // console.log('persona con ingresosAfectos si existen', persona);

      // persona.montoAPagarPorVacaciones = 0;
      persona.montoPomedioIngresoAfectoVacacionesTruncas = 0;
      //buscar ingresos afectos en mas de un periodo para promediar y sumar al monto total de remuneracion computable
      persona.ingresosAfectosAEsSaludPorPeriodo.map((ingresoAfecto) => {
        if (ingresoAfecto.cantidadPeriodos > 2) {
          // console.log(ingresoAfecto);
          const montoIngresoAfecto =
            (ingresoAfecto.montoTotal / 6 / 30) *
            persona.cantidadDiasVacaciones;
          persona.montoPorVacacionesTruncas =
            persona.montoPorVacacionesTruncas + montoIngresoAfecto;
          persona.montoPomedioIngresoAfectoVacacionesTruncas = montoIngresoAfecto;
        }
        return ingresoAfecto;
      });

      //agregar campos para reporte de liquidación
      persona.dias_vacaciones_indemnizables = vacacionesIndemnizables;
      persona.dias_vacaciones_truncas = vacacionesTruncas;

      //se calcula el monto a pagar por asignación familiar prorrateado al ser cálculo trunco
      if (this.cliente.datos_empresa.regimen_laboral !== 'MICRO EMPRESA') {
        if (persona.asignacion_familiar === 'SI') {
          persona.montoPorVacacionesTruncas =
            persona.montoPorVacacionesTruncas +
            (Number(this.parametroAsignacionFamiliar.monto) / 30) *
              persona.vacacionesPendientes;
        }
      }

      //agregar concepto de vacaciones truncas de SUNAT para el pago
      let ingresoVacacionestruncas = persona.ingresos_por_concepto.find(
        (ingreso) => ingreso.codigo === '0114'
      );
      if (ingresoVacacionestruncas) {
        ingresoVacacionestruncas.monto =
          ingresoVacacionestruncas.monto + persona.montoPorVacacionesTruncas;
      } else {
        persona.ingresos_por_concepto.push({
          codigo: '0114',
          monto: persona.montoPorVacacionesTruncas,
        });
      }
      // console.log(persona);
    },
    calcularGratificacionTrunca(persona) {
      const mesPeriodo = this.nuevaPlanillaCreada.periodo.split('-')[0];
      const anioPeriodo = this.nuevaPlanillaCreada.periodo.split('-')[1];

      const numeroMesDelAnio = this.mesesDelAnio.find(
        (mes) => mes.mes === mesPeriodo
      );

      let periodosPlanilla = [];
      // let cantidadDiasPeriodoPlanilla = 0;
      let indiceMesInicio = 6;
      let indiceMesFin = 0;

      // para q se pueda pagar por grati trunca, se debe tener mes completo, cts sí considera por días

      if (numeroMesDelAnio.numeroMes >= 7 && numeroMesDelAnio.numeroMes <= 12) {
        indiceMesInicio = 12;
        indiceMesFin = 6;
      }

      // if (numeroMesDelAnio.numeroMes >= 1 && numeroMesDelAnio.numeroMes <= 6) {
      //buscar planillas de remuneración de Enero a Junio por rango de fechas
      for (let index = indiceMesInicio; index > indiceMesFin; index--) {
        const mesDelAnio = this.mesesDelAnio.find(
          (mes) => mes.numeroMes === index
        );

        const planillasEnUnPeriodo = this.planillasRemuneracionDelAnioDelPeriodo.filter(
          (planilla) => planilla.periodo.split('-')[0] === mesDelAnio.mes
        );

        if (planillasEnUnPeriodo.length > 0) {
          let periodoEditado = {
            personal: [],
            periodo: '',
            fecha_inicio: '',
            fecha_fin: '',
          };

          planillasEnUnPeriodo.map((planilla) => {
            if (periodoEditado.personal.length === 0) {
              (periodoEditado.personal = planilla.personal),
                (periodoEditado.periodo = planilla.periodo),
                (periodoEditado.fecha_inicio = planilla.fecha_inicio),
                (periodoEditado.fecha_fin = planilla.fecha_fin);
            } else {
              planilla.personal.map((persona) => {
                const personaEncontrada = periodoEditado.personal.find(
                  (trabajador) =>
                    trabajador.doi.toString() === persona.doi.toString()
                );

                if (personaEncontrada) {
                  personaEncontrada.ingresos_por_concepto.map(
                    (personaEncontradaIngreso) => {
                      let ingresoEncontrado = persona.ingresos_por_concepto.find(
                        (ingreso) =>
                          ingreso.codigo === personaEncontradaIngreso.codigo
                      );

                      if (ingresoEncontrado) {
                        personaEncontradaIngreso.monto =
                          personaEncontradaIngreso.monto +
                          Number(ingresoEncontrado.monto);
                      } else {
                        personaEncontrada.ingresos_por_concepto.push(
                          ingresoEncontrado
                        );
                      }
                      return personaEncontradaIngreso;
                    }
                  );

                  personaEncontrada.cantidad_dias_efectivos_beneficios_sociales =
                    personaEncontrada.cantidad_dias_efectivos_beneficios_sociales +
                    persona.cantidad_dias_efectivos_beneficios_sociales;
                } else {
                  periodoEditado.personal.push(persona);
                }

                return persona;
              });
              periodoEditado.periodo = planilla.periodo;
              if (periodoEditado.fecha_inicio === '') {
                periodoEditado.fecha_inicio = planilla.fecha_inicio;
              }
              periodoEditado.fecha_fin = planilla.fecha_fin;
            }

            return planilla;
          });

          periodosPlanilla.push(periodoEditado);
        }
      }

      //calcular la cantidad de días totales del período para dividir entre 180
      //inicializar el contador total de días computables para Gratificación (el máximo es 180)
      persona.cantidad_dias_efectivos_total = 0;
      //verificar si existe el array con los ingresos afectos a Essalud obtenidos dentro de los últimos 6 períodos
      if (!persona.ingresosAfectosAEsSaludPorPeriodo) {
        persona.ingresosAfectosAEsSaludPorPeriodo = [];
      }

      persona.montoAPagarPorGratificacion = 0;
      // persona.cantidadMesesCompletosTrabajados = 0
      persona.cantidadDePlanillasDeRemuneracion = 0;
      // }

      //############# AQUI INICIA EL LOOP DENTRO DE CADA PLANILLA MENSUAL ######################
      // console.log(periodosPlanilla);
      periodosPlanilla.map((planilla) => {
        // console.log(planilla);
        // agregar el monto y los conceptos de cada ingreso por cada periodo a cada persona

        const personaEncontrada = planilla.personal.find(
          (personalPlanilla) =>
            personalPlanilla.doi.toString() === persona.doi.toString()
        );
        // console.log(personaEncontrada);
        if (personaEncontrada) {
          //calcular cantidad total de días efectivos para el cálculo
          //si ingresa el 2 o más de un mes del cálculo no se considera ese mes para el cálculo

          const mesPeriodoAEvaluar = planilla.periodo.split('-')[0];
          const mesPlanilla = this.mesesDelAnio.find(
            (mes) => mes.mes === mesPeriodoAEvaluar
          );

          //si la fecha de ingreso de esta persona es menor al 01 del mes en evaluación, entonces se considera el mes para el cálculo, de lo contrario se pasa al sgte mes
          const fechaMinimaIngreso = moment(
            `${Number(anioPeriodo)}-${mesPlanilla.numeroMes}-01`,
            'YYYY-MM-DD'
          ).tz('America/Lima');

          if (moment(persona.fecha_ingreso) <= fechaMinimaIngreso) {
            //se consulta la fecha de cese para capturar meses/periodos completos
            if (
              !!persona.fecha_cese &&
              moment(persona.fecha_cese).tz('America/Lima') >=
                moment(planilla.fecha_fin).tz('America/Lima')
            ) {
              //si la persona ha ingresado antes del 01 de planilla.periodo[mes] se considera como mes completo
              persona.cantidadDePlanillasDeRemuneracion++;
              // persona.cantidadMesesCompletosTrabajados++

              //verificar si se incluirá vacaciones y/o DMs entre otros
              persona.cantidad_dias_efectivos_total =
                persona.cantidad_dias_efectivos_total +
                personaEncontrada.cantidad_dias_efectivos_beneficios_sociales;
              //promedio de ingresos adicionales (distintos a la remuneracion basica y asignacion familiar)
              //buscar ingresos afectos y considerar la remuneración para la suma
              personaEncontrada.ingresos_por_concepto.map((ingreso) => {
                //buscar ingresos afectos
                let ingresoAfectoAEsSalud = this.conceptosSunat.find(
                  (concepto) =>
                    concepto.codigo === ingreso.codigo &&
                    concepto.afectacion_empleador.essalud_seguro === 'SI' &&
                    ingreso.codigo !== '0121' &&
                    ingreso.codigo !== '0201'
                );
                if (ingresoAfectoAEsSalud) {
                  //buscar en el array persona.ingresosAfectosAEsSaludPorPeriodo si existe este ingreso,
                  //si existe -> aumentar en 1 la cantidad de "cantidadDePeriodos" para ese ingreso (luego se promedia y divide entre la cantidadDePlanillasDeRemuneracion)
                  let ingresoAfectoEncontrado = persona.ingresosAfectosAEsSaludPorPeriodo.find(
                    (ingresoAfecto) => ingresoAfecto.codigo === ingreso.codigo
                  );
                  if (ingresoAfectoEncontrado) {
                    ingresoAfectoEncontrado.montoTotal =
                      ingresoAfectoEncontrado.montoTotal +
                      Number(ingreso.monto);
                    ingresoAfectoEncontrado.cantidadPeriodos++;
                  } else {
                    persona.ingresosAfectosAEsSaludPorPeriodo.push({
                      codigo: ingreso.codigo,
                      montoTotal: Number(ingreso.monto),
                      cantidadPeriodos: 1,
                    });
                  }
                }
                return ingreso;
              });
            }
          }

          // }
        }
        return planilla;
      });
      // }
      //   return persona;
      // });
      //agregar campo necesario para reporte de liquidación
      persona.meses_gratificacion_trunca =
        persona.cantidadDePlanillasDeRemuneracion;
      //calcular totales
      // this.nuevaPlanillaCreada.personal.map((persona) => {
      // calcular grati-sueldo remuneracion básica
      if (
        persona.cantidadDePlanillasDeRemuneracion > 0 &&
        persona.cantidad_dias_efectivos_total > 0
      ) {
        const montoPorRemuneracion =
          (persona.remuneracion_mensual / 180) *
          persona.cantidad_dias_efectivos_total;
        persona.montoAPagarPorGratificacion =
          persona.montoAPagarPorGratificacion + montoPorRemuneracion;
        // console.log(montoPorRemuneracion);
        // calcular grati-asignacion_familiar
        if (this.cliente.datos_empresa.regimen_laboral !== 'MICRO EMPRESA') {
          if (persona.asignacion_familiar === 'SI') {
            const montoPorAsignacionFamiliar =
              (Number(this.parametroAsignacionFamiliar.monto) / 180) *
              persona.cantidad_dias_efectivos_total;
            persona.montoAPagarPorGratificacion =
              persona.montoAPagarPorGratificacion + montoPorAsignacionFamiliar;
            persona.montoPorAsignacionFamiliar = montoPorAsignacionFamiliar;
          }
        }
        // console.log(montoPorAsignacionFamiliar);
        //calcular grati-conceptos_afectos_essalud
        //buscar ingresos afectos en mas de un periodo para promediar y sumar al monto total de remuneracion computable
        persona.ingresosAfectosAEsSaludPorPeriodo.map((ingresoAfecto) => {
          if (ingresoAfecto.cantidadPeriodos > 2) {
            const montoIngresoAfecto =
              (ingresoAfecto.montoTotal /
                ingresoAfecto.cantidadPeriodos /
                180) *
              persona.cantidad_dias_efectivos_total;
            persona.montoAPagarPorGratificacion =
              persona.montoAPagarPorGratificacion + montoIngresoAfecto;
            persona.montoIngresoAfecto = montoIngresoAfecto;
          }
          return ingresoAfecto;
        });
      }
      //si la empresa tiene el regimen laboral de PEQUEÑA EMPRESA entonces se divide el resultado a la mitad
      if (
        this.cliente.datos_empresa.regimen_laboral === 'PEQUEÑA EMPRESA' &&
        persona.montoAPagarPorGratificacion > 0
      ) {
        persona.montoAPagarPorGratificacion =
          persona.montoAPagarPorGratificacion / 2;
      }
      //agregar concepto de gratificacion fiestas patrias y navidad
      let ingresoGratificacion = persona.ingresos_por_concepto.find(
        (ingreso) => ingreso.codigo === '0406'
      );
      if (ingresoGratificacion) {
        ingresoGratificacion.monto =
          ingresoGratificacion.monto + persona.montoAPagarPorGratificacion;
      } else {
        persona.ingresos_por_concepto.push({
          codigo: '0406',
          monto: persona.montoAPagarPorGratificacion,
        });
      }
      //datos para reporte de liquidación
      persona.montoAPagarPorGratificacionTrunca =
        persona.montoAPagarPorGratificacion;
      //calcular grati-bonif_extraordinaria
      let montoGratificacionBonificacionExtraordinaria;
      if (persona.tipo_seguro && persona.tipo_seguro === 'ESSALUD') {
        //si es Essalud se calcula el 9%, si es EPS el 6.75%
        montoGratificacionBonificacionExtraordinaria =
          persona.montoAPagarPorGratificacion *
          Number(this.parametroEsSalud.porcentaje);
      }
      if (persona.tipo_seguro && persona.tipo_seguro === 'EPS') {
        //si es EPS restar el parametro de essalud - parametro essalud eps
        montoGratificacionBonificacionExtraordinaria =
          persona.montoAPagarPorGratificacion *
          Number(this.porcentajeDescuentoPorEPS);
      }
      // console.log(Number(this.parametroEsSalud.porcentaje));
      // console.log(montoGratificacionBonificacionExtraordinaria);
      //agregar concepto de bonificacion extraordinaria
      let ingresoPorBonificacion = persona.ingresos_por_concepto.find(
        (ingreso) => ingreso.codigo === '0407'
      );
      if (ingresoPorBonificacion) {
        ingresoPorBonificacion.monto =
          ingresoPorBonificacion.monto +
          montoGratificacionBonificacionExtraordinaria;
      } else {
        persona.ingresos_por_concepto.push({
          codigo: '0407',
          monto: montoGratificacionBonificacionExtraordinaria,
        });
      }

      persona.montoAPagarPorGratificacion =
        persona.montoAPagarPorGratificacion +
        montoGratificacionBonificacionExtraordinaria;
      persona.montoGratificacionBonificacionExtraordinaria = montoGratificacionBonificacionExtraordinaria;

      return persona;
      // });
    },
    async calcularCTSTrunca(persona) {
      // console.log(persona);
      // let person = await obtenerVacacionesCumplidasYProgramadas(persona);
      // console.log(person);

      const mesPeriodo = this.nuevaPlanillaCreada.periodo.split('-')[0];
      const anioPeriodo = this.nuevaPlanillaCreada.periodo.split('-')[1];
      let fechaMinimaIngreso = '';

      const numeroMesDelAnio = this.mesesDelAnio.find(
        (mes) => mes.mes === mesPeriodo
      );
      // crear el array con las 6 planillas de remuneracion
      let periodosPlanilla = [];

      //si la fecha de cese está entre Enero y Abril
      if (numeroMesDelAnio.numeroMes >= 1 && numeroMesDelAnio.numeroMes <= 4) {
        const anioPasado = Number(anioPeriodo) - 1;

        fechaMinimaIngreso = moment({
          year: Number(anioPasado),
          month: 0,
          day: 1,
        })
          .tz('America/Lima')
          .startOf('month');

        // console.log(fechaMinimaIngreso);

        //lógica para buscar planillas basado en mayo
        //buscar de Noviembre a Abril por rango de fechas
        for (let index = 4; index > 0; index--) {
          // inicializar el contador de meses en Abril u Octubre
          const mesDelAnio = this.mesesDelAnio.find(
            (mes) => mes.numeroMes === index
          );

          const planillasEnUnPeriodo = this.planillasRemuneracionDelAnioDelPeriodo.filter(
            (planilla) => planilla.periodo.split('-')[0] === mesDelAnio.mes
          );

          if (planillasEnUnPeriodo.length > 0) {
            let periodoEditado = {
              personal: [],
              periodo: '',
              fecha_inicio: '',
              fecha_fin: '',
            };

            planillasEnUnPeriodo.map((planilla) => {
              if (periodoEditado.personal.length === 0) {
                (periodoEditado.personal = planilla.personal),
                  (periodoEditado.periodo = planilla.periodo),
                  (periodoEditado.fecha_inicio = planilla.fecha_inicio),
                  (periodoEditado.fecha_fin = planilla.fecha_fin);
              } else {
                planilla.personal.map((persona) => {
                  const personaEncontrada = periodoEditado.personal.find(
                    (trabajador) =>
                      trabajador.doi.toString() === persona.doi.toString()
                  );

                  if (personaEncontrada) {
                    personaEncontrada.ingresos_por_concepto.map(
                      (personaEncontradaIngreso) => {
                        let ingresoEncontrado = persona.ingresos_por_concepto.find(
                          (ingreso) =>
                            ingreso.codigo === personaEncontradaIngreso.codigo
                        );

                        if (ingresoEncontrado) {
                          personaEncontradaIngreso.monto =
                            personaEncontradaIngreso.monto +
                            Number(ingresoEncontrado.monto);
                        } else {
                          personaEncontrada.ingresos_por_concepto.push(
                            ingresoEncontrado
                          );
                        }
                        return personaEncontradaIngreso;
                      }
                    );

                    personaEncontrada.cantidad_dias_efectivos_beneficios_sociales =
                      personaEncontrada.cantidad_dias_efectivos_beneficios_sociales +
                      persona.cantidad_dias_efectivos_beneficios_sociales;
                  } else {
                    periodoEditado.personal.push(persona);
                  }

                  return persona;
                });
                periodoEditado.periodo = planilla.periodo;
                if (periodoEditado.fecha_inicio === '') {
                  periodoEditado.fecha_inicio = planilla.fecha_inicio;
                }
                periodoEditado.fecha_fin = planilla.fecha_fin;
              }

              return planilla;
            });

            periodosPlanilla.push(periodoEditado);
          }
        }
        //buscar la planilla de remuneración de Diciembre del anioPeriodo - 1
        const Diciembre = this.mesesDelAnio.find((mes) => mes.numeroMes === 12);
        const {
          planillas: planillaDiciembreAnioPasado,
        } = await getPlanillaPorPeriodoYPorTipo(
          Diciembre.mes + '-' + anioPasado,
          'Remuneración'
        );
        if (planillaDiciembreAnioPasado) {
          periodosPlanilla.push(...planillaDiciembreAnioPasado);
        }
        //buscar la planilla de remuneración de Noviembre del anioPeriodo - 1
        const Noviembre = this.mesesDelAnio.find((mes) => mes.numeroMes === 11);
        const {
          planillas: planillaNoviembreAnioPasado,
        } = await getPlanillaPorPeriodoYPorTipo(
          Noviembre.mes + '-' + anioPasado,
          'Remuneración'
        );
        if (planillaNoviembreAnioPasado) {
          periodosPlanilla.push(...planillaNoviembreAnioPasado);
        }
        //buscar la planilla de gratificación de Diciembre del anioPeriodo - 1
        const {
          planillas: planillaGratificacionDiciembreAnioPasado,
        } = await getPlanillaPorPeriodoYPorTipo(
          Diciembre.mes + '-' + anioPasado,
          'Gratificación'
        );
        if (planillaGratificacionDiciembreAnioPasado) {
          periodosPlanilla.push(...planillaGratificacionDiciembreAnioPasado);
        }
      }

      //si la fecha de cese está entre Mayo y Octubre
      if (numeroMesDelAnio.numeroMes >= 5 && numeroMesDelAnio.numeroMes <= 10) {
        fechaMinimaIngreso = moment({
          year: Number(anioPeriodo),
          month: 4,
          day: 1,
        })
          .tz('America/Lima')
          .startOf('month');
        for (let index = 10; index > 4; index--) {
          // inicializar el contador de meses en Octubre hasta Mayo
          const mesDelAnio = this.mesesDelAnio.find(
            (mes) => mes.numeroMes === index
          );

          const planillasEnUnPeriodo = this.planillasRemuneracionDelAnioDelPeriodo.filter(
            (planilla) => planilla.periodo.split('-')[0] === mesDelAnio.mes
          );

          if (planillasEnUnPeriodo.length > 0) {
            let periodoEditado = {
              personal: [],
              periodo: '',
              fecha_inicio: '',
              fecha_fin: '',
            };

            planillasEnUnPeriodo.map((planilla) => {
              if (periodoEditado.personal.length === 0) {
                (periodoEditado.personal = planilla.personal),
                  (periodoEditado.periodo = planilla.periodo),
                  (periodoEditado.fecha_inicio = planilla.fecha_inicio),
                  (periodoEditado.fecha_fin = planilla.fecha_fin);
              } else {
                planilla.personal.map((persona) => {
                  const personaEncontrada = periodoEditado.personal.find(
                    (trabajador) =>
                      trabajador.doi.toString() === persona.doi.toString()
                  );

                  if (personaEncontrada) {
                    personaEncontrada.ingresos_por_concepto.map(
                      (personaEncontradaIngreso) => {
                        let ingresoEncontrado = persona.ingresos_por_concepto.find(
                          (ingreso) =>
                            ingreso.codigo === personaEncontradaIngreso.codigo
                        );

                        if (ingresoEncontrado) {
                          personaEncontradaIngreso.monto =
                            personaEncontradaIngreso.monto +
                            Number(ingresoEncontrado.monto);
                        } else {
                          personaEncontrada.ingresos_por_concepto.push(
                            ingresoEncontrado
                          );
                        }
                        return personaEncontradaIngreso;
                      }
                    );

                    personaEncontrada.cantidad_dias_efectivos_beneficios_sociales =
                      personaEncontrada.cantidad_dias_efectivos_beneficios_sociales +
                      persona.cantidad_dias_efectivos_beneficios_sociales;
                  } else {
                    periodoEditado.personal.push(persona);
                  }

                  return persona;
                });
                periodoEditado.periodo = planilla.periodo;
                if (periodoEditado.fecha_inicio === '') {
                  periodoEditado.fecha_inicio = planilla.fecha_inicio;
                }
                periodoEditado.fecha_fin = planilla.fecha_fin;
              }

              return planilla;
            });

            periodosPlanilla.push(periodoEditado);
          }

          // const mesDelAnio = this.mesesDelAnio.find(
          //   (mes) => mes.numeroMes === index
          // );

          // this.planillasRemuneracionDelAnioDelPeriodo.map((periodo) => {
          //   // console.log(periodo);
          //   const mes = periodo.periodo.split('-')[0];
          //   // console.log(mes, mesDelAnio.mes);
          //   if (mes === mesDelAnio.mes) {
          //     periodosPlanilla.push(periodo);
          //   }
          //   return periodo;
          // });
        }

        const {
          planillas: planillaGratificacionJulio,
        } = await getPlanillaPorPeriodoYPorTipo(
          'Julio-' + anioPeriodo,
          'Gratificación'
        );
        if (planillaGratificacionJulio) {
          periodosPlanilla.push(...planillaGratificacionJulio);
        }
      }

      //si la fecha de cese está entre Noviembre y Diciembre
      if (
        numeroMesDelAnio.numeroMes >= 11 &&
        numeroMesDelAnio.numeroMes <= 12
      ) {
        fechaMinimaIngreso = moment({
          year: Number(anioPeriodo),
          month: 10,
          day: 1,
        })
          .tz('America/Lima')
          .startOf('month');
        for (let index = 12; index > 10; index--) {
          // inicializar el contador de meses en Octubre hasta Mayo
          const mesDelAnio = this.mesesDelAnio.find(
            (mes) => mes.numeroMes === index
          );

          const planillasEnUnPeriodo = this.planillasRemuneracionDelAnioDelPeriodo.filter(
            (planilla) => planilla.periodo.split('-')[0] === mesDelAnio.mes
          );

          if (planillasEnUnPeriodo.length > 0) {
            let periodoEditado = {
              personal: [],
              periodo: '',
              fecha_inicio: '',
              fecha_fin: '',
            };

            planillasEnUnPeriodo.map((planilla) => {
              if (periodoEditado.personal.length === 0) {
                (periodoEditado.personal = planilla.personal),
                  (periodoEditado.periodo = planilla.periodo),
                  (periodoEditado.fecha_inicio = planilla.fecha_inicio),
                  (periodoEditado.fecha_fin = planilla.fecha_fin);
              } else {
                planilla.personal.map((persona) => {
                  const personaEncontrada = periodoEditado.personal.find(
                    (trabajador) =>
                      trabajador.doi.toString() === persona.doi.toString()
                  );

                  if (personaEncontrada) {
                    personaEncontrada.ingresos_por_concepto.map(
                      (personaEncontradaIngreso) => {
                        let ingresoEncontrado = persona.ingresos_por_concepto.find(
                          (ingreso) =>
                            ingreso.codigo === personaEncontradaIngreso.codigo
                        );

                        if (ingresoEncontrado) {
                          personaEncontradaIngreso.monto =
                            personaEncontradaIngreso.monto +
                            Number(ingresoEncontrado.monto);
                        } else {
                          personaEncontrada.ingresos_por_concepto.push(
                            ingresoEncontrado
                          );
                        }
                        return personaEncontradaIngreso;
                      }
                    );

                    personaEncontrada.cantidad_dias_efectivos_beneficios_sociales =
                      personaEncontrada.cantidad_dias_efectivos_beneficios_sociales +
                      persona.cantidad_dias_efectivos_beneficios_sociales;
                  } else {
                    periodoEditado.personal.push(persona);
                  }

                  return persona;
                });
                periodoEditado.periodo = planilla.periodo;
                if (periodoEditado.fecha_inicio === '') {
                  periodoEditado.fecha_inicio = planilla.fecha_inicio;
                }
                periodoEditado.fecha_fin = planilla.fecha_fin;
              }

              return planilla;
            });

            periodosPlanilla.push(periodoEditado);
          }
          // const mesDelAnio = this.mesesDelAnio.find(
          //   (mes) => mes.numeroMes === index
          // );

          // this.planillasRemuneracionDelAnioDelPeriodo.map((periodo) => {
          //   // console.log(periodo);
          //   const mes = periodo.periodo.split('-')[0];
          //   // console.log(mes, mesDelAnio.mes);
          //   if (mes === mesDelAnio.mes) {
          //     periodosPlanilla.push(periodo);
          //   }
          //   return periodo;
          // });
        }
      }
      // console.log(periodosPlanilla);
      // this.nuevaPlanillaCreada.personal.map((persona) => {
      //inicializar el contador total de días computables para CTS
      persona.cantidad_dias_efectivos_total = 0;
      // inicializar la remuneración computable por persona
      persona.remuneracionComputable = 0;
      //verificar si existe el array con los ingresos afectos a Essalud obtenidos dentro de los últimos 6 períodos
      if (!persona.ingresosAfectosAEsSaludPorPeriodo) {
        persona.ingresosAfectosAEsSaludPorPeriodo = [];
      }

      if (
        moment(persona.fecha_ingreso).tz('America/Lima') <= fechaMinimaIngreso
      ) {
        //calcular si la persona tiene fecha de cese dentro del rango del periodo
        // console.log('es menor');
        //remuneracion basica
        persona.remuneracionComputable = Number(persona.remuneracion_mensual);
        if (this.cliente.datos_empresa.regimen_laboral !== 'MICRO EMPRESA') {
          if (persona.asignacion_familiar === 'SI') {
            //asignacion familiar
            persona.remuneracionComputable =
              persona.remuneracionComputable +
              Number(this.parametroAsignacionFamiliar.monto);
            persona.montoPorAsignacionFamiliar = Number(
              this.parametroAsignacionFamiliar.monto
            );
          }
        }
        //promedio de ingresos adicionales (distintos a la remuneracion basica y asignacion familiar)
        periodosPlanilla.map((planilla) => {
          // console.log(planilla);
          // agregar el monto y los conceptos de cada ingreso por cada periodo a cada persona

          const personaEncontrada = planilla.personal.find(
            (personalPlanilla) =>
              personalPlanilla.doi.toString() === persona.doi.toString()
          );
          // console.log(personaEncontrada);
          if (personaEncontrada) {
            if (planilla.tipo_proceso === 'Gratificación') {
              //calcular la grati según las reglas para CTS
              //ultima grati - bonif extraord / 6
              const ingresoGratiEncontrado = personaEncontrada.ingresos_por_concepto.find(
                (ingreso) => ingreso.codigo === '0406'
              );
              if (ingresoGratiEncontrado) {
                persona.remuneracionComputable =
                  persona.remuneracionComputable +
                  ingresoGratiEncontrado.monto / 6;
                persona.sextoRemuneracion = ingresoGratiEncontrado.monto / 6;
              }
            } else {
              persona.cantidad_dias_efectivos_total =
                persona.cantidad_dias_efectivos_total +
                personaEncontrada.cantidad_dias_efectivos_beneficios_sociales;

              //buscar ingresos afectos y considerar la remuneración para la suma
              personaEncontrada.ingresos_por_concepto.map((ingreso) => {
                //buscar ingresos afectos
                let ingresoAfectoAEsSalud = this.conceptosSunat.find(
                  (concepto) =>
                    concepto.codigo === ingreso.codigo &&
                    concepto.afectacion_empleador.essalud_seguro === 'SI' &&
                    ingreso.codigo !== '0121' &&
                    ingreso.codigo !== '0201'
                );
                if (ingresoAfectoAEsSalud) {
                  //buscar en el array persona.ingresosAfectosAEsSaludPorPeriodo si existe este ingreso,
                  //si existe -> aumentar en 1 la cantidad de "cantidadDePeriodos" para ese ingreso (luego se promedia y divide entre la cantidadDePlanillasDeRemuneracion)
                  let ingresoAfectoEncontrado = persona.ingresosAfectosAEsSaludPorPeriodo.find(
                    (ingresoAfecto) => ingresoAfecto.codigo === ingreso.codigo
                  );
                  if (ingresoAfectoEncontrado) {
                    ingresoAfectoEncontrado.montoTotal =
                      ingresoAfectoEncontrado.montoTotal +
                      Number(ingreso.monto);
                    ingresoAfectoEncontrado.cantidadPeriodos++;
                  } else {
                    persona.ingresosAfectosAEsSaludPorPeriodo.push({
                      codigo: ingreso.codigo,
                      montoTotal: Number(ingreso.monto),
                      cantidadPeriodos: 1,
                    });
                  }
                }
                return ingreso;
              });
            }
          }
          return planilla;
        });

        //sumar totales
        //buscar ingresos afectos en mas de un periodo para promediar y sumar al monto total de remuneracion computable
        persona.ingresosAfectosAEsSaludPorPeriodo.map((ingresoAfecto) => {
          if (ingresoAfecto.cantidadPeriodos > 2) {
            let promedioIngresoAfecto = ingresoAfecto.montoTotal / 6;
            persona.remuneracionComputable =
              persona.remuneracionComputable + promedioIngresoAfecto;
            persona.montoIngresoAfecto = promedioIngresoAfecto;
          }
          return ingresoAfecto;
        });

        //agregar campo necesario para reporte de liquidación
        persona.dias_cts_truncas = persona.cantidad_dias_efectivos_total;
        //dividir la rem total computable / 2 luego entre 180 y multiplicar por la cantidad de dias efectivos totales
        persona.montoAPagarPorCTS =
          ((persona.remuneracionComputable / 180) * // en lugar de 180 considerar la resta entra el inicio y fin de este periodo
            persona.cantidad_dias_efectivos_total) /
          2;

        //si la empresa tiene el regimen laboral de PEQUEÑA EMPRESA entonces se divide el resultado a la mitad
        if (
          this.cliente.datos_empresa.regimen_laboral === 'PEQUEÑA EMPRESA' &&
          persona.montoAPagarPorCTS > 0
        ) {
          persona.montoAPagarPorCTS = persona.montoAPagarPorCTS / 2;
        }

        //luego de obtener el monto a pagar por concepto de CTS, se debe agregar este monto como ingresos_por_concepto 0904
        let ingresoEncontrado = persona.ingresos_por_concepto.find(
          (ingreso) => ingreso.codigo === '0904'
        );
        if (ingresoEncontrado) {
          ingresoEncontrado.monto =
            ingresoEncontrado.monto + persona.montoAPagarPorCTS;
        } else {
          persona.ingresos_por_concepto.push({
            codigo: '0904',
            monto: persona.montoAPagarPorCTS,
          });
        }
      }
      return persona;
      // });
    },
    consultarFeriadoODescansoPagado(fecha) {
      if (!this.cliente.datos_empresa.feriados) {
        this.cliente.datos_empresa.feriados = [];
      }
      const diaEncontrado = this.cliente.datos_empresa.feriados.find(
        (feriado) =>
          moment(feriado.fecha)
            .tz('America/Lima')
            .format('YYYY-MM-DD') ===
          moment(fecha)
            .tz('America/Lima')
            .format('YYYY-MM-DD')
      );
      return diaEncontrado;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/main.scss';
.v-card {
  overflow-y: auto;
}
.v-card__text,
.v-card__title {
  word-break: normal;
}
</style>
