<template>
  <v-row justify="center">
    <v-dialog
      v-model="selectorPersonas"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="teal">
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Seleccione el personal a procesar</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="seleccionarPersonasAlert">
              Guardar
              <v-icon>mdi-check-bold</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-data-table
          v-model="selected"
          no-results-text="No se encontraron registros..."
          id="table"
          :headers="headers"
          height="93vh"
          fixed-header
          :loading="tableLoading"
          loading-text="Cargando personal... Espere..."
          :items="personal"
          class="elevation-1"
          :search="search"
          hide-default-footer
          disable-pagination
          no-data-text="No hay personal aún..."
          item-key="doi"
          show-select
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar persona..."
                single-line
                hide-details
              ></v-text-field>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapState } from 'vuex';
import moment from 'moment';

export default {
  name: 'SelectorPersonas',
  data() {
    return {
      selected: [],
      search: '',
      tableLoading: true,
      headers: [
        {
          text: 'Documento',
          value: 'doi',
        },
        {
          text: 'Nombres',
          value: 'nombre',
          width: '275px',
        },
        {
          text: 'Cargo',
          value: 'datos_laborales.cargo',
        },

        {
          text: 'Fecha de ingreso',
          value: 'datos_laborales.fecha_ingreso',
        },
        {
          text: 'Tipo de trabajador',
          value: 'datos_laborales.tipo_trabajador',
        },
        {
          text: 'Situación laboral',
          value: 'datos_laborales.situacion',
        },
        {
          text: 'Sueldo básico',
          value:
            'datos_laborales.datos_remuneracion.sueldo_basico_sin_descuentos',
        },
        {
          text: 'Tipo de contrato',
          value: 'datos_laborales.tipo_contrato',
        },
        {
          text: 'Inicio de contrato',
          value: 'datos_laborales.fecha_inicio_contrato',
        },
        {
          text: 'Fin de contrato',
          value: 'datos_laborales.fecha_fin_contrato',
        },
      ],
      personal: [],
    };
  },
  computed: {
    ...mapState([
      'personalActivo',
      'personalCesado',
      'personalCompleto',
      'personalParaProceso',
      'selectorPersonas',
      'nuevaPlanillaCreada',
      'procesoPlanilla',
      'planVigenteActual',
    ]),
  },
  methods: {
    seleccionarPersonasAlert() {
      this.$swal
        .fire({
          title: `Atención!`,
          text: `Está seguro que desea iniciar el proceso de planilla para ${this.selected.length} trabajadores?`,
          icon: 'warning',
          showCancelButton: true,
          cancelButtonColor: '#d33',
          cancelButtonText: 'No',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Sí',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$store.state.personalParaProceso = this.selected;
            this.closeDialog();
          }
        });
    },
    closeDialog() {
      this.$store.state.selectorPersonas = false;
    },
    async getPerson() {
      this.personal = [];

      //se valida el límite de personas a procesar dependiendo del plan contratado
      let limiteDePersonas = 3;
      if (
        this.planVigenteActual.tipo_plan !== '' &&
        this.planVigenteActual.tipo_plan !== 'CTIEmprendedor'
      ) {
        limiteDePersonas = this.personalCompleto.length;
      }

      //se incluye al personal cesado dentro del periodo, el resto de calculos se gestionan dentro del proceso de liquidación
      if (
        this.procesoPlanilla.tipoProceso === 'Remuneración' ||
        this.procesoPlanilla.tipoProceso === 'Locación de servicio' ||
        this.procesoPlanilla.tipoProceso === 'Vacaciones'
      ) {
        //se agrega el personal cesado dentro del periodo seleccionado en el paso 1
        this.personal = this.personalCesado.filter(
          (persona) =>
            moment(persona.datos_laborales.fecha_cese).tz('America/Lima') >=
            moment(this.procesoPlanilla.fechaInicio).tz('America/Lima')
          //   &&
          // moment(persona.datos_laborales.fecha_cese).tz('America/Lima') <=
          //   moment(this.procesoPlanilla.fechaFin).tz('America/Lima')
        );
      }

      //se incluye al personal cesado dentro del periodo y con fecha de cese menor a la fecha de fin de la planilla de adelanto
      if (this.procesoPlanilla.tipoProceso === 'Adelanto de sueldo') {
        //se agrega el personal cesado dentro del periodo seleccionado en el paso 1
        this.personal = this.personalCesado.filter(
          (persona) =>
            moment(persona.datos_laborales.fecha_cese).tz('America/Lima') >=
              moment(this.procesoPlanilla.fechaInicio).tz('America/Lima') &&
            moment(persona.datos_laborales.fecha_cese).tz('America/Lima') <=
              moment(this.procesoPlanilla.fechaFin).tz('America/Lima')
        );
      }

      //se agrega el personal con status = activo y q se encuentren laborando dentro del periodo de cálculo
      const personalActivoDentroDelPeriodo = this.personalActivo.filter(
        (persona) =>
          moment(persona.datos_laborales.fecha_ingreso).tz('America/Lima') <=
          moment(this.procesoPlanilla.fechaFin).tz('America/Lima')
      );
      this.personal.push(
        ...personalActivoDentroDelPeriodo.slice(0, limiteDePersonas)
      );

      switch (this.procesoPlanilla.tipoProceso) {
        case 'Locación de servicio':
          this.personal = this.personal.filter(
            (persona) =>
              persona.datos_laborales.tipo_contrato === 'Locación de servicio'
          );
          break;
        case 'Adelanto de sueldo':
          this.personal = this.personal.filter(
            (persona) => persona.datos_laborales.switchSujetoAAdelantoDeQuincena
          );
          break;

        default:
          this.personal = this.personal.filter(
            (persona) =>
              persona.datos_laborales.tipo_contrato !== 'Locación de servicio'
          );
          break;
      }

      //si el paso actual del proceso de planilla es Ingresos o Descuentos entonces se devuelve el personal ya filtrado
      if (
        this.$store.state.procesoPlanilla.pasoDeProcesoActual === 2 ||
        this.$store.state.procesoPlanilla.pasoDeProcesoActual === 3
      ) {
        this.personal = this.nuevaPlanillaCreada.personal;
      }
      this.tableLoading = false;
    },
  },
  created() {
    this.getPerson();
  },
  watch: {
    selected() {
      this.$store.state.personalParaProceso = this.selected;
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'src/styles/main.scss';
.v-data-table::v-deep th {
  font-size: 16px !important;
  font-weight: bold !important;
  color: teal !important;
}
#table {
  overflow-y: auto;
  overflow-x: auto;
  width: 100%;
  margin: auto;
  td,
  th {
    border: 1px solid rgba(97, 92, 92, 0.253);
  }
  tr:nth-child(even) {
    cursor: pointer;
    background-color: $secondary;
  }
  th {
    height: 30px;
    text-align: justify;
  }
  td {
    height: 30px;
    padding: 0px 10px;
    text-align: justify;
  }
}
#table::-webkit-scrollbar {
  height: 10px;
  width: 4px;
  background: $main;
}
#table::-webkit-scrollbar-thumb {
  background: #313d35;
  border-radius: 10px;
}
</style>
