import fetch from 'isomorphic-fetch';

let ENDPOINT_URL = process.env.VUE_APP_FLOTA_EDIT;

async function updateUnit(unit, dates) {
  const bodyParams = {
    _id: unit._id,
    placa: unit.placa,
    tipo_unidad: unit.tipo_unidad,
    disponible: unit.disponible,
    fecha_mtto_preventivo_inicio: dates.dateoptional,
    fecha_mtto_preventivo_fin: dates.date1optional,
    fecha_mtto_correctivo_inicio: dates.date2optional,
    fecha_mtto_correctivo_fin: dates.date3optional,
    observaciones: unit.observaciones,
    token: localStorage.getItem('token'),
  };

  let body = JSON.stringify(bodyParams);

  return await fetch(ENDPOINT_URL, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  }).then((item) => item.json());
}
export { updateUnit };
