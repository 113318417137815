<template>
  <v-container fluid>
    <v-row class="d-flex flex-column">
      <v-col class="top-bar-buttons-container">
        <v-row no-gutters row>
          <v-col>
            <v-btn-toggle
              v-model="toogle"
              color="teal darken-1"
              mandatory
              rounded
            >
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn @click="abrirProceso" v-on="on">
                    <v-icon color="orange lighten-1"
                      >mdi-alarm-panel-outline</v-icon
                    >
                  </v-btn>
                </template>
                <span>Procesos</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn @click="abrirReportes" v-on="on">
                    <v-icon color="blue darken-1">mdi-chart-timeline</v-icon>
                  </v-btn>
                </template>
                <span>Reportes</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn @click="abrirConfiguracion" v-on="on">
                    <v-icon color="teal">mdi-cog-outline</v-icon>
                  </v-btn>
                </template>
                <span>Configuración</span>
              </v-tooltip>
            </v-btn-toggle>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <Configuracion v-if="configuracion" />
        <Proceso v-if="proceso" />
        <Reportes v-if="reportes" />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';
import { getConceptosSunat } from '@/api/payments/getConceptosSunat.js';
import { getClientData } from '@/api/configuration/getClientData.js';

import Configuracion from '@/components/modules/personnel/planilla/Configuracion';
import Proceso from '@/components/modules/personnel/planilla/Proceso';
import Reportes from '@/components/modules/personnel/planilla/Reportes';

export default {
  data: () => ({
    toogle: 0,
    configuracion: false,
    proceso: true,
    reportes: false,
  }),
  components: {
    Configuracion,
    Proceso,
    Reportes,
  },
  methods: {
    abrirProceso() {
      this.proceso = true;
      this.configuracion = false;
      this.reportes = false;
    },
    abrirConfiguracion() {
      this.proceso = false;
      this.configuracion = true;
      this.reportes = false;
    },
    abrirReportes() {
      this.proceso = false;
      this.configuracion = false;
      this.reportes = true;
    },
    async storeClientData() {
      const { error, tokenExpired, client } = await getClientData();
      if (error) {
        //do somethiung
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (client) {
        this.$store.state.cliente = client;
      }
    },
    async obtenerConceptosSunat() {
      const {
        error,
        tokenExpired,
        conceptos_sunat,
      } = await getConceptosSunat();
      if (error) {
        //do something
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (conceptos_sunat) {
        this.$store.state.conceptosSunat = conceptos_sunat;
      }
    },
  },
  created() {
    this.storeClientData();
    this.obtenerConceptosSunat();
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/main.scss';
.search-right {
  text-align: end !important;
  max-width: 450px;
}
.top-bar-buttons-container {
  text-align: left !important;
}
</style>
