import fetch from 'isomorphic-fetch';

let ENDPOINT_URL = process.env.VUE_APP_EDITAR_ASISTENCIA;
async function editarAsistencia(asistencia) {
  const bodyParams = {
    fecha_asistencia: asistencia.fecha_asistencia,
    observaciones: asistencia.observaciones,
    personal: asistencia.personal,
    token: localStorage.getItem('token'),
  };

  let body = JSON.stringify(bodyParams);

  return await fetch(ENDPOINT_URL, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  }).then((item) => item.json());
}
export { editarAsistencia };
