<template>
  <div>
    <div class="flex">
      <v-row class="align-baseline" rows="6">
        <v-col data-app class="d-flex">
          <v-select
            v-model="estado"
            :items="dropdown1"
            label="Estado"
          ></v-select>
        </v-col>
        <v-col class="d-flex">
          <v-text-field type="date" v-model="range1" label="Inicio" />
        </v-col>
        <v-col class="d-flex">
          <v-text-field
            class="search_date"
            type="date"
            v-model="range2"
            label="Fin"
          />
        </v-col>
        <v-col class="d-flex">
          <v-btn @click="filterbyDate()" class="btn_filter">
            <v-img
              max-width="20px"
              cover
              :src="require('@/assets/menu-icons/filter.svg')"
            />
          </v-btn>
        </v-col>
      </v-row>
      <div class="relative">
        <v-text-field placeholder="Buscar por nombre" v-model="inputSearch" />
        <v-btn>
          <v-img
            max-width="20px"
            cover
            :src="require('@/assets/menu-icons/search.svg')"
          />
        </v-btn>
      </div>
    </div>
    <v-divider horizontal />
    <div class="container ">
      <div v-for="(item1, y) in change" :key="y">
        <div v-if="item1.estado == 'Pendiente'">
          <v-card
            @click="
              overlay = !overlay;
              handleEmit(item1);
            "
            elevation="6"
            :class="[
              item1.estado == 'Pendiente'
                ? 'pendiente'
                : item1.estado == 'Abierto'
                ? 'abierto'
                : item1.estado == 'Cerrado'
                ? 'cerrado'
                : null,
            ]"
          >
            <div class="group-data_incidence">
              <p>{{ item1.ruta }}</p>
              <p>Hora de reporte : {{ item1.hora }}</p>
              <p>Lugar de incidencia : {{ item1.lugar }}</p>
              <p>Creado por : {{ item1.creado }}</p>
            </div>
          </v-card>
        </div>
      </div>
      <div v-for="(item, i) in change" :key="i">
        <div v-if="item.estado == 'Abierto'">
          <v-card
            @click="
              overlay = !overlay;
              handleEmit(item);
            "
            elevation="6"
            :class="[
              item.estado == 'Pendiente'
                ? 'pendiente'
                : item.estado == 'Abierto'
                ? 'abierto'
                : item.estado == 'Cerrado'
                ? 'cerrado'
                : null,
            ]"
          >
            <div class="group-data_incidence">
              <p>{{ item.estado }}</p>
              <p>Hora de reporte : {{ item.hora }}</p>
              <p>Lugar de incidencia : {{ item.lugar }}</p>
              <p>Creado por : {{ item.creado }}</p>
            </div>
          </v-card>
        </div>
      </div>
    </div>
    <div v-if="overlay">
      <complete-info :data="item" />
      <v-btn @click="overlay = false" class="btn-close">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
  </div>
</template>
<script>
import CompleteInfo from './CompleteInfo';
export default {
  data: () => ({
    range1: '',
    range2: '',
    estado: '',
    data: '',
    overlay: false,
    inputSearch: '',
    dropdown1: ['Pendiente', 'Abierto', 'Cerrado', 'Todo'],
    incidencia: [],
    // incidencia: [
    // 	{
    // 		ruta: 'a-123',
    // 		creado: 'Minivan',
    // 		hora: '09:12',
    // 		lugar: 'la victoria',
    // 		estado: 'Pendiente',
    // 		fecha: '2021-03-14',
    // 		placa: 'XME-637',
    // 		text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit.',
    // 	},
    // 	{
    // 		ruta: 'a-124',
    // 		creado: 'Minivan',
    // 		hora: '09:12',
    // 		lugar: 'la victoria',
    // 		estado: 'Abierto',
    // 		fecha: '2021-03-13',
    // 		placa: 'TJÑ-920',
    // 		text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. ',
    // 	},
    // 	{
    // 		ruta: 'a-124',
    // 		creado: 'Minivan',
    // 		hora: '09:12',
    // 		lugar: 'la victoria',
    // 		fecha: '2021-03-12',
    // 		estado: 'Pendiente',
    // 		placa: 'DGH-201',
    // 		text:
    // 			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus ',
    // 	},
    // 	{
    // 		ruta: 'a-125',
    // 		creado: 'Minivan',
    // 		hora: '09:12',
    // 		lugar: 'la victoria',
    // 		fecha: '2021-03-11',
    // 		estado: 'Pendiente',
    // 		placa: 'FBI-785',
    // 		text:
    // 			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus tenetur d',
    // 	},
    // 	{
    // 		ruta: 'a-127',
    // 		creado: 'Minivan',
    // 		hora: '09:12',
    // 		lugar: 'la victoria',
    // 		estado: 'Cerrado',
    // 		fecha: '2021-03-10',
    // 		placa: 'ZAQ-138',
    // 		text:
    // 			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus tenetur doloremque eius laudantium, ',
    // 	},
    // 	{
    // 		ruta: 'a-126',
    // 		creado: 'Minivan',
    // 		hora: '09:12',
    // 		lugar: 'la victoria',
    // 		fecha: '2021-03-09',
    // 		estado: 'Pendiente',
    // 		placa: 'JKL-054',
    // 		text:
    // 			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus tenetur doloremque eius laudantium, doloribus fuga ex, ',
    // 	},
    // 	{
    // 		ruta: 'a-129',
    // 		creado: 'Minivan',
    // 		hora: '09:12',
    // 		lugar: 'la victoria',
    // 		estado: 'Abierto',
    // 		fecha: '2021-03-08',
    // 		placa: 'ZXY-143',
    // 		text:
    // 			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus tenetur doloremque eius laudantium, doloribus fuga ex, ',
    // 	},
    // 	{
    // 		ruta: 'a-130',
    // 		creado: 'Minivan',
    // 		hora: '09:12',
    // 		lugar: 'la victoria',
    // 		estado: 'Pendiente',
    // 		fecha: '2021-03-07',
    // 		placa: 'QÑL-978',
    // 		text:
    // 			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus tenetur doloremque eius laudantium, doloribus fuga ex, explicabo ad, error autem deserunt fugit aspernatur. Incidunt dolorem possimus maxime fuga laboriosam quia Dignissimos, maiores qui! Dolor asperiores quisquam ipsum debitis sapiente est incidunt veniam magni autem ullam in, nobis eligendi odio similique nostrum aliquam',
    // 	},
    // 	{
    // 		ruta: 'a-140',
    // 		creado: 'Minivan',
    // 		hora: '09:12',
    // 		lugar: 'la victoria',
    // 		estado: 'Abierto',
    // 		fecha: '2021-03-06',
    // 		placa: 'DVC-162',
    // 		text:
    // 			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus tenetur doloremque eius laudantium, doloribus fuga ex, explicabo ad, error autem deserunt fugit aspernatur. Incidunt dolorem possimus maxime fuga laboriosam quia Dignissimos, maiores qui! Dolor asperiores quisquam ipsum debitis sapiente est incidunt veniam magni autem ullam in, nobis eligendi odio similique nostrum aliquam',
    // 	},
    // 	{
    // 		ruta: 'a-150',
    // 		creado: 'Minivan',
    // 		hora: '09:12',
    // 		lugar: 'la victoria',
    // 		estado: 'Pendiente',
    // 		fecha: '2021-03-05',
    // 		placa: 'SFB-002',
    // 		text:
    // 			'Lorem ipsum dolor sit amet consectetur adipisicing elit.  ullam in, nobis eligendi odio similique nostrum aliquam',
    // 	},
    // 	{
    // 		ruta: 'a-151',
    // 		creado: 'Minivan',
    // 		hora: '09:12',
    // 		lugar: 'la victoria',
    // 		estado: 'Pendiente',
    // 		fecha: '2021-03-04',
    // 		placa: 'AQB-562',
    // 		text:
    // 			'Lorem ipsum dolor sit amet consectetur adipisicing elit.  ullam in, nobis eligendi odio similique nostrum aliquam',
    // 	},
    // 	{
    // 		ruta: 'a-110',
    // 		creado: 'Pepe Gonzales',
    // 		hora: '09:12',
    // 		lugar: 'la victoria',
    // 		estado: 'Cerrado',
    // 		fecha: '2021-03-03',
    // 		placa: 'AOB-562',
    // 		text:
    // 			'Lorem ipsum dolor sit amet consectetur adipisicing elit. Accusamus tenetur doloremque eius laudantium, doloribus fuga ex, explicabo ad, error autem deserunt fugit aspernatur. Incidunt dolorem possimus maxime fuga laboriosam quia Dignissimos, maiores qui! Dolor asperiores quisquam ipsum debitis sapiente est incidunt veniam magni autem ullam in, nobis eligendi odio similique nostrum aliquam',
    // 	},
    // 	{
    // 		ruta: 'a-103',
    // 		creado: 'Andrés perez',
    // 		hora: '09:12',
    // 		lugar: 'la victoria',
    // 		estado: 'Pendiente',
    // 		fecha: '2021-03-02',
    // 		placa: 'AFB-862',
    // 		text:
    // 			'Lorem ipsum dolor sit amet consectetur adipisicing elit.  ullam in, nobis eligendi odio similique nostrum aliquam',
    // 	},
    // 	{
    // 		ruta: 'a-163',
    // 		creado: 'Alberto ruiz',
    // 		hora: '09:12',
    // 		lugar: 'la victoria',
    // 		estado: 'Abierto',
    // 		fecha: '2021-03-01',
    // 		placa: 'AFB-562',
    // 		text:
    // 			'Lorem ipsum dolor sit amet consectetur adipisicing elit.  ullam in, nobis eligendi odio similique nostrum aliquam',
    // 	},
    // ],
    change: '',
  }),
  components: {
    CompleteInfo,
  },
  watch: {
    estado() {
      this.filterByStatus();
    },
    inputSearch() {
      this.searchByName();
    },
  },
  created() {
    this.change = this.incidencia;
  },
  methods: {
    handleEmit(item) {
      this.item = item;
    },
    filterByStatus() {
      const word = this.estado.toLowerCase();
      this.change = this.incidencia;
      let newArray = [];
      if (this.estado == 'Todo') {
        newArray.push(this.incidencia);
      } else {
        for (let i in this.change) {
          if (this.change[i].estado.toLowerCase().includes(word)) {
            newArray.push(this.change[i]);
          }
        }
        this.change = newArray;
      }
    },
    searchByName() {
      const word = this.inputSearch.toLowerCase();
      this.change = this.incidencia;
      let newArray = [];
      for (let i in this.change) {
        if (
          this.change[i].creado.toLowerCase().includes(word) ||
          this.change[i].placa.toLowerCase().includes(word)
        ) {
          newArray.push(this.change[i]);
        }
      }
      this.change = newArray;
    },
    filterbyDate() {
      let newFilter = [];
      for (let i in this.change) {
        if (
          this.change[i].fecha >= this.range1 &&
          this.change[i].fecha <= this.range2
        ) {
          newFilter.push(this.change[i]);
        }
      }
      this.change = newFilter;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/main.scss';

.v-card {
  padding: 5px;
  margin: 10px;
  height: fit-content;
}
.btn-new {
  margin-top: 12px;
  margin-bottom: 20px;
}
.btn-close {
  position: absolute;
  top: 100px;
  left: 30px;
  color: white !important;
  box-shadow: none !important;
  background-color: #1e1e1e !important;
  border-radius: 50%;
  min-width: 25px !important;
  padding: 10px !important;
  z-index: 6;
}
.search_date::-webkit-inner-spin-button,
.search_date::-webkit-calendar-picker-indicator {
  cursor: pointer;
  outline: none !important;
}
.btn_filter {
  background: transparent !important;
  box-shadow: none;
}
.abierto {
  background-color: #ff8d0080 !important;
}
.cerrado {
  opacity: 0.8;
  color: #2b2828;
  background-color: darkgray !important;
}
.pendiente {
  background-color: rgba(255, 0, 0, 0.466) !important;
}
.flex {
  display: flex;
  height: 70px;
  justify-content: space-between;
}
.group-data_incidence {
  text-align: start;
  p {
    margin: 5px;
  }
}
.align-baseline {
  align-items: baseline;
}
.relative {
  .v-btn {
    right: -71px;
    top: -56px;
    position: relative;
    box-shadow: none !important;
    background-color: transparent !important;
  }
}
.responsive-content {
  top: 30px !important;
}
.container {
  height: 70vh;
  overflow: scroll;
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.v-card__title {
  padding: 0px !important;
  margin: 20px;
}
.inactive {
  opacity: 0.7 !important;
  background-color: darkgray !important;
}
.info-unidades,
.active {
  margin: 15px;
  height: 250px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 200px;
}
.container::-webkit-scrollbar {
  width: 4px;
  background: #efeff6;
}

.container::-webkit-scrollbar-thumb {
  background: $main;
  border-radius: 10px;
}
@media screen and (max-width: 425px) {
  .btn-close {
    position: relative;
    top: -30px;
    right: -1px;
    color: white !important;
    box-shadow: none !important;
    background-color: #1e1e1e !important;
    border-radius: 50%;
    min-width: 25px !important;
    padding: 10px !important;
    z-index: 6;
  }
}
</style>
