import fetch from 'isomorphic-fetch';

const ENDPOINT_URL = process.env.VUE_APP_NUEVA_PLANILLA;
async function crearNuevaPlanilla(planilla) {
  const bodyParams = {
    periodo: planilla.periodo,
    fecha_inicio: planilla.fecha_inicio,
    fecha_fin: planilla.fecha_fin,
    personal: planilla.personal,
    estado: planilla.estado,
    tipo_calculo: planilla.tipo_calculo,
    tipo_proceso: planilla.tipo_proceso,
    periodicidad: planilla.periodicidad,
    token: localStorage.getItem('token'),
  };

  let body = JSON.stringify(bodyParams);

  return await fetch(ENDPOINT_URL, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  }).then((item) => item.json());
}
export { crearNuevaPlanilla };
