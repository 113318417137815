import moment from 'moment';

const obtenerVacacionesPendientes = (persona, fechaDeCorte, cliente) => {
  let vacacionesPendientes = 0;
  let cantidadDiasVacacionesPorAnio = 30;
  if (
    cliente.datos_empresa.regimen_laboral === 'MICRO EMPRESA' ||
    cliente.datos_empresa.regimen_laboral === 'PEQUEÑA EMPRESA'
  ) {
    cantidadDiasVacacionesPorAnio = 15;
  }

  //rate de dias de vacaciones ganados por día laborado en un año de 360 días
  const rateDiario = Number(cantidadDiasVacacionesPorAnio / 365).toFixed(4);
  //obtener fecha de ingreso de la persona
  const { fecha_ingreso } = persona;

  const fechaDeIngreso = moment(fecha_ingreso);

  //cantidad de dias desde la fecha de ingreso hasta la fecha de corte
  const diasLaborados = fechaDeCorte.diff(fechaDeIngreso, 'days');
  const diasDeVacacionesGanados = diasLaborados * rateDiario;

  vacacionesPendientes = Math.round(diasDeVacacionesGanados);

  persona.vacacionesPendientes =
    vacacionesPendientes - persona.vacacionesCumplidasYProgramadas;
  return persona;
};

export default obtenerVacacionesPendientes;
