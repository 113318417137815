<template>
  <v-overlay>
    <v-card>
      <v-row class="m_auto">
        <v-col class="m_5 p_20 left"
          ><p>
            {{ report.estado }}
          </p>
          <p>Hora de reporte : {{ report.hora }}</p>
          <p>Lugar de incidencia : {{ report.lugar }}</p>
          <p>Creado por : {{ report.creado }}</p>
          <p>
            {{ report.text }}
          </p></v-col
        >
        <v-col class="p_20">
          <v-row
            ><v-card-title>Comentarios :</v-card-title>
            <div class="container comments">
              <div
                class="box_comments"
                v-for="(item, i) in comentarios"
                :key="i"
              >
                <v-card-subtitle
                  >{{ item.creado }} - {{ item.fecha }}</v-card-subtitle
                >
                <v-card-text>{{ item.text }}</v-card-text>
              </div>
            </div></v-row
          >
          <v-row>
            <v-card-title class="hide">Comentar :</v-card-title>
            <div class="box_comments_edit">
              <textarea
                v-model="comment"
                placeholder=" Comentar "
                name=""
                id=""
                cols="30"
                rows="3"
              ></textarea>
            </div>
            <v-btn @click="saveComment()" class="reset_btn">
              <v-icon>mdi-check</v-icon>
            </v-btn></v-row
          ></v-col
        >
      </v-row>
    </v-card>
  </v-overlay>
</template>

<script>
export default {
  data: () => ({
    report: '',
    comment: '',
    comentarios: [
      {
        creado: 'Antony Aquino',
        fecha: '18/03/2019',
        text: ' Lorem ipsum dolor sit amet consectetur adipisicing elit.  ',
      },
      {
        creado: 'Carlos Behr',
        fecha: '18/03/2019',
        text: ' Lorem ipsum dolor sit amet consectetur adipisicing elit.  ',
      },
      {
        creado: 'Carlos Huaynates',
        fecha: '18/03/2019',
        text: ' Lorem ipsum dolor sit amet consectetur adipisicing elit.  ',
      },
    ],
  }),
  props: {
    data: Object,
  },
  created() {
    this.report = this.data;
  },
  methods: {
    showSuccessAlert(msj) {
      this.$swal.fire({
        icon: 'success',
        title: msj,
        showConfirmButton: false,
        timer: 3000,
      });
    },
    saveComment() {
      let msj = 'Se guardó el comentario';
      this.comentarios.push({
        creado: 'Usuario',
        fecha: '23/05/2021',
        text: this.comment,
      }),
        this.showSuccessAlert(msj);
    },
    changeStatus() {
      let msj = 'El estado de la incidencia cambió';
      this.showSuccessAlert(msj);
      setTimeout(function() {
        window.location.reload();
      }, 3000);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/main.scss';
.m_auto {
  margin: auto;
}
.left {
  padding-left: 30px;
  text-align: left;
}
.p_20 {
  padding: 20px;
}
.m_5 {
  margin-top: 20px;
}
textarea {
  padding: 10px;
  outline: none;
  color: rgb(66, 66, 66) !important;
}
.v-card {
  width: 70vw !important;
  height: 45vh;
}
.box_comments {
  background: #b9d6a5;
  border-radius: 5px;
  color: rgb(109, 108, 108);
  text-align: start;
  margin: 5px;
  height: fit-content !important;
  .v-card__subtitle {
    color: rgb(66, 66, 66) !important;
  }
}
.box_comments_edit {
  background: #b9d6a5;
  border-radius: 5px;
  color: rgb(109, 108, 108);
  text-align: start;
  margin-top: 10px;
  margin-left: 17px;
  height: 70px;
  width: 20vw;
  overflow: scroll;
  overflow-x: hidden;
}
.box_comments_edit::-webkit-scrollbar,
.comments::-webkit-scrollbar {
  width: 4px;
  background: #efeff6;
}

.box_comments_edit::-webkit-scrollbar-thumb,
.comments::-webkit-scrollbar-thumb {
  background: $main;
  border-radius: 10px;
}
.comments {
  height: 20vh;
  overflow: scroll;
  overflow-x: hidden;
}
.reset_btn {
  background: #67ee0d7c !important;
  position: relative;
  top: 30px;
  left: 10px;
}
@media screen and (max-width: 425px) {
  .v-card {
    height: 80vh;
    overflow: scroll;
  }
  .hide {
    display: none;
  }
  .box_comments_edit {
    width: 50%;
  }
}
</style>
