<template>
  <v-container>
    <div class="container_newregister">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card class="card_data">
          <v-card-title>Datos personales</v-card-title>
          <v-row class="m-10">
            <v-col>
              <v-select
                label="Tipo de documento"
                v-model="dataAEditar.datos_laborales.tipo_documento"
                :items="items"
                :rules="fieldRules"
              />
              <v-text-field
                v-if="dataAEditar.datos_laborales.tipo_documento == 'CE'"
                label="Número de carnet de extranjería"
                v-model.trim="dataAEditar.doi"
                :rules="fieldRules"
              />
              <v-text-field
                v-if="dataAEditar.datos_laborales.tipo_documento == 'Pasaporte'"
                label="Número de pasaporte"
                v-model.trim="dataAEditar.doi"
                :rules="fieldRules"
              />
              <v-text-field
                v-if="dataAEditar.datos_laborales.tipo_documento == 'DNI'"
                label="Número de DNI"
                v-model.trim="dataAEditar.doi"
                :rules="fieldRules"
              />
              <v-text-field
                label="Nombres"
                v-model="dataAEditar.datos_laborales.nombres"
                :rules="fieldRules"
              />
              <v-text-field
                label="Apellido Paterno"
                v-model="dataAEditar.datos_laborales.apellido_paterno"
                :rules="fieldRules"
              />
              <v-text-field
                label="Apellido Materno"
                v-model="dataAEditar.datos_laborales.apellido_materno"
                :rules="fieldRules"
              />
              <v-text-field
                type="date"
                label="Fecha de nacimiento"
                v-model="
                  dataAEditar.datos_laborales.datos_nacimiento.fecha_nacimiento
                "
              />
            </v-col>
            <v-col>
              <v-text-field
                label="Dirección actual"
                v-model="dataAEditar.direccion"
                :rules="fieldRules"
              />
              <v-text-field
                label="País"
                v-model="dataAEditar.datos_laborales.datos_nacimiento.pais"
                :rules="fieldRules"
              />
              <v-autocomplete
                label="Departamento"
                :items="departamentos"
                v-model="
                  dataAEditar.datos_laborales.datos_nacimiento.departamento
                "
                :rules="fieldRules"
              />
              <v-autocomplete
                label="Provincia"
                :items="provincias"
                v-model="dataAEditar.datos_laborales.datos_nacimiento.provincia"
                :rules="fieldRules"
              />
              <v-autocomplete
                label="Distrito"
                :items="distritos"
                v-model="dataAEditar.datos_laborales.datos_nacimiento.distrito"
                :rules="fieldRules"
              />
            </v-col>
            <v-col>
              <v-text-field
                label="Nacionalidad"
                v-model="
                  dataAEditar.datos_laborales.datos_nacimiento.nacionalidad
                "
              />
              <v-select
                label="Estado civil"
                v-model="dataAEditar.datos_laborales.estadoCivil"
                :items="estadoCivil"
              />
              <v-text-field
                label="Email"
                v-model="dataAEditar.email"
                :rules="emailRules"
              />
              <v-text-field
                label="Celular"
                v-model="dataAEditar.datos_laborales.celular"
              />
            </v-col>
          </v-row>
        </v-card>
        <v-divider />

        <v-card class="card_data">
          <v-card-title>Datos laborales</v-card-title>
          <v-row class="m-10">
            <v-col>
              <v-text-field
                label="Fecha de ingreso"
                v-model="dataAEditar.datos_laborales.fecha_ingreso"
                type="date"
                :rules="fieldRules"
              />
              <v-text-field
                disabled
                label="Fecha de cese"
                v-model="dataAEditar.datos_laborales.fecha_cese"
                type="date"
              />
              <v-select
                label="Tipo de pensión"
                v-model="
                  dataAEditar.datos_laborales.datos_remuneracion.tipo_pension
                "
                :items="items2"
                :rules="fieldRules"
                v-if="
                  !(
                    dataAEditar.datos_laborales.tipo_contrato ===
                      'Locación de servicio' ||
                    dataAEditar.datos_laborales.tipo_trabajador ===
                      'Locador de servicio'
                  )
                "
              />
              <v-select
                v-if="
                  dataAEditar.datos_laborales.datos_remuneracion
                    .tipo_pension === 'AFP' &&
                    !(
                      dataAEditar.datos_laborales.tipo_contrato ===
                        'Locación de servicio' ||
                      dataAEditar.datos_laborales.tipo_trabajador ===
                        'Locador de servicio'
                    )
                "
                label="Nombre AFP"
                v-model="
                  dataAEditar.datos_laborales.datos_remuneracion.nombre_afp
                "
                :items="nombreAFP"
                :rules="fieldRules"
              />
              <v-select
                v-if="
                  dataAEditar.datos_laborales.datos_remuneracion
                    .tipo_pension === 'AFP' &&
                    !(
                      dataAEditar.datos_laborales.tipo_contrato ===
                        'Locación de servicio' ||
                      dataAEditar.datos_laborales.tipo_trabajador ===
                        'Locador de servicio'
                    )
                "
                label="Tipo de comisión"
                v-model="
                  dataAEditar.datos_laborales.datos_remuneracion
                    .tipo_comision_afp
                "
                :items="tipoComisionAFP"
                :rules="fieldRules"
              />
              <v-text-field
                v-if="
                  dataAEditar.datos_laborales.datos_remuneracion
                    .tipo_pension === 'AFP' &&
                    !(
                      dataAEditar.datos_laborales.tipo_contrato ===
                        'Locación de servicio' ||
                      dataAEditar.datos_laborales.tipo_trabajador ===
                        'Locador de servicio'
                    )
                "
                label="Fecha de ingreso al SPP"
                v-model="
                  dataAEditar.datos_laborales.datos_remuneracion
                    .fecha_ingreso_spp
                "
                type="date"
              />
              <v-text-field
                v-if="
                  dataAEditar.datos_laborales.datos_remuneracion
                    .tipo_pension === 'AFP' &&
                    !(
                      dataAEditar.datos_laborales.tipo_contrato ===
                        'Locación de servicio' ||
                      dataAEditar.datos_laborales.tipo_trabajador ===
                        'Locador de servicio'
                    )
                "
                label="CUSPP"
                v-model="dataAEditar.datos_laborales.datos_remuneracion.cuspp"
              />
              <v-select
                label="Situación laboral"
                v-model="dataAEditar.datos_laborales.situacion"
                :items="situacion"
                disabled
              />
              <v-select
                label="¿Asignación familiar?"
                v-model="
                  dataAEditar.datos_laborales.datos_remuneracion
                    .asignacion_familiar
                "
                :items="items6"
                :rules="fieldRules"
                v-if="
                  !(
                    dataAEditar.datos_laborales.tipo_contrato ===
                      'Locación de servicio' ||
                    dataAEditar.datos_laborales.tipo_trabajador ===
                      'Locador de servicio'
                  )
                "
              />
              <v-autocomplete
                label="Cargo"
                v-model="dataAEditar.datos_laborales.cargo"
                :items="cargo"
                :rules="fieldRules"
              />
              <v-select
                v-if="dataAEditar.datos_laborales.cargo === 'Conductor'"
                label="Tipo de brevete"
                v-model="dataAEditar.datos_laborales.tipo_brevete"
                :items="brevete"
                :rules="fieldRules"
              />
              <v-text-field
                v-if="dataAEditar.datos_laborales.cargo === 'Conductor'"
                label="Fecha de caducidad del brevete"
                type="date"
                v-model="dataAEditar.datos_laborales.fecha_cad_brevete"
                :rules="fieldRules"
              />
              <v-text-field
                v-if="dataAEditar.datos_laborales.cargo === 'Conductor'"
                label="Número de brevete"
                type="text"
                v-model="dataAEditar.datos_laborales.numero_brevete"
                :rules="fieldRules"
              />
              <v-select
                label="Tipo de seguro laboral"
                v-model="dataAEditar.datos_laborales.tipo_seguro"
                :items="tiposSeguroLaboral"
                :rules="fieldRules"
                v-if="
                  !(
                    dataAEditar.datos_laborales.tipo_contrato ===
                      'Locación de servicio' ||
                    dataAEditar.datos_laborales.tipo_trabajador ===
                      'Locador de servicio'
                  )
                "
              />
              <v-text-field
                v-if="
                  dataAEditar.datos_laborales.tipo_seguro === 'EPS' &&
                    !(
                      dataAEditar.datos_laborales.tipo_contrato ===
                        'Locación de servicio' ||
                      dataAEditar.datos_laborales.tipo_trabajador ===
                        'Locador de servicio'
                    )
                "
                label="Monto a descontar al trabajador por EPS (S/.)"
                type="number"
                v-model="dataAEditar.datos_laborales.monto_descuento_eps"
                :rules="fieldRules"
              />
              <v-text-field
                v-if="
                  dataAEditar.datos_laborales.tipo_seguro === 'EPS' &&
                    !(
                      dataAEditar.datos_laborales.tipo_contrato ===
                        'Locación de servicio' ||
                      dataAEditar.datos_laborales.tipo_trabajador ===
                        'Locador de servicio'
                    )
                "
                label="Porcentaje que pagará el trabajador por EPS"
                type="number"
                v-model="dataAEditar.datos_laborales.porcentaje_pago_eps"
                :rules="fieldRules"
              />
            </v-col>

            <v-col>
              <v-text-field
                label="Fecha de inicio de contrato"
                v-model="dataAEditar.datos_laborales.fecha_inicio_contrato"
                type="date"
              />
              <v-text-field
                label="Fecha de fin de contrato"
                v-model="dataAEditar.datos_laborales.fecha_fin_contrato"
                type="date"
              />
              <v-autocomplete
                label="Tipo de contrato"
                v-model="dataAEditar.datos_laborales.tipo_contrato"
                :items="tipo_contrato"
              />
              <v-autocomplete
                label="Tipo de empleado"
                v-model="dataAEditar.datos_laborales.tipo_trabajador"
                :items="tipo"
                :rules="fieldRules"
              />
              <v-text-field
                label="Sueldo básico"
                v-model="
                  dataAEditar.datos_laborales.datos_remuneracion
                    .sueldo_basico_sin_descuentos
                "
                :rules="fieldRules"
                type="number"
              />
              <v-autocomplete
                label="Roles"
                v-model="dataAEditar.roles"
                small-chips
                multiple
                :items="roles"
              />
              <v-autocomplete
                label="Sede del trabajador"
                v-model="dataAEditar.datos_laborales.sede"
                :items="sedes"
                item-text="nombre_referencial"
                :rules="fieldRules"
              />
            </v-col>

            <v-col>
              <v-autocomplete
                label="Concepto ingreso fijo 1"
                v-model="dataAEditar.datos_laborales.concepto_ingreso_fijo1"
                :items="conceptosIngresos"
                item-text="nombre"
                clearable
                v-if="
                  !(
                    dataAEditar.datos_laborales.tipo_contrato ===
                      'Locación de servicio' ||
                    dataAEditar.datos_laborales.tipo_trabajador ===
                      'Locador de servicio'
                  )
                "
              />
              <v-row
                v-if="
                  dataAEditar.datos_laborales.concepto_ingreso_fijo1 &&
                    !(
                      dataAEditar.datos_laborales.tipo_contrato ===
                        'Locación de servicio' ||
                      dataAEditar.datos_laborales.tipo_trabajador ===
                        'Locador de servicio'
                    )
                "
              >
                <v-col>
                  <v-text-field
                    label="Monto ingreso fijo 1"
                    v-model="dataAEditar.datos_laborales.monto_ingreso_fijo1"
                    type="number"
                    :rules="fieldRules"
                  />
                </v-col>
                <v-col>
                  <v-switch
                    v-model="dataAEditar.datos_laborales.switchIngresoFijo1"
                    label="Sujeto a asistencia?"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-autocomplete
                label="Concepto ingreso fijo 2"
                v-model="dataAEditar.datos_laborales.concepto_ingreso_fijo2"
                :items="conceptosIngresos"
                item-text="nombre"
                clearable
                v-if="
                  !(
                    dataAEditar.datos_laborales.tipo_contrato ===
                      'Locación de servicio' ||
                    dataAEditar.datos_laborales.tipo_trabajador ===
                      'Locador de servicio'
                  )
                "
              />
              <v-row
                v-if="
                  dataAEditar.datos_laborales.concepto_ingreso_fijo2 &&
                    !(
                      dataAEditar.datos_laborales.tipo_contrato ===
                        'Locación de servicio' ||
                      dataAEditar.datos_laborales.tipo_trabajador ===
                        'Locador de servicio'
                    )
                "
              >
                <v-col>
                  <v-text-field
                    label="Monto ingreso fijo 2"
                    v-model="dataAEditar.datos_laborales.monto_ingreso_fijo2"
                    type="number"
                    :rules="fieldRules"
                  />
                </v-col>
                <v-col>
                  <v-switch
                    v-model="dataAEditar.datos_laborales.switchIngresoFijo2"
                    label="Sujeto a asistencia?"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-autocomplete
                label="Concepto ingreso fijo 3"
                v-model="dataAEditar.datos_laborales.concepto_ingreso_fijo3"
                :items="conceptosIngresos"
                item-text="nombre"
                clearable
                v-if="
                  !(
                    dataAEditar.datos_laborales.tipo_contrato ===
                      'Locación de servicio' ||
                    dataAEditar.datos_laborales.tipo_trabajador ===
                      'Locador de servicio'
                  )
                "
              />
              <v-row
                v-if="
                  dataAEditar.datos_laborales.concepto_ingreso_fijo3 &&
                    !(
                      dataAEditar.datos_laborales.tipo_contrato ===
                        'Locación de servicio' ||
                      dataAEditar.datos_laborales.tipo_trabajador ===
                        'Locador de servicio'
                    )
                "
              >
                <v-col>
                  <v-text-field
                    label="Monto ingreso fijo 3"
                    v-model="dataAEditar.datos_laborales.monto_ingreso_fijo3"
                    type="number"
                    :rules="fieldRules"
                  />
                </v-col>
                <v-col>
                  <v-switch
                    v-model="dataAEditar.datos_laborales.switchIngresoFijo3"
                    label="Sujeto a asistencia?"
                  ></v-switch>
                </v-col>
              </v-row>
              <v-text-field
                label="Monto ingresos trabajo anterior para cálculo de renta de 5ta"
                v-model="
                  dataAEditar.datos_laborales.monto_ingresos_trabajo_anterior
                "
                type="number"
                v-if="
                  !(
                    dataAEditar.datos_laborales.tipo_contrato ===
                      'Locación de servicio' ||
                    dataAEditar.datos_laborales.tipo_trabajador ===
                      'Locador de servicio'
                  )
                "
              />
              <v-text-field
                label="Monto descuento renta 5ta categoría trabajo anterior"
                v-model="
                  dataAEditar.datos_laborales
                    .monto_descuento_quinta_trabajo_anterior
                "
                type="number"
                v-if="
                  !(
                    dataAEditar.datos_laborales.tipo_contrato ===
                      'Locación de servicio' ||
                    dataAEditar.datos_laborales.tipo_trabajador ===
                      'Locador de servicio'
                  )
                "
              />

              <v-switch
                v-model="
                  dataAEditar.datos_laborales.switchSujetoAAdelantoDeQuincena
                "
                label="Sujeto a adelanto de sueldo?"
              ></v-switch>
              <!-- se muestra sólo si adelanto por monto fijo es vacío -->
              <v-text-field
                v-if="
                  dataAEditar.datos_laborales.switchSujetoAAdelantoDeQuincena &&
                    !dataAEditar.datos_laborales.monto_fijo_adelanto
                "
                label="Adelanto por porcentaje de sueldo"
                v-model="dataAEditar.datos_laborales.monto_porcentaje_adelanto"
                :rules="fieldRules"
                type="number"
              />

              <!-- se muestra sólo si adelanto por porcentaje es vacío -->
              <v-text-field
                v-if="
                  dataAEditar.datos_laborales.switchSujetoAAdelantoDeQuincena &&
                    !dataAEditar.datos_laborales.monto_porcentaje_adelanto
                "
                label="Adelanto por monto fijo"
                v-model="dataAEditar.datos_laborales.monto_fijo_adelanto"
                :rules="fieldRules"
                type="number"
              />

              <!-- si el trabajador esta sujeto a horario fraccionado, esto cambia la vista en la versión móvil -->
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on" v-bind="attrs">
                    <v-switch
                      v-model="
                        dataAEditar.datos_laborales
                          .switchSujetoAJornadaIntermitente
                      "
                      label="Sujeto a jornada intermitente?"
                    ></v-switch>
                  </div>
                </template>
                <span>
                  Al activar este switch se modifica la ventana de marcación de
                  asistencia en el app
                </span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card>
        <v-divider />

        <v-card class="card_data">
          <v-card-title>Datos de pago</v-card-title>
          <v-row class="m-10">
            <v-col>
              <v-select
                label="Metodo de pago"
                v-model="
                  dataAEditar.datos_laborales.datos_remuneracion.metodo_pago
                "
                :items="items4"
              />
              <v-select
                label="Periodicidad de remuneracion"
                v-model="
                  dataAEditar.datos_laborales.datos_remuneracion.periodicidad
                "
                :items="items5"
              />
            </v-col>
            <v-col>
              <v-text-field
                label="Banco"
                v-model="dataAEditar.datos_laborales.datos_remuneracion.banco"
              />
              <v-text-field
                label="Moneda"
                v-model="
                  dataAEditar.datos_laborales.datos_remuneracion.moneda_pago
                "
              />
            </v-col>
            <v-col>
              <v-text-field
                label="Tipo de cuenta"
                v-model="
                  dataAEditar.datos_laborales.datos_remuneracion.tipo_cuenta
                "
              />
              <v-text-field
                label="N° de cuenta"
                v-model="
                  dataAEditar.datos_laborales.datos_remuneracion.numero_cuenta
                "
              />
              <v-text-field
                label="CCI"
                v-model="dataAEditar.datos_laborales.datos_remuneracion.cci"
              />
            </v-col>
          </v-row>
        </v-card>
        <v-divider />

        <v-card class="card_data">
          <v-card-title>Credenciales de acceso:</v-card-title>
          <v-row class="m-10">
            <v-col>
              <v-text-field
                label="Nombre de Usuario"
                v-model.trim="dataAEditar.username"
                :rules="fieldRules"
              />
              <v-btn
                @click="cambiarClave"
                text
                color="#020329"
                :disabled="
                  !!this.dataAEditar.datos_laborales.fecha_cese &&
                    !!this.dataAEditar.datos_laborales.motivo_cese &&
                    this.dataAEditar.datos_laborales.fecha_cese.length > 0 &&
                    this.dataAEditar.datos_laborales.motivo_cese.length > 0
                "
                >Cambiar clave</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
        <v-divider />
      </v-form>

      <!-- botones inferiores -->
      <v-row class="m-20">
        <v-col>
          <v-btn
            @click="updateInfo"
            text
            color="success"
            :disabled="
              !!this.dataAEditar.datos_laborales.fecha_cese &&
                !!this.dataAEditar.datos_laborales.motivo_cese &&
                this.dataAEditar.datos_laborales.fecha_cese.length > 0 &&
                this.dataAEditar.datos_laborales.motivo_cese.length > 0
            "
            >Actualizar información</v-btn
          >
          <v-btn @click="closeComponent" text color="blue darken-1"
            >Cancelar</v-btn
          >
          <v-btn
            @click="deshabilitarPersonal"
            text
            color="red darken-1"
            :disabled="
              !!this.dataAEditar.datos_laborales.fecha_cese &&
                !!this.dataAEditar.datos_laborales.motivo_cese &&
                this.dataAEditar.datos_laborales.fecha_cese.length > 0 &&
                this.dataAEditar.datos_laborales.motivo_cese.length > 0
            "
            >Cesar</v-btn
          >
        </v-col>
      </v-row>
    </div>
    <!-- formulario de cese de personal -->
    <CesePersonalDialog :persona="this.dataAEditar" />
    <!-- formulario de cambio de clave -->
    <CambiarClaveDialog :persona="this.dataAEditar" />
  </v-container>
</template>
<script>
import { mapState } from 'vuex';
import CesePersonalDialog from './CesePersonalDialog.vue';
import CambiarClaveDialog from './CambiarClaveDialog.vue';
import { editUserInfo } from '@/api/persons/editUserInfo.js';
import { getUbigeoPeru } from '@/api/persons/getUbigeoPeru.js';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';

export default {
  data: () => ({
    ubigeo_peru: [],
    persons: [],
    data: {},
    rol: '',
    emailRules: [
      (v) => !!v || 'E-mail requerido',
      (v) => /.+@.+/.test(v) || 'Debe ser un e-mail válido',
    ],
    fieldRules: [
      (v) => !!v || 'Campo requerido',
      // (v) => v.length >= 3 || 'Mínimo 3 caracteres',
    ],
    dataAEditar: {
      doi: '',
      username: '',
      password1: '',
      email: '',
      direccion: '',
      roles: [],

      datos_laborales: {
        sede: '',
        switchIngresoFijo1: false,
        switchIngresoFijo2: false,
        switchIngresoFijo3: false,
        switchSujetoAAdelantoDeQuincena: false,
        switchSujetoAJornadaIntermitente: false,
        situacion: '',
        celular: '',
        estadoCivil: '',
        nombres: '',
        apellido_materno: '',
        apellido_paterno: '',
        fecha_ingreso: '',
        fecha_inicio_contrato: '',
        tipo_contrato: '',
        fecha_fin_contrato: '',
        tipo_brevete: '',
        fecha_cad_brevete: '',
        numero_brevete: '',
        fecha_cese: '',
        tipo_documento: '',
        tipo_trabajador: '',
        tipo_seguro: '',
        monto_porcentaje_adelanto: '',
        monto_fijo_adelanto: '',
        monto_descuento_eps: '',
        concepto_ingreso_fijo1: '',
        monto_ingreso_fijo1: '',
        concepto_ingreso_fijo2: '',
        monto_ingreso_fijo2: '',
        concepto_ingreso_fijo3: '',
        monto_ingreso_fijo3: '',
        monto_ingresos_trabajo_anterior: '',
        monto_descuento_quinta_trabajo_anterior: '',
        porcentaje_pago_eps: '',
        cargo: '',

        datos_nacimiento: {
          fecha_nacimiento: '',
          departamento: '',
          provincia: '',
          distrito: '',
          pais: '',
          nacionalidad: '',
        },

        datos_remuneracion: {
          metodo_pago: '',
          banco: '',
          numero_cuenta: '',
          tipo_cuenta: '',
          fecha_ingreso_spp: '',
          tipo_comision_afp: '',
          nombre_afp: '',
          cci: '',
          tipo_pension: '',
          cuspp: '',
          periodicidad: '',
          asignacion_familiar: '',
          sueldo_basico_sin_descuentos: '',
          moneda_pago: '',
        },
      },

      creditos: [],
      descansos_medicos: [],
      permisos: [],
      sanciones: [],
      vacaciones: [],
    },
    brevete: [
      'A-I',
      'A-IIa',
      'A-IIb',
      'A-IIIa',
      'A-IIIb',
      'A-IIIc',
      'B-I',
      'B-IIa',
      'B-II2b',
      'B-IIc',
    ],
    tipo_contrato: [
      'A plazo indeterminado',
      'A tiempo parcial',
      'Por inicio o incremento de actividad',
      'Por necesidades del mercado',
      'Por reconversión empresarial',
      'Ocasional',
      'Suplencia',
      'De emergencia',
      'Para obra determinada o servicio específico',
      'Intermitente',
      'De temporada',
      'Locación de servicio',
    ],
    estadoCivil: [
      'Soltero(a)',
      'Casado(a)',
      'Viudo(a)',
      'Divorciado(a)',
      'Conviviente',
    ],
    inputSearch: '',
    resulSearch: false,
    situacion: [
      'Activo',
      'Cesado',
      'Subsidiado',
      'Sin vínculo pendiente por liquidar',
      'Suspensión perfecta',
    ],
    items6: ['SI', 'NO'],
    items5: ['Quincenal', 'Mensual'],
    items4: ['Efectivo', 'Cuenta'],
    roles: [
      'Acceso app',
      'Gerente',
      'Gestor de créditos',
      'Gestor de operaciones',
      'Gestor del personal',
    ],
    items2: ['AFP', 'ONP'],
    nombreAFP: ['HABITAT', 'INTEGRA', 'PRIMA', 'PROFUTURO'],
    tipoComisionAFP: ['Flujo', 'Mixta'],
    items: ['DNI', 'CE', 'PASAPORTE'],
    cargo: [
      'Administrador',
      'Agente de ventas',
      'Analista de marketing',
      'Analista de operaciones',
      'Asistente administrativo',
      'Asistente contable',
      'Asistente operaciones',
      'Asistente recursos humanos',
      'Asistente sistemas',
      'Asistente tesorería',
      'Auxiliar de mantenimiento',
      'Auxiliar de reparto',
      'Ayudante',
      'Carrocero',
      'Cargador',
      'Conductor',
      'Coordinador de operaciones logísticas',
      'Coordinador operativo',
      'Coordinador zonal',
      'Ejecutivo de ventas',
      'Gerente',
      'Gerente comercial',
      'Gerente general',
      'Jefe comercial',
      'Jefe mantenimiento',
      'Jefe operaciones',
      'Mecánico',
      'Operario',
      'Otro',
      'Practicante de sistemas',
      'Supervisor',
      'Técnico oficial',
      'Tripulante',
    ],
    tipo: ['Ejecutivo', 'Obrero', 'Empleado', 'Locador de servicio'],
    valid: true,
  }),
  props: {
    dataAEditarProp: Object,
  },
  created() {
    this.obtenerUbigeoPeru();
  },
  mounted() {
    if (this.dataAEditarProp) {
      this.dataAEditar = this.dataAEditarProp;
    }
  },
  components: {
    CesePersonalDialog,
    CambiarClaveDialog,
  },
  computed: {
    tiposSeguroLaboral() {
      if (
        this.cliente.datos_empresa.regimen_laboral === 'MICRO EMPRESA' ||
        this.cliente.datos_empresa.regimen_laboral === 'PEQUEÑA EMPRESA'
      ) {
        return ['ESSALUD', 'EPS', 'SIS'];
      } else {
        return ['ESSALUD', 'EPS'];
      }
    },
    ...mapState(['editPerson', 'cliente', 'personalCompleto']),
    sedes() {
      return this.cliente.datos_empresa.direccion;
    },
    conceptosIngresos() {
      return this.cliente.datos_empresa.conceptos_sunat.filter(
        (concepto) =>
          (Number(concepto.codigo) >= 100 && Number(concepto.codigo) < 200) ||
          (Number(concepto.codigo) >= 900 && Number(concepto.codigo) < 1000)
      );
    },
    departamentos() {
      let departamentosFinal = [];
      this.ubigeo_peru.map((provincia) => {
        if (
          (!departamentosFinal.includes(provincia.departamento) &&
            this.dataAEditar.datos_laborales.datos_nacimiento.pais.toUpperCase() ===
              'PERU') ||
          this.dataAEditar.datos_laborales.datos_nacimiento.pais.toUpperCase() ===
            'PERÚ'
        ) {
          departamentosFinal.push(provincia.departamento);
        }
      });
      return departamentosFinal;
    },
    provincias() {
      let provinciasFinal = [];
      this.ubigeo_peru.map((provincia) => {
        if (
          !provinciasFinal.includes(provincia.provincia) &&
          provincia.departamento ===
            this.dataAEditar.datos_laborales.datos_nacimiento.departamento
        ) {
          provinciasFinal.push(provincia.provincia);
        }
      });
      return provinciasFinal;
    },
    distritos() {
      let distritosFinal = [];
      this.ubigeo_peru.map((distrito) => {
        if (
          !distritosFinal.includes(distrito.distrito) &&
          distrito.provincia ===
            this.dataAEditar.datos_laborales.datos_nacimiento.provincia
        ) {
          distritosFinal.push(distrito.distrito);
        }
      });
      return distritosFinal;
    },
  },
  methods: {
    cambiarClave() {
      this.$store.state.cambiarClaveDialog = true;
    },
    async deshabilitarPersonal() {
      this.$store.state.cesePersonalDialog = true;
    },
    async obtenerUbigeoPeru() {
      const { error, tokenExpired, ubigeo_peru } = await getUbigeoPeru();
      if (error) {
        //do something
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (ubigeo_peru) {
        this.ubigeo_peru = ubigeo_peru;
      }
    },
    showSuccessAlert() {
      this.$swal.fire({
        title: 'Operación realizada con éxito!',
        text: '',
        icon: 'success',
      });
    },
    showErrorAlert() {
      this.$swal.fire({
        title: 'Ocurrió un error!',
        text: '',
        icon: 'error',
      });
    },
    showAlert(title, icon) {
      this.$swal.fire({
        title,
        text: '',
        icon,
      });
    },
    closeComponent() {
      this.$store.state.botonPersonalSeleccionado = 'activo';
      this.$store.state.verPersonalActivo = true;
      this.$store.state.verNuevoPersonal = false;
      this.$store.state.verPersonalHistorico = false;
      this.$store.state.editPerson = false;
    },
    async updateInfo() {
      if (this.$refs.form.validate()) {
        const usernameDuplicado = this.personalCompleto.filter(
          (personal) =>
            personal.username.toLowerCase() ===
            this.dataAEditar.username.toLowerCase()
        );
        if (usernameDuplicado.length > 1) {
          const msjAlerta = 'El nombre de usuario se encuentra duplicado!';
          this.showAlert(msjAlerta, 'error');
          return;
        }

        // Validar que si se elimina un concepto por ingreso fijo también se eliminen los datos de monto y si está sujeto a asistencia
        if (!this.dataAEditar.datos_laborales.concepto_ingreso_fijo1) {
          this.dataAEditar.datos_laborales.monto_ingreso_fijo1 = null;
          this.dataAEditar.datos_laborales.switchIngresoFijo1 = false;
        }
        if (!this.dataAEditar.datos_laborales.concepto_ingreso_fijo2) {
          this.dataAEditar.datos_laborales.monto_ingreso_fijo2 = null;
          this.dataAEditar.datos_laborales.switchIngresoFijo2 = false;
        }
        if (!this.dataAEditar.datos_laborales.concepto_ingreso_fijo3) {
          this.dataAEditar.datos_laborales.monto_ingreso_fijo3 = null;
          this.dataAEditar.datos_laborales.switchIngresoFijo3 = false;
        }

        // Validar montos en el caso de adelanto de sueldo por porcentaje
        if (
          this.dataAEditar.datos_laborales.switchSujetoAAdelantoDeQuincena &&
          !!this.dataAEditar.datos_laborales.monto_porcentaje_adelanto &&
          this.dataAEditar.datos_laborales.monto_porcentaje_adelanto.length > 1
        ) {
          if (
            Number(this.dataAEditar.datos_laborales.monto_porcentaje_adelanto) >
            100
          ) {
            const msjAlerta =
              'El porcentaje de adelanto de sueldo ingresado excede el monto del sueldo bruto!';
            this.showAlert(msjAlerta, 'error');
            return;
          }
        }

        // Validar montos en el caso de adelanto de sueldo por monto fijo
        if (
          this.dataAEditar.datos_laborales.switchSujetoAAdelantoDeQuincena &&
          !!this.dataAEditar.datos_laborales.monto_fijo_adelanto &&
          this.dataAEditar.datos_laborales.monto_fijo_adelanto.length > 1
        ) {
          const montoSueldoBasico = Number(
            this.dataAEditar.datos_laborales.datos_remuneracion
              .sueldo_basico_sin_descuentos
          );
          const montoIngresoFijo1 = this.dataAEditar.datos_laborales
            .monto_ingreso_fijo1
            ? Number(this.dataAEditar.datos_laborales.monto_ingreso_fijo1)
            : 0;
          const montoIngresoFijo2 = this.dataAEditar.datos_laborales
            .monto_ingreso_fijo2
            ? Number(this.dataAEditar.datos_laborales.monto_ingreso_fijo2)
            : 0;
          const montoIngresoFijo3 = this.dataAEditar.datos_laborales
            .monto_ingreso_fijo3
            ? Number(this.dataAEditar.datos_laborales.monto_ingreso_fijo3)
            : 0;
          if (
            montoSueldoBasico +
              montoIngresoFijo1 +
              montoIngresoFijo2 +
              montoIngresoFijo3 <
            Number(this.dataAEditar.datos_laborales.monto_fijo_adelanto)
          ) {
            const msjAlerta =
              'El monto de adelanto de sueldo ingresado excede el monto del sueldo bruto!';
            this.showAlert(msjAlerta, 'error');
            return;
          }
        }

        const { error, message, tokenExpired } = await editUserInfo(
          this.dataAEditar
        );
        if (error) {
          this.showErrorAlert();
        }
        if (tokenExpired) {
          routeToLoginIfTokenIsExpired(this);
        }
        if (message) {
          this.showSuccessAlert();
          this.$store.state.editPerson = false;
          this.$store.state.verPersonalHistorico = false;
          this.$store.state.verPersonalActivo = true;
          this.$store.state.botonPersonalSeleccionado = 'activo';
        }
      } else {
        const msjAlerta = 'Existen campos requeridos que faltan completar!';
        this.showAlert(msjAlerta, 'error');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/main.scss';
.row {
  align-items: baseline;
  justify-content: center;
}
.relative {
  position: absolute;
  top: 10px;
  width: fit-content;
  right: 20px;
  .v-btn {
    right: -70px;
    top: -56px;
    position: relative;
    box-shadow: none !important;
    background-color: transparent !important;
  }
}
.container {
  padding: 0px !important;
}
.container_newregister {
  position: reltive;
  top: -10px;
  padding: 10px 15px !important;
  height: 87vh !important;
  overflow: scroll;
}
.container_newregister::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background: #efeff6;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.r-t10 {
  position: relative;
  top: -10px;
}
.container_newregister::-webkit-scrollbar-thumb {
  background: $main;
}
.m-20 {
  margin-top: 20px;
}
.m-auto {
  margin: auto;
}
hr {
  margin: 20px auto;
}
.p-5 {
  padding: 5px;
}
.reset_css {
  height: 20px;
  margin-bottom: 24px;
}
.card_data {
  border: 1px solid rgb(43, 59, 54) !important;
  padding: 5px 20px;
}
</style>
