<template>
  <v-card>
    <v-card-title>Proceso de planillas</v-card-title>

    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12" sm="6">
            <v-autocomplete
              v-model="tipoProcesoPlanilla"
              :items="seleccionProcesoPlanilla"
              placeholder="Seleccione el proceso de esta planilla"
              label="Proceso de esta planilla"
              :rules="fieldRules"
            ></v-autocomplete>
            <v-autocomplete
              v-if="tipoProcesoPlanilla === 'Remuneración'"
              v-model="tipoPagoPlanilla"
              :items="seleccionTipoPagoPlanilla"
              item-text="texto"
              placeholder="Seleccione el tipo de pago de esta planilla"
              label="Tipo de cálculo de esta planilla"
              :rules="fieldRules"
            ></v-autocomplete>
            <v-autocomplete
              v-model="mesSeleccionado"
              :items="seleccionPeriodoMes"
              placeholder="Mes a procesar"
              label="Seleccione el mes a procesar"
              :rules="fieldRules"
            ></v-autocomplete>
            <v-autocomplete
              v-model="anioSeleccionado"
              :items="seleccionPeriodoAnio"
              placeholder="Año a procesar"
              label="Seleccione el año a procesar"
              :rules="fieldRules"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12" sm="6">
            <v-autocomplete
              v-if="tipoProcesoPlanilla === 'Remuneración'"
              v-model="periodicidadCalculo"
              :items="seleccionPeriodicidadCalculo"
              item-text="texto"
              placeholder="Seleccione la periodicidad de pago de esta planilla"
              label="Periodicidad de cálculo de esta planilla"
              :rules="fieldRules"
            ></v-autocomplete>
            <v-text-field
              v-model="fechaInicioPeriodo"
              readonly
              placeholder="Fecha de inicio"
              label="Fecha de inicio del período"
              @click="abrirDatePickerDialog('inicio')"
              :rules="fieldRules"
              v-if="
                tipoProcesoPlanilla === 'Remuneración' ||
                  tipoProcesoPlanilla === 'Vacaciones' ||
                  tipoProcesoPlanilla === 'Locación de servicio' ||
                  tipoProcesoPlanilla === 'Adelanto de sueldo'
              "
            ></v-text-field>
            <v-text-field
              v-model="fechaFinPeriodo"
              readonly
              placeholder="Fecha de fin"
              label="Fecha de fin del período"
              @click="abrirDatePickerDialog('fin')"
              :rules="fieldRules"
              v-if="
                tipoProcesoPlanilla === 'Remuneración' ||
                  tipoProcesoPlanilla === 'Vacaciones' ||
                  tipoProcesoPlanilla === 'Locación de servicio' ||
                  tipoProcesoPlanilla === 'Adelanto de sueldo'
              "
            ></v-text-field>
            <v-text-field
              v-if="
                tipoProcesoPlanilla === 'Remuneración' ||
                  tipoProcesoPlanilla === 'Locación de servicio' ||
                  tipoProcesoPlanilla === 'Adelanto de sueldo'
              "
              v-model="resumenPersonalSeleccionado"
              readonly
              placeholder="Selección del personal a procesar"
              label="Personal a procesar"
              @click="abrirSelectorPersonasDialog"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
      <SelectorPersonas v-if="selectorPersonas" />
      <v-dialog v-model="datePickerDialog" max-width="400px" max-height="400px">
        <v-date-picker
          v-model="date"
          scrollable
          color="teal"
          locale="es-ES"
          :max="fechaMaximaPorPeriodicidad()"
          :min="fechaMinimaPorPeriodicidad()"
        ></v-date-picker>
      </v-dialog>
    </v-card-text>
    <v-card-actions>
      <v-btn color="success" @click="validarYContinuar">Procesar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';

import { crearNuevaPlanilla } from '@/api/payments/crearNuevaPlanilla.js';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';
import SelectorPersonas from './SelectorPersonas.vue';

export default {
  name: 'Paso1',
  data: () => ({
    fieldRules: [(v) => !!v || 'Campo requerido'],
    tipoFecha: '',
    fechaInicioPeriodo: '',
    fechaFinPeriodo: '',
    tipoProcesoPlanilla: '',
    periodicidadCalculo: '',
    seleccionPeriodicidadCalculo: [
      { texto: 'mensual' },
      // { texto: 'semanal' },
      { texto: 'quincenal' },
    ],
    valid: true,
    tipoPagoPlanilla: '',
    seleccionTipoPagoPlanilla: [
      // { texto: 'horas', disabled: true },
      { texto: 'jornadas' },
    ],
    mesSeleccionado: '',
    anioSeleccionado: '',
    nuevo: '',
    nuevaPlanilla: false,
    date: '',
    datePickerDialog: false,
    mesesDelAnio: [
      {
        mes: 'Enero',
        numeroMes: 1,
      },
      {
        mes: 'Febrero',
        numeroMes: 2,
      },
      {
        mes: 'Marzo',
        numeroMes: 3,
      },
      {
        mes: 'Abril',
        numeroMes: 4,
      },
      {
        mes: 'Mayo',
        numeroMes: 5,
      },
      {
        mes: 'Junio',
        numeroMes: 6,
      },
      {
        mes: 'Julio',
        numeroMes: 7,
      },
      {
        mes: 'Agosto',
        numeroMes: 8,
      },
      {
        mes: 'Septiembre',
        numeroMes: 9,
      },
      {
        mes: 'Octubre',
        numeroMes: 10,
      },
      {
        mes: 'Noviembre',
        numeroMes: 11,
      },
      {
        mes: 'Diciembre',
        numeroMes: 12,
      },
    ],
  }),
  components: {
    SelectorPersonas,
  },
  computed: {
    seleccionProcesoPlanilla() {
      let items = [
        'Adelanto de sueldo',
        'CTS',
        'Gratificación',
        'Liquidación',
        'Remuneración',
        // 'Utilidades',
        'Vacaciones',
        'Locación de servicio',
      ];
      if (this.cliente.datos_empresa.regimen_laboral === 'MICRO EMPRESA') {
        items = [
          'Adelanto de sueldo',
          'Liquidación',
          'Remuneración',
          // 'Utilidades',
          'Vacaciones',
        ];
      }

      return items;
    },
    // anioActual() {
    //   return moment().format('YYYY');
    // },
    ...mapState([
      'personalActivo',
      'personalCesado',
      'personalCompleto',
      'personalParaProceso',
      'selectorPersonas',
      'cliente',
      'planVigenteActual',
    ]),
    resumenPersonalSeleccionado() {
      if (this.personalParaProceso.length > 0) {
        return `Total trabajadores seleccionados: ${this.personalParaProceso.length}`;
      }
      return `Total trabajadores seleccionados: 0`;
    },
    seleccionPeriodoMes() {
      let meses = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ];
      if (this.tipoProcesoPlanilla === 'CTS') {
        meses = ['Mayo', 'Noviembre'];
      }
      if (this.tipoProcesoPlanilla === 'Gratificación') {
        meses = ['Julio', 'Diciembre'];
      }

      return meses;
    },
    seleccionPeriodoAnio() {
      let periodos = [
        '2023',
        '2024',
        '2025',
        '2026',
        '2027',
        '2028',
        '2029',
        '2030',
        '2031',
        '2032',
        '2033',
        '2034',
        '2035',
        '2036',
        '2037',
        '2038',
        '2039',
        '2040',
      ];
      return periodos;
    },
  },
  created() {
    this.$store.state.personalParaProceso = [];
  },
  methods: {
    abrirSelectorPersonasDialog() {
      if (this.fechaInicioPeriodo !== '' && this.fechaFinPeriodo !== '') {
        this.$store.state.procesoPlanilla.fechaInicio = this.fechaInicioPeriodo;
        this.$store.state.procesoPlanilla.fechaFin = this.fechaFinPeriodo;
        this.$store.state.selectorPersonas = true;
      } else {
        this.showErrorAlert(
          'Debe seleccionar las fechas de inicio y fin del período antes de continuar!'
        );
      }
    },
    async verificarYCrearNuevaPlanilla() {
      const fechaInicio = this.fechaInicioPeriodo;
      const fechaFin = this.fechaFinPeriodo;

      let nuevaPlanilla = {
        periodo: `${this.mesSeleccionado}-${this.anioSeleccionado}`,
        fecha_inicio: moment(fechaInicio)
          .tz('America/Lima')
          .format(),
        fecha_fin: moment(fechaFin)
          .tz('America/Lima')
          .format(),
        personal: this.personalParaProceso,
        tipo_calculo: this.tipoPagoPlanilla,
        tipo_proceso: this.tipoProcesoPlanilla,
        periodicidad: this.periodicidadCalculo,
        estado: 'abierto',
      };

      let personalEditadoParaPlanilla = nuevaPlanilla.personal.map(
        (persona) => {
          if (!persona.datos_laborales) return persona;
          let personaEditada = {};
          personaEditada._id = persona._id;
          personaEditada.nombre = persona.nombre;
          personaEditada.nombresSolos = persona.datos_laborales.nombres;
          personaEditada.email = persona.email;
          personaEditada.cuspp =
            persona.datos_laborales.datos_remuneracion.cuspp;
          personaEditada.tipoTrabajador =
            persona.datos_laborales.tipo_trabajador;
          personaEditada.situacion = persona.datos_laborales.situacion;
          personaEditada.apellidoPaterno =
            persona.datos_laborales.apellido_paterno;
          personaEditada.apellidoMaterno =
            persona.datos_laborales.apellido_materno;
          personaEditada.doi = persona.doi;
          personaEditada.tipoDocumento = persona.datos_laborales.tipo_documento;
          personaEditada.fecha_ingreso = persona.datos_laborales.fecha_ingreso;
          personaEditada.fecha_cese = persona.datos_laborales.fecha_cese;
          personaEditada.motivo_cese = persona.datos_laborales.motivo_cese;
          personaEditada.remuneracion_mensual = Number(
            persona.datos_laborales.datos_remuneracion
              .sueldo_basico_sin_descuentos
          );
          personaEditada.asignacion_familiar =
            persona.datos_laborales.datos_remuneracion.asignacion_familiar;
          personaEditada.tipo_seguro = persona.datos_laborales.tipo_seguro;
          personaEditada.monto_descuento_eps =
            persona.datos_laborales.monto_descuento_eps;
          personaEditada.cargo = persona.datos_laborales.cargo;
          personaEditada.porcentaje_pago_eps =
            persona.datos_laborales.porcentaje_pago_eps;
          personaEditada.pension =
            persona.datos_laborales.datos_remuneracion.tipo_pension;
          personaEditada.tipo_comision_afp =
            persona.datos_laborales.datos_remuneracion.tipo_comision_afp;
          personaEditada.nombre_afp =
            persona.datos_laborales.datos_remuneracion.nombre_afp;
          //agregar campos necesarios para generar TXT para bancos
          personaEditada.monedaDePago =
            persona.datos_laborales.datos_remuneracion.moneda_pago;
          personaEditada.nroDeCuenta =
            persona.datos_laborales.datos_remuneracion.tipo_cuenta === 'Ahorros'
              ? persona.datos_laborales.datos_remuneracion.numero_cuenta
              : persona.datos_laborales.datos_remuneracion.cci;

          personaEditada.monto_ingresos_trabajo_anterior =
            persona.datos_laborales.monto_ingresos_trabajo_anterior;
          personaEditada.monto_descuento_quinta_trabajo_anterior =
            persona.datos_laborales.monto_descuento_quinta_trabajo_anterior;
          personaEditada.concepto_ingreso_fijo1 =
            persona.datos_laborales.concepto_ingreso_fijo1;
          personaEditada.switchIngresoFijo1 =
            persona.datos_laborales.switchIngresoFijo1;
          personaEditada.monto_ingreso_fijo1 =
            persona.datos_laborales.monto_ingreso_fijo1;
          personaEditada.concepto_ingreso_fijo2 =
            persona.datos_laborales.concepto_ingreso_fijo2;
          personaEditada.switchIngresoFijo2 =
            persona.datos_laborales.switchIngresoFijo2;
          personaEditada.monto_ingreso_fijo2 =
            persona.datos_laborales.monto_ingreso_fijo2;
          personaEditada.concepto_ingreso_fijo3 =
            persona.datos_laborales.concepto_ingreso_fijo3;
          personaEditada.switchIngresoFijo3 =
            persona.datos_laborales.switchIngresoFijo3;
          personaEditada.monto_ingreso_fijo3 =
            persona.datos_laborales.monto_ingreso_fijo3;
          // asignación de campos de adelanto de sueldo (switch/flag, monto_porcentaje y monto_fijo)
          personaEditada.switchSujetoAAdelantoDeQuincena =
            persona.datos_laborales.switchSujetoAAdelantoDeQuincena;
          personaEditada.monto_porcentaje_adelanto =
            persona.datos_laborales.monto_porcentaje_adelanto;
          personaEditada.monto_fijo_adelanto =
            persona.datos_laborales.monto_fijo_adelanto;

          personaEditada.cantidad_dias_efectivos =
            this.periodicidadCalculo === 'mensual'
              ? 30
              : this.periodicidadCalculo === 'quincenal'
              ? 15
              : 7;
          // personaEditada.cantidad_dias_efectivos = this.periodicidad;
          personaEditada.ingresos_por_concepto = [];
          personaEditada.descuentos_por_concepto = [];
          personaEditada.aportes_empleador_por_concepto = [];
          personaEditada.vacaciones = persona.vacaciones;
          personaEditada.creditos = persona.creditos;
          personaEditada.actualizarCreditos = false; // variable usada para actualizar las cuotas pagadas de un trabajador al guardar la planilla
          personaEditada.cantidadDiasVacaciones =
            persona.cantidadDiasVacaciones;

          return personaEditada;
        }
      );

      nuevaPlanilla.personal = personalEditadoParaPlanilla;

      // console.log(nuevaPlanilla);

      const { error, planilla, tokenExpired } = await crearNuevaPlanilla(
        nuevaPlanilla
      );
      if (error) {
        //do something
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (planilla) {
        this.$store.state.nuevaPlanillaCreada = planilla;
        this.siguientePaso();
      }
    },
    abrirDatePickerDialog(tipoFecha) {
      if (!this.anioSeleccionado || this.anioSeleccionado === '') {
        this.showErrorAlert('Debe seleccionar el año del período a calcular');
        return;
      }
      if (
        (!this.periodicidadCalculo || this.periodicidadCalculo === '') &&
        this.tipoProcesoPlanilla === 'Remuneración'
      ) {
        this.showErrorAlert(
          'Debe seleccionar la periodicidad de cálculo de esta planilla'
        );
        return;
      }

      if (tipoFecha === 'fin') {
        if (!this.fechaInicioPeriodo || this.fechaInicioPeriodo === '') {
          this.showErrorAlert(
            'Debe seleccionar la fecha de inicio de este cálculo'
          );
          return;
        }
      }
      this.tipoFecha = tipoFecha;
      this.datePickerDialog = true;
    },
    siguientePaso() {
      this.$store.state.personalParaProceso = [];
      this.$store.state.procesoPlanilla.pasoDeProcesoActual = 2;
      // if (this.tipoProcesoPlanilla === 'Remuneración') {
      //   this.$store.state.procesoPlanilla.pasoDeProcesoActual = 2;
      // } else {
      //   this.$store.state.procesoPlanilla.pasoDeProcesoActual = 5;
      // }
    },
    showErrorAlert(text) {
      this.$swal.fire({
        title: `Error`,
        text,
        icon: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    validarYContinuar() {
      // solo mostrar las usuarios_habilitados permitidos por el plan vigente
      let limiteDePersonas = 3;
      if (
        this.planVigenteActual.tipo_plan !== '' &&
        this.planVigenteActual.tipo_plan !== 'CTIEmprendedor'
      ) {
        limiteDePersonas = this.personalCompleto.length;
      }

      if (!this.$refs.form.validate()) {
        return;
      }
      //validar si el cliente aún no ha seleccionado el regimen de salud ni laboral
      if (
        !this.cliente.datos_empresa.regimen_laboral ||
        this.cliente.datos_empresa.regimen_laboral === ''
      ) {
        this.showErrorAlert(
          `Aún no se ha seleccionado el régimen laboral. Por favor complete este campo desde el módulo de Configuración en el Menú Principal`
        );
        return;
      }

      if (
        this.personalParaProceso.length === 0 &&
        (this.tipoProcesoPlanilla === 'Remuneración' ||
          this.tipoProcesoPlanilla === 'Adelanto de sueldo')
      ) {
        this.showErrorAlert(
          'Debe existir al menos 1 trabajador que cumpla con las condiciones para procesar esta planilla!'
        );
        return;
      }

      if (this.tipoProcesoPlanilla === 'CTS') {
        if (
          this.mesSeleccionado !== 'Mayo' &&
          this.mesSeleccionado !== 'Noviembre'
        ) {
          this.showErrorAlert(
            'La planilla de CTS sólo se genera en Mayo o Noviembre'
          );
          return;
        }

        this.$store.state.personalParaProceso = [];
        const anioPeriodo = this.anioSeleccionado;
        const anioPasado = Number(anioPeriodo) - 1;

        let numeroMesInicio = '11';
        let numeroMesFin = '04';
        let anioABuscar = anioPasado.toString();

        if (this.mesSeleccionado === 'Noviembre') {
          numeroMesInicio = '05';
          numeroMesFin = '10';
          anioABuscar = anioPeriodo;
        }

        const fechaInicio = moment(
          `01-${numeroMesInicio}-${anioABuscar}`,
          'DD-MM-YYYY'
        ).startOf('month');
        const fechaFin = moment(
          `01-${numeroMesFin}-${anioPeriodo}`,
          'DD-MM-YYYY'
        ).endOf('month');
        const fechaMinimaIngreso = moment(
          `01-${numeroMesFin}-${anioPeriodo}`,
          'DD-MM-YYYY'
        ).startOf('month');

        this.fechaInicioPeriodo = fechaInicio;
        this.fechaFinPeriodo = fechaFin;

        const personalActivoDentroDelPeriodo = this.personalActivo.filter(
          (persona) =>
            moment(persona.datos_laborales.fecha_ingreso).tz('America/Lima') <=
            moment(fechaMinimaIngreso).tz('America/Lima')
        );
        const personalCesadoDespuesDelPeriodo = this.personalCesado.filter(
          (persona) =>
            moment(persona.datos_laborales.fecha_cese).tz('America/Lima') >=
              moment(fechaFin).tz('America/Lima') &&
            moment(persona.datos_laborales.fecha_ingreso).tz('America/Lima') <=
              moment(fechaMinimaIngreso).tz('America/Lima')
        );
        this.personalParaProceso.push(
          ...personalActivoDentroDelPeriodo.slice(0, limiteDePersonas)
        );
        this.personalParaProceso.push(...personalCesadoDespuesDelPeriodo);
      }

      if (this.tipoProcesoPlanilla === 'Liquidación') {
        this.$store.state.personalParaProceso = [];
        const anioPeriodo = this.anioSeleccionado;
        const mesPeriodo = this.mesSeleccionado;

        const mesPeriodoNumero = this.mesesDelAnio.find(
          (mes) => mes.mes === mesPeriodo
        );

        const fechaInicio = moment(
          `01-${mesPeriodoNumero.numeroMes}-${anioPeriodo}`,
          'DD-MM-YYYY'
        ).startOf('month');
        const fechaFin = moment(
          `01-${mesPeriodoNumero.numeroMes}-${anioPeriodo}`,
          'DD-MM-YYYY'
        ).endOf('month');

        this.fechaInicioPeriodo = fechaInicio; //1ero del mes seleccionado desde el formulario
        this.fechaFinPeriodo = fechaFin;

        const personalCesadoFiltrado = this.personalCesado.filter(
          (persona) =>
            moment(persona.datos_laborales.fecha_cese) >= fechaInicio &&
            moment(persona.datos_laborales.fecha_cese) <= fechaFin
        );

        this.personalParaProceso.push(...personalCesadoFiltrado);

        if (this.personalParaProceso.length === 0) {
          this.showErrorAlert(
            'No existe personal cesado dentro del período seleccionado'
          );
          return;
        }
      }

      if (this.tipoProcesoPlanilla === 'Gratificación') {
        if (
          this.mesSeleccionado !== 'Julio' &&
          this.mesSeleccionado !== 'Diciembre'
        ) {
          this.showErrorAlert(
            'La planilla de gratificación sólo se genera en Julio o Diciembre'
          );
          return;
        }

        this.$store.state.personalParaProceso = [];
        const anioPeriodo = this.anioSeleccionado;

        let numeroMesInicio = '01';
        let numeroMesFin = '06';

        if (this.mesSeleccionado === 'Diciembre') {
          numeroMesInicio = '07';
          numeroMesFin = '12';
        }

        const fechaInicio = moment(
          `01-${numeroMesInicio}-${anioPeriodo}`,
          'DD-MM-YYYY'
        ).startOf('month');
        const fechaFin = moment(
          `01-${numeroMesFin}-${anioPeriodo}`,
          'DD-MM-YYYY'
        ).endOf('month');

        this.fechaInicioPeriodo = fechaInicio;
        this.fechaFinPeriodo = fechaFin;

        const personalActivoDentroDelPeriodo = this.personalActivo.filter(
          (persona) =>
            moment(persona.datos_laborales.fecha_ingreso).tz('America/Lima') <=
            moment(this.fechaFinPeriodo).tz('America/Lima')
        );
        const personalCesadoDespuesDelPeriodo = this.personalCesado.filter(
          (persona) =>
            moment(persona.datos_laborales.fecha_cese).tz('America/Lima') >=
            fechaFin
        );

        this.personalParaProceso.push(
          ...personalActivoDentroDelPeriodo.slice(0, limiteDePersonas), //se coloca el limite por plan porque la validación para personal cesado sería en data histórica
          ...personalCesadoDespuesDelPeriodo
        );
        // this.personalParaProceso.push(...personalCesadoDespuesDelPeriodo);
      }

      if (this.tipoProcesoPlanilla === 'Vacaciones') {
        this.$store.state.personalParaProceso = [];

        // const fechaInicio = moment(this.fechaInicioPeriodo).tz('America/Lima');
        const fechaFin = moment(this.fechaFinPeriodo).tz('America/Lima');

        const personalCesadoDespuesDelPeriodo = this.personalCesado.filter(
          (persona) =>
            moment(persona.datos_laborales.fecha_cese).tz('America/Lima') >=
            fechaFin
        );

        this.personalParaProceso.push(...personalCesadoDespuesDelPeriodo);
        this.personalParaProceso.push(...this.personalActivo);
        // console.log(personalConVacaciones);
        const personalActivoDentroDelPeriodo = this.personalParaProceso.filter(
          (persona) =>
            moment(persona.datos_laborales.fecha_ingreso).tz('America/Lima') <=
            fechaFin
        );
        // this.personalParaProceso.push(...personalActivoDentroDelPeriodo);
        this.$store.state.personalParaProceso = personalActivoDentroDelPeriodo.slice(
          0,
          limiteDePersonas
        );
        if (this.$store.state.personalParaProceso.length === 0) {
          this.showErrorAlert(
            'No existe personal activo dentro del período seleccionado'
          );
          return;
        }
        // this.personalParaProceso.push(...personalCesadoDespuesDelPeriodo);
      }

      // if (this.tipoProcesoPlanilla === 'Adelanto de sueldo') {
      //   this.$store.state.personalParaProceso = [];

      //   this.personalParaProceso.push(...this.personalActivo);

      //   // Se filtra de todo el personal activo a los que están dentro del período y con adelanto de sueldo seleccionado
      //   const personalDentroDelPeriodo = this.personalParaProceso.filter(
      //     (persona) =>
      //       moment(persona.datos_laborales.fecha_ingreso).tz('America/Lima') <=
      //         moment(this.fechaFinPeriodo) && // sólo se consideran las personas con fecha de ingreso hasta el 15 del mes
      //       persona.datos_laborales.switchSujetoAAdelantoDeQuincena
      //   );
      //   // this.personalParaProceso.push(...personalActivoDentroDelPeriodo);
      //   this.$store.state.personalParaProceso = personalDentroDelPeriodo.slice(
      //     0,
      //     limiteDePersonas
      //   );

      //   if (this.$store.state.personalParaProceso.length === 0) {
      //     this.showErrorAlert(
      //       'No existe personal activo dentro del período seleccionado'
      //     );
      //     return;
      //   }
      // }

      // console.log(this.fechaFinPeriodo, this.fechaInicioPeriodo);
      if (this.fechaFinPeriodo < this.fechaInicioPeriodo) {
        this.showErrorAlert(
          'La fecha de fin no puede ser menor a la fecha de inicio!'
        );
        return;
      }

      this.$swal
        .fire({
          title: `Atención!`,
          text: `Está seguro que desea generar la planilla de ${
            this.tipoProcesoPlanilla === 'Remuneración'
              ? ` ${this.tipoProcesoPlanilla} desde el ${moment(
                  this.fechaInicioPeriodo
                )
                  .tz('America/Lima')
                  .format('LL')} al ${moment(this.fechaFinPeriodo)
                  .tz('America/Lima')
                  .format('LL')}`
              : this.tipoProcesoPlanilla
          }  ${
            this.tipoProcesoPlanilla === 'Vacaciones'
              ? `de ${this.mesSeleccionado}-${this.anioSeleccionado}`
              : `para ${this.personalParaProceso.length} ${
                  this.personalParaProceso.length === 1
                    ? 'trabajador'
                    : 'trabajadores'
                }?`
          }`,

          icon: 'warning',
          showCancelButton: true,
          cancelButtonColor: '#d33',
          cancelButtonText: 'No',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Sí',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$store.state.procesoPlanilla.fechaInicio = this.fechaInicioPeriodo;
            this.$store.state.procesoPlanilla.fechaFin = this.fechaFinPeriodo;
            this.verificarYCrearNuevaPlanilla();
          } else {
            this.$store.state.personalParaProceso = [];
          }
        });
    },
    clearDateFields() {
      this.fechaInicioPeriodo = '';
      this.fechaFinPeriodo = '';
    },
    fechaMaximaPorPeriodicidad() {
      if (this.tipoFecha === 'fin') {
        switch (this.tipoProcesoPlanilla) {
          case 'Remuneración':
            return moment(this.fechaInicioPeriodo)
              .add(
                this.periodicidadCalculo === 'mensual'
                  ? 30
                  : this.periodicidadCalculo === 'quincenal'
                  ? 15
                  : 6,
                'days'
              )
              .format('YYYY-MM-DD');
          case 'Adelanto de sueldo':
            return moment(this.fechaInicioPeriodo)
              .add(14, 'days')
              .format('YYYY-MM-DD');

          default:
            return '';
        }
      }
    },
    fechaMinimaPorPeriodicidad() {
      if (
        this.tipoProcesoPlanilla !== 'Remuneración' &&
        this.tipoProcesoPlanilla !== 'Adelanto de sueldo'
      ) {
        return this.fechaInicioPeriodo === ''
          ? moment(`${this.anioSeleccionado}-01-01`).format('YYYY-MM-DD')
          : this.tipoFecha === 'inicio'
          ? moment(this.anioSeleccionado).format('YYYY-MM-DD')
          : moment(this.fechaInicioPeriodo).format('YYYY-MM-DD');
      } else {
        return '';
      }
    },
  },
  watch: {
    tipoProcesoPlanilla() {
      this.$store.state.personalParaProceso = [];
      this.$store.state.procesoPlanilla.tipoProceso = this.tipoProcesoPlanilla;
      this.clearDateFields();
    },
    date() {
      if (this.tipoFecha === 'fin') {
        this.fechaFinPeriodo = this.date;
        this.fechaMaximaPorPeriodicidad();
      } else {
        this.fechaFinPeriodo = '';
        this.fechaInicioPeriodo = this.date;
      }
      this.datePickerDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/main.scss';
.justify-btn {
  display: flex;
  justify-content: space-around;
}
</style>
