<template>
  <v-card min-height="400px" class="pt-2">
    <v-data-table
      class="elevation-1"
      no-results-text="No se encontraron registros..."
      fixed-header
      height="83vh"
      id="horasLaboradas"
      :headers="headers"
      :items="personal"
      :loading="tableLoading"
      :custom-sort="customSort"
      loading-text="Cargando reporte de horas laboradas... Espere..."
      no-data-text="No hay data aún..."
      :search="searchPerson"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-toolbar-title class="top-bar-title"
            >Horas laboradas</v-toolbar-title
          >

          <v-spacer></v-spacer>

          <v-text-field
            v-model="searchPerson"
            append-icon="mdi-magnify"
            label="Buscar persona..."
            single-line
            hide-details
          ></v-text-field>

          <v-spacer></v-spacer>
          <v-menu
            bottom
            origin="center center"
            transition="scale-transition"
            rounded="b-xl"
          >
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn v-on="{ ...tooltip, ...menu }" fab v-bind="attrs">
                    <v-icon class="boton-txt" color="green darken-4" large
                      >mdi-file-excel</v-icon
                    >
                  </v-btn>
                </template>
                <span>Descargar reportes</span>
              </v-tooltip>
            </template>

            <v-list>
              <v-list-item
                v-for="(tipoReporte, index) in tiposReportes"
                :key="index"
                link
                @click="exportarExcel(tipoReporte.value)"
              >
                <v-list-item-title>{{ tipoReporte.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-spacer></v-spacer>

          <v-dialog
            ref="fechaDialog"
            v-model="fechaModal"
            :return-value.sync="date"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="dateText()"
                label="Mes de reporte"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              type="month"
              scrollable
              color="teal"
              locale="es-ES"
            >
              <v-spacer></v-spacer>
              <v-btn text color="blue" @click="fechaModal = false">
                Cancelar
              </v-btn>
              <v-btn
                text
                color="success"
                @click="cambiarMesReporte(), $refs.fechaDialog.save(date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-for="h in headers" v-slot:[`header.${h.value}`]="{ header }">
        <v-row :key="h.value">
          <v-row class="d-flex flex-column justify-start" no-gutters>
            <v-col class="align-header">
              {{ header.text.toUpperCase() }}
            </v-col>
            <v-col class="align-header">
              {{ header.value > 0 ? header.value : null }}
            </v-col>
          </v-row>
        </v-row>
      </template>

      <template
        v-for="(semana, l) in semanas"
        v-slot:[`item.semana${l}.sumaMinutosTotales`]="{ item }"
      >
        <div v-if="item[`semana${l}`]" :key="l">
          <v-tooltip top :key="semana[l]">
            <template v-slot:activator="{ on }">
              <!-- agregar cada día de jornadas a la persona para ver detalle -->
              <!-- se puede cambiar el coloor si ya pasó las 48 horas -->
              <v-chip v-on="on" @click="verDetalleDeHorasDeUnaSemana(l, item)">
                {{
                  item[`semana${l}`]
                    ? formatMinutos(item[`semana${l}`].sumaMinutosTotales)
                    : ''
                }}
              </v-chip>
            </template>
            <span>{{ `Ver el detalle de horas de la semana ${l + 1}` }}</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:[`item.sumaMinutosTotalesMes`]="{ item }">
        <span>{{
          item.sumaMinutosTotalesMes
            ? formatMinutos(item.sumaMinutosTotalesMes)
            : ''
        }}</span>
      </template>
    </v-data-table>
    <VerDetalleHorasLaboradasPorSemana
      :asistencia="this.asistenciaADetallar"
      :indiceSemana="this.indiceSemana"
    />
  </v-card>
</template>
<script>
import moment from 'moment';
import { mapState } from 'vuex';

import { getPersons } from '@/api/persons/getPersons.js';
import { obtenerAsistenciaPorRangoDeFechas } from '@/api/persons/obtenerAsistenciaPorRangoDeFechas.js';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';
import VerDetalleHorasLaboradasPorSemana from './VerDetalleHorasLaboradasPorSemana.vue';
import {
  excelExport,
  excelExportHorasLaboradasPorMes,
} from '@/plugins/excelExport.js';

export default {
  name: 'HorasLaboradas',
  data: () => ({
    tiposReportes: [
      { title: 'Detalle mes', value: 'mes' },
      { title: 'Semanal', value: 'semana' },
    ],
    fechaModal: false,
    date: moment().format('YYYY-MM'),
    tableLoading: true,
    personal: [],
    searchPerson: '',
    semanas: [],
    asistenciaADetallar: {},
    indiceSemana: 0,
    asistencia: [],
  }),
  components: {
    VerDetalleHorasLaboradasPorSemana,
  },
  computed: {
    ...mapState([
      'verHorasLaboradasPorSemanaDialog',
      'planVigenteActual',
      'reporteHorasLaboradasFecha',
    ]),
    headers() {
      let fixedHeaders = [
        {
          text: 'Documento',
          value: 'doi',
          width: '175px',
        },
        {
          text: 'Nombre',
          value: 'nombre',
          width: '375px',
        },
        {
          text: 'Cargo',
          value: 'datos_laborales.cargo',
          width: '175px',
        },
        {
          text: 'Sede',
          value: 'datos_laborales.sede',
          width: '150px',
        },
      ];

      this.semanas.map((semana, index) => {
        let headerSemana = {
          value: `semana${index}.sumaMinutosTotales`,
          text: `Semana ${index + 1}`,
          width: '85px',
        };
        fixedHeaders.push(headerSemana);
        return semana;
      });

      fixedHeaders.push({
        value: `sumaMinutosTotalesMes`,
        text: `Total horas del mes`,
        width: '100px',
      });
      return fixedHeaders;
    },
  },
  mounted() {
    if (Object.keys(this.reporteHorasLaboradasFecha).length === 0) {
      this.$store.state.reporteHorasLaboradasFecha = moment().format('YYYY-MM');
    }
    this.cambiarMesReporte();
    this.renderizarTablaConAsistencias();
  },
  watch: {
    reporteHorasLaboradasFecha() {
      this.renderizarTablaConAsistencias();
    },
  },
  methods: {
    formatMinutos(minutos) {
      if (minutos === 0) return '0';

      let hours = Math.floor(minutos / 60);
      let mins = minutos % 60;

      // Asegurarse de que los minutos sean presentados siempre con dos digitos
      let formattedMins = mins < 10 ? '0' + mins : '' + mins;

      return `${hours}:${formattedMins}`;
    },
    exportarExcel(tipoReporte) {
      switch (tipoReporte) {
        case 'mes':
          excelExportHorasLaboradasPorMes(this.personal);
          break;
        case 'semana':
          // reporte es el nombre del sheet en excel
          excelExport('horasLaboradas', 'ReporteHorasSemanal');

          break;

        default:
          break;
      }
    },
    renderizarTablaConAsistencias() {
      let fecha_inicio = '';
      let fecha_fin = '';

      if (
        this.reporteHorasLaboradasFecha &&
        this.reporteHorasLaboradasFecha.length > 0
      ) {
        fecha_inicio = moment(this.reporteHorasLaboradasFecha, 'YYYY-MM')
          .tz('America/Lima')
          .startOf('month');
        fecha_fin = moment(this.reporteHorasLaboradasFecha, 'YYYY-MM')
          .tz('America/Lima')
          .endOf('month');

        this.obtenerAsistencias(fecha_inicio, fecha_fin);
      }
    },
    dateText() {
      return moment(this.date)
        .locale('es')
        .format('MMMM-YYYY')
        .toUpperCase();
    },
    cambiarMesReporte() {
      this.$store.state.reporteHorasLaboradasFecha = this.date;
    },
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        switch (index[0]) {
          case 'datos_laborales.cargo':
            if (isDesc[0]) {
              return a.datos_laborales.cargo > b.datos_laborales.cargo ? -1 : 1;
            } else {
              return b.datos_laborales.cargo > a.datos_laborales.cargo ? -1 : 1;
            }
          case 'datos_laborales.sede':
            if (isDesc[0]) {
              return a.datos_laborales.sede > b.datos_laborales.sede ? -1 : 1;
            } else {
              return b.datos_laborales.sede > a.datos_laborales.sede ? -1 : 1;
            }

          default:
            if (isDesc[0]) {
              return a[index[0]] > b[index[0]] ? -1 : 1;
            } else {
              return b[index[0]] > a[index[0]] ? -1 : 1;
            }
        }
      });
      return items;
    },
    async obtenerAsistencias(fecha_inicio, fecha_fin) {
      const {
        error,
        asistencias,
        tokenExpired,
      } = await obtenerAsistenciaPorRangoDeFechas(fecha_inicio, fecha_fin);

      if (asistencias) {
        // obtener la cantidad de semanas de este mes seleccionado
        let actual = fecha_inicio.clone();
        let vacio = 7;
        this.semanas = [];
        let semana = Array(7).fill(null);

        while (actual.isBefore(fecha_fin)) {
          let dia = actual.day() === 0 ? 6 : actual.day() - 1; // Mueve Domingo al final
          semana[dia] = actual.date();
          vacio--;

          // Si es Sábado, guarda la semana y reinicia
          if (dia === 6) {
            if (vacio != 7) {
              this.semanas.push(semana);
            }
            semana = Array(7).fill(null);
            vacio = 7;
          }

          actual.add(1, 'day');
        }

        if (actual.isSame(fecha_fin, 'day') || vacio < 7) {
          if (vacio !== 7) {
            this.semanas.push(semana);
          }
        }

        await this.getPersonal();

        this.asistencia = asistencias.map((asistencia) => {
          //esta es una asistencia por día, se debería agregar a la semana que corresponde?
          let fechaAsistencia = moment(asistencia.fecha_asistencia);
          let diaDelMes = fechaAsistencia.format('D');
          let semanaActual = 0;

          //buscar a que semana pertenece este día del mes
          this.semanas.map((semana, index) => {
            const diaEncontrado = semana.find(
              (dia) => dia === parseInt(diaDelMes)
            );
            if (diaEncontrado) {
              semanaActual = index;
            }
            return semana;
          });

          this.personal = this.personal.map((persona) => {
            // se obtiene la asistencia si la persona se encuentra en ese día
            let registroDeAsistencia = asistencia.personal.find(
              (person) => person.doi.toString() === persona.doi.toString()
            );
            //se inicializa la cantidad de minutosTotalesMes para sumar todas las semanas
            if (!persona.sumaMinutosTotalesMes) {
              persona.sumaMinutosTotalesMes = 0;
            }
            //se inicializa la cantidad de minutosTotales trabajados en cada semana y el campo para guardar las asistencias por día
            if (!persona[`semana${semanaActual}`]) {
              persona[`semana${semanaActual}`] = { sumaMinutosTotales: 0 };
              persona[`semana${semanaActual}`].jornadasPorDias = [];
            }

            if (registroDeAsistencia) {
              // se agregan las asistencias por cada semana
              registroDeAsistencia.cantidadMinutosLaborados = 0;
              registroDeAsistencia.fechaAsistencia = fechaAsistencia;
              persona[`semana${semanaActual}`].jornadasPorDias.push(
                registroDeAsistencia
              );
              // validar si tieene l swtich intermitente y en base a eso agregar los contadores de "semana1", etc y mostrar la MediaKeyStatusMap, ordenar los headers
              //validar si este día la persona tenía jornada intermitente
              var sumaMinutosTotalesPorJornada = 0;
              if (
                registroDeAsistencia.datos_laborales
                  .switchSujetoAJornadaIntermitente
              ) {
                if (
                  registroDeAsistencia.jornadas &&
                  registroDeAsistencia.jornadas.length > 0
                ) {
                  // persona[`semana${semanaActual}`].jornadasPorDias.push(registroDeAsistencia)
                  registroDeAsistencia.jornadas.map((jornada) => {
                    // Verificando si existen y no son nulos hora_inicio_jornada y hora_fin_jornada
                    let hora_inicio_jornada = jornada.hora_inicio_jornada?.substring(
                      0,
                      5
                    )
                      ? moment(
                          jornada.hora_inicio_jornada.substring(0, 5),
                          'HH:mm'
                        )
                      : null;

                    let hora_fin_jornada = jornada.hora_fin_jornada?.substring(
                      0,
                      5
                    )
                      ? moment(
                          jornada.hora_fin_jornada.substring(0, 5),
                          'HH:mm'
                        )
                      : null;

                    // Si horainicio_jornada y hora_fin_jornada existen y no son nulas, calculamos la diferencia
                    if (hora_inicio_jornada && hora_fin_jornada) {
                      // Si la hora de inicio es mayor que la hora de fin, agregamos un día a la hora de fin
                      if (hora_inicio_jornada.isAfter(hora_fin_jornada)) {
                        hora_fin_jornada.add(1, 'days');
                      }

                      let diferencia = hora_fin_jornada.diff(
                        hora_inicio_jornada,
                        'minutes'
                      );
                      if (diferencia >= 0) {
                        sumaMinutosTotalesPorJornada += diferencia;
                      }
                    }
                  });
                  registroDeAsistencia.cantidadMinutosLaborados = sumaMinutosTotalesPorJornada;
                  persona[
                    `semana${semanaActual}`
                  ].sumaMinutosTotales += sumaMinutosTotalesPorJornada;
                } else {
                  sumaMinutosTotalesPorJornada = 0;
                  persona[
                    `semana${semanaActual}`
                  ].sumaMinutosTotales += sumaMinutosTotalesPorJornada;
                }
              } else {
                // si tiene hora de inicio y no tiene hora de fin no considerar en la sumaMinutosTotalesPorJornada, que se revise el detalle y ajusten desde el control de asistencia
                if (
                  registroDeAsistencia.hora_inicio_jornada &&
                  registroDeAsistencia.hora_inicio_jornada.length > 0 &&
                  registroDeAsistencia.hora_fin_jornada &&
                  registroDeAsistencia.hora_fin_jornada.length > 0
                ) {
                  //validar suma de minutos por horario de refrigerio si existen marcaciones
                  let minutosRefrigerio = 0;
                  if (
                    registroDeAsistencia.hora_inicio_almuerzo &&
                    registroDeAsistencia.hora_inicio_almuerzo.length > 0 &&
                    registroDeAsistencia.hora_fin_almuerzo &&
                    registroDeAsistencia.hora_fin_almuerzo.length > 0
                  ) {
                    let hora_inicio_almuerzo = registroDeAsistencia.hora_inicio_almuerzo?.substring(
                      0,
                      5
                    )
                      ? moment(
                          registroDeAsistencia.hora_inicio_almuerzo.substring(
                            0,
                            5
                          ),
                          'HH:mm'
                        )
                      : null;

                    let hora_fin_almuerzo = registroDeAsistencia.hora_fin_almuerzo?.substring(
                      0,
                      5
                    )
                      ? moment(
                          registroDeAsistencia.hora_fin_almuerzo.substring(
                            0,
                            5
                          ),
                          'HH:mm'
                        )
                      : null;

                    // Si horainicio_jornada y hora_fin_almuerzo existen y no son nulas, calculamos la diferencia
                    if (hora_inicio_almuerzo && hora_fin_almuerzo) {
                      let diferencia = hora_fin_almuerzo.diff(
                        hora_inicio_almuerzo,
                        'minutes'
                      );
                      if (diferencia >= 0) {
                        minutosRefrigerio += diferencia;
                      }
                    }
                  }
                  // Verificando si existen y no son nulos hora_inicio_jornada y hora_fin_jornada
                  let hora_inicio_jornada = registroDeAsistencia.hora_inicio_jornada?.substring(
                    0,
                    5
                  )
                    ? moment(
                        registroDeAsistencia.hora_inicio_jornada.substring(
                          0,
                          5
                        ),
                        'HH:mm'
                      )
                    : null;

                  let hora_fin_jornada = registroDeAsistencia.hora_fin_jornada?.substring(
                    0,
                    5
                  )
                    ? moment(
                        registroDeAsistencia.hora_fin_jornada.substring(0, 5),
                        'HH:mm'
                      )
                    : null;
                  // Si horainicio_jornada y hora_fin_jornada existen y no son nulas, calculamos la diferencia
                  if (hora_inicio_jornada && hora_fin_jornada) {
                    // Si la hora de inicio es mayor que la hora de fin, agregamos un día a la hora de fin
                    if (hora_inicio_jornada.isAfter(hora_fin_jornada)) {
                      hora_fin_jornada.add(1, 'days');
                    }
                    let diferencia = hora_fin_jornada.diff(
                      hora_inicio_jornada,
                      'minutes'
                    );
                    if (diferencia >= 0) {
                      sumaMinutosTotalesPorJornada +=
                        diferencia - minutosRefrigerio;
                    }
                  }
                  registroDeAsistencia.cantidadMinutosLaborados = sumaMinutosTotalesPorJornada;
                  persona[
                    `semana${semanaActual}`
                  ].sumaMinutosTotales += sumaMinutosTotalesPorJornada;
                } else {
                  sumaMinutosTotalesPorJornada = 0;
                  persona[
                    `semana${semanaActual}`
                  ].sumaMinutosTotales += sumaMinutosTotalesPorJornada;
                }
              }
            }

            persona.sumaMinutosTotalesMes += sumaMinutosTotalesPorJornada;

            return persona;
          });

          return asistencia;
        });

        this.tableLoading = false;
      }
      if (error) {
        //do something
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
    },
    getColor(persona, index) {
      let tipoDeJornada = persona[index];
      let colorDeCelda = '';

      switch (tipoDeJornada) {
        case 'A':
          colorDeCelda = 'green';
          break;
        case 'Descanso médico':
          colorDeCelda = 'pink lighten-2';
          break;
        case 'DM':
          colorDeCelda = 'pink lighten-2';
          break;
        case 'D':
          colorDeCelda = 'amber lighten-3';
          break;
        case 'F':
          colorDeCelda = 'red';
          break;
        case 'LM':
          colorDeCelda = 'blue';
          break;
        case 'LP':
          colorDeCelda = 'green';
          break;
        case 'LF':
          colorDeCelda = 'red';
          break;
        case 'PG':
          colorDeCelda = 'brown';
          break;
        case 'P':
          colorDeCelda = 'teal';
          break;
        case 'S':
          colorDeCelda = 'lime darken-3';
          break;
        case 'T':
          colorDeCelda = 'yellow';
          break;
        case 'V':
          colorDeCelda = 'purple';
          break;

        case 'N':
          colorDeCelda = 'transparent';
          break;

        default:
          colorDeCelda = 'transparent';
          break;
      }
      return colorDeCelda;
    },
    async getPersonal() {
      const { error, personal, tokenExpired } = await getPersons();
      if (error) {
        //do something
      }
      if (personal) {
        // solo mostrar las usuarios_habilitados permitidos por el plan vigente
        let limiteDePersonas = 3;
        if (
          this.planVigenteActual.tipo_plan !== '' &&
          this.planVigenteActual.tipo_plan !== 'CTIEmprendedor'
        ) {
          limiteDePersonas = personal.length;
        }
        this.personal = personal;

        this.personal = this.personal.map((persona) => {
          persona.nombre = `${persona.datos_laborales.apellido_paterno} ${persona.datos_laborales.apellido_materno}, ${persona.nombre}`;
          return persona;
        });

        //filtrar cesados antes del inicio e ingreso luego del fin de esta asistencia
        this.personal = this.personal.filter(
          (persona) =>
            moment(persona.datos_laborales.fecha_ingreso).tz('America/Lima') <=
            moment(this.fechaAsistencia)
              .tz('America/Lima')
              .endOf('month')
        );
        this.personal = this.personal
          .filter((persona) =>
            !!persona.datos_laborales.fecha_cese &&
            persona.datos_laborales.fecha_cese !== ''
              ? moment(persona.datos_laborales.fecha_cese).tz('America/Lima') >=
                moment(this.fechaAsistencia)
                  .tz('America/Lima')
                  .endOf('month')
              : true
          )
          .slice(0, limiteDePersonas);

        // this.getDaysInMonth();
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
    },
    verDetalleDeHorasDeUnaSemana(indiceSemana, asistencia) {
      asistencia.fechaAsistencia = this.dateText();
      this.indiceSemana = indiceSemana;
      this.asistenciaADetallar = asistencia;
      this.$store.state.verHorasLaboradasPorSemanaDialog = true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/main.scss';
.top-bar-title {
  font-size: 25px !important;
  font-weight: bold !important;
  color: teal !important;
}
.v-data-table-header {
  height: 50px !important;
}
.v-data-table::v-deep th {
  font-size: 15px !important;
  font-weight: bold !important;
  color: teal !important;
  .v-icon {
    display: none !important;
  }
}
.align-header {
  text-align: left !important;
  background-color: $white;
}
.btn_close {
  position: relative;
  left: 208px;
}
.v-overlay {
  background-color: #1312129a;
}
.table {
  // max-height: 74vh;
  overflow-y: scroll;
}

table {
  border-collapse: collapse;
  width: 100%;
}
</style>
