<template>
  <v-tabs vertical>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-tab class="tab-class" v-on="on">
          0100 - INGRESOS
        </v-tab>
      </template>
      <span>0100 - INGRESOS</span>
    </v-tooltip>

    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-tab class="tab-class" v-on="on">
          0200 - INGRESOS: ASIGNACIONES
        </v-tab>
      </template>
      <span>0200 - INGRESOS: ASIGNACIONES</span>
    </v-tooltip>

    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-tab class="tab-class" v-on="on">
          0300 - INGRESOS: BONIFICACIONES
        </v-tab>
      </template>
      <span>0300 - INGRESOS: BONIFICACIONES</span>
    </v-tooltip>

    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-tab class="tab-class" v-on="on">
          0400 - INGRESOS: GRATIFICACIONES
        </v-tab>
      </template>
      <span>0400 - INGRESOS: GRATIFICACIONES/AGUINALDOS</span>
    </v-tooltip>

    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-tab class="tab-class" v-on="on">
          0500 - INGRESOS: INDEMNIZACIONES
        </v-tab>
      </template>
      <span>0500 - INGRESOS: INDEMNIZACIONES</span>
    </v-tooltip>

    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-tab class="tab-class" v-on="on">
          0600 - APORTACIONES DEL TRABAJADOR
        </v-tab>
      </template>
      <span>0600 - APORTACIONES DEL TRABAJADOR/PENSIONISTA</span>
    </v-tooltip>

    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-tab class="tab-class" v-on="on">
          0700 - DESCUENTOS AL TRABAJADOR
        </v-tab>
      </template>
      <span>0700 - DESCUENTOS AL TRABAJADOR</span>
    </v-tooltip>

    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-tab class="tab-class" v-on="on">
          0800 - APORTACIONES DEL EMPLEADOR
        </v-tab>
      </template>
      <span>0800 - APORTACIONES DE CARGO DEL EMPLEADOR</span>
    </v-tooltip>

    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-tab class="tab-class" v-on="on">
          0900 - CONCEPTOS VARIOS
        </v-tab>
      </template>
      <span>0900 - CONCEPTOS VARIOS</span>
    </v-tooltip>

    <!-- <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-tab class="tab-class" v-on="on">
          1000 - OTROS CONCEPTOS
        </v-tab>
      </template>
      <span>1000 - OTROS CONCEPTOS</span>
    </v-tooltip> -->

    <v-tab-item>
      <ConceptosDetalle
        :grupoConcepto="100"
        :conceptosSunat="obtenerConceptosSunatFiltrados(100)"
      />
    </v-tab-item>

    <v-tab-item>
      <ConceptosDetalle
        :grupoConcepto="200"
        :conceptosSunat="obtenerConceptosSunatFiltrados(200)"
      />
    </v-tab-item>

    <v-tab-item>
      <ConceptosDetalle
        :grupoConcepto="300"
        :conceptosSunat="obtenerConceptosSunatFiltrados(300)"
      />
    </v-tab-item>

    <v-tab-item>
      <ConceptosDetalle
        :grupoConcepto="400"
        :conceptosSunat="obtenerConceptosSunatFiltrados(400)"
      />
    </v-tab-item>

    <v-tab-item>
      <ConceptosDetalle
        :grupoConcepto="500"
        :conceptosSunat="obtenerConceptosSunatFiltrados(500)"
      />
    </v-tab-item>

    <v-tab-item>
      <ConceptosDetalle
        :grupoConcepto="600"
        :conceptosSunat="obtenerConceptosSunatFiltrados(600)"
      />
    </v-tab-item>

    <v-tab-item>
      <ConceptosDetalle
        :grupoConcepto="700"
        :conceptosSunat="obtenerConceptosSunatFiltrados(700)"
      />
    </v-tab-item>

    <v-tab-item>
      <ConceptosDetalle
        :grupoConcepto="800"
        :conceptosSunat="obtenerConceptosSunatFiltrados(800)"
      />
    </v-tab-item>

    <v-tab-item>
      <ConceptosDetalle
        :grupoConcepto="900"
        :conceptosSunat="obtenerConceptosSunatFiltrados(900)"
      />
    </v-tab-item>

    <!-- <v-tab-item>
      <ConceptosDetalle
        :grupoConcepto="1000"
        :conceptosSunat="obtenerConceptosSunatFiltrados(1000)"
      />
    </v-tab-item> -->
  </v-tabs>
</template>

<script>
import { mapState } from 'vuex';

import ConceptosDetalle from './ConceptosDetalle';

export default {
  name: 'Conceptos',
  data() {
    return {
      ingresosAEditar: [],
      descuentosAEditar: [],
      tributosAEditar: [],
      valid: true,
      tiposDeIngresos: [
        { nombre: 'Ingresos1', codigo: 'cod1' },
        { nombre: 'Ingresos2', codigo: 'cod2' },
      ],
      tiposDeDescuentos: [
        { nombre: 'Descuentos1', codigo: 'cod3' },
        { nombre: 'Descuentos2', codigo: 'cod4' },
      ],
      tiposDeTributos: [
        { nombre: 'Tributos1', codigo: 'cod5' },
        { nombre: 'Tributos2', codigo: 'cod6' },
      ],
    };
  },
  components: {
    ConceptosDetalle,
  },
  computed: {
    ...mapState(['cliente', 'conceptosSunat']),
  },
  methods: {
    obtenerConceptosSunatFiltrados(grupoConcepto) {
      let conceptosSunatFiltrados = [];
      conceptosSunatFiltrados = this.conceptosSunat.filter(
        (concepto) =>
          Number(concepto.codigo) >= grupoConcepto &&
          Number(concepto.codigo) < grupoConcepto + 100
      );
      return conceptosSunatFiltrados;
    },
    showSuccessAlert() {
      this.$swal.fire({
        title: `Operación realizada correctamente!`,
        text: '',
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    showErrorAlert() {
      this.$swal.fire({
        title: `Está seguro que va a modificar este concepto?`,
        text: '',
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/main.scss';

.tab-class {
  align-self: baseline !important;
}
</style>
