<template>
  <v-card flat height="86.5vh">
    <v-card-text>
      <v-stepper v-model="procesoPlanilla.pasoDeProcesoActual" vertical>
        <v-stepper-step
          :complete="procesoPlanilla.pasoDeProcesoActual > 1"
          step="1"
          :color="
            procesoPlanilla.pasoDeProcesoActual > 1 ? 'success' : 'primary'
          "
        >
          Selecione el periodo a evaluar
          <small>Selección del período para procesar la planilla</small>
        </v-stepper-step>

        <v-stepper-content step="1">
          <Paso1 v-if="procesoPlanilla.pasoDeProcesoActual === 1" />
        </v-stepper-content>

        <v-stepper-step
          :complete="procesoPlanilla.pasoDeProcesoActual > 2"
          step="2"
          :color="
            procesoPlanilla.pasoDeProcesoActual > 2 ? 'success' : 'primary'
          "
        >
          Validar ingresos
          <small
            >Revisión de todos los ingresos asignados a los trabajadores</small
          >
        </v-stepper-step>

        <v-stepper-content step="2">
          <Paso2 v-if="procesoPlanilla.pasoDeProcesoActual === 2" />
        </v-stepper-content>

        <v-stepper-step
          :complete="procesoPlanilla.pasoDeProcesoActual > 3"
          step="3"
          :color="
            procesoPlanilla.pasoDeProcesoActual > 3 ? 'success' : 'primary'
          "
        >
          Validar descuentos
          <small
            >Revisión de todos los descuentos cargados a los trabajadores</small
          >
        </v-stepper-step>

        <v-stepper-content step="3">
          <Paso3 v-if="procesoPlanilla.pasoDeProcesoActual === 3" />
        </v-stepper-content>

        <v-stepper-step
          :complete="procesoPlanilla.pasoDeProcesoActual > 4"
          step="4"
          :color="
            procesoPlanilla.pasoDeProcesoActual > 4 ? 'success' : 'primary'
          "
        >
          Aportes del empleador
          <small>Revisión de los aportes del empleador</small>
        </v-stepper-step>
        <v-stepper-content step="4">
          <Paso4 v-if="procesoPlanilla.pasoDeProcesoActual === 4" />
        </v-stepper-content>

        <v-stepper-step
          step="5"
          :color="
            procesoPlanilla.pasoDeProcesoActual > 5 ? 'success' : 'primary'
          "
        >
          Revisión y cierre
          <small
            >Revisión de los cálculos y finalización del proceso de
            planilla</small
          >
        </v-stepper-step>
        <v-stepper-content step="5">
          <Paso5 v-if="procesoPlanilla.pasoDeProcesoActual === 5" />
        </v-stepper-content>
      </v-stepper>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapState } from 'vuex';

import Paso1 from '@/components/modules/personnel/planilla/proceso/Paso1.vue';
import Paso2 from '@/components/modules/personnel/planilla/proceso/Paso2.vue';
import Paso3 from '@/components/modules/personnel/planilla/proceso/Paso3.vue';
import Paso4 from '@/components/modules/personnel/planilla/proceso/Paso4.vue';
import Paso5 from '@/components/modules/personnel/planilla/proceso/Paso5.vue';

export default {
  name: 'Proceso',
  data() {
    return {
      // e6: 1,
    };
  },
  components: {
    Paso1,
    Paso2,
    Paso3,
    Paso4,
    Paso5,
  },
  computed: {
    ...mapState(['procesoPlanilla']),
  },
  mounted() {
    //si se requiere q se reinicien todos los pasos cuando se salga de este modulo, aquí se debe modificar procesoPlanilla
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/main.scss';
.v-card {
  overflow-y: auto;
}
</style>
