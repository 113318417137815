import fetch from 'isomorphic-fetch';

const ENDPOINT_URL = process.env.VUE_APP_GENERAR_CODIGO_CIP;
async function getCipCode(tokenCip, planSolicitado, cliente) {
  const bodyParams = {
    tokenCip,
    planSolicitado,
    cliente,
  };

  let body = JSON.stringify(bodyParams);

  return await fetch(ENDPOINT_URL, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  }).then((item) => item.json());
}
export { getCipCode };
