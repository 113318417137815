<template>
  <v-container>
    <v-data-table
      class="elevation-1 "
      no-results-text="No se encontraron registros..."
      fixed-header
      height="70.5vh"
      :id="getTablaReportePlanilla()"
      :headers="headers"
      :items="personal"
      :loading="tableLoading"
      loading-text="Cargando reporte de planilla... Espere..."
      hide-default-footer
      disable-pagination
      no-data-text="Seleccione el período..."
      :search="search"
      :hide-default-header="esconderEncabezados"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-toolbar-title class="top-bar-title">{{
            tituloReporte
          }}</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-autocomplete
            class="selector-periodo-container"
            label="Período a buscar (mes)"
            v-model="periodoSeleccionadoMes"
            :items="periodosDisponiblesMes"
          />

          <v-spacer></v-spacer>

          <v-autocomplete
            class="selector-periodo-container"
            label="Período a buscar (año)"
            v-model="periodoSeleccionadoAnio"
            :items="periodosDisponiblesAnio"
            clearable
          />

          <v-spacer></v-spacer>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn @click="descargarTabla" v-on="on" fab>
                <v-icon color="teal" large>mdi-file-excel</v-icon>
              </v-btn>
            </template>
            <span>Descargar como archivo Excel</span>
          </v-tooltip>
          <v-spacer></v-spacer>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                @click="enviarBoletasMensualesPorEmail"
                v-on="on"
                fab
                v-if="tipoDePlanilla === 'Boleta mensual'"
              >
                <v-icon color="red lighten-1" large>mdi-email</v-icon>
              </v-btn>
            </template>
            <span>Enviar boletas mensuales por email</span>
          </v-tooltip>

          <v-spacer v-if="tipoDePlanilla === 'Boleta mensual'"></v-spacer>

          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn
                @click="descargarPDF"
                v-on="on"
                fab
                v-if="tipoDePlanilla === 'Boleta mensual'"
              >
                <v-icon color="light-blue lighten-1" large
                  >mdi-cloud-print-outline</v-icon
                >
              </v-btn>
            </template>
            <span>Descargar boletas seleccionadas</span>
          </v-tooltip>

          <v-menu
            v-if="tipoDePlanilla !== 'Boleta mensual'"
            bottom
            origin="center center"
            transition="scale-transition"
            rounded="b-xl"
          >
            <template v-slot:activator="{ on: menu, attrs }">
              <v-tooltip top>
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn v-on="{ ...tooltip, ...menu }" fab v-bind="attrs">
                    <v-icon class="boton-txt" large>mdi-file-document</v-icon>
                  </v-btn>
                </template>
                <span>Descargar archivos como texto</span>
              </v-tooltip>
            </template>

            <v-list>
              <v-list-item
                v-for="(tipoTXT, index) in tiposTXT"
                :key="index"
                link
                @click="descargarTXT(tipoTXT.title)"
                disabled
              >
                <v-list-item-title>{{ tipoTXT.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-spacer></v-spacer>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar persona..."
            single-line
            hide-details
          />
        </v-toolbar>
      </template>

      <template v-slot:[`item.netoAPagar`]="{ item }">
        {{ netoTotalAPagar(item) }}
      </template>

      <template v-slot:header>
        <!-- encabezados del proceso Adelanto de sueldo -->
        <thead
          v-if="!esconderEncabezados && tipoDePlanilla === 'Adelanto de sueldo'"
        >
          <tr>
            <th colspan="4" rowspan="1">Datos personales</th>
            <th :colspan="asistenciaHeaders" rowspan="1">
              Asistencia
            </th>

            <th :colspan="ingresoHeaders" rowspan="1">
              Ingresos
            </th>
            <!-- <div > -->
            <th
              :colspan="descuentoHeaders"
              rowspan="1"
              v-if="mostrarHeadersDescuentos"
            >
              Descuentos
            </th>
            <!-- </div> -->

            <!-- <th :colspan="aporteHeaders" rowspan="1">
              Aportes
            </th> -->

            <th colspan="1" rowspan="1">Total</th>
          </tr>
        </thead>
        <!-- encabezados del proceso Remuneración -->
        <thead v-if="!esconderEncabezados && tipoDePlanilla === 'Remuneración'">
          <tr>
            <th colspan="5" rowspan="1">Datos personales</th>
            <th :colspan="asistenciaHeaders" rowspan="1">
              Asistencia
            </th>

            <th :colspan="ingresoHeaders" rowspan="1">
              Ingresos
            </th>

            <th :colspan="descuentoHeaders" rowspan="1">
              Descuentos
            </th>

            <th :colspan="aporteHeaders" rowspan="1">
              Aportes
            </th>

            <th colspan="1" rowspan="1">Total</th>
          </tr>
        </thead>
        <!-- encabezados del proceso Gratificación -->
        <thead
          v-if="!esconderEncabezados && tipoDePlanilla === 'Gratificación'"
        >
          <tr>
            <th colspan="9" rowspan="1">Datos</th>

            <th :colspan="ingresoHeaders" rowspan="1">
              Ingresos
            </th>

            <th :colspan="descuentoHeaders" rowspan="1">
              Descuentos
            </th>

            <th colspan="1" rowspan="1">Total</th>
          </tr>
        </thead>
        <!-- encabezados del proceso CTS -->
        <thead v-if="!esconderEncabezados && tipoDePlanilla === 'CTS'">
          <tr>
            <th colspan="10" rowspan="1">Datos personales</th>

            <!-- <th :colspan="ingresoHeaders" rowspan="1">
              Ingresos
            </th> -->

            <th colspan="1" rowspan="1">Total</th>
          </tr>
        </thead>
        <!-- encabezados del proceso Liquidación -->
        <thead v-if="!esconderEncabezados && tipoDePlanilla === 'Liquidación'">
          <tr>
            <th colspan="20" rowspan="1">Datos</th>

            <th :colspan="5" rowspan="1">
              Ingresos
            </th>

            <th :colspan="descuentoHeaders" rowspan="1">
              Descuentos
            </th>

            <th :colspan="aporteHeaders" rowspan="1">
              Aportes
            </th>

            <th colspan="1" rowspan="1">Total</th>
          </tr>
        </thead>
        <!-- encabezados del proceso Vacaciones -->
        <thead v-if="!esconderEncabezados && tipoDePlanilla === 'Vacaciones'">
          <tr>
            <th colspan="5" rowspan="1">Datos personales</th>

            <th :colspan="ingresoHeaders" rowspan="1">
              Ingresos
            </th>

            <th :colspan="descuentoHeaders" rowspan="1">
              Descuentos
            </th>

            <th :colspan="aporteHeaders" rowspan="1">
              Aportes
            </th>

            <th colspan="1" rowspan="1">Total</th>
          </tr>
        </thead>
        <!-- encabezados del proceso Locación de servicio -->
        <thead
          v-if="
            !esconderEncabezados && tipoDePlanilla === 'Locación de servicio'
          "
        >
          <tr>
            <th colspan="4" rowspan="1">Datos personales</th>

            <th :colspan="ingresoHeaders" rowspan="1">
              Ingresos
            </th>

            <th :colspan="descuentoHeaders" rowspan="1">
              Descuentos
            </th>

            <!-- <th :colspan="aporteHeaders" rowspan="1">
              Aportes
            </th> -->

            <th colspan="1" rowspan="1">Total</th>
          </tr>
        </thead>
        <!-- encabezados del proceso Boleta mensual -->
        <thead
          v-if="!esconderEncabezados && tipoDePlanilla === 'Boleta mensual'"
        >
          <tr>
            <!-- Cabecera para seleccionar personal para descargar boletas -->
            <th colspan="1" rowspan="1">
              <div style="display: flex; align-items: center;">
                <input
                  type="checkbox"
                  v-model="selectAll"
                  style="margin-right: 10px; transform: scale(1.3);"
                />
                <span style="text-align: center;">Selecciona todo</span>
              </div>
            </th>

            <th colspan="4" rowspan="1">Datos personales</th>

            <th :colspan="ingresoHeaders" rowspan="1">
              Ingresos
            </th>

            <th :colspan="descuentoHeaders" rowspan="1">
              Descuentos
            </th>

            <th :colspan="aporteHeaders" rowspan="1">
              Aportes
            </th>

            <th colspan="1" rowspan="1">Total</th>
          </tr>
        </thead>
      </template>
      <!-- template para agregar el checkbox a cada fila cuando la planilla es la de Boleta Mensual -->
      <template
        v-slot:item.selected="{ item }"
        v-if="tipoDePlanilla === 'Boleta mensual'"
      >
        <div style="display: flex; justify-content: center;">
          <v-checkbox
            v-model="item.selected"
            @change="checkSelectAll"
          ></v-checkbox>
        </div>
      </template>
    </v-data-table>

    <!-- <VueHtml2pdf
      :manual-pagination="true"
      :enable-download="false"
      :preview-modal="true"
      :pdf-quality="1.1"
      :filename="nombreArchivoPDF"
      :paginate-elements-by-height="1200"
      pdf-format="a4"
      pdf-orientation="portrait"
      @progress="progresoDescargaBoletas($event)"
      ref="descargarBoletasMensuales"
    >
      <section slot="pdf-content">
        <section class="pdf-item" v-for="(persona, i) in personal" :key="i">
          <FormatoBoletaMensual :persona="persona"></FormatoBoletaMensual>
          <div class="html2pdf__page-break" />
        </section>
      </section>
    </VueHtml2pdf> -->
    <div v-if="tipoDePlanilla === 'Boleta mensual'">
      <VueHtml2pdf
        :manual-pagination="true"
        :enable-download="false"
        :preview-modal="false"
        pdf-format="a4"
        :filename="nombreArchivoPDF"
        ref="descargarBoletasMensuales"
        @beforeDownload="descargarBoletasDePago($event, personaSeleccionada)"
      >
        <section slot="pdf-content">
          <section class="pdf-item">
            <FormatoBoletaMensual
              :persona="personaSeleccionada"
            ></FormatoBoletaMensual>
            <div class="html2pdf__page-break" />
          </section>
        </section>
      </VueHtml2pdf>
      <VueHtml2pdf
        :manual-pagination="true"
        :enable-download="false"
        :preview-modal="false"
        pdf-format="a4"
        :filename="nombreArchivoPDF"
        v-for="(persona, i) in personal"
        :key="i"
        ref="boletaPDF"
        @beforeDownload="convertirPdfABase64($event, persona)"
      >
        <section slot="pdf-content">
          <section class="pdf-item">
            <FormatoBoletaMensual :persona="persona"></FormatoBoletaMensual>
            <div class="html2pdf__page-break" />
          </section>
        </section>
      </VueHtml2pdf>
    </div>

    <v-dialog v-model="mostrarLoader" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Cargando, por favor espere...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import moment from 'moment';
import { mapState } from 'vuex';
import { excelExport } from '@/plugins/excelExport.js';
import FileSaver from 'file-saver';
import { getPlanillaPorPeriodoYPorTipo } from '@/api/payments/getPlanillaPorPeriodoYPorTipo.js';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';
import VueHtml2pdf from 'vue-html2pdf';
import FormatoBoletaMensual from './FormatoBoletaMensual.vue';
// import { htmlToPdf } from '@/plugins/exportToPdf.js';
import { enviarBoletaMensual } from '@/api/email/enviarBoletaMensual.js';
import JSZip from 'jszip';

export default {
  name: 'TablaReportePlanilla',
  data: () => ({
    periodoSeleccionadoMes: '',
    periodoSeleccionadoAnio: '',
    periodosDisponiblesAnio: [
      '2023',
      '2024',
      '2025',
      '2026',
      '2027',
      '2028',
      '2029',
      '2030',
      '2031',
      '2032',
      '2033',
      '2034',
      '2035',
      '2036',
      '2037',
      '2038',
      '2039',
      '2040',
    ],
    asistenciaAEditar: {},
    personaAEditar: {},
    tableLoading: false,
    pdfLoading: false,
    asistencia: [],
    personal: [],
    diasDelMes: [],
    search: '',
    idTablaReportePlanilla: '',
    tiposDeAsistencia: [],
    tiposDeIngresos: [],
    tiposDeDescuentos: [],
    tiposDeAportes: [],
    zip: new JSZip(),
    loadingPromises: [],
    personaSeleccionada: {},
    selectAll: false,
    parametroAsignacionFamiliar: {},
    planillas: [],
    esconderEncabezados: true,
    verPDF: false,
    tiposTXT: [
      //{ title: 'BCP' },
      // { title: 'Interbank' },
      { title: 'PLAME' },
    ],
  }),
  components: {
    VueHtml2pdf,
    FormatoBoletaMensual,
  },
  props: {
    tipoDePlanilla: String,
  },
  computed: {
    mostrarHeadersDescuentos() {
      return this.tiposDeDescuentos.length > 0;
    },
    mostrarLoader() {
      return this.pdfLoading || this.tableLoading;
    },
    nombreArchivoPDF() {
      return `Boletas de pago ${
        this.planillaSeleccionada && this.planillaSeleccionada.periodo
          ? this.planillaSeleccionada.periodo
          : 'Sin periodo'
      }`;
    },
    ...mapState([
      'conceptosSunat',
      'selectorPlanillasDialog',
      'planillaSeleccionada',
      'tipoReportePlanilla',
      'cliente',
    ]),
    periodosDisponiblesMes() {
      let periodosDisponibles = [
        'Enero',
        'Febrero',
        'Marzo',
        'Abril',
        'Mayo',
        'Junio',
        'Julio',
        'Agosto',
        'Septiembre',
        'Octubre',
        'Noviembre',
        'Diciembre',
      ];

      if (this.tipoDePlanilla === 'Gratificación') {
        periodosDisponibles = ['Julio', 'Diciembre'];
      }

      if (this.tipoDePlanilla === 'CTS') {
        periodosDisponibles = ['Mayo', 'Noviembre'];
      }

      return periodosDisponibles;
    },
    tituloReporte() {
      return this.tipoDePlanilla;
    },
    asistenciaHeaders() {
      return this.tiposDeAsistencia.length;
    },
    ingresoHeaders() {
      return this.tiposDeIngresos.length;
    },
    descuentoHeaders() {
      return this.tiposDeDescuentos.length;
    },
    aporteHeaders() {
      return this.tiposDeAportes.length;
    },
    headers() {
      let fixedHeaders = [
        {
          text: 'Documento',
          value: 'doi',
          // width: '100px',
        },
        {
          text: 'Nombre',
          value: 'nombre',
          width: '275px',
        },
        {
          text: 'Cargo',
          value: 'cargo',
          // width: '100px',
        },
        {
          text: 'Fecha de ingreso',
          value: 'fecha_ingreso',
          width: '110px',
        },
      ];

      //encabezados si la planilla es Boleta mensual
      if (this.tipoDePlanilla === 'Boleta mensual') {
        const encabezados = [
          {
            text: '',
            value: 'selected',
            sortable: false,
            width: '110px',
            align: 'center',
          },
        ];
        //añadir encabezados de boleta mensual al inicio del array
        fixedHeaders.unshift(...encabezados);
      }

      //encabezados si la planilla es Adelanto de sueldo
      if (this.tipoDePlanilla === 'Adelanto de sueldo') {
        // const encabezados = [
        //   {
        //     text: 'Fecha de cese',
        //     value: 'fecha_cese',
        //     width: '110px',
        //     // divider: true,
        //   },
        // ];
        //añadir encabezados de Asistencia
        fixedHeaders.push(...this.tiposDeAsistencia);
        // fixedHeaders.push(...encabezados, ...this.tiposDeAsistencia);
      }

      //encabezados si la planilla es Remuneración
      if (this.tipoDePlanilla === 'Remuneración') {
        const encabezados = [
          {
            text: 'Fecha de cese',
            value: 'fecha_cese',
            width: '110px',
            // divider: true,
          },
        ];
        //añadir encabezados de Asistencia
        fixedHeaders.push(...encabezados, ...this.tiposDeAsistencia);
      }

      //encabezados si la planilla es Gratificación
      if (this.tipoDePlanilla === 'Gratificación') {
        const encabezados = [
          {
            text: 'Meses laborados',
            value: 'meses_laborados',
            // width: '110px',
          },
          {
            text: 'Remuneración básica',
            value: 'remuneracion_basica',
            // width: '110px',
          },
          {
            text: 'Asignación familiar',
            value: 'parametro_asignacion_familiar',
            // width: '110px',
          },
          {
            text: 'Promedio otros ingresos',
            value: 'promedio_conceptos_afectos',
            width: '180px',
          },
          {
            text: 'Remuneración base grati',
            value: 'remuneracion_base_grati',
            // width: '180px',
          },
          // {
          //   text: 'Grati trunca',
          //   value: 'grati_trunca',
          //   // width: '180px',
          // },
        ];
        //añadir encabezados de Asistencia
        fixedHeaders.push(...encabezados);
      }

      //encabezados si la planilla es CTS
      if (this.tipoDePlanilla === 'CTS') {
        const encabezados = [
          {
            text: 'Total días',
            value: 'total_dias',
            // width: '110px',
          },
          {
            text: 'Remuneración básica',
            value: 'remuneracion_basica',
            // width: '110px',
          },
          {
            text: 'Asignación familiar',
            value: 'parametro_asignacion_familiar',
            // width: '110px',
          },
          {
            text: 'Promedio otros ingresos',
            value: 'promedio_conceptos_afectos',
            width: '180px',
          },
          {
            text: 'Sexto de última remuneración',
            value: 'sexto_remuneracion',
            // width: '180px',
          },
          {
            text: 'Remuneración base para CTS',
            value: 'remuneracion_base_cts',
            // width: '180px',
          },
        ];
        //añadir encabezados de Asistencia
        fixedHeaders.push(...encabezados);
      }

      //encabezados si la planilla es Liquidación
      if (this.tipoDePlanilla === 'Liquidación') {
        const encabezados = [
          {
            text: 'Fecha de cese',
            value: 'fecha_cese',
            width: '110px',
            // divider: true,
          },
          {
            text: 'Motivo de cese',
            value: 'motivo_cese',
            // width: '110px',
            // divider: true,
          },
          {
            text: 'Tiempo de servicio en días',
            value: 'tiempo_servicio_dias',
            width: '170px',
          },
          {
            text: 'Días vacaciones indemnizables',
            value: 'dias_vacaciones_indemnizables',
            // width: '110px',
          },
          {
            text: 'Días vacaciones pendientes',
            value: 'dias_vacaciones_truncas',
            // width: '110px',
          },
          {
            text: 'Días CTS truncas',
            value: 'dias_cts_truncas',
            // width: '110px',
          },
          {
            text: 'Meses gratificación trunca',
            value: 'meses_gratificacion_trunca',
            // width: '110px',
          },
          {
            text: 'Remuneración básica',
            value: 'remuneracion_basica',
            // width: '110px',
          },
          {
            text: 'Asignación familiar',
            value: 'parametro_asignacion_familiar',
            // width: '110px',
          },
          {
            text: 'Promedio otros ingresos por vacaciones truncas', //buscar 6 ultimas planillas de remuneración
            value: 'promedio_conceptos_afectos_vacaciones_truncas',
            width: '210px',
          },
          {
            text: 'Remuneración base por vacaciones truncas',
            value: 'remuneracion_base_afectos_vacaciones_truncas',
            width: '180px',
          },
          {
            text: 'Promedio otros ingresos por CTS truncas',
            value: 'promedio_conceptos_afectos_cts_truncas',
            width: '210px',
          },
          {
            text: 'Sexto de última gratificación',
            value: 'sexto_gratificacion',
            // width: '180px',
          },
          {
            text: 'Remuneración base para CTS truncas',
            value: 'remuneracion_base_afectos_cts_truncas',
            width: '180px',
          },

          {
            text: 'Promedio otros ingresos para gratificación truncas',
            value: 'promedio_conceptos_afectos_gratificacion_truncas',
            width: '210px',
          },
          {
            text: 'Remuneración base para gratificación trunca',
            value: 'remuneracion_base_gratificacion',
            width: '180px',
          },

          {
            text: 'Monto por vacaciones indemnizables',
            value: 'monto_vacaciones_indemnizables',
            width: '180px',
          },
          {
            text: 'Monto por vacaciones truncas',
            value: 'monto_vacaciones_truncas',
            width: '180px',
          },
          {
            text: 'Monto por CTS trunca',
            value: 'monto_cts_trunca',
            width: '180px',
          },
          {
            text: 'Monto por gratificación trunca',
            value: 'monto_gratificacion_trunca',
            width: '180px',
          },
          {
            text: 'Monto por bonificación extraordinaria',
            value: 'monto_bonificacion_extraordinaria',
            width: '180px',
          },
        ];
        //añadir encabezados de Asistencia
        fixedHeaders.push(...encabezados);
      }

      //encabezados si la planilla es Vacaciones
      if (this.tipoDePlanilla === 'Vacaciones') {
        const encabezados = [
          {
            text: 'Días de vacaciones',
            value: 'cantidadDiasVacaciones',
            width: '110px',
            // divider: true,
          },
        ];
        fixedHeaders.push(...encabezados);
      }

      //no considerar los ingresos en el proceso de CTS debido a la composición de los encabezados
      if (
        this.tipoDePlanilla !== 'CTS' &&
        this.tipoDePlanilla !== 'Liquidación'
      ) {
        //añadir encabezados de Ingresos
        this.tiposDeIngresos.map((ingreso) => {
          let encabezadoEditado = this.conceptosSunat.find(
            (concepto) => concepto.codigo === ingreso.codigo
          );
          if (encabezadoEditado) {
            ingreso.width = '130px';
            ingreso.text =
              ingreso.text + ' ' + encabezadoEditado.nombre.substring(0, 13);
          }
          return ingreso;
        });
        fixedHeaders.push(...this.tiposDeIngresos);
      }

      //añadir encabezados de Descuentos
      this.tiposDeDescuentos.map((descuento) => {
        let encabezadoEditado = this.conceptosSunat.find(
          (concepto) => concepto.codigo === descuento.codigo
        );
        if (encabezadoEditado) {
          descuento.width = '130px';
          descuento.text =
            descuento.text + ' ' + encabezadoEditado.nombre.substring(0, 13);
        }
        return descuento;
      });
      fixedHeaders.push(...this.tiposDeDescuentos);

      //añadir encabezados de Aportes
      this.tiposDeAportes.map((aporte) => {
        let encabezadoEditado = this.conceptosSunat.find(
          (concepto) => concepto.codigo === aporte.codigo
        );
        if (encabezadoEditado) {
          aporte.width = '130px';
          aporte.text =
            aporte.text + ' ' + encabezadoEditado.nombre.substring(0, 13);
        }
        return aporte;
      });
      fixedHeaders.push(...this.tiposDeAportes);

      //añadir header para neto total a pagar
      fixedHeaders.push({
        text: 'Neto a pagar',
        value: 'netoAPagar',
        width: '140px',
      });

      return fixedHeaders;
    },
  },
  watch: {
    selectAll(newValue) {
      // console.log('se seleccionó todo -> ', newValue);
      if (newValue) {
        this.personal = this.personal.map((person) => ({
          ...person,
          selected: true,
        }));
      } else if (!newValue && this.personal.every((p) => p.selected)) {
        this.personal = this.personal.map((person) => ({
          ...person,
          selected: false,
        }));
      }
    },
    tipoReportePlanilla() {
      this.esconderEncabezados = true;
      this.personal = [];
      this.planillas = [];
      this.tiposDeAportes = [];
      this.tiposDeAsistencia = [];
      this.tiposDeIngresos = [];
      this.tiposDeDescuentos = [];
      // this.tableLoading = false;
      this.periodoSeleccionadoMes = '';
      this.periodoSeleccionadoAnio = '';
    },
    periodoSeleccionadoMes() {
      if (
        !this.periodoSeleccionadoMes ||
        this.periodoSeleccionadoMes === '' ||
        !this.periodoSeleccionadoAnio ||
        this.periodoSeleccionadoAnio === ''
      ) {
        // this.tableLoading = false;
        return;
      }
      this.esconderEncabezados = true;
      this.personal = [];
      this.planillas = [];
      this.tiposDeAportes = [];
      this.tiposDeAsistencia = [];
      this.tiposDeIngresos = [];
      this.tiposDeDescuentos = [];
      let periodoABuscar =
        this.periodoSeleccionadoMes + '-' + this.periodoSeleccionadoAnio;
      if (this.tipoDePlanilla === 'Boleta mensual') {
        this.obtenerPlanillasParaBoletasMensuales(periodoABuscar);
        return;
      }
      this.obtenerPlanillaPorPeriodoYTipo(periodoABuscar);
    },
    periodoSeleccionadoAnio() {
      if (
        !this.periodoSeleccionadoMes ||
        this.periodoSeleccionadoMes === '' ||
        !this.periodoSeleccionadoAnio ||
        this.periodoSeleccionadoAnio === ''
      ) {
        // this.tableLoading = false;
        return;
      }
      this.esconderEncabezados = true;
      this.personal = [];
      this.planillas = [];
      this.tiposDeAportes = [];
      this.tiposDeAsistencia = [];
      this.tiposDeIngresos = [];
      this.tiposDeDescuentos = [];
      let periodoABuscar =
        this.periodoSeleccionadoMes + '-' + this.periodoSeleccionadoAnio;
      if (this.tipoDePlanilla === 'Boleta mensual') {
        this.obtenerPlanillasParaBoletasMensuales(periodoABuscar);
        return;
      }
      this.obtenerPlanillaPorPeriodoYTipo(periodoABuscar);
    },
    planillaSeleccionada() {
      if (this.planillaSeleccionada.tipo_proceso === this.tipoDePlanilla) {
        this.personal = [];
        this.esconderEncabezados = false;
        this.procesarPlanillaParaTabla(this.planillaSeleccionada);
        return;
      }
    },
  },
  mounted() {
    this.parametroAsignacionFamiliar = this.cliente.datos_empresa.parametros.find(
      (parametro) => parametro.concepto === 'ASIGNACION FAMILIAR'
    );
    this.esconderEncabezados = true;
  },
  methods: {
    base64ToBlob(base64) {
      const binaryString = window.atob(base64);
      const length = binaryString.length;
      const bytes = new Uint8Array(length);

      for (let i = 0; i < length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      return new Blob([bytes], { type: 'application/pdf' });
    },
    checkSelectAll() {
      this.selectAll = this.personal.every((person) => person.selected);
    },
    progresoDescargaBoletas(progreso) {
      console.log(progreso);
    },
    async convertirPdfABase64({ html2pdf, options, pdfContent }, persona) {
      // console.log('aqui es', persona);
      // let pdfs = [];
      let pdfBase64 = await html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        // .get('pdf')
        // .then((pdf) => {
        //   console.log(pdf);
        //   const totalPages = pdf.internal.getNumberOfPages();
        //   for (let i = 1; i <= totalPages; i++) { //agregar paginación al pie de página del PDF
        //     pdf.setPage(i);
        //     pdf.setFontSize(10);
        //     pdf.setTextColor(150);
        //     pdf.text(
        //       'Page ' + i + ' of ' + totalPages,
        //       pdf.internal.pageSize.getWidth() * 0.88,
        //       pdf.internal.pageSize.getHeight() - 0.3
        //     );
        //     // pdfs.push(pdf);
        //   }
        // })
        .output('datauristring'); // se genera un pdf por cada persona en formato base64 para luego ser enviado por email
      // console.log(pdfBase64);
      // console.log(persona);
      if (persona.email && persona.email !== '') {
        const emailComplete = {
          periodo: this.planillaSeleccionada.periodo,
          // emailFrom: this.cliente.email_remitente_boletas,
          // emailFrom: 'carlos.behr@ctiprocesos.com',
          emailFrom: 'no-reply@ctiroads.com',
          // emailFrom: 'carlos.behr.s@gmail.com',
          emailTo: persona.email,
          // emailTo: 'carlos.behr@ctiprocesos.com',
          body: pdfBase64,
        };
        enviarBoletaMensual(emailComplete);
      }
    },
    async descargarBoletasDePago({ html2pdf, options, pdfContent }, persona) {
      // console.log('aqui está persona -> ', persona);
      const promise = new Promise((resolve, reject) => {
        html2pdf()
          .set(options)
          .from(pdfContent)
          .toPdf()
          .output('datauristring')
          .then((pdfBase64) => {
            const base64Data = pdfBase64.split(';base64,')[1];
            const pdfBlob = this.base64ToBlob(base64Data);
            this.zip.file(`${persona.nombre}.pdf`, pdfBlob, { binary: true });

            resolve();
          })
          .catch(reject);
      });

      this.loadingPromises.push(promise);
    },
    async descargarPDF() {
      // console.log(
      //   'iniciar loader descarga/impresión',
      //   this.planillas,
      //   this.planillaSeleccionada,
      //   this.personal,
      //   this.$refs //validar cuando son 52 trabajadores a más, probar creando pdf individuales y unificarlos luego
      // );
      this.pdfLoading = true;
      if (!this.planillaSeleccionada.personal) {
        const errorMsg = 'Primero debe seleccionar un periodo';
        this.showErrorAlert(errorMsg);
        return;
      }

      const personalSeleccionado = this.personal.filter(
        (persona) => persona.selected
      );
      let counter = 0;
      let blockSize = 10;
      for (const persona of personalSeleccionado) {
        this.personaSeleccionada = persona;
        await this.$refs.descargarBoletasMensuales.generatePdf();
        counter++;
        if (counter % blockSize == 0) {
          await Promise.all(this.loadingPromises);
          this.loadingPromises = [];
        }
      }
      await Promise.all(this.loadingPromises);

      const zipBlob = await this.zip.generateAsync({ type: 'blob' });

      const link = document.createElement('a');
      link.href = URL.createObjectURL(zipBlob);
      link.download = this.nombreArchivoPDF;
      link.click();
      // await this.$refs.descargarBoletasMensuales.generatePdf();
      this.pdfLoading = false;
      // console.log('terminar loader descarga/impresión');
      this.personaSeleccionada = {};
      this.zip = new JSZip();
      this.loadingPromises = [];
    },
    showEnviarCorreoAlert() {
      this.$swal
        .fire({
          title: `Atención!`,
          text: `Se van a enviar las boletas mensuales de ${this.planillaSeleccionada.personal.length} trabajadores, es conforme?`,
          icon: 'warning',
          showCancelButton: true,
          cancelButtonColor: 'grey',
          cancelButtonText: 'Cancelar',
          confirmButtonColor: 'success',
          confirmButtonText: 'Enviar',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.enviarEmailBoletasMensuales();
          }
        });
    },
    enviarBoletasMensualesPorEmail() {
      // if (!this.cliente.datos_empresa.email_remitente_boletas) {
      //   let errorMsg =
      //     'Aún no ha configurado el correo remitente para esta función, por favor vaya al módulo de configuración!';
      //   this.showErrorAlert(errorMsg);
      //   return;
      // }

      if (!this.planillaSeleccionada.personal) {
        const errorMsg = 'Primero debe seleccionar un periodo';
        this.showErrorAlert(errorMsg);
        return;
      }
      this.showEnviarCorreoAlert();
    },
    enviarEmailBoletasMensuales() {
      // console.log('mostrar inicio de loader aqui');
      const cantidadEmailsPorSegundo = 14; // parámetro definido en AWS SES (quota)
      this.planillaSeleccionada.personal.map((persona, i) => {
        delay(i);
        return persona;
      });

      const parent = this;
      async function delay(i) {
        await setTimeout(() => {
          parent.$refs.boletaPDF[i].generatePdf();
        }, Math.ceil(1000 / cantidadEmailsPorSegundo) * i); //se redondea al entero superior para no superar la cuota de envío de emails por segundo
      }
      this.showSuccessAlert();
      // console.log('mostrar fin de loader aqui');
    },
    showErrorAlert(errorMsg) {
      this.$swal.fire({
        title: `Error`,
        text: errorMsg,
        icon: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    showSuccessAlert() {
      this.$swal.fire({
        title: `Operación realizada correctamente!`,
        text: '',
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    async descargarTXT(banco) {
      if (this.personal.length <= 0) {
        let errorMsg = 'Primero debe seleccionar una planilla!';
        this.showErrorAlert(errorMsg);
        return;
      }

      let TXTEnJSON = [];

      switch (banco) {
        case 'BCP':
          console.log('Descargar TXT para BCP');
          break;
        case 'Interbank':
          this.personal.map((persona) => {
            //crear cada línea del TXT con los datos de cada persona
            const codigoPersona =
              persona.doi && persona.doi.length >= 20
                ? persona.doi.substring(0, 20)
                : `${persona.doi}${' '.repeat(20 - persona.doi.length)}`; //20 caracteres
            //obtener el tipo de moneda
            const tipoMoneda = persona.monedaDePago === 'Soles' ? '01' : '10';
            //calcular el monto neto a pagar, se suman todos los ingresos - los descuentos
            let montoNetoTotalAPagar = 0;
            persona.ingresos_por_concepto.map((ingreso) => {
              montoNetoTotalAPagar = montoNetoTotalAPagar + ingreso.monto;
              return ingreso;
            });
            persona.descuentos_por_concepto.map((descuento) => {
              montoNetoTotalAPagar = montoNetoTotalAPagar - descuento.monto;
              return descuento;
            });
            if (montoNetoTotalAPagar < 0) montoNetoTotalAPagar = 0;
            const montoNetoTotalAPagarEnString =
              Number(montoNetoTotalAPagar)
                .toFixed(2)
                .split('.')[0] +
              Number(montoNetoTotalAPagar)
                .toFixed(2)
                .split('.')[1];
            const montoNetoAPagar =
              montoNetoTotalAPagarEnString.length >= 15
                ? montoNetoTotalAPagarEnString.substring(0, 15)
                : `${'0'.repeat(
                    15 - montoNetoTotalAPagarEnString.length
                  )}${montoNetoTotalAPagarEnString}`; // 15 caracteres
            //obtener numero de cuenta del banco

            let numeroCuentaBanco = ''; // 30 caracteres en total

            if (persona.nroDeCuenta) {
              switch (persona.nroDeCuenta.length) {
                case 13: //cuenta de ahorros IBK
                  numeroCuentaBanco = `09002${tipoMoneda}${
                    persona.nroDeCuenta
                  }${' '.repeat(10)}`;

                  break;
                case 20: //interbancario
                  numeroCuentaBanco = `99${'0'.repeat(8)}${
                    persona.nroDeCuenta
                  }`;
                  break;

                default:
                  numeroCuentaBanco = `00${' '.repeat(28)}`;
                  break;
              }
            } else {
              numeroCuentaBanco = `00${' '.repeat(28)}`;
            }

            let documento = ''; //17 caracteres
            switch (persona.tipoDocumento) {
              case 'DNI':
                documento = `P01${persona.doi}${' '.repeat(6)}`;
                break;
              case 'CE':
                documento = `P03${persona.doi}${' '.repeat(2)}`;
                break;
              case 'PASAPORTE':
                documento = `P05${persona.doi}${' '.repeat(2)}`;
                break;

              default:
                documento = `P01${'0'.repeat(14)}`;
                break;
            }

            const apellidoPaterno =
              persona.apellidoPaterno.length >= 20
                ? persona.apellidoPaterno.substring(0, 20)
                : `${persona.apellidoPaterno}${' '.repeat(
                    20 - persona.apellidoPaterno.length
                  )}`; // 20 caracteres

            const apellidoMaterno =
              persona.apellidoMaterno.length >= 20
                ? persona.apellidoMaterno.substring(0, 20)
                : `${persona.apellidoMaterno}${' '.repeat(
                    20 - persona.apellidoMaterno.length
                  )}`; // 20 caracteres

            const nombres =
              persona.nombresSolos.length >= 20
                ? persona.nombresSolos.substring(0, 20)
                : `${persona.nombresSolos}${' '.repeat(
                    20 - persona.nombresSolos.length
                  )}`; // 20 caracteres

            const finalDeFilaEnTXT = '0'.repeat(15) + ' '.repeat(6);

            let filaParaTXTPorPersona = `02${codigoPersona} ${' '.repeat(
              27
            )} ${tipoMoneda}${montoNetoAPagar} ${numeroCuentaBanco}${documento} ${apellidoPaterno}${apellidoMaterno}${nombres}  ${finalDeFilaEnTXT}`;
            // "a".repeat(10)
            TXTEnJSON.push(filaParaTXTPorPersona);
            return persona;
          });
          var blob = new Blob(
            [
              JSON.stringify(TXTEnJSON)
                .replace(/\["/g, '')
                .replace(/"\]/g, '')
                .replace(/","/g, '\r\n'),
            ],
            {
              type: 'text/plain;charset=utf-8',
            }
          );
          FileSaver.saveAs(
            blob,
            `Planilla-${this.tipoDePlanilla}-${this.periodoSeleccionadoMes}${this.periodoSeleccionadoAnio}`
          );
          break;
        case 'PLAME':
          console.log('Descargar TXT para PLAME');
          break;

        default:
          break;
      }
    },
    getTablaReportePlanilla() {
      this.idTablaReportePlanilla = this.tipoDePlanilla;
      return this.idTablaReportePlanilla;
    },
    descargarTabla() {
      const nombreDeHojaExcel = `Reporte`;
      excelExport(this.idTablaReportePlanilla, nombreDeHojaExcel);
    },
    netoTotalAPagar(item) {
      // console.log(item);
      let ingresos = 0;
      let descuentos = 0;
      item.ingresos_por_concepto.map((ingreso) => {
        ingresos = ingresos + ingreso.monto;
        return ingreso;
      });
      item.descuentos_por_concepto.map((descuento) => {
        descuentos = descuentos + descuento.monto;
        return descuento;
      });
      let total = ingresos - descuentos;
      return Math.round(total * 100) / 100;
    },
    async obtenerPlanillaPorPeriodoYTipo(periodo) {
      const {
        error,
        planillas,
        tokenExpired,
      } = await getPlanillaPorPeriodoYPorTipo(periodo, this.tipoDePlanilla);
      if (error) {
        //do something
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (planillas.length > 0) {
        this.planillas = [];
        this.$store.state.planillas = planillas;
        this.$store.state.selectorPlanillasDialog = true;
      } else {
        this.planillas = [];
        this.personal = [];
        this.tiposDeAportes = [];
        this.tiposDeAsistencia = [];
        this.tiposDeIngresos = [];
        this.tiposDeDescuentos = [];
      }

      this.tableLoading = false;
    },
    async obtenerPlanillasParaBoletasMensuales(periodo) {
      // inicio de loader
      this.tableLoading = true;
      let planillasParaBoletasMensuales = [];
      const {
        error: errorRemuneracion,
        planillas: planillasRemuneracion,
        tokenExpired,
      } = await getPlanillaPorPeriodoYPorTipo(periodo, 'Remuneración');
      if (errorRemuneracion) {
        //do something
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (planillasRemuneracion.length > 0) {
        planillasRemuneracion.map((planilla) => {
          planillasParaBoletasMensuales.push(planilla);
          return planilla;
        });
      }
      const {
        error: errorVacaciones,
        planillas: planillasVacaciones,
      } = await getPlanillaPorPeriodoYPorTipo(periodo, 'Vacaciones');
      if (errorVacaciones) {
        //do something
      }
      if (planillasVacaciones.length > 0) {
        planillasVacaciones.map((planilla) => {
          planillasParaBoletasMensuales.push(planilla);
          return planilla;
        });
      }

      if (planillasParaBoletasMensuales.length > 0) {
        this.planillas = [];
        this.$store.state.planillas = planillasParaBoletasMensuales;

        //######### unificar todas las planillas encontradas en el periodo ########
        let planillasUnificadas = {
          tipo_proceso: this.tipoDePlanilla,
          personal: [],
          periodo,
        };

        planillasParaBoletasMensuales.map((planilla) => {
          planilla.personal.map((persona) => {
            const personaEncontradaEnPlanillaUnificada = planillasUnificadas.personal.find(
              (personaPlanillaUnificada) =>
                personaPlanillaUnificada.doi.toString() ===
                persona.doi.toString()
            );

            if (!personaEncontradaEnPlanillaUnificada) {
              planillasUnificadas.personal.push(persona);
            } else {
              persona.ingresos_por_concepto.map((ingreso) => {
                let ingresoEncontrado = personaEncontradaEnPlanillaUnificada.ingresos_por_concepto.find(
                  (ingresoPlanillaUnificada) =>
                    ingresoPlanillaUnificada.codigo === ingreso.codigo
                );
                if (ingresoEncontrado) {
                  ingresoEncontrado.monto =
                    ingresoEncontrado.monto + ingreso.monto;
                } else {
                  personaEncontradaEnPlanillaUnificada.ingresos_por_concepto.push(
                    ingreso
                  );
                }
                return ingreso;
              });

              persona.descuentos_por_concepto.map((descuento) => {
                let descuentoEncontrado = personaEncontradaEnPlanillaUnificada.descuentos_por_concepto.find(
                  (descuentoPlanillaUnificada) =>
                    descuentoPlanillaUnificada.codigo === descuento.codigo
                );
                if (descuentoEncontrado) {
                  descuentoEncontrado.monto =
                    descuentoEncontrado.monto + descuento.monto;
                } else {
                  personaEncontradaEnPlanillaUnificada.descuentos_por_concepto.push(
                    descuento
                  );
                }
                return descuento;
              });

              persona.aportes_empleador_por_concepto.map((aporte) => {
                let aporteEncontrado = personaEncontradaEnPlanillaUnificada.aportes_empleador_por_concepto.find(
                  (aportePlanillaUnificada) =>
                    aportePlanillaUnificada.codigo === aporte.codigo
                );
                if (aporteEncontrado) {
                  aporteEncontrado.monto =
                    aporteEncontrado.monto + aporte.monto;
                } else {
                  personaEncontradaEnPlanillaUnificada.aportes_empleador_por_concepto.push(
                    aporte
                  );
                }
                return aporte;
              });
            }
            return persona;
          });
          return planilla;
        });

        // ordenar la lista de personas por nombre, de la A a la Z
        planillasUnificadas.personal = planillasUnificadas.personal.sort(
          (a, b) => {
            let na = a.nombre.toLowerCase(),
              nb = b.nombre.toLowerCase();
            return na < nb ? -1 : na > nb ? 1 : 0;
          }
        );

        this.$store.state.planillaSeleccionada = planillasUnificadas;
        //fin de loader
        this.tableLoading = false;
        // #######################################################################
      } else {
        this.planillas = [];
        this.personal = [];
        this.tiposDeAportes = [];
        this.tiposDeAsistencia = [];
        this.tiposDeIngresos = [];
        this.tiposDeDescuentos = [];
      }

      this.tableLoading = false;
    },
    procesarPlanillaParaTabla(planilla) {
      if (planilla.tipo_proceso) {
        this.personal = [];
        this.personal = planilla.personal;

        let fechaInicio = null;
        let fechaFin = null;

        //evaluar la asistencia en el rango de fechas seleccionado al crear la planilla
        let cantidadDiasPeriodoPlanilla = 0;

        if (planilla.tipo_proceso === 'Boleta mensual') {
          fechaInicio = moment(planilla.periodo, 'MMMM-YYYY')
            .tz('America/Lima')
            .startOf('month');
          fechaFin = moment(planilla.periodo, 'MMMM-YYYY')
            .tz('America/Lima')
            .endOf('month');
        } else {
          fechaInicio = moment(planilla.fecha_inicio, 'DD-MM-YYYY').tz(
            'America/Lima'
          );
          fechaFin = moment(planilla.fecha_fin, 'DD-MM-YYYY').tz(
            'America/Lima'
          );
        }
        cantidadDiasPeriodoPlanilla = fechaFin.diff(fechaInicio, 'days') + 1;

        this.personal.map((persona) => {
          // Como estamos viendo un reporte, se puede reiniciar esta variable para computar los días pagados
          persona.cantidad_dias_efectivos = 0;

          // Procesar asistencias si la planilla es de remuneración
          if (
            this.tipoDePlanilla === 'Remuneración' ||
            this.tipoDePlanilla === 'Adelanto de sueldo' ||
            this.tipoDePlanilla === 'Boleta mensual'
          ) {
            persona['selected'] = false; // se agrega esta propiedad para agregar el checkbox a cada persona para la boleta mensual
            persona['parametro_asignacion_familiar'] =
              persona.asignacion_familiar === 'SI'
                ? this.parametroAsignacionFamiliar.monto
                : 0;
            persona.info_dias.map((dia) => {
              //aplicar contador de dias por tipo de jornada
              if (!persona[`${dia.tipo_jornada}`]) {
                persona[`${dia.tipo_jornada}`] = 0;
              }
              persona[`${dia.tipo_jornada}`] = persona.info_dias.filter(
                (asistencia) => asistencia.tipo_jornada === dia.tipo_jornada
              ).length;

              //revisar, validación por ahora por si el tipo de jornada llega como F o Falto
              if (dia.tipo_jornada === 'F' || dia.tipo_jornada === 'Falto') {
                persona['F'] = persona.info_dias.filter(
                  (asistencia) =>
                    asistencia.tipo_jornada === 'Falto' ||
                    asistencia.tipo_jornada === 'F'
                ).length;
              }
              //revisar, validación por ahora por si el tipo de jornada llega como DM o Descanso médico
              if (
                dia.tipo_jornada === 'DM' ||
                dia.tipo_jornada === 'Descanso médico'
              ) {
                persona['DM'] = persona.info_dias.filter(
                  (asistencia) =>
                    asistencia.tipo_jornada === 'Descanso médico' ||
                    asistencia.tipo_jornada === 'DM'
                ).length;
              }
              // persona[`${dia.tipo_jornada}`] =
              //   persona[`${dia.tipo_jornada}`] + 1;
              let asistenciaEncontrada = {};
              if (
                dia.tipo_jornada === 'Descanso' ||
                dia.tipo_jornada === 'Asistencia' ||
                dia.tipo_jornada === 'A' ||
                dia.tipo_jornada === 'Tardanza'
              ) {
                persona.cantidad_dias_efectivos++;
                asistenciaEncontrada = this.tiposDeAsistencia.find(
                  (asistencia) => asistencia.text === 'Días pagados'
                );
              } else if (
                dia.tipo_jornada === 'F' ||
                dia.tipo_jornada === 'Falto'
              ) {
                asistenciaEncontrada = this.tiposDeAsistencia.find(
                  (asistencia) => asistencia.text === 'Falto'
                );
              } else if (
                dia.tipo_jornada === 'DM' ||
                dia.tipo_jornada === 'Descanso médico'
              ) {
                asistenciaEncontrada = this.tiposDeAsistencia.find(
                  (asistencia) => asistencia.text === 'Descanso médico'
                );
              } else {
                asistenciaEncontrada = this.tiposDeAsistencia.find(
                  (asistencia) => asistencia.text === dia.tipo_jornada
                );
              }
              if (!asistenciaEncontrada) {
                let asistenciaEditada = {
                  text:
                    dia.tipo_jornada === 'Descanso' ||
                    dia.tipo_jornada === 'Asistencia' ||
                    dia.tipo_jornada === 'A' ||
                    dia.tipo_jornada === 'Tardanza'
                      ? 'Días pagados'
                      : dia.tipo_jornada === 'F' || dia.tipo_jornada === 'Falto'
                      ? 'Falto'
                      : dia.tipo_jornada === 'DM' ||
                        dia.tipo_jornada === 'Descanso médico'
                      ? 'Descanso médico'
                      : dia.tipo_jornada,
                  value:
                    dia.tipo_jornada === 'Descanso' ||
                    dia.tipo_jornada === 'Asistencia' ||
                    dia.tipo_jornada === 'A' ||
                    dia.tipo_jornada === 'Tardanza'
                      ? 'cantidad_dias_efectivos'
                      : dia.tipo_jornada === 'F' || dia.tipo_jornada === 'Falto'
                      ? 'F'
                      : dia.tipo_jornada === 'DM' ||
                        dia.tipo_jornada === 'Descanso médico'
                      ? 'DM'
                      : dia.tipo_jornada,
                  width:
                    dia.tipo_jornada.includes('Permiso') ||
                    dia.tipo_jornada.includes('Licencia')
                      ? '140px'
                      : '80px',
                };
                this.tiposDeAsistencia.push(asistenciaEditada);
              }
              return dia;
            });
            // si el mes es un mes de 31 días, de 29 días o de 29 días, se va a mostrar los días pagados en base a 30
            switch (cantidadDiasPeriodoPlanilla) {
              case 31:
                persona.cantidad_dias_efectivos =
                  persona.cantidad_dias_efectivos - 1;
                break;
              case 29:
                persona.cantidad_dias_efectivos =
                  persona.cantidad_dias_efectivos + 1;
                break;
              case 28:
                persona.cantidad_dias_efectivos =
                  persona.cantidad_dias_efectivos + 2;
                break;

              default:
                break;
            }
          }
          //procesar encabezados para el proceso de gratificación
          if (this.tipoDePlanilla === 'Gratificación') {
            persona['meses_laborados'] =
              persona.cantidadDePlanillasDeRemuneracion;
            persona['remuneracion_basica'] = persona.remuneracion_mensual;
            persona['parametro_asignacion_familiar'] =
              persona.asignacion_familiar === 'SI'
                ? this.parametroAsignacionFamiliar.monto
                : 0;
            persona['promedio_conceptos_afectos'] =
              persona.montoIngresoAfecto > 0 ? persona.montoIngresoAfecto : 0;
            persona['remuneracion_base_grati'] =
              persona['remuneracion_basica'] +
              persona['parametro_asignacion_familiar'] +
              persona['promedio_conceptos_afectos'];
            // persona['grati_trunca'] =
            //   persona.montoIngresoAfecto > 0  && persona.montoIngresoAfecto !== '' ? persona.montoIngresoAfecto : 0;
          }
          //procesar encabezados para mostrar la boleta mensual
          // if (this.tipoDePlanilla === 'Boleta mensual') {
          //   persona['selected'] = false; // se agrega esta propiedad para agregar el checkbox a cada persona para la boleta mensual
          //   persona['meses_laborados'] =
          //     persona.cantidadDePlanillasDeRemuneracion;
          //   persona['remuneracion_basica'] = persona.remuneracion_mensual;
          //   persona['parametro_asignacion_familiar'] =
          //     persona.asignacion_familiar === 'SI'
          //       ? this.parametroAsignacionFamiliar.monto
          //       : 0;
          //   persona['promedio_conceptos_afectos'] =
          //     persona.montoIngresoAfecto > 0 ? persona.montoIngresoAfecto : 0;
          //   persona['remuneracion_base_grati'] =
          //     persona['remuneracion_basica'] +
          //     persona['parametro_asignacion_familiar'] +
          //     persona['promedio_conceptos_afectos'];

          //     console.log(cantidadDiasPeriodoPlanilla);
          //   switch (cantidadDiasPeriodoPlanilla) {
          //     case 31:
          //       persona.cantidad_dias_efectivos =
          //         persona.cantidad_dias_efectivos - 1;
          //       break;
          //     case 29:
          //       persona.cantidad_dias_efectivos =
          //         persona.cantidad_dias_efectivos + 1;
          //       break;
          //     case 28:
          //       persona.cantidad_dias_efectivos =
          //         persona.cantidad_dias_efectivos + 2;
          //       break;

          //     default:
          //       break;
          //   }
          // }
          //procesar encabezados para el proceso de CTS
          if (this.tipoDePlanilla === 'CTS') {
            persona['total_dias'] = persona.cantidad_dias_efectivos_total;
            persona['remuneracion_basica'] = persona.remuneracion_mensual;
            persona['parametro_asignacion_familiar'] =
              persona.asignacion_familiar === 'SI'
                ? this.parametroAsignacionFamiliar.monto
                : 0;
            persona['promedio_conceptos_afectos'] =
              persona.montoIngresoAfecto > 0 ? persona.montoIngresoAfecto : 0;
            persona['sexto_remuneracion'] =
              persona.sextoRemuneracion > 0
                ? Number(persona.sextoRemuneracion).toFixed(2)
                : 0;

            persona['remuneracion_base_cts'] =
              persona.remuneracion_mensual +
              persona['parametro_asignacion_familiar'] +
              (persona['promedio_conceptos_afectos'] === ''
                ? 0
                : Number(persona['promedio_conceptos_afectos'])) +
              (persona['sexto_remuneracion'] === ''
                ? 0
                : Number(persona['sexto_remuneracion']));
            persona['remuneracion_base_cts'] = Number(
              persona['remuneracion_base_cts']
            ).toFixed(2);

            // persona['grati_trunca'] =
            //   persona.montoIngresoAfecto > 0  && persona.montoIngresoAfecto !== '' ? persona.montoIngresoAfecto : 0;
          }
          //procesar encabezados para el proceso de Liquidación
          if (this.tipoDePlanilla === 'Liquidación') {
            //calcular el tiempo de servicio del trabajador en días
            const fechaInicio = moment(persona.fecha_ingreso).tz(
              'America/Lima'
            );
            const fechaFin = moment(persona.fecha_cese).tz('America/Lima');
            const tiempoServicioEnDias = fechaFin.diff(fechaInicio, 'days') + 1;
            persona['tiempo_servicio_dias'] = tiempoServicioEnDias;
            persona['remuneracion_basica'] = persona.remuneracion_mensual;

            persona['parametro_asignacion_familiar'] =
              persona.asignacion_familiar === 'SI'
                ? this.parametroAsignacionFamiliar.monto
                : 0;

            persona['promedio_conceptos_afectos_vacaciones_truncas'] =
              persona.montoPomedioIngresoAfectoVacacionesTruncas;

            persona['remuneracion_base_afectos_vacaciones_truncas'] =
              persona['remuneracion_basica'] +
              persona['parametro_asignacion_familiar'] +
              persona['promedio_conceptos_afectos_vacaciones_truncas'];

            persona['promedio_conceptos_afectos_cts_truncas'] =
              persona['promedio_conceptos_afectos_vacaciones_truncas'];

            persona['sexto_gratificacion'] =
              persona.sextoRemuneracion > 0
                ? Number(persona.sextoRemuneracion).toFixed(2)
                : '';
            persona['remuneracion_base_afectos_cts_truncas'] =
              persona['remuneracion_basica'] +
              persona['parametro_asignacion_familiar'] +
              persona['promedio_conceptos_afectos_cts_truncas'] +
              Number(persona['sexto_gratificacion']);

            persona['promedio_conceptos_afectos_gratificacion_truncas'] =
              persona['promedio_conceptos_afectos_vacaciones_truncas'];

            persona['remuneracion_base_gratificacion'] =
              persona['remuneracion_basica'] +
              persona['parametro_asignacion_familiar'] +
              persona['promedio_conceptos_afectos_gratificacion_truncas'];

            persona['monto_vacaciones_indemnizables'] = Number(
              (persona['remuneracion_base_afectos_vacaciones_truncas'] / 30) *
                persona.dias_vacaciones_indemnizables *
                2
            ).toFixed(2);
            let vacacionesTruncas = persona.ingresos_por_concepto.filter(
              (ing) => ing.codigo === '0114'
            );
            let montoPorVacacionesTruncas = 0;
            vacacionesTruncas.map((vacaciones) => {
              montoPorVacacionesTruncas =
                montoPorVacacionesTruncas + vacaciones.monto;
              return vacaciones;
            });
            persona['monto_vacaciones_truncas'] = montoPorVacacionesTruncas;
            persona['monto_cts_trunca'] = persona.montoAPagarPorCTS
              ? Number(persona.montoAPagarPorCTS).toFixed(2)
              : '';
            persona[
              'monto_gratificacion_trunca'
            ] = persona.montoAPagarPorGratificacionTrunca
              ? Number(persona.montoAPagarPorGratificacionTrunca).toFixed(2)
              : '';
            persona[
              'monto_bonificacion_extraordinaria'
            ] = persona.montoGratificacionBonificacionExtraordinaria
              ? Number(
                  persona.montoGratificacionBonificacionExtraordinaria
                ).toFixed(2)
              : '';
          }

          //agregar el monto de cada concepto para q haga match con el value del header
          //procesar ingresos
          persona.ingresos_por_concepto.map((concepto) => {
            persona[`${concepto.codigo}`] =
              Math.round(concepto.monto * 100) / 100;
            let ingresoEncontrado = this.tiposDeIngresos.find(
              (ingreso) => ingreso.codigo === concepto.codigo
            );
            if (!ingresoEncontrado) {
              let ingresoEditado = {
                codigo: concepto.codigo,
                text: concepto.codigo,
                value: concepto.codigo,
              };
              this.tiposDeIngresos.push(ingresoEditado);
            }
            return concepto;
          });

          //procesar descuentos
          persona.descuentos_por_concepto.map((concepto) => {
            persona[`${concepto.codigo}`] =
              Math.round(concepto.monto * 100) / 100;
            let descuentoEncontrado = this.tiposDeDescuentos.find(
              (descuento) => descuento.codigo === concepto.codigo
            );
            if (!descuentoEncontrado) {
              let descuentoEditado = {
                codigo: concepto.codigo,
                text: concepto.codigo,
                value: concepto.codigo,
              };
              this.tiposDeDescuentos.push(descuentoEditado);
            }
            return concepto;
          });

          //procesar aportes
          persona.aportes_empleador_por_concepto.map((concepto) => {
            persona[`${concepto.codigo}`] =
              Math.round(concepto.monto * 100) / 100;
            let aporteEncontrado = this.tiposDeAportes.find(
              (aporte) => aporte.codigo === concepto.codigo
            );
            if (!aporteEncontrado) {
              let aporteEditado = {
                codigo: concepto.codigo,
                text: concepto.codigo,
                value: concepto.codigo,
              };
              this.tiposDeAportes.push(aporteEditado);
            }
            return concepto;
          });

          return persona;
        });
      }
      // this.periodoSeleccionadoAnio = '';
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/main.scss';
.boton-txt {
  color: $stage-azul-oscuro-base !important;
}
.fuente-celdas {
  font-size: 50px !important;
}
.top-bar-title {
  font-size: 25px !important;
  font-weight: bold !important;
  color: teal !important;
}
.v-data-table::v-deep th {
  border: 1px solid #0f501d3d;
  text-align: left;
  padding: 8px;
  font-size: 12px;
  overflow: auto;
  font-size: 15px !important;
  font-weight: bold !important;
  color: teal !important;
  .v-icon {
    display: none !important;
  }
}
.selector-periodo-container {
  height: 35px !important;
}
</style>
