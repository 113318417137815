import moment from 'moment-timezone';

import { obtenerAsistenciaPorRangoDeFechas } from '@/api/persons/obtenerAsistenciaPorRangoDeFechas.js';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';
import { setAssistance } from '@/api/persons/newAssistance.js';

const verificarExistenciaAsistenciasMes = async (fecha) => {
  // const verificarExistenciaAsistenciasMes = async (fecha, app) => {
  // const numeroDeDia = Number(
  //   moment(fecha)
  //     .tz('America/Lima')
  //     .format('D')
  // );
  const mesFecha = Number(
    moment(fecha)
      .tz('America/Lima')
      .format('M')
  );
  const anioFecha = Number(
    moment(fecha)
      .tz('America/Lima')
      .format('YYYY')
  );
  // const numeroDeDiaHoy = Number(
  //   moment()
  //     .tz('America/Lima')
  //     .format('D')
  // );
  const mesActual = Number(
    moment()
      .tz('America/Lima')
      .format('M')
  );
  const anioActual = Number(
    moment()
      .tz('America/Lima')
      .format('YYYY')
  );

  // console.log(mesActual, anioActual);
  // console.log(mesFecha, anioFecha);
  let hoy = moment().tz('America/Lima');
  let fecha_inicio = moment()
    .tz('America/Lima')
    .startOf('month');

  if (
    (mesFecha !== mesActual &&
      anioFecha === anioActual &&
      moment(fecha).tz('America/Lima') < moment().tz('America/Lima')) ||
    (anioFecha !== anioActual &&
      moment(fecha).tz('America/Lima') < moment().tz('America/Lima'))
  ) {
    // console.log('usar el endOfMonth y mostrar alerta', app);
    hoy = moment(fecha)
      .tz('America/Lima')
      .endOf('month');
    fecha_inicio = moment(fecha)
      .tz('America/Lima')
      .startOf('month');
    // app.$swal
    //   .fire({
    //     title: `Atención! No existen registros!`,
    //     text:
    //       'Generar los plantillas de registro de asistencia de todo el mes?',
    //     icon: 'warning',
    //     showCancelButton: true,
    //     cancelButtonColor: '#d33',
    //     cancelButtonText: 'No',
    //     confirmButtonColor: '#3085d6',
    //     confirmButtonText: 'Sí',
    //   })
    //   .then(async (result) => {
    //     if (result.isConfirmed) {
    //       hoy = moment(fecha)
    //         .tz('America/Lima')
    //         .endOf('month');
    //       fecha_inicio = moment(fecha)
    //         .tz('America/Lima')
    //         .startOf('month');
    //     }
    //   });
  }

  // console.log(hoy.diff(fecha_inicio, 'days'));
  const cantidadDiasInicioDeMesAHoy = hoy.diff(fecha_inicio, 'days') + 1;

  const {
    error,
    tokenExpired,
    asistencias,
  } = await obtenerAsistenciaPorRangoDeFechas(
    fecha_inicio.format(),
    hoy.format()
  );
  if (error) {
    //do something
  }
  if (tokenExpired) {
    routeToLoginIfTokenIsExpired(this);
  }
  if (asistencias) {
    if (asistencias.length !== cantidadDiasInicioDeMesAHoy) {
      for (let dia = 1; dia <= cantidadDiasInicioDeMesAHoy; dia++) {
        let found = '';
        let fechaAsistencia = fecha_inicio;
        found = asistencias.find(
          (asistencia) =>
            Number(moment(asistencia.fecha_asistencia).format('D')) === dia
        );
        if (!found) {
          const { error, tokenExpired } = await setAssistance(
            fechaAsistencia.tz('America/Lima'),
            `Asistencia del día: ${fechaAsistencia.locale('es').format('L')}`
          );
          if (error) {
            //do something
          }
          if (tokenExpired) {
            routeToLoginIfTokenIsExpired(this);
          }
        }
        fechaAsistencia = fecha_inicio.add(1, 'days');
      }
    }
  }
};

export default verificarExistenciaAsistenciasMes;
