<template>
  <v-dialog
    v-model="editarSancionesDialog"
    max-width="60vw"
    max-height="50vh"
    persistent
  >
    <v-card>
      <v-card-title
        class="text-h5 justify-center"
        style="color: teal; font-weight: bold"
      >
        {{ !nuevaSancion ? `Editando sanciones de:` : 'Agregar sanción para:' }}
      </v-card-title>
      <v-card-subtitle style="color: teal;" class="text-h5">
        {{ `${persona.nombre}` }}
      </v-card-subtitle>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col>
              <v-autocomplete
                label="Tipo de sanción"
                v-model="sancionAEditar.tipo_sancion"
                :items="tiposDeSancion"
                :rules="nameRules"
                :readonly="!nuevaSancion"
              />
            </v-col>

            <v-col>
              <v-autocomplete
                label="Gravedad de la falta"
                v-model="sancionAEditar.gravedad_falta"
                :items="gravedadDeFaltas"
                :rules="nameRules"
                v-if="sancionAEditar.tipo_sancion === 'Suspensión'"
                :readonly="!nuevaSancion"
              />
            </v-col>
          </v-row>
          <v-row class="d-flex flex-column">
            <v-textarea
              auto-grow
              outlined
              rows="3"
              row-height="25"
              shaped
              v-model="sancionAEditar.texto_sancion.parrafo1"
              :readonly="!nuevaSancion"
            />
            <v-textarea
              auto-grow
              label="Hechos que generaron la presente sanción"
              outlined
              rows="3"
              row-height="25"
              background-color="teal lighten-5"
              v-model="sancionAEditar.hechos"
              :readonly="!nuevaSancion"
            />
            <v-textarea
              auto-grow
              outlined
              rows="3"
              row-height="25"
              shaped
              v-model="sancionAEditar.texto_sancion.parrafo2"
              :readonly="!nuevaSancion"
            />
            <v-row>
              <v-col>
                <v-text-field
                  label="Fecha de inicio"
                  v-model="sancionAEditar.fecha_inicio"
                  type="date"
                  :rules="dateRules"
                  v-if="sancionAEditar.tipo_sancion === 'Suspensión'"
                  :readonly="!nuevaSancion"
                />
              </v-col>
              <v-col>
                <v-text-field
                  label="Fecha de fin"
                  v-model="sancionAEditar.fecha_fin"
                  type="date"
                  :rules="dateRules"
                  v-if="sancionAEditar.tipo_sancion === 'Suspensión'"
                  :readonly="!nuevaSancion"
                />
              </v-col>
            </v-row>
            <v-textarea
              auto-grow
              outlined
              rows="3"
              row-height="25"
              shaped
              v-model="sancionAEditar.texto_sancion.parrafo3"
              :readonly="!nuevaSancion"
            />
          </v-row>
        </v-form>
        <span
          style="color:red"
          v-if="sancionAEditar.tipo_sancion !== '' && nuevaSancion"
          >* en caso de querer editar esta plantilla permanentemente, deberá
          hacerlo desde el módulo de configuración</span
        >
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="blue darken-1"
          text
          @click="closeDialog"
          :disabled="deshabilitarBotonGuardar"
          >Cancelar</v-btn
        >
        <v-btn
          color="success"
          text
          @click="validate"
          :disabled="deshabilitarBotonGuardar || !nuevaSancion"
          >Guardar</v-btn
        >
        <v-btn
          color="red"
          text
          @click="showDeleteAlert"
          v-show="!nuevaSancion"
          :disabled="deshabilitarBotonGuardar"
          >Eliminar</v-btn
        >
        <v-btn
          color="red darken-4"
          text
          @click="showDownloadPDFAlert"
          v-show="!nuevaSancion"
          :disabled="deshabilitarBotonGuardar"
          >Descargar PDF</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from 'vuex';
import { toPdf } from '@/plugins/exportToPdf.js';
import { getClientData } from '@/api/configuration/getClientData.js';
import { editUserInfo } from '@/api/persons/editUserInfo.js';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';
import { actualizarAsistencia } from '@/api/persons/actualizarAsistencia.js';
import { obtenerAsistenciaPorRangoDeFechas } from '@/api/persons/obtenerAsistenciaPorRangoDeFechas.js';
import { editarAsistencia } from '@/api/persons/editarAsistencia.js';
import moment from 'moment-timezone';
moment.locale('es-us');

export default {
  name: 'EditarSanciones',
  sancionOriginal: {},
  plantillaTextoAmonestacion: {},
  plantillaTextoSuspension: {},
  data: () => ({
    deshabilitarBotonGuardar: false,
    valid: true,
    tiposDeSancion: ['Amonestación', 'Suspensión'],
    gravedadDeFaltas: ['Leve', 'Grave'],
    sancionAEditar: {
      hechos: '',
      tipo_sancion: '',
      gravedad_falta: '',
      fecha_inicio: '',
      fecha_fin: '',
      texto_sancion: {
        parrafo1: '',
        parrafo2: '',
        parrafo3: '',
      },
    },
    nameRules: [
      (v) => !!v || 'Campo requerido',
      (v) => (v && v.length >= 3) || 'Mínimo 3 caracteres',
    ],
    dateRules: [(v) => !!v || 'Campo requerido'],
  }),
  props: {
    sancion: Object,
    persona: Object,
    nuevaSancion: Boolean,
  },
  computed: {
    ...mapState(['editarSancionesDialog']),
  },
  mounted() {
    this.obtenerPlantillasDeTextos();
  },
  watch: {
    sancion() {
      this.sancionAEditar = this.sancion;
      this.$options.sancionOriginal = { ...this.sancion };

      this.$nextTick(function() {
        this.$refs.form.resetValidation();
      });
    },
    'sancionAEditar.tipo_sancion'() {
      if (this.nuevaSancion) {
        let tipoSancion = this.sancionAEditar.tipo_sancion;
        switch (tipoSancion) {
          case 'Amonestación':
            this.sancionAEditar.texto_sancion = this.plantillaTextoAmonestacion;
            break;
          case 'Suspensión':
            this.sancionAEditar.texto_sancion = this.plantillaTextoSuspension;
            break;

          default:
            break;
        }
      }
    },
  },
  methods: {
    descargarPDF() {
      const template = `
      ${this.sancionAEditar.tipo_sancion}\n
    Sr./Sra. ${this.persona.nombre}:\n
          \n
          ${this.sancionAEditar.texto_sancion.parrafo1}\n
          ${this.sancionAEditar.hechos}\n
          ${this.sancionAEditar.texto_sancion.parrafo2}\n
          ${
            this.sancionAEditar.tipo_sancion == 'Suspensión'
              ? `Del ${moment(this.sancionAEditar.fecha_inicio).format(
                  'LL'
                )} al ${moment(this.sancionAEditar.fecha_fin).format('LL')}`
              : ''
          }\n
          ${this.sancionAEditar.texto_sancion.parrafo3}\n
          \n
          \n
          \n
          Atentamente,\n
          Gestión  de personal\n
          \n
          \n
          \n
            Firma trabajador                                     Firma empleador\n
	               `;
      toPdf(template, `Sanción_${this.persona.nombre}`);
    },
    showDownloadPDFAlert() {
      this.$swal
        .fire({
          title: '¿Descargar archivo PDF?',
          text: '',
          icon: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: 'No',
          confirmButtonText: 'Sí',
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.descargarPDF();
          }
        });
    },
    showDeleteAlert() {
      this.$swal
        .fire({
          title: `Atención!`,
          text: 'Está seguro que desea eliminar esta sanción?',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonColor: '#d33',
          cancelButtonText: 'No',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Sí',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.deshabilitarBotonGuardar = true;
            const { fecha_inicio, fecha_fin } = this.$options.sancionOriginal;

            this.persona.sanciones = this.persona.sanciones.filter(
              (sancion) =>
                JSON.stringify(sancion) !==
                JSON.stringify(this.$options.sancionOriginal)
            );

            const { error, message, tokenExpired } = await editUserInfo(
              this.persona
            );

            if (error) {
              //do something
            }
            if (tokenExpired) {
              routeToLoginIfTokenIsExpired(this);
            }
            if (message) {
              if (this.$options.sancionOriginal.tipo_sancion === 'Suspensión') {
                await this.actualizarAsistencias(
                  fecha_inicio,
                  fecha_fin,
                  'Falto'
                );
                this.showSuccessAlert();
                this.closeDialog();
                return;
              }
              this.showSuccessAlert();
              this.closeDialog();
            }
          }
        });
    },
    showSuccessAlert() {
      this.$swal.fire({
        title: `Operación realizada correctamente!`,
        text: '',
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    showDateErrorAlert() {
      this.$swal.fire({
        title: `Error`,
        text:
          'La fecha de fin no puede ser menor a la fecha de inicio de la suspensión!',
        icon: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    async obtenerPlantillasDeTextos() {
      const { error, client, tokenExpired } = await getClientData();
      if (error) {
        //do something
      }
      if (client) {
        client.datos_empresa.plantillas_texto_sanciones.map((texto) => {
          if (texto.tipo === 'amonestacion') {
            this.plantillaTextoAmonestacion = texto;
          }
          if (texto.tipo === 'suspension') {
            this.plantillaTextoSuspension = texto;
          }
          return texto;
        });
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
    },
    async closeDialog() {
      this.$options.sancionOriginal = {};
      this.deshabilitarBotonGuardar = false;
      this.$store.state.editarSancionesDialog = false;
    },
    async validate() {
      if (this.$refs.form.validate()) {
        this.deshabilitarBotonGuardar = true;
        const { fecha_inicio, fecha_fin } = this.sancionAEditar;
        const {
          fecha_inicio: fecha_inicioOriginal,
          fecha_fin: fecha_finOriginal,
        } = this.$options.sancionOriginal;
        if (fecha_fin < fecha_inicio) {
          this.showDateErrorAlert();
          return;
        }

        if (this.nuevaSancion) {
          this.persona.sanciones.push(this.sancionAEditar);
        } else {
          this.persona.sanciones.map((sancion) => {
            if (
              JSON.stringify(sancion) ===
              JSON.stringify(this.$options.sancionOriginal)
            ) {
              sancion = this.sancionAEditar;
            }
            return sancion;
          });
        }

        const { error, message, tokenExpired } = await editUserInfo(
          this.persona
        );
        if (message) {
          if (
            fecha_inicio !== fecha_inicioOriginal ||
            fecha_fin !== fecha_finOriginal
          ) {
            if (
              this.nuevaSancion &&
              this.sancionAEditar.tipo_sancion === 'Suspensión'
            ) {
              await this.actualizarAsistencias(fecha_inicio, fecha_fin, 'S');
              this.showSuccessAlert();
              this.closeDialog();
              return;
            }

            if (
              this.nuevaSancion &&
              this.sancionAEditar.tipo_sancion === 'Amonestación'
            ) {
              return;
            }

            await this.actualizarAsistencias(
              fecha_inicioOriginal,
              fecha_finOriginal,
              'Falto'
            );
            await this.actualizarAsistencias(fecha_inicio, fecha_fin, 'S');
            this.showSuccessAlert();
            this.closeDialog();
          } else {
            await this.actualizarAsistencias(fecha_inicio, fecha_fin, 'S');
            this.showSuccessAlert();
            this.closeDialog();
          }
        }
        if (error) {
          // do something
        }
        if (tokenExpired) {
          routeToLoginIfTokenIsExpired(this);
        }
      }
    },
    async actualizarAsistencias(fecha_inicio, fecha_fin, tipo_jornada) {
      const {
        error,
        tokenExpired,
        asistencias,
      } = await obtenerAsistenciaPorRangoDeFechas(
        moment(fecha_inicio).tz('America/Lima'),
        moment(fecha_fin).tz('America/Lima')
      );
      // } = await obtenerAsistenciaPorRangoDeFechas(fecha_inicio, fecha_fin);
      if (error) {
        //do something
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (asistencias) {
        for (let asistencia in asistencias) {
          let personaFound = asistencias[asistencia].personal.find(
            (person) => person.doi.toString() === this.persona.doi.toString()
          );
          if (!personaFound) {
            this.persona.disponible = true;
            if (tipo_jornada !== 'Falto') {
              this.persona.disponible = false;
            }
            this.persona.tipo_jornada = tipo_jornada;
            asistencias[asistencia].personal.push(this.persona);
            const {
              error: errorEditandoAsistencia,
              message: asistenciaEditada,
            } = await editarAsistencia(asistencias[asistencia]);
            if (errorEditandoAsistencia) {
              //do something
            }
            if (asistenciaEditada) {
              //do something
            }
          } else {
            let registroDeAsistenciaActualizado = {
              user_id: this.persona._id,
              doi: this.persona.doi,
              fecha_asistencia: asistencias[asistencia].fecha_asistencia,
              tipo_jornada,
              hora_programada: '',
              hora_inicio_jornada: '',
              hora_fin_jornada: '',
              hora_inicio_almuerzo: '',
              hora_fin_almuerzo: '',
            };
            const {
              error: errorEditandoAsistencia,
            } = await actualizarAsistencia(registroDeAsistenciaActualizado);
            if (errorEditandoAsistencia) {
              //do something
            }
          }
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.informe {
  width: 560px;
  height: 430px;
  margin: 20px auto;
  text-align: justify;
  #content {
    padding: 5px 10px;
    height: 420px;
    overflow-x: hidden !important;
    overflow: scroll;
  }
  #content::-webkit-scrollbar {
    width: 4px;
    background: #efeff6;
  }

  #content::-webkit-scrollbar-thumb {
    background: #44524257;
    border-radius: 10px;
  }
  .d-flex {
    align-items: baseline !important;
  }
}
</style>
