import fetch from 'isomorphic-fetch';

const ENDPOINT_URL = process.env.VUE_APP_EDITAR_RUTA;
async function editPuntoOrigen(fecha_programacion, codigo_ruta, punto_origen) {
  const bodyParams = {
    fecha_programacion,
    codigo_ruta,
    punto_origen,
    token: localStorage.getItem('token'),
  };

  let body = JSON.stringify(bodyParams);

  return await fetch(ENDPOINT_URL, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  }).then((item) => item.json());
}

async function editRoute(fecha_programacion, ruta) {
  const bodyParams = {
    fecha_programacion,
    codigo_ruta: ruta.codigo_ruta,
    hora_programada: ruta.hora_programada,
    ruta_optimizada: ruta.ruta_optimizada,
    servicios: ruta.servicios,
    token: localStorage.getItem('token'),
  };

  let body = JSON.stringify(bodyParams);

  return await fetch(ENDPOINT_URL, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  }).then((item) => item.json());
}
export { editPuntoOrigen, editRoute };
