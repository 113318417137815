<template>
  <v-dialog
    v-model="editarLicenciaDialog"
    max-width="60vw"
    max-height="50vh"
    persistent
  >
    <v-card>
      <v-card-title
        class="text-h5 justify-center"
        style="color: teal; font-weight: bold"
      >
        {{
          !nuevoPermiso
            ? `Editando licencia/permiso de:`
            : 'Agregar licencia/permiso para:'
        }}
      </v-card-title>
      <v-card-subtitle style="color: teal;" class="text-h5">
        {{ `${persona.nombre}` }}
      </v-card-subtitle>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col>
              <v-text-field
                label="Fecha de inicio"
                v-model="permisoAEditar.fecha_inicio"
                type="date"
                :rules="dateRules"
              />
              <v-text-field
                label="Fecha de fin"
                v-model="permisoAEditar.fecha_fin"
                type="date"
                :rules="dateRules"
              />
            </v-col>

            <v-col>
              <v-autocomplete
                label="Tipo de licencia/permiso"
                v-model="permisoAEditar.tipo_permiso"
                :items="tiposDePermiso"
                item-text="nombre"
                :rules="nameRules"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-textarea
              outlined
              label="Observaciones"
              v-model="permisoAEditar.observaciones"
            />
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="blue darken-1"
          text
          @click="closeDialog"
          :disabled="deshabilitarBotonGuardar"
          >Cancelar</v-btn
        >
        <v-btn
          color="success"
          text
          @click="validate"
          :disabled="deshabilitarBotonGuardar"
          >Guardar</v-btn
        >
        <v-btn
          color="red"
          text
          @click="eliminarLicencia"
          v-show="!nuevoPermiso"
          :disabled="deshabilitarBotonGuardar"
          >Eliminar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';

import { actualizarAsistencia } from '@/api/persons/actualizarAsistencia.js';
import { obtenerAsistenciaPorRangoDeFechas } from '@/api/persons/obtenerAsistenciaPorRangoDeFechas.js';
import { editUserInfo } from '@/api/persons/editUserInfo.js';
import { editarAsistencia } from '@/api/persons/editarAsistencia.js';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';

export default {
  name: 'EditarLicencia',
  permisoOriginal: {},
  data() {
    return {
      deshabilitarBotonGuardar: false,
      permisoAEditar: {
        tipo_permiso: '',
        fecha_inicio: '',
        fecha_fin: '',
        goce_haber: '',
        observaciones: '',
      },
      valid: true,
      tiposDePermiso: [
        { nombre: 'Licencia por fallecimiento' },
        { nombre: 'Licencia por maternidad' },
        { nombre: 'Licencia por paternidad' },
        { nombre: 'Licencia con goce de haber' },
        { nombre: 'Permiso sin goce de haber' },
      ],
      nameRules: [
        (v) => !!v || 'Campo requerido',
        (v) => (v && v.length >= 3) || 'Mínimo 3 caracteres',
      ],
      dateRules: [(v) => !!v || 'Campo requerido'],
    };
  },
  props: {
    permiso: Object,
    persona: Object,
    nuevoPermiso: Boolean,
  },
  watch: {
    editarLicenciaDialog() {
      this.$nextTick(function() {
        this.$refs.form.resetValidation();
      });
    },
    permiso() {
      this.permisoAEditar = this.permiso;
      this.$options.permisoOriginal = { ...this.permiso };
    },
  },
  computed: {
    ...mapState(['editarLicenciaDialog']),
  },
  methods: {
    closeDialog() {
      this.$options.permisoOriginal = {};
      this.deshabilitarBotonGuardar = false;
      this.$store.state.editarLicenciaDialog = false;
    },
    showSuccessAlert() {
      this.$swal.fire({
        title: `Operación realizada correctamente!`,
        text: '',
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    showErrorAlert() {
      this.$swal.fire({
        title: `No se puede modificar esta licencia o permiso!`,
        text: '',
        icon: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    showDateErrorAlert() {
      this.$swal.fire({
        title: `Error`,
        text: 'La fecha de fin no puede ser menor a la fecha de inicio!',
        icon: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    showDeleteAlert() {
      this.$swal
        .fire({
          title: `Atención!`,
          text: 'Está seguro que desea eliminar esta licencia/permiso?',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonColor: '#d33',
          cancelButtonText: 'No',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.deshabilitarBotonGuardar = true;
            const {
              fecha_inicio,
              fecha_fin,
              tipo_permiso,
            } = this.$options.permisoOriginal;
            this.persona.permisos = this.persona.permisos.filter(
              (permiso) =>
                permiso.fecha_inicio !== fecha_inicio &&
                permiso.fecha_fin !== fecha_fin &&
                permiso.tipo_permiso !== tipo_permiso
            );

            await this.actualizarAsistencias(fecha_inicio, fecha_fin, 'Falto');

            const { error, message, tokenExpired } = await editUserInfo(
              this.persona
            );

            if (error) {
              //do something
            }
            if (tokenExpired) {
              routeToLoginIfTokenIsExpired(this);
            }
            if (message) {
              this.showSuccessAlert();
              this.closeDialog();
            }
          }
        });
    },
    async eliminarLicencia() {
      this.showDeleteAlert();
    },
    async validate() {
      if (this.$refs.form.validate()) {
        this.deshabilitarBotonGuardar = true;
        const { fecha_inicio, fecha_fin } = this.permisoAEditar;
        const {
          fecha_inicio: fecha_inicioOriginal,
          fecha_fin: fecha_finOriginal,
          tipo_permiso,
        } = this.$options.permisoOriginal;
        if (fecha_fin < fecha_inicio) {
          this.showDateErrorAlert();
          return;
        }
        this.permisoAEditar.goce_haber = 'Si';
        if (this.permisoAEditar.tipo_permiso === 'Permiso sin goce de haber')
          this.permisoAEditar.goce_haber = 'No';

        if (this.nuevoPermiso) {
          this.persona.permisos.push(this.permisoAEditar);
        } else {
          this.persona.permisos.map((permiso) => {
            if (
              permiso.fecha_inicio === fecha_inicioOriginal &&
              permiso.fecha_fin === fecha_finOriginal &&
              permiso.tipo_permiso === tipo_permiso
            ) {
              permiso = this.permisoAEditar;
            }
            return permiso;
          });
        }

        const { error: errorActualizando, message } = await editUserInfo(
          this.persona
        );

        if (errorActualizando) {
          //do something
        }
        if (message) {
          const tipoJornada = this.permisoAEditar.tipo_permiso;
          if (
            fecha_inicio !== fecha_inicioOriginal ||
            fecha_fin !== fecha_finOriginal
          ) {
            //si la fecha_inicioOriginal === '' entonces solo actualizar con las fechas del descanso a editar
            if (fecha_inicioOriginal === '') {
              await this.actualizarAsistencias(
                fecha_inicio,
                fecha_fin,
                tipoJornada
              );
              this.showSuccessAlert();
              this.closeDialog();
              return;
            }
            //las dos siguientes funciones se deben complementar (en suma y resta de dias) para solo actualizar lo q se debe
            await this.actualizarAsistencias(
              fecha_inicioOriginal,
              fecha_finOriginal,
              'Falto'
            );
            await this.actualizarAsistencias(
              fecha_inicio,
              fecha_fin,
              tipoJornada
            );
            this.showSuccessAlert();
            this.closeDialog();
          } else {
            await this.actualizarAsistencias(
              fecha_inicio,
              fecha_fin,
              tipoJornada
            );
            this.showSuccessAlert();
            this.closeDialog();
          }
        }
      }
    },
    async actualizarAsistencias(fecha_inicio, fecha_fin, tipo_jornada) {
      const {
        error,
        tokenExpired,
        asistencias,
      } = await obtenerAsistenciaPorRangoDeFechas(
        moment(fecha_inicio).tz('America/Lima'),
        moment(fecha_fin).tz('America/Lima')
      );
      // } = await obtenerAsistenciaPorRangoDeFechas(fecha_inicio, fecha_fin);
      if (error) {
        //do something
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (asistencias) {
        for (let asistencia in asistencias) {
          let personaFound = asistencias[asistencia].personal.find(
            (person) => person.doi.toString() === this.persona.doi.toString()
          );
          if (!personaFound) {
            //modificar como sería la asistencia y luego hacer el push
            this.persona.disponible = true;
            if (tipo_jornada !== 'Falto') {
              this.persona.disponible = false;
            }
            this.persona.tipo_jornada = tipo_jornada;
            asistencias[asistencia].personal.push(this.persona);
            const {
              error: errorEditandoAsistencia,
              message: asistenciaEditada,
            } = await editarAsistencia(asistencias[asistencia]);
            if (errorEditandoAsistencia) {
              //do something
            }
            if (asistenciaEditada) {
              //do something
            }
          } else {
            //   personaFound.disponible = false
            let registroDeAsistenciaActualizado = {
              user_id: this.persona._id,
              doi: this.persona.doi,
              fecha_asistencia: asistencias[asistencia].fecha_asistencia,
              tipo_jornada,
              hora_programada: '',
              hora_inicio_jornada: '',
              hora_fin_jornada: '',
              hora_inicio_almuerzo: '',
              hora_fin_almuerzo: '',
            };
            const {
              error: errorEditandoAsistencia,
            } = await actualizarAsistencia(registroDeAsistenciaActualizado);
            if (errorEditandoAsistencia) {
              //do something
            }
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/main.scss';

.dialog-container {
  overflow: scroll;
}
</style>
