<template>
  <v-card min-height="400px">
    <v-data-table
      id="Descanso-Medico"
      class="elevation-1"
      no-results-text="No se encontraron registros..."
      fixed-header
      height="80vh"
      :headers="headers"
      :items="personal"
      :loading="tableLoading"
      loading-text="Cargando reporte de descansos médicos... Espere..."
      hide-default-footer
      disable-pagination
      no-data-text="No hay personal aún..."
      single-expand
      :expanded.sync="expanded"
      show-expand
      :search="searchPerson"
      item-key="uniqueKey"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-toolbar-title class="top-bar-title"
            >Descansos médicos</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchPerson"
            append-icon="mdi-magnify"
            label="Buscar persona..."
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <!-- More info about {{ item.descansos_medicos }} -->
          <v-container fluid>
            <v-data-table
              id="DescansoMedico-Detalle"
              :headers="descansoMedicoHeaders"
              :items="item.descansos_medicos"
              hide-default-footer
              disable-pagination
              :search="search"
              no-data-text="No hay descansos médicos registrados..."
              no-results-text="No se encontraron registros..."
              @click:row="editarDescansoMedico"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title class="top-bar-title"
                    >Registros de {{ item.nombre }}</v-toolbar-title
                  >
                  <v-spacer></v-spacer>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="agregarDescansoMedico()" v-on="on">
                        <v-icon color="blue">mdi-thermometer-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Agregar descanso médico</span>
                  </v-tooltip>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar descanso médico..."
                    single-line
                    hide-details
                  ></v-text-field>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-container>
        </td>
      </template>
    </v-data-table>

    <EditarDescansoMedico
      :persona="this.persona"
      :descansoMedico="this.descansoMedico"
      :nuevoDescansoMedico="this.nuevoDescansoMedico"
    />
  </v-card>
</template>

<script>
import { mapState } from 'vuex';

import { getPersons } from '@/api/persons/getPersons.js';
import EditarDescansoMedico from './EditarDescansoMedico.vue';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';

export default {
  name: 'DescansoMedico',
  data: () => ({
    nuevoDescansoMedico: true,
    tableLoading: true,
    expanded: [],
    personal: [],
    persona: {},
    descansoMedico: {},
    search: '',
    searchPerson: '',
  }),
  components: {
    EditarDescansoMedico,
  },
  computed: {
    ...mapState(['editarDescansoMedicoDialog']),
    descansoMedicoHeaders() {
      let fixedHeaders = [
        {
          text: 'Nro. CITT',
          value: 'citt',
        },
        {
          text: 'Tipo de descanso',
          value: 'tipo_descanso',
        },
        {
          text: 'Fecha de inicio',
          value: 'fecha_inicio',
        },
        {
          text: 'Fecha de fin',
          value: 'fecha_fin',
        },
        {
          text: 'Centro de salud',
          value: 'centro_salud',
        },
        {
          text: 'Diagnóstico',
          value: 'diagnostico',
        },
        {
          text: 'Observaciones',
          value: 'observaciones',
          width: '300px',
        },
      ];

      //   fixedHeaders.push(...this.diasDelMes);
      return fixedHeaders;
    },
    headers() {
      let fixedHeaders = [
        {
          text: 'Documento',
          value: 'doi',
        },
        {
          text: 'Nombre/Razón social',
          value: 'nombre',
          width: '275px',
        },
        {
          text: 'Cargo',
          value: 'datos_laborales.cargo',
        },
        {
          text: 'Dirección',
          value: 'direccion',
        },
        {
          text: 'Situación laboral',
          value: 'datos_laborales.situacion',
        },
      ];

      //   fixedHeaders.push(...this.diasDelMes);
      return fixedHeaders;
    },
  },
  created() {
    this.getPersonal();
    this.$store.state.tablaActivaGestionPersonal = 'Descanso-Medico';
  },
  watch: {
    expanded() {
      this.$store.state.tablaActivaGestionPersonal = 'Descanso-Medico';
      if (this.expanded.length > 0)
        this.$store.state.tablaActivaGestionPersonal = 'DescansoMedico-Detalle';
    },
    editarDescansoMedicoDialog() {
      if (!this.editarDescansoMedicoDialog) this.getPersonal();
    },
  },
  methods: {
    async getPersonal() {
      const { error, personal, tokenExpired } = await getPersons();
      if (error) {
        console.error('error en el created', error);
      }
      if (personal) {
        this.personal = personal;
        this.personal = this.personal.map((persona) => {
          persona.uniqueKey =
            persona.doi +
            '-descansoMedico-' +
            persona.datos_laborales.fecha_ingreso +
            persona.datos_laborales.situacion;
          persona.nombre = `${persona.datos_laborales.apellido_paterno} ${persona.datos_laborales.apellido_materno}, ${persona.nombre}`;
          return persona;
        });
        this.personal = this.personal.filter(
          (persona) => persona.datos_laborales.situacion === 'Activo'
        );
        this.tableLoading = false;
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
    },
    editarDescansoMedico(descansoMedico) {
      this.nuevoDescansoMedico = false;
      this.persona = this.expanded[0];
      this.descansoMedico = descansoMedico;
      this.$store.state.editarDescansoMedicoDialog = true;
    },
    agregarDescansoMedico() {
      this.nuevoDescansoMedico = true;
      this.persona = this.expanded[0];
      this.descansoMedico = {
        citt: '',
        fecha_inicio: '',
        fecha_fin: '',
        tipo_descanso: '',
        centro_salud: '',
        diagnostico: '',
        observaciones: '',
      };
      this.$store.state.editarDescansoMedicoDialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/main.scss';
.top-bar-title {
  font-size: 25px !important;
  font-weight: bold !important;
  color: teal !important;
}
.v-data-table::v-deep th {
  font-size: 15px !important;
  font-weight: bold !important;
  color: teal !important;
  .v-icon {
    display: none !important;
  }
}
.align-header {
  text-align: center !important;
  background-color: $white;
}
</style>
