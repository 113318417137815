import fetch from 'isomorphic-fetch';

let ENDPOINT_URL = process.env.VUE_APP_CAMBIAR_CREDENCIALES_USUARIO;
async function cambiarCredenciales(persona) {
  const { _id, password, username, roles } = persona;
  const bodyParams = {
    _id,
    newUsername: username,
    newPassword: password,
    roles,
    token: localStorage.getItem('token'),
  };

  let body = JSON.stringify(bodyParams);

  return await fetch(ENDPOINT_URL, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  }).then((item) => item.json());
}
export { cambiarCredenciales };
