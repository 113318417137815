<template>
  <v-container fluid>
    <EditarDireccionClienteDialog :nuevaDireccion="nuevaDireccion" />
    <v-form class="card_data" ref="form" v-model="valid" lazy-validation>
      <v-card-title>Datos de la empresa</v-card-title>
      <v-row>
        <v-col cols="6" class="m-10">
          <v-col>
            <v-text-field label="RUC" v-model="client.ruc" :disabled="true" />
            <v-text-field
              label="Razón Social"
              v-model="client.datos_empresa.razon_social"
            />
            <v-text-field
              label="Nombre Comercial"
              v-model="client.datos_empresa.nombre_comercial"
            />
            <v-text-field
              label="Nombre del representante"
              v-model="client.datos_empresa.nombre_representante"
              :rules="nameRules"
            />

            <v-text-field
              label="Usuario o nickname"
              v-model="client.username"
            />

            <v-autocomplete
              label="Régimen laboral"
              v-model="client.datos_empresa.regimen_laboral"
              :items="tiposRegimenLaboral"
            />

            <!-- <v-autocomplete
              label="Régimen de salud"
              v-model="client.datos_empresa.regimen_salud"
              :items="tiposRegimenSalud"
            /> -->
          </v-col>
        </v-col>
        <v-col cols="6">
          <v-col>
            <v-text-field
              label="Email"
              v-model="client.email"
              :disabled="true"
            />
            <v-text-field
              label="Email remitente para boletas mensuales"
              v-model="client.datos_empresa.email_remitente_boletas"
              :rules="emailRules"
            />
            <v-text-field
              label="Apellidos del representante"
              v-model="client.datos_empresa.apellidos_representante"
              :rules="nameRules"
            />
            <v-text-field
              label="DNI del representante"
              v-model="client.datos_empresa.doi_representante"
              :rules="doiRules"
            />
            <v-text-field
              label="Teléfono"
              v-model="client.datos_empresa.telefono"
              :rules="fieldRules"
            />

            <v-flex>
              <v-text-field
                label="Dirección principal"
                v-model="direccionPrincipal"
                @keypress.enter.prevent
                :rules="direccionRules"
                @click="editarDireccion('DireccionPrincipal')"
              >
                <template slot="append-outer">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        @click="editarDireccion('DireccionPrincipal')"
                        >mdi-map-marker</v-icon
                      >
                    </template>
                    <span>Editar dirección en mapa</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        @click="editarDireccion('Nueva')"
                        color="success"
                        >mdi-map-marker-plus-outline</v-icon
                      >
                    </template>
                    <span>Agregar nueva dirección</span>
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-flex>

            <label for="chipsContainer" v-if="chips.length >= 1"
              >Direcciones secundarias (sedes)</label
            >
            <div class="text-left">
              <v-tooltip top v-for="(item, i) in chips" :key="i">
                <template v-slot:activator="{ on }">
                  <v-chip
                    v-on="on"
                    class="ma-2"
                    color="teal"
                    text-color="white"
                    @click="editarDireccion(item)"
                    return-object
                    >{{ item.nombre_referencial }}
                  </v-chip>
                </template>
                <span>{{ item.text }}</span>
              </v-tooltip>
            </div>
          </v-col>

          <v-btn @click="updateClient">
            Actualizar
          </v-btn>
          <v-btn @click="cambiarClave" :disabled="true">
            Cambiar clave
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <!-- <v-divider /> -->
  </v-container>
</template>
<script>
import { mapState } from 'vuex';
import { getClientData } from '@/api/configuration/getClientData.js';
import { updateClient } from '@/api/configuration/updateClient.js';
// import gmapsInit from '@/plugins/gmaps.js';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';
import EditarDireccionClienteDialog from './EditarDireccionClienteDialog.vue';
import obtenerPlanVigenteActual from '@/utils/obtenerPlanVigenteActual.js';

export default {
  data: () => ({
    nuevaDireccion: false,
    direccionMap: '',
    direccionAEditar: '',
    valid: true,
    chips: [],
    direccion: {
      text: 'Av. Alfonso Ugarte 595, Cercado de Lima 15001, Perú',
      nombre_referencial: 'Oficina principal',
      latlng: {
        lat: -12.046108962080762,
        lng: -77.04275890227068,
      },
    },
    client: {
      ruc: '',
      email: '',
      username: '',
      datos_empresa: {
        razon_social: '',
        doi_representante: '',
        email_remitente_boletas: '',
        apellidos_representante: '',
        nombre_representante: '',
        nombre_comercial: '',
        telefono: '',
        tiposRegimenLaboral: [],
        tiposRegimenSalud: [],
        direccion: [
          {
            index: 0,
            text: '',
            nombre_referencial: '',
          },
        ],
      },
    },
    direccionPrincipal: '',
    latDireccionPrincipal: 0,
    lngDireccionPrincipal: 0,
    direccionesSecundarias: [],
    direccionRules: [
      (v) => !!v || 'Datos requeridos',
      (v) => 1 > v.length <= 250 || 'Los caracteres ingresados son menores',
    ],
    doiRules: [
      (v) => !!v || 'Datos requeridos',
      (v) => v.length < 15 || 'El valor debe tener máximo 30 caracteres', //validación pagoEfectivo
    ],
    emailRules: [
      (v) => !!v || 'Email requerido',
      (v) => /.+@.+/.test(v) || 'Debe ser un email válido',
    ],
    nameRules: [
      (v) => !!v || 'Campo requerido',
      (v) => v.length < 31 || 'El valor debe tener máximo 30 caracteres', //validación pagoEfectivo
    ],
    fieldRules: [(v) => !!v || 'Campo requerido'],
  }),
  created() {
    this.getClientData();
  },
  components: {
    EditarDireccionClienteDialog,
  },
  computed: {
    ...mapState(['editarDireccionClienteDialog']),
    tiposRegimenLaboral() {
      let tiposDeRegimenLaboral = [
        'DECRETO LEGISLATIVO N° 728',
        'MICRO EMPRESA',
        'PEQUEÑA EMPRESA',
      ];
      return tiposDeRegimenLaboral;
    },
    // tiposRegimenSalud() {
    //   let tiposDeRegimenSalud = [
    //     'ESSALUD REGULAR',
    //     'ESSALUD REGULAR Y EPS/SERVICIO PROPIO',
    //     'SIS',
    //   ];
    //   return tiposDeRegimenSalud;
    // },
  },
  watch: {
    editarDireccionClienteDialog() {
      if (!this.editarDireccionClienteDialog) {
        this.getClientData();
      }
    },
  },
  methods: {
    editarDireccion(chipDireccion) {
      this.nuevaDireccion = false;

      if (chipDireccion === 'DireccionPrincipal') {
        this.direccionAEditar = this.client.datos_empresa.direccion[0];
        this.verDireccionEnMapa();
        return;
      }
      if (chipDireccion === 'Nueva') {
        this.nuevaDireccion = true;
        this.direccionAEditar = {
          text: 'Av. Alfonso Ugarte 595, Cercado de Lima 15001, Perú',
          nombre_referencial: '',
          latlng: {
            lat: -12.046108962080762,
            lng: -77.04275890227068,
          },
        };
        this.verDireccionEnMapa();
        return;
      }

      let direccionEncontrada = this.client.datos_empresa.direccion.find(
        (direccion) => direccion.text === chipDireccion.text
      );
      if (direccionEncontrada) {
        this.direccionAEditar = direccionEncontrada;
        this.verDireccionEnMapa();
      }
    },
    async verDireccionEnMapa() {
      this.$store.state.direccionClienteAEditar = this.direccionAEditar;
      this.$store.state.editarDireccionClienteDialog = true;
      // this.overlay = true;
      // setTimeout(() => {
      //   this.loadMap();
      // }, 100);
    },

    showSuccessAlert() {
      this.$swal.fire({
        title: `Operación realizada correctamente!`,
        text: '',
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    async updateClient() {
      if (this.$refs.form.validate()) {
        const { error, tokenExpired, message } = await updateClient(
          this.client
        );
        if (error) {
          //do something
        }
        if (tokenExpired) {
          routeToLoginIfTokenIsExpired(this);
        }
        if (message) {
          this.showSuccessAlert();
          //do something
        }
      }
    },
    async cambiarClave() {
      console.log('cambiar la clave', this.client);
    },
    async getClientData() {
      const { error, tokenExpired, client } = await getClientData();
      if (error) {
        //do somethiung
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (client) {
        this.client = client;
        this.$store.state.cliente = client;
        const planVigenteActual = obtenerPlanVigenteActual(client, this);
        this.$store.state.planVigenteActual = planVigenteActual;
        let direcciones = client.datos_empresa.direccion;
        if (!direcciones || direcciones[0].text.length <= 0) return;
        if (direcciones.length === 1) {
          this.direccionPrincipal = direcciones[0].text;
          return;
        }
        if (direcciones.length >= 2) {
          this.direccionPrincipal = direcciones[0].text;
          this.chips = direcciones.map((item, i) => {
            return {
              index: i,
              text: item.text,
              latlng: item.latlng,
              nombre_referencial: item.nombre_referencial,
            };
          });
          this.chips = this.chips.filter((item) => item.index !== 0);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/main.scss';
.v-card {
  height: 90%;
  overflow: scroll;
  overflow-x: hidden;
  margin: 50px auto;
  padding: 20px;
  .align-form {
    display: flex;
    padding: 20px;
    text-align: start;
    display: flex;
    align-items: center;
  }
}
.p-relative {
  position: relative;
  top: 30px;
  background: black;
}
.flex-end {
  display: flex;
  align-items: flex-end;
}
.v-card::-webkit-scrollbar {
  width: 4px;
  background: #efeff6;
}
.card_data {
  padding: 20px;
}
.v-card::-webkit-scrollbar-thumb {
  background: $main;
  border-radius: 10px;
}
#map {
  height: 350%;
  width: 50vw;
}
.mdi_pointer {
  cursor: pointer;
  position: relative;
  bottom: 7px;
  left: 16px;
}

#floating-panel {
  position: absolute;
  top: 10px;
  left: 25%;
  z-index: 5;
  background-color: #fff;
  padding: 5px;
  border: 1px solid #999;
  text-align: center;
  line-height: 30px;
  padding-left: 10px;
}
.p-30 {
  padding: 30px;
}
.textFieldMap {
  width: 400px;
}
</style>
