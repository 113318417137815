<template>
  <v-dialog
    v-model="editarAsistenciaDialog"
    max-width="60vw"
    max-height="50vh"
    persistent
  >
    <v-card>
      <v-card-title
        class="text-h5 justify-center"
        style="color: teal; font-weight: bold"
      >
        {{ `Editando la asistencia de ${this.obtenerNombre}` }}
      </v-card-title>
      <v-card-subtitle style="color: teal;" class="text-h5">
        {{ `del día ${this.obtenerDiaFormateado}` }}
      </v-card-subtitle>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <!-- si es jornada intermitente -->
          <v-row v-if="this.esAsistenciaIntermitente">
            <!-- columna izquierda -->
            <v-col>
              <v-text-field
                label="Hora programada"
                v-model="registroDeAsistenciaAEditar.hora_programada"
                type="time"
                disabled
              />

              <!-- v-for -->
              <div
                v-for="(marcacion, index) in asistencia.jornadas"
                :key="index + marcacion.hora_inicio_jornada"
              >
                <v-text-field
                  :label="`Hora de inicio de jornada ${index + 1}`"
                  v-model="marcacion.hora_inicio_jornada"
                  type="time"
                >
                  <template slot="append-outer">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          @click="openMap('inicioJornada', marcacion)"
                          >mdi-map-marker</v-icon
                        >
                      </template>
                      <span>Ver lugar de marcación en mapa</span>
                    </v-tooltip>
                  </template>
                </v-text-field>
              </div>
            </v-col>

            <!-- columna derecha -->
            <v-col>
              <v-autocomplete
                label="Tipo de jornada"
                v-model="registroDeAsistenciaAEditar.tipo_jornada"
                :items="tiposDeJornada"
                item-text="nombre"
                :disabled="verificarTipoJornada"
              />

              <div
                v-for="(marcacion, index) in asistencia.jornadas"
                :key="index"
              >
                <v-text-field
                  :label="`Hora de fin de jornada ${index + 1}`"
                  v-model="marcacion.hora_fin_jornada"
                  type="time"
                >
                  <template slot="append-outer">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          v-on="on"
                          @click="openMap('finJornada', marcacion)"
                          >mdi-map-marker</v-icon
                        >
                      </template>
                      <span>Ver lugar de marcación en mapa</span>
                    </v-tooltip>
                  </template>
                </v-text-field>
              </div>
            </v-col>
          </v-row>
          <!-- si no es jornada intermitente -->
          <v-row v-else>
            <v-col>
              <v-text-field
                label="Hora programada"
                v-model="registroDeAsistenciaAEditar.hora_programada"
                type="time"
              />
              <v-text-field
                label="Hora de inicio de jornada"
                v-model="registroDeAsistenciaAEditar.hora_inicio_jornada"
                type="time"
              >
                <template slot="append-outer">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        @click="
                          openMap('inicioJornada', registroDeAsistenciaAEditar)
                        "
                        >mdi-map-marker</v-icon
                      >
                    </template>
                    <span>Ver lugar de marcación en mapa</span>
                  </v-tooltip>
                </template>
              </v-text-field>
              <v-text-field
                label="Hora de inicio de almuerzo"
                v-model="registroDeAsistenciaAEditar.hora_inicio_almuerzo"
                type="time"
              />
            </v-col>

            <v-col>
              <v-autocomplete
                label="Tipo de jornada"
                v-model="registroDeAsistenciaAEditar.tipo_jornada"
                :items="tiposDeJornada"
                item-text="nombre"
                :disabled="verificarTipoJornada"
              />
              <v-text-field
                label="Hora de fin de jornada"
                v-model="registroDeAsistenciaAEditar.hora_fin_jornada"
                type="time"
              >
                <template slot="append-outer">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        @click="
                          openMap('finJornada', registroDeAsistenciaAEditar)
                        "
                        >mdi-map-marker</v-icon
                      >
                    </template>
                    <span>Ver lugar de marcación en mapa</span>
                  </v-tooltip>
                </template>
              </v-text-field>
              <v-text-field
                label="Hora de fin de almuerzo"
                v-model="registroDeAsistenciaAEditar.hora_fin_almuerzo"
                type="time"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn color="blue darken-1" text @click="closeDialog">Cancelar</v-btn>
        <v-btn color="success" text @click="validate">Guardar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import { mapState } from 'vuex';

import { actualizarAsistencia } from '@/api/persons/actualizarAsistencia.js';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';

export default {
  name: 'EditarAsistencia',
  data() {
    return {
      esAsistenciaIntermitente: false,
      horaInicioJornada: '',
      horaFinJornada: '',
      registroDeAsistenciaAEditar: {
        hora_programada: '',
        hora_inicio_jornada: '',
        hora_fin_jornada: '',
        hora_inicio_almuerzo: '',
        hora_fin_almuerzo: '',
        tipo_jornada: '',
      },
      valid: true,
      mapContainer: 0,
      tiposDeJornada: [
        { nombre: 'Asistencia' },
        { nombre: 'Descanso médico', disabled: true },
        { nombre: 'Descanso' },
        { nombre: 'Falto' },
        { nombre: 'Licencia por fallecimiento' },
        { nombre: 'Licencia por maternidad' },
        { nombre: 'Licencia por paternidad' },
        { nombre: 'Licencia con goce de haber' },
        { nombre: 'Permiso sin goce de haber' },
        { nombre: 'Suspensión', disabled: true },
        { nombre: 'Tardanza' },
        { nombre: 'Vacaciones', disabled: true },
      ],
      //   nameRules: [
      //     (v) => !!v || 'Campo requerido',
      //     (v) => v.length >= 3 || 'Mínimo 3 caracteres',
      //   ],
      validacion_asistencia: '',
      verificarTipoJornada: false,
      items: ['Sí', 'No'],
    };
  },
  props: {
    asistencia: Object,
    persona: Object,
  },
  computed: {
    ...mapState(['editarAsistenciaDialog']),
    obtenerNombre() {
      if (this.persona.nombre) return this.persona.nombre.toUpperCase();
      return '';
    },
    obtenerDiaFormateado() {
      let fecha = moment(this.asistencia.fecha_asistencia);

      let mes = fecha.tz('America/Lima').format('MMMM');
      let nombreDia = fecha.tz('America/Lima').format('dddd');
      let numeroDia = fecha.tz('America/Lima').format('D');
      return `${_.capitalize(nombreDia)} ${numeroDia} de ${_.capitalize(mes)}`;
    },
  },
  methods: {
    openMap(marcacion, persona) {
      if (marcacion === 'inicioJornada') {
        if (
          !!persona.latlng_inicio_jornada &&
          !!persona.latlng_inicio_jornada.lat &&
          !!persona.latlng_inicio_jornada.lng &&
          persona.latlng_inicio_jornada.lat !== '' &&
          persona.latlng_inicio_jornada.lng !== ''
        ) {
          let newWindow = window.open(
            `https://www.google.com/maps/place/${persona.latlng_inicio_jornada.lat},${persona.latlng_inicio_jornada.lng}`,
            '_blank'
          );
          newWindow.opener = null;
        } else {
          this.showSinCoordenadasAlert('inicio');
        }
      }
      if (marcacion === 'finJornada') {
        if (
          !!persona.latlng_fin_jornada &&
          !!persona.latlng_fin_jornada.lat &&
          !!persona.latlng_fin_jornada.lng &&
          persona.latlng_fin_jornada.lat !== '' &&
          persona.latlng_fin_jornada.lng !== ''
        ) {
          let newWindow = window.open(
            `https://www.google.com/maps/place/${persona.latlng_fin_jornada.lat},${persona.latlng_fin_jornada.lng}`,
            '_blank'
          );
          newWindow.opener = null;
        } else {
          this.showSinCoordenadasAlert('fin');
        }
      }
    },
    closeDialog() {
      this.$store.state.editarAsistenciaDialog = false;
    },
    showSuccessAlert() {
      this.$swal.fire({
        title: `Operación realizada correctamente!`,
        text: '',
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    showErrorAlert() {
      this.$swal.fire({
        title: `No se puede modificar este tipo de jornada!`,
        text: 'Se debe ingresar al módulo correspondiente desde el menú',
        icon: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    showSinCoordenadasAlert(marcacion) {
      this.$swal.fire({
        title: `Atención!`,
        text: `No se encontraron coordenadas GPS para el ${marcacion} de jornada`,
        icon: 'info',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    async validate() {
      if (this.$refs.form.validate()) {
        //si la asistencia en DM, SUSP o VACA no se podrá editar desde aquí, se debe ir al módulo específico
        if (
          this.registroDeAsistenciaAEditar.tipo_jornada === 'Vacaciones' ||
          this.registroDeAsistenciaAEditar.tipo_jornada === 'Suspensión' ||
          this.registroDeAsistenciaAEditar.tipo_jornada === 'Descanso médico'
        ) {
          this.showErrorAlert();
          return;
        }
        //validación para no guardar un registro de marcación vacío si la jornada es intermitente
        if (
          this.registroDeAsistenciaAEditar.datos_laborales
            .switchSujetoAJornadaIntermitente
        ) {
          if (
            this.asistencia.jornadas[this.asistencia.jornadas.length - 1]
              .hora_inicio_jornada === '' &&
            this.asistencia.jornadas[this.asistencia.jornadas.length - 1]
              .hora_fin_jornada === ''
          ) {
            this.registroDeAsistenciaAEditar.jornadas.pop();
          }
          if (
            this.registroDeAsistenciaAEditar.tipo_jornada !== 'Asistencia' &&
            this.registroDeAsistenciaAEditar.tipo_jornada !== 'Tardanza'
          ) {
            this.asistencia.jornadas = [];
          }
        } else {
          if (
            this.registroDeAsistenciaAEditar.hora_inicio_jornada !==
            this.horaInicioJornada
          ) {
            //se puede colocar una alerta para prevenir eliminar las coordenadas del registro de asistencia desde el móvil
            this.registroDeAsistenciaAEditar.latlng_inicio_jornada = {};
          }
          if (
            this.registroDeAsistenciaAEditar.hora_fin_jornada !==
            this.horaFinJornada
          ) {
            this.registroDeAsistenciaAEditar.latlng_fin_jornada = {};
          }
          if (
            this.registroDeAsistenciaAEditar.tipo_jornada !== 'Asistencia' &&
            this.registroDeAsistenciaAEditar.tipo_jornada !== 'Tardanza'
          ) {
            this.registroDeAsistenciaAEditar.hora_programada = '';
            this.registroDeAsistenciaAEditar.hora_inicio_jornada = '';
            this.registroDeAsistenciaAEditar.hora_fin_jornada = '';
            this.registroDeAsistenciaAEditar.hora_inicio_almuerzo = '';
            this.registroDeAsistenciaAEditar.hora_fin_almuerzo = '';
          }
        }

        this.registroDeAsistenciaAEditar.fecha_asistencia = this.asistencia.fecha_asistencia;

        const { error, tokenExpired, message } = await actualizarAsistencia(
          this.registroDeAsistenciaAEditar
        );
        if (error) {
          //do something
        }
        if (tokenExpired) {
          routeToLoginIfTokenIsExpired(this);
        }
        if (message) {
          this.showSuccessAlert();
          this.closeDialog();
        }
      }
    },
  },
  watch: {
    asistencia() {
      this.esAsistenciaIntermitente = false;
      if (this.asistencia.datos_laborales.switchSujetoAJornadaIntermitente) {
        if (
          !this.asistencia.jornadas ||
          this.asistencia.jornadas.length === 0 ||
          (this.asistencia.jornadas[this.asistencia.jornadas.length - 1]
            .hora_inicio_jornada === '' &&
            this.asistencia.jornadas[this.asistencia.jornadas.length - 1]
              .hora_fin_jornada === '')
        ) {
          this.asistencia.jornadas = [
            {
              hora_inicio_jornada: '',
              latlng_inicio_jornada: {
                lat: '',
                lng: '',
              },
              hora_fin_jornada: '',
              latlng_fin_jornada: {
                lat: '',
                lng: '',
              },
            },
          ];
        }
        if (
          this.asistencia.jornadas[this.asistencia.jornadas.length - 1]
            .hora_inicio_jornada !== '' &&
          this.asistencia.jornadas[this.asistencia.jornadas.length - 1]
            .hora_fin_jornada !== ''
        ) {
          this.asistencia.jornadas.push({
            hora_inicio_jornada: '',
            latlng_inicio_jornada: {
              lat: '',
              lng: '',
            },
            hora_fin_jornada: '',
            latlng_fin_jornada: {
              lat: '',
              lng: '',
            },
          });
        }
        this.esAsistenciaIntermitente = true;
      }
      this.verificarTipoJornada = false;
      this.registroDeAsistenciaAEditar = this.asistencia;
      this.horaInicioJornada = this.asistencia.hora_inicio_jornada;
      this.horaFinJornada = this.asistencia.hora_fin_jornada;
      switch (this.registroDeAsistenciaAEditar.tipo_jornada) {
        case 'A':
          this.registroDeAsistenciaAEditar.tipo_jornada = 'Asistencia';
          break;
        case 'Descanso médico':
        case 'DM':
          this.registroDeAsistenciaAEditar.tipo_jornada = 'Descanso médico';
          this.verificarTipoJornada = true;
          break;
        case 'D':
          this.registroDeAsistenciaAEditar.tipo_jornada = 'Descanso';
          break;
        case 'F':
          this.registroDeAsistenciaAEditar.tipo_jornada = 'Falto';
          break;
        case 'LF':
          this.registroDeAsistenciaAEditar.tipo_jornada =
            'Licencia por fallecimiento';
          break;
        case 'LM':
          this.registroDeAsistenciaAEditar.tipo_jornada =
            'Licencia por maternidad';
          break;
        case 'LP':
          this.registroDeAsistenciaAEditar.tipo_jornada =
            'Licencia por paternidad';
          break;
        case 'PG':
          this.registroDeAsistenciaAEditar.tipo_jornada =
            'Licencia con goce de haber';
          break;
        case 'P':
          this.registroDeAsistenciaAEditar.tipo_jornada =
            'Permiso sin goce de haber';
          break;
        case 'S':
          this.registroDeAsistenciaAEditar.tipo_jornada = 'Suspensión';
          this.verificarTipoJornada = true;
          break;
        case 'T':
          this.registroDeAsistenciaAEditar.tipo_jornada = 'Tardanza';
          break;
        case 'V':
          this.registroDeAsistenciaAEditar.tipo_jornada = 'Vacaciones';
          this.verificarTipoJornada = true;
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/main.scss';

.dialog-container {
  overflow: scroll;
}
</style>
