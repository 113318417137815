import jwt_decode from 'jwt-decode';

const validarAccesoPorRol = (token, rolesNecesarios) => {
  const decoded = jwt_decode(token);
  const { roles, isSuperadmin } = decoded;
  if (isSuperadmin) {
    return isSuperadmin;
  }
  if (!rolesNecesarios) {
    return true;
  }

  return rolesNecesarios.find(
    (rol) => rol === roles.find((rolDelUsario) => rolDelUsario === rol)
  );
};

export default validarAccesoPorRol;
