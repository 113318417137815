import moment from 'moment-timezone';
import { updateClient } from '@/api/configuration/updateClient.js';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';

const obtenerPlanVigenteActual = (cliente, component) => {
  // existe plan vigente (aplicar validacion por fecha (hoy vs ultimo plan contratado))?
  if (
    !cliente.planes_contratados ||
    (cliente.planes_contratados.length === 1 &&
      cliente.planes_contratados[0].tipo_plan === 'CTIEmprendedor')
  ) {
    return {
      tipo_plan: 'CTIEmprendedor',
      plan_vigente: true,
      usuarios_habilitados: 3,
    };
  }

  let planesVigentes = cliente.planes_contratados.filter(
    (plan_contratado) => plan_contratado.plan_vigente === true
  );
  if (!planesVigentes || planesVigentes.length === 0) {
    return {
      tipo_plan: 'CTIEmprendedor',
      plan_vigente: true,
      usuarios_habilitados: 3,
    };
  }

  // validar esta lógica y agregar update a la bd con false cuando no haya plan vigente
  let planVigente = {
    tipo_plan: '',
    plan_vigente: false,
    usuarios_habilitados: 0,
    cantidadDiasHastaVencimiento: -1,
  };
  planesVigentes.map(async (plan) => {
    //se coloca el nombre del plan más alto si existe
    switch (plan.tipo_plan) {
      case 'CTIRoads':
        planVigente.tipo_plan = 'CTIRoads';
        break;
      case 'CTIPlanilla':
        if (planVigente.tipo_plan !== 'CTIRoads') {
          planVigente.tipo_plan = 'CTIPlanilla';
        }
        break;

      default:
        break;
    }
    if (planVigente.usuarios_habilitados < plan.usuarios_habilitados) {
      planVigente.usuarios_habilitados = plan.usuarios_habilitados;
    }

    const fechaVencimientoPlan = moment(plan.fecha_inicio)
      .tz('America/Lima')
      .add(plan.plazo, 'M');
    const cantidadDiasHastaVencimiento = fechaVencimientoPlan.diff(
      moment().tz('America/Lima'),
      'days'
    );

    if (
      planVigente.cantidadDiasHastaVencimiento <= cantidadDiasHastaVencimiento
    ) {
      planVigente.cantidadDiasHastaVencimiento = cantidadDiasHastaVencimiento;
    }

    planVigente.plan_vigente = true;
    if (cantidadDiasHastaVencimiento < 0) {
      plan.plan_vigente = false;
      // console.log(cliente);
      // si cambió el plan vigente a false entonces se actualiza la bd
      const { error, tokenExpired } = await updateClient(cliente);
      if (error) {
        //do something
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(component);
      }
    }

    return plan;
  });

  if (
    planVigente.cantidadDiasHastaVencimiento >= 1 &&
    planVigente.cantidadDiasHastaVencimiento <= 5
  ) {
    let errorMsg = `El plan vigente actual vencerá dentro de ${
      planVigente.cantidadDiasHastaVencimiento
    } ${planVigente.cantidadDiasHastaVencimiento === 1 ? 'día' : 'días'}`;
    mostrarAlerta(errorMsg, component);
  }
  if (planVigente.cantidadDiasHastaVencimiento === 0) {
    let errorMsg = `El plan vigente actual vence hoy, puede ampliar su suscripción desde el módulo de configuración`;
    mostrarAlerta(errorMsg, component);
  }
  if (planVigente.cantidadDiasHastaVencimiento < 0) {
    planVigente.plan_vigente = false;
  }
  return planVigente;
};

const mostrarAlerta = (errorMsg, component) => {
  component.$swal.fire({
    title: 'Atención',
    text: errorMsg,
    icon: 'warning',
    confirmButtonColor: '#3085d6',
    confirmButtonText: 'Ok',
  });
};

export default obtenerPlanVigenteActual;
