<template>
  <v-card class="card_container" :key="renderCard">
    <v-row class="d-flex flex-column justify-center" no-gutters>
      <v-col class="input_search">
        <v-text-field v-model="inputSearch"></v-text-field>
        <v-btn>
          <v-img
            max-width="20px"
            cover
            :src="require('@/assets/menu-icons/search.svg')"
          />
        </v-btn>
      </v-col>
      <!-- <v-chip>Asistencia del día: {{ this.picker }}</v-chip> -->
      <v-row class="drag-container justify-center">
        <drag
          v-for="(persona, i) in personal"
          :key="i"
          :data="persona"
          @cut="remove(persona)"
        >
          <v-card class="card-personal justify-center">
            <v-card-title
              style="display: contents !important"
              class="justify-center"
            >
              {{ persona.datos_laborales.apellido_paterno }}
              {{ persona.datos_laborales.apellido_materno }},
              {{ persona.nombre }}
            </v-card-title>
            <v-spacer></v-spacer>
            <v-card-subtitle style="color: red" class="fuente-branding"
              >{{ persona.datos_laborales.cargo }}
            </v-card-subtitle>
          </v-card>
          <template v-slot:drag-image>
            <v-badge style="transform: translate(10px, 5px)">
              <v-icon large color="primary">mdi-clipboard-account</v-icon>
            </v-badge>
          </template>
        </drag>
      </v-row>
    </v-row>
  </v-card>
</template>
<script>
import { Drag } from 'vue-easy-dnd';
import moment from 'moment';

import { getAssistance } from '@/api/persons/getAssistance.js';
import { setAssistance } from '@/api/persons/newAssistance.js';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';

export default {
  name: 'Personal',
  components: {
    Drag,
  },
  data: () => ({
    renderCard: 0,
    data: [],
    personal: [],
    inputSearch: '',
    picker: localStorage.getItem('Picker'),
  }),
  created() {
    this.getAssistanceXDay();
  },
  watch: {
    inputSearch() {
      this.searchByName();
    },
  },
  methods: {
    showSuccessAlert() {
      this.$swal.fire({
        title: `Operación realizada correctamente!`,
        text: '',
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    async getAssistanceXDay() {
      const { error, asistencia } = await getAssistance(
        moment(this.picker)
          .tz('America/Lima')
          .format()
      );
      if (asistencia) {
        this.personal = asistencia.personal.filter(
          (persona) => persona.disponible
        );
        this.data = this.personal;
        this.renderCard++;
      }
      if (error) {
        // console.log(error);
        this.createAssistanceAlert();
      }
    },
    createAssistanceAlert() {
      this.$swal
        .fire({
          title: 'Atención',
          text:
            'No hay asistencia de personal para este día aún, ¿Deseas crearla?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: 'No',
          confirmButtonText: 'Sí',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const obs = `Asistencia del día: ${moment(this.picker)
              .tz('America/Lima')
              .locale('es')
              .format('L')}`;
            const { error, message, tokenExpired } = await setAssistance(
              moment(this.picker).tz('America/Lima'),
              obs
            );
            if (error) {
              // console.log(error);
            }
            if (tokenExpired) {
              routeToLoginIfTokenIsExpired(this);
            }
            if (message) {
              this.showSuccessAlert();
              await this.getAssistanceXDay();
            }
          } else {
            this.noPersonalAlert();
          }
        });
    },
    noPersonalAlert() {
      this.$swal.fire({
        title: 'Atención',
        text: `No hay personal disponible en el día: ${moment(this.picker)
          .tz('America/Lima')
          .locale('es')
          .format('L')}`,
        icon: 'info',
        showConfirmButton: false,
        timer: 1500,
      });
    },
    // async newAssistance(day, obs) {
    //   await setAssistance(day, obs);
    // },
    searchByName() {
      const word = this.inputSearch.toLowerCase();
      this.personal = this.data;
      let newArray = [];
      for (let i in this.personal) {
        if (
          this.personal[i].nombre.toLowerCase().includes(word) ||
          this.personal[i].datos_laborales.apellido_paterno
            .toLowerCase()
            .includes(word) ||
          this.personal[i].datos_laborales.apellido_materno
            .toLowerCase()
            .includes(word)
        ) {
          newArray.push(this.personal[i]);
        }
      }
      this.personal = newArray;
    },
    remove(n) {
      let index = this.personal.indexOf(n);
      this.personal.splice(index, 1);
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'src/styles/main.scss';
.fuente-branding {
  font-size: 15px !important;
  font-family: 'Branding-Medium' !important;
  display: contents !important;
}
.v-card__text,
.v-card__title {
  word-break: normal !important;
}
.card_container {
  background-color: $secondary !important;
  padding: 10px;
}
.card__text {
  font-size: 1.2em;
  padding-top: 0;
  padding-bottom: 7%;
}
.input_search {
  position: relative;
  width: 180px;
  left: 150px;
  height: 50px;
  padding-bottom: 15px;
  margin-bottom: 5px;
}
.drag-container {
  margin-top: 10px;
  max-height: 69vh;
  overflow: scroll;
  overflow-x: hidden;
  background-color: $secondary !important;
}
.drag-container::-webkit-scrollbar {
  width: 4px;
  background: $secondary;
}

.drag-container::-webkit-scrollbar-thumb {
  background: $main;
  border-radius: 10px;
}
.v-text-field {
  margin: 0px;
}
.card-personal {
  text-align: center;
  margin: 10px;
  width: 200px;
  height: 140px;
  border: 1px solid $secondary;
}
.v-image {
  margin: auto;
}
button.v-btn {
  position: relative;
  top: -60px;
  right: -78px;
  box-shadow: none;
  background-color: transparent !important;
  .v-image {
    width: 10px;
    margin: auto;
  }
}
</style>
