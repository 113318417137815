<template>
  <v-container fluid>
    <v-form ref="formPlantillaSanciones" v-model="valid" lazy-validation>
      <v-row>
        <v-col>
          <v-autocomplete
            label="Tipo de sanción"
            v-model="sancionSeleccionada"
            :items="tiposDeSancion"
            :rules="nameRules"
          />
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row class="d-flex flex-column">
        <v-textarea
          auto-grow
          outlined
          rows="3"
          row-height="25"
          shaped
          v-model="textoSancion.parrafo1"
          :rules="nameRules"
        />
        <v-textarea
          auto-grow
          label="Hechos que generan la sanción"
          outlined
          rows="3"
          row-height="25"
          background-color="teal lighten-5"
          readonly
        />
        <v-textarea
          auto-grow
          outlined
          rows="3"
          row-height="25"
          shaped
          v-model="textoSancion.parrafo2"
          :rules="nameRules"
        />
        <v-textarea
          auto-grow
          outlined
          rows="3"
          row-height="25"
          shaped
          v-model="textoSancion.parrafo3"
          :rules="nameRules"
        />
      </v-row>
    </v-form>
    <v-row class="align-content-center justify-center mt-10">
      <!-- <v-btn text color="blue darken-1">Cancelar</v-btn> -->
      <v-btn text color="success" @click="validate">Editar</v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';

// import { editUserInfo } from '@/api/persons/editUserInfo.js';
// import { getClientData } from '@/api/configuration/getClientData.js';
import { updateClient } from '@/api/configuration/updateClient.js';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';

export default {
  name: 'PlantillaSanciones',
  data: () => ({
    sancionSeleccionada: '',
    valid: true,
    tiposDeSancion: ['Amonestación', 'Suspensión'],
    textoSancion: {
      parrafo1: '',
      parrafo2: '',
      parrafo3: '',
    },
    nameRules: [
      (v) => !!v || 'Campo requerido',
      (v) => (v && v.length >= 3) || 'Mínimo 3 caracteres',
    ],
  }),
  computed: {
    ...mapState(['cliente']),
  },
  watch: {
    sancionSeleccionada() {
      this.textoSancion = {
        parrafo1: '',
        parrafo2: '',
        parrafo3: '',
      };
      if (this.sancionSeleccionada === 'Amonestación') {
        const plantillaAmonestacion = this.cliente.datos_empresa.plantillas_texto_sanciones.find(
          (plantilla) => plantilla.tipo === 'amonestacion'
        );
        this.textoSancion = plantillaAmonestacion;
      }
      if (this.sancionSeleccionada === 'Suspensión') {
        const plantillaSuspension = this.cliente.datos_empresa.plantillas_texto_sanciones.find(
          (plantilla) => plantilla.tipo === 'suspension'
        );
        this.textoSancion = plantillaSuspension;
      }
    },
  },
  methods: {
    showEditarAlert() {
      this.$swal
        .fire({
          title: `Atención!`,
          text: 'Está seguro que desea editar esta plantilla?',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonColor: '#d33',
          cancelButtonText: 'No',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Sí',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            if (this.sancionSeleccionada === 'Amonestación') {
              let plantillaAmonestacion = this.cliente.datos_empresa.plantillas_texto_sanciones.find(
                (plantilla) => plantilla.tipo === 'amonestacion'
              );
              plantillaAmonestacion.parrafo1 = this.textoSancion.parrafo1;
              plantillaAmonestacion.parrafo2 = this.textoSancion.parrafo2;
              plantillaAmonestacion.parrafo3 = this.textoSancion.parrafo3;
            }
            if (this.sancionSeleccionada === 'Suspensión') {
              let plantillaSuspension = this.cliente.datos_empresa.plantillas_texto_sanciones.find(
                (plantilla) => plantilla.tipo === 'suspension'
              );
              plantillaSuspension.parrafo1 = this.textoSancion.parrafo1;
              plantillaSuspension.parrafo2 = this.textoSancion.parrafo2;
              plantillaSuspension.parrafo3 = this.textoSancion.parrafo3;
            }

            const { error, message, tokenExpired } = await updateClient(
              this.cliente
            );

            if (error) {
              //do something
            }
            if (tokenExpired) {
              routeToLoginIfTokenIsExpired(this);
            }
            if (message) {
              this.showSuccessAlert();
            }
          }
        });
    },
    showSuccessAlert() {
      this.$swal.fire({
        title: `Operación realizada correctamente!`,
        text: '',
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    async validate() {
      if (this.$refs.formPlantillaSanciones.validate()) {
        this.showEditarAlert();
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
