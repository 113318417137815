<template>
  <v-dialog
    v-model="cesePersonalDialog"
    max-width="60vw"
    max-height="50vh"
    persistent
  >
    <v-card>
      <v-card-title
        class="text-h5 justify-center"
        style="color: teal; font-weight: bold"
      >
        Formulario de cese/baja de trabajador
      </v-card-title>
      <v-card-subtitle style="color: teal;" class="text-h5">
        {{ `${persona.nombre}` }}
      </v-card-subtitle>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col>
              <v-text-field
                label="Fecha de cese/baja"
                v-model="fechaCese"
                type="date"
                :rules="fieldRules"
              />

              <v-select
                label="Situación laboral"
                v-model="situacionLaboral"
                :items="situacionLaboralItems"
                :rules="fieldRules"
              />
            </v-col>

            <v-col>
              <!-- <v-text-field
                label="Fecha de fin de contrato"
                v-model="fechaFinContrato"
                type="date"
                :rules="fieldRules"
              /> -->
              <v-select
                label="Motivo cese/baja"
                v-model="motivoCese"
                :items="motivosCese"
                :rules="fieldRules"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-textarea
              outlined
              label="Observaciones"
              v-model="observacionesCese"
              :rules="fieldRules"
            />
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn color="blue darken-1" text @click="closeDialog">Cancelar</v-btn>
        <v-btn color="success" text @click="validate">Guardar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

import { editUserInfo } from '@/api/persons/editUserInfo.js';
import { getPersons } from '@/api/persons/getPersons.js';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';

export default {
  name: 'CesePersonalDialog',
  dmOriginal: {},
  data() {
    return {
      motivoCese: '',
      fechaCese: '',
      // fechaFinContrato: '',
      situacionLaboral: '',
      observacionesCese: '',
      situacionLaboralItems: [
        'Cesado',
        'Subsidiado',
        'Sin vínculo pendiente por liquidar',
        'Suspensión perfecta',
      ],
      motivosCese: [
        'Despido o Destitución',
        'Fallecimiento',
        'Invalidez absoluta',
        'Jubilación',
        'Mutuo disenso',
        'Renuncia',
        'Renuncia con incentivos',
        'Termino o Vencimiento de Contrato',
      ],
      personal: [],
      valid: true,
      fieldRules: [(v) => !!v || 'Campo requerido'],
    };
  },
  props: {
    persona: Object,
  },
  watch: {
    cesePersonalDialog() {
      this.$nextTick(function() {
        this.$refs.form.resetValidation();
      });
    },
  },
  computed: {
    ...mapState(['cesePersonalDialog']),
  },
  methods: {
    async obtenerPersonalCompleto() {
      const { error, personal, tokenExpired } = await getPersons();
      if (personal) {
        this.personal = personal;
        this.personal = this.personal.map((persona) => {
          persona.nombre = `${persona.datos_laborales.apellido_paterno} ${persona.datos_laborales.apellido_materno}, ${persona.nombre}`;
          return persona;
        });

        //todo el personal
        this.$store.state.personalCompleto = [];
        this.$store.state.personalCompleto.push(...this.personal);

        //filtro aplicado al personal para solo obtener personal activo para ser utilizado en el proceso de planillas
        this.$store.state.personalActivo = this.personal.filter(
          (persona) => persona.datos_laborales.situacion !== 'Cesado'
        );

        //considerar al personal "Cesado" en el proceso de planilla de liquidación
        this.$store.state.personalCesado = this.personal.filter(
          (persona) => persona.datos_laborales.situacion === 'Cesado'
        );
      }
      if (error) {
        //do something
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
    },
    closeDialog() {
      this.obtenerPersonalCompleto(); // se llama a esta función para tener actualizado los datos cuando se salga de este modal
      this.$store.state.editPerson = false;
      this.$store.state.cesePersonalDialog = false;
    },
    showSuccessAlert() {
      this.$swal.fire({
        title: `Operación realizada correctamente!`,
        text: '',
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    // showDateErrorAlert() {
    //   this.$swal.fire({
    //     title: `Error`,
    //     text:
    //       'La fecha de fin de contrato no puede ser menor a la fecha de cese/baja!',
    //     icon: 'error',
    //     confirmButtonColor: '#3085d6',
    //     confirmButtonText: 'Ok',
    //   });
    // },
    showCeseAlert() {
      this.$swal
        .fire({
          title: `Atención!`,
          text: 'Está seguro que desea deshabilitar a este trabajador?',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonColor: '#d33',
          cancelButtonText: 'No',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            //actualizar datos laborales del usuario
            this.persona.datos_laborales.fecha_cese = this.fechaCese;
            // this.persona.datos_laborales.fecha_fin_contrato = this.fechaFinContrato;
            this.persona.datos_laborales.observaciones_cese = this.observacionesCese;
            this.persona.datos_laborales.motivo_cese = this.motivoCese;
            this.persona.datos_laborales.situacion = this.situacionLaboral;

            const { error, tokenExpired, message } = await editUserInfo(
              this.persona
            );
            if (error) {
              //do something
            }
            if (tokenExpired) {
              routeToLoginIfTokenIsExpired(this);
            }
            if (message) {
              this.showSuccessAlert();
              this.closeDialog();
            }
          }
        });
    },
    async validate() {
      if (this.$refs.form.validate()) {
        // if (this.fechaFinContrato < this.fechaCese) {
        //   this.showDateErrorAlert();
        //   return;
        // }
        this.showCeseAlert();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/main.scss';

.dialog-container {
  overflow: scroll;
}
</style>
