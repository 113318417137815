const numeroALetras = (num, currency) => {
  const unidades = (num) => {
    switch (num) {
      case 1:
        return 'UN';
      case 2:
        return 'DOS';
      case 3:
        return 'TRES';
      case 4:
        return 'CUATRO';
      case 5:
        return 'CINCO';
      case 6:
        return 'SEIS';
      case 7:
        return 'SIETE';
      case 8:
        return 'OCHO';
      case 9:
        return 'NUEVE';
    }

    return '';
  };

  const decenas = (num) => {
    let decena = Math.floor(num / 10);
    let unidad = num - decena * 10;

    switch (decena) {
      case 1:
        switch (unidad) {
          case 0:
            return 'DIEZ';
          case 1:
            return 'ONCE';
          case 2:
            return 'DOCE';
          case 3:
            return 'TRECE';
          case 4:
            return 'CATORCE';
          case 5:
            return 'QUINCE';
          default:
            return 'DIECI' + unidades(unidad);
        }
      case 2:
        switch (unidad) {
          case 0:
            return 'VEINTE';
          default:
            return 'VEINTI' + unidades(unidad);
        }
      case 3:
        return decenasY('TREINTA', unidad);
      case 4:
        return decenasY('CUARENTA', unidad);
      case 5:
        return decenasY('CINCUENTA', unidad);
      case 6:
        return decenasY('SESENTA', unidad);
      case 7:
        return decenasY('SETENTA', unidad);
      case 8:
        return decenasY('OCHENTA', unidad);
      case 9:
        return decenasY('NOVENTA', unidad);
      case 0:
        return unidades(unidad);
    }
  };

  const decenasY = (strSin, numUnidades) => {
    if (numUnidades > 0) return strSin + ' Y ' + unidades(numUnidades);

    return strSin;
  };

  function centenas(num) {
    let centenas = Math.floor(num / 100);
    let cantDecenas = num - centenas * 100;

    switch (centenas) {
      case 1:
        if (cantDecenas > 0) return 'CIENTO ' + decenas(cantDecenas);
        return 'CIEN';
      case 2:
        return 'DOSCIENTOS ' + decenas(cantDecenas);
      case 3:
        return 'TRESCIENTOS ' + decenas(cantDecenas);
      case 4:
        return 'CUATROCIENTOS ' + decenas(cantDecenas);
      case 5:
        return 'QUINIENTOS ' + decenas(cantDecenas);
      case 6:
        return 'SEISCIENTOS ' + decenas(cantDecenas);
      case 7:
        return 'SETECIENTOS ' + decenas(cantDecenas);
      case 8:
        return 'OCHOCIENTOS ' + decenas(cantDecenas);
      case 9:
        return 'NOVECIENTOS ' + decenas(cantDecenas);
    }

    return decenas(cantDecenas);
  }

  function seccion(num, divisor, strSingular, strPlural) {
    let cientos = Math.floor(num / divisor);
    let resto = num - cientos * divisor;

    let letras = '';

    if (cientos > 0)
      if (cientos > 1) letras = centenas(cientos) + ' ' + strPlural;
      else letras = strSingular;

    if (resto > 0) letras += '';

    return letras;
  }

  function miles(num) {
    let divisor = 1000;
    let cientos = Math.floor(num / divisor);
    let resto = num - cientos * divisor;

    let strMiles = seccion(num, divisor, 'UN MIL', 'MIL');
    let strCentenas = centenas(resto);

    if (strMiles == '') return strCentenas;

    return strMiles + ' ' + strCentenas;
  }

  function millones(num) {
    let divisor = 1000000;
    let cientos = Math.floor(num / divisor);
    let resto = num - cientos * divisor;

    let strMillones = seccion(num, divisor, 'UN MILLON DE', 'MILLONES DE');
    let strMiles = miles(resto);

    if (strMillones == '') return strMiles;

    return strMillones + ' ' + strMiles;
  } //Millones()

  //   return function NumeroALetras(num, currency) {
  currency = currency || {};
  let data = {
    numero: num,
    enteros: Math.floor(num),
    centavos: Math.round(num * 100) - Math.floor(num) * 100,
    letrasCentavos: '',
    letrasMonedaPlural: currency.plural || 'SOLES', //'PESOS', 'Dólares', 'Bolívares', 'etcs'
    letrasMonedaSingular: currency.singular || 'SOL', //'PESO', 'Dólar', 'Bolivar', 'etc'
    letrasMonedaCentavoPlural: currency.centPlural || 'CÉNTIMOS',
    letrasMonedaCentavoSingular: currency.centSingular || 'CÉNTIMO',
  };

  if (data.centavos > 0) {
    data.letrasCentavos =
      'CON ' +
      (function() {
        if (data.centavos == 1)
          return (
            millones(data.centavos) + ' ' + data.letrasMonedaCentavoSingular
          );
        else
          return millones(data.centavos) + ' ' + data.letrasMonedaCentavoPlural;
      })();
  }

  if (data.enteros == 0)
    return 'CERO ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos;
  if (data.enteros == 1)
    return (
      millones(data.enteros) +
      ' ' +
      data.letrasMonedaSingular +
      ' ' +
      data.letrasCentavos
    );
  else
    return (
      millones(data.enteros) +
      ' ' +
      data.letrasMonedaPlural +
      ' ' +
      data.letrasCentavos
    );
  //   };
};

export default numeroALetras;
