import fetch from 'isomorphic-fetch';

let ENDPOINT_URL = process.env.VUE_APP_APERTURAR_NUEVA_RUTA;
async function newRoute(date, hora, ruta) {
  const bodyParams = {
    fecha_programacion: date,
    token: localStorage.getItem('token'),
    codigo_ruta: ruta,
    hora_programada: hora,
  };

  let body = JSON.stringify(bodyParams);

  return await fetch(ENDPOINT_URL, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  }).then((item) => item.json());
}
export { newRoute };
