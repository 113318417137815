<template>
  <v-data-table
    :headers="headers"
    :items="pensiones"
    class="elevation-1"
    no-data-text="No hay parámetros por configurar..."
    fixed-header
    height="80vh"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-dialog v-model="dialog" max-width="500px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.concepto"
                      label="Concepto"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.comision_fija"
                      label="Comisión fija"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.comision_flujo"
                      label="Comisión sobre flujo"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.comision_mixta"
                      label="Comisión mixta"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.prima_seguro"
                      label="Prima de seguro"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.aporte_obligatorio"
                      label="Aporte obligatorio"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.remuneracion_maxima"
                      label="Rem. máxima asegurable"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Cancelar
              </v-btn>
              <v-btn color="success" text @click="save">
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.comision_fija`]="{ item }">
      {{ Number(item.comision_fija * 100).toFixed(2) }}%
    </template>
    <template v-slot:[`item.comision_flujo`]="{ item }">
      {{ Number(item.comision_flujo * 100).toFixed(2) }}%
    </template>
    <template v-slot:[`item.comision_mixta`]="{ item }">
      {{ Number(item.comision_mixta * 100).toFixed(2) }}%
    </template>
    <template v-slot:[`item.prima_seguro`]="{ item }">
      {{ Number(item.prima_seguro * 100).toFixed(2) }}%
    </template>
    <template v-slot:[`item.aporte_obligatorio`]="{ item }">
      {{ Number(item.aporte_obligatorio * 100).toFixed(2) }}%
    </template>
    <template v-slot:[`item.remuneracion_maxima`]="{ item }">
      S/.
      {{
        item.remuneracion_maxima === 0
          ? Number(0).toFixed(2)
          : item.remuneracion_maxima
      }}
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
    </template>
  </v-data-table>
</template>
<script>
import { mapState } from 'vuex';

import { updateClient } from '@/api/configuration/updateClient.js';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';

export default {
  name: 'SPP',
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'Concepto',
        value: 'concepto',
      },
      { text: 'Comisión fija', value: 'comision_fija' },
      { text: 'Comisión sobre flujo', value: 'comision_flujo' },
      { text: 'Comisión mixta', value: 'comision_mixta' },
      { text: 'Prima de seguro', value: 'prima_seguro' },
      { text: 'Aporte obligatorio', value: 'aporte_obligatorio' },
      { text: 'Remuneración máxima asegurable', value: 'remuneracion_maxima' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    pensiones: [],
    editedIndex: -1,
    editedItem: {
      concepto: '',
      comision_fija: 0,
      comision_flujo: 0,
      comision_mixta: 0,
      prima_seguro: 0,
      aporte_obligatorio: 0,
      remuneracion_maxima: 0,
    },
    defaultItem: {
      concepto: '',
      comision_fija: 0,
      comision_flujo: 0,
      comision_mixta: 0,
      prima_seguro: 0,
      aporte_obligatorio: 0,
      remuneracion_maxima: 0,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Nueva pensión' : 'Editar pensión';
    },
    ...mapState(['cliente']),
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    async updateClient() {
      const { error, tokenExpired, message } = await updateClient(this.cliente);
      if (error) {
        //do something
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (message) {
        this.showSuccessAlert();
      }
    },
    initialize() {
      this.pensiones = this.cliente.datos_empresa.sistema_pensiones;
    },

    editItem(item) {
      this.editedIndex = this.pensiones.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.pensiones.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.pensiones.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.pensiones[this.editedIndex], this.editedItem);
      } else {
        this.pensiones.push(this.editedItem);
      }
      this.cliente.datos_empresa.sistema_pensiones = this.pensiones;
      this.updateClient();
      this.close();
    },
    showSuccessAlert() {
      this.$swal.fire({
        title: `Operación realizada correctamente!`,
        text: '',
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
  },
};
</script>
