<template>
  <div class="layout-table">
    <Aside />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Aside from './Aside';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';
import obtenerRolesDelToken from '@/utils/obtenerRolesDelToken.js';

export default {
  name: 'Flota',
  components: {
    Aside,
  },
  computed: {
    ...mapState(['usuarioLogueado']),
  },
  mounted() {
    const token = localStorage.getItem('token');
    if (!token) {
      routeToLoginIfTokenIsExpired(this);
      return;
    }
    if (!this.usuarioLogueado || !this.usuarioLogueado._id) {
      this.$store.state.usuarioLogueado = obtenerRolesDelToken(token);
      return;
    }
  },
};
</script>
<style lang="scss" scoped>
@import 'src/styles/main.scss';
.layout-table {
  background: $general-bg;
  width: 100%;
  height: 100vh;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
}
@media (max-width: 735px) {
  .layout-table {
    width: 100vw;
    overflow-x: scroll;
  }
}
</style>
