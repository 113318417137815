<template>
  <v-card min-height="400px">
    <v-toolbar flat color="blue darken-1" dark>
      <v-spacer></v-spacer>
      <v-toolbar-title>Reportes de los procesos de planilla</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-tabs vertical>
      <v-tab
        class="tab-class"
        v-for="tabTitle in tabTitles"
        :key="tabTitle"
        @click="toggleDialog(tabTitle)"
      >
        {{ tabTitle }}
      </v-tab>
      <v-tab-item v-for="tabTitle in tabTitles" :key="tabTitle">
        <TablaReportePlanilla
          :tipoDePlanilla="tabTitle"
          :id="'Tabla' + tabTitle"
          :ref="'Tabla' + tabTitle"
        />
      </v-tab-item>
    </v-tabs>
    <SelectorPlanillas v-if="selectorPlanillasDialog" :planillas="planillas" />
  </v-card>
</template>
<script>
import TablaReportePlanilla from './TablaReportePlanilla.vue';
import SelectorPlanillas from './SelectorPlanillas.vue';
import { mapState } from 'vuex';

export default {
  name: 'Reportes',
  data: () => ({
    contenedorTabs: 100,
  }),
  components: {
    TablaReportePlanilla,
    SelectorPlanillas,
  },

  computed: {
    ...mapState([
      'cliente',
      'selectorPlanillasDialog',
      'planillas',
      'tipoReportePlanilla',
    ]),
    tabTitles() {
      let reportesDisponibles = [
        'Adelanto de sueldo',
        'CTS',
        'Gratificación',
        'Liquidación',
        'Remuneración',
        // 'Utilidades',
        'Vacaciones',
        'Locación de servicio',
        'Boleta mensual',
      ];

      if (this.cliente.datos_empresa.regimen_laboral === 'MICRO EMPRESA') {
        reportesDisponibles = [
          'Adelanto de sueldo',
          'Liquidación',
          'Remuneración',
          // 'Utilidades',
          'Vacaciones',
          'Locación de servicio',
          'Boleta mensual',
        ];
      }

      return reportesDisponibles;
    },
  },
  methods: {
    toggleDialog(reporte) {
      this.$store.state.tipoReportePlanilla = reporte;
    },
  },
  mounted() {
    this.$store.state.tipoReportePlanilla = this.tabTitles[0];
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/main.scss';

.tab-class {
  justify-content: flex-start !important;
}
</style>
