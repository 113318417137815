<template>
  <!-- <CardContainer> -->
  <!-- <div> -->
  <v-card>
    <v-card-text>
      <template>
        <v-tabs v-model="tabs" centered>
          <v-tab v-for="n in tabTitles" :key="n">
            {{ n }}
          </v-tab>
        </v-tabs>
      </template>

      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <!-- <v-card flat> -->
          <DatePicker />
          <!-- </v-card> -->
        </v-tab-item>
        <!-- <v-tab-item>
          <Gestor />
        </v-tab-item> -->
      </v-tabs-items>
    </v-card-text>
  </v-card>
  <!-- </div> -->
  <!-- </CardContainer> -->
</template>
<script>
// import CardContainer from './CardContainer';
import DatePicker from './DatePicker';
// import Gestor from './Gestor';
export default {
  data: () => ({
    tabs: null,
    tabTitles: ['Gestión de rutas'],
    // tabTitles: ['Programación de ruta', 'Gestión de ruta'],
  }),
  components: {
    DatePicker,
    // CardContainer,
    // Gestor,
  },
};
</script>
<style lang="scss" scoped>
@import 'src/styles/main.scss';

// .theme--light.v-sheet {
//   background-color: transparent !important;
//   border-color: none !important;
// }
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none !important;
}
.v-slide-group__content {
  max-height: 100vh;
}
.v-tab {
  max-height: 100vh;
  letter-spacing: 0px;
  text-transform: none;
}
.v-tabs:not(.v-tabs--vertical) .v-tab {
  margin-bottom: 10px;
}
// .card-content {
//   width: 25vw;
//   max-height: 100vh;
//   background-color: red !important;
// }
.v-slide-group__content {
  justify-content: center !important;
}
.v-sheet.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: none;
}
.v-toolbar--dense .v-toolbar__content {
  display: none;
  height: 25px !important;
}
button.btn-right.v-btn:not(.v-btn--round).v-size--default {
  position: relative;
  top: 0px;
  right: 0px;
  min-width: 30px;
  width: 30px;
  .v-image {
    transform: rotate(180deg);
  }
}
</style>
