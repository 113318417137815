<template>
  <v-card class="card_container" :key="renderCard">
    <v-row class="d-flex flex-column justify-center" no-gutters>
      <v-col class="input_search">
        <v-text-field v-model="inputSearch"></v-text-field>
        <v-btn>
          <v-img
            max-width="20px"
            cover
            :src="require('@/assets/menu-icons/search.svg')"
          />
        </v-btn>
      </v-col>
      <v-col>
        <v-row class="drag-container justify-center">
          <drag
            v-for="(index, i) in units"
            :key="i"
            :data="index"
            @cut="remove(index)"
          >
            <v-card v-if="index.disponible == true" class="card-unidad">
              <div v-if="index.tipo_unidad">
                <div v-for="(img, i) in unidades" :key="i">
                  <v-img
                    max-width="145px"
                    height="100px"
                    v-if="index.tipo_unidad == img.title"
                    :src="img.url"
                  />
                </div>
              </div>
              <v-img :src="index.url" />
              <v-card-text>
                {{ index.placa }}
              </v-card-text>
            </v-card>
            <template v-slot:drag-image>
              <v-badge color="red" style="transform: translate(10px, 5px)">
                <v-icon large color="primary">mdi-bus-marker</v-icon>
              </v-badge>
            </template>
          </drag>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { readUnits } from '@/api/flota/getUnits';
import { Drag } from 'vue-easy-dnd';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';

export default {
  name: 'Flota',
  data: () => ({
    renderCard: 0,
    inputSearch: '',
    data: '',
    units: [],
    unidades: [
      {
        url: require('@/assets/flota/moto.png'),
        title: 'Moto',
      },
      {
        url: require('@/assets/flota/minivan.png'),
        title: 'Minivan',
      },
      {
        url: require('@/assets/flota/bus.png'),
        title: 'Bus',
      },
      {
        url: require('@/assets/flota/semitrailer.png'),
        title: 'Semitrailer',
      },
      {
        url: require('@/assets/flota/camión.png'),
        title: 'Camión',
      },
    ],
  }),
  components: {
    Drag,
  },
  created() {
    this.obtenerFlota();
  },
  watch: {
    inputSearch() {
      this.searchByName();
    },
  },
  methods: {
    async obtenerFlota() {
      const { error, tokenExpired, flota } = await readUnits();

      if (error) {
        //do something
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (flota) {
        this.data = flota;
        this.units = this.data;
        this.renderCard++;
      }
    },
    remove(index) {
      this.units = this.units.filter((e) => e !== index);
    },
    searchByName() {
      const word = this.inputSearch.toLowerCase();
      this.units = this.data;
      let newArray = [];
      for (let i in this.units) {
        if (this.units[i].placa.toLowerCase().includes(word)) {
          newArray.push(this.units[i]);
        }
      }
      this.units = newArray;
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'src/styles/main.scss';

.card_container {
  background-color: $secondary !important;
  padding: 10px;
}
.drag-container {
  margin-top: 10px;
  max-height: 72vh;
  overflow: scroll;
  overflow-x: hidden;
  background-color: $secondary !important;
}
.drag-container::-webkit-scrollbar {
  width: 4px;
  background: $secondary;
}

.drag-container::-webkit-scrollbar-thumb {
  background: $main;
  border-radius: 10px;
}
.card-unidad {
  text-align: center;
  margin: 10px;
  width: 150px;
  border: 1px solid $secondary;
}
.input_search {
  position: relative;
  width: 180px;
  left: 150px;
  height: 50px;
  padding-bottom: 15px;
  margin-bottom: 5px;
}
button.v-btn {
  position: relative;
  top: -60px;
  right: -78px;
  box-shadow: none;
  background-color: transparent !important;
  .v-image {
    width: 10px;
  }
}
</style>
