var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"min-height":"400px"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"id":"Vacaciones","fixed-header":"","height":"80vh","headers":_vm.headers,"items":_vm.personal,"loading":_vm.tableLoading,"loading-text":"Cargando reporte de vacaciones... Espere...","hide-default-footer":"","disable-pagination":"","no-data-text":"No hay personal aún...","no-results-text":"No se encontraron registros...","single-expand":"","expanded":_vm.expanded,"show-expand":"","search":_vm.searchPerson,"item-key":"uniqueKey","custom-sort":_vm.customSort},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-toolbar-title',{staticClass:"top-bar-title"},[_vm._v("Vacaciones")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar persona...","single-line":"","hide-details":""},model:{value:(_vm.searchPerson),callback:function ($$v) {_vm.searchPerson=$$v},expression:"searchPerson"}})],1)]},proxy:true},{key:"item.estados",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"chip-class",attrs:{"color":_vm.getColor(item),"dark":item.vacacionesPendientes > 30 ? true : false}},on),[_vm._v(" "+_vm._s(item.vacacionesPendientes)+" ")])]}}],null,true)},[_c('span',[_vm._v("Días pendientes de gozar")])])]}},{key:"item.solicitudes",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.vacacionesSolicitadas)?_c('v-icon',_vm._g({},on),[_vm._v("mdi-email-alert-outline")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Hay vacaciones solicitadas")])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"id":"Vacaciones-Detalle","headers":_vm.vacacionesHeaders,"items":item.vacaciones,"hide-default-footer":"","disable-pagination":"","search":_vm.search,"no-data-text":"No hay vacaciones registradas...","no-results-text":"No se encontraron registros...","item-class":_vm.vacacionesClass},on:{"click:row":_vm.editarVacaciones},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"top-bar-title"},[_vm._v("Registros de "+_vm._s(item.nombre))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({on:{"click":_vm.agregarVacaciones}},on),[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-earth-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Agregar vacaciones")])]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar vacaciones...","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true}],null,true)})],1)],1)]}}],null,true)}),_c('EditarVacaciones',{attrs:{"persona":this.persona,"vacaciones":this.vacaciones,"nuevoVacaciones":this.nuevoVacaciones}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }