import fetch from 'isomorphic-fetch';

let ENDPOINT_URL = process.env.VUE_APP_ELIMINAR_RUTA;
async function deleteRoute(date, ruta) {
  const bodyParams = {
    fecha_programacion: date,
    codigo_ruta: ruta,
    token: localStorage.getItem('token'),
  };

  let body = JSON.stringify(bodyParams);

  return await fetch(ENDPOINT_URL, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  }).then((item) => item.json());
}
export { deleteRoute };
