<template>
  <v-card height="67vh" ref="render">
    <!-- <v-card-title
      max-width="800px"
      class="text-h5 justify-center"
      style="color: teal; font-weight: bold"
    >
      {{ `Conceptos Detalles del grupo: ${grupoConcepto}` }}
    </v-card-title> -->
    <!-- <v-card-subtitle style="color: teal;" class="text-h5">
        {{ `del día ` }}
      </v-card-subtitle> -->
    <v-card-text>
      <v-data-table
        class="elevation-1"
        no-results-text="No se encontraron conceptos..."
        fixed-header
        height="53vh"
        :headers="headers"
        :items="items"
        loading-text="Cargando conceptos de Sunat... Espere..."
        hide-default-footer
        disable-pagination
        no-data-text="No hay conceptos aún..."
        :search="buscarConcepto"
        item-key="codigo"
      >
        <template v-slot:[`item.seleccionado`]="{ item }">
          <v-checkbox
            color="success"
            v-ripple
            :v-model="item.seleccionado"
            :value="item.seleccionado"
            @change="conceptoSeleccionado(item)"
          ></v-checkbox>
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title class="top-bar-title"
              >Detalles de conceptos del grupo
              {{ grupoConcepto }}</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-text-field
              v-model="buscarConcepto"
              append-icon="mdi-magnify"
              label="Buscar conceptos..."
              single-line
              hide-details
            ></v-text-field>
          </v-toolbar>
        </template>

        <template v-slot:[`item.afectacion`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">
                mdi-magnify
              </v-icon>
            </template>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      ESSALUD Seguro
                    </th>
                    <th class="text-left">
                      ESSALUD SCTR
                    </th>
                    <th class="text-left">
                      SNP
                    </th>
                    <th class="text-left">
                      AFP
                    </th>
                    <th class="text-left">
                      5ta Categoría
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <td>{{ item.afectacion_empleador.essalud_seguro }}</td>
                  <td>{{ item.afectacion_empleador.essalud_sctr }}</td>
                  <td>{{ item.afectacion_trabajador.snp }}</td>
                  <td>{{ item.afectacion_trabajador.afp }}</td>
                  <td>{{ item.afectacion_trabajador.quinta_categoria }}</td>
                </tbody>
              </template>
            </v-simple-table>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>

    <v-card-actions class="justify-center">
      <!-- <v-btn
        color="blue"
        text
        @click="showErrorAlert"
        v-if="grupoConcepto === 1000"
        >Guardar</v-btn
      > -->
      <v-btn
        color="success"
        text
        @click="showErrorAlert"
        v-if="grupoConcepto === 1000"
        >Nuevo Concepto</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';

import { updateClient } from '@/api/configuration/updateClient.js';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';

export default {
  name: 'ConceptosDetalle',
  data() {
    return {
      tableLoading: true,
      buscarConcepto: '',
      conceptosSunatFiltrados: [],
      conceptosClienteFiltrados: [],
      conceptoEditado: '',
    };
  },
  props: {
    grupoConcepto: Number,
    conceptosSunat: Array,
  },
  computed: {
    ...mapState(['cliente']),
    headers() {
      let fixedHeaders = [
        {
          text: 'Código',
          value: 'codigo',
        },
        {
          text: 'Descripción',
          value: 'nombre',
        },
        {
          text: 'Afectación',
          value: 'afectacion',
        },
        {
          text: 'Seleccionado',
          value: 'seleccionado',
        },
      ];

      return fixedHeaders;
    },
    items() {
      let fixedItems = [];
      if (this.cliente.datos_empresa && this.conceptosSunat.length > 0) {
        fixedItems = this.conceptosSunat.map((concepto) => {
          concepto.seleccionado = false;
          let found = {};
          found = this.conceptosClienteFiltrados.find(
            (item) => item.codigo === concepto.codigo
          );
          if (found) concepto.seleccionado = true;
          return concepto;
        });
      }

      return fixedItems;
    },
  },
  methods: {
    conceptoSeleccionado(concepto) {
      this.conceptoEditado = concepto;
      concepto.seleccionado = !concepto.seleccionado;
      if (concepto.seleccionado) {
        let found = false;
        found = this.cliente.datos_empresa.conceptos_sunat.find(
          (item) => item.codigo === concepto.codigo
        );
        if (!found) {
          this.cliente.datos_empresa.conceptos_sunat.push(concepto);
          this.updateClient();
        }
      } else {
        let found = false;
        found = this.cliente.datos_empresa.conceptos_sunat.find(
          (item) => item.codigo === concepto.codigo
        );
        if (found) {
          this.cliente.datos_empresa.conceptos_sunat = this.cliente.datos_empresa.conceptos_sunat.filter(
            (item) => item.codigo !== concepto.codigo
          );
          this.updateClient();
        }
      }
      // console.log(concepto);
    },
    async updateClient() {
      //impedir que se "desmarquen" conceptos mínimos necesarios para cálculos de planilla
      const { codigo } = this.conceptoEditado;
      if (
        codigo === '0118' ||
        codigo === '0121' ||
        codigo === '0201' ||
        codigo === '0601' ||
        codigo === '0605' ||
        codigo === '0606' ||
        codigo === '0607' ||
        codigo === '0608' ||
        codigo === '0704' ||
        codigo === '0705' ||
        codigo === '0706' ||
        codigo === '0804' ||
        codigo === '0809' ||
        codigo === '0811' ||
        codigo === '0904' ||
        codigo === '0907' ||
        codigo === '0911' ||
        codigo === '0915' ||
        codigo === '0916'
      ) {
        // console.log(codigo);
        return;
      }
      const { error, tokenExpired, message } = await updateClient(this.cliente);
      if (error) {
        //do something
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (message) {
        // this.showSuccessAlert();
        //do something
      }
    },
    showSuccessAlert() {
      this.$swal.fire({
        title: `Operación realizada correctamente!`,
        text: '',
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    showErrorAlert() {
      console.log(this.ingresosAEditar);
      this.$swal.fire({
        title: `Está seguro que va a modificar este concepto?`,
        text: '',
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
  },
  mounted() {
    if (this.cliente.datos_empresa && this.conceptosSunat.length > 0) {
      this.conceptosClienteFiltrados = this.cliente.datos_empresa.conceptos_sunat.filter(
        (concepto) =>
          Number(concepto.codigo) >= this.grupoConcepto &&
          Number(concepto.codigo) < this.grupoConcepto + 100
      );
      this.conceptosSunatFiltrados = this.conceptosSunat.filter(
        (concepto) =>
          Number(concepto.codigo) >= this.grupoConcepto &&
          Number(concepto.codigo) < this.grupoConcepto + 100
      );
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/main.scss';
.top-bar-title {
  font-size: 25px !important;
  font-weight: bold !important;
  color: teal !important;
}
</style>
