<template>
  <v-card height="50vh">
    <v-progress-circular
      v-if="showLoadingDialog"
      :size="180"
      :width="7"
      color="teal"
      indeterminate
    ></v-progress-circular>
    <v-tabs vertical v-else>
      <!-- tab 1 -->
      <v-tab>
        <v-icon left>
          mdi-chart-line
        </v-icon>
        Descuentos
      </v-tab>

      <!-- tab 2 -->
      <v-tab>
        <v-icon left>
          mdi-lock
        </v-icon>
        Conceptos
      </v-tab>

      <!-- tab 3 -->
      <v-tab @click="siguientePaso">
        <v-icon left color="teal">
          mdi-checkbox-marked-outline
        </v-icon>
        Continuar
      </v-tab>

      <!-- tab 4 -->
      <v-tab @click="volverAlPasoAnterior">
        <v-icon left color="red">
          mdi-undo
        </v-icon>
        Anterior
      </v-tab>

      <!-- tab-item 1 -->
      <v-tab-item>
        <v-card flat :key="renderTable">
          <v-card-text v-if="esPlanillaRemuneracion">
            <!-- <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-toolbar-title>
                <v-btn color="teal" dark>Editar jornada</v-btn>
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar> -->
            <v-row>
              <v-spacer></v-spacer>
              <apexchart
                type="bar"
                height="320"
                :options="chartOptionsTotalAportesObligatorios"
                :series="seriesTotalAporteObligatorioPensiones"
              ></apexchart>
              <v-spacer></v-spacer>
              <apexchart
                type="bar"
                height="320"
                :options="chartOptionsTotalComisionPensiones"
                :series="seriesTotalComisionesPensiones"
              ></apexchart>
              <v-spacer></v-spacer>
              <apexchart
                type="bar"
                height="320"
                :options="chartOptionsTotalPrimaPensiones"
                :series="seriesTotalPrimaSeguroPensiones"
              ></apexchart>
              <v-spacer></v-spacer>
              <apexchart
                type="bar"
                height="320"
                :options="chartOptionsTotalONP"
                :series="seriesMontoONP"
              ></apexchart>
              <v-spacer></v-spacer>
            </v-row>
          </v-card-text>
          <v-card-text v-else>
            <v-data-table
              no-results-text="No se encontraron registros..."
              :headers="headers"
              :loading="tableLoading"
              loading-text="Cargando conceptos... Espere..."
              :items="conceptosTabla"
              class="elevation-1"
              :search="search"
              no-data-text="No hay descuentos aún..."
              item-key="codigo"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-spacer></v-spacer>
                  <v-toolbar-title class="top-bar-title"
                    >Descuentos</v-toolbar-title
                  >
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar concepto..."
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-spacer></v-spacer>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- tab-item 2 -->
      <v-tab-item>
        <v-row no-gutters>
          <v-col>
            <v-card flat max-width="475px">
              <v-card-title class="text-h5 justify-center">
                Agregar descuentos por concepto
              </v-card-title>
              <v-card-text>
                <v-row class="d-flex flex-column" no-gutters>
                  <v-col>
                    <v-card-title
                      class="text-subtitle-1 justify-center"
                      style="color: teal; font-weight: bold"
                    >
                      Importar masivamente desde Excel?
                    </v-card-title>
                  </v-col>
                  <v-col>
                    <v-row>
                      <v-spacer></v-spacer>
                      <v-switch
                        v-model="importaDesdeExcel"
                        label="Sí"
                      ></v-switch>
                      <v-spacer></v-spacer>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text v-if="importaDesdeExcel">
                <v-row class="d-flex flex-column" no-gutters>
                  <v-col>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          @click="descargarFormato"
                          v-on="on"
                          fab
                          class="mb-4"
                        >
                          <v-icon color="success"
                            >mdi-cloud-download-outline</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Descargar formato de carga masiva</span>
                    </v-tooltip>
                  </v-col>
                  <v-col>
                    <v-file-input
                      v-model="file"
                      label="Seleccione el archivo de carga masiva"
                      outlined
                      dense
                    ></v-file-input>
                  </v-col>
                  <v-col>
                    <v-btn @click="procesarFile">
                      <v-icon color="success">mdi-check-bold</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text v-else>
                <v-row class="d-flex flex-column" no-gutters>
                  <v-col>
                    <v-autocomplete
                      label="Seleccione el concepto"
                      v-model="conceptoSeleccionado"
                      :items="conceptosDescuentos"
                      item-text="nombre"
                      return-object
                    />
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="resumenPersonalSeleccionado"
                      readonly
                      placeholder="Selección del personal a procesar"
                      label="Personal a procesar"
                      @click="abrirSelectorPersonasDialog"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="montoDescuentoMasivo"
                      label="Ingrese el monto"
                      outlined
                      type="number"
                      min="0"
                    />
                  </v-col>
                  <v-col>
                    <v-btn @click="procesarDescuentos">
                      <v-icon color="success">mdi-check-bold</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

                <SelectorPersonas v-if="selectorPersonas" />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card flat>
              <v-data-table
                no-results-text="No se encontraron registros..."
                :headers="headers"
                :loading="tableLoading"
                loading-text="Cargando conceptos... Espere..."
                :items="conceptosTabla"
                class="elevation-1"
                :search="search"
                no-data-text="No hay descuentos aún..."
                item-key="codigo"
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-spacer></v-spacer>
                    <v-toolbar-title class="top-bar-title"
                      >Descuentos</v-toolbar-title
                    >
                    <v-spacer></v-spacer>
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Buscar concepto..."
                      single-line
                      hide-details
                    ></v-text-field>
                    <v-spacer></v-spacer>
                  </v-toolbar>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>

      <!-- tab-item 3 -->
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>
              Continuar al siguiente paso...
            </p>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- tab-item 4 -->
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>
              VOLVER AL PASO ANTERIOR
            </p>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import { excelToJSON } from '@/plugins/excelToJSON.js';

import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';
import { getPlanillaPorPeriodoYPorTipo } from '@/api/payments/getPlanillaPorPeriodoYPorTipo.js';
import VueApexCharts from 'vue-apexcharts';
import SelectorPersonas from './SelectorPersonas.vue';

export default {
  name: 'Paso3',
  data: () => ({
    // mostrarImportacionDesdeExcel: false,
    renderTable: 0,
    tableLoading: false,
    montoDescuentoMasivo: 0,
    conceptoSeleccionado: '',
    file: {},
    importaDesdeExcel: false,
    showLoadingDialog: true,
    totalPrimaSeguroPensiones: 0,
    totalAporteObligatorioPensiones: 0,
    totalComisionPensiones: 0,
    totalMontoONP: 0,
    headers: [
      {
        text: 'Código',
        value: 'codigo',
        width: '100px',
      },
      {
        text: 'Concepto',
        value: 'nombre',
      },
      {
        text: 'Monto',
        value: 'montoTotal',
      },
    ],
    search: '',
    seriesTotalComisionesPensiones: [],
    seriesTotalAporteObligatorioPensiones: [],
    seriesTotalPrimaSeguroPensiones: [],
    seriesMontoONP: [],
    planillaGratificacionJulio: [],
    mesesDelAnio: [
      {
        mes: 'Enero',
        numeroMes: 1,
      },
      {
        mes: 'Febrero',
        numeroMes: 2,
      },
      {
        mes: 'Marzo',
        numeroMes: 3,
      },
      {
        mes: 'Abril',
        numeroMes: 4,
      },
      {
        mes: 'Mayo',
        numeroMes: 5,
      },
      {
        mes: 'Junio',
        numeroMes: 6,
      },
      {
        mes: 'Julio',
        numeroMes: 7,
      },
      {
        mes: 'Agosto',
        numeroMes: 8,
      },
      {
        mes: 'Septiembre',
        numeroMes: 9,
      },
      {
        mes: 'Octubre',
        numeroMes: 10,
      },
      {
        mes: 'Noviembre',
        numeroMes: 11,
      },
      {
        mes: 'Diciembre',
        numeroMes: 12,
      },
    ],
  }),
  components: {
    apexchart: VueApexCharts,
    SelectorPersonas,
  },
  computed: {
    esPlanillaRemuneracion() {
      return this.nuevaPlanillaCreada.tipo_proceso === 'Remuneración';
    },
    ...mapState([
      'procesoPlanilla',
      'personalActivo',
      'nuevaPlanillaCreada',
      'cliente',
      'conceptosSunat',
      'planillaAdelantoDelPeriodo',
      'planillasRemuneracionDelAnioDelPeriodo',
      'planillasVacacionesDelAnioDelPeriodo',
      'personalParaProceso',
      'selectorPersonas',
      'planillasNoviembreAnioPasado',
      'planillasDiciembreAnioPasado',
    ]),
    chartOptionsTotalComisionPensiones() {
      let configuracionGraficos = {
        chart: {
          type: 'bar',
          height: 350,
          width: 360,
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: '16px',
            fontFamily: 'Branding-Medium',
          },
          x: {
            show: true,
          },
          y: {
            formatter: function(value) {
              var formatter = new Intl.NumberFormat('es-PE', {
                style: 'currency',
                currency: 'PEN',
              });

              return `${formatter.format(value)}`;
            },
          },
          fixed: {
            enabled: true,
            position: 'topRight',
            offsetX: 0,
            offsetY: 0,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '35%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        title: {
          text: 'Comisión de pensiones',
          align: 'left',
        },
        xaxis: {
          categories: ['Comisión de pensiones'],
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#008FFB',
            },
            labels: {
              style: {
                colors: '#008FFB',
              },
            },
            title: {
              text: 'Total comisión de pensiones (S/.)',
              style: {
                color: '#008FFB',
              },
            },
          },
        ],
      };
      return configuracionGraficos;
    },
    chartOptionsTotalAportesObligatorios() {
      let configuracionGraficos = {
        chart: {
          type: 'bar',
          height: 350,
          width: 360,
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: '16px',
            fontFamily: 'Branding-Medium',
          },
          x: {
            show: true,
          },
          y: {
            formatter: function(value) {
              var formatter = new Intl.NumberFormat('es-PE', {
                style: 'currency',
                currency: 'PEN',
              });

              return `${formatter.format(value)}`;
            },
          },
          fixed: {
            enabled: true,
            position: 'topRight',
            offsetX: 0,
            offsetY: 0,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '35%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        title: {
          text: 'Aportes de pensiones',
          align: 'left',
        },
        xaxis: {
          categories: ['Aportes obligatorios de pensión'],
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: 'green',
            },
            labels: {
              style: {
                colors: 'green',
              },
            },
            title: {
              text: 'Total aportes obligatorios de pensiones (S/.)',
              style: {
                color: 'green',
              },
            },
          },
        ],
      };
      return configuracionGraficos;
    },
    chartOptionsTotalPrimaPensiones() {
      let configuracionGraficos = {
        chart: {
          type: 'bar',
          height: 350,
          width: 360,
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: '16px',
            fontFamily: 'Branding-Medium',
          },
          x: {
            show: true,
          },
          y: {
            formatter: function(value) {
              var formatter = new Intl.NumberFormat('es-PE', {
                style: 'currency',
                currency: 'PEN',
              });

              return `${formatter.format(value)}`;
            },
          },
          fixed: {
            enabled: true,
            position: 'topRight',
            offsetX: 0,
            offsetY: 0,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '35%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        title: {
          text: 'Prima de seguro de pensiones',
          align: 'left',
        },
        xaxis: {
          categories: ['Prima seguro pensiones'],
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: 'red',
            },
            labels: {
              style: {
                colors: 'red',
              },
            },
            title: {
              text: 'Total primas de seguro de pensiones (S/.',
              style: {
                color: 'red',
              },
            },
          },
        ],
      };
      return configuracionGraficos;
    },
    chartOptionsTotalONP() {
      let configuracionGraficos = {
        chart: {
          type: 'bar',
          height: 350,
          width: 360,
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: '16px',
            fontFamily: 'Branding-Medium',
          },
          x: {
            show: true,
          },
          y: {
            formatter: function(value) {
              var formatter = new Intl.NumberFormat('es-PE', {
                style: 'currency',
                currency: 'PEN',
              });

              return `${formatter.format(value)}`;
            },
          },
          fixed: {
            enabled: true,
            position: 'topRight',
            offsetX: 0,
            offsetY: 0,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '35%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        title: {
          text: 'Monto total por ONP',
          align: 'left',
        },
        xaxis: {
          categories: ['Aportes por ONP'],
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: 'teal',
            },
            labels: {
              style: {
                colors: 'teal',
              },
            },
            title: {
              text: 'Total por ONP (S/.',
              style: {
                color: 'teal',
              },
            },
          },
        ],
      };
      return configuracionGraficos;
    },
    conceptosDescuentos() {
      let conceptosDescuentos = this.cliente.datos_empresa.conceptos_sunat.filter(
        (concepto) =>
          Number(concepto.codigo) >= 600 && Number(concepto.codigo) < 800
      );
      return conceptosDescuentos;
    },
    resumenPersonalSeleccionado() {
      if (this.personalParaProceso.length > 0) {
        return `Total trabajadores seleccionados: ${this.personalParaProceso.length}`;
      }
      return `Total trabajadores seleccionados: 0`;
    },
    conceptosTabla() {
      let arrayDescuentos = [];

      this.nuevaPlanillaCreada.personal.map((persona) => {
        persona.descuentos_por_concepto.map((descuento) => {
          let descuentoEncontrado = arrayDescuentos.find(
            (descuentoArray) => descuentoArray.codigo === descuento.codigo
          );
          if (descuentoEncontrado) {
            descuentoEncontrado.montoTotal =
              descuentoEncontrado.montoTotal + descuento.monto;
          } else {
            let nombreConcepto = this.conceptosSunat.find(
              (conceptoSunat) => conceptoSunat.codigo === descuento.codigo
            );
            if (nombreConcepto) {
              arrayDescuentos.push({
                nombre: nombreConcepto.nombre,
                codigo: descuento.codigo,
                montoTotal: descuento.monto,
              });
            }
          }

          arrayDescuentos.map((descuento) => {
            descuento.montoTotal = Math.round(descuento.montoTotal * 100) / 100;
            return descuento;
          });
          return descuento;
        });

        return persona;
      });

      return arrayDescuentos;
    },
  },
  mounted() {
    // console.log(this.procesoPlanilla);
    // console.log(this.cliente);
    // console.log(this.nuevaPlanillaCreada);
    this.planillaGratificacionJulio = [];
    switch (this.nuevaPlanillaCreada.tipo_proceso) {
      case 'Remuneración':
        this.procesarPlanillaRemuneracion();
        break;
      // case 'CTS':
      //   this.procesarPlanillaCTS();
      //   break;
      case 'Gratificación':
        this.procesarPlanillaGratificacion();
        break;
      case 'Liquidación':
        this.procesarPlanillaLiquidacion();
        break;
      // case 'Utilidades':
      //   this.procesarPlanillaUtilidades();
      //   break;
      case 'Vacaciones':
        this.procesarPlanillaVacaciones();
        break;
      case 'Locación de servicio':
        this.procesarPlanillaLocacionDeServicio();
        break;
      case 'Adelanto de sueldo':
        this.procesarPlanillaAdelantoDeSueldo();
        break;
      default:
        this.showErrorAlert('No se ha seleccionado un tipo de planilla válido');
        break;
    }
  },
  methods: {
    abrirSelectorPersonasDialog() {
      this.$store.state.selectorPersonas = true;
    },
    procesarDescuentos() {
      this.personalParaProceso.map((personaProceso) => {
        let personaEncontrada = this.nuevaPlanillaCreada.personal.find(
          (persona) => persona.doi.toString() === personaProceso.doi.toString()
        );
        // validar si es un descuento si debe restar al monto de "ADELANTO" en ingresos por concepto
        if (personaEncontrada) {
          let descuentoEncontrado = personaEncontrada.descuentos_por_concepto.find(
            (descuento) => descuento.codigo === this.conceptoSeleccionado.codigo
          );
          if (descuentoEncontrado) {
            descuentoEncontrado.monto =
              descuentoEncontrado.monto + Number(this.montoDescuentoMasivo);
          } else {
            personaEncontrada.descuentos_por_concepto.push({
              codigo: this.conceptoSeleccionado.codigo,
              monto: Number(this.montoDescuentoMasivo),
            });
          }
        }

        return personaProceso;
      });
      this.showSuccessAlert();
      this.montoDescuentoMasivo = 0;
      this.conceptoSeleccionado = '';
      this.$store.state.personalParaProceso = [];
    },
    descargarFormato() {
      window.open(
        'https://cti-roads-assets.s3.us-west-2.amazonaws.com/Formato_carga_masiva_descuentos.xlsx'
      );
    },
    showSuccessAlert() {
      this.$swal.fire({
        title: `Operación realizada correctamente!`,
        text: '',
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    async procesarFile() {
      if (this.file.size > 0) {
        this.showLoadingDialog = true;
        let conceptosDescuentosFiltrados = this.conceptosSunat.filter(
          (concepto) =>
            Number(concepto.codigo) >= 600 && Number(concepto.codigo) < 800
        );
        const arrayFilasExcel = await excelToJSON(this.file);
        arrayFilasExcel.map((filaExcel) => {
          let existeCodigoDescuento = conceptosDescuentosFiltrados.find(
            (concepto) => concepto.codigo === '0' + filaExcel.Codigo_concepto
          );
          if (existeCodigoDescuento) {
            // validar si es un descuento si debe restar al monto de "ADELANTO" en ingresos por concepto
            let personaEncontrada = this.nuevaPlanillaCreada.personal.find(
              (trabajador) =>
                trabajador.doi.toString() === filaExcel.No_Documento.toString()
            );
            if (personaEncontrada) {
              //filtrar concepto de ingreso y añadir el monto
              let descuentoEncontrado = personaEncontrada.descuentos_por_concepto.find(
                (ingreso) => ingreso.codigo === '0' + filaExcel.Codigo_concepto
              );
              if (descuentoEncontrado) {
                descuentoEncontrado.monto =
                  descuentoEncontrado.monto + Number(filaExcel.Monto);
              } else {
                personaEncontrada.descuentos_por_concepto.push({
                  codigo: '0' + filaExcel.Codigo_concepto,
                  monto: Number(filaExcel.Monto),
                });
              }
            }
          }
          return filaExcel;
        });
        this.showLoadingDialog = false;
        this.showSuccessAlert();
        this.file = {};
      }
    },
    async procesarPlanillaRemuneracion() {
      const parametroAsignacionFamiliar = this.cliente.datos_empresa.parametros.find(
        (parametro) => parametro.concepto === 'ASIGNACION FAMILIAR'
      );
      const parametroUIT = this.cliente.datos_empresa.parametros.find(
        (parametro) => parametro.concepto === 'UIT'
      );
      const { sistema_pensiones } = this.cliente.datos_empresa;

      const mesPeriodo = this.nuevaPlanillaCreada.periodo.split('-')[0];
      const numeroDeMesPeriodoActual = this.mesesDelAnio.find(
        (mes) => mes.mes === mesPeriodo
      );

      //calcular monto de la proyección por los periodos que faltan hasta terminar el año sumando los periodos de gratificación
      if (numeroDeMesPeriodoActual.numeroMes > 7) {
        //si se está calculando las planillas posteriores a Julio, entonces se debe obtener la planilla de gratificación de Julio
        await this.obtenerPlanillaGratificacionJulio();
      }

      this.nuevaPlanillaCreada.personal.map((persona) => {
        //calculo de planilla por horas
        // const montoRemuneracionDiaria =
        //   Number(persona.remuneracion_mensual) / 30;
        //         persona.info_dias.map(dia => {
        //           //aplicar cálculo de tardanza si el cálculo de la planilla es por horas

        //           // if (this.nuevaPlanillaCreada.tipo_calculo === 'horas'){
        //           //   //calcular el monto a descontar por tardanza
        //           //   if (dia.tipo_jornada === 'Tardanza'){
        //           //     ///buscar por este concepto en persona.descuentos_por_concepto y si no existe hacer push, sino calcular y sumar el monto (de jornada diaria + asig familiar???)
        //           //   }
        //           // }

        //varaibles para calcules de ingresos afectos a 5ta categoría y/o a pension de "este periodo"
        let montoTotalIngresosAfectosQuintaCategoria = 0;
        let montoTotalIngresosAfectosPension = 0;

        persona.ingresos_por_concepto.map((ingreso) => {
          //Calcular ingresos afectos a 5ta Categoria
          const ingresoAfectoAQuintaCategoria = this.conceptosSunat.find(
            (concepto) =>
              concepto.codigo === ingreso.codigo &&
              concepto.afectacion_trabajador.quinta_categoria === 'SI'
            // &&
            // ingreso.codigo !== '0121'
          );
          if (ingresoAfectoAQuintaCategoria)
            montoTotalIngresosAfectosQuintaCategoria =
              montoTotalIngresosAfectosQuintaCategoria + ingreso.monto;

          // Calcular montos de ingresos afectos a SNP/AFP
          // if (pensionEsONP) {
          const ingresoAfectoAPension = this.conceptosSunat.find(
            (concepto) =>
              concepto.codigo === ingreso.codigo &&
              (concepto.afectacion_trabajador.snp === 'SI' ||
                concepto.afectacion_trabajador.afp === 'SI')
          );
          if (ingresoAfectoAPension)
            montoTotalIngresosAfectosPension =
              montoTotalIngresosAfectosPension + ingreso.monto;
          // }
          return ingreso;
        });

        //Obtener el tipo de pensión de la persona a evaluar
        const pensionEsONP = persona.pension === 'ONP' ? true : false;
        if (pensionEsONP) {
          const pension = sistema_pensiones.find(
            (pension) => pension.concepto === 'ONP'
          );
          if (pension) {
            let montoPorONP =
              montoTotalIngresosAfectosPension *
              Number(pension.aporte_obligatorio);
            //suma de totales para los gráficos
            this.totalMontoONP = this.totalMontoONP + montoPorONP;
            //redondeo a dos decimales
            this.totalMontoONP = Math.round(this.totalMontoONP * 100) / 100;
            //agregar concepto de descuento
            let descuentoEncontrado = persona.descuentos_por_concepto.find(
              (descuento) => descuento.codigo === '0607'
            );
            if (descuentoEncontrado) {
              descuentoEncontrado.monto =
                descuentoEncontrado.monto + montoPorONP;
            } else {
              persona.descuentos_por_concepto.push({
                codigo: '0607',
                monto: montoPorONP,
              });
            }
          }
        } else {
          let pension = sistema_pensiones.find(
            (pension) => pension.concepto === persona.nombre_afp
          );
          if (pension) {
            const montoAportesObligatorios =
              montoTotalIngresosAfectosPension *
              Number(pension.aporte_obligatorio);
            const comisionAFP =
              persona.tipo_comision_afp === 'Flujo'
                ? pension.comision_flujo
                : pension.comision_mixta;
            const montoComision =
              montoTotalIngresosAfectosPension * Number(comisionAFP);
            const montoPrimaSeguro =
              montoTotalIngresosAfectosPension * Number(pension.prima_seguro);

            this.totalAporteObligatorioPensiones =
              this.totalAporteObligatorioPensiones + montoAportesObligatorios;
            this.totalComisionPensiones =
              this.totalComisionPensiones + montoComision;
            this.totalPrimaSeguroPensiones =
              this.totalPrimaSeguroPensiones + montoPrimaSeguro;
            //redondeo a dos decimales
            this.totalAporteObligatorioPensiones =
              Math.round(this.totalAporteObligatorioPensiones * 100) / 100;
            this.totalComisionPensiones =
              Math.round(this.totalComisionPensiones * 100) / 100;
            this.totalPrimaSeguroPensiones =
              Math.round(this.totalPrimaSeguroPensiones * 100) / 100;

            //agregar concepto 608 - aportes obligatorios
            let aporteObligatorioEncontrado = persona.descuentos_por_concepto.find(
              (descuento) => descuento.codigo === '0608'
            );
            if (aporteObligatorioEncontrado) {
              aporteObligatorioEncontrado.monto =
                aporteObligatorioEncontrado.monto + montoAportesObligatorios;
            } else {
              persona.descuentos_por_concepto.push({
                codigo: '0608',
                monto: montoAportesObligatorios,
              });
            }

            //agregar concepto 601 - comision afp
            let comisionAFPEncontrado = persona.descuentos_por_concepto.find(
              (descuento) => descuento.codigo === '0601'
            );
            if (comisionAFPEncontrado) {
              comisionAFPEncontrado.monto =
                comisionAFPEncontrado.monto + montoComision;
            } else {
              persona.descuentos_por_concepto.push({
                codigo: '0601',
                monto: montoComision,
              });
            }

            //agregar concepto 606 - prima seguro - calcular remuneración máxima asegurable
            let primaSeguroEncontrado = persona.descuentos_por_concepto.find(
              (descuento) => descuento.codigo === '0606'
            );
            if (primaSeguroEncontrado) {
              primaSeguroEncontrado.monto =
                primaSeguroEncontrado.monto + montoPrimaSeguro;
            } else {
              persona.descuentos_por_concepto.push({
                codigo: '0606',
                monto: montoPrimaSeguro,
              });
            }
          }
        }

        if (persona.tipo_seguro === 'EPS') {
          //Calcular descuento por EPS
          let montoAsumeTrabajador =
            persona.monto_descuento_eps * (persona.porcentaje_pago_eps / 100);
          let descuentoEPSEncontrado = persona.descuentos_por_concepto.find(
            (descuento) => descuento.codigo === '0706'
          );
          if (descuentoEPSEncontrado) {
            descuentoEPSEncontrado.monto =
              descuentoEPSEncontrado.monto + montoAsumeTrabajador;
          } else {
            persona.descuentos_por_concepto.push({
              codigo: '0706',
              monto: montoAsumeTrabajador,
            });
          }
        }

        //inicializar contador de monto ya descontado por quinta categoría períodos anteriores
        let montoDescontadoQuintaCategoria = 0;
        let montoTotalQuintaCategoria = 0;

        let montoProyeccionQuintaCategoria = 0;
        let cantidadPeriodosFaltantes = 0;
        //buscar si la persona ha sido cesada "este mes" para no proyectar la renta de quinta categoría
        if (
          persona.fecha_cese &&
          moment(persona.fecha_cese).tz('America/Lima') >=
            moment(this.nuevaPlanillaCreada.fecha_inicio).tz('America/Lima') &&
          moment(persona.fecha_cese).tz('America/Lima') <=
            moment(this.nuevaPlanillaCreada.fecha_fin).tz('America/Lima')
        ) {
          cantidadPeriodosFaltantes = 0;
          montoProyeccionQuintaCategoria = 0;
        } else {
          if (numeroDeMesPeriodoActual.numeroMes <= 7) {
            switch (this.cliente.datos_empresa.regimen_laboral) {
              case 'MICRO EMPRESA':
                cantidadPeriodosFaltantes =
                  12 - numeroDeMesPeriodoActual.numeroMes;
                break;
              case 'PEQUEÑA EMPRESA':
                cantidadPeriodosFaltantes =
                  12 - numeroDeMesPeriodoActual.numeroMes + 1;
                break;

              default:
                cantidadPeriodosFaltantes =
                  12 - numeroDeMesPeriodoActual.numeroMes + 2; // 2 periodos que faltan calcular por conceptos de gratificacion
                break;
            }
          } else {
            switch (this.cliente.datos_empresa.regimen_laboral) {
              case 'MICRO EMPRESA':
                cantidadPeriodosFaltantes =
                  12 - numeroDeMesPeriodoActual.numeroMes;
                break;
              case 'PEQUEÑA EMPRESA':
                cantidadPeriodosFaltantes =
                  12 - numeroDeMesPeriodoActual.numeroMes + 0.5;
                break;

              default:
                cantidadPeriodosFaltantes =
                  12 - numeroDeMesPeriodoActual.numeroMes + 1; // 1 periodo de gratificacion faltante (Diciembre)
                break;
            }
          }
          //cantidad de periodos faltantes totales
          montoProyeccionQuintaCategoria =
            montoProyeccionQuintaCategoria +
            cantidadPeriodosFaltantes * persona.remuneracion_mensual;

          //verificar si aplica la asignacion familiar para la proyeccion de 5ta
          if (this.cliente.datos_empresa.regimen_laboral !== 'MICRO EMPRESA') {
            if (persona.asignacion_familiar === 'SI') {
              montoProyeccionQuintaCategoria =
                montoProyeccionQuintaCategoria +
                cantidadPeriodosFaltantes *
                  Number(parametroAsignacionFamiliar.monto);
            }
          }
        }

        //verificar las planillas de remuneración del año del periodo para los calculos de 5ta categoría
        this.planillasRemuneracionDelAnioDelPeriodo.map((planilla) => {
          const mesPlanilla = planilla.periodo.split('-')[0];
          const numeroDeMesPlanillaActual = this.mesesDelAnio.find(
            (mes) => mes.mes === mesPlanilla
          );
          //no considerar planillas posteriores a la que se está calculando para no afectar los cálculos de renta de 5ta
          if (
            numeroDeMesPlanillaActual.numeroMes >
            numeroDeMesPeriodoActual.numeroMes
          ) {
            return planilla;
          }

          let personalEncontrado = planilla.personal.find(
            (trabajador) => trabajador.doi.toString() === persona.doi.toString()
          );

          if (personalEncontrado) {
            personalEncontrado.ingresos_por_concepto.map((ingreso) => {
              //buscar conceptos afectos a quinta categoría
              let ingresoAfectoAQuintaCategoria = this.conceptosSunat.find(
                (concepto) =>
                  concepto.codigo === ingreso.codigo &&
                  concepto.afectacion_trabajador.quinta_categoria === 'SI'
                // && ingreso.codigo !== '0121'
              );
              if (ingresoAfectoAQuintaCategoria)
                montoTotalQuintaCategoria =
                  montoTotalQuintaCategoria + ingreso.monto;
              return ingreso;
            });

            //obtener monto ya descontado por quinta categoría en periodos anteriores
            personalEncontrado.descuentos_por_concepto.map((descuento) => {
              if (descuento.codigo === '0605')
                montoDescontadoQuintaCategoria =
                  montoDescontadoQuintaCategoria + descuento.monto;
              return descuento;
            });
          }
          return planilla;
        });

        //calcular periodo de gratificación de Julio si es que existe (es decir, a partir de Agosto)
        this.planillaGratificacionJulio.map((planilla) => {
          let personalEncontrado = planilla.personal.find(
            (trabajador) => trabajador.doi.toString() === persona.doi.toString()
          );

          if (personalEncontrado) {
            personalEncontrado.ingresos_por_concepto.map((ingreso) => {
              //buscar conceptos afectos a quinta categoría
              //Calcular el monto de ingresos por el concepto de remuneración
              let ingresoAfectoAQuintaCategoria = this.conceptosSunat.find(
                (concepto) =>
                  concepto.codigo === ingreso.codigo &&
                  concepto.afectacion_trabajador.quinta_categoria === 'SI'
                // && ingreso.codigo !== '0121'
              );
              if (ingresoAfectoAQuintaCategoria)
                montoTotalQuintaCategoria =
                  montoTotalQuintaCategoria + ingreso.monto;
              return ingreso;
            });

            //obtener monto ya descontado por quinta categoría en periodos anteriores
            personalEncontrado.descuentos_por_concepto.map((descuento) => {
              if (descuento.codigo === '0605')
                montoDescontadoQuintaCategoria =
                  montoDescontadoQuintaCategoria + descuento.monto;
              return descuento;
            });
          }
          return planilla;
        });

        //verificar las planillas de vacaciones generadas en el año para buscar el monto de ingresos afectos a 5ta cat
        this.planillasVacacionesDelAnioDelPeriodo.map((planilla) => {
          const mesPlanilla = planilla.periodo.split('-')[0];
          const numeroDeMesPlanillaActual = this.mesesDelAnio.find(
            (mes) => mes.mes === mesPlanilla
          );
          //no considerar planillas posteriores a la que se está calculando para no afectar los cálculos de renta de 5ta
          if (
            numeroDeMesPlanillaActual.numeroMes >
            numeroDeMesPeriodoActual.numeroMes
          ) {
            return planilla;
          }
          let personalEncontrado = planilla.personal.find(
            (trabajador) => trabajador.doi.toString() === persona.doi.toString()
          );

          if (personalEncontrado) {
            personalEncontrado.ingresos_por_concepto.map((ingreso) => {
              //buscar conceptos afectos a quinta categoría
              //Calcular el monto de ingresos por el concepto de remuneración
              let ingresoAfectoAQuintaCategoria = this.conceptosSunat.find(
                (concepto) =>
                  concepto.codigo === ingreso.codigo &&
                  concepto.afectacion_trabajador.quinta_categoria === 'SI'
                // && ingreso.codigo !== '0121'
              );
              if (ingresoAfectoAQuintaCategoria)
                montoTotalQuintaCategoria =
                  montoTotalQuintaCategoria + ingreso.monto;
              return ingreso;
            });

            //obtener monto ya descontado por quinta categoría en periodos anteriores
            personalEncontrado.descuentos_por_concepto.map((descuento) => {
              if (descuento.codigo === '0605')
                montoDescontadoQuintaCategoria =
                  montoDescontadoQuintaCategoria + descuento.monto;
              return descuento;
            });
          }
          return planilla;
        });

        //verificar la planilla de adelanto de sueldo para agregar todos los ingresos como ADELANTO y considerar los descuentos
        this.planillaAdelantoDelPeriodo.map((planilla) => {
          let personalEncontrado = planilla.personal.find(
            (trabajador) => trabajador.doi.toString() === persona.doi.toString()
          );

          if (personalEncontrado) {
            personalEncontrado.ingresos_por_concepto.map((ingreso) => {
              //buscar concepto ADELANTO
              //buscar si ya existe el codigo del descuento en la persona de la planilla de remuneracion
              let descuentoEncontrado = persona.descuentos_por_concepto.find(
                (descuento) => descuento.codigo === '0701'
              );

              if (descuentoEncontrado) {
                descuentoEncontrado.monto =
                  descuentoEncontrado.monto + ingreso.monto;
              } else {
                persona.descuentos_por_concepto.push({
                  codigo: '0701',
                  monto: ingreso.monto,
                });
              }
              return ingreso;
            });

            personalEncontrado.descuentos_por_concepto.map(
              (descuentoDelAdelanto) => {
                //buscar si ya existe el codigo del descuento en la persona de la planilla de remuneracion
                let descuentoEncontrado = persona.descuentos_por_concepto.find(
                  (descuento) => descuento.codigo === '0701'
                );

                if (descuentoEncontrado) {
                  descuentoEncontrado.monto =
                    descuentoEncontrado.monto - descuentoDelAdelanto.monto;
                } else {
                  persona.descuentos_por_concepto.push({
                    codigo: '0701',
                    monto: descuentoDelAdelanto.monto,
                  });
                }
                return descuentoDelAdelanto;
              }
            );
          }
          return planilla;
        });

        //verificar si la persona tiene ingresos fijos afectos a 5ta
        if (
          Number(persona.monto_ingreso_fijo1) > 0 &&
          persona.concepto_ingreso_fijo1 !== ''
        ) {
          //verificar si el ingreso fijo está afecto a la renta de 5ta categoría para considerarlo en la proyección
          let ingresoAfectoAQuintaCategoria = this.conceptosSunat.find(
            (concepto) =>
              concepto.nombre === persona.concepto_ingreso_fijo1 &&
              concepto.afectacion_trabajador.quinta_categoria === 'SI'
          );
          if (ingresoAfectoAQuintaCategoria)
            montoProyeccionQuintaCategoria =
              montoProyeccionQuintaCategoria +
              cantidadPeriodosFaltantes * Number(persona.monto_ingreso_fijo1);
        }
        if (
          Number(persona.monto_ingreso_fijo2) > 0 &&
          persona.concepto_ingreso_fijo2 !== ''
        ) {
          let ingresoAfectoAQuintaCategoria = this.conceptosSunat.find(
            (concepto) =>
              concepto.nombre === persona.concepto_ingreso_fijo2 &&
              concepto.afectacion_trabajador.quinta_categoria === 'SI'
          );
          if (ingresoAfectoAQuintaCategoria)
            montoProyeccionQuintaCategoria =
              montoProyeccionQuintaCategoria +
              cantidadPeriodosFaltantes * Number(persona.monto_ingreso_fijo2);
        }
        if (
          Number(persona.monto_ingreso_fijo3) > 0 &&
          persona.concepto_ingreso_fijo3 !== ''
        ) {
          let ingresoAfectoAQuintaCategoria = this.conceptosSunat.find(
            (concepto) =>
              concepto.nombre === persona.concepto_ingreso_fijo3 &&
              concepto.afectacion_trabajador.quinta_categoria === 'SI'
          );
          if (ingresoAfectoAQuintaCategoria)
            montoProyeccionQuintaCategoria =
              montoProyeccionQuintaCategoria +
              cantidadPeriodosFaltantes * Number(persona.monto_ingreso_fijo3);
        }

        //sumar el monto del periodo actual al monto ya calculado de ingresos afectos a quinta categoría
        montoTotalQuintaCategoria =
          montoTotalQuintaCategoria + montoTotalIngresosAfectosQuintaCategoria;

        //validar contra fecha de ingreso si se debe considerar el monto de ingresos por concepto de trabajo anterior
        const anioIngresoTrabajador = moment(persona.fecha_ingreso).format(
          'YYYY'
        );
        const anioPeriodo = this.nuevaPlanillaCreada.periodo.split('-')[1];
        if (anioIngresoTrabajador === anioPeriodo) {
          if (Number(persona.monto_descuento_quinta_trabajo_anterior) > 0) {
            montoDescontadoQuintaCategoria =
              montoDescontadoQuintaCategoria +
              Number(persona.monto_descuento_quinta_trabajo_anterior);
          }
          if (Number(persona.monto_ingresos_trabajo_anterior) > 0) {
            montoTotalQuintaCategoria =
              montoTotalQuintaCategoria +
              Number(persona.monto_ingresos_trabajo_anterior);
          }
        }

        const montoTotalACalcularPorQuintaCategoria =
          montoTotalQuintaCategoria + montoProyeccionQuintaCategoria;
        //aplicar reglas para cálculo de renta de 5ta cat en base a la UIT (parámetro) y obtener el valor a descontar este mes
        if (
          montoTotalACalcularPorQuintaCategoria >
          7 * Number(parametroUIT.monto)
        ) {
          let montoADescontarPorQuintaCategoria = 0;
          //si el excedente > 5UIT
          let montoExcedente =
            montoTotalACalcularPorQuintaCategoria -
            7 * Number(parametroUIT.monto);

          if (montoExcedente <= 5 * Number(parametroUIT.monto)) {
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria + montoExcedente * 0.08;
          }
          if (
            montoExcedente > 5 * Number(parametroUIT.monto) &&
            montoExcedente <= 20 * Number(parametroUIT.monto)
          ) {
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              5 * Number(parametroUIT.monto) * 0.08;
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              (montoExcedente - 5 * Number(parametroUIT.monto)) * 0.14;
          }
          if (
            montoExcedente > 20 * Number(parametroUIT.monto) &&
            montoExcedente <= 35 * Number(parametroUIT.monto)
          ) {
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              5 * Number(parametroUIT.monto) * 0.08;
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              15 * Number(parametroUIT.monto) * 0.14;
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              (montoExcedente - 20 * Number(parametroUIT.monto)) * 0.17;
          }
          if (
            montoExcedente > 35 * Number(parametroUIT.monto) &&
            montoExcedente <= 45 * Number(parametroUIT.monto)
          ) {
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              5 * Number(parametroUIT.monto) * 0.08;
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              15 * Number(parametroUIT.monto) * 0.14;
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              15 * Number(parametroUIT.monto) * 0.17;
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              (montoExcedente - 35 * Number(parametroUIT.monto)) * 0.2;
          }
          if (montoExcedente > 45 * Number(parametroUIT.monto)) {
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              5 * Number(parametroUIT.monto) * 0.08;
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              15 * Number(parametroUIT.monto) * 0.14;
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              15 * Number(parametroUIT.monto) * 0.17;
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              10 * Number(parametroUIT.monto) * 0.2;
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              (montoExcedente - 45 * Number(parametroUIT.monto)) * 0.3;
          }

          montoADescontarPorQuintaCategoria =
            montoADescontarPorQuintaCategoria - montoDescontadoQuintaCategoria;

          let montoADescontarPorQuintaPeriodoActual = 0;

          if (cantidadPeriodosFaltantes > 0) {
            montoADescontarPorQuintaPeriodoActual =
              montoADescontarPorQuintaCategoria /
              (cantidadPeriodosFaltantes + 1); // se suma una unidad para incluir el mes actual en el periodo de evaluación
          }

          //concepto 605 para descuentos por renta de quinta
          let descuentoQuintaEncontrado = persona.descuentos_por_concepto.find(
            (descuento) => descuento.codigo === '0605'
          );
          if (descuentoQuintaEncontrado) {
            descuentoQuintaEncontrado.monto =
              descuentoQuintaEncontrado.monto +
              montoADescontarPorQuintaPeriodoActual;
          } else {
            persona.descuentos_por_concepto.push({
              codigo: '0605',
              monto: montoADescontarPorQuintaPeriodoActual,
            });
          }
        }
        //validar si la persona cuenta con descuentos/préstamos a ser aplicados en esta planilla
        this.procesarPrestamosDelTrabajador(persona);
        return persona;
      });

      this.prepararGraficos();
    },
    // async procesarPlanillaCTS() {
    //   console.log('procesar planilla CTS');
    //   //0904
    // },
    async procesarPlanillaGratificacion() {
      //(sueldo + AF + otros ingresos) + bonif extraord. - renta 5ta
      const mesPeriodoPlanilla = this.nuevaPlanillaCreada.periodo.split('-')[0];
      const anioPeriodo = this.nuevaPlanillaCreada.periodo.split('-')[1];

      const parametroAsignacionFamiliar = this.cliente.datos_empresa.parametros.find(
        (parametro) => parametro.concepto === 'ASIGNACION FAMILIAR'
      );
      const parametroUIT = this.cliente.datos_empresa.parametros.find(
        (parametro) => parametro.concepto === 'UIT'
      );
      const parametroEsSalud = this.cliente.datos_empresa.parametros.find(
        (parametro) => parametro.concepto === 'ESSALUD'
      );
      //variable para calcular monto de la proyección de 5ta categoría por los periodos que faltan hasta terminar el año
      let cantidadPeriodosFaltantes = 0;
      //Calcular cantidad de días que se calcularán en el período seleccionado
      // let fechaInicio = moment(`01-01-${anioPeriodo}`, 'MM-DD-YYYY');
      // let fechaFin = moment(`06-30-${anioPeriodo}`, 'MM-DD-YYYY');

      if (mesPeriodoPlanilla === 'Julio') {
        cantidadPeriodosFaltantes = 7; // Si es Julio, faltan 6 planillas de remuneración + 1 de gratificación... si es Dic, queda en 0
        this.planillaGratificacionJulio = [];
      } else {
        await this.obtenerPlanillaGratificacionJulio();
        // fechaInicio = moment(`07-01-${anioPeriodo}`, 'MM-DD-YYYY');
        // fechaFin = moment(`12-31-${anioPeriodo}`, 'MM-DD-YYYY');
      }

      // const cantidadDiasPeriodoGeneral = fechaFin.diff(fechaInicio, 'days') + 1;

      this.nuevaPlanillaCreada.personal.map((persona) => {
        let montoTotalIngresosAfectosQuintaCategoria = 0;
        let montoProyeccionQuintaCategoria = 0;
        //inicializar contador de monto ya descontado por quinta categoría períodos anteriores
        let montoDescontadoQuintaCategoria = 0;
        let montoTotalQuintaCategoria = 0;
        // buscar ingresos afectos a 5ta Categoria sin contemplar la remuneración mensual de esta planilla
        //(básicamente lo agregado manualmente en el paso anterior)
        persona.ingresos_por_concepto.map((ingreso) => {
          let ingresoAfectoAQuintaCategoria = this.conceptosSunat.find(
            (concepto) =>
              concepto.codigo === ingreso.codigo &&
              concepto.afectacion_trabajador.quinta_categoria === 'SI'
            //  &&
            // ingreso.codigo !== '0121' &&
            // ingreso.codigo !== '0407'
          );
          if (ingresoAfectoAQuintaCategoria)
            montoTotalIngresosAfectosQuintaCategoria =
              montoTotalIngresosAfectosQuintaCategoria + ingreso.monto;
          return ingreso;
        });

        // verificar la fecha de ignreso del trabajador para considerar mes completo para el pago
        this.planillasRemuneracionDelAnioDelPeriodo.map((planilla) => {
          //falta aplicar filtro para no traer meses posteriores a Julio por ejemplo
          let personalEncontrado = planilla.personal.find(
            (trabajador) => trabajador.doi.toString() === persona.doi.toString()
          );

          if (
            personalEncontrado &&
            personalEncontrado.cantidad_dias_efectivos_beneficios_sociales
          ) {
            personalEncontrado.ingresos_por_concepto.map((ingreso) => {
              //buscar conceptos afectos a quinta categoría incluyendo la remuneración
              //Calcular el monto de ingresos por el concepto de remuneración
              let ingresoAfectoAQuintaCategoria = this.conceptosSunat.find(
                (concepto) =>
                  concepto.codigo === ingreso.codigo &&
                  concepto.afectacion_trabajador.quinta_categoria === 'SI'
                // && ingreso.codigo !== '0121'
              );
              if (ingresoAfectoAQuintaCategoria)
                montoTotalQuintaCategoria =
                  montoTotalQuintaCategoria + ingreso.monto;
              return ingreso;
            });

            //obtener monto ya descontado por quinta categoría en periodos anteriores
            personalEncontrado.descuentos_por_concepto.map((descuento) => {
              if (descuento.codigo === '0605')
                montoDescontadoQuintaCategoria =
                  montoDescontadoQuintaCategoria + descuento.monto;
              return descuento;
            });
          }
          return planilla;
        });

        //verificar las planillas de vacaciones generadas en el año para buscar el monto de ingresos afectos a 5ta cat
        this.planillasVacacionesDelAnioDelPeriodo.map((planilla) => {
          // console.log(planilla);
          let personalEncontrado = planilla.personal.find(
            (trabajador) => trabajador.doi.toString() === persona.doi.toString()
          );

          if (personalEncontrado) {
            // console.log(personalEncontrado);
            personalEncontrado.ingresos_por_concepto.map((ingreso) => {
              //buscar conceptos afectos a quinta categoría
              //Calcular el monto de ingresos por el concepto de remuneración
              let ingresoAfectoAQuintaCategoria = this.conceptosSunat.find(
                (concepto) =>
                  concepto.codigo === ingreso.codigo &&
                  concepto.afectacion_trabajador.quinta_categoria === 'SI'
                // && ingreso.codigo !== '0121'
              );
              if (ingresoAfectoAQuintaCategoria)
                montoTotalQuintaCategoria =
                  montoTotalQuintaCategoria + ingreso.monto;
              return ingreso;
            });

            //obtener monto ya descontado por quinta categoría en periodos anteriores
            personalEncontrado.descuentos_por_concepto.map((descuento) => {
              if (descuento.codigo === '0605')
                montoDescontadoQuintaCategoria =
                  montoDescontadoQuintaCategoria + descuento.monto;
              return descuento;
            });
          }
          return planilla;
        });

        //verificar la planilla de gratificación de Julio generada en el año para buscar el monto de ingresos afectos a 5ta cat
        this.planillaGratificacionJulio.map((planilla) => {
          let personalEncontrado = planilla.personal.find(
            (trabajador) => trabajador.doi.toString() === persona.doi.toString()
          );

          if (
            personalEncontrado
            // &&
            // personalEncontrado.cantidad_dias_efectivos_beneficios_sociales
          ) {
            personalEncontrado.ingresos_por_concepto.map((ingreso) => {
              //buscar conceptos afectos a quinta categoría incluyendo la remuneración
              //Calcular el monto de ingresos por el concepto de remuneración
              let ingresoAfectoAQuintaCategoria = this.conceptosSunat.find(
                (concepto) =>
                  concepto.codigo === ingreso.codigo &&
                  concepto.afectacion_trabajador.quinta_categoria === 'SI'
                // && ingreso.codigo !== '0121'
              );
              if (ingresoAfectoAQuintaCategoria)
                montoTotalQuintaCategoria =
                  montoTotalQuintaCategoria + ingreso.monto;
              return ingreso;
            });

            //obtener monto ya descontado por quinta categoría en periodos anteriores
            personalEncontrado.descuentos_por_concepto.map((descuento) => {
              if (descuento.codigo === '0605')
                montoDescontadoQuintaCategoria =
                  montoDescontadoQuintaCategoria + descuento.monto;
              return descuento;
            });
          }
          return planilla;
        });

        //Calcular el monto de ingreso máximo a pagar por el concepto de remuneración - sueldo
        if (persona.cantidad_dias_efectivos_total) {
          montoTotalIngresosAfectosQuintaCategoria =
            montoTotalIngresosAfectosQuintaCategoria +
            (Number(persona.remuneracion_mensual) / 180) *
              persona.cantidad_dias_efectivos_total;
          //agregar monto proyecto por remuneración
          montoProyeccionQuintaCategoria =
            montoProyeccionQuintaCategoria +
            cantidadPeriodosFaltantes * persona.remuneracion_mensual;
        }

        //Calcular el monto de ingreso máximo a pagar por el concepto de asignacion familiar - AF
        if (this.cliente.datos_empresa.regimen_laboral !== 'MICRO EMPRESA') {
          if (persona.asignacion_familiar === 'SI') {
            //agregar monto de AF a los ingresos de este periodo
            montoTotalIngresosAfectosQuintaCategoria =
              montoTotalIngresosAfectosQuintaCategoria +
              (Number(parametroAsignacionFamiliar.monto) / 180) *
                persona.cantidad_dias_efectivos_total;
            //agregar monto de AF a la proyección
            montoProyeccionQuintaCategoria =
              montoProyeccionQuintaCategoria +
              cantidadPeriodosFaltantes *
                Number(parametroAsignacionFamiliar.monto);
          }
        }
        // Calcular el monto de ingreso máximo a pagar por el concepto de montos FIJOS

        //aqui se suma (sueldo + AF + ingresos fijos) + bonif grati
        montoTotalIngresosAfectosQuintaCategoria =
          montoTotalIngresosAfectosQuintaCategoria +
          montoTotalIngresosAfectosQuintaCategoria *
            Number(parametroEsSalud.porcentaje);

        //verificar si la persona tiene ingresos fijos para la proyección unicamente ya que lo pagado está calculado anteriormente en planillas de remuneración
        if (
          Number(persona.monto_ingreso_fijo1) > 0 &&
          persona.concepto_ingreso_fijo1 !== ''
        ) {
          let ingresoAfectoAQuintaCategoria = this.conceptosSunat.find(
            (concepto) =>
              concepto.nombre === persona.concepto_ingreso_fijo1 &&
              concepto.afectacion_trabajador.quinta_categoria === 'SI'
          );
          if (ingresoAfectoAQuintaCategoria)
            montoProyeccionQuintaCategoria =
              montoProyeccionQuintaCategoria +
              cantidadPeriodosFaltantes * Number(persona.monto_ingreso_fijo1);
        }
        if (
          Number(persona.monto_ingreso_fijo2) > 0 &&
          persona.concepto_ingreso_fijo2 !== ''
        ) {
          let ingresoAfectoAQuintaCategoria = this.conceptosSunat.find(
            (concepto) =>
              concepto.nombre === persona.concepto_ingreso_fijo2 &&
              concepto.afectacion_trabajador.quinta_categoria === 'SI'
          );
          if (ingresoAfectoAQuintaCategoria)
            montoProyeccionQuintaCategoria =
              montoProyeccionQuintaCategoria +
              cantidadPeriodosFaltantes * Number(persona.monto_ingreso_fijo2);
        }
        if (
          Number(persona.monto_ingreso_fijo3) > 0 &&
          persona.concepto_ingreso_fijo3 !== ''
        ) {
          let ingresoAfectoAQuintaCategoria = this.conceptosSunat.find(
            (concepto) =>
              concepto.nombre === persona.concepto_ingreso_fijo3 &&
              concepto.afectacion_trabajador.quinta_categoria === 'SI'
          );
          if (ingresoAfectoAQuintaCategoria)
            montoProyeccionQuintaCategoria =
              montoProyeccionQuintaCategoria +
              cantidadPeriodosFaltantes * Number(persona.monto_ingreso_fijo3);
        }
        //sumnar el monto del periodo actual al monto ya calculado de ingresos afectos a quinta categoría
        montoTotalQuintaCategoria =
          montoTotalQuintaCategoria + montoTotalIngresosAfectosQuintaCategoria;

        //validar contra fecha de ingreso si se debe considerar el monto de ingresos por concepto de trabajo anterior
        const anioIngresoTrabajador = moment(persona.fecha_ingreso).format(
          'YYYY'
        );
        // const anioPeriodo = this.nuevaPlanillaCreada.periodo.split('-')[1];

        if (anioIngresoTrabajador === anioPeriodo) {
          if (Number(persona.monto_descuento_quinta_trabajo_anterior) > 0) {
            montoDescontadoQuintaCategoria =
              montoDescontadoQuintaCategoria +
              Number(persona.monto_descuento_quinta_trabajo_anterior);
          }
          if (Number(persona.monto_ingresos_trabajo_anterior) > 0) {
            montoTotalQuintaCategoria =
              montoTotalQuintaCategoria +
              Number(persona.monto_ingresos_trabajo_anterior);
          }
        }

        let montoTotalACalcularPorQuintaCategoria =
          montoTotalQuintaCategoria + montoProyeccionQuintaCategoria;
        //aplicar reglas para cálculo de renta de 5ta cat en base a la UIT (parámetro) y obtener el valor a descontar este mes
        if (
          montoTotalACalcularPorQuintaCategoria >
          7 * Number(parametroUIT.monto)
        ) {
          let montoADescontarPorQuintaCategoria = 0;
          //si el excedente > 5UIT
          let montoExcedente =
            montoTotalACalcularPorQuintaCategoria -
            7 * Number(parametroUIT.monto);
          if (montoExcedente <= 5 * Number(parametroUIT.monto)) {
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria + montoExcedente * 0.08;
          }
          // console.log(montoADescontarPorQuintaCategoria);
          if (
            montoExcedente > 5 * Number(parametroUIT.monto) &&
            montoExcedente <= 20 * Number(parametroUIT.monto)
          ) {
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              5 * Number(parametroUIT.monto) * 0.08;
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              (montoExcedente - 5 * Number(parametroUIT.monto)) * 0.14;
            // console.log(montoADescontarPorQuintaCategoria);
          }
          if (
            montoExcedente > 20 * Number(parametroUIT.monto) &&
            montoExcedente <= 35 * Number(parametroUIT.monto)
          ) {
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              5 * Number(parametroUIT.monto) * 0.08;
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              15 * Number(parametroUIT.monto) * 0.14;
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              (montoExcedente - 20 * Number(parametroUIT.monto)) * 0.17;
          }
          if (
            montoExcedente > 35 * Number(parametroUIT.monto) &&
            montoExcedente <= 45 * Number(parametroUIT.monto)
          ) {
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              5 * Number(parametroUIT.monto) * 0.08;
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              15 * Number(parametroUIT.monto) * 0.14;
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              15 * Number(parametroUIT.monto) * 0.17;
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              (montoExcedente - 35 * Number(parametroUIT.monto)) * 0.2;
          }
          if (montoExcedente > 45 * Number(parametroUIT.monto)) {
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              5 * Number(parametroUIT.monto) * 0.08;
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              15 * Number(parametroUIT.monto) * 0.14;
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              15 * Number(parametroUIT.monto) * 0.17;
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              10 * Number(parametroUIT.monto) * 0.2;
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              (montoExcedente - 45 * Number(parametroUIT.monto)) * 0.3;
          }

          montoADescontarPorQuintaCategoria =
            montoADescontarPorQuintaCategoria - montoDescontadoQuintaCategoria;
          if (montoADescontarPorQuintaCategoria < 0) {
            montoADescontarPorQuintaCategoria = 0;
          }

          let montoADescontarPorQuintaPeriodoActual = 0;

          if (cantidadPeriodosFaltantes > 0) {
            montoADescontarPorQuintaPeriodoActual =
              montoADescontarPorQuintaCategoria / cantidadPeriodosFaltantes;
          }
          // console.log(cantidadPeriodosFaltantes);
          //concepto 605 para descuentos por renta de quinta
          let descuentoQuintaEncontrado = persona.descuentos_por_concepto.find(
            (descuento) => descuento.codigo === '0605'
          );
          if (descuentoQuintaEncontrado) {
            descuentoQuintaEncontrado.monto =
              descuentoQuintaEncontrado.monto +
              montoADescontarPorQuintaPeriodoActual;
          } else {
            persona.descuentos_por_concepto.push({
              codigo: '0605',
              monto: montoADescontarPorQuintaPeriodoActual,
            });
          }
        }

        //validar si la persona cuenta con descuentos/préstamos a ser aplicados en esta planilla
        this.procesarPrestamosDelTrabajador(persona);
        return persona;
      });
      // console.log(this.nuevaPlanillaCreada);

      this.showLoadingDialog = false;
    },
    async procesarPlanillaLiquidacion() {
      this.procesarDatosParaTabla();
    },
    // async procesarPlanillaUtilidades() {
    //   console.log('procesar planilla Utilidades');
    //   //911
    // },
    procesarPrestamosDelTrabajador(persona) {
      let prestamosVigentes = [];
      prestamosVigentes = persona.creditos.filter(
        (credito) =>
          credito.pagado === 'No' &&
          credito.aprobado === 'Sí' &&
          credito.planilla_descuento_cuota.includes(
            this.nuevaPlanillaCreada.tipo_proceso
          )
      );
      if (prestamosVigentes.length > 0) {
        prestamosVigentes.map((prestamo) => {
          //obtener fecha de inicio de periodo a descontar
          const fechaMinimaInicioDescuento = moment(
            prestamo.periodo_inicio_descuento,
            'YYYY-MM'
          )
            .tz('America/Lima')
            .startOf('month');
          //obtener periodo de esta planilla q se está procesando
          const fechaInicioPlanilla = moment(
            this.nuevaPlanillaCreada.fecha_inicio
          ).tz('America/Lima');
          if (fechaInicioPlanilla >= fechaMinimaInicioDescuento) {
            if (prestamo.total_cuotas > prestamo.cuotas_pagadas) {
              prestamo.cuotas_pagadas++;
              // variable identificadora para actualizar el registro de préstamos en la base de datos al guardar la planilla
              persona.actualizarCreditos = true;
              if (prestamo.cuotas_pagadas === prestamo.total_cuotas) {
                prestamo.pagado = 'Sí';
              }
              //agregar el descuento en persona.descuentos_por_concepto
              let montoCuota = Number(prestamo.monto / prestamo.total_cuotas);

              if (this.nuevaPlanillaCreada.tipo_proceso === 'Liquidación') {
                prestamo.cuotas_pagadas = prestamo.total_cuotas;
                montoCuota = Number(
                  prestamo.monto -
                    (prestamo.monto / prestamo.total_cuotas) *
                      prestamo.cuotas_pagadas
                );
                prestamo.pagado = 'Sí';
              }

              //concepto 0701 para descuentos por adelantos
              let descuentoPorAdelanto = persona.descuentos_por_concepto.find(
                (descuento) => descuento.codigo === '0701'
              );
              if (descuentoPorAdelanto) {
                descuentoPorAdelanto.monto =
                  descuentoPorAdelanto.monto + montoCuota;
              } else {
                persona.descuentos_por_concepto.push({
                  codigo: '0701',
                  monto: montoCuota,
                });
              }
            }
          }
          return prestamo;
        });
      }
      return persona;
    },
    async procesarPlanillaVacaciones() {
      this.procesarDatosParaTabla();
    },
    async procesarPlanillaLocacionDeServicio() {
      this.tableLoading = true;
      this.nuevaPlanillaCreada.personal.map((persona) => {
        //verificar la planilla de adelanto de sueldo para agregar todos los ingresos como ADELANTO y considerar los descuentos
        // sumar todos los ingresos y restarle los descuentos y sólo subir un concepto 0701 por el total?
        this.planillaAdelantoDelPeriodo.map((planilla) => {
          let personalEncontrado = planilla.personal.find(
            (trabajador) => trabajador.doi.toString() === persona.doi.toString()
          );

          if (personalEncontrado) {
            personalEncontrado.ingresos_por_concepto.map((ingreso) => {
              //buscar concepto ADELANTO
              //buscar si ya existe el codigo del descuento en la persona de la planilla
              let descuentoEncontrado = persona.descuentos_por_concepto.find(
                (descuento) => descuento.codigo === '0701'
              );

              if (descuentoEncontrado) {
                descuentoEncontrado.monto =
                  descuentoEncontrado.monto + ingreso.monto;
              } else {
                persona.descuentos_por_concepto.push({
                  codigo: '0701',
                  monto: ingreso.monto,
                });
              }
              return ingreso;
            });

            personalEncontrado.descuentos_por_concepto.map(
              (descuentoDelAdelanto) => {
                //buscar si ya existe el codigo del descuento en la persona de la planilla de remuneracion
                let descuentoEncontrado = persona.descuentos_por_concepto.find(
                  (descuento) => descuento.codigo === '0701'
                );

                if (descuentoEncontrado) {
                  descuentoEncontrado.monto =
                    descuentoEncontrado.monto - descuentoDelAdelanto.monto;
                } else {
                  persona.descuentos_por_concepto.push({
                    codigo: '0701',
                    monto: descuentoDelAdelanto.monto,
                  });
                }
                return descuentoDelAdelanto;
              }
            );
          }
          return planilla;
        });

        //validar si la persona cuenta con descuentos/préstamos a ser aplicados en esta planilla
        this.procesarPrestamosDelTrabajador(persona);
        return persona;
      });

      this.showLoadingDialog = false;
      this.tableLoading = false;
      this.renderTable++;
    },
    async procesarPlanillaAdelantoDeSueldo() {
      this.tableLoading = true;

      this.nuevaPlanillaCreada.personal.map((persona) => {
        //validar si la persona cuenta con descuentos/préstamos a ser aplicados en esta planilla
        this.procesarPrestamosDelTrabajador(persona);
        return persona;
      });

      this.showLoadingDialog = false;
      this.tableLoading = false;
      this.renderTable++;
    },
    async obtenerPlanillaGratificacionJulio() {
      const anioPeriodo = this.nuevaPlanillaCreada.periodo.split('-')[1];
      const {
        error,
        planillas,
        tokenExpired,
      } = await getPlanillaPorPeriodoYPorTipo(
        `Julio-${anioPeriodo}`,
        'Gratificación'
      );
      if (error) {
        //do something
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (planillas) {
        this.planillaGratificacionJulio = planillas;
      }
    },
    async procesarDatosDelosGraficos(planillas) {
      let totalMontoONPPeriodoAnterior = 0;
      let totalMontoComisionPeriodoAnterior = 0;
      let totalMontoAporteObligatorioPeriodoAnterior = 0;
      let totalMontoPrimaPeriodoAnterior = 0;
      let periodoPlanillaAnterior = '';

      planillas.map((planillaPeriodoAnterior) => {
        periodoPlanillaAnterior = planillaPeriodoAnterior.periodo;

        planillaPeriodoAnterior.personal.map((persona) => {
          //Buscar descuento por ONP
          let descuentoONP = persona.descuentos_por_concepto.find(
            (descuento) => descuento.codigo === '0607'
          );
          let montoONP = 0;
          if (descuentoONP) {
            montoONP = descuentoONP.monto;
          }

          totalMontoONPPeriodoAnterior =
            totalMontoONPPeriodoAnterior + montoONP;

          //Buscar descuento por Comision AFP
          let descuentoComision = persona.descuentos_por_concepto.find(
            (descuento) => descuento.codigo === '0601'
          );
          let montoComision = 0;
          if (descuentoComision) {
            montoComision = descuentoComision.monto;
          }

          totalMontoComisionPeriodoAnterior =
            totalMontoComisionPeriodoAnterior + montoComision;

          //Buscar descuento por Aporte obligatorio AFP
          let descuentoAporteObligatorio = persona.descuentos_por_concepto.find(
            (descuento) => descuento.codigo === '0608'
          );
          let montoAporteObligatorio = 0;
          if (descuentoAporteObligatorio) {
            montoAporteObligatorio = descuentoAporteObligatorio.monto;
          }

          totalMontoAporteObligatorioPeriodoAnterior =
            totalMontoAporteObligatorioPeriodoAnterior + montoAporteObligatorio;

          //Buscar descuento por prima AFP
          let descuentoPrimaAFP = persona.descuentos_por_concepto.find(
            (descuento) => descuento.codigo === '0606'
          );
          let montoPrimaAFP = 0;
          if (descuentoPrimaAFP) {
            montoPrimaAFP = descuentoPrimaAFP.monto;
          }

          totalMontoPrimaPeriodoAnterior =
            totalMontoPrimaPeriodoAnterior + montoPrimaAFP;

          return persona;
        });

        totalMontoONPPeriodoAnterior =
          Math.round(totalMontoONPPeriodoAnterior * 100) / 100;

        totalMontoComisionPeriodoAnterior =
          Math.round(totalMontoComisionPeriodoAnterior * 100) / 100;

        totalMontoAporteObligatorioPeriodoAnterior =
          Math.round(totalMontoAporteObligatorioPeriodoAnterior * 100) / 100;

        totalMontoPrimaPeriodoAnterior =
          Math.round(totalMontoPrimaPeriodoAnterior * 100) / 100;

        return planillaPeriodoAnterior;
      });

      const nuevoGraficoComisionAFP = {
        name: periodoPlanillaAnterior,
        type: 'column',
        data: [totalMontoComisionPeriodoAnterior],
      };
      const nuevoGraficoAporteObligatorio = {
        name: periodoPlanillaAnterior,
        type: 'column',
        data: [totalMontoAporteObligatorioPeriodoAnterior],
      };
      const nuevoGraficoPrimaSeguro = {
        name: periodoPlanillaAnterior,
        type: 'column',
        data: [totalMontoPrimaPeriodoAnterior],
      };
      const nuevoGraficoONP = {
        name: periodoPlanillaAnterior,
        type: 'column',
        data: [totalMontoONPPeriodoAnterior],
      };

      this.seriesTotalComisionesPensiones.unshift(nuevoGraficoComisionAFP);
      this.seriesTotalAporteObligatorioPensiones.unshift(
        nuevoGraficoAporteObligatorio
      );

      this.seriesTotalPrimaSeguroPensiones.unshift(nuevoGraficoPrimaSeguro);

      this.seriesMontoONP.unshift(nuevoGraficoONP);
    },
    async procesarDatosParaTabla() {
      const parametroAsignacionFamiliar = this.cliente.datos_empresa.parametros.find(
        (parametro) => parametro.concepto === 'ASIGNACION FAMILIAR'
      );
      const parametroUIT = this.cliente.datos_empresa.parametros.find(
        (parametro) => parametro.concepto === 'UIT'
      );
      const { sistema_pensiones } = this.cliente.datos_empresa;

      const mesPeriodo = this.nuevaPlanillaCreada.periodo.split('-')[0];
      const numeroDeMesPeriodoActual = this.mesesDelAnio.find(
        (mes) => mes.mes === mesPeriodo
      );

      //calcular monto de la proyección por los periodos que faltan hasta terminar el año sumando los periodos de gratificación
      if (numeroDeMesPeriodoActual.numeroMes > 7) {
        //si se está calculando las planillas posteriores a Julio, entonces se debe obtener la planilla de gratificación de Julio
        await this.obtenerPlanillaGratificacionJulio();
      }

      this.nuevaPlanillaCreada.personal.map((persona) => {
        //calculo de planilla por horas
        // const montoRemuneracionDiaria =
        //   Number(persona.remuneracion_mensual) / 30;
        //         persona.info_dias.map(dia => {
        //           //aplicar cálculo de tardanza si el cálculo de la planilla es por horas

        //           // if (this.nuevaPlanillaCreada.tipo_calculo === 'horas'){
        //           //   //calcular el monto a descontar por tardanza
        //           //   if (dia.tipo_jornada === 'Tardanza'){
        //           //     ///buscar por este concepto en persona.descuentos_por_concepto y si no existe hacer push, sino calcular y sumar el monto (de jornada diaria + asig familiar???)
        //           //   }
        //           // }

        //  calcular ingresos afectos a SNP / AFP
        let montoTotalIngresosAfectosPension = 0;
        let montoTotalIngresosAfectosQuintaCategoria = 0;
        // calcular montos de ingresos afectos a pensión y 5ta categoría
        persona.ingresos_por_concepto.map((ingreso) => {
          //Calcular ingresos afectos a 5ta Categoria
          const ingresoAfectoAQuintaCategoria = this.conceptosSunat.find(
            (concepto) =>
              concepto.codigo === ingreso.codigo &&
              concepto.afectacion_trabajador.quinta_categoria === 'SI'
            // &&
            // ingreso.codigo !== '0121'
          );
          if (ingresoAfectoAQuintaCategoria)
            montoTotalIngresosAfectosQuintaCategoria =
              montoTotalIngresosAfectosQuintaCategoria + ingreso.monto;

          //Calcular ingresos afectos a pension
          // if (pensionEsONP) {
          const ingresoAfectoAPension = this.conceptosSunat.find(
            (concepto) =>
              concepto.codigo === ingreso.codigo &&
              (concepto.afectacion_trabajador.snp === 'SI' ||
                concepto.afectacion_trabajador.afp === 'SI')
          );
          if (ingresoAfectoAPension)
            montoTotalIngresosAfectosPension =
              montoTotalIngresosAfectosPension + ingreso.monto;
          return ingreso;
          // }
        });

        const pensionEsONP = persona.pension === 'ONP' ? true : false;
        if (pensionEsONP) {
          const pension = sistema_pensiones.find(
            (pension) => pension.concepto === 'ONP'
          );
          if (pension) {
            let montoPorONP =
              // (
              montoTotalIngresosAfectosPension *
              Number(pension.aporte_obligatorio);

            let descuentoEncontrado = persona.descuentos_por_concepto.find(
              (descuento) => descuento.codigo === '0607'
            );
            if (descuentoEncontrado) {
              descuentoEncontrado.monto =
                descuentoEncontrado.monto + montoPorONP;
            } else {
              persona.descuentos_por_concepto.push({
                codigo: '0607',
                monto: montoPorONP,
              });
            }
          }
        } else {
          let pension = sistema_pensiones.find(
            (pension) => pension.concepto === persona.nombre_afp
          );
          if (pension) {
            let montoAportesObligatorios =
              // (
              montoTotalIngresosAfectosPension *
              Number(pension.aporte_obligatorio);
            //   /
            //   cantidadDiasPeriodoPlanilla) *
            // persona.cantidadDiasVacaciones;
            let comisionAFP =
              persona.tipo_comision_afp === 'Flujo'
                ? pension.comision_flujo
                : pension.comision_mixta;
            let montoComision =
              // (
              montoTotalIngresosAfectosPension * Number(comisionAFP);
            // / cantidadDiasPeriodoPlanilla) *
            // persona.cantidadDiasVacaciones;
            //calcular prima de seguro en base a total de remunueracions de varios procesos
            let montoPrimaSeguro =
              // (
              montoTotalIngresosAfectosPension * Number(pension.prima_seguro);
            //   /
            //   cantidadDiasPeriodoPlanilla) *
            // persona.cantidadDiasVacaciones;

            //agregar concepto 608 - aportes obligatorios
            let aporteObligatorioEncontrado = persona.descuentos_por_concepto.find(
              (descuento) => descuento.codigo === '0608'
            );
            if (aporteObligatorioEncontrado) {
              aporteObligatorioEncontrado.monto =
                aporteObligatorioEncontrado.monto + montoAportesObligatorios;
            } else {
              persona.descuentos_por_concepto.push({
                codigo: '0608',
                monto: montoAportesObligatorios,
              });
            }

            //agregar concepto 601 - comision afp
            let comisionAFPEncontrado = persona.descuentos_por_concepto.find(
              (descuento) => descuento.codigo === '0601'
            );
            if (comisionAFPEncontrado) {
              comisionAFPEncontrado.monto =
                comisionAFPEncontrado.monto + montoComision;
            } else {
              persona.descuentos_por_concepto.push({
                codigo: '0601',
                monto: montoComision,
              });
            }

            //agregar concepto 606 - prima seguro - calcular remuneración máxima asegurable
            let primaSeguroEncontrado = persona.descuentos_por_concepto.find(
              (descuento) => descuento.codigo === '0606'
            );
            if (primaSeguroEncontrado) {
              primaSeguroEncontrado.monto =
                primaSeguroEncontrado.monto + montoPrimaSeguro;
            } else {
              persona.descuentos_por_concepto.push({
                codigo: '0606',
                monto: montoPrimaSeguro,
              });
            }
          }
        }

        if (persona.tipo_seguro === 'EPS') {
          //Calcular descuento por EPS
          let montoAsumeTrabajador =
            // (
            persona.monto_descuento_eps * (persona.porcentaje_pago_eps / 100);
          //   /
          //   cantidadDiasPeriodoPlanilla) *
          // persona.cantidadDiasVacaciones;
          let descuentoEPSEncontrado = persona.descuentos_por_concepto.find(
            (descuento) => descuento.codigo === '0706'
          );
          if (descuentoEPSEncontrado) {
            descuentoEPSEncontrado.monto =
              descuentoEPSEncontrado.monto + montoAsumeTrabajador;
          } else {
            persona.descuentos_por_concepto.push({
              codigo: '0706',
              monto: montoAsumeTrabajador,
            });
          }
        }

        //Calcular renta de 5ta categoría : de las planillas Remuneración, Gratificación de Julio si existe y Vacaciones
        let montoProyeccionQuintaCategoria = 0;
        //inicializar contador de monto ya descontado por quinta categoría períodos anteriores
        let montoDescontadoQuintaCategoria = 0;
        let montoTotalQuintaCategoria = 0;
        let cantidadPeriodosFaltantes = 0;

        //buscar si la persona ha sido cesada "este mes" para no proyectar la renta de quinta categoría
        if (
          persona.fecha_cese &&
          moment(persona.fecha_cese).tz('America/Lima') >=
            moment(this.nuevaPlanillaCreada.fecha_inicio).tz('America/Lima') &&
          moment(persona.fecha_cese).tz('America/Lima') <=
            moment(this.nuevaPlanillaCreada.fecha_fin).tz('America/Lima')
        ) {
          cantidadPeriodosFaltantes = 0;
          montoProyeccionQuintaCategoria = 0;
        } else {
          if (numeroDeMesPeriodoActual.numeroMes <= 7) {
            cantidadPeriodosFaltantes =
              12 - numeroDeMesPeriodoActual.numeroMes + 2; // 2 periodos que faltan calcular por conceptos de gratificacion
          } else {
            cantidadPeriodosFaltantes =
              12 - numeroDeMesPeriodoActual.numeroMes + 1; // 1 periodo de gratificacion faltante (Diciembre)
          }
          //cantidad de periodos faltantes totales para este ejemplo es 13
          montoProyeccionQuintaCategoria =
            montoProyeccionQuintaCategoria +
            cantidadPeriodosFaltantes * persona.remuneracion_mensual;

          //verificar si aplica la asignacion familiar para la proyeccion de 5ta
          if (this.cliente.datos_empresa.regimen_laboral !== 'MICRO EMPRESA') {
            if (persona.asignacion_familiar === 'SI') {
              montoProyeccionQuintaCategoria =
                montoProyeccionQuintaCategoria +
                cantidadPeriodosFaltantes *
                  Number(parametroAsignacionFamiliar.monto);
            }
          }
        }

        //verificar las planillas de remuneración del año del periodo para los calculos de 5ta categoría
        this.planillasRemuneracionDelAnioDelPeriodo.map((planilla) => {
          const mesPlanilla = planilla.periodo.split('-')[0];
          const numeroDeMesPlanillaActual = this.mesesDelAnio.find(
            (mes) => mes.mes === mesPlanilla
          );
          //no considerar planillas posteriores a la que se está calculando para no afectar los cálculos de renta de 5ta
          if (
            numeroDeMesPlanillaActual.numeroMes >
            numeroDeMesPeriodoActual.numeroMes
          ) {
            return planilla;
          }
          planilla.personal.map((trabajador) => {
            if (trabajador.doi.toString() === persona.doi.toString()) {
              trabajador.ingresos_por_concepto.map((ingreso) => {
                //buscar conceptos afectos a quinta categoría sin contar la remuneración
                let ingresoAfectoAQuintaCategoria = this.conceptosSunat.find(
                  (concepto) =>
                    concepto.codigo === ingreso.codigo &&
                    concepto.afectacion_trabajador.quinta_categoria === 'SI'
                  // &&
                  // ingreso.codigo !== '0121'
                );
                if (ingresoAfectoAQuintaCategoria)
                  montoTotalQuintaCategoria =
                    montoTotalQuintaCategoria + ingreso.monto;
                return ingreso;
              });
              //obtener monto ya descontado por quinta categoría en periodos anteriores
              trabajador.descuentos_por_concepto.map((descuento) => {
                if (descuento.codigo === '0605')
                  montoDescontadoQuintaCategoria =
                    montoDescontadoQuintaCategoria + descuento.monto;
                return descuento;
              });
            }
            return trabajador;
          });
          return planilla;
        });

        //calcular periodo de gratificación de Julio si es que existe (es decir, a partir de Agosto)
        this.planillaGratificacionJulio.map((planilla) => {
          let personalEncontrado = planilla.personal.find(
            (trabajador) => trabajador.doi.toString() === persona.doi.toString()
          );

          if (personalEncontrado) {
            personalEncontrado.ingresos_por_concepto.map((ingreso) => {
              //buscar conceptos afectos a quinta categoría
              //Calcular el monto de ingresos por el concepto de remuneración
              let ingresoAfectoAQuintaCategoria = this.conceptosSunat.find(
                (concepto) =>
                  concepto.codigo === ingreso.codigo &&
                  concepto.afectacion_trabajador.quinta_categoria === 'SI'
                // && ingreso.codigo !== '0121'
              );
              if (ingresoAfectoAQuintaCategoria)
                montoTotalQuintaCategoria =
                  montoTotalQuintaCategoria + ingreso.monto;
              return ingreso;
            });

            //obtener monto ya descontado por quinta categoría en periodos anteriores
            personalEncontrado.descuentos_por_concepto.map((descuento) => {
              if (descuento.codigo === '0605')
                montoDescontadoQuintaCategoria =
                  montoDescontadoQuintaCategoria + descuento.monto;
              return descuento;
            });
          }
          return planilla;
        });

        //verificar las planillas de vacaciones generadas en el año para buscar el monto de ingresos afectos a 5ta cat
        this.planillasVacacionesDelAnioDelPeriodo.map((planilla) => {
          const mesPlanilla = planilla.periodo.split('-')[0];
          const numeroDeMesPlanillaActual = this.mesesDelAnio.find(
            (mes) => mes.mes === mesPlanilla
          );
          //no considerar planillas posteriores a la que se está calculando para no afectar los cálculos de renta de 5ta
          if (
            numeroDeMesPlanillaActual.numeroMes >
            numeroDeMesPeriodoActual.numeroMes
          ) {
            return planilla;
          }
          let personalEncontrado = planilla.personal.find(
            (trabajador) => trabajador.doi.toString() === persona.doi.toString()
          );

          if (personalEncontrado) {
            personalEncontrado.ingresos_por_concepto.map((ingreso) => {
              //buscar conceptos afectos a quinta categoría
              //Calcular el monto de ingresos por el concepto de remuneración
              let ingresoAfectoAQuintaCategoria = this.conceptosSunat.find(
                (concepto) =>
                  concepto.codigo === ingreso.codigo &&
                  concepto.afectacion_trabajador.quinta_categoria === 'SI'
                // && ingreso.codigo !== '0121'
              );
              if (ingresoAfectoAQuintaCategoria)
                montoTotalQuintaCategoria =
                  montoTotalQuintaCategoria + ingreso.monto;
              return ingreso;
            });

            //obtener monto ya descontado por quinta categoría en periodos anteriores
            personalEncontrado.descuentos_por_concepto.map((descuento) => {
              if (descuento.codigo === '0605')
                montoDescontadoQuintaCategoria =
                  montoDescontadoQuintaCategoria + descuento.monto;
              return descuento;
            });
          }
          return planilla;
        });

        //verificar si la persona tiene ingresos fijos afectos a 5ta
        if (
          Number(persona.monto_ingreso_fijo1) > 0 &&
          persona.concepto_ingreso_fijo1 !== ''
        ) {
          //verificar si el ingreso fijo está afecto a la renta de 5ta categoría para considerarlo en la proyección
          let ingresoAfectoAQuintaCategoria = this.conceptosSunat.find(
            (concepto) =>
              concepto.nombre === persona.concepto_ingreso_fijo1 &&
              concepto.afectacion_trabajador.quinta_categoria === 'SI'
          );
          if (ingresoAfectoAQuintaCategoria)
            montoProyeccionQuintaCategoria =
              montoProyeccionQuintaCategoria +
              cantidadPeriodosFaltantes * Number(persona.monto_ingreso_fijo1);
        }
        if (
          Number(persona.monto_ingreso_fijo2) > 0 &&
          persona.concepto_ingreso_fijo2 !== ''
        ) {
          let ingresoAfectoAQuintaCategoria = this.conceptosSunat.find(
            (concepto) =>
              concepto.nombre === persona.concepto_ingreso_fijo2 &&
              concepto.afectacion_trabajador.quinta_categoria === 'SI'
          );
          if (ingresoAfectoAQuintaCategoria)
            montoProyeccionQuintaCategoria =
              montoProyeccionQuintaCategoria +
              cantidadPeriodosFaltantes * Number(persona.monto_ingreso_fijo2);
        }
        if (
          Number(persona.monto_ingreso_fijo3) > 0 &&
          persona.concepto_ingreso_fijo3 !== ''
        ) {
          let ingresoAfectoAQuintaCategoria = this.conceptosSunat.find(
            (concepto) =>
              concepto.nombre === persona.concepto_ingreso_fijo3 &&
              concepto.afectacion_trabajador.quinta_categoria === 'SI'
          );
          if (ingresoAfectoAQuintaCategoria)
            montoProyeccionQuintaCategoria =
              montoProyeccionQuintaCategoria +
              cantidadPeriodosFaltantes * Number(persona.monto_ingreso_fijo3);
        }

        //sumar el monto del periodo actual al monto ya calculado de ingresos afectos a quinta categoría
        montoTotalQuintaCategoria =
          montoTotalQuintaCategoria + montoTotalIngresosAfectosQuintaCategoria;

        //validar contra fecha de ingreso si se debe considerar el monto de ingresos por concepto de trabajo anterior
        const anioIngresoTrabajador = moment(persona.fecha_ingreso).format(
          'YYYY'
        );
        const anioPeriodo = this.nuevaPlanillaCreada.periodo.split('-')[1];
        if (anioIngresoTrabajador === anioPeriodo) {
          if (Number(persona.monto_descuento_quinta_trabajo_anterior) > 0) {
            montoDescontadoQuintaCategoria =
              montoDescontadoQuintaCategoria +
              Number(persona.monto_descuento_quinta_trabajo_anterior);
          }
          if (Number(persona.monto_ingresos_trabajo_anterior) > 0) {
            montoTotalQuintaCategoria =
              montoTotalQuintaCategoria +
              Number(persona.monto_ingresos_trabajo_anterior);
          }
        }

        const montoTotalACalcularPorQuintaCategoria =
          montoTotalQuintaCategoria + montoProyeccionQuintaCategoria;
        //aplicar reglas para cálculo de renta de 5ta cat en base a la UIT (parámetro) y obtener el valor a descontar este mes
        if (
          montoTotalACalcularPorQuintaCategoria >
          7 * Number(parametroUIT.monto)
        ) {
          let montoADescontarPorQuintaCategoria = 0;
          //si el excedente > 5UIT
          let montoExcedente =
            montoTotalACalcularPorQuintaCategoria -
            7 * Number(parametroUIT.monto);

          if (montoExcedente <= 5 * Number(parametroUIT.monto)) {
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria + montoExcedente * 0.08;
          }
          if (
            montoExcedente > 5 * Number(parametroUIT.monto) &&
            montoExcedente <= 20 * Number(parametroUIT.monto)
          ) {
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              5 * Number(parametroUIT.monto) * 0.08;
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              (montoExcedente - 5 * Number(parametroUIT.monto)) * 0.14;
          }
          if (
            montoExcedente > 20 * Number(parametroUIT.monto) &&
            montoExcedente <= 35 * Number(parametroUIT.monto)
          ) {
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              5 * Number(parametroUIT.monto) * 0.08;
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              15 * Number(parametroUIT.monto) * 0.14;
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              (montoExcedente - 20 * Number(parametroUIT.monto)) * 0.17;
          }
          if (
            montoExcedente > 35 * Number(parametroUIT.monto) &&
            montoExcedente <= 45 * Number(parametroUIT.monto)
          ) {
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              5 * Number(parametroUIT.monto) * 0.08;
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              15 * Number(parametroUIT.monto) * 0.14;
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              15 * Number(parametroUIT.monto) * 0.17;
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              (montoExcedente - 35 * Number(parametroUIT.monto)) * 0.2;
          }
          if (montoExcedente > 45 * Number(parametroUIT.monto)) {
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              5 * Number(parametroUIT.monto) * 0.08;
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              15 * Number(parametroUIT.monto) * 0.14;
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              15 * Number(parametroUIT.monto) * 0.17;
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              10 * Number(parametroUIT.monto) * 0.2;
            montoADescontarPorQuintaCategoria =
              montoADescontarPorQuintaCategoria +
              (montoExcedente - 45 * Number(parametroUIT.monto)) * 0.3;
          }

          montoADescontarPorQuintaCategoria =
            montoADescontarPorQuintaCategoria - montoDescontadoQuintaCategoria;

          let montoADescontarPorQuintaPeriodoActual = 0;

          if (cantidadPeriodosFaltantes > 0) {
            montoADescontarPorQuintaPeriodoActual =
              montoADescontarPorQuintaCategoria /
              (cantidadPeriodosFaltantes + 1);
          }
          //concepto 605 para descuentos por renta de quinta
          let descuentoQuintaEncontrado = persona.descuentos_por_concepto.find(
            (descuento) => descuento.codigo === '0605'
          );
          if (descuentoQuintaEncontrado) {
            descuentoQuintaEncontrado.monto =
              descuentoQuintaEncontrado.monto +
              montoADescontarPorQuintaPeriodoActual;
          } else {
            persona.descuentos_por_concepto.push({
              codigo: '0605',
              monto: montoADescontarPorQuintaPeriodoActual,
            });
          }
        }
        //validar si la persona cuenta con descuentos/préstamos a ser aplicados en esta planilla
        this.procesarPrestamosDelTrabajador(persona);
        return persona;
      });

      this.showLoadingDialog = false;
      this.tableLoading = false;
      this.renderTable++;
    },
    async prepararGraficos() {
      const mesPeriodoActual = this.nuevaPlanillaCreada.periodo.split('-')[0];
      const anioPeriodoActual = this.nuevaPlanillaCreada.periodo.split('-')[1];

      const mesDelAnio = this.mesesDelAnio.find(
        (mes) => mes.mes === mesPeriodoActual
      );

      if (mesDelAnio.numeroMes === 1) {
        //Enero
        //Buscar planilla de remuneración de Noviembre del año anterior al evaluado
        if (this.planillasNoviembreAnioPasado.length > 0) {
          this.procesarDatosDelosGraficos(this.planillasNoviembreAnioPasado);
        }

        //Buscar planilla de remuneración de Diciembre del año anterior al evaluado
        if (this.planillasDiciembreAnioPasado.length > 0) {
          this.procesarDatosDelosGraficos(this.planillasDiciembreAnioPasado);
        }
      }
      if (mesDelAnio.numeroMes === 2) {
        //Febrero
        const enero = this.mesesDelAnio.find((mes) => mes.numeroMes === 1);

        //Buscar la planilla de remuneración de Enero
        const planillasEnero = this.planillasRemuneracionDelAnioDelPeriodo.filter(
          (planilla) => planilla.periodo === `${enero.mes}-${anioPeriodoActual}`
        );

        if (planillasEnero.length > 0) {
          this.procesarDatosDelosGraficos(planillasEnero);
        }

        //Buscar planilla de remuneración de Diciembre del año anterior al evaluado
        if (this.planillasDiciembreAnioPasado.length > 0) {
          this.procesarDatosDelosGraficos(this.planillasDiciembreAnioPasado);
        }
      }
      if (mesDelAnio.numeroMes > 2) {
        //Resto de meses y filtro para buscar únicamente los dos periodos anteriores al actual
        for (let index = 1; index < 3; index++) {
          const periodoAnterior = this.mesesDelAnio.find(
            (mes) => mes.numeroMes === mesDelAnio.numeroMes - index
          );

          const planillasEncontradas = this.planillasRemuneracionDelAnioDelPeriodo.filter(
            (planilla) =>
              planilla.periodo === `${periodoAnterior.mes}-${anioPeriodoActual}`
          );

          if (planillasEncontradas.length > 0) {
            this.procesarDatosDelosGraficos(planillasEncontradas);
          }
        }
      }

      this.seriesTotalComisionesPensiones.push({
        name: this.nuevaPlanillaCreada.periodo,
        type: 'column',
        data: [this.totalComisionPensiones],
      });

      this.seriesTotalAporteObligatorioPensiones.push({
        name: this.nuevaPlanillaCreada.periodo,
        type: 'column',
        data: [this.totalAporteObligatorioPensiones],
      });

      this.seriesTotalPrimaSeguroPensiones.push({
        name: this.nuevaPlanillaCreada.periodo,
        type: 'column',
        data: [this.totalPrimaSeguroPensiones],
      });

      this.seriesMontoONP.push({
        name: this.nuevaPlanillaCreada.periodo,
        type: 'column',
        data: [this.totalMontoONP],
      });

      this.showLoadingDialog = false;
    },
    borrarDatosDeEstePaso() {
      this.nuevaPlanillaCreada.personal.map((persona) => {
        persona.descuentos_por_concepto = [];
        persona.ingresos_por_concepto = [];
        persona.info_dias =
          !!persona.info_dias && persona.info_dias.length > 0 ? [] : null;
        persona.remuneracionComputable =
          !!persona.remuneracionComputable && persona.remuneracionComputable > 0
            ? 0
            : null;
        persona.montoAPagarPorCTS =
          !!persona.montoAPagarPorCTS && persona.montoAPagarPorCTS > 0
            ? 0
            : null;
        persona.ingresosAfectosAEsSaludPorPeriodo =
          !!persona.ingresosAfectosAEsSaludPorPeriodo &&
          persona.ingresosAfectosAEsSaludPorPeriodo.length > 0
            ? []
            : null;
        persona.cantidad_dias_efectivos = 0;
        persona.cantidad_dias_efectivos_beneficios_sociales = 0;
        persona.montoAPagarPorGratificacion =
          !!persona.montoAPagarPorGratificacion &&
          persona.montoAPagarPorGratificacion > 0
            ? 0
            : null;
        persona.cantidadDePlanillasDeRemuneracion =
          !!persona.cantidadDePlanillasDeRemuneracion &&
          persona.cantidadDePlanillasDeRemuneracion > 0
            ? 0
            : null;
        persona.cantidadDiasVacaciones =
          !!persona.cantidadDiasVacaciones && persona.cantidadDiasVacaciones > 0
            ? 0
            : null;
        persona.montoPorVacacionesTruncas =
          !!persona.montoPorVacacionesTruncas &&
          persona.montoPorVacacionesTruncas > 0
            ? 0
            : null;
        persona.vacacionesPendientes =
          !!persona.vacacionesPendientes && persona.vacacionesPendientes > 0
            ? 0
            : null;
        persona.actualizarCreditos = false;
        return persona;
      });
    },
    volverAlPasoAnterior() {
      this.borrarDatosDeEstePaso();
      this.$store.state.procesoPlanilla.pasoDeProcesoActual = 2;
    },
    siguientePaso() {
      if (
        this.nuevaPlanillaCreada.tipo_proceso === 'Gratificación' ||
        this.nuevaPlanillaCreada.tipo_proceso === 'Locación de servicio' ||
        this.nuevaPlanillaCreada.tipo_proceso === 'Adelanto de sueldo'
      ) {
        this.$store.state.procesoPlanilla.pasoDeProcesoActual = 5;
        return;
      }
      this.$store.state.procesoPlanilla.pasoDeProcesoActual = 4;
    },
    showErrorAlert(text) {
      this.$swal.fire({
        title: `Error`,
        text,
        icon: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/main.scss';
.v-card {
  overflow-y: auto;
}
.v-card__text,
.v-card__title {
  word-break: normal;
}
</style>
