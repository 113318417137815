import fetch from 'isomorphic-fetch';

let ENDPOINT_URL = process.env.VUE_APP_REGISTER;

async function register(user) {
  const bodyParams = {
    ruc: user.ruc,
    username: user.username.toLowerCase(),
    password: user.password,
    email: user.email,
    datos_empresa: {
      nombre_representante: user.name,
      apellidos_representante: user.apellidos,
      doi_representante: user.dni,
      razon_social: user.razonSocial,
    },
    planes_contratados: [],
  };

  let body = JSON.stringify(bodyParams);

  return await fetch(ENDPOINT_URL, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  }).then((item) => item.json());
}
export { register };
