<template>
  <v-container fluid class="pa-0 ma-0 container">
    <Aside />
  </v-container>
</template>
<script>
import Aside from './Aside';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';
import obtenerRolesDelToken from '@/utils/obtenerRolesDelToken.js';
import { mapState } from 'vuex';

export default {
  name: 'Config',
  components: {
    Aside,
  },
  computed: {
    ...mapState(['usuarioLogueado']),
  },
  mounted() {
    const token = localStorage.getItem('token');
    if (!token) {
      routeToLoginIfTokenIsExpired(this);
      return;
    }
    if (!this.usuarioLogueado || !this.usuarioLogueado._id) {
      this.$store.state.usuarioLogueado = obtenerRolesDelToken(token);
      return;
    }
  },
};
</script>
<style lang="scss" scoped>
@import 'src/styles/main.scss';
.container {
  height: 100vh;
  background-color: $general-bg !important;
}
</style>
