<template>
  <EditPersonal :dataAEditarProp="this.personalEditable" v-if="editPerson" />
  <v-card v-else min-height="400px" flat :key="tableRender" class="mt-4">
    <v-data-table
      no-results-text="No se encontraron registros..."
      id="table"
      :headers="encabezadosSeleccionados"
      height="70.25vh"
      fixed-header
      :items="personal"
      :options.sync="options"
      @click:row="enviarData"
      :search="search"
      no-data-text="No hay personal aún..."
    >
      <template v-slot:top>
        <v-toolbar flat height="100px">
          <v-spacer></v-spacer>

          <v-toolbar-title class="top-bar-title"
            >Gestión de personal</v-toolbar-title
          >

          <v-spacer></v-spacer>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar persona..."
            single-line
            outlined
          ></v-text-field>

          <v-spacer></v-spacer>

          <v-select
            v-model="encabezadosSeleccionados"
            :items="encabezadosTotales"
            label="Selector de columnas para visualizar"
            multiple
            small-chips
            return-object
            outlined
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span>{{ item.text }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ encabezadosSeleccionados.length - 1 }} otras columnas)
              </span>
            </template>
          </v-select>
        </v-toolbar>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
import { mapState } from 'vuex';

import { getPersons } from '@/api/persons/getPersons.js';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';

import EditPersonal from './EditPersonal';
export default {
  data: () => ({
    tableRender: 99999,
    options: {},
    personal: [],
    personalEditable: {},
    encabezadosTotales: [
      {
        text: 'Asignación familiar',
        value: 'datos_laborales.datos_remuneracion.asignacion_familiar',
      },
      {
        text: 'Banco',
        value: 'datos_laborales.datos_remuneracion.banco',
      },
      {
        text: 'Cargo',
        value: 'datos_laborales.cargo',
        width: '150px',
      },
      {
        text: 'Celular',
        value: 'datos_laborales.celular',
      },
      {
        text: 'Concepto ingreso fijo 1',
        value: 'datos_laborales.concepto_ingreso_fijo1',
        width: '280px',
      },
      {
        text: 'Concepto ingreso fijo 2',
        value: 'datos_laborales.concepto_ingreso_fijo2',
        width: '280px',
      },
      {
        text: 'Concepto ingreso fijo 3',
        value: 'datos_laborales.concepto_ingreso_fijo3',
        width: '280px',
      },
      {
        text: 'CUSPP',
        value: 'datos_laborales.datos_remuneracion.cuspp',
      },
      {
        text: 'Dirección',
        value: 'direccion',
        width: '280px',
      },
      {
        text: 'Distrito',
        value: 'datos_laborales.datos_nacimiento.distrito',
      },
      {
        text: 'Documento',
        value: 'doi',
      },
      {
        text: 'Email',
        value: 'email',
      },
      {
        text: 'Estado Civil',
        value: 'datos_laborales.estadoCivil',
      },
      {
        text: 'Fecha de cese',
        value: 'datos_laborales.fecha_cese',
        width: '120px',
      },
      {
        text: 'Fecha de ingreso',
        value: 'datos_laborales.fecha_ingreso',
        width: '120px',
      },
      {
        text: 'Fecha de ingreso SPP',
        value: 'datos_laborales.datos_remuneracion.fecha_ingreso_spp',
        width: '120px',
      },
      {
        text: 'Fecha de nacimiento',
        value: 'datos_laborales.datos_nacimiento.fecha_nacimiento',
        width: '120px',
      },
      {
        text: 'Fin de contrato',
        value: 'datos_laborales.fecha_fin_contrato',
        width: '120px',
      },
      {
        text: 'Inicio de contrato',
        value: 'datos_laborales.fecha_inicio_contrato',
      },
      {
        text: 'Método de pago',
        value: 'datos_laborales.datos_remuneracion.metodo_pago',
        width: '120px',
      },
      {
        text: 'Monto ingreso fijo 1',
        value: 'datos_laborales.monto_ingreso_fijo1',
        width: '120px',
      },
      {
        text: 'Monto ingreso fijo 2',
        value: 'datos_laborales.monto_ingreso_fijo2',
        width: '120px',
      },
      {
        text: 'Monto ingreso fijo 3',
        value: 'datos_laborales.monto_ingreso_fijo3',
        width: '120px',
      },
      {
        text: 'Nombres',
        value: 'nombre',
        width: '275px',
      },
      {
        text: 'Nombre AFP',
        value: 'datos_laborales.datos_remuneracion.nombre_afp',
      },
      {
        text: 'Número de cuenta banco',
        value: 'datos_laborales.datos_remuneracion.numero_cuenta',
        width: '120px',
      },
      {
        text: 'Periodicidad de pago',
        value: 'datos_laborales.datos_remuneracion.periodicidad',
      },
      {
        text: 'Situación laboral',
        value: 'datos_laborales.situacion',
      },
      {
        text: 'Sede',
        value: 'datos_laborales.sede',
      },
      {
        text: 'Sueldo básico',
        value:
          'datos_laborales.datos_remuneracion.sueldo_basico_sin_descuentos',
      },
      {
        text: 'Sujeto a adelanto',
        value: 'sujetoAAdelanto',
      },
      {
        text: 'Tipo de comisión AFP',
        value: 'datos_laborales.datos_remuneracion.tipo_comision_afp',
        width: '120px',
      },
      {
        text: 'Tipo de contrato',
        value: 'datos_laborales.tipo_contrato',
        width: '280px',
      },
      {
        text: 'Tipo de documento',
        value: 'datos_laborales.tipo_documento',
      },
      {
        text: 'Tipo de pensión',
        value: 'datos_laborales.datos_remuneracion.tipo_pension',
      },
      {
        text: 'Tipo de seguro',
        value: 'datos_laborales.tipo_seguro',
      },
      {
        text: 'Tipo de trabajador',
        value: 'datos_laborales.tipo_trabajador',
      },
    ],
    encabezadosSeleccionados: [
      {
        text: 'Cargo',
        value: 'datos_laborales.cargo',
        width: '150px',
      },
      {
        text: 'Documento',
        value: 'doi',
      },
      {
        text: 'Fecha de ingreso',
        value: 'datos_laborales.fecha_ingreso',
        width: '120px',
      },
      {
        text: 'Fin de contrato',
        value: 'datos_laborales.fecha_fin_contrato',
        width: '120px',
      },
      {
        text: 'Inicio de contrato',
        value: 'datos_laborales.fecha_inicio_contrato',
        width: '120px',
      },
      {
        text: 'Nombres',
        value: 'nombre',
        width: '275px',
      },
      {
        text: 'Situación laboral',
        value: 'datos_laborales.situacion',
      },
      {
        text: 'Sueldo básico',
        value:
          'datos_laborales.datos_remuneracion.sueldo_basico_sin_descuentos',
      },
      {
        text: 'Tipo de contrato',
        value: 'datos_laborales.tipo_contrato',
        width: '280px',
      },
      {
        text: 'Tipo de trabajador',
        value: 'datos_laborales.tipo_trabajador',
      },
    ],
    search: '',
  }),
  components: {
    EditPersonal,
  },
  mounted() {
    this.obtenerPersonalCompleto();
    // this.personal = this.personalCompleto;
  },
  computed: {
    ...mapState([
      'editPerson',
      'personalCompleto',
      'personalActivo',
      'personalCesado',
      'planVigenteActual',
      'verPersonalHistorico',
    ]),
  },
  methods: {
    async obtenerPersonalCompleto() {
      const { error, personal, tokenExpired } = await getPersons();
      if (personal) {
        // solo mostrar las usuarios_habilitados permitidos por el plan vigente
        let limiteDePersonas = 3;
        if (
          this.planVigenteActual.tipo_plan !== '' &&
          this.planVigenteActual.tipo_plan !== 'CTIEmprendedor'
        ) {
          limiteDePersonas = personal.length;
        }
        // this.personal = personal;
        // Editar los campos para mostrar en la tabla
        personal.map((persona) => {
          persona.nombre = `${persona.datos_laborales.apellido_paterno} ${persona.datos_laborales.apellido_materno}, ${persona.nombre}`;
          persona.sujetoAAdelanto = persona.datos_laborales
            .switchSujetoAAdelantoDeQuincena
            ? 'SI'
            : null;
          return persona;
        });

        //todo el personal
        this.$store.state.personalCompleto = [];
        this.$store.state.personalCompleto.push(...personal);

        //filtro aplicado al personal para solo obtener personal activo para ser utilizado en el proceso de planillas
        this.$store.state.personalActivo = personal.filter(
          (persona) => persona.datos_laborales.situacion !== 'Cesado'
        );

        //considerar al personal "Cesado" en el proceso de planilla de liquidación
        this.$store.state.personalCesado = personal.filter(
          (persona) => persona.datos_laborales.situacion === 'Cesado'
        );

        this.personal = [];
        if (this.verPersonalHistorico) {
          this.personal.push(
            ...this.personalCesado,
            ...this.personalActivo.slice(0, limiteDePersonas)
          );
        } else {
          this.personal.push(...this.personalActivo.slice(0, limiteDePersonas));
        }
      }
      if (error) {
        //do something
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
    },
    enviarData(personal) {
      this.personalEditable = personal;
      this.$store.state.editPerson = true;
    },
    cargarPersonal() {
      this.personal = [];
      let limiteDePersonas = 3;
      if (
        this.planVigenteActual.tipo_plan !== '' &&
        this.planVigenteActual.tipo_plan !== 'CTIEmprendedor'
      ) {
        limiteDePersonas = this.personalCompleto.length;
      }

      if (this.verPersonalHistorico) {
        this.personal.push(
          ...this.personalCesado,
          ...this.personalActivo.slice(0, limiteDePersonas)
        );
      } else {
        this.personal.push(...this.personalActivo.slice(0, limiteDePersonas));
      }
    },
  },
  watch: {
    editPerson() {
      if (!this.editPerson) {
        this.obtenerPersonalCompleto();
      }
    },
    verPersonalHistorico() {
      this.cargarPersonal();
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'src/styles/main.scss';
.top-bar-title {
  font-size: 25px !important;
  font-weight: bold !important;
  color: teal !important;
}
.v-data-table::v-deep th {
  font-size: 16px !important;
  font-weight: bold !important;
  color: teal !important;
}
#table {
  // position: relative;
  // height: 60vh;
  overflow-y: auto;
  overflow-x: auto;
  width: 100%;
  margin: auto;
  // top: -93px;
  td,
  th {
    border: 1px solid rgba(97, 92, 92, 0.253);
  }
  tr:nth-child(even) {
    cursor: pointer;
    background-color: $secondary;
  }
  th {
    height: 32px;
    text-align: justify;
  }
  td {
    height: 32px;
    padding: 0px 10px;
    text-align: justify;
  }
}
#table::-webkit-scrollbar {
  height: 10px;
  width: 4px;
  background: $main;
}
#table::-webkit-scrollbar-thumb {
  background: #313d35;
  border-radius: 10px;
}
.table {
  // max-height: 74vh;
  overflow: auto;
}
// @media (min-width: 1264px) {
//   .container {
//     height: 60vh;
//   }
// }
</style>
