<template>
  <v-overlay>
    <v-card>
      <v-row>
        <v-col cols="5" class="align-form">
          <v-row>
            <v-col data-app>
              <v-select
                label="¿Disponible?"
                v-model="unidad.disponible"
                :items="dropdown1"
              ></v-select>
            </v-col>
            <v-col>
              <v-text-field v-model="unidad.placa" label="Placa :" />
            </v-col>
            <v-col data-app>
              <v-select
                v-model="unidad.tipo"
                :items="dropdown"
                label="Tipo"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field v-model="unidad.obs" label="Observaciones" />
            </v-col>
          </v-row>
          <v-btn @click="addNewUnit(unidad)">
            Agregar
          </v-btn>
        </v-col>
        <v-col>
          <div v-if="unidad.tipo">
            <div v-for="(item, i) in dropoptions" :key="i">
              <v-img
                width="420px"
                v-if="unidad.tipo == item.title"
                :src="item.url"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-overlay>
</template>
<script>
import { newUnit } from '@/api/flota/newUnit';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';

export default {
  data: () => ({
    dropdown: ['Moto', 'Minivan', 'Bus', 'Semitrailer', 'Camión'],
    dropdown1: ['Disponible', 'No disponible'],
    unidad: {
      placa: '',
      tipo: 'Minivan',
      disponible: '',
      obs: '',
    },
  }),
  computed: {
    dropoptions() {
      const dropoptions = [
        {
          url: require('@/assets/flota/moto.png'),
          alt: '',
          title: 'Moto',
        },
        {
          url: require('@/assets/flota/minivan.png'),
          alt: '',
          title: 'Minivan',
        },
        {
          url: require('@/assets/flota/bus.png'),
          alt: '',
          title: 'Bus',
        },
        {
          url: require('@/assets/flota/semitrailer.png'),
          alt: '',
          title: 'Semitrailer',
        },
        {
          url: require('@/assets/flota/camión.png'),
          alt: '',
          title: 'Camión',
        },
      ];
      return dropoptions;
    },
  },
  methods: {
    showSuccessAlert(msj) {
      this.$swal.fire({
        icon: 'success',
        title: msj,
        showConfirmButton: false,
        timer: 3000,
      });
    },
    async addNewUnit(item) {
      if (item.disponible == 'Disponible') {
        item.disponible = true;
      } else {
        item.disponible = false;
      }
      const { error, message, tokenExpired } = await newUnit(item);
      if (error) {
        //do something
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (message) {
        this.showSuccessAlert('¡La unidad fue agregada con éxito!');
        setTimeout(function() {
          window.location.reload();
        }, 3000);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/main.scss';
.v-card {
  width: 70vw;
  height: 470px;
  overflow: scroll;
  overflow-x: hidden;
  margin: 50px auto;
  padding: 20px;
  .align-form {
    display: flex;
    height: fit-content;
    padding: 20px;
    align-self: center;
    flex-direction: column;
  }
}
.v-image {
  margin: 50px auto;
}
.v-card::-webkit-scrollbar {
  width: 4px;
  background: #efeff6;
}
.v-card::-webkit-scrollbar-thumb {
  background: $main;
  border-radius: 10px;
}
</style>
