var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tabs',{attrs:{"vertical":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-tab',_vm._g({staticClass:"tab-class"},on),[_vm._v(" 0100 - INGRESOS ")])]}}])},[_c('span',[_vm._v("0100 - INGRESOS")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-tab',_vm._g({staticClass:"tab-class"},on),[_vm._v(" 0200 - INGRESOS: ASIGNACIONES ")])]}}])},[_c('span',[_vm._v("0200 - INGRESOS: ASIGNACIONES")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-tab',_vm._g({staticClass:"tab-class"},on),[_vm._v(" 0300 - INGRESOS: BONIFICACIONES ")])]}}])},[_c('span',[_vm._v("0300 - INGRESOS: BONIFICACIONES")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-tab',_vm._g({staticClass:"tab-class"},on),[_vm._v(" 0400 - INGRESOS: GRATIFICACIONES ")])]}}])},[_c('span',[_vm._v("0400 - INGRESOS: GRATIFICACIONES/AGUINALDOS")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-tab',_vm._g({staticClass:"tab-class"},on),[_vm._v(" 0500 - INGRESOS: INDEMNIZACIONES ")])]}}])},[_c('span',[_vm._v("0500 - INGRESOS: INDEMNIZACIONES")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-tab',_vm._g({staticClass:"tab-class"},on),[_vm._v(" 0600 - APORTACIONES DEL TRABAJADOR ")])]}}])},[_c('span',[_vm._v("0600 - APORTACIONES DEL TRABAJADOR/PENSIONISTA")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-tab',_vm._g({staticClass:"tab-class"},on),[_vm._v(" 0700 - DESCUENTOS AL TRABAJADOR ")])]}}])},[_c('span',[_vm._v("0700 - DESCUENTOS AL TRABAJADOR")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-tab',_vm._g({staticClass:"tab-class"},on),[_vm._v(" 0800 - APORTACIONES DEL EMPLEADOR ")])]}}])},[_c('span',[_vm._v("0800 - APORTACIONES DE CARGO DEL EMPLEADOR")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-tab',_vm._g({staticClass:"tab-class"},on),[_vm._v(" 0900 - CONCEPTOS VARIOS ")])]}}])},[_c('span',[_vm._v("0900 - CONCEPTOS VARIOS")])]),_c('v-tab-item',[_c('ConceptosDetalle',{attrs:{"grupoConcepto":100,"conceptosSunat":_vm.obtenerConceptosSunatFiltrados(100)}})],1),_c('v-tab-item',[_c('ConceptosDetalle',{attrs:{"grupoConcepto":200,"conceptosSunat":_vm.obtenerConceptosSunatFiltrados(200)}})],1),_c('v-tab-item',[_c('ConceptosDetalle',{attrs:{"grupoConcepto":300,"conceptosSunat":_vm.obtenerConceptosSunatFiltrados(300)}})],1),_c('v-tab-item',[_c('ConceptosDetalle',{attrs:{"grupoConcepto":400,"conceptosSunat":_vm.obtenerConceptosSunatFiltrados(400)}})],1),_c('v-tab-item',[_c('ConceptosDetalle',{attrs:{"grupoConcepto":500,"conceptosSunat":_vm.obtenerConceptosSunatFiltrados(500)}})],1),_c('v-tab-item',[_c('ConceptosDetalle',{attrs:{"grupoConcepto":600,"conceptosSunat":_vm.obtenerConceptosSunatFiltrados(600)}})],1),_c('v-tab-item',[_c('ConceptosDetalle',{attrs:{"grupoConcepto":700,"conceptosSunat":_vm.obtenerConceptosSunatFiltrados(700)}})],1),_c('v-tab-item',[_c('ConceptosDetalle',{attrs:{"grupoConcepto":800,"conceptosSunat":_vm.obtenerConceptosSunatFiltrados(800)}})],1),_c('v-tab-item',[_c('ConceptosDetalle',{attrs:{"grupoConcepto":900,"conceptosSunat":_vm.obtenerConceptosSunatFiltrados(900)}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }