<template>
  <v-card class="tabs-container">
    <v-tabs vertical>
      <v-tab class="fuente-branding">
        Horas laboradas
      </v-tab>
      <div class="btn-group">
        <router-link to="/dashboard">
          <v-btn class="fuente-branding">
            <v-img
              max-width="20px"
              cover
              :src="require('@/assets/menu-icons/home.svg')"
            />
            Inicio
          </v-btn>
        </router-link>
        <router-link to="/">
          <v-btn class="fuente-branding">
            <v-img
              max-width="20px"
              cover
              :src="require('@/assets/menu-icons/endsession.svg')"
            />
            Salir
          </v-btn>
        </router-link>
      </div>
      <v-tab-item class="m-20">
        <v-card flat>
          <HorasLaboradas />
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>
<script>
import HorasLaboradas from './HorasLaboradas';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';
import { getClientData } from '@/api/configuration/getClientData.js';
import { mapState } from 'vuex';
import validarAccesoPorRol from '@/utils/validarAccesoPorRol.js';
import obtenerPlanVigenteActual from '@/utils/obtenerPlanVigenteActual.js';

export default {
  components: {
    HorasLaboradas,
  },
  computed: {
    ...mapState(['rolesDelSistema', 'planVigenteActual']),
  },
  methods: {
    validarAccesoPorRol(rolesNecesarios) {
      const token = localStorage.getItem('token');
      return validarAccesoPorRol(token, rolesNecesarios);
    },
    async storeClientData() {
      const { error, tokenExpired, client } = await getClientData();
      if (error) {
        //do somethiung
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (client) {
        this.$store.state.cliente = client;
        if (!this.planVigenteActual.tipo_plan) {
          const planVigenteActual = obtenerPlanVigenteActual(client, this);
          this.$store.state.planVigenteActual = planVigenteActual;
        }
      }
    },
  },
  created() {
    this.storeClientData();
  },
};
</script>
<style lang="scss" scoped>
@import 'src/styles/main.scss';
.tabs-container {
  background-color: $general-bg;
}
.fuente-branding {
  font-size: 15px !important;
  font-family: 'Branding-Black';
}
.v-tab--active {
  background-color: $main;
  color: #fff !important;
}
a {
  text-decoration: none !important;
  color: $main !important;
}
.btn-group {
  justify-content: space-around;
  flex-direction: column;
  display: flex;
  position: absolute;
  bottom: 50px;
  .v-btn {
    background-color: transparent;
    box-shadow: none;
    display: flex;
    justify-content: flex-start;
  }
  .v-image {
    margin-right: 10px;
  }
}
.m-20 {
  right: 24px;
  background-color: $secondary;
  padding: 10px 20px;
  border-radius: 0px 5px 5px 5px;
  border: 3px solid $main;
}
@media (max-width: 735px) {
  .m-20 {
    margin: 40px 0px 5px;
  }
}
</style>
