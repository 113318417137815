<template>
  <v-card height="50vh">
    <v-progress-circular
      v-if="showLoadingDialog"
      :size="180"
      :width="7"
      color="teal"
      indeterminate
    ></v-progress-circular>
    <v-tabs vertical v-else>
      <!-- tab 1 -->
      <v-tab>
        <v-icon left>
          mdi-chart-line
        </v-icon>
        Totales
      </v-tab>

      <!-- tab 2 -->
      <v-tab @click="showCompleteAlert">
        <v-icon left color="success">
          mdi-check-outline
        </v-icon>
        Completar
      </v-tab>

      <!-- tab 3 -->
      <v-tab @click="volverAlPasoAnterior">
        <v-icon left color="red">
          mdi-undo
        </v-icon>
        Anterior
      </v-tab>

      <!-- tab-item 1 -->
      <v-tab-item>
        <v-card flat>
          <v-card-text v-if="esPlanillaRemuneracion">
            <v-row>
              <v-spacer></v-spacer>
              <apexchart
                type="bar"
                height="320"
                :options="chartOptionsTotalIngresos"
                :series="seriesTotalIngresos"
              ></apexchart>
              <v-spacer></v-spacer>
              <apexchart
                type="bar"
                height="320"
                :options="chartOptionsTotalDescuentos"
                :series="seriesTotalDescuentos"
              ></apexchart>
              <v-spacer></v-spacer>
              <apexchart
                type="bar"
                height="320"
                :options="chartOptionsTotalAportes"
                :series="seriesTotalAportes"
              ></apexchart>
              <v-spacer></v-spacer>
            </v-row>
          </v-card-text>
          <v-card-text v-else>
            <v-data-table
              :headers="headers"
              :items="items"
              :items-per-page="5"
              class="elevation-1"
              hide-default-footer
              disable-pagination
            ></v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- tab-item 2 -->
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>
              Completar el proceso de planilla...
            </p>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <!-- tab-item 3 -->
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>
              VOLVER AL PASO ANTERIOR
            </p>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
// import moment from 'moment';
import { mapState } from 'vuex';

import { editarPlanilla } from '@/api/payments/editarPlanilla.js';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';
import VueApexCharts from 'vue-apexcharts';
import { editarCreditos } from '@/api/persons/editUserInfo.js';

export default {
  name: 'Paso5',
  data: () => ({
    // mostrarImportacionDesdeExcel: false,
    // importaDesdeExcel: false,
    showLoadingDialog: true,
    totalIngresos: 0,
    totalDescuentos: 0,
    totalAportes: 0,
    seriesTotalIngresos: [],
    seriesTotalDescuentos: [],
    seriesTotalAportes: [],
    mesesDelAnio: [
      {
        mes: 'Enero',
        numeroMes: 1,
      },
      {
        mes: 'Febrero',
        numeroMes: 2,
      },
      {
        mes: 'Marzo',
        numeroMes: 3,
      },
      {
        mes: 'Abril',
        numeroMes: 4,
      },
      {
        mes: 'Mayo',
        numeroMes: 5,
      },
      {
        mes: 'Junio',
        numeroMes: 6,
      },
      {
        mes: 'Julio',
        numeroMes: 7,
      },
      {
        mes: 'Agosto',
        numeroMes: 8,
      },
      {
        mes: 'Septiembre',
        numeroMes: 9,
      },
      {
        mes: 'Octubre',
        numeroMes: 10,
      },
      {
        mes: 'Noviembre',
        numeroMes: 11,
      },
      {
        mes: 'Diciembre',
        numeroMes: 12,
      },
    ],
    headers: [
      {
        text: 'Total trabajadores procesados',
        align: 'start',
        value: 'totalTrabajadores',
      },
      { text: 'Total ingresos', value: 'totalIngresos' },
      { text: 'Total descuentos', value: 'totalDescuentos' },
      { text: 'Total aportes', value: 'totalAportes' },
      { text: 'Total neto a pagar', value: 'totalNeto' },
    ],
    items: [],
  }),
  components: {
    apexchart: VueApexCharts,
  },
  computed: {
    ...mapState([
      'procesoPlanilla',
      'nuevaPlanillaCreada',
      'cliente',
      'conceptosSunat',
      'planillasRemuneracionDelAnioDelPeriodo',
      // 'personalActivo',
      'planillasNoviembreAnioPasado',
      'planillasDiciembreAnioPasado',
    ]),
    chartOptionsTotalIngresos() {
      let configuracionGraficos = {
        chart: {
          type: 'bar',
          height: 350,
          width: 360,
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: '16px',
            fontFamily: 'Branding-Medium',
          },
          x: {
            show: true,
          },
          y: {
            formatter: function(value) {
              var formatter = new Intl.NumberFormat('es-PE', {
                style: 'currency',
                currency: 'PEN',
              });

              return `${formatter.format(value)}`;
            },
          },
          fixed: {
            enabled: true,
            position: 'topRight',
            offsetX: 0,
            offsetY: 0,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '35%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        title: {
          text: 'Total ingresos',
          align: 'left',
        },
        xaxis: {
          categories: ['Monto por ingresos'],
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#008FFB',
            },
            labels: {
              style: {
                colors: '#008FFB',
              },
            },
            title: {
              text: 'Total monto ingresos (S/.)',
              style: {
                color: '#008FFB',
              },
            },
          },
        ],
      };
      return configuracionGraficos;
    },
    chartOptionsTotalDescuentos() {
      let configuracionGraficos = {
        chart: {
          type: 'bar',
          height: 350,
          width: 360,
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: '16px',
            fontFamily: 'Branding-Medium',
          },
          x: {
            show: true,
          },
          y: {
            formatter: function(value) {
              var formatter = new Intl.NumberFormat('es-PE', {
                style: 'currency',
                currency: 'PEN',
              });

              return `${formatter.format(value)}`;
            },
          },
          fixed: {
            enabled: true,
            position: 'topRight',
            offsetX: 0,
            offsetY: 0,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '35%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        title: {
          text: 'Total descuentos',
          align: 'left',
        },
        xaxis: {
          categories: ['Total descuentos'],
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: 'green',
            },
            labels: {
              style: {
                colors: 'green',
              },
            },
            title: {
              text: 'Total monto descuentos (S/.)',
              style: {
                color: 'green',
              },
            },
          },
        ],
      };
      return configuracionGraficos;
    },
    chartOptionsTotalAportes() {
      let configuracionGraficos = {
        chart: {
          type: 'bar',
          height: 350,
          width: 360,
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: '16px',
            fontFamily: 'Branding-Medium',
          },
          x: {
            show: true,
          },
          y: {
            formatter: function(value) {
              var formatter = new Intl.NumberFormat('es-PE', {
                style: 'currency',
                currency: 'PEN',
              });

              return `${formatter.format(value)}`;
            },
          },
          fixed: {
            enabled: true,
            position: 'topRight',
            offsetX: 0,
            offsetY: 0,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '35%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        title: {
          text: 'Total aportes del empleador',
          align: 'left',
        },
        xaxis: {
          categories: ['Aportes empleador'],
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: 'orange',
            },
            labels: {
              style: {
                colors: 'orange',
              },
            },
            title: {
              text: 'Total aportes empleador (S/.)',
              style: {
                color: 'orange',
              },
            },
          },
        ],
      };
      return configuracionGraficos;
    },
    esPlanillaRemuneracion() {
      return this.nuevaPlanillaCreada.tipo_proceso === 'Remuneración';
    },
  },
  mounted() {
    this.totalIngresos = 0;
    this.totalDescuentos = 0;
    this.totalAportes = 0;
    switch (this.nuevaPlanillaCreada.tipo_proceso) {
      case 'Remuneración':
        this.procesarPlanillaRemuneracion();
        break;
      case 'CTS':
        this.procesarPlanillaCTS();
        break;
      case 'Gratificación':
        this.procesarPlanillaGratificacion();
        break;
      case 'Liquidación':
        this.procesarPlanillaLiquidacion();
        break;
      // case 'Utilidades':
      //   this.procesarPlanillaUtilidades();
      //   break;
      case 'Vacaciones':
        this.procesarPlanillaVacaciones();
        break;
      case 'Locación de servicio':
        this.procesarPlanillaLocacionDeServicio();
        break;
      case 'Adelanto de sueldo':
        this.procesarPlanillaAdelantoDeSueldo();
        break;

      default:
        this.showErrorAlert('No se ha seleccionado un tipo de planilla válido');
        break;
    }
  },
  methods: {
    procesarGraficosPorIngresosDescuentosYAportes() {
      this.nuevaPlanillaCreada.personal.map((persona) => {
        persona.ingresos_por_concepto.map((ingreso) => {
          this.totalIngresos = this.totalIngresos + ingreso.monto;
          return ingreso;
        });
        persona.descuentos_por_concepto.map((descuento) => {
          this.totalDescuentos = this.totalDescuentos + descuento.monto;
          return descuento;
        });
        persona.aportes_empleador_por_concepto.map((aporte) => {
          this.totalAportes = this.totalAportes + aporte.monto;
          return aporte;
        });

        return persona;
      });

      //redondear valores
      this.totalIngresos = Math.round(this.totalIngresos * 100) / 100;
      this.totalDescuentos = Math.round(this.totalDescuentos * 100) / 100;
      this.totalAportes = Math.round(this.totalAportes * 100) / 100;

      this.prepararGraficos();
    },
    procesarDatosParaTablaSinGraficos() {
      let totalTrabajadores = this.nuevaPlanillaCreada.personal.length;
      let totalIngresos = 0;
      let totalDescuentos = 0;
      let totalAportes = 0;
      let totalNeto = 0;

      this.nuevaPlanillaCreada.personal.map((persona) => {
        // console.log(persona);

        persona.ingresos_por_concepto.map((ingreso) => {
          totalIngresos = totalIngresos + ingreso.monto;
          return ingreso;
        });
        persona.descuentos_por_concepto.map((descuento) => {
          totalDescuentos = totalDescuentos + descuento.monto;
          return descuento;
        });
        persona.aportes_empleador_por_concepto.map((aporte) => {
          totalAportes = totalAportes + aporte.monto;
          return aporte;
        });

        return persona;
      });

      totalNeto = totalIngresos - totalDescuentos;

      //redondear valores
      totalIngresos = Math.round(totalIngresos * 100) / 100;
      totalDescuentos = Math.round(totalDescuentos * 100) / 100;
      totalAportes = Math.round(totalAportes * 100) / 100;
      totalNeto = Math.round(totalNeto * 100) / 100;

      this.items = [
        {
          totalTrabajadores,
          totalIngresos,
          totalDescuentos,
          totalAportes,
          totalNeto,
        },
      ];
      this.showLoadingDialog = false;
    },
    procesarPlanillaRemuneracion() {
      this.procesarGraficosPorIngresosDescuentosYAportes();
    },
    async procesarPlanillaCTS() {
      let totalTrabajadores = this.nuevaPlanillaCreada.personal.length;
      let totalIngresos = 0;
      let totalDescuentos = 0;
      let totalAportes = 0;
      let totalNeto = 0;

      this.nuevaPlanillaCreada.personal.map((persona) => {
        totalIngresos = totalIngresos + Number(persona.montoAPagarPorCTS);
        return persona;
      });

      totalNeto = totalIngresos - totalDescuentos;

      //redondear valores
      totalIngresos = Math.round(totalIngresos * 100) / 100;
      totalDescuentos = Math.round(totalDescuentos * 100) / 100;
      totalAportes = Math.round(totalAportes * 100) / 100;
      totalNeto = Math.round(totalNeto * 100) / 100;

      this.items = [
        {
          totalTrabajadores,
          totalIngresos,
          totalDescuentos,
          totalAportes,
          totalNeto,
        },
      ];
      this.showLoadingDialog = false;
    },
    async procesarPlanillaGratificacion() {
      // console.log(this.nuevaPlanillaCreada);

      let totalTrabajadores = this.nuevaPlanillaCreada.personal.length;
      let totalIngresos = 0;
      let totalDescuentos = 0;
      let totalAportes = 0;
      let totalNeto = 0;

      this.nuevaPlanillaCreada.personal.map((persona) => {
        totalIngresos =
          totalIngresos + Number(persona.montoAPagarPorGratificacion);

        persona.descuentos_por_concepto.map((descuento) => {
          totalDescuentos = totalDescuentos + descuento.monto;
          return descuento;
        });

        return persona;
      });

      totalNeto = totalIngresos - totalDescuentos;

      //redondear valores
      totalIngresos = Math.round(totalIngresos * 100) / 100;
      totalDescuentos = Math.round(totalDescuentos * 100) / 100;
      totalAportes = Math.round(totalAportes * 100) / 100;
      totalNeto = Math.round(totalNeto * 100) / 100;

      this.items = [
        {
          totalTrabajadores,
          totalIngresos,
          totalDescuentos,
          totalAportes,
          totalNeto,
        },
      ];
      this.showLoadingDialog = false;
    },
    async procesarPlanillaLiquidacion() {
      this.procesarDatosParaTablaSinGraficos();
    },
    // async procesarPlanillaUtilidades() {
    //   console.log('procesar planilla Utilidades');
    //   //911
    // },
    async procesarPlanillaVacaciones() {
      // console.log(this.nuevaPlanillaCreada);
      this.procesarDatosParaTablaSinGraficos();
    },
    async procesarPlanillaLocacionDeServicio() {
      this.procesarDatosParaTablaSinGraficos();
    },
    async procesarPlanillaAdelantoDeSueldo() {
      this.procesarDatosParaTablaSinGraficos();
    },
    async procesarDatosDelosGraficos(planillas) {
      let totalMontoIngresos = 0;
      let totalMontoDescuentos = 0;
      let totalMontoAportes = 0;
      let periodoPlanillaAnterior = '';

      planillas.map((planillaPeriodoAnterior) => {
        periodoPlanillaAnterior = planillaPeriodoAnterior.periodo;

        planillaPeriodoAnterior.personal.map((persona) => {
          persona.ingresos_por_concepto.map((ingreso) => {
            totalMontoIngresos = totalMontoIngresos + ingreso.monto;
            return ingreso;
          });
          persona.descuentos_por_concepto.map((descuento) => {
            totalMontoDescuentos = totalMontoDescuentos + descuento.monto;
            return descuento;
          });
          persona.aportes_empleador_por_concepto.map((aporte) => {
            totalMontoAportes = totalMontoAportes + aporte.monto;
            return aporte;
          });

          return persona;
        });

        totalMontoIngresos = Math.round(totalMontoIngresos * 100) / 100;
        totalMontoDescuentos = Math.round(totalMontoDescuentos * 100) / 100;
        totalMontoAportes = Math.round(totalMontoAportes * 100) / 100;

        return planillaPeriodoAnterior;
      });

      const nuevoGraficoMontoIngresos = {
        name: periodoPlanillaAnterior,
        type: 'column',
        data: [totalMontoIngresos],
      };
      const nuevoGraficoMontoDescuentos = {
        name: periodoPlanillaAnterior,
        type: 'column',
        data: [totalMontoDescuentos],
      };
      const nuevoGraficoAportes = {
        name: periodoPlanillaAnterior,
        type: 'column',
        data: [totalMontoAportes],
      };

      this.seriesTotalIngresos.unshift(nuevoGraficoMontoIngresos);
      this.seriesTotalDescuentos.unshift(nuevoGraficoMontoDescuentos);
      this.seriesTotalAportes.unshift(nuevoGraficoAportes);
    },
    async prepararGraficos() {
      const mesPeriodoActual = this.nuevaPlanillaCreada.periodo.split('-')[0];
      const anioPeriodoActual = this.nuevaPlanillaCreada.periodo.split('-')[1];

      const mesDelAnio = this.mesesDelAnio.find(
        (mes) => mes.mes === mesPeriodoActual
      );

      if (mesDelAnio.numeroMes === 1) {
        //Enero
        //Buscar planilla de remuneración de Noviembre del año anterior al evaluado
        if (this.planillasNoviembreAnioPasado.length > 0) {
          this.procesarDatosDelosGraficos(this.planillasNoviembreAnioPasado);
        }

        //Buscar planilla de remuneración de Diciembre del año anterior al evaluado
        if (this.planillasDiciembreAnioPasado.length > 0) {
          this.procesarDatosDelosGraficos(this.planillasDiciembreAnioPasado);
        }
      }
      if (mesDelAnio.numeroMes === 2) {
        //Febrero
        const enero = this.mesesDelAnio.find((mes) => mes.numeroMes === 1);

        //Buscar la planilla de remuneración de Enero
        const planillasEnero = this.planillasRemuneracionDelAnioDelPeriodo.filter(
          (planilla) => planilla.periodo === `${enero.mes}-${anioPeriodoActual}`
        );

        if (planillasEnero.length > 0) {
          this.procesarDatosDelosGraficos(planillasEnero);
        }

        //Buscar planilla de remuneración de Diciembre del año anterior al evaluado
        if (this.planillasDiciembreAnioPasado.length > 0) {
          this.procesarDatosDelosGraficos(this.planillasDiciembreAnioPasado);
        }
      }
      if (mesDelAnio.numeroMes > 2) {
        //Resto de meses y filtro para buscar únicamente los dos periodos anteriores al actual
        for (let index = 1; index < 3; index++) {
          const periodoAnterior = this.mesesDelAnio.find(
            (mes) => mes.numeroMes === mesDelAnio.numeroMes - index
          );

          const planillasEncontradas = this.planillasRemuneracionDelAnioDelPeriodo.filter(
            (planilla) =>
              planilla.periodo === `${periodoAnterior.mes}-${anioPeriodoActual}`
          );

          if (planillasEncontradas.length > 0) {
            this.procesarDatosDelosGraficos(planillasEncontradas);
          }
        }
      }

      this.seriesTotalIngresos.push({
        name: this.nuevaPlanillaCreada.periodo,
        type: 'column',
        data: [this.totalIngresos],
      });

      this.seriesTotalDescuentos.push({
        name: this.nuevaPlanillaCreada.periodo,
        type: 'column',
        data: [this.totalDescuentos],
      });

      this.seriesTotalAportes.push({
        name: this.nuevaPlanillaCreada.periodo,
        type: 'column',
        data: [this.totalAportes],
      });

      this.showLoadingDialog = false;
    },
    borrarDatosDeIngresos() {
      this.nuevaPlanillaCreada.personal.map((persona) => {
        persona.ingresos_por_concepto = [];
        persona.info_dias =
          !!persona.info_dias && persona.info_dias.length > 0 ? [] : null;
        persona.remuneracionComputable =
          !!persona.remuneracionComputable && persona.remuneracionComputable > 0
            ? 0
            : null;
        persona.ingresosAfectosAEsSaludPorPeriodo =
          !!persona.ingresosAfectosAEsSaludPorPeriodo &&
          persona.ingresosAfectosAEsSaludPorPeriodo.length > 0
            ? []
            : null;
        persona.montoAPagarPorCTS =
          !!persona.montoAPagarPorCTS && persona.montoAPagarPorCTS > 0
            ? 0
            : null;
        persona.cantidad_dias_efectivos = 0;
        persona.cantidad_dias_efectivos_beneficios_sociales = 0;
        persona.montoAPagarPorGratificacion =
          !!persona.montoAPagarPorGratificacion &&
          persona.montoAPagarPorGratificacion > 0
            ? 0
            : null;
        persona.cantidadDePlanillasDeRemuneracion =
          !!persona.cantidadDePlanillasDeRemuneracion &&
          persona.cantidadDePlanillasDeRemuneracion > 0
            ? 0
            : null;
        persona.cantidadDiasVacaciones =
          !!persona.cantidadDiasVacaciones && persona.cantidadDiasVacaciones > 0
            ? 0
            : null;
        persona.montoPorVacacionesTruncas =
          !!persona.montoPorVacacionesTruncas &&
          persona.montoPorVacacionesTruncas > 0
            ? 0
            : null;
        persona.vacacionesPendientes =
          !!persona.vacacionesPendientes && persona.vacacionesPendientes > 0
            ? 0
            : null;
        return persona;
      });
    },
    borrarDatosDeDescuentos() {
      this.nuevaPlanillaCreada.personal.map((persona) => {
        persona.aportes_empleador_por_concepto = [];
        persona.descuentos_por_concepto = [];
        return persona;
      });
    },
    volverAlPasoAnterior() {
      this.nuevaPlanillaCreada.personal.map((persona) => {
        persona.aportes_empleador_por_concepto = [];
        return persona;
      });

      if (this.nuevaPlanillaCreada.tipo_proceso === 'CTS') {
        this.borrarDatosDeIngresos();
        this.$store.state.procesoPlanilla.pasoDeProcesoActual = 2;
        return;
      }

      if (this.nuevaPlanillaCreada.tipo_proceso === 'Gratificación') {
        this.borrarDatosDeDescuentos();
        this.$store.state.procesoPlanilla.pasoDeProcesoActual = 3;
        return;
      }
      this.$store.state.procesoPlanilla.pasoDeProcesoActual = 4;
    },
    siguientePaso() {
      this.$store.state.procesoPlanilla.pasoDeProcesoActual = 1;
    },
    showSuccessAlert() {
      this.$swal.fire({
        title: `Operación realizada correctamente!`,
        text: '',
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    showErrorAlert(text) {
      this.$swal.fire({
        title: `Error`,
        text,
        icon: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    showCompleteAlert() {
      this.$swal
        .fire({
          title: `Atención!`,
          text: 'Está seguro que desea cerrar esta planilla?',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonColor: '#d33',
          cancelButtonText: 'No',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Sí',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.nuevaPlanillaCreada.estado = 'cerrado';

            const {
              error,
              tokenExpired,
              planillaActualizada,
            } = await editarPlanilla(this.nuevaPlanillaCreada);
            if (error) {
              //do something
            }
            if (tokenExpired) {
              routeToLoginIfTokenIsExpired(this);
            }
            if (planillaActualizada) {
              //si la planilla se guardó correctamente, entonces actualizar al personal
              this.nuevaPlanillaCreada.personal.map(async (persona) => {
                if (persona.actualizarCreditos) {
                  await editarCreditos(persona);
                }
                return persona;
              });

              this.$store.state.personalParaProceso = [];
              this.$store.state.planillasNoviembreAnioPasado = [];
              this.$store.state.planillasDiciembreAnioPasado = [];
              this.showSuccessAlert();
              this.siguientePaso();
            }
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/main.scss';
.v-card {
  overflow-y: auto;
}
.v-card__text,
.v-card__title {
  word-break: normal;
}
</style>
