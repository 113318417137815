<template>
  <v-container fluid>
    <v-card class="m-auto" v-if="!showKickOffPlans">
      <v-card-title class="justify-center title-form text-xl-h4"
        >Registro</v-card-title
      >
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container>
            <v-col>
              <v-row rows="12" md="4">
                <v-text-field
                  type="number"
                  v-model.trim="user.ruc"
                  label="RUC"
                  required
                  :rules="rucRules"
                ></v-text-field>
              </v-row>
              <v-row rows="12" md="4">
                <v-text-field
                  v-model.trim="user.razonSocial"
                  label="Razón Social"
                  required
                  :rules="fieldRules"
                ></v-text-field>
              </v-row>
              <v-row rows="12" md="4">
                <v-text-field
                  v-model="user.name"
                  label="Nombres"
                  required
                  :rules="nameRules"
                ></v-text-field>
              </v-row>
              <v-row rows="12" md="4">
                <v-text-field
                  v-model="user.apellidos"
                  label="Apellidos"
                  required
                  :rules="nameRules"
                ></v-text-field>
              </v-row>
              <v-row rows="12" md="4">
                <v-text-field
                  v-model.trim="user.dni"
                  label="DNI"
                  required
                  :rules="doiRules"
                ></v-text-field>
              </v-row>
              <v-row rows="12" md="4">
                <v-text-field
                  v-model.trim="user.username"
                  label="Usuario o nickname"
                  required
                  :rules="fieldRules"
                ></v-text-field>
              </v-row>
              <v-row rows="12" md="4">
                <v-text-field
                  v-model.trim="user.email"
                  label="Correo"
                  required
                  :rules="emailRules"
                ></v-text-field>
              </v-row>
              <v-row rows="12" md="4">
                <v-text-field
                  type="password"
                  v-model.trim="user.password"
                  label="Contraseña"
                  required
                  :rules="fieldRules"
                ></v-text-field>
              </v-row>
              <v-row rows="12" md="4">
                <v-text-field
                  @keyup="verifiedPassword()"
                  type="password"
                  v-model.trim="confirmPassword"
                  label="Escribe nuevamente la contraseña"
                  required
                  :rules="fieldRules"
                ></v-text-field>
              </v-row>
              <span class="red--text" v-if="error">{{ msj }}</span>
              <v-row
                rows="12"
                md="4"
                class="justify-center contenedor-checkbox-politica"
              >
                <v-checkbox v-model="aceptaPoliticasDePrivacidad" />
                <span>
                  Acepto la
                  <span
                    style="color: blue; cursor: pointer"
                    @click="verPoliticaPrivacidad"
                    >Política de Privacidad</span
                  >
                </span>
              </v-row>
            </v-col>
            <div class="captcha_container">
              <vue-recaptcha
                @verify="onVerify"
                @expired="onExpired"
                :sitekey="sitekey"
              >
              </vue-recaptcha>
            </div>
            <v-btn
              class="registrar"
              @click="newUser()"
              normal
              outlined
              :disabled="!loginVerified || !aceptaPoliticasDePrivacidad"
            >
              Registrar
            </v-btn>
            <v-col class="d-flex justify-center">
              <v-img
                :src="require('@/assets/img/nuevoLogo.svg')"
                @click="enviarAHome"
                max-width="6.5vw"
                class="mt-3"
              />
            </v-col>
          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
    <KickOffPlans v-else :nuevoCliente="false" />
  </v-container>
</template>
<script>
import { mapState } from 'vuex';
import VueRecaptcha from 'vue-recaptcha';

import { register } from '@/api/auth/register.js';
import KickOffPlans from '@/components/landingPage/plans/KickOffPlans';

const recaptchaKey = process.env.VUE_APP_RECAPTCHA;

export default {
  name: 'Register',
  data: () => ({
    aceptaPoliticasDePrivacidad: false,
    loginVerified: false,
    error: false,
    msj: '',
    valid: true,
    confirmPassword: '',
    user: {
      ruc: '',
      dni: '',
      apellidos: '',
      razonSocial: '',
      password: '',
      name: '',
      username: '',
      email: '',
    },
    doiRules: [
      (v) => !!v || 'Campo requerido',
      (v) => v.length >= 3 || 'Mínimo 3 caracteres',
      (v) => v.length < 15 || 'Máximo 14 caracteres',
    ],
    nameRules: [
      (v) => !!v || 'Campo requerido',
      (v) => v.length >= 3 || 'Mínimo 3 caracteres',
      (v) => v.length < 31 || 'Máximo 30 caracteres',
    ],
    fieldRules: [
      (v) => !!v || 'Campo requerido',
      (v) => v.length >= 3 || 'Mínimo 3 caracteres',
    ],
    emailRules: [
      (v) => !!v || 'E-mail requerido',
      (v) => /.+@.+/.test(v) || 'E-mail debe ser válido',
      (v) =>
        v.length < 101 ||
        'El email debe tener máximo 200 caracteres de longitud',
    ],
    rucRules: [
      (v) => !!v || 'RUC requerido',
      (v) => v.length === 11 || 'El RUC debe tener 11 dígitos',
    ],
  }),
  computed: {
    ...mapState(['showKickOffPlans', 'nuevoRegistro']),
    sitekey() {
      return recaptchaKey;
    },
  },
  components: {
    KickOffPlans,
    VueRecaptcha,
  },
  methods: {
    enviarAHome() {
      this.$router.push({
        path: '/',
      });
    },
    verPoliticaPrivacidad() {
      let newWindow = window.open(
        'https://cti-roads-assets.s3.us-west-2.amazonaws.com/Privacy+Policy+CTI+Roads.pdf',
        '_blank'
      );
      newWindow.opener = null;
    },
    onVerify() {
      this.loginVerified = true;
    },
    onExpired() {
      this.loginVerified = false;
    },
    verifiedPassword() {
      if (this.confirmPassword != this.user.password) {
        this.error = true;
        this.msj = 'Las contraseñas no coinciden';
      } else {
        this.error = false;
        this.msj = '';
      }
    },
    async newUser() {
      if (this.$refs.form.validate()) {
        const { error, token, client } = await register(this.user);
        if (error) {
          //Debe retornar una alerta con el error --TODO
          return console.log('Error: ', error);
        }
        if (token && client) {
          this.$store.state.cliente = client;
          localStorage.setItem('token', token);
          if (!this.$store.state.planSeleccionado.tipo_plan) {
            this.$store.state.showKickOffPlans = true;
            return;
          }
          if (
            this.$store.state.planSeleccionado.tipo_plan === 'CTIEmprendedor'
          ) {
            this.$router.push({
              path: '/dashboard',
            });
            return;
          }
          this.$store.state.nuevoRegistro = true;
          this.$router.push({
            path: '/payment',
          });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'src/styles/main.scss';
.captcha_container {
  display: inline-table !important;
}
.selected_plan {
  position: relative;
  top: 60px;
  font-size: xx-large;
  color: #1b4c2a;
}
.hide {
  display: none;
}
.show {
  display: block;
  color: green;
  padding: 10px;
}
.m-auto {
  text-align: center;
  width: 450px !important;
  padding: 25px;
  margin: 100px auto !important;
  .title-form {
    font-size: 24px;
    color: $main;
  }
}
.registrar {
  width: 250px;
  background: #fff;
  margin: auto !important;
  border: 2px solid $main;
  border-radius: 5px;
  color: $main;
  font-weight: bold;
}
.contenedor-checkbox-politica {
  align-items: center !important;
}
</style>
