<template>
  <v-card height="50vh">
    <v-progress-circular
      v-if="showLoadingDialog"
      :size="180"
      :width="7"
      color="teal"
      indeterminate
    ></v-progress-circular>
    <v-tabs vertical v-else>
      <v-tab>
        <v-icon left>
          mdi-chart-line
        </v-icon>
        Aportes
      </v-tab>
      <!-- <v-tab>
        <v-icon left>
          mdi-lock
        </v-icon>
        Conceptos
      </v-tab> -->
      <v-tab @click="siguientePaso">
        <v-icon left color="teal">
          mdi-checkbox-marked-outline
        </v-icon>
        Continuar
      </v-tab>
      <v-tab @click="volverAlPasoAnterior">
        <v-icon left color="red">
          mdi-undo
        </v-icon>
        Anterior
      </v-tab>

      <v-tab-item>
        <v-card flat :key="renderTable">
          <v-card-text v-if="esPlanillaRemuneracion">
            <!-- <v-toolbar flat>
              <v-spacer></v-spacer>
              <v-toolbar-title>
                <v-btn color="teal" dark>Editar jornada</v-btn>
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar> -->
            <v-row>
              <v-spacer></v-spacer>
              <apexchart
                type="bar"
                height="320"
                :options="chartOptionsTotalAportesEsSalud"
                :series="seriesTotalAportesEsSalud"
              ></apexchart>
              <v-spacer></v-spacer>
              <apexchart
                type="bar"
                height="320"
                :options="chartOptionsTotalAportesEPS"
                :series="seriesTotalAportesEPS"
              ></apexchart>
              <v-spacer></v-spacer>
            </v-row>
          </v-card-text>
          <v-card-text v-else>
            <v-data-table
              no-results-text="No se encontraron registros..."
              :headers="headers"
              :loading="tableLoading"
              loading-text="Cargando conceptos... Espere..."
              :items="conceptosTabla"
              class="elevation-1"
              :search="search"
              no-data-text="No hay descuentos aún..."
              item-key="codigo"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-spacer></v-spacer>
                  <v-toolbar-title class="top-bar-title"
                    >Aportes</v-toolbar-title
                  >
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar concepto..."
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-spacer></v-spacer>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <!-- <v-tab-item>
        <v-row no-gutters>
          <v-col>
            <v-card flat>
              <v-card-title class="text-h5 justify-center">
                Agregar aportes del empleador por concepto
              </v-card-title>
              <v-card-text>
                <v-row class="d-flex flex-column" no-gutters>
                  <v-col>
                    <v-card-title
                      class="text-subtitle-1 justify-center"
                      style="color: teal; font-weight: bold"
                    >
                      Importar masivamente desde Excel?
                    </v-card-title>
                  </v-col>
                  <v-col>
                    <v-row>
                      <v-spacer></v-spacer>
                      <v-switch
                        v-model="importaDesdeExcel"
                        label="Sí"
                      ></v-switch>
                      <v-spacer></v-spacer>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text v-if="importaDesdeExcel">
                <p>
                  esto es true, osea aquí mostrar todo lo de excel
                </p>
              </v-card-text>
              <v-card-text v-else>
                <p>
                  esto es false
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card flat>
              <v-card-text>
                <p>
                  Morbi nec metus. Suspendisse faucibus, nunc et pellentesque
                  egestas, lacus ante convallis tellus, vitae iaculis lacus elit
                  id tortor. Sed mollis, eros et ultrices tempus, mauris ipsum
                  aliquam libero, non adipiscing dolor urna a orci. Curabitur
                  ligula sapien, tincidunt non, euismod vitae, posuere
                  imperdiet, leo. Nunc sed turpis.
                </p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item> -->
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>
              Continuar al siguiente paso...
            </p>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <p>
              VOLVER AL PASO ANTERIOR
            </p>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';

// import { getAssistance } from '@/api/persons/getAssistance.js';
// import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'Paso4',
  data: () => ({
    // mostrarImportacionDesdeExcel: false,
    renderTable: 0,
    tableLoading: false,
    importaDesdeExcel: false,
    showLoadingDialog: true,
    totalAportesEsSalud: 0,
    totalAportesEPS: 0,
    seriesTotalAportesEPS: [],
    seriesTotalAportesEsSalud: [],
    parametroRemuneracionMinimaVital: {},
    parametroSIS: {},
    parametroEsSalud: {},
    parametroEsSaludEPS: {},
    search: '',
    mesesDelAnio: [
      {
        mes: 'Enero',
        numeroMes: 1,
      },
      {
        mes: 'Febrero',
        numeroMes: 2,
      },
      {
        mes: 'Marzo',
        numeroMes: 3,
      },
      {
        mes: 'Abril',
        numeroMes: 4,
      },
      {
        mes: 'Mayo',
        numeroMes: 5,
      },
      {
        mes: 'Junio',
        numeroMes: 6,
      },
      {
        mes: 'Julio',
        numeroMes: 7,
      },
      {
        mes: 'Agosto',
        numeroMes: 8,
      },
      {
        mes: 'Septiembre',
        numeroMes: 9,
      },
      {
        mes: 'Octubre',
        numeroMes: 10,
      },
      {
        mes: 'Noviembre',
        numeroMes: 11,
      },
      {
        mes: 'Diciembre',
        numeroMes: 12,
      },
    ],
    headers: [
      {
        text: 'Código',
        value: 'codigo',
        width: '100px',
      },
      {
        text: 'Concepto',
        value: 'nombre',
      },
      {
        text: 'Monto',
        value: 'montoTotal',
      },
    ],
  }),
  components: {
    apexchart: VueApexCharts,
  },
  computed: {
    esPlanillaRemuneracion() {
      return this.nuevaPlanillaCreada.tipo_proceso === 'Remuneración';
    },
    ...mapState([
      'procesoPlanilla',
      'nuevaPlanillaCreada',
      'cliente',
      'conceptosSunat',
      'planillasRemuneracionDelAnioDelPeriodo',
      'planillasNoviembreAnioPasado',
      'planillasDiciembreAnioPasado',
      'planillasVacacionesDelAnioDelPeriodo',
    ]),
    chartOptionsTotalAportesEPS() {
      let configuracionGraficos = {
        chart: {
          type: 'bar',
          height: 350,
          width: 360,
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: '16px',
            fontFamily: 'Branding-Medium',
          },
          x: {
            show: true,
          },
          y: {
            formatter: function(value) {
              var formatter = new Intl.NumberFormat('es-PE', {
                style: 'currency',
                currency: 'PEN',
              });

              return `${formatter.format(value)}`;
            },
          },
          fixed: {
            enabled: true,
            position: 'topRight',
            offsetX: 0,
            offsetY: 0,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '35%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        title: {
          text: 'Total aportes por EPS',
          align: 'left',
        },
        xaxis: {
          categories: ['Aportes EPS'],
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#008FFB',
            },
            labels: {
              style: {
                colors: '#008FFB',
              },
            },
            title: {
              text: 'Total aportes por EPS (S/.)',
              style: {
                color: '#008FFB',
              },
            },
          },
        ],
      };
      return configuracionGraficos;
    },
    chartOptionsTotalAportesEsSalud() {
      let configuracionGraficos = {
        chart: {
          type: 'bar',
          height: 350,
          width: 360,
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: '16px',
            fontFamily: 'Branding-Medium',
          },
          x: {
            show: true,
          },
          y: {
            formatter: function(value) {
              var formatter = new Intl.NumberFormat('es-PE', {
                style: 'currency',
                currency: 'PEN',
              });

              return `${formatter.format(value)}`;
            },
          },
          fixed: {
            enabled: true,
            position: 'topRight',
            offsetX: 0,
            offsetY: 0,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '35%',
            endingShape: 'rounded',
          },
        },
        dataLabels: {
          enabled: false,
        },
        title: {
          text: 'Total aportes por EsSalud',
          align: 'left',
        },
        xaxis: {
          categories: ['Aportes EsSalud'],
        },
        yaxis: [
          {
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: 'green',
            },
            labels: {
              style: {
                colors: 'green',
              },
            },
            title: {
              text: 'Total aportes por EsSalud (S/.)',
              style: {
                color: 'green',
              },
            },
          },
        ],
      };
      return configuracionGraficos;
    },
    conceptosTabla() {
      let arrayAportes = [];

      this.nuevaPlanillaCreada.personal.map((persona) => {
        persona.aportes_empleador_por_concepto.map((aporte) => {
          let aporteEncontrado = arrayAportes.find(
            (aporteArray) => aporteArray.codigo === aporte.codigo
          );
          if (aporteEncontrado) {
            aporteEncontrado.montoTotal =
              aporteEncontrado.montoTotal + aporte.monto;
          } else {
            let nombreConcepto = this.conceptosSunat.find(
              (conceptoSunat) => conceptoSunat.codigo === aporte.codigo
            );
            if (nombreConcepto) {
              arrayAportes.push({
                nombre: nombreConcepto.nombre,
                codigo: aporte.codigo,
                montoTotal: aporte.monto,
              });
            }
          }

          arrayAportes.map((aporte) => {
            aporte.montoTotal = Math.round(aporte.montoTotal * 100) / 100;
            return aporte;
          });
          return aporte;
        });

        return persona;
      });

      return arrayAportes;
    },
  },
  mounted() {
    // console.log(this.procesoPlanilla);
    // console.log(this.cliente);
    // console.log(this.nuevaPlanillaCreada);

    this.parametroRemuneracionMinimaVital = this.cliente.datos_empresa.parametros.find(
      (parametro) => parametro.concepto === 'REMUNERACION MINIMA VITAL'
    );
    this.parametroSIS = this.cliente.datos_empresa.parametros.find(
      (parametro) => parametro.concepto === 'SIS'
    );
    this.parametroEsSalud = this.cliente.datos_empresa.parametros.find(
      (parametro) => parametro.concepto === 'ESSALUD'
    );
    this.parametroEsSaludEPS = this.cliente.datos_empresa.parametros.find(
      (parametro) => parametro.concepto === 'ESSALUD - EPS'
    );

    this.totalAportesEsSalud = 0;
    this.totalAportesEPS = 0;
    switch (this.nuevaPlanillaCreada.tipo_proceso) {
      case 'Remuneración':
        this.procesarPlanillaRemuneracion();
        break;
      // case 'CTS':
      //   this.procesarPlanillaCTS();
      //   break;
      // case 'Gratificación':
      //   this.procesarPlanillaGratificacion();
      //   break;
      case 'Liquidación':
        this.procesarPlanillaLiquidacion();
        break;
      // case 'Utilidades':
      //   this.procesarPlanillaUtilidades();
      //   break;
      case 'Vacaciones':
        this.procesarPlanillaVacaciones();
        break;

      default:
        this.showErrorAlert('No se ha seleccionado un tipo de planilla válido');
        break;
    }
  },
  methods: {
    procesarPlanillaRemuneracion() {
      //buscar en la planilla de Vacaciones (si existe) los aportes del empleador para sumarlos en este periodo

      const mesDelPeriodo = this.mesesDelAnio.find(
        (mes) => mes.mes === this.nuevaPlanillaCreada.periodo.split('-')[0]
      );
      const planillaVacacionesDelPeriodo = this.planillasVacacionesDelAnioDelPeriodo.filter(
        (planillaVacaciones) =>
          planillaVacaciones.periodo.split('-')[0] === mesDelPeriodo.mes
      );
      this.nuevaPlanillaCreada.personal.map((persona) => {
        let montoTotalRegistradoPorAportes = 0;
        let montoTotalIngresosAfectosAEsSalud = 0;
        //buscar monto de ingresos afectos a EsSalud en este periodo
        persona.ingresos_por_concepto.map((ingreso) => {
          let ingresoAfectoAEsSalud = this.conceptosSunat.find(
            (concepto) =>
              concepto.codigo === ingreso.codigo &&
              concepto.afectacion_empleador.essalud_seguro === 'SI'
          );
          if (ingresoAfectoAEsSalud)
            montoTotalIngresosAfectosAEsSalud =
              montoTotalIngresosAfectosAEsSalud + ingreso.monto;
          return ingreso;
        });

        //buscar monto de aportes del empleador
        planillaVacacionesDelPeriodo.map((planillaVacaciones) => {
          const personaEncontrada = planillaVacaciones.personal.find(
            (personaVacaciones) =>
              personaVacaciones.doi.toString() === persona.doi.toString()
          );
          if (personaEncontrada && personaEncontrada.doi) {
            let aporteEncontradoEmpleador = personaEncontrada.aportes_empleador_por_concepto.find(
              (aporte) => aporte.codigo === '0804'
              // montoTotalRegistradoPorAportes =
              //   montoTotalRegistradoPorAportes + aporte.monto
            );

            if (
              aporteEncontradoEmpleador &&
              aporteEncontradoEmpleador.monto > 0
            ) {
              montoTotalRegistradoPorAportes =
                montoTotalRegistradoPorAportes +
                aporteEncontradoEmpleador.monto;
            }

            //buscar monto de ingresos afectos a EsSalud en este periodo de vacaciones
            personaEncontrada.ingresos_por_concepto.map((ingreso) => {
              let ingresoAfectoAEsSalud = this.conceptosSunat.find(
                (concepto) =>
                  concepto.codigo === ingreso.codigo &&
                  concepto.afectacion_empleador.essalud_seguro === 'SI'
              );
              if (ingresoAfectoAEsSalud)
                montoTotalIngresosAfectosAEsSalud =
                  montoTotalIngresosAfectosAEsSalud + ingreso.monto;
              return ingreso;
            });
          }
          return planillaVacaciones;
        });

        if (persona.tipo_seguro === 'SIS') {
          let aporteEncontrado = persona.aportes_empleador_por_concepto.find(
            (aporte) => aporte.codigo === '0811'
          );
          if (aporteEncontrado) {
            aporteEncontrado.monto =
              aporteEncontrado.monto +
              Number(this.parametroSIS.monto) -
              montoTotalRegistradoPorAportes;
            if (aporteEncontrado.monto < 0) {
              aporteEncontrado.monto = 0;
            }
          } else {
            let monto =
              Number(this.parametroSIS.monto) - montoTotalRegistradoPorAportes;
            if (monto < 0) {
              monto = 0;
            }
            persona.aportes_empleador_por_concepto.push({
              codigo: '0811',
              monto,
            });
          }
        }
        if (persona.tipo_seguro === 'ESSALUD') {
          //essalud
          //descontar el 9% y ese monto cargarlo en el concepto 804
          let montoPorEsSalud = 0;
          // montoTotalIngresos * Number(pension.aporte_obligatorio);
          if (
            montoTotalIngresosAfectosAEsSalud <
            Number(this.parametroRemuneracionMinimaVital.monto)
          ) {
            montoPorEsSalud =
              Number(this.parametroRemuneracionMinimaVital.monto) *
                Number(this.parametroEsSalud.porcentaje) -
              montoTotalRegistradoPorAportes;
          } else {
            montoPorEsSalud =
              montoTotalIngresosAfectosAEsSalud *
                Number(this.parametroEsSalud.porcentaje) -
              montoTotalRegistradoPorAportes;
          }
          montoPorEsSalud = Math.round(montoPorEsSalud * 100) / 100;

          let aporteEncontrado = persona.aportes_empleador_por_concepto.find(
            (aporte) => aporte.codigo === '0804'
          );
          if (aporteEncontrado) {
            aporteEncontrado.monto = aporteEncontrado.monto + montoPorEsSalud;
          } else {
            persona.aportes_empleador_por_concepto.push({
              codigo: '0804',
              monto: montoPorEsSalud,
            });
          }
          // console.log(persona);
        }
        if (persona.tipo_seguro === 'EPS') {
          // eps
          //si el monto es menor a this.parametroRemuneracionMinimaVital entonces el tope es this.parametroRemuneracionMinimaVital
          let montoAportePorEPS = 0;
          let montoAsumeEmpleador =
            persona.monto_descuento_eps -
            persona.monto_descuento_eps * (persona.porcentaje_pago_eps / 100);
          //descontar el 2.25 y enviarlo al concepto 809
          let montoConceptoEsSaludEPS =
            montoTotalIngresosAfectosAEsSalud *
            Number(this.parametroEsSaludEPS.porcentaje);
          // console.log(
          //   montoAsumeEmpleador, montoConceptoEsSaludEPS
          // );

          montoConceptoEsSaludEPS < montoAsumeEmpleador
            ? (montoAportePorEPS = montoAsumeEmpleador)
            : (montoAportePorEPS = montoConceptoEsSaludEPS);
          // del monto total de ingresos afectos a EPS

          let aporteEPSEncontrado = persona.aportes_empleador_por_concepto.find(
            (aporte) => aporte.codigo === '0809'
          );
          if (aporteEPSEncontrado) {
            aporteEPSEncontrado.monto =
              aporteEPSEncontrado.monto + montoAportePorEPS;
          } else {
            persona.aportes_empleador_por_concepto.push({
              codigo: '0809',
              monto: montoAportePorEPS,
            });
          }

          //descontar el 6.75 y enviarlo al concepto 804
          // del monto total de ingresos afectos a Essalud
          let montoAportePorEsSalud = 0;
          let porcentajeEsSalud =
            Number(this.parametroEsSalud.porcentaje) -
            Number(this.parametroEsSaludEPS.porcentaje);
          // montoTotalIngresos * Number(pension.aporte_obligatorio);

          if (
            montoTotalIngresosAfectosAEsSalud <
            Number(this.parametroRemuneracionMinimaVital.monto)
          ) {
            montoAportePorEsSalud =
              Number(this.parametroRemuneracionMinimaVital.monto) *
              porcentajeEsSalud;
          } else {
            montoAportePorEsSalud =
              montoTotalIngresosAfectosAEsSalud * porcentajeEsSalud;
          }
          montoAportePorEsSalud = Math.round(montoAportePorEsSalud * 100) / 100;

          let aporteEncontrado = persona.aportes_empleador_por_concepto.find(
            (aporte) => aporte.codigo === '0804'
          );
          if (aporteEncontrado) {
            aporteEncontrado.monto =
              aporteEncontrado.monto +
              montoAportePorEsSalud -
              montoTotalRegistradoPorAportes;
            if (aporteEncontrado.monto < 0) {
              aporteEncontrado.monto = 0;
            }
          } else {
            let monto = montoAportePorEsSalud - montoTotalRegistradoPorAportes;
            if (monto < 0) {
              monto = 0;
            }

            persona.aportes_empleador_por_concepto.push({
              codigo: '0804',
              monto,
            });
          }
        }

        //calcular montos para los gráficos
        persona.aportes_empleador_por_concepto.map((aporte) => {
          if (aporte.codigo === '0804' || aporte.codigo === '0811') {
            this.totalAportesEsSalud = this.totalAportesEsSalud + aporte.monto;
          }

          if (aporte.codigo === '0809') {
            this.totalAportesEPS = this.totalAportesEPS + aporte.monto;
          }

          return aporte;
        });
        return persona;
      });

      //redondeando totales
      this.totalAportesEsSalud =
        Math.round(this.totalAportesEsSalud * 100) / 100;
      this.totalAportesEPS = Math.round(this.totalAportesEPS * 100) / 100;

      this.prepararGraficos();
    },
    // async procesarPlanillaCTS() {
    //   console.log('procesar planilla CTS');
    //   //0904
    // },
    // async procesarPlanillaGratificacion() {
    //   console.log('procesar planilla Gratificación');
    // },
    async procesarPlanillaLiquidacion() {
      // console.log('procesar planilla Liquidación');
      this.procesarDatosParaTablaSinGrafico();
      // this.siguientePaso()
    },
    // async procesarPlanillaUtilidades() {
    //   // console.log('procesar planilla Utilidades');
    //   //911
    // },
    procesarDatosParaTablaSinGrafico() {
      const fechaInicio = moment(this.procesoPlanilla.fechaInicio).tz(
        'America/Lima'
      );
      const fechaFin = moment(this.procesoPlanilla.fechaFin).tz('America/Lima');

      let cantidadDiasPeriodoPlanilla = fechaFin.diff(fechaInicio, 'days') + 1;

      this.nuevaPlanillaCreada.personal.map((persona) => {
        let montoTotalIngresosAfectosAEsSalud = 0;

        persona.ingresos_por_concepto.map((ingreso) => {
          let ingresoAfectoAEsSalud = this.conceptosSunat.find(
            (concepto) =>
              concepto.codigo === ingreso.codigo &&
              concepto.afectacion_empleador.essalud_seguro === 'SI'
          );
          if (ingresoAfectoAEsSalud)
            montoTotalIngresosAfectosAEsSalud =
              montoTotalIngresosAfectosAEsSalud + ingreso.monto;
          return ingreso;
        });

        let cantidadDiasEfectivos = persona.cantidadDiasVacaciones;
        //si la persona ha cesado hay q considerar solo los días desde la fecha de inicio del periodo hasta la fecha de cese
        if (this.nuevaPlanillaCreada.tipo_proceso === 'Liquidación') {
          const fechaCese = moment(persona.fecha_cese).tz('America/Lima');
          cantidadDiasPeriodoPlanilla = fechaCese.diff(fechaInicio, 'days') + 1;
          cantidadDiasEfectivos = cantidadDiasPeriodoPlanilla;
        }

        if (persona.tipo_seguro === 'SIS') {
          let aporteEncontrado = persona.aportes_empleador_por_concepto.find(
            (aporte) => aporte.codigo === '0811'
          );
          // let montoAporte =
          //   (Number(this.parametroSIS.monto) / 30) *
          //   persona.cantidadDiasVacaciones;
          let montoAporte =
            (Number(this.parametroSIS.monto) / cantidadDiasPeriodoPlanilla) *
            cantidadDiasEfectivos;
          if (aporteEncontrado) {
            aporteEncontrado.monto = aporteEncontrado.monto + montoAporte;
          } else {
            persona.aportes_empleador_por_concepto.push({
              codigo: '0811',
              monto: montoAporte,
            });
          }
        }
        if (persona.tipo_seguro === 'ESSALUD') {
          //essalud
          //descontar el 9% y ese monto cargarlo en el concepto 804
          let montoPorEsSalud = 0;
          // montoTotalIngresos * Number(pension.aporte_obligatorio);

          if (
            montoTotalIngresosAfectosAEsSalud <
            Number(this.parametroRemuneracionMinimaVital.monto)
          ) {
            montoPorEsSalud =
              ((Number(this.parametroRemuneracionMinimaVital.monto) *
                Number(this.parametroEsSalud.porcentaje)) /
                cantidadDiasPeriodoPlanilla) *
              cantidadDiasEfectivos;
            // montoPorEsSalud =
            //   ((Number(this.parametroRemuneracionMinimaVital.monto) *
            //     Number(this.parametroEsSalud.porcentaje)) /
            //     30) *
            //   persona.cantidadDiasVacaciones;
          } else {
            montoPorEsSalud =
              ((montoTotalIngresosAfectosAEsSalud *
                Number(this.parametroEsSalud.porcentaje)) /
                cantidadDiasPeriodoPlanilla) *
              cantidadDiasEfectivos;
            // montoPorEsSalud =
            //   ((montoTotalIngresosAfectosAEsSalud *
            //     Number(this.parametroEsSalud.porcentaje)) /
            //     30) *
            //   persona.cantidadDiasVacaciones;
          }
          montoPorEsSalud = Math.round(montoPorEsSalud * 100) / 100;

          let aporteEncontrado = persona.aportes_empleador_por_concepto.find(
            (aporte) => aporte.codigo === '0804'
          );
          if (aporteEncontrado) {
            aporteEncontrado.monto = aporteEncontrado.monto + montoPorEsSalud;
          } else {
            persona.aportes_empleador_por_concepto.push({
              codigo: '0804',
              monto: montoPorEsSalud,
            });
          }
        }
        if (persona.tipo_seguro === 'EPS') {
          // eps
          //si el monto es menor a this.parametroRemuneracionMinimaVital entonces el tope es this.parametroRemuneracionMinimaVital
          let montoAportePorEPS = 0;
          let montoAsumeEmpleador =
            ((persona.monto_descuento_eps -
              persona.monto_descuento_eps *
                (persona.porcentaje_pago_eps / 100)) /
              cantidadDiasPeriodoPlanilla) *
            cantidadDiasEfectivos;
          // let montoAsumeEmpleador =
          //   ((persona.monto_descuento_eps -
          //     persona.monto_descuento_eps *
          //       (persona.porcentaje_pago_eps / 100)) /
          //     30) *
          //   persona.cantidadDiasVacaciones;
          //descontar el 2.25 y enviarlo al concepto 809
          let montoConceptoEsSaludEPS =
            ((montoTotalIngresosAfectosAEsSalud *
              Number(this.parametroEsSaludEPS.porcentaje)) /
              cantidadDiasPeriodoPlanilla) *
            cantidadDiasEfectivos;
          // let montoConceptoEsSaludEPS =
          //   ((montoTotalIngresosAfectosAEsSalud *
          //     Number(this.parametroEsSaludEPS.porcentaje)) /
          //     30) *
          //   persona.cantidadDiasVacaciones;
          // console.log(
          //   montoAsumeEmpleador, montoConceptoEsSaludEPS
          // );

          montoConceptoEsSaludEPS < montoAsumeEmpleador
            ? (montoAportePorEPS = montoAsumeEmpleador)
            : (montoAportePorEPS = montoConceptoEsSaludEPS);
          // del monto total de ingresos afectos a EPS

          let aporteEPSEncontrado = persona.aportes_empleador_por_concepto.find(
            (aporte) => aporte.codigo === '0809'
          );

          if (aporteEPSEncontrado) {
            aporteEPSEncontrado.monto =
              aporteEPSEncontrado.monto + montoAportePorEPS;
          } else {
            persona.aportes_empleador_por_concepto.push({
              codigo: '0809',
              monto: montoAportePorEPS,
            });
          }

          //descontar el 6.75 y enviarlo al concepto 804
          // del monto total de ingresos afectos a Essalud
          let montoAportePorEsSalud = 0;
          let porcentajeEsSalud =
            Number(this.parametroEsSalud.porcentaje) -
            Number(this.parametroEsSaludEPS.porcentaje);
          // montoTotalIngresos * Number(pension.aporte_obligatorio);

          if (
            montoTotalIngresosAfectosAEsSalud <
            Number(this.parametroRemuneracionMinimaVital.monto)
          ) {
            montoAportePorEsSalud =
              ((Number(this.parametroRemuneracionMinimaVital.monto) *
                porcentajeEsSalud) /
                cantidadDiasPeriodoPlanilla) *
              cantidadDiasEfectivos;
            // montoAportePorEsSalud =
            //   ((Number(this.parametroRemuneracionMinimaVital.monto) *
            //     porcentajeEsSalud) /
            //     30) *
            //   persona.cantidadDiasVacaciones;
          } else {
            montoAportePorEsSalud =
              ((montoTotalIngresosAfectosAEsSalud * porcentajeEsSalud) /
                cantidadDiasPeriodoPlanilla) *
              cantidadDiasEfectivos;
            // montoAportePorEsSalud =
            //   ((montoTotalIngresosAfectosAEsSalud * porcentajeEsSalud) / 30) *
            //   persona.cantidadDiasVacaciones;
          }
          montoAportePorEsSalud = Math.round(montoAportePorEsSalud * 100) / 100;

          let aporteEncontrado = persona.aportes_empleador_por_concepto.find(
            (aporte) => aporte.codigo === '0804'
          );
          if (aporteEncontrado) {
            aporteEncontrado.monto =
              aporteEncontrado.monto + montoAportePorEsSalud;
          } else {
            persona.aportes_empleador_por_concepto.push({
              codigo: '0804',
              monto: montoAportePorEsSalud,
            });
          }
        }

        //calcular montos para los gráficos
        // persona.aportes_empleador_por_concepto.map((aporte) => {
        //   if (aporte.codigo === '0804' || aporte.codigo === '0811') {
        //     this.totalAportesEsSalud = this.totalAportesEsSalud + aporte.monto;
        //   }

        //   if (aporte.codigo === '0809') {
        //     this.totalAportesEPS = this.totalAportesEPS + aporte.monto;
        //   }

        //   return aporte;
        // });
        return persona;
      });

      this.showLoadingDialog = false;
      this.tableLoading = false;
      this.renderTable++;
    },
    procesarPlanillaVacaciones() {
      this.procesarDatosParaTablaSinGrafico();
    },
    async procesarDatosDelosGraficos(planillas) {
      let totalMontoAportesEsSalud = 0;
      let totalMontoAportesEPS = 0;
      let periodoPlanillaAnterior = '';

      planillas.map((planillaPeriodoAnterior) => {
        periodoPlanillaAnterior = planillaPeriodoAnterior.periodo;

        planillaPeriodoAnterior.personal.map((persona) => {
          // let montoAportesEsSalud = 0;
          // let montoAportesEPS = 0;

          persona.aportes_empleador_por_concepto.map((aporte) => {
            //Buscar aportes por EsSalud
            if (aporte.codigo === '0804' || aporte.codigo === '0811') {
              totalMontoAportesEsSalud =
                totalMontoAportesEsSalud + aporte.monto;
            }

            //Buscar aportes por EPS
            if (aporte.codigo === '0809') {
              totalMontoAportesEPS = totalMontoAportesEPS + aporte.monto;
            }

            return aporte;
          });

          return persona;
        });

        totalMontoAportesEsSalud =
          Math.round(totalMontoAportesEsSalud * 100) / 100;

        totalMontoAportesEPS = Math.round(totalMontoAportesEPS * 100) / 100;

        return planillaPeriodoAnterior;
      });

      const nuevoGraficoMontoAportesEsSalud = {
        name: periodoPlanillaAnterior,
        type: 'column',
        data: [totalMontoAportesEsSalud],
      };
      const nuevoGraficoAportesEPS = {
        name: periodoPlanillaAnterior,
        type: 'column',
        data: [totalMontoAportesEPS],
      };

      this.seriesTotalAportesEsSalud.unshift(nuevoGraficoMontoAportesEsSalud);
      this.seriesTotalAportesEPS.unshift(nuevoGraficoAportesEPS);
    },
    async prepararGraficos() {
      //se debe consultar por los dos periodos anteriores al q se está procesando para mostrar los gráficos comparativos

      const mesPeriodoActual = this.nuevaPlanillaCreada.periodo.split('-')[0];
      const anioPeriodoActual = this.nuevaPlanillaCreada.periodo.split('-')[1];

      const mesDelAnio = this.mesesDelAnio.find(
        (mes) => mes.mes === mesPeriodoActual
      );

      if (mesDelAnio.numeroMes === 1) {
        //Enero
        //Buscar planilla de remuneración de Noviembre del año anterior al evaluado
        if (this.planillasNoviembreAnioPasado.length > 0) {
          this.procesarDatosDelosGraficos(this.planillasNoviembreAnioPasado);
        }

        //Buscar planilla de remuneración de Diciembre del año anterior al evaluado
        if (this.planillasDiciembreAnioPasado.length > 0) {
          this.procesarDatosDelosGraficos(this.planillasDiciembreAnioPasado);
        }
      }
      if (mesDelAnio.numeroMes === 2) {
        //Febrero
        const enero = this.mesesDelAnio.find((mes) => mes.numeroMes === 1);

        //Buscar la planilla de remuneración de Enero
        const planillasEnero = this.planillasRemuneracionDelAnioDelPeriodo.filter(
          (planilla) => planilla.periodo === `${enero.mes}-${anioPeriodoActual}`
        );

        if (planillasEnero.length > 0) {
          this.procesarDatosDelosGraficos(planillasEnero);
        }

        //Buscar planilla de remuneración de Diciembre del año anterior al evaluado
        if (this.planillasDiciembreAnioPasado.length > 0) {
          this.procesarDatosDelosGraficos(this.planillasDiciembreAnioPasado);
        }
      }
      if (mesDelAnio.numeroMes > 2) {
        //Resto de meses y filtro para buscar únicamente los dos periodos anteriores al actual
        for (let index = 1; index < 3; index++) {
          const periodoAnterior = this.mesesDelAnio.find(
            (mes) => mes.numeroMes === mesDelAnio.numeroMes - index
          );

          const planillasEncontradas = this.planillasRemuneracionDelAnioDelPeriodo.filter(
            (planilla) =>
              planilla.periodo === `${periodoAnterior.mes}-${anioPeriodoActual}`
          );

          if (planillasEncontradas.length > 0) {
            this.procesarDatosDelosGraficos(planillasEncontradas);
          }
        }
      }

      this.seriesTotalAportesEPS.push({
        name: this.nuevaPlanillaCreada.periodo,
        type: 'column',
        data: [this.totalAportesEPS],
      });

      this.seriesTotalAportesEsSalud.push({
        name: this.nuevaPlanillaCreada.periodo,
        type: 'column',
        data: [this.totalAportesEsSalud],
      });

      this.showLoadingDialog = false;
    },
    borrarDatosDeEstePaso() {
      this.nuevaPlanillaCreada.personal.map((persona) => {
        persona.aportes_empleador_por_concepto = [];
        persona.descuentos_por_concepto = [];
        return persona;
      });
    },
    volverAlPasoAnterior() {
      this.borrarDatosDeEstePaso();
      this.$store.state.procesoPlanilla.pasoDeProcesoActual = 3;
    },
    siguientePaso() {
      this.$store.state.procesoPlanilla.pasoDeProcesoActual = 5;
    },
    showErrorAlert(text) {
      this.$swal.fire({
        title: `Error`,
        text,
        icon: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/main.scss';
.v-card {
  overflow-y: auto;
}
.v-card__text,
.v-card__title {
  word-break: normal;
}
</style>
