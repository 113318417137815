<template>
  <v-card min-height="400px">
    <v-data-table
      class="elevation-1"
      no-results-text="No se encontraron registros..."
      fixed-header
      height="71.25vh"
      id="Asistencia"
      :headers="headers"
      :items="personal"
      :loading="tableLoading"
      :custom-sort="customSort"
      loading-text="Cargando reporte de asistencia... Espere..."
      no-data-text="No hay personal aún..."
      :search="searchPerson"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-toolbar-title class="top-bar-title">Asistencia</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-text-field
            v-model="searchPerson"
            append-icon="mdi-magnify"
            label="Buscar persona..."
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>

      <template v-for="h in headers" v-slot:[`header.${h.value}`]="{ header }">
        <v-row :key="h.value">
          <v-row class="d-flex flex-column justify-center" no-gutters>
            <v-col class="align-header">
              {{ header.text.toUpperCase() }}
            </v-col>
            <v-col class="align-header">
              {{ header.value > 0 ? header.value : null }}
            </v-col>
          </v-row>
        </v-row>
      </template>

      <template
        v-for="(asistencia, l) in asistencia"
        v-slot:[`item.${l+1}`]="{ item }"
      >
        <v-tooltip top :key="asistencia.fecha_programacion">
          <template v-slot:activator="{ on }">
            <v-chip
              v-on="on"
              :color="getColor(item, l + 1)"
              @click="editarAsistencia(asistencia, item, l + 1)"
            >
              {{ item[l + 1] }}
            </v-chip>
          </template>
          <span>
            {{
              item[l + 1] === 'A'
                ? `Editar asistencia del día ${l + 1}?`
                : item[l + 1] === 'Descanso médico' || item[l + 1] === 'DM'
                ? `Editar descanso médico del día ${l + 1}?`
                : item[l + 1] === 'D'
                ? `Editar descanso semanal del día ${l + 1}?`
                : item[l + 1] === 'F'
                ? `Editar falto del día ${l + 1}?`
                : item[l + 1] === 'LF'
                ? `Editar licencia por fallecimiento del día ${l + 1}?`
                : item[l + 1] === 'LM'
                ? `Editar licencia por maternidad del día ${l + 1}?`
                : item[l + 1] === 'LP'
                ? `Editar licencia por paternidad del día ${l + 1}?`
                : item[l + 1] === 'PG'
                ? `Editar licencia con goce de haber del día ${l + 1}?`
                : item[l + 1] === 'P'
                ? `Editar permiso sin goce de haber del día ${l + 1}?`
                : item[l + 1] === 'S'
                ? `Editar suspensión del día ${l + 1}?`
                : item[l + 1] === 'T'
                ? `Editar tardanza del día ${l + 1}?`
                : item[l + 1] === 'V'
                ? `Editar vacaciones del día ${l + 1}?`
                : item[l + 1] === 'N'
                ? `No existe registro/programación el día: ${l + 1}`
                : `Sin marcaciones el día ${l + 1}`
            }}</span
          >
        </v-tooltip>
      </template>
    </v-data-table>

    <EditarAsistencia
      :asistencia="this.asistenciaAEditar"
      :persona="this.personaAEditar"
    />
  </v-card>
</template>
<script>
import moment from 'moment';
import { mapState } from 'vuex';

import { getPersons } from '@/api/persons/getPersons.js';
// import { getAssistanceCurrentMonth } from '@/api/persons/getAssistanceCurrentMonth.js';
import { editarAsistencia } from '@/api/persons/editarAsistencia.js';

import { obtenerAsistenciaPorRangoDeFechas } from '@/api/persons/obtenerAsistenciaPorRangoDeFechas.js';
import verificarExistenciaAsistenciasMes from '@/utils/verificarExistenciaAsistenciasMes.js';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';
import EditarAsistencia from './EditarAsistencia.vue';

export default {
  name: 'Asistencia',
  data: () => ({
    asistenciaAEditar: {},
    personaAEditar: {},
    tableLoading: true,
    asistencia: [],
    personal: [],
    diasDelMes: [],
    searchPerson: '',
  }),
  components: {
    EditarAsistencia,
  },
  computed: {
    ...mapState([
      'editarAsistenciaDialog',
      'fechaAsistencia',
      'cliente',
      'planVigenteActual',
    ]),
    headers() {
      let fixedHeaders = [
        {
          text: 'Documento',
          value: 'doi',
        },
        {
          text: 'Nombre',
          value: 'nombre',
          width: '275px',
        },
        {
          text: 'Cargo',
          value: 'datos_laborales.cargo',
          width: '175px',
        },
        {
          text: 'Sede',
          value: 'datos_laborales.sede',
          width: '150px',
        },
      ];

      fixedHeaders.push(...this.diasDelMes);
      return fixedHeaders;
    },
  },
  mounted() {
    // this.$store.state.fechaAsistencia = moment().format('MMMM-YYYY');
    if (Object.keys(this.fechaAsistencia).length === 0) {
      this.$store.state.fechaAsistencia = moment().format('YYYY-MM');
    }
    this.$store.state.tablaActivaGestionPersonal = 'Asistencia';
    this.getPresenceByDate();
  },
  watch: {
    fechaAsistencia() {
      this.verificarAsistencias();
    },
    editarAsistenciaDialog() {
      if (!this.editarAsistenciaDialog) {
        //aqui se puede editar el objeto y actualizar la bd asincronamente para no demorar el renderizado del personal luego del cambio
        //así como evitar llamadas al endpoint si solo se abre y cierra el dialogo
        this.getPresenceByDate();
      }
    },
  },
  methods: {
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        switch (index[0]) {
          case 'datos_laborales.cargo':
            if (isDesc[0]) {
              return a.datos_laborales.cargo > b.datos_laborales.cargo ? -1 : 1;
            } else {
              return b.datos_laborales.cargo > a.datos_laborales.cargo ? -1 : 1;
            }
          case 'datos_laborales.sede':
            if (isDesc[0]) {
              return a.datos_laborales.sede > b.datos_laborales.sede ? -1 : 1;
            } else {
              return b.datos_laborales.sede > a.datos_laborales.sede ? -1 : 1;
            }

          default:
            if (isDesc[0]) {
              return a[index[0]] > b[index[0]] ? -1 : 1;
            } else {
              return b[index[0]] > a[index[0]] ? -1 : 1;
            }
        }
      });
      return items;
    },
    async verificarAsistencias() {
      this.personal = [];
      this.tableLoading = true;
      await verificarExistenciaAsistenciasMes(moment(this.fechaAsistencia));
      this.getPresenceByDate();
    },
    getColor(persona, index) {
      let tipoDeJornada = persona[index];
      let colorDeCelda = '';

      switch (tipoDeJornada) {
        case 'A':
          colorDeCelda = 'green';
          break;
        case 'Descanso médico':
          colorDeCelda = 'pink lighten-2';
          break;
        case 'DM':
          colorDeCelda = 'pink lighten-2';
          break;
        case 'D':
          colorDeCelda = 'amber lighten-3';
          break;
        case 'F':
          colorDeCelda = 'red';
          break;
        case 'LM':
          colorDeCelda = 'blue';
          break;
        case 'LP':
          colorDeCelda = 'green';
          break;
        case 'LF':
          colorDeCelda = 'red';
          break;
        case 'PG':
          colorDeCelda = 'brown';
          break;
        case 'P':
          colorDeCelda = 'teal';
          break;
        case 'S':
          colorDeCelda = 'lime darken-3';
          break;
        case 'T':
          colorDeCelda = 'yellow';
          break;
        case 'V':
          colorDeCelda = 'purple';
          break;

        case 'N':
          colorDeCelda = 'transparent';
          break;

        default:
          colorDeCelda = 'transparent';
          break;
      }
      return colorDeCelda;
    },
    // definirEstadoDeAsistencia(persona, registroDeAsistencia) {
    //   console.log(persona, registroDeAsistencia);
    // },
    async getPresenceByDate() {
      let fecha_inicio = '';
      let fecha_fin = '';
      if (this.fechaAsistencia && this.fechaAsistencia.length > 0) {
        fecha_inicio = moment(this.fechaAsistencia, 'YYYY-MM')
          .tz('America/Lima')
          .startOf('month');
        fecha_fin = moment(this.fechaAsistencia, 'YYYY-MM')
          .tz('America/Lima')
          .endOf('month');

        const {
          error,
          asistencias,
          tokenExpired,
        } = await obtenerAsistenciaPorRangoDeFechas(fecha_inicio, fecha_fin);

        if (asistencias) {
          this.asistencia = asistencias;

          //verificar si esta llamada al API se puede integrar dentro del map q viene
          // esta llamada se debe retirar
          await this.getPersonal();

          this.asistencia = this.asistencia.map((asistencia) => {
            //esta es una asistencia por día, se debe comparar con los días del mes seleccionado para mostrar una columna en "blanco" si no hay programacion
            // let hoy = moment().format();
            let fechaAsistencia = moment(asistencia.fecha_asistencia);
            let diaDelMes = fechaAsistencia.format('D');
            this.personal = this.personal.map((persona) => {
              let registroDeAsistencia = asistencia.personal.find(
                (person) => person.doi.toString() === persona.doi.toString()
              );
              // Si la persona tiene fecha de cese, se oculta la jornada de este día si existe
              if (
                !!persona.datos_laborales.fecha_cese &&
                moment(persona.datos_laborales.fecha_cese).tz('America/Lima') <
                  moment(asistencia.fecha_asistencia)
              ) {
                persona[diaDelMes.toString()] = '';
                return persona;
              }
              if (registroDeAsistencia) {
                //operar para encontrar el status correcto de acuerdo al tipo de jornada
                if (registroDeAsistencia.tipo_jornada) {
                  switch (registroDeAsistencia.tipo_jornada.toUpperCase()) {
                    case 'ASISTENCIA':
                      registroDeAsistencia.tipo_jornada = 'A';
                      break;
                    case 'DESCANSO MÉDICO':
                      registroDeAsistencia.tipo_jornada = 'DM';
                      break;
                    case 'DESCANSO':
                      registroDeAsistencia.tipo_jornada = 'D';
                      break;
                    case 'FALTO':
                      registroDeAsistencia.tipo_jornada = 'F';
                      break;
                    case 'LICENCIA POR FALLECIMIENTO':
                      registroDeAsistencia.tipo_jornada = 'LF';
                      break;
                    case 'LICENCIA POR MATERNIDAD':
                      registroDeAsistencia.tipo_jornada = 'LM';
                      break;
                    case 'LICENCIA POR PATERNIDAD':
                      registroDeAsistencia.tipo_jornada = 'LP';
                      break;
                    case 'LICENCIA CON GOCE DE HABER':
                      registroDeAsistencia.tipo_jornada = 'PG';
                      break;
                    case 'PERMISO SIN GOCE DE HABER':
                      registroDeAsistencia.tipo_jornada = 'P';
                      break;
                    case 'SUSPENSIÓN':
                      registroDeAsistencia.tipo_jornada = 'S';
                      break;
                    case 'TARDANZA':
                      registroDeAsistencia.tipo_jornada = 'T';
                      break;
                    case 'VACACIONES':
                      registroDeAsistencia.tipo_jornada = 'V';
                      break;

                    default:
                      break;
                  }
                }
                if (!registroDeAsistencia.tipo_jornada) {
                  registroDeAsistencia.tipo_jornada = 'D';
                }
                persona[diaDelMes.toString()] =
                  registroDeAsistencia.tipo_jornada;
                // this.definirEstadoDeAsistencia(persona, registroDeAsistencia);
              } else {
                persona[diaDelMes.toString()] = '';
              }

              return persona;
            });

            return asistencia;
          });
          //se puede haceer una validación adicional por día del mes, buscar a una persona y su asistencia y mostrarla
          // this.personal = this.personal.map((persona) => {
          //   for (let dia in this.diasDelMes) {
          //     persona[this.diasDelMes[dia].value] = 'A';
          //   }
          //   persona.nombre = `${persona.datos_laborales.apellido_paterno} ${persona.datos_laborales.apellido_materno}, ${persona.nombre}`;
          //   return persona;
          // });

          this.tableLoading = false;
        }
        if (error) {
          //do something
        }
        if (tokenExpired) {
          routeToLoginIfTokenIsExpired(this);
        }
      }
    },
    getDaysInMonth() {
      this.diasDelMes = [];
      var año = moment(this.fechaAsistencia).format('YYYY');
      var mes = moment(this.fechaAsistencia).format('M');

      var diasMes = new Date(año, mes, 0).getDate();
      var diasSemana = [
        'Domingo',
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado',
      ];

      for (var dia = 1; dia <= diasMes; dia++) {
        var indice = new Date(año, mes - 1, dia).getDay();
        this.diasDelMes.push({
          value: dia.toString(),
          text: diasSemana[indice].substring(0, 2),
        });
      }
      // this.render = this.render + 1;
    },
    async getPersonal() {
      const { error, personal, tokenExpired } = await getPersons();
      if (error) {
        //do something
      }
      if (personal) {
        // solo mostrar las usuarios_habilitados permitidos por el plan vigente
        let limiteDePersonas = 3;
        if (
          this.planVigenteActual.tipo_plan !== '' &&
          this.planVigenteActual.tipo_plan !== 'CTIEmprendedor'
        ) {
          limiteDePersonas = personal.length;
        }
        this.personal = personal;

        this.personal = this.personal.map((persona) => {
          persona.nombre = `${persona.datos_laborales.apellido_paterno} ${persona.datos_laborales.apellido_materno}, ${persona.nombre}`;
          return persona;
        });

        //filtrar cesados antes del inicio e ingreso luego del fin de esta asistencia
        this.personal = this.personal.filter(
          (persona) =>
            moment(persona.datos_laborales.fecha_ingreso).tz('America/Lima') <=
            moment(this.fechaAsistencia)
              .tz('America/Lima')
              .endOf('month')
        );
        this.personal = this.personal
          .filter((persona) =>
            !!persona.datos_laborales.fecha_cese &&
            persona.datos_laborales.fecha_cese !== ''
              ? moment(persona.datos_laborales.fecha_cese).tz('America/Lima') >=
                  moment(this.fechaAsistencia)
                    .tz('America/Lima')
                    .endOf('month') ||
                (moment(persona.datos_laborales.fecha_cese).tz(
                  'America/Lima'
                ) >=
                  moment(this.fechaAsistencia)
                    .tz('America/Lima')
                    .startOf('month') &&
                  moment(persona.datos_laborales.fecha_cese).tz(
                    'America/Lima'
                  ) <=
                    moment(this.fechaAsistencia)
                      .tz('America/Lima')
                      .endOf('month'))
              : true
          )
          .slice(0, limiteDePersonas);

        this.getDaysInMonth();
        // this.getPresenceByDate();
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
    },
    editarAsistencia(asistencia, persona) {
      // console.log('editando asistencia de: ', asistencia, persona);
      // this.asistenciaAEditar = asistencia;
      this.personaAEditar = persona;

      if (
        moment(persona.datos_laborales.fecha_ingreso).tz('America/Lima') >
        moment(asistencia.fecha_asistencia)
      ) {
        this.fechaAlert(
          `La fecha de ingreso de ${this.personaAEditar.nombre} es posterior a la fecha que intenta editar`
        );
        return;
      }
      if (
        !!persona.datos_laborales.fecha_cese &&
        moment(persona.datos_laborales.fecha_cese).tz('America/Lima') <
          moment(asistencia.fecha_asistencia)
      ) {
        this.fechaAlert(
          `La fecha de cese de ${this.personaAEditar.nombre} es anterior a la fecha que intenta editar`
        );
        return;
      }

      if (asistencia.personal) {
        let person = asistencia.personal.find(
          (personal) => personal.doi.toString() === persona.doi.toString()
        );
        //si no se encuentra la persona se puede mostrar una alerta para agregarla a la programación del día
        if (person) {
          // si la persona es disponible = false entonces agregar el tipo de jornada correspondiente
          this.asistenciaAEditar = person;
          this.asistenciaAEditar.fecha_asistencia = asistencia.fecha_asistencia;
          this.$store.state.editarAsistenciaDialog = true;
          return;
        }

        this.agregarPersonaAAsistenciaAlert(asistencia);
      }
    },
    fechaAlert(mensaje) {
      this.$swal.fire({
        title: `Error!`,
        text: mensaje,
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    agregarPersonaAAsistenciaAlert(asistencia) {
      this.$swal
        .fire({
          title: `Atención!`,
          text: `${this.personaAEditar.nombre} no se encuentra programado este día, desea agregarlo?`,
          icon: 'warning',
          showCancelButton: true,
          cancelButtonColor: '#d33',
          cancelButtonText: 'No',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Sí',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.personaAEditar.disponible = true;
            asistencia.personal.push(this.personaAEditar);
            const { error, message } = await editarAsistencia(asistencia);
            if (error) {
              //do something
            }
            if (message) {
              //do something
              this.asistenciaAEditar = this.personaAEditar;
              this.asistenciaAEditar.fecha_asistencia =
                asistencia.fecha_asistencia;
              this.$store.state.editarAsistenciaDialog = true;
            }
          }
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/main.scss';
.top-bar-title {
  font-size: 25px !important;
  font-weight: bold !important;
  color: teal !important;
}
.v-data-table-header {
  height: 50px !important;
}
.v-data-table::v-deep th {
  font-size: 15px !important;
  font-weight: bold !important;
  color: teal !important;
  .v-icon {
    display: none !important;
  }
}
.align-header {
  text-align: center !important;
  background-color: $white;
}
.btn_close {
  position: relative;
  left: 208px;
}
.v-overlay {
  background-color: #1312129a;
}
.table {
  // max-height: 74vh;
  overflow-y: scroll;
}

table {
  border-collapse: collapse;
  width: 100%;
}
// td,
// th {
//   border: 1px solid #0f501d3d;
//   text-align: left;
//   padding: 8px;
//   font-size: 12px;
// }

// tr:nth-child(even) {
//   background-color: #dddddd;
// }
</style>
