var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex flex-column mb-4",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"top-bar-buttons-container"},[_c('v-row',{attrs:{"no-gutters":"","row":""}},[_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({class:{
                  'boton-seleccionado':
                    _vm.botonPersonalSeleccionado === 'activo',
                },on:{"click":function($event){return _vm.seleccionarPersonalActivo()}}},on),[_c('v-icon',{attrs:{"color":"teal"}},[_vm._v("mdi-account-group-outline")])],1)]}}])},[_c('span',[_vm._v("Personal activo")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({class:{
                  'boton-seleccionado':
                    _vm.botonPersonalSeleccionado === 'historico',
                },on:{"click":function($event){return _vm.seleccionarPersonalHistorico()}}},on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-account-group-outline")])],1)]}}])},[_c('span',[_vm._v("Personal histórico")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({class:{
                  'boton-seleccionado':
                    _vm.botonPersonalSeleccionado === 'nuevoPersonal',
                },on:{"click":function($event){return _vm.seleccionarNuevoPersonal()}}},on),[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-account-plus-outline")])],1)]}}])},[_c('span',[_vm._v("Agregar nuevo personal")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(!_vm.verNuevoPersonal),expression:"!verNuevoPersonal"}],attrs:{"elevation":"2"},on:{"click":function($event){return _vm.exportExcel('table', 'Registro')}}},on),[_c('v-icon',{attrs:{"color":"green darken-4"}},[_vm._v("mdi-file-excel")])],1)]}}])},[_c('span',[_vm._v("Exportar a Excel")])])],1)],1)],1),_c('v-col',[(_vm.verPersonalActivo === true || _vm.verPersonalHistorico === true)?_c('AllPersonInRegister'):_c('NewPerson')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }