var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"60vw","max-height":"50vh","persistent":""},model:{value:(_vm.editarAsistenciaDialog),callback:function ($$v) {_vm.editarAsistenciaDialog=$$v},expression:"editarAsistenciaDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 justify-center",staticStyle:{"color":"teal","font-weight":"bold"}},[_vm._v(" "+_vm._s(("Editando la asistencia de " + (this.obtenerNombre)))+" ")]),_c('v-card-subtitle',{staticClass:"text-h5",staticStyle:{"color":"teal"}},[_vm._v(" "+_vm._s(("del día " + (this.obtenerDiaFormateado)))+" ")]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(this.esAsistenciaIntermitente)?_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Hora programada","type":"time","disabled":""},model:{value:(_vm.registroDeAsistenciaAEditar.hora_programada),callback:function ($$v) {_vm.$set(_vm.registroDeAsistenciaAEditar, "hora_programada", $$v)},expression:"registroDeAsistenciaAEditar.hora_programada"}}),_vm._l((_vm.asistencia.jornadas),function(marcacion,index){return _c('div',{key:index + marcacion.hora_inicio_jornada},[_c('v-text-field',{attrs:{"label":("Hora de inicio de jornada " + (index + 1)),"type":"time"},model:{value:(marcacion.hora_inicio_jornada),callback:function ($$v) {_vm.$set(marcacion, "hora_inicio_jornada", $$v)},expression:"marcacion.hora_inicio_jornada"}},[_c('template',{slot:"append-outer"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){return _vm.openMap('inicioJornada', marcacion)}}},on),[_vm._v("mdi-map-marker")])]}}],null,true)},[_c('span',[_vm._v("Ver lugar de marcación en mapa")])])],1)],2)],1)})],2),_c('v-col',[_c('v-autocomplete',{attrs:{"label":"Tipo de jornada","items":_vm.tiposDeJornada,"item-text":"nombre","disabled":_vm.verificarTipoJornada},model:{value:(_vm.registroDeAsistenciaAEditar.tipo_jornada),callback:function ($$v) {_vm.$set(_vm.registroDeAsistenciaAEditar, "tipo_jornada", $$v)},expression:"registroDeAsistenciaAEditar.tipo_jornada"}}),_vm._l((_vm.asistencia.jornadas),function(marcacion,index){return _c('div',{key:index},[_c('v-text-field',{attrs:{"label":("Hora de fin de jornada " + (index + 1)),"type":"time"},model:{value:(marcacion.hora_fin_jornada),callback:function ($$v) {_vm.$set(marcacion, "hora_fin_jornada", $$v)},expression:"marcacion.hora_fin_jornada"}},[_c('template',{slot:"append-outer"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){return _vm.openMap('finJornada', marcacion)}}},on),[_vm._v("mdi-map-marker")])]}}],null,true)},[_c('span',[_vm._v("Ver lugar de marcación en mapa")])])],1)],2)],1)})],2)],1):_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Hora programada","type":"time"},model:{value:(_vm.registroDeAsistenciaAEditar.hora_programada),callback:function ($$v) {_vm.$set(_vm.registroDeAsistenciaAEditar, "hora_programada", $$v)},expression:"registroDeAsistenciaAEditar.hora_programada"}}),_c('v-text-field',{attrs:{"label":"Hora de inicio de jornada","type":"time"},model:{value:(_vm.registroDeAsistenciaAEditar.hora_inicio_jornada),callback:function ($$v) {_vm.$set(_vm.registroDeAsistenciaAEditar, "hora_inicio_jornada", $$v)},expression:"registroDeAsistenciaAEditar.hora_inicio_jornada"}},[_c('template',{slot:"append-outer"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){return _vm.openMap('inicioJornada', _vm.registroDeAsistenciaAEditar)}}},on),[_vm._v("mdi-map-marker")])]}}])},[_c('span',[_vm._v("Ver lugar de marcación en mapa")])])],1)],2),_c('v-text-field',{attrs:{"label":"Hora de inicio de almuerzo","type":"time"},model:{value:(_vm.registroDeAsistenciaAEditar.hora_inicio_almuerzo),callback:function ($$v) {_vm.$set(_vm.registroDeAsistenciaAEditar, "hora_inicio_almuerzo", $$v)},expression:"registroDeAsistenciaAEditar.hora_inicio_almuerzo"}})],1),_c('v-col',[_c('v-autocomplete',{attrs:{"label":"Tipo de jornada","items":_vm.tiposDeJornada,"item-text":"nombre","disabled":_vm.verificarTipoJornada},model:{value:(_vm.registroDeAsistenciaAEditar.tipo_jornada),callback:function ($$v) {_vm.$set(_vm.registroDeAsistenciaAEditar, "tipo_jornada", $$v)},expression:"registroDeAsistenciaAEditar.tipo_jornada"}}),_c('v-text-field',{attrs:{"label":"Hora de fin de jornada","type":"time"},model:{value:(_vm.registroDeAsistenciaAEditar.hora_fin_jornada),callback:function ($$v) {_vm.$set(_vm.registroDeAsistenciaAEditar, "hora_fin_jornada", $$v)},expression:"registroDeAsistenciaAEditar.hora_fin_jornada"}},[_c('template',{slot:"append-outer"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({on:{"click":function($event){return _vm.openMap('finJornada', _vm.registroDeAsistenciaAEditar)}}},on),[_vm._v("mdi-map-marker")])]}}])},[_c('span',[_vm._v("Ver lugar de marcación en mapa")])])],1)],2),_c('v-text-field',{attrs:{"label":"Hora de fin de almuerzo","type":"time"},model:{value:(_vm.registroDeAsistenciaAEditar.hora_fin_almuerzo),callback:function ($$v) {_vm.$set(_vm.registroDeAsistenciaAEditar, "hora_fin_almuerzo", $$v)},expression:"registroDeAsistenciaAEditar.hora_fin_almuerzo"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDialog}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"success","text":""},on:{"click":_vm.validate}},[_vm._v("Guardar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }