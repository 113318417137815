import fetch from 'isomorphic-fetch';

let ENDPOINT_URL = process.env.VUE_APP_EDITAR_CLIENTE;
async function updateClient(client) {
  const bodyParams = {
    username: client.username.toLowerCase(),
    datos_empresa: client.datos_empresa,
    planes_contratados: client.planes_contratados,
    token: localStorage.getItem('token'),
  };

  let body = JSON.stringify(bodyParams);

  return await fetch(ENDPOINT_URL, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  }).then((item) => item.json());
}
export { updateClient };
