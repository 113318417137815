var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.renderEditRoutes)?_c('v-container',{key:_vm.containerKey},[_c('v-row',{staticClass:"d-flex flex-column"},_vm._l((this.programacionDeRutas.rutas),function(ruta,i){return _c('v-col',{key:i},[_c('v-col',[_c('v-row',[_c('div',{class:("new_route " + (!_vm.rutaInactiva || ''))},[_c('v-checkbox',{attrs:{"value":ruta},model:{value:(_vm.val),callback:function ($$v) {_vm.val=$$v},expression:"val"}}),_c('v-text-field',{attrs:{"disabled":!_vm.val || ruta.codigo_ruta !== _vm.val.codigo_ruta,"readonly":"","label":"Ruta:"},model:{value:(ruta.codigo_ruta),callback:function ($$v) {_vm.$set(ruta, "codigo_ruta", $$v)},expression:"ruta.codigo_ruta"}}),_c('v-text-field',{attrs:{"disabled":!_vm.val || ruta.codigo_ruta !== _vm.val.codigo_ruta,"label":"Hora inicio:","type":"time"},model:{value:(ruta.hora_programada),callback:function ($$v) {_vm.$set(ruta, "hora_programada", $$v)},expression:"ruta.hora_programada"}}),_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"disabled":!_vm.val || ruta.codigo_ruta !== _vm.val.codigo_ruta,"fab":"","x-small":"","color":"cyan"},on:{"click":function($event){return _vm.editarRuta(ruta)}}},on),[_vm._v("mdi-pencil")])]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"fab":"","x-small":"","color":"red"},on:{"click":function($event){return _vm.showDeleteRouteAlert(ruta.codigo_ruta)}}},on),[_vm._v("mdi-delete")])]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])],1)],1)])],1),_c('v-col',[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(
            !!_vm.val && ruta.codigo_ruta === _vm.val.codigo_ruta ? true : false
          ),expression:"\n            !!val && ruta.codigo_ruta === val.codigo_ruta ? true : false\n          "}],staticClass:"mx-auto "},[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-row',{staticClass:"justify-space-between"},[_c('h4',{staticClass:"d-flex pa-2"},[_vm._v("Punto de origen:")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-switch',{attrs:{"color":"primary","hide-details":""},on:{"click":function($event){return _vm.optimizarRuta(ruta)}},model:{value:(ruta.ruta_optimizada),callback:function ($$v) {_vm.$set(ruta, "ruta_optimizada", $$v)},expression:"ruta.ruta_optimizada"}})],1)]}}],null,true)},[_c('span',[_vm._v("Optimizar ruta?")])])],1),_c('v-select',{attrs:{"items":_vm.programacionDeRutas.sucursales},model:{value:(_vm.puntoDeOrigen),callback:function ($$v) {_vm.puntoDeOrigen=$$v},expression:"puntoDeOrigen"}})],1),_c('v-col',[_c('drop',{staticClass:"servicios_drop",attrs:{"accepts-data":function (item) { return item.latlng; },"mode":"cut"},on:{"drop":function($event){return _vm.checkServiceInRoute($event, ruta)}}},[_c('h4',[_vm._v("Clientes:")]),_vm._l((ruta.servicios),function(item,i){return [_c('v-list-item',{key:i},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [(
                                ruta.servicios && ruta.servicios.length > 1
                              )?_c('v-btn',_vm._g({staticClass:"mr-1 mt-3",attrs:{"fab":"","small":""},on:{"click":function($event){return _vm.cambiarOrdenDelServicio(item, ruta, 1)}}},on),[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-arrow-down-thin-circle-outline")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Cambiar el orden de atención del cliente")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [(
                                ruta.servicios && ruta.servicios.length > 1
                              )?_c('v-btn',_vm._g({staticClass:"mr-1 mt-3",attrs:{"icon":"","fab":"","small":""},on:{"click":function($event){return _vm.cambiarOrdenDelServicio(item, ruta, -1)}}},on),[_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-arrow-up-thin-circle-outline")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Cambiar el orden de atención del cliente")])]),_c('v-list-item-content',{staticClass:"ml-2"},[_c('v-list-item-title',{staticClass:"item-list-width"},[_vm._v(" "+_vm._s(i + 1)+".- "+_vm._s(item.nombre)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.direccion)+" ")])],1),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"red--text ml-1 mt-3",attrs:{"fab":"","x-small":""},on:{"click":function($event){return _vm.deleteServices(item, ruta)}}},on),[_c('v-icon',[_vm._v("mdi-close-thick")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar cliente de la ruta")])])],1)],1)]})],2)],1)],1),_c('v-row',[_c('v-col',[_c('drop',{staticClass:"personal_drop",attrs:{"accepts-data":function (item) { return item.datos_laborales; },"mode":"cut"},on:{"drop":function($event){return _vm.verificarResponsableDeRuta($event, ruta)}}},[_c('h4',[_vm._v("Tripulantes:")]),_vm._l((ruta.tripulacion),function(item,i){return [_c('v-list-item',{key:i},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.nombre.toUpperCase())+" ")]),_c('v-list-item-subtitle',[_vm._v("Cargo: "+_vm._s(item.cargo)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.responsable ? 'Responsable de ruta' : null)+" ")])],1),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"red--text",attrs:{"fab":"","x-small":""},on:{"click":function($event){return _vm.removePersonal(item, ruta)}}},on),[_c('v-icon',[_vm._v("mdi-close-thick")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar tripulante de la ruta")])])],1)]})],2)],1)],1),_c('v-row',[_c('v-col',[_c('drop',{staticClass:"flota_drop",attrs:{"accepts-data":function (item) { return item.placa; },"mode":"cut"},on:{"drop":function($event){return _vm.addUnit($event, ruta)}}},[_c('h4',[_vm._v("Unidad:")]),_vm._l((ruta.flota),function(item,i){return [_c('v-list-item',{key:i},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.tipo_unidad)+" ")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(item.placa)+" ")])],1),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
                              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"red--text",attrs:{"fab":"","x-small":""},on:{"click":function($event){return _vm.deleteUnit(item, ruta)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close-thick")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar unidad de la ruta")])])],1)]})],2)],1)],1)],1)],1)],1)],1)}),1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }