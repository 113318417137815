<template>
  <v-dialog
    v-model="editarPrestamoDialog"
    max-width="60vw"
    max-height="50vh"
    persistent
  >
    <v-card>
      <v-card-title
        class="text-h5 justify-center"
        style="color: teal; font-weight: bold"
      >
        {{ !nuevoPrestamo ? `Editando crédito de:` : 'Agregar crédito para:' }}
      </v-card-title>
      <v-card-subtitle style="color: teal;" class="text-h5">
        {{ `${persona.nombre}` }}
      </v-card-subtitle>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row dense>
            <v-col>
              <v-text-field
                label="Fecha de solicitud"
                v-model="prestamoAEditar.fecha_solicitud"
                type="date"
                readonly
                :disabled="!nuevoPrestamo"
              />
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="true"
                :return-value.sync="dateForPicker"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
                :disabled="!nuevoPrestamo && prestamoAEditar.aprobado !== 'No'"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateText"
                    :value="dateText"
                    label="Periodo de inicio de descuento"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateForPicker"
                  type="month"
                  no-title
                  scrollable
                  color="teal"
                  locale="es-ES"
                  @input="$refs.menu.save(dateForPicker)"
                >
                </v-date-picker>
              </v-menu>
              <v-text-field
                label="Total cuotas"
                v-model="prestamoAEditar.total_cuotas"
                type="number"
                min="1"
                :rules="fieldRules"
                :disabled="!nuevoPrestamo && prestamoAEditar.aprobado !== 'No'"
              />
              <v-text-field
                label="Monto"
                v-model="prestamoAEditar.monto"
                type="number"
                min="0"
                :rules="fieldRules"
                :disabled="!nuevoPrestamo && prestamoAEditar.aprobado !== 'No'"
              />
              <v-text-field
                label="Monto a pagar por cuota"
                :value="
                  Number(
                    (
                      prestamoAEditar.monto / prestamoAEditar.total_cuotas
                    ).toFixed(2)
                  )
                "
                type="number"
                min="0"
                readonly
                :disabled="!nuevoPrestamo && prestamoAEditar.aprobado !== 'No'"
              />
              <v-text-field
                v-if="prestamoAEditar.cuotas_pagadas > 0"
                label="Cuotas pagadas"
                :value="Number(prestamoAEditar.cuotas_pagadas)"
                type="number"
                min="0"
                readonly
                :disabled="!nuevoPrestamo && prestamoAEditar.aprobado !== 'No'"
              />
            </v-col>

            <v-col>
              <v-autocomplete
                label="Tipo de crédito"
                v-model="prestamoAEditar.tipo_credito"
                :items="tiposDePrestamo"
                item-text="nombre"
                :rules="fieldRules"
                :disabled="!nuevoPrestamo && prestamoAEditar.aprobado !== 'No'"
              />
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-autocomplete
                    v-on="on"
                    label="Planilla(s) para descuento de cuota"
                    v-model="prestamoAEditar.planilla_descuento_cuota"
                    multiple
                    :items="planillasDescuentoCuota"
                    :rules="arrayFieldRules"
                    :disabled="
                      !nuevoPrestamo && prestamoAEditar.aprobado !== 'No'
                    "
                  />
                </template>
                <span>{{
                  `Procesos que serán los encargados de ejecutar el cobro de cada cuota`
                }}</span>
              </v-tooltip>
              <v-autocomplete
                label="Firmado digitalmente"
                v-model="prestamoAEditar.firmado_digitalmente"
                :items="items"
                :rules="fieldRules"
                :disabled="!nuevoPrestamo && prestamoAEditar.aprobado !== 'No'"
              />
              <v-autocomplete
                label="Pagado"
                v-model="prestamoAEditar.pagado"
                :items="items"
                :rules="fieldRules"
                readonly
                :disabled="!nuevoPrestamo && prestamoAEditar.aprobado !== 'No'"
              />
              <v-autocomplete
                label="Aprobado"
                v-model="prestamoAEditar.aprobado"
                :items="items"
                :rules="fieldRules"
                :disabled="!nuevoPrestamo && prestamoAEditar.aprobado !== 'No'"
              />
              <v-text-field
                v-if="prestamoAEditar.cuotas_pagadas > 0"
                label="Saldo pendiente"
                :value="
                  Number(
                    prestamoAEditar.monto -
                      (prestamoAEditar.monto / prestamoAEditar.total_cuotas) *
                        prestamoAEditar.cuotas_pagadas
                  )
                "
                type="number"
                min="0"
                readonly
                :disabled="!nuevoPrestamo && prestamoAEditar.aprobado !== 'No'"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-textarea
              outlined
              label="Observaciones"
              v-model="prestamoAEditar.observaciones"
            />
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="blue darken-1"
          text
          @click="closeDialog"
          :disabled="deshabilitarBotonGuardar"
          >Cancelar</v-btn
        >
        <v-btn
          color="success"
          text
          @click="validate"
          :disabled="deshabilitarBotonGuardar"
          >Guardar</v-btn
        >
        <!-- antes de eliminar un préstamo se valida si existe al menos una cuota pagada -->
        <v-btn
          color="red"
          text
          @click="showDeleteAlert"
          v-show="!nuevoPrestamo"
          :disabled="nuevoPrestamo || prestamoAEditar.cuotas_pagadas > 0"
          >Eliminar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment-timezone';

import { editUserInfo } from '@/api/persons/editUserInfo.js';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';

export default {
  name: 'EditarPrestamo',
  prestamoOriginal: {},
  data() {
    return {
      dateForPicker: new Date().toISOString().substr(0, 7),
      menu: false,
      deshabilitarBotonGuardar: false,
      items: ['Sí', 'No'],
      prestamoAEditar: {
        tipo_credito: '',
        monto: 0,
        fecha_solicitud: '',
        periodo_inicio_descuento: '',
        firmado_digitalmente: 'No',
        total_cuotas: 1,
        cuotas_pagadas: 0,
        aprobado: 'No',
        pagado: 'No',
        observaciones: '',
        planilla_descuento_cuota: [],
      },
      valid: true,
      tiposDePrestamo: [
        { nombre: 'Adelanto de sueldo' },
        { nombre: 'Descuento' },
        { nombre: 'Préstamo' },
      ],
      fieldRules: [(v) => !!v || 'Campo requerido'],
      arrayFieldRules: [(v) => !!(v && v.length) || 'Campo requerido'],
      dateRules: [(v) => !!v || 'Campo requerido'],
    };
  },
  props: {
    prestamo: Object,
    persona: Object,
    nuevoPrestamo: Boolean,
  },
  watch: {
    editarPrestamoDialog() {
      this.$nextTick(function() {
        this.$refs.form.resetValidation();
      });
    },
    prestamo() {
      this.prestamoAEditar = this.prestamo;
      if (this.nuevoPrestamo) {
        this.prestamoAEditar.fecha_solicitud = moment
          .tz('America/Lima')
          .format('yyyy-MM-DD');
      }
      this.$options.prestamoOriginal = { ...this.prestamo };
    },
  },
  computed: {
    ...mapState(['editarPrestamoDialog']),
    dateText() {
      return moment(this.dateForPicker)
        .format('MMMM-YYYY')
        .toUpperCase();
    },
    planillasDescuentoCuota() {
      const planillasParaDescuento = ['Adelanto de sueldo'];

      if (
        this.persona.datos_laborales.tipo_contrato === 'Locación de servicio' ||
        this.persona.datos_laborales.tipo_trabajador === 'Locador de servicio'
      ) {
        planillasParaDescuento.push(...['Locación de servicio']);
      } else {
        planillasParaDescuento.push(
          ...['Remuneración', 'Gratificación', 'Vacaciones']
        );
      }

      return planillasParaDescuento;
    },
  },
  methods: {
    closeDialog() {
      this.$options.prestamoOriginal = {};
      this.deshabilitarBotonGuardar = false;
      this.$store.state.editarPrestamoDialog = false;
    },
    showSuccessAlert() {
      this.$swal.fire({
        title: `Operación realizada correctamente!`,
        text: '',
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    showErrorAlert() {
      this.$swal.fire({
        title: `No se puede crear este crédito si no ha sido aprobado!`,
        text: '',
        icon: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    showDeleteAlert() {
      this.$swal
        .fire({
          title: `Atención!`,
          text: 'Está seguro que desea eliminar esta solicitud?',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonColor: '#d33',
          cancelButtonText: 'No',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Sí',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.deshabilitarBotonGuardar = true;

            this.persona.creditos = this.persona.creditos.filter(
              (credito) =>
                JSON.stringify(credito) !==
                JSON.stringify(this.$options.prestamoOriginal)
            );

            const { error, message, tokenExpired } = await editUserInfo(
              this.persona
            );

            if (error) {
              //do something
            }
            if (tokenExpired) {
              routeToLoginIfTokenIsExpired(this);
            }
            if (message) {
              this.showSuccessAlert();
              this.closeDialog();
            }
          }
        });
    },
    showSaveAlert() {
      this.$swal
        .fire({
          title: `Atención!`,
          text: `Está seguro que desea procesar esta solicitud por S/. ${this.prestamoAEditar.monto}?`,
          icon: 'warning',
          showCancelButton: true,
          cancelButtonColor: '#d33',
          cancelButtonText: 'No',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Sí',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const { error, tokenExpired, message } = await editUserInfo(
              this.persona
            );

            if (error) {
              //do something
            }
            if (tokenExpired) {
              routeToLoginIfTokenIsExpired(this);
            }
            if (message) {
              this.showSuccessAlert();
              this.closeDialog();
            }
          }
        });
    },
    async validate() {
      this.prestamoAEditar.periodo_inicio_descuento = this.dateForPicker;
      if (this.$refs.form.validate()) {
        this.deshabilitarBotonGuardar = true;

        if (this.prestamoAEditar.aprobado === 'No') {
          this.showErrorAlert();
          this.deshabilitarBotonGuardar = false;
          return;
        }

        if (this.nuevoPrestamo) {
          this.persona.creditos.push(this.prestamoAEditar);
        } else {
          this.persona.creditos.map((prestamo) => {
            if (
              JSON.stringify(prestamo) ===
              JSON.stringify(this.$options.prestamoOriginal)
            ) {
              prestamo = this.prestamoAEditar;
            }
            return prestamo;
          });
        }

        this.showSaveAlert();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/main.scss';

.dialog-container {
  overflow: scroll;
}
</style>
