const obtenerVacacionesSolicitadas = (persona) => {
  let existenVacacionesSolitadas = persona.vacaciones.find(
    (vacaciones) => vacaciones.aprobado === '' || !vacaciones.aprobado
  );

  if (existenVacacionesSolitadas) persona.vacacionesSolicitadas = true;

  return persona;
};

export default obtenerVacacionesSolicitadas;
