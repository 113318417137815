import fetch from 'isomorphic-fetch';

const ENDPOINT_URL = process.env.VUE_APP_ELIMINAR_PLANILLA;
async function eliminarPlanilla(planilla) {
  const bodyParams = {
    planilla,
    token: localStorage.getItem('token'),
  };

  let body = JSON.stringify(bodyParams);

  return await fetch(ENDPOINT_URL, {
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  }).then((item) => item.json());
}
export { eliminarPlanilla };
