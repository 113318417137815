import fetch from 'isomorphic-fetch';

const ENDPOINT_URL = process.env.VUE_APP_ENVIAR_BOLETA_MENSUAL_URL;
async function enviarBoletaMensual(email) {
  const bodyParams = {
    periodo: email.periodo,
    emailFrom: email.emailFrom,
    emailTo: email.emailTo,
    body: email.body,
    token: localStorage.getItem('token'),
  };

  let body = JSON.stringify(bodyParams);

  return fetch(ENDPOINT_URL, {
    // return await fetch(ENDPOINT_URL, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  }).then((item) => item.json());
}
export { enviarBoletaMensual };
