<template>
  <v-dialog
    v-model="cambiarClaveDialog"
    max-width="45vw"
    max-height="35vh"
    persistent
  >
    <v-card>
      <v-card-title
        class="text-h5 justify-center"
        style="color: teal; font-weight: bold"
      >
        {{ `Cambio de clave` }}
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col>
              <v-text-field
                type="password"
                label="Contraseña"
                v-model.trim="password"
                :rules="fieldRules"
              />
              <!-- </v-col>
            </v-row>
            <v-row>
              <v-col> -->
              <v-text-field
                type="password"
                label="Confirmar contraseña"
                v-model.trim="confirmarPassword"
                :rules="fieldRules"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn color="blue darken-1" text @click="closeDialog">Cancelar</v-btn>
        <v-btn color="success" text @click="validate">Guardar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';
import { cambiarCredenciales } from '@/api/persons/cambiarCredenciales.js';

export default {
  name: 'CambiarClaveDialog',
  data() {
    return {
      password: '',
      confirmarPassword: '',
      valid: true,
      fieldRules: [
        (v) => !!v || 'Campo requerido',
        (v) => v.length >= 5 || 'Mínimo 5 caracteres',
      ],
    };
  },
  props: {
    persona: Object,
  },
  computed: {
    ...mapState(['cambiarClaveDialog']),
  },
  methods: {
    closeDialog() {
      this.password = '';
      this.confirmarPassword = '';
      this.$store.state.cambiarClaveDialog = false;
    },
    // showSuccessAlert() {
    //   this.$swal.fire({
    //     title: `Operación realizada correctamente!`,
    //     text: '',
    //     icon: 'success',
    //     confirmButtonColor: '#3085d6',
    //     confirmButtonText: 'Ok',
    //   });
    // },
    showAlert(title, icon) {
      this.$swal.fire({
        title,
        text: '',
        icon,
      });
    },
    async validate() {
      if (this.$refs.form.validate()) {
        if (this.password === this.confirmarPassword) {
          let updatedPerson = this.persona;
          updatedPerson.password = this.password;
          const { error, message, tokenExpired } = await cambiarCredenciales(
            updatedPerson
          );
          if (error) {
            //do something
          }
          if (message) {
            const msjAlerta = 'Datos actualizados correctamente!';
            this.showAlert(msjAlerta, 'success');
            this.closeDialog();
          }
          if (tokenExpired) {
            routeToLoginIfTokenIsExpired(this);
          }
          return;
        }
        //mostrar alerta
        const msjAlerta = 'Las contraseñas ingresadas no coinciden!';
        this.showAlert(msjAlerta, 'error');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/main.scss';

.dialog-container {
  overflow: scroll;
}
</style>
