import fetch from 'isomorphic-fetch';

let ENDPOINT_URL = process.env.VUE_APP_FLOTA_NEW;

async function newUnit(item) {
  const bodyParams = {
    placa: item.placa,
    tipo_unidad: item.tipo,
    disponible: item.disponible,
    observaciones: item.obs,
    token: localStorage.getItem('token'),
  };

  let body = JSON.stringify(bodyParams);

  return await fetch(ENDPOINT_URL, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  }).then((item) => item.json());
}
export { newUnit };
