<template>
  <v-container v-if="renderCreateRoutes">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-col>
        <v-row>
          <div class="new_route">
            <v-text-field
              :disabled="disabled"
              label="Ruta : "
              v-model="name_ruta"
              :rules="fieldRules"
            />
            <v-text-field
              :disabled="disabled"
              label="Hora inicio"
              v-model="hour"
              type="time"
              name=""
              id=""
              :rules="fieldRules"
            />
            <div>
              <v-btn :disabled="disabled" small @click="newRoute">
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
            </div>
          </div>
        </v-row>
      </v-col>
    </v-form>
  </v-container>
</template>
<script>
import { mapState } from 'vuex';
import { newRoute } from '@/api/route/newRoute.js';
import moment from 'moment';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';

export default {
  data: () => ({
    hour: '',
    name_ruta: '',
    picker: localStorage.getItem('Picker'),
    valid: true,
    fieldRules: [(v) => !!v || 'Campo requerido'],
  }),
  props: {
    programacion: Object,
    disabled: Boolean,
  },
  computed: {
    ...mapState(['renderCreateRoutes']),
  },
  methods: {
    async newRoute() {
      // console.log(this.picker);
      // if (!this.hour) {
      //   this.$swal.fire({
      //     title: 'Debes ingresar la hora, inténtalo nuevamente',
      //     text: '',
      //     icon: 'warning',
      //     showConfirmButton: false,
      //     timer: 1500,
      //   });
      // }
      if (this.$refs.form.validate()) {
        const { error, message, tokenExpired } = await newRoute(
          moment(this.picker)
            .tz('America/Lima')
            .format(),
          this.hour,
          this.name_ruta
        );
        if (error) {
          // console.log(error);
        }

        if (tokenExpired) {
          routeToLoginIfTokenIsExpired(this);
        }

        if (message) {
          this.$swal.fire({
            title:
              'La ruta fue creada con éxito, ahora puedes agregar clientes, tripulantes y unidades',
            text: '',
            icon: 'success',
            showConfirmButton: false,
            timer: 1500,
          });
          this.hour = '';
          this.name_ruta = '';
          this.$store.state.renderCreateRoutes = false;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'src/styles/main.scss';
.new_route {
  border: 1px rgba(82, 95, 88, 0.678) solid;
  border-radius: 5px;
  margin: auto;
  justify-content: space-between;
  display: flex;
  padding: 10px;
  .v-text-field {
    max-width: 30% !important;
  }
  .v-btn {
    display: flex;
    flex-direction: row;
    background: transparent !important;
    min-width: 20px !important;
    box-shadow: none !important;
  }
}
.container_cards {
  height: 56vh;
  overflow: scroll;
  overflow-x: hidden;
}
.container_cards::-webkit-scrollbar {
  width: 4px;
  background: #efeff6;
}

.container_cards::-webkit-scrollbar-thumb {
  background: $main;
  border-radius: 10px;
}
.align-center {
  text-align: center;
}
.p-10 {
  padding: 10px;
}
.column {
  flex-direction: row;
}
.bg-secondary {
  background: $secondary;
}
.block {
  display: block;
}
div {
  .theme--light.v-card {
    background-color: $secondary;
  }
  .v-card {
    margin: auto;
    max-width: fit-content;
  }
}
</style>
