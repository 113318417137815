<template>
  <v-card min-height="400px">
    <v-data-table
      id="Vacaciones"
      class="elevation-1"
      fixed-header
      height="80vh"
      :headers="headers"
      :items="personal"
      :loading="tableLoading"
      loading-text="Cargando reporte de vacaciones... Espere..."
      hide-default-footer
      disable-pagination
      no-data-text="No hay personal aún..."
      no-results-text="No se encontraron registros..."
      single-expand
      :expanded.sync="expanded"
      show-expand
      :search="searchPerson"
      item-key="uniqueKey"
      :custom-sort="customSort"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-toolbar-title class="top-bar-title">Vacaciones</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchPerson"
            append-icon="mdi-magnify"
            label="Buscar persona..."
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:[`item.estados`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-chip
              :color="getColor(item)"
              class="chip-class"
              :dark="item.vacacionesPendientes > 30 ? true : false"
              v-on="on"
            >
              {{ item.vacacionesPendientes }}
            </v-chip>
          </template>
          <span>Días pendientes de gozar</span>
        </v-tooltip>
      </template>
      <!-- <v-chip :color="getColor(item)" class="chip-class" label> </v-chip> -->
      <template v-slot:[`item.solicitudes`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-icon v-if="item.vacacionesSolicitadas" v-on="on"
              >mdi-email-alert-outline</v-icon
            >
          </template>
          <span>Hay vacaciones solicitadas</span>
        </v-tooltip>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <!-- More info about {{ item.vacaciones }} -->
          <v-container fluid>
            <v-data-table
              id="Vacaciones-Detalle"
              :headers="vacacionesHeaders"
              :items="item.vacaciones"
              hide-default-footer
              disable-pagination
              :search="search"
              no-data-text="No hay vacaciones registradas..."
              no-results-text="No se encontraron registros..."
              @click:row="editarVacaciones"
              :item-class="vacacionesClass"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title class="top-bar-title"
                    >Registros de {{ item.nombre }}</v-toolbar-title
                  >
                  <v-spacer></v-spacer>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="agregarVacaciones" v-on="on">
                        <v-icon color="orange">mdi-earth-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Agregar vacaciones</span>
                  </v-tooltip>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar vacaciones..."
                    single-line
                    hide-details
                  ></v-text-field>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-container>
        </td>
      </template>
    </v-data-table>

    <EditarVacaciones
      :persona="this.persona"
      :vacaciones="this.vacaciones"
      :nuevoVacaciones="this.nuevoVacaciones"
    />
  </v-card>
</template>
<script>
import { mapState } from 'vuex';
import moment from 'moment';

import { getPersons } from '@/api/persons/getPersons.js';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';
import obtenerVacacionesCumplidasYProgramadas from '@/utils/obtenerVacacionesCumplidasYProgramadas.js';
import obtenerVacacionesPendientes from '@/utils/obtenerVacacionesPendientes.js';
import obtenerVacacionesSolicitadas from '@/utils/obtenerVacacionesSolicitadas.js';
import EditarVacaciones from './EditarVacaciones.vue';

export default {
  name: 'Vacaciones',
  data: () => ({
    nuevoVacaciones: true,
    tableLoading: true,
    expanded: [],
    personal: [],
    persona: {},
    vacaciones: {},
    search: '',
    searchPerson: '',
  }),
  components: {
    EditarVacaciones,
  },
  watch: {
    expanded() {
      this.$store.state.tablaActivaGestionPersonal = 'Vacaciones';
      if (this.expanded.length > 0)
        this.$store.state.tablaActivaGestionPersonal = 'Vacaciones-Detalle';
    },
    editarVacacionesDialog() {
      if (!this.editarVacacionesDialog) this.getPersonal();
    },
  },
  computed: {
    ...mapState(['editarVacacionesDialog', 'cliente']),
    vacacionesHeaders() {
      let fixedHeaders = [
        {
          text: 'Período',
          value: 'periodo',
        },
        {
          text: 'Fecha de inicio',
          value: 'fecha_inicio',
        },
        {
          text: 'Fecha de fin',
          value: 'fecha_fin',
        },
        {
          text: 'Aprobado',
          value: 'aprobado',
        },
        {
          text: 'Tipo',
          value: 'tipo_vacaciones',
        },
        {
          text: 'Observaciones',
          value: 'observaciones',
          width: '300px',
        },
      ];

      return fixedHeaders;
    },
    headers() {
      let fixedHeaders = [
        {
          text: 'Documento',
          value: 'doi',
        },
        {
          text: 'Nombre',
          value: 'nombre',
          width: '275px',
        },
        {
          text: 'Cargo',
          value: 'datos_laborales.cargo',
        },
        {
          text: 'Fecha de ingreso',
          value: 'fecha_ingreso',
        },
        {
          text: 'Situación laboral',
          value: 'datos_laborales.situacion',
        },
        {
          text: 'Días pendientes',
          value: 'estados',
        },
        {
          text: 'Solicitudes',
          value: 'solicitudes',
        },
      ];

      return fixedHeaders;
    },
  },
  created() {
    this.getPersonal();
    this.$store.state.tablaActivaGestionPersonal = 'Vacaciones';
  },
  methods: {
    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        switch (index[0]) {
          case 'estados':
            if (isDesc[0]) {
              return a.vacacionesPendientes > b.vacacionesPendientes ? -1 : 1;
            } else {
              return b.vacacionesPendientes > a.vacacionesPendientes ? -1 : 1;
            }
          case 'solicitudes':
            if (isDesc[0]) {
              return a.vacacionesSolicitadas === b.vacacionesSolicitadas
                ? 0
                : a.vacacionesSolicitadas
                ? -1
                : 1;
            } else {
              return a.vacacionesSolicitadas === b.vacacionesSolicitadas
                ? 0
                : a.vacacionesSolicitadas
                ? 1
                : -1;
            }
          case 'datos_laborales.situacion':
            if (isDesc[0]) {
              return a.datos_laborales.situacion > b.datos_laborales.situacion
                ? -1
                : 1;
            } else {
              return b.datos_laborales.situacion > a.datos_laborales.situacion
                ? -1
                : 1;
            }
          case 'datos_laborales.cargo':
            if (isDesc[0]) {
              return a.datos_laborales.cargo > b.datos_laborales.cargo ? -1 : 1;
            } else {
              return b.datos_laborales.cargo > a.datos_laborales.cargo ? -1 : 1;
            }

          default:
            if (isDesc[0]) {
              return a[index[0]] > b[index[0]] ? -1 : 1;
            } else {
              return b[index[0]] > a[index[0]] ? -1 : 1;
            }
        }
      });
      return items;
    },
    vacacionesClass(vacaciones) {
      let colorFila = '';
      if (vacaciones.aprobado === '') {
        colorFila = 'red lighten-3';
      }
      if (vacaciones.vacacionesPendientes > 30) {
        colorFila = 'red';
      }
      // console.log(colorFila);
      // return '';
      return colorFila;
    },
    async getPersonal() {
      const { error, personal, tokenExpired } = await getPersons();
      if (error) {
        console.error('error en el created', error);
      }
      if (personal) {
        this.personal = personal;
        this.personal = this.personal.map((persona) => {
          persona.uniqueKey =
            persona.doi +
            '-vacaciones-' +
            persona.datos_laborales.fecha_ingreso +
            persona.datos_laborales.situacion;
          persona.nombre = `${persona.datos_laborales.apellido_paterno} ${persona.datos_laborales.apellido_materno}, ${persona.nombre}`;
          persona.fecha_ingreso = persona.datos_laborales.fecha_ingreso;
          persona.estados = [];
          persona.solicitudes = [];
          persona = obtenerVacacionesCumplidasYProgramadas(persona);
          let fechaTerminoBusquedaVacacionesPendientes = moment();

          if (
            persona.datos_laborales.situacion &&
            persona.datos_laborales.situacion === 'Cesado'
          )
            fechaTerminoBusquedaVacacionesPendientes = moment(
              persona.datos_laborales.fecha_cese
            );
          // si la persona está cesada, considerar esa fecha como termino sino hoy
          persona = obtenerVacacionesPendientes(
            persona,
            fechaTerminoBusquedaVacacionesPendientes,
            this.cliente
          );
          persona = obtenerVacacionesSolicitadas(persona);
          return persona;
        });
        this.personal = this.personal.filter(
          (persona) => persona.datos_laborales.situacion === 'Activo'
        );
        this.tableLoading = false;
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
    },
    getColor(persona) {
      if (persona.vacacionesPendientes > 30) {
        return 'red';
      }
    },
    editarVacaciones(vacaciones) {
      this.nuevoVacaciones = false;
      this.persona = this.expanded[0];
      this.vacaciones = vacaciones;
      this.$store.state.editarVacacionesDialog = true;
    },
    agregarVacaciones() {
      this.nuevoVacaciones = true;
      this.persona = this.expanded[0];

      const { fecha_ingreso } = this.persona;

      const fechaDeIngreso = moment(fecha_ingreso);
      let cantidadDiasVacacionesPorAnio = 30;
      if (
        this.cliente.datos_empresa.regimen_laboral === 'MICRO EMPRESA' ||
        this.cliente.datos_empresa.regimen_laboral === 'PEQUEÑA EMPRESA'
      ) {
        cantidadDiasVacacionesPorAnio = 15;
      }
      let periodosCumplidos = Math.floor(
        this.persona.vacacionesCumplidasYProgramadas /
          cantidadDiasVacacionesPorAnio
      );
      const periodoParteUno =
        Number(fechaDeIngreso.format('YYYY')) + periodosCumplidos;
      const periodoParteDos =
        Number(fechaDeIngreso.format('YYYY')) + periodosCumplidos + 1;

      this.vacaciones = {
        periodo: `${periodoParteUno} - ${periodoParteDos}`,
        fecha_inicio: '',
        fecha_fin: '',
        aprobado: '',
        tipo_vacaciones: [],

        observaciones: '',
      };
      this.$store.state.editarVacacionesDialog = true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/main.scss';
.chip-class {
  margin-right: 10px;
  width: 60px;
}
.top-bar-title {
  font-size: 25px !important;
  font-weight: bold !important;
  color: teal !important;
}
.v-data-table::v-deep th {
  font-size: 15px !important;
  font-weight: bold !important;
  color: teal !important;
  .v-icon {
    display: none !important;
  }
}
.align-header {
  text-align: center !important;
  background-color: $white;
}
</style>
