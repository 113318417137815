<template>
  <v-card>
    <v-row align="center" justify="center">
      <v-col v-if="mostrarDatePicker" class="date_picker_background">
        <v-date-picker
          :header-date-format="customDateTitle"
          :weekday-format="getDay"
          :month-format="getMonth"
          :first-day-of-week="0"
          color="light-green darken-3"
          header-color="light-green darken-3"
          v-model="picker"
          locale="es_PE"
          @click:date="log()"
          :min="min"
          no-title
          scrollable
          elevation="15"
          dark
          :show-current="showCurrent"
        />
      </v-col>
      <v-col v-else class="date_picker_background">
        <v-col style="text-align: center !important"
          ><v-chip>Programación día : {{ this.picker }}</v-chip>
        </v-col>
        <v-col class="edit_routes">
          <EditRoutes :programacion="this.programacionDeRutas" />
          <!-- <EditRoutes
            :disabled="closeProgramming"
            :programacion="this.programacionDeRutas"
          /> -->
          <CreateRoutes
            :disabled="closeProgramming"
            :programacion="this.programacionDeRutas"
          />
          <v-divider horizontal />
          <v-row>
            <v-col>
              <v-btn @click="openCreateRoutes()" class="fuente-boton">{{
                renderCreateRoutes ? 'cancelar' : 'nueva ruta'
              }}</v-btn>
            </v-col>
            <!-- <v-col>
              <v-btn @click="close">Cerrar programación del día</v-btn>
            </v-col> -->
          </v-row>
        </v-col>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import moment from 'moment-timezone';
import { mapState } from 'vuex';
import EditRoutes from './EditRoutes';
import CreateRoutes from './CreateRoutes';
import { getByDate } from '@/api/route/getxDate.js';
import { newProgramming } from '@/api/route/newRoutesProgramming.js';
// import { closeProgramming } from '@/api/route/closeProgramming.js';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';
// import CardContainer from './CardContainer';
export default {
  name: 'DatePicker',
  data() {
    return {
      closeProgramming: false,
      disabled: false,
      enableEvents: false,
      flat: false,
      fullWidth: false,
      landscape: false,
      min: moment()
        .add(-10, 'days')
        .tz('America/Lima')
        .format(),
      // month: false,
      mostrarDatePicker: true,
      multiple: false,
      name_ruta: 'Nueva_ruta',
      picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      picker2: moment()
        .tz('America/Lima')
        .format('MMM-YYYY'),
      // programacion: {},
      reactive: false,
      router: 0,
      readonly: false,
      showCurrent: true,
    };
  },
  components: {
    CreateRoutes,
    EditRoutes,
  },
  created() {
    this.$store.state.renderCreateRoutes = false;
  },
  mounted() {
    //si existe una propiedad del objeto significa q está cargado el mapa sino hay q volver a cargarlo
    if (!this.directionsRenderer.bound) {
      this.$router.push({
        path: '/dashboard',
      });
    }
  },
  computed: {
    // functionEvents() {
    //   return this.month ? this.monthFunctionEvents : this.dateFunctionEvents;
    // },
    ...mapState([
      'directionsRenderer',
      'renderCreateRoutes',
      'renderEditRoutes',
      'programacionDeRutas',
    ]),
  },
  watch: {
    renderCreateRoutes() {
      //si la ruta fue guardada en CreateRoutes y se cerró ese componente, entonces se vuelve a renderizar el DatePicker para visualizar la nueva ruta
      if (!this.$store.state.renderCreateRoutes) this.renderDatePicker();
    },
  },
  methods: {
    getDay(date) {
      const daysOfWeek = ['Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa', 'Do'];
      let i = new Date(date).getDay(date);
      return daysOfWeek[i];
    },
    getMonth(date) {
      const months = [
        'FEB',
        'MAR',
        'ABR',
        'MAY',
        'JUN',
        'JUL',
        'AGO',
        'SEP',
        'OCT',
        'NOV',
        'DIC',
        'ENE',
      ];
      let i = new Date(date).getMonth(date);
      return months[i];
    },
    customDateTitle(date) {
      const localeMonth = moment(date)
        .tz('America/Lima')
        .format('M');
      let month = localeMonth;
      switch (month) {
        case '1':
          month = 'Ene';
          break;
        case '2':
          month = 'Feb';
          break;
        case '3':
          month = 'Mar';
          break;
        case '4':
          month = 'Abr';
          break;
        case '5':
          month = 'May';
          break;
        case '6':
          month = 'Jun';
          break;
        case '7':
          month = 'Jul';
          break;
        case '8':
          month = 'Ago';
          break;
        case '9':
          month = 'Sep';
          break;
        case '10':
          month = 'Oct';
          break;
        case '11':
          month = 'Nov';
          break;
        case '12':
          month = 'Dic';
          break;

        default:
          break;
      }
      const fixedMonth =
        month +
        ' - ' +
        moment(date)
          .tz('America/Lima')
          .format('YYYY');
      return fixedMonth;
    },
    openCreateRoutes() {
      if (this.renderCreateRoutes) {
        this.$store.state.renderCreateRoutes = false;
      } else {
        this.$store.state.renderCreateRoutes = true;
      }
    },
    // dateFunctionEvents(date) {
    //   const [, , day] = date.split('-');
    //   if ([12, 17, 28].includes(parseInt(day, 10))) return true;
    //   if ([1, 19, 22].includes(parseInt(day, 10))) return ['red', '#00f'];
    //   return false;
    // },
    // monthFunctionEvents(date) {
    //   const month = parseInt(date.split('-')[1], 10);
    //   if ([1, 3, 7].includes(month)) return true;
    //   if ([2, 5, 12].includes(month))
    //     return ['error', 'purple', 'rgba(0, 128, 0, 0.5)'];
    //   return false;
    // },
    // async close() {
    //   const { error, message, tokenExpired } = await closeProgramming(
    //     moment(this.picker).tz('America/Lima')
    //   );
    //   if (error) {
    //     //do something
    //   }
    //   if (message) {
    //     this.closeProgramming = true;
    //   }
    //   if (tokenExpired) {
    //     routeToLoginIfTokenIsExpired(this);
    //   }
    // },
    showAlert() {
      this.$swal
        .fire({
          title: 'No existe programación ¿Deseas crearla?',
          text: '',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: 'No',
          confirmButtonText: 'Sí',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.createProgramming();
            this.mostrarDatePicker = false;
          } else {
            this.mostrarDatePicker = true;
          }
        });
    },
    async renderDatePicker() {
      // console.log(this.picker);
      this.$store.state.renderEditRoutes = false;
      const { error, programacion, tokenExpired } = await getByDate(
        moment(this.picker)
          .tz('America/Lima')
          .format()
      );
      if (error) {
        this.showAlert();
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (programacion) {
        // this.programacion = programacion;
        this.$store.state.programacionDeRutas = programacion;
        this.$store.state.renderEditRoutes = true;
        this.mostrarDatePicker = false;
      }
    },
    async log() {
      localStorage.setItem('Picker', this.picker);
      this.renderDatePicker();
    },
    async createProgramming() {
      const { error, message, tokenExpired } = await newProgramming(
        moment(this.picker)
          .tz('America/Lima')
          .format()
      );
      if (error) {
        //do something
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (message) {
        await this.renderDatePicker();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'src/styles/main.scss';
.fuente-boton {
  font-size: 16px !important;
}
.edit_routes {
  overflow: scroll;
  overflow-x: hidden;
  max-height: 70vh;
}
.edit_routes::-webkit-scrollbar {
  width: 4px;
  background: $secondary;
}

.edit_routes::-webkit-scrollbar-thumb {
  background: $main;
  border-radius: 10px;
}
.date_picker_background {
  background-color: $secondary;
  text-align: center !important;
}
.servicios_drop,
.personal_drop,
.flota_drop {
  padding: 5px;
  width: 100%;
  max-height: 100vh;
}
.personal_drop .flota_drop .flex {
  display: flex;
}
</style>
