<template>
  <v-dialog
    v-model="formularioContactoSoporte"
    max-width="60vw"
    max-height="50vh"
    persistent
  >
    <v-card>
      <v-card-title
        class="text-h5 justify-center"
        style="color: teal; font-weight: bold"
      >
        Soporte Técnico
      </v-card-title>
      <v-card-subtitle>
        Si tienes problemas con la aplicación CTI Roads o necesitas asistencia,
        por favor detalla tu consulta abajo y nos comunicaremos contigo!
      </v-card-subtitle>
      <v-card-text>
        <v-form ref="formContactoSoporte" v-model="valid" lazy-validation>
          <v-row>
            <v-col>
              <v-flex>
                <v-textarea
                  label="Escribe tu consulta para soporte técnico"
                  placeholder="Detalle aquí tu problema o consulta"
                  v-model="textoConsulta"
                  :rules="textoConsultaRules"
                >
                </v-textarea>
              </v-flex>
            </v-col>

            <!-- <v-col class="mapa-servicio">
              <div ref="map"></div>
            </v-col> -->
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDialog">Cancelar</v-btn>
        <v-btn color="success" text @click="validate">Enviar</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

import { getClientData } from '@/api/configuration/getClientData.js';
import { crearIssueSoporte } from '@/api/jira/crearIssueSoporte.js';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';

export default {
  name: 'FormularioContactoSoporte',
  data() {
    return {
      textoConsulta: '',
      textoConsultaRules: [(v) => !!v || 'Campo obligatorio'],
      valid: false,
    };
  },
  computed: {
    ...mapState(['formularioContactoSoporte', 'cliente']),
  },
  methods: {
    showEnviarCorreoAlert() {
      this.$swal
        .fire({
          title: `Atención!`,
          text: `Va a enviar su requerimiento al área de soporte técnico`,
          icon: 'warning',
          showCancelButton: true,
          cancelButtonColor: 'grey',
          cancelButtonText: 'Cancelar',
          confirmButtonColor: 'success',
          confirmButtonText: 'Enviar',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.enviarCorreoSoporte();
          }
        });
    },
    showSuccessAlert(issueID) {
      this.$swal.fire({
        title: `Operación realizada correctamente!`,
        text: `El código de atención generado es: ${issueID}`,
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    showErrorAlert() {
      this.$swal.fire({
        title: `Error`,
        text:
          'Hubo un error al contactarse con soporte técnico, por favor envíe un email a ventas@ctiprocesos.com',
        icon: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    async enviarCorreoSoporte() {
      //generar un nro random de atención/ticket de 10 caracteres
      const issueID = Math.random()
        .toString(36)
        .slice(2);

      const { ruc } = this.$store.state.cliente;

      //generar JIRA card con los detalles del contacto
      const response = await crearIssueSoporte(
        this.textoConsulta,
        ruc,
        issueID
      );

      if (response) {
        this.showSuccessAlert(issueID);
        this.textoConsulta = '';
        this.closeDialog();
      } else {
        this.showErrorAlert();
      }
    },
    validate() {
      if (this.$refs.formContactoSoporte.validate()) {
        this.showEnviarCorreoAlert();
      }
    },
    closeDialog() {
      this.$store.state.formularioContactoSoporte = false;
    },
    async storeClientData() {
      const { error, tokenExpired, client } = await getClientData();
      if (error) {
        //do something
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (client) {
        this.$store.state.cliente = client;
      }
    },
  },
  mounted() {
    this.storeClientData();
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/main.scss';
// .mapa-servicio {
//   display: grid !important;
//   max-height: 100%;
//   max-width: 50vw;
//   height: 450px !important;
// }
// .dialog-container {
//   overflow: scroll;
// }
</style>
