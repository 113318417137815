import fetch from 'isomorphic-fetch';

let ENDPOINT_URL = process.env.VUE_APP_EDITAR_PERSONAL;

async function editUserInfo(persona) {
  const bodyParams = {
    _id: persona._id,
    doi: persona.doi.toString(),
    username: persona.username.toLowerCase(),
    nombre: persona.datos_laborales.nombres,
    email:
      !!persona.email && persona.email.length > 0
        ? persona.email
        : 'test@test.com',
    direccion: persona.direccion,
    roles: persona.roles,
    datos_laborales: persona.datos_laborales,
    creditos: persona.creditos,
    descansos_medicos: persona.descansos_medicos,
    permisos: persona.permisos,
    sanciones: persona.sanciones,
    vacaciones: persona.vacaciones,
    token: localStorage.getItem('token'),
  };

  let body = JSON.stringify(bodyParams);

  return await fetch(ENDPOINT_URL, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  }).then((item) => item.json());
}

async function editarVacaciones(persona) {
  const bodyParams = {
    _id: persona._id,
    vacaciones: persona.vacaciones,
    token: localStorage.getItem('token'),
  };

  let body = JSON.stringify(bodyParams);

  return await fetch(ENDPOINT_URL, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  }).then((item) => item.json());
}

async function editarCreditos(persona) {
  const bodyParams = {
    _id: persona._id,
    creditos: persona.creditos,
    token: localStorage.getItem('token'),
  };

  let body = JSON.stringify(bodyParams);

  return await fetch(ENDPOINT_URL, {
    method: 'put',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  }).then((item) => item.json());
}

export { editUserInfo, editarVacaciones, editarCreditos };
