var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex"},[_c('v-row',{staticClass:"align-baseline",attrs:{"rows":"6"}},[_c('v-col',{staticClass:"d-flex",attrs:{"data-app":""}},[_c('v-select',{attrs:{"items":_vm.dropdown1,"label":"Estado"},model:{value:(_vm.estado),callback:function ($$v) {_vm.estado=$$v},expression:"estado"}})],1),_c('v-col',{staticClass:"d-flex"},[_c('v-text-field',{attrs:{"type":"date","label":"Inicio"},model:{value:(_vm.range1),callback:function ($$v) {_vm.range1=$$v},expression:"range1"}})],1),_c('v-col',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"search_date",attrs:{"type":"date","label":"Fin"},model:{value:(_vm.range2),callback:function ($$v) {_vm.range2=$$v},expression:"range2"}})],1),_c('v-col',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"btn_filter",on:{"click":function($event){return _vm.filterbyDate()}}},[_c('v-img',{attrs:{"max-width":"20px","cover":"","src":require('@/assets/menu-icons/filter.svg')}})],1)],1)],1),_c('div',{staticClass:"relative"},[_c('v-text-field',{attrs:{"placeholder":"Buscar por nombre"},model:{value:(_vm.inputSearch),callback:function ($$v) {_vm.inputSearch=$$v},expression:"inputSearch"}}),_c('v-btn',[_c('v-img',{attrs:{"max-width":"20px","cover":"","src":require('@/assets/menu-icons/search.svg')}})],1)],1)],1),_c('v-divider',{attrs:{"horizontal":""}}),_c('div',{staticClass:"container "},[_vm._l((_vm.change),function(item1,y){return _c('div',{key:y},[(item1.estado == 'Pendiente')?_c('div',[_c('v-card',{class:[
            item1.estado == 'Pendiente'
              ? 'pendiente'
              : item1.estado == 'Abierto'
              ? 'abierto'
              : item1.estado == 'Cerrado'
              ? 'cerrado'
              : null ],attrs:{"elevation":"6"},on:{"click":function($event){_vm.overlay = !_vm.overlay;
            _vm.handleEmit(item1);}}},[_c('div',{staticClass:"group-data_incidence"},[_c('p',[_vm._v(_vm._s(item1.ruta))]),_c('p',[_vm._v("Hora de reporte : "+_vm._s(item1.hora))]),_c('p',[_vm._v("Lugar de incidencia : "+_vm._s(item1.lugar))]),_c('p',[_vm._v("Creado por : "+_vm._s(item1.creado))])])])],1):_vm._e()])}),_vm._l((_vm.change),function(item,i){return _c('div',{key:i},[(item.estado == 'Abierto')?_c('div',[_c('v-card',{class:[
            item.estado == 'Pendiente'
              ? 'pendiente'
              : item.estado == 'Abierto'
              ? 'abierto'
              : item.estado == 'Cerrado'
              ? 'cerrado'
              : null ],attrs:{"elevation":"6"},on:{"click":function($event){_vm.overlay = !_vm.overlay;
            _vm.handleEmit(item);}}},[_c('div',{staticClass:"group-data_incidence"},[_c('p',[_vm._v(_vm._s(item.estado))]),_c('p',[_vm._v("Hora de reporte : "+_vm._s(item.hora))]),_c('p',[_vm._v("Lugar de incidencia : "+_vm._s(item.lugar))]),_c('p',[_vm._v("Creado por : "+_vm._s(item.creado))])])])],1):_vm._e()])})],2),(_vm.overlay)?_c('div',[_c('complete-info',{attrs:{"data":_vm.item}}),_c('v-btn',{staticClass:"btn-close",on:{"click":function($event){_vm.overlay = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }