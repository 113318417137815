<template>
  <v-card min-height="400px" class="licencia-container">
    <v-data-table
      id="Licencia"
      class="elevation-1"
      fixed-header
      height="80vh"
      :headers="headers"
      :items="personal"
      :loading="tableLoading"
      loading-text="Cargando reporte de licencias y permisos... Espere..."
      hide-default-footer
      disable-pagination
      no-data-text="No hay personal aún..."
      no-results-text="No se encontraron registros..."
      single-expand
      :expanded.sync="expanded"
      show-expand
      :search="searchPerson"
      item-key="uniqueKey"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-toolbar-title class="top-bar-title"
            >Licencias y Permisos</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchPerson"
            append-icon="mdi-magnify"
            label="Buscar persona..."
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <!-- More info about {{ item.descansos_medicos }} -->
          <v-container fluid>
            <v-data-table
              id="Licencia-Detalle"
              :headers="licenciaHeaders"
              :items="item.permisos"
              hide-default-footer
              disable-pagination
              :search="search"
              no-data-text="No hay licencias ni permisos registrados..."
              no-results-text="No se encontraron registros..."
              @click:row="editarLicencia"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title class="top-bar-title"
                    >Registros de {{ item.nombre }}</v-toolbar-title
                  >
                  <v-spacer></v-spacer>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="agregarLicencia()" v-on="on">
                        <v-icon color="purple darken-2">mdi-home-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Agregar licencia o permiso</span>
                  </v-tooltip>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar licencia/permiso..."
                    single-line
                    hide-details
                  ></v-text-field>
                </v-toolbar>
              </template>
            </v-data-table>
          </v-container>
        </td>
      </template>
    </v-data-table>

    <EditarLicencia
      :persona="this.persona"
      :permiso="this.permiso"
      :nuevoPermiso="this.nuevoPermiso"
    />
  </v-card>
</template>

<script>
import { mapState } from 'vuex';

import { getPersons } from '@/api/persons/getPersons.js';
import EditarLicencia from './EditarLicencia.vue';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';

export default {
  name: 'Licencia',
  data: () => ({
    nuevoPermiso: true,
    tableLoading: true,
    expanded: [],
    personal: [],
    persona: {},
    permiso: {},
    search: '',
    searchPerson: '',
  }),
  components: {
    EditarLicencia,
  },
  computed: {
    ...mapState(['editarLicenciaDialog']),
    licenciaHeaders() {
      let fixedHeaders = [
        {
          text: 'Tipo de permiso',
          value: 'tipo_permiso',
        },
        {
          text: 'Fecha de inicio',
          value: 'fecha_inicio',
        },
        {
          text: 'Fecha de fin',
          value: 'fecha_fin',
        },
        {
          text: 'Con goce de haber',
          value: 'goce_haber',
        },
        {
          text: 'Observaciones',
          value: 'observaciones',
          width: '300px',
        },
      ];

      //   fixedHeaders.push(...this.diasDelMes);
      return fixedHeaders;
    },
    headers() {
      let fixedHeaders = [
        {
          text: 'Documento',
          value: 'doi',
        },
        {
          text: 'Nombre/Razón social',
          value: 'nombre',
          width: '275px',
        },
        {
          text: 'Cargo',
          value: 'datos_laborales.cargo',
        },
        {
          text: 'Dirección',
          value: 'direccion',
        },
        {
          text: 'Situación laboral',
          value: 'datos_laborales.situacion',
        },
      ];

      //   fixedHeaders.push(...this.diasDelMes);
      return fixedHeaders;
    },
  },
  created() {
    this.getPersonal();
    this.$store.state.tablaActivaGestionPersonal = 'Licencia';
  },
  watch: {
    expanded() {
      this.$store.state.tablaActivaGestionPersonal = 'Licencia';
      if (this.expanded.length > 0)
        this.$store.state.tablaActivaGestionPersonal = 'Licencia-Detalle';
    },
    editarLicenciaDialog() {
      if (!this.editarLicenciaDialog) this.getPersonal();
    },
  },
  methods: {
    async getPersonal() {
      const { error, personal, tokenExpired } = await getPersons();
      if (error) {
        console.error('error en el created', error);
      }
      if (personal) {
        this.personal = personal;
        this.personal = this.personal.map((persona) => {
          persona.uniqueKey =
            persona.doi +
            '-licencia-' +
            persona.datos_laborales.fecha_ingreso +
            persona.datos_laborales.situacion;
          persona.nombre = `${persona.datos_laborales.apellido_paterno} ${persona.datos_laborales.apellido_materno}, ${persona.nombre}`;
          return persona;
        });
        this.personal = this.personal.filter(
          (persona) => persona.datos_laborales.situacion === 'Activo'
        );
        this.tableLoading = false;
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
    },
    editarLicencia(permiso) {
      this.nuevoPermiso = false;
      this.persona = this.expanded[0];
      this.permiso = permiso;
      this.$store.state.editarLicenciaDialog = true;
    },
    agregarLicencia() {
      this.nuevoPermiso = true;
      this.persona = this.expanded[0];
      this.permiso = {
        tipo_permiso: '',
        fecha_inicio: '',
        fecha_fin: '',
        goce_haber: '',
        observaciones: '',
      };
      this.$store.state.editarLicenciaDialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/main.scss';
.top-bar-title {
  font-size: 25px !important;
  font-weight: bold !important;
  color: teal !important;
}
.licencia-container {
  overflow: auto;
}
.v-data-table::v-deep th {
  font-size: 15px !important;
  font-weight: bold !important;
  color: teal !important;
  .v-icon {
    display: none !important;
  }
}
.align-header {
  text-align: center !important;
  background-color: $white;
}
</style>
