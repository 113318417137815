import fetch from 'isomorphic-fetch';

let ENDPOINT_URL = process.env.VUE_APP_LOGIN;

async function login(user) {
  const bodyParams = {
    ruc: user.ruc.toString(),
    username: user.username.toLowerCase(),
    password: user.password,
  };

  let body = JSON.stringify(bodyParams);

  return await fetch(ENDPOINT_URL, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  }).then((item) => item.json());
}
export { login };
