var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-height":"50vh","max-width":"75vw","persistent":""},model:{value:(_vm.verHorasLaboradasPorSemanaDialog),callback:function ($$v) {_vm.verHorasLaboradasPorSemanaDialog=$$v},expression:"verHorasLaboradasPorSemanaDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 justify-center",staticStyle:{"color":"teal","font-weight":"bold"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.obtenerNombre)}})]),_c('v-card-subtitle',{staticClass:"text-h5",staticStyle:{"color":"teal"}},[_vm._v(" "+_vm._s(("" + (this.obtenerNroDeSemana)))+" ")]),_c('div',{staticClass:"scrollable-horizontal"},[_c('v-row',{staticClass:"no-wrap"},_vm._l((_vm.asistenciasARenderizar),function(asistencia,index){return _c('v-col',{key:index,staticClass:"custom-col",attrs:{"cols":"2"}},[_c('div',{staticClass:"negrita"},[_vm._v(" "+_vm._s(_vm.obtenerDiaAsistenciaText(asistencia))+" ")]),_c('div',{staticClass:"negrita"},[_vm._v(" "+_vm._s(_vm.obtenerHorasLaboradasText(asistencia))+" ")]),(asistencia.datos_laborales.switchSujetoAJornadaIntermitente)?_c('div',_vm._l((asistencia.jornadas),function(marcacion,index){return _c('v-row',{key:index + marcacion.hora_inicio_jornada},[_c('v-col',[_c('v-text-field',{attrs:{"label":("Inicio de jornada " + (index + 1)),"value":marcacion.hora_inicio_jornada,"readonly":""}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":("Fin de jornada " + (index + 1)),"value":marcacion.hora_fin_jornada,"readonly":""}})],1)],1)}),1):_c('div',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Inicio de jornada","value":asistencia.hora_inicio_jornada
                      ? asistencia.hora_inicio_jornada
                      : '-',"readonly":""}}),_c('v-text-field',{attrs:{"label":"Inicio de almuerzo","value":asistencia.hora_inicio_almuerzo
                      ? asistencia.hora_inicio_almuerzo
                      : '-',"readonly":""}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Fin de jornada","value":asistencia.hora_fin_jornada
                      ? asistencia.hora_fin_jornada
                      : '-',"readonly":""}}),_c('v-text-field',{attrs:{"label":"Fin de almuerzo","value":asistencia.hora_fin_almuerzo
                      ? asistencia.hora_fin_almuerzo
                      : '-',"readonly":""}})],1)],1)],1)])}),1)],1),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDialog}},[_vm._v("Cancelar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }