<template>
  <v-dialog
    fullscreen
    v-model="plansDialog"
    max-width="60vw"
    max-height="50vh"
    persistent
  >
    <v-card>
      <v-toolbar dark class="encabezado">
        <v-btn icon dark @click="closeDialog">
          <v-icon class="cerrarPagina">mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Precios</v-toolbar-title>
      </v-toolbar>
      <KickOffPlans :nuevoCliente="true" v-if="showKickOffPlans" />
      <!-- <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDialog">Cancelar</v-btn>
      </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import KickOffPlans from '@/components/landingPage/plans/KickOffPlans';
// import KickOffPlans from '@/components/landing/container/plans/KickOffPlans';

export default {
  name: 'PlansDialog',
  data: () => ({
    tipoPlan: 'Precios',
    precioPorUsuario: 0,
    precio: 0,
    subtitleSupport: 'S/0 por usuario',
    cantidadUsuarios: 1,
    plansDialog: true,
  }),
  computed: {
    ...mapState(['showKickOffPlans']),
    // ...mapState(['plansDialog', 'showKickOffPlans']),
  },
  components: {
    KickOffPlans,
  },
  mounted() {
    this.$store.state.showKickOffPlans = true;
  },

  methods: {
    closeDialog() {
      // this.$store.state.plansDialog = false;
      this.$router.push({
        path: '/',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/main.scss';
.encabezado {
  color: $stage-celeste-base !important;
  background: $stage-azul-oscuro-base !important;
}
.cerrarPagina {
  color: $stage-celeste-base !important;
}
</style>
