<template>
  <v-card min-height="400px">
    <v-data-table
      id="Prestamo"
      class="elevation-1"
      fixed-header
      height="80vh"
      :headers="headers"
      :items="personal"
      :loading="tableLoading"
      loading-text="Cargando reporte de créditos... Espere..."
      hide-default-footer
      disable-pagination
      no-data-text="No hay personal aún..."
      single-expand
      :expanded.sync="expanded"
      show-expand
      :search="searchPerson"
      item-key="uniqueKey"
      no-results-text="No se encontraron registros..."
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <v-toolbar-title class="top-bar-title">Créditos</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchPerson"
            append-icon="mdi-magnify"
            label="Buscar persona..."
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-container fluid>
            <v-data-table
              id="Prestamo-Detalle"
              :headers="prestamoHeaders"
              :items="item.creditos"
              hide-default-footer
              disable-pagination
              :search="search"
              no-data-text="No hay créditos registrados..."
              @click:row="editarPrestamo"
              :item-class="prestamoClass"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title class="top-bar-title"
                    >Registros de {{ item.nombre }}</v-toolbar-title
                  >
                  <v-spacer></v-spacer>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="agregarPrestamo" v-on="on">
                        <v-icon color="red lighten-1">mdi-bank-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Agregar crédito</span>
                  </v-tooltip>
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar créditos..."
                    single-line
                    hide-details
                  ></v-text-field>
                </v-toolbar>
              </template>
              <template v-slot:[`item.periodoInicioDescuento`]="{ item }">
                {{ item.periodo_inicio_descuento === '' ? '' : dateText(item) }}
              </template>
              <template v-slot:[`item.cuotasPagadas`]="{ item }">
                {{ Number(item.cuotas_pagadas) }}/{{
                  Number(item.total_cuotas)
                }}
              </template>
              <template v-slot:[`item.saldoPendiente`]="{ item }">
                {{
                  Number(item.cuotas_pagadas) === 0
                    ? Number(item.monto)
                    : Number(
                        item.monto -
                          (item.monto / Number(item.total_cuotas)) *
                            Number(item.cuotas_pagadas)
                      )
                }}
              </template>
            </v-data-table>
          </v-container>
        </td>
      </template>
    </v-data-table>

    <EditarPrestamo
      :persona="this.persona"
      :prestamo="this.prestamo"
      :nuevoPrestamo="this.nuevoPrestamo"
    />
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';

import { getPersons } from '@/api/persons/getPersons.js';
import EditarPrestamo from './EditarPrestamo.vue';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';

export default {
  name: 'Prestamo',
  data: () => ({
    nuevoPrestamo: true,
    tableLoading: true,
    expanded: [],
    personal: [],
    persona: {},
    prestamo: {},
    search: '',
    searchPerson: '',
  }),
  components: {
    EditarPrestamo,
  },
  computed: {
    ...mapState(['editarPrestamoDialog']),
    prestamoHeaders() {
      let fixedHeaders = [
        {
          text: 'Tipo de crédito',
          value: 'tipo_credito',
        },
        {
          text: 'Monto',
          value: 'monto',
        },
        {
          text: 'Fecha de solicitud',
          value: 'fecha_solicitud',
        },
        {
          text: 'Periodo inicio de descuento',
          value: 'periodoInicioDescuento',
        },
        {
          text: 'Firma digital',
          value: 'firmado_digitalmente',
        },
        {
          text: 'Cuotas pagadas',
          value: 'cuotasPagadas', // computed in template
        },
        {
          text: 'Saldo pendiente',
          value: 'saldoPendiente', //computed in template
        },
        {
          text: 'Aprobado',
          value: 'aprobado',
        },
        {
          text: 'Pagado',
          value: 'pagado',
        },
        {
          text: 'Observaciones',
          value: 'observaciones',
          width: '300px',
        },
      ];

      //   fixedHeaders.push(...this.diasDelMes);
      return fixedHeaders;
    },
    headers() {
      let fixedHeaders = [
        {
          text: 'Documento',
          value: 'doi',
        },
        {
          text: 'Nombre/Razón social',
          value: 'nombre',
          width: '275px',
        },
        {
          text: 'Cargo',
          value: 'datos_laborales.cargo',
        },
        {
          text: 'Dirección',
          value: 'direccion',
        },
        {
          text: 'Situación laboral',
          value: 'datos_laborales.situacion',
        },
      ];

      //   fixedHeaders.push(...this.diasDelMes);
      return fixedHeaders;
    },
  },
  watch: {
    expanded() {
      this.$store.state.tablaActivaGestionPersonal = 'Prestamo';
      if (this.expanded.length > 0)
        this.$store.state.tablaActivaGestionPersonal = 'Prestamo-Detalle';
    },
    editarPrestamoDialog() {
      if (!this.editarPrestamoDialog) this.getPersonal();
    },
  },
  created() {
    this.getPersonal();
    this.$store.state.tablaActivaGestionPersonal = 'Prestamo';
  },
  methods: {
    dateText(item) {
      return moment(item.periodo_inicio_descuento, 'YYYY-MM')
        .format('MMMM-YYYY')
        .toUpperCase();
    },
    prestamoClass(prestamo) {
      if (prestamo.aprobado === 'No') {
        return 'red lighten-3';
      }
      if (prestamo.pagado === 'Sí') {
        return 'teal accent-3';
      }
    },
    async getPersonal() {
      const { error, personal, tokenExpired } = await getPersons();
      if (error) {
        console.error('error en el created', error);
      }
      if (personal) {
        this.personal = personal;
        this.personal = this.personal.map((persona) => {
          persona.uniqueKey =
            persona.doi +
            '-prestamo-' +
            persona.datos_laborales.fecha_ingreso +
            persona.datos_laborales.situacion;
          persona.nombre = `${persona.datos_laborales.apellido_paterno} ${persona.datos_laborales.apellido_materno}, ${persona.nombre}`;
          return persona;
        });
        this.personal = this.personal.filter(
          (persona) => persona.datos_laborales.situacion === 'Activo'
        );
        this.tableLoading = false;
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
    },
    editarPrestamo(prestamo) {
      this.nuevoPrestamo = false;
      this.persona = this.expanded[0];
      this.prestamo = prestamo;
      this.$store.state.editarPrestamoDialog = true;
    },
    agregarPrestamo() {
      this.nuevoPrestamo = true;
      this.persona = this.expanded[0];
      this.prestamo = {
        tipo_credito: '',
        monto: 0,
        fecha_solicitud: '',
        periodo_inicio_descuento: '',
        firmado_digitalmente: 'No',
        total_cuotas: 1,
        cuotas_pagadas: 0,
        aprobado: 'No',
        pagado: 'No',
        observaciones: '',
        planilla_descuento_cuota: [],
      };
      this.$store.state.editarPrestamoDialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/main.scss';
.column-header {
  font-size: 15px !important;
  font-weight: bold !important;
  color: teal !important;
}
.top-bar-title {
  font-size: 25px !important;
  font-weight: bold !important;
  color: teal !important;
}
.v-data-table::v-deep th {
  font-size: 15px !important;
  font-weight: bold !important;
  color: teal !important;
  .v-icon {
    display: none !important;
  }
}
.align-header {
  text-align: center !important;
  background-color: $white;
}
</style>
