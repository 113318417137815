<template>
  <v-dialog
    v-model="editarVacacionesDialog"
    max-width="60vw"
    max-height="50vh"
    persistent
  >
    <v-card>
      <v-card-title
        class="text-h5 justify-center"
        style="color: teal; font-weight: bold"
      >
        {{
          !nuevoVacaciones
            ? `Editando vacaciones de:`
            : 'Agregar vacaciones para:'
        }}
      </v-card-title>
      <v-card-subtitle style="color: teal;" class="text-h5">
        {{ `${persona.nombre}` }}
      </v-card-subtitle>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col>
              <v-text-field
                label="Periodo"
                v-model="vacacionesAEditar.periodo"
                readonly
              />
              <v-text-field
                label="Fecha de inicio"
                v-model="vacacionesAEditar.fecha_inicio"
                type="date"
                :min="
                  persona._id && persona.datos_laborales.fecha_ingreso
                    ? persona.datos_laborales.fecha_ingreso
                    : ''
                "
                :rules="dateRules"
              />
              <v-text-field
                label="Fecha de fin"
                v-model="vacacionesAEditar.fecha_fin"
                type="date"
                :rules="dateRules"
              />
            </v-col>

            <v-col>
              <v-autocomplete
                label="Aprobado"
                v-model="vacacionesAEditar.aprobado"
                :items="['Sí', 'No']"
              />
              <v-autocomplete
                label="Tipo de vacaciones"
                v-model="vacacionesAEditar.tipo_vacaciones"
                :items="tipoVacaciones"
                :rules="nameRules"
              />

              <!-- <v-text-field label="Gozado" v-model="vacacionesAEditar.gozado" />
              <v-text-field
                label="Comprado"
                v-model="vacacionesAEditar.comprado"
              /> -->
            </v-col>
          </v-row>
          <v-row>
            <v-textarea
              outlined
              label="Observaciones"
              v-model="vacacionesAEditar.observaciones"
            />
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="blue darken-1"
          text
          @click="closeDialog"
          :disabled="deshabilitarBotonGuardar"
          >Cancelar</v-btn
        >
        <v-btn
          color="success"
          text
          @click="validate"
          :disabled="deshabilitarBotonGuardar"
          >Guardar</v-btn
        >
        <v-btn
          color="red"
          text
          @click="eliminarVacaciones"
          v-show="!nuevoVacaciones"
          :disabled="deshabilitarBotonGuardar"
          >Eliminar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';

import { actualizarAsistencia } from '@/api/persons/actualizarAsistencia.js';
import { obtenerAsistenciaPorRangoDeFechas } from '@/api/persons/obtenerAsistenciaPorRangoDeFechas.js';
// import { editarAsistencia } from '@/api/persons/editarAsistencia.js';
import { editUserInfo } from '@/api/persons/editUserInfo.js';
import routeToLoginIfTokenIsExpired from '@/utils/routeToLoginIfTokenIsExpired.js';

export default {
  name: 'EditarVacaciones',
  vacacionesOriginal: {},
  data() {
    return {
      deshabilitarBotonGuardar: false,
      vacacionesAEditar: {
        periodo: '',
        fecha_inicio: '',
        fecha_fin: '',
        aprobado: '',
        tipo_vacaciones: [],
        observaciones: '',
      },
      tipoVacaciones: ['Gozado', 'Comprado'],
      valid: true,
      nameRules: [
        (v) => !!v || 'Campo requerido',
        (v) => (v && v.length >= 3) || 'Mínimo 3 caracteres',
      ],
      dateRules: [(v) => !!v || 'Campo requerido'],
    };
  },
  props: {
    vacaciones: Object,
    persona: Object,
    nuevoVacaciones: Boolean,
  },
  watch: {
    editarVacacionesDialog() {
      this.$nextTick(function() {
        if (this.persona._id) {
          this.$refs.form.resetValidation();
        }
      });
    },
    vacaciones() {
      this.vacacionesAEditar = this.vacaciones;
      this.$options.vacacionesOriginal = { ...this.vacaciones };
    },
  },
  computed: {
    ...mapState(['editarVacacionesDialog']),
  },
  methods: {
    closeDialog() {
      this.$options.vacacionesOriginal = {};
      this.deshabilitarBotonGuardar = false;
      this.$store.state.editarVacacionesDialog = false;
    },
    showSuccessAlert() {
      this.$swal.fire({
        title: `Operación realizada correctamente!`,
        text: '',
        icon: 'success',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    showErrorAlert() {
      this.$swal.fire({
        title: `No se pueden modificar estas vacaciones!`,
        text: '',
        icon: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    showDateErrorAlert() {
      this.$swal.fire({
        title: `Error`,
        text: 'La fecha de fin no puede ser menor a la fecha de inicio!',
        icon: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    showDeleteAlert() {
      this.$swal
        .fire({
          title: `Atención!`,
          text: 'Está seguro que desea eliminar estas vacaciones?',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonColor: '#d33',
          cancelButtonText: 'No',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok',
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.deshabilitarBotonGuardar = true;

            const {
              fecha_inicio,
              fecha_fin,
            } = this.$options.vacacionesOriginal;
            this.persona.vacaciones = this.persona.vacaciones.filter(
              (vacaciones) =>
                JSON.stringify(vacaciones) !==
                JSON.stringify(this.vacacionesAEditar)
            );

            await this.actualizarAsistencias(fecha_inicio, fecha_fin, 'Falto');

            const { error, message, tokenExpired } = await editUserInfo(
              this.persona
            );

            if (error) {
              //do something
            }
            if (tokenExpired) {
              routeToLoginIfTokenIsExpired(this);
            }
            if (message) {
              this.showSuccessAlert();
              this.closeDialog();
            }
          }
        });
    },
    async eliminarVacaciones() {
      this.showDeleteAlert();
    },
    async validate() {
      if (this.$refs.form.validate()) {
        this.deshabilitarBotonGuardar = true;
        const { fecha_inicio, fecha_fin } = this.vacacionesAEditar;
        const {
          fecha_inicio: fecha_inicioOriginal,
          fecha_fin: fecha_finOriginal,
        } = this.$options.vacacionesOriginal;
        if (fecha_fin < fecha_inicio) {
          this.showDateErrorAlert();
          return;
        }

        if (this.nuevoVacaciones) {
          this.persona.vacaciones.push(this.vacacionesAEditar);
        } else {
          this.persona.vacaciones.map((vacaciones) => {
            if (vacaciones.citt === this.vacacionesAEditar.citt) {
              vacaciones = this.vacacionesAEditar;
            }
            return vacaciones;
          });
        }

        const { error: errorActualizando, message } = await editUserInfo(
          this.persona
        );

        if (errorActualizando) {
          //do something
        }
        if (message) {
          if (
            fecha_inicio !== fecha_inicioOriginal ||
            fecha_fin !== fecha_finOriginal
          ) {
            //si la fecha_inicioOriginal === '' entonces solo actualizar con las fechas del descanso a editar
            if (fecha_inicioOriginal === '') {
              await this.actualizarAsistencias(fecha_inicio, fecha_fin, 'V');
              this.showSuccessAlert();
              this.closeDialog();
              return;
            }
            await this.actualizarAsistencias(
              fecha_inicioOriginal,
              fecha_finOriginal,
              'Falto'
            );
            await this.actualizarAsistencias(fecha_inicio, fecha_fin, 'V');
            this.showSuccessAlert();
            this.closeDialog();
          } else {
            await this.actualizarAsistencias(fecha_inicio, fecha_fin, 'V');
            this.showSuccessAlert();
            this.closeDialog();
          }

          //si las fechas cambiaron se deben actualizar las asistencias... reemplazar lo antiguo con Falto y volver a reemplazar por DM, o hay una mejor forma?

          //se actualizó el campo descansos_medicos de la persona
        }
      }
    },
    async actualizarAsistencias(fecha_inicio, fecha_fin, tipo_jornada) {
      const {
        error,
        tokenExpired,
        asistencias,
      } = await obtenerAsistenciaPorRangoDeFechas(
        moment(fecha_inicio).tz('America/Lima'),
        moment(fecha_fin).tz('America/Lima')
      );
      // } = await obtenerAsistenciaPorRangoDeFechas(fecha_inicio, fecha_fin);
      if (error) {
        //do something
      }
      if (tokenExpired) {
        routeToLoginIfTokenIsExpired(this);
      }
      if (asistencias) {
        for (let asistencia in asistencias) {
          // let personaFound = asistencias[asistencia].personal.find(
          //   (person) => person.doi === this.persona.doi
          // );
          // if (!personaFound) {
          //   //modificar como sería la asistencia y luego hacer el push
          //   this.persona.disponible = true;
          //   if (tipo_jornada !== 'Falto') {
          //     this.persona.disponible = false;
          //   }
          //   this.persona.tipo_jornada = tipo_jornada;
          //   asistencias[asistencia].personal.push(this.persona);
          //   const {
          //     error: errorEditandoAsistencia,
          //     message: asistenciaEditada,
          //   } = await editarAsistencia(asistencias[asistencia]);
          //   if (errorEditandoAsistencia) {
          //     //do something
          //   }
          //   if (asistenciaEditada) {
          //     //do something
          //   }
          // } else {
          //   personaFound.disponible = false
          let registroDeAsistenciaActualizado = {
            doi: this.persona.doi,
            fecha_asistencia: asistencias[asistencia].fecha_asistencia,
            tipo_jornada,
            hora_programada: '',
            hora_inicio_jornada: '',
            hora_fin_jornada: '',
            hora_inicio_almuerzo: '',
            hora_fin_almuerzo: '',
          };
          const { error: errorEditandoAsistencia } = await actualizarAsistencia(
            registroDeAsistenciaActualizado
          );
          if (errorEditandoAsistencia) {
            //do something
          }
          // }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/main.scss';

.dialog-container {
  overflow: scroll;
}
</style>
