<template>
  <v-container class="container_pay__methods">
    <v-row v-if="!showKickOffPlans">
      <v-col cols="3" sm="3">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Información de compra</v-list-item-title>
              <v-list-item-subtitle
                >Plan elegido:
                {{ this.planToPay.tipo_plan }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            >Cantidad de usuarios:
            {{ this.planToPay.usuarios_habilitados }}
          </v-list-item>
          <v-list-item
            >Precio mensual por usuario: S/.
            {{ this.planToPay.precio_por_usuario }}</v-list-item
          >
          <v-slider
            v-if="planToPay.tipo_plan !== 'CTIEmprendedor'"
            v-model="plazoPlanMes"
            max="1"
            min="1"
            step="1"
            thumb-label
            hide-details
          ></v-slider>
          <v-list-item
            style="font-size: 20px; font-weight: bold; text-align: center; align-content: center; aling-self: center"
            v-if="planToPay.tipo_plan !== 'CTIEmprendedor'"
          >
            Plazo de suscripción: {{ this.planToPay.plazo }}
            {{ this.planToPay.plazo === 1 ? 'mes' : 'meses' }}
          </v-list-item>
          <v-list-item
            style="font-size: 20px; font-weight: bold; text-align: center; align-content: center; aling-self: center"
            v-if="planToPay.tipo_plan !== 'CTIEmprendedor'"
          >
            Sub-total: S/. {{ this.planToPay.monto }}
          </v-list-item>
          <v-list-item
            style="font-size: 20px; font-weight: bold; text-align: center; align-content: center; aling-self: center"
            v-if="planToPay.tipo_plan !== 'CTIEmprendedor'"
          >
            IGV (18%): S/. {{ Number(this.planToPay.monto * 0.18).toFixed(2) }}
          </v-list-item>
          <v-list-item
            style="font-size: 20px; font-weight: bold; text-align: center; align-content: center; aling-self: center"
            v-if="planToPay.tipo_plan !== 'CTIEmprendedor'"
          >
            Total a pagar: S/.
            {{
              Number(
                this.planToPay.monto + this.planToPay.monto * 0.18
              ).toFixed(2)
            }}
            por
            {{ this.planToPay.plazo }}
            {{ this.planToPay.plazo === 1 ? 'mes' : 'meses' }}
          </v-list-item>
          <v-list-item
            style="font-size: 20px; font-weight: bold; text-align: center; align-content: center; aling-self: center"
            v-if="planToPay.tipo_plan === 'CTIEmprendedor'"
          >
            Total a pagar: Gratis
          </v-list-item>

          <!-- max="36" -->

          <!-- <v-list-item
            >Plazo en días:
            {{ this.planToPay.plazo }}
          </v-list-item> -->
        </v-list>
        <v-btn class="mt-2" ref="btn_back" @click="escogerOtroPlan" color="info"
          >Escoger otro plan</v-btn
        >
      </v-col>
      <v-col
        cols="3"
        sm="3"
        class="pay_methods"
        v-if="this.planToPay.monto > 0"
      >
        <v-row>
          <h3 class="color-fuente">Seleccione un método de pago:</h3>
        </v-row>
        <v-row>
          <v-card class="pay_method" color="#385F73" dark>
            <v-card-actions>
              <v-checkbox v-model="banca" />
            </v-card-actions>
            <v-card-subtitle>Banca móvil <v-img /></v-card-subtitle>
            <v-img
              :src="require('@/assets/menu-icons/pagoefectivo.png')"
              maxWidth="30px"
              contain
              width="100%"
            />
          </v-card>
        </v-row>
        <v-row>
          <v-card class="pay_method" color="#385F73" dark>
            <v-card-actions>
              <v-checkbox v-model="agente" />
            </v-card-actions>
            <v-card-subtitle>Agentes y bodegas </v-card-subtitle>
            <v-img
              :src="require('@/assets/menu-icons/pagoefectivo.png')"
              maxWidth="30px"
              contain
              width="100%"
            />
          </v-card>
        </v-row>
      </v-col>
      <v-col v-if="banca && this.planToPay.monto > 0" cols="3" sm="3">
        <v-row class="m-auto color-fuente">
          <h3 class="m-auto">
            Genera un código de 8 dígitos <br />
            y págalo a través de:
          </h3>
          <div class="align_center">
            <v-icon>mdi-check</v-icon>
            <p>
              Banca móvil y banca por internet vía pago efectivo en los
              siguientes bancos
            </p>
          </div>
          <v-container>
            <v-btn
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.bbva.pe/personas/servicios-digitales/banca-por-internet.html"
              ><v-img
                :src="require('@/assets/menu-icons/bbva.jpg')"
                maxWidth="55px"
                contain
                width="100%"
            /></v-btn>
            <v-btn
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.banbif.com.pe/bancaporinternet"
              ><v-img
                :src="require('@/assets/menu-icons/banbif.jpg')"
                maxWidth="55px"
                contain
                width="100%"
            /></v-btn>
            <v-btn
              target="_blank"
              rel="noopener noreferrer"
              href="https://bcpzonasegurabeta.viabcp.com/#/iniciar-sesion"
              ><v-img
                :src="require('@/assets/menu-icons/bcp.png')"
                maxWidth="55px"
                contain
                width="100%"
            /></v-btn>
            <v-btn
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.cajaarequipa.pe/banca-por-internet/"
              ><v-img
                :src="require('@/assets/menu-icons/cajarequipa.jpg')"
                maxWidth="55px"
                contain
                width="100%"
            /></v-btn>
            <v-btn
              target="_blank"
              rel="noopener noreferrer"
              href="https://interbank.pe/banca-por-internet"
              ><v-img
                :src="require('@/assets/menu-icons/interbank.jpg')"
                maxWidth="55px"
                contain
                width="100%"
            /></v-btn>
            <v-btn
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.pichincha.pe/canales-de-atencion/banca-internet"
              ><v-img
                :src="require('@/assets/menu-icons/pichincha.png')"
                maxWidth="55px"
                contain
                width="100%"
            /></v-btn>
            <v-btn
              class="scotia"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.scotiabank.com.pe/Personas/Canales-digitales/canales/banca-por-internet"
              ><v-img
                :src="require('@/assets/menu-icons/scotia.jpg')"
                maxWidth="55px"
                contain
                width="100%"
            /></v-btn>
          </v-container>
        </v-row>
        <v-btn class="m-auto " @click="obtenerCIPUrl" color="yellow"
          >Generar código</v-btn
        >
      </v-col>
      <v-col v-if="agente && this.planToPay.monto > 0" cols="3" sm="3">
        <v-row class="m-auto color-fuente">
          <h3 class="m-auto">
            Genera un código de 8 dígitos <br />
            y págalo a través de:
          </h3>
          <div class="align_center">
            <v-icon>mdi-check</v-icon>
            <p>
              Puedes pagar también en Agentes y bodegas de los siguientes
              establecimientos:
            </p>
          </div>
          <v-container>
            <v-img
              class="m-auto"
              :src="require('@/assets/menu-icons/agentes.png')"
              maxWidth="300px"
              contain
              width="100%"
            />
          </v-container>
        </v-row>
        <v-btn class="m-auto" @click="obtenerCIPUrl" color="yellow"
          >Generar código</v-btn
        >
      </v-col>
      <div v-if="this.planToPay.monto <= 0">
        <v-btn class="m-auto" @click="empezar" color="success"
          >EMPEZAR!!!!</v-btn
        >
      </div>
    </v-row>
    <KickOffPlans v-else />
  </v-container>
</template>
<script>
import { mapState } from 'vuex';

// import Suscripcion from '@/components/landing/container/plans/KickOffPlans';
import KickOffPlans from '@/components/landingPage/plans/KickOffPlans';
import { getCipToken } from '@/api/payments/getCipToken.js';
import { getCipCode } from '@/api/payments/getCipCode.js';

export default {
  name: 'Payment',
  data: () => ({
    plazoPlanMes: 1,
    banca: false,
    agente: false,
    planToPay: {},
    cliente: {},
  }),
  components: {
    KickOffPlans,
  },
  computed: {
    ...mapState(['planSeleccionado', 'showKickOffPlans']),
  },
  created() {
    if (
      !this.$store.state.planSeleccionado.cantidadUsuarios &&
      !this.$store.state.cliente.ruc
    ) {
      this.showNoPlanAlert();
      return;
    }
  },
  mounted() {
    this.planToPay = this.$store.state.planSeleccionado;
    this.plazoPlanMes = this.planToPay.plazo;
    this.cliente = this.$store.state.cliente;
  },
  watch: {
    planSeleccionado() {
      this.planToPay = this.$store.state.planSeleccionado;
    },
    plazoPlanMes() {
      this.planToPay.monto =
        this.planToPay.precio_por_usuario *
        this.planToPay.usuarios_habilitados *
        this.plazoPlanMes;
      this.planToPay.monto = Number(this.planToPay.monto.toFixed(2));
      this.planToPay.plazo = this.plazoPlanMes;
    },
    banca() {
      if (this.banca == true) {
        this.agente = false;
      }
    },
    agente() {
      if (this.agente == true) {
        //this.$refs.btn_back.classList.toggle('disabled'),
        this.banca = false;
      }
    },
  },
  methods: {
    empezar() {
      this.$router.push({
        path: '/dashboard',
      });
    },
    escogerOtroPlan() {
      this.$store.state.showKickOffPlans = true;
    },
    showNoPlanAlert() {
      this.$swal.fire({
        title: `Error`,
        text: 'No hay un plan seleccionado aún, escoja uno',
        icon: 'error',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    async obtenerCIPUrl() {
      this.banca = false;
      this.agente = false;
      this.planToPay.monto = Number(
        this.planToPay.monto + this.planToPay.monto * 0.18
      ).toFixed(2);
      const { error, token } = await getCipToken();
      if (error) {
        //do something
      }
      if (token) {
        const { error, urlCip } = await getCipCode(
          token,
          this.planToPay,
          this.cliente
        );
        if (error) {
          //do something
        }
        if (urlCip) {
          let newWindow = window.open(urlCip, '_blank');
          newWindow.opener = null;
          //si el cliente es nuevo, entonces enviar un correo de bienvenida o en todo caso alertar al área de ventas
          this.$router.push({
            path: '/dashboard',
          });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'src/styles/main.scss';

.color-fuente {
  color: $stage-celeste-base !important;
}
.next_btn {
  margin: 20px auto 0;
}

.align_center {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cip_code {
  font-size: 35px;
  width: fit-content;
  margin: auto;
}
.container_code_cip {
  background: #ffcc00 !important;
}
.v-list {
  background: $white;
  border: 0.5px rgba(40, 65, 40, 0.418) solid;
  border-radius: 5px;
  .v-list-item {
    font-size: 18px;
  }
  .v-list-item__title {
    font-size: 22px;
  }
}
.v-btn {
  height: auto;
  min-width: fit-content;
  padding: 0px;
  box-shadow: none;
}
.m-auto {
  margin: auto;
}
.container_pay__methods {
  justify-content: center !important;
  margin: 120px auto !important;
  .pay_methods {
    .pay_method {
      width: 250px;
      margin: 3px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: rgb(74, 112, 71) !important;
      .v-card__actions {
        margin: 5px 10px !important;
        padding: 0px;
        width: fit-content;
      }
      .v-card__subtitle {
        font-size: 18px;
        padding: 0px;
        width: fit-content;
        text-align: center;
      }
      .v-image {
        margin-right: 10px;
      }
    }
  }
}
@media screen and(max-width: 800px) {
  .row {
    flex-direction: column !important;
    align-items: center !important;
  }
  .col-3 {
    max-width: 100%;
  }
}
@media screen and(min-width: 1441px) {
  .row {
    flex-direction: column !important;
    align-items: center !important;
  }
}
</style>
