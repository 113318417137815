import fetch from 'isomorphic-fetch';

let ENDPOINT_URL = process.env.VUE_APP_AGREGAR_SERVICIOS;
async function addServices(date, ruta, item, orden) {
  const bodyParams = {
    fecha_programacion: date,
    codigo_ruta: ruta,
    token: localStorage.getItem('token'),
    _id: item._id,
    nombre: item.nombre,
    nombre_referencial: item.nombre_referencial,
    direccion: item.direccion,
    latlng: item.latlng,
    orden: orden,
  };

  let body = JSON.stringify(bodyParams);

  return await fetch(ENDPOINT_URL, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  }).then((item) => item.json());
}
export { addServices };
