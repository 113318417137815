<template>
  <v-card min-height="400px">
    <v-toolbar flat color="teal" dark>
      <v-spacer></v-spacer>
      <v-toolbar-title>Configuración para cálculos de planilla</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-tabs vertical>
      <v-tab class="tab-class">
        <v-icon left>
          mdi-consolidate
        </v-icon>
        Conceptos
      </v-tab>
      <v-tab class="tab-class">
        <v-icon left>
          mdi-car-cruise-control
        </v-icon>
        Parámetros
      </v-tab>
      <v-tab class="tab-class">
        <v-icon left>
          mdi-podium-gold
        </v-icon>
        Pensiones
      </v-tab>
      <v-tab class="tab-class">
        <v-icon left>
          mdi-calendar-star
        </v-icon>
        Feriados
      </v-tab>
      <v-tab-item>
        <v-card flat height="80vh">
          <v-card-text>
            <Conceptos />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat height="80vh">
          <v-card-text>
            <Parametros />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat height="80vh">
          <v-card-text>
            <SPP />
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat height="80vh">
          <v-card-text>
            <Feriados />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>
<script>
import Conceptos from './Conceptos.vue';
import Feriados from './Feriados.vue';
import Parametros from './Parametros.vue';
import SPP from './SPP.vue';

export default {
  name: 'Configuracion',
  data: () => ({
    conceptos: true,
    parametros: false,
  }),
  components: {
    Conceptos,
    Feriados,
    Parametros,
    SPP,
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/main.scss';

.tab-class {
  align-self: baseline !important;
}
</style>
