var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"min-height":"400px"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"id":"Prestamo","fixed-header":"","height":"80vh","headers":_vm.headers,"items":_vm.personal,"loading":_vm.tableLoading,"loading-text":"Cargando reporte de créditos... Espere...","hide-default-footer":"","disable-pagination":"","no-data-text":"No hay personal aún...","single-expand":"","expanded":_vm.expanded,"show-expand":"","search":_vm.searchPerson,"item-key":"uniqueKey","no-results-text":"No se encontraron registros..."},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-toolbar-title',{staticClass:"top-bar-title"},[_vm._v("Créditos")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar persona...","single-line":"","hide-details":""},model:{value:(_vm.searchPerson),callback:function ($$v) {_vm.searchPerson=$$v},expression:"searchPerson"}})],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"id":"Prestamo-Detalle","headers":_vm.prestamoHeaders,"items":item.creditos,"hide-default-footer":"","disable-pagination":"","search":_vm.search,"no-data-text":"No hay créditos registrados...","item-class":_vm.prestamoClass},on:{"click:row":_vm.editarPrestamo},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"top-bar-title"},[_vm._v("Registros de "+_vm._s(item.nombre))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({on:{"click":_vm.agregarPrestamo}},on),[_c('v-icon',{attrs:{"color":"red lighten-1"}},[_vm._v("mdi-bank-plus")])],1)]}}],null,true)},[_c('span',[_vm._v("Agregar crédito")])]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar créditos...","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.periodoInicioDescuento",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.periodo_inicio_descuento === '' ? '' : _vm.dateText(item))+" ")]}},{key:"item.cuotasPagadas",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.cuotas_pagadas))+"/"+_vm._s(Number(item.total_cuotas))+" ")]}},{key:"item.saldoPendiente",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Number(item.cuotas_pagadas) === 0 ? Number(item.monto) : Number( item.monto - (item.monto / Number(item.total_cuotas)) * Number(item.cuotas_pagadas) ))+" ")]}}],null,true)})],1)],1)]}}])}),_c('EditarPrestamo',{attrs:{"persona":this.persona,"prestamo":this.prestamo,"nuevoPrestamo":this.nuevoPrestamo}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }