import XLSX from 'xlsx';

function excelToJSON(file) {
  return new Promise((resolve, reject) => {
    let fileReader = new FileReader();
    fileReader.onload = (event) => {
      const data = event.target.result;
      // console.log(data);
      const workbook = XLSX.read(data, { type: 'binary' });

      const arrayFilasExcel = XLSX.utils.sheet_to_row_object_array(
        workbook.Sheets['Carga_masiva']
      );

      resolve(arrayFilasExcel);
    };
    fileReader.onerror = reject;
    fileReader.readAsBinaryString(file);
  });
}
export { excelToJSON };
