import fetch from 'isomorphic-fetch';

let ENDPOINT_URL = process.env.VUE_APP_CREAR_PERSONAL;
async function createUser(item) {
  const bodyParams = {
    doi: item.doi.toString(),
    username: item.username.toLowerCase(),
    password: item.password1,
    nombre: item.datos_laborales.nombres,
    email: item.email,
    direccion: item.direccion,
    roles: item.roles,
    datos_laborales: item.datos_laborales,
    creditos: item.creditos,
    descansos_medicos: item.descansos_medicos,
    permisos: item.permisos,
    sanciones: item.sanciones,
    vacaciones: item.vacaciones,
    token: localStorage.getItem('token'),
  };

  let body = JSON.stringify(bodyParams);

  return await fetch(ENDPOINT_URL, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  }).then((item) => item.json());
}
export { createUser };
