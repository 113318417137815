var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"60vw","max-height":"50vh","persistent":""},model:{value:(_vm.editarPrestamoDialog),callback:function ($$v) {_vm.editarPrestamoDialog=$$v},expression:"editarPrestamoDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 justify-center",staticStyle:{"color":"teal","font-weight":"bold"}},[_vm._v(" "+_vm._s(!_vm.nuevoPrestamo ? "Editando crédito de:" : 'Agregar crédito para:')+" ")]),_c('v-card-subtitle',{staticClass:"text-h5",staticStyle:{"color":"teal"}},[_vm._v(" "+_vm._s(("" + (_vm.persona.nombre)))+" ")]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-text-field',{attrs:{"label":"Fecha de solicitud","type":"date","readonly":"","disabled":!_vm.nuevoPrestamo},model:{value:(_vm.prestamoAEditar.fecha_solicitud),callback:function ($$v) {_vm.$set(_vm.prestamoAEditar, "fecha_solicitud", $$v)},expression:"prestamoAEditar.fecha_solicitud"}}),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":true,"return-value":_vm.dateForPicker,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto","disabled":!_vm.nuevoPrestamo && _vm.prestamoAEditar.aprobado !== 'No'},on:{"update:returnValue":function($event){_vm.dateForPicker=$event},"update:return-value":function($event){_vm.dateForPicker=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.dateText,"label":"Periodo de inicio de descuento","readonly":""},model:{value:(_vm.dateText),callback:function ($$v) {_vm.dateText=$$v},expression:"dateText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"type":"month","no-title":"","scrollable":"","color":"teal","locale":"es-ES"},on:{"input":function($event){return _vm.$refs.menu.save(_vm.dateForPicker)}},model:{value:(_vm.dateForPicker),callback:function ($$v) {_vm.dateForPicker=$$v},expression:"dateForPicker"}})],1),_c('v-text-field',{attrs:{"label":"Total cuotas","type":"number","min":"1","rules":_vm.fieldRules,"disabled":!_vm.nuevoPrestamo && _vm.prestamoAEditar.aprobado !== 'No'},model:{value:(_vm.prestamoAEditar.total_cuotas),callback:function ($$v) {_vm.$set(_vm.prestamoAEditar, "total_cuotas", $$v)},expression:"prestamoAEditar.total_cuotas"}}),_c('v-text-field',{attrs:{"label":"Monto","type":"number","min":"0","rules":_vm.fieldRules,"disabled":!_vm.nuevoPrestamo && _vm.prestamoAEditar.aprobado !== 'No'},model:{value:(_vm.prestamoAEditar.monto),callback:function ($$v) {_vm.$set(_vm.prestamoAEditar, "monto", $$v)},expression:"prestamoAEditar.monto"}}),_c('v-text-field',{attrs:{"label":"Monto a pagar por cuota","value":Number(
                  (
                    _vm.prestamoAEditar.monto / _vm.prestamoAEditar.total_cuotas
                  ).toFixed(2)
                ),"type":"number","min":"0","readonly":"","disabled":!_vm.nuevoPrestamo && _vm.prestamoAEditar.aprobado !== 'No'}}),(_vm.prestamoAEditar.cuotas_pagadas > 0)?_c('v-text-field',{attrs:{"label":"Cuotas pagadas","value":Number(_vm.prestamoAEditar.cuotas_pagadas),"type":"number","min":"0","readonly":"","disabled":!_vm.nuevoPrestamo && _vm.prestamoAEditar.aprobado !== 'No'}}):_vm._e()],1),_c('v-col',[_c('v-autocomplete',{attrs:{"label":"Tipo de crédito","items":_vm.tiposDePrestamo,"item-text":"nombre","rules":_vm.fieldRules,"disabled":!_vm.nuevoPrestamo && _vm.prestamoAEditar.aprobado !== 'No'},model:{value:(_vm.prestamoAEditar.tipo_credito),callback:function ($$v) {_vm.$set(_vm.prestamoAEditar, "tipo_credito", $$v)},expression:"prestamoAEditar.tipo_credito"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-autocomplete',_vm._g({attrs:{"label":"Planilla(s) para descuento de cuota","multiple":"","items":_vm.planillasDescuentoCuota,"rules":_vm.arrayFieldRules,"disabled":!_vm.nuevoPrestamo && _vm.prestamoAEditar.aprobado !== 'No'},model:{value:(_vm.prestamoAEditar.planilla_descuento_cuota),callback:function ($$v) {_vm.$set(_vm.prestamoAEditar, "planilla_descuento_cuota", $$v)},expression:"prestamoAEditar.planilla_descuento_cuota"}},on))]}}])},[_c('span',[_vm._v(_vm._s("Procesos que serán los encargados de ejecutar el cobro de cada cuota"))])]),_c('v-autocomplete',{attrs:{"label":"Firmado digitalmente","items":_vm.items,"rules":_vm.fieldRules,"disabled":!_vm.nuevoPrestamo && _vm.prestamoAEditar.aprobado !== 'No'},model:{value:(_vm.prestamoAEditar.firmado_digitalmente),callback:function ($$v) {_vm.$set(_vm.prestamoAEditar, "firmado_digitalmente", $$v)},expression:"prestamoAEditar.firmado_digitalmente"}}),_c('v-autocomplete',{attrs:{"label":"Pagado","items":_vm.items,"rules":_vm.fieldRules,"readonly":"","disabled":!_vm.nuevoPrestamo && _vm.prestamoAEditar.aprobado !== 'No'},model:{value:(_vm.prestamoAEditar.pagado),callback:function ($$v) {_vm.$set(_vm.prestamoAEditar, "pagado", $$v)},expression:"prestamoAEditar.pagado"}}),_c('v-autocomplete',{attrs:{"label":"Aprobado","items":_vm.items,"rules":_vm.fieldRules,"disabled":!_vm.nuevoPrestamo && _vm.prestamoAEditar.aprobado !== 'No'},model:{value:(_vm.prestamoAEditar.aprobado),callback:function ($$v) {_vm.$set(_vm.prestamoAEditar, "aprobado", $$v)},expression:"prestamoAEditar.aprobado"}}),(_vm.prestamoAEditar.cuotas_pagadas > 0)?_c('v-text-field',{attrs:{"label":"Saldo pendiente","value":Number(
                  _vm.prestamoAEditar.monto -
                    (_vm.prestamoAEditar.monto / _vm.prestamoAEditar.total_cuotas) *
                      _vm.prestamoAEditar.cuotas_pagadas
                ),"type":"number","min":"0","readonly":"","disabled":!_vm.nuevoPrestamo && _vm.prestamoAEditar.aprobado !== 'No'}}):_vm._e()],1)],1),_c('v-row',[_c('v-textarea',{attrs:{"outlined":"","label":"Observaciones"},model:{value:(_vm.prestamoAEditar.observaciones),callback:function ($$v) {_vm.$set(_vm.prestamoAEditar, "observaciones", $$v)},expression:"prestamoAEditar.observaciones"}})],1)],1)],1),_c('v-card-actions',{staticClass:"justify-center"},[_c('v-btn',{attrs:{"color":"blue darken-1","text":"","disabled":_vm.deshabilitarBotonGuardar},on:{"click":_vm.closeDialog}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"success","text":"","disabled":_vm.deshabilitarBotonGuardar},on:{"click":_vm.validate}},[_vm._v("Guardar")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.nuevoPrestamo),expression:"!nuevoPrestamo"}],attrs:{"color":"red","text":"","disabled":_vm.nuevoPrestamo || _vm.prestamoAEditar.cuotas_pagadas > 0},on:{"click":_vm.showDeleteAlert}},[_vm._v("Eliminar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }