<template>
    <div class="layout-table">
        <Aside/>
    </div>
</template>
<script>

import Aside from './Aside'
export default {
    name:"Config",
    components:{
        Aside
    }
}
</script>
<style lang="scss" scoped>
@import "src/styles/main.scss";
.layout-table{
    background: $general-bg;
    width: 100%;
    height: 100vh;
    margin:auto;
    display:flex;justify-content: center;
    flex-direction: row-reverse;
}
@media (max-width: 735px){
    .layout-table{
        width: 100vw;
        overflow-x: scroll;
    }
    
}
</style>
