import fetch from 'isomorphic-fetch';

let ENDPOINT_URL = process.env.VUE_APP_OBTENER_ASISTENCIA_POR_FECHAS;
async function obtenerAsistenciaPorRangoDeFechas(fecha_inicio, fecha_fin) {
  const bodyParams = {
    fecha_inicio,
    fecha_fin,
    token: localStorage.getItem('token'),
  };

  let body = JSON.stringify(bodyParams);

  return await fetch(ENDPOINT_URL, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  }).then((item) => item.json());
}
export { obtenerAsistenciaPorRangoDeFechas };
