<template>
  <!-- <div>
    <layout> -->
  <v-card flat class="container">
    <!-- <Header />
    <v-card-text style="background: cadetblue"> -->
    <register-container></register-container>
    <!-- </v-card-text> -->
  </v-card>
  <!-- </layout>
  </div> -->
</template>
<script>
// import Layout from '@/components/landing/Layout.vue';
// import Header from '@/components/landingPage/Header.vue';
import RegisterContainer from './RegisterContainer.vue';
export default {
  name: 'Register',
  components: {
    // Layout,
    // Header,
    RegisterContainer,
  },
  mounted() {
    this.$store.state.showKickOffPlans = false;
  },
};
</script>
<style lang="scss" scoped>
@import 'src/styles/main.scss';

.container {
  background-color: $stage-azul-oscuro-base !important;
}
</style>
