<template>
  <v-container fluid>
    <v-row class="d-flex flex-column mb-4" no-gutters>
      <v-col class="top-bar-buttons-container">
        <v-row no-gutters row>
          <v-col>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="seleccionarPersonalActivo()"
                  v-on="on"
                  v-bind:class="{
                    'boton-seleccionado':
                      botonPersonalSeleccionado === 'activo',
                  }"
                >
                  <v-icon color="teal">mdi-account-group-outline</v-icon>
                </v-btn>
              </template>
              <span>Personal activo</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="seleccionarPersonalHistorico()"
                  v-on="on"
                  v-bind:class="{
                    'boton-seleccionado':
                      botonPersonalSeleccionado === 'historico',
                  }"
                >
                  <v-icon color="red">mdi-account-group-outline</v-icon>
                </v-btn>
              </template>
              <span>Personal histórico</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="seleccionarNuevoPersonal()"
                  v-on="on"
                  v-bind:class="{
                    'boton-seleccionado':
                      botonPersonalSeleccionado === 'nuevoPersonal',
                  }"
                >
                  <v-icon color="blue">mdi-account-plus-outline</v-icon>
                </v-btn>
              </template>
              <span>Agregar nuevo personal</span>
            </v-tooltip>
            <!-- </v-btn-toggle> -->

            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="exportExcel('table', 'Registro')"
                  elevation="2"
                  v-on="on"
                  v-show="!verNuevoPersonal"
                >
                  <v-icon color="green darken-4">mdi-file-excel</v-icon>
                </v-btn>
              </template>
              <span>Exportar a Excel</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <AllPersonInRegister
          v-if="verPersonalActivo === true || verPersonalHistorico === true"
        />
        <NewPerson v-else />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapState } from 'vuex';

import { excelExport } from '@/plugins/excelExport.js';
import NewPerson from '@/components/modules/personnel/personal/NewPerson';
import AllPersonInRegister from '@/components/modules/personnel/personal/AllPersonInRegister';

export default {
  data: () => ({
    dialog: false,
  }),
  computed: {
    ...mapState([
      'verNuevoPersonal',
      'verPersonalActivo',
      'verPersonalHistorico',
      'botonPersonalSeleccionado',
    ]),
  },
  methods: {
    exportExcel(table, services) {
      excelExport(table, services);
    },
    seleccionarNuevoPersonal() {
      this.$store.state.botonPersonalSeleccionado = 'nuevoPersonal';
      this.$store.state.verNuevoPersonal = true;
      this.$store.state.verPersonalActivo = false;
      this.$store.state.verPersonalHistorico = false;
      this.$store.state.editPerson = false;
    },
    seleccionarPersonalActivo() {
      this.$store.state.botonPersonalSeleccionado = 'activo';
      this.$store.state.verPersonalActivo = true;
      this.$store.state.verNuevoPersonal = false;
      this.$store.state.verPersonalHistorico = false;
      this.$store.state.editPerson = false;
    },
    seleccionarPersonalHistorico() {
      this.$store.state.botonPersonalSeleccionado = 'historico';
      this.$store.state.verPersonalHistorico = true;
      this.$store.state.verNuevoPersonal = false;
      this.$store.state.verPersonalActivo = false;
      this.$store.state.editPerson = false;
    },
  },
  components: {
    NewPerson,
    AllPersonInRegister,
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/main.scss';
.search-right {
  text-align: end !important;
  max-width: 450px;
}
.top-bar-buttons-container {
  text-align: left !important;
}
.flex {
  display: flex;
  justify-content: space-between;
}
.nav-bar {
  height: 50px;
}
.boton-seleccionado {
  box-shadow: none !important;
  transform: scale(0.95);
  transition: all 0.3s ease;
}
</style>
