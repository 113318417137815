<template>
  <v-container>
    <v-row>
      <!-- Plan CTI Emprendedor -->
      <!-- <v-card elevation="16" v-if="nuevoCliente"> -->
      <v-card elevation="16" v-if="nuevoCliente || nuevoRegistro">
        <!-- <v-card elevation="16"> -->
        <v-card-title class="titulo-plan" style="font-size: 40px"
          >CTI Emprendedor</v-card-title
        >
        <v-card-subtitle>*CTI Planilla para 3 usuarios </v-card-subtitle>
        <v-card-text>
          <v-text-field
            class="centered-input"
            outline
            type="number"
            v-model="cantidadUsuariosPlanEmprendedor"
            label="Usuarios:"
            readonly
          />

          <!-- max="125" -->
          <v-slider
            v-model="cantidadUsuariosPlanEmprendedor"
            max="3"
            min="1"
            readonly
          ></v-slider>

          <span style="font-size: 20px">
            GRATIS
          </span>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="teal darken-3"
            text
            @click="storeNewSelectedPlan('CTIEmprendedor')"
          >
            {{ nuevoCliente ? 'Empezar' : 'Continuar' }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <!-- Plan CTI Planilla -->
      <v-card elevation="16">
        <v-card-title class="titulo-plan" style="font-size: 40px"
          >CTI Planilla</v-card-title
        >

        <v-card-subtitle>
          S/. {{ Number(precioPlanPlanilla.toFixed(2)) }} x mes sin IGV
        </v-card-subtitle>
        <v-card-text>
          <v-text-field
            class="centered-input"
            outline
            type="number"
            v-model="cantidadUsuariosPlanPlanilla"
            label="Usuarios:"
          />

          <!-- max="125" -->
          <v-slider
            thumb-label
            v-model="cantidadUsuariosPlanPlanilla"
            max="501"
            :min="cantidadMinimaUsuariosPlanPlanilla"
            step="1"
          ></v-slider>
          <span style="font-size: 20px">S/. 5.90 mensual por usuario</span>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="teal darken-3"
            text
            @click="storeNewSelectedPlan('CTIPlanilla')"
          >
            {{ nuevoCliente ? 'Empezar' : 'Continuar' }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <!-- Plan CTI Roads -->
      <v-card elevation="16">
        <v-card-title class="titulo-plan" style="font-size: 40px"
          >CTI Roads</v-card-title
        >
        <v-card-subtitle>
          S/. {{ Number(precioPlanCTIRoads.toFixed(2)) }} x mes sin IGV
        </v-card-subtitle>
        <v-card-text>
          <v-text-field
            class="centered-input"
            outline
            type="number"
            v-model="cantidadUsuariosPlanCTIRoads"
            label="Usuarios:"
          />

          <!-- max="125" -->
          <v-slider
            thumb-label
            v-model="cantidadUsuariosPlanCTIRoads"
            max="501"
            :min="cantidadMinimaUsuariosPlanCTIRoads"
            step="1"
          ></v-slider>
          <span style="font-size: 20px">S/. 9.90 mensual por usuario</span>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="teal darken-3"
            text
            @click="storeNewSelectedPlan('CTIRoads')"
          >
            {{ nuevoCliente ? 'Empezar' : 'Continuar' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'KickOffPlans',
  data: () => ({
    tipoPlan: 'Precios',
    precioPorUsuario: 0,
    precio: 0,
    precioPlanPlanilla: 0,
    precioPlanCTIRoads: 0,
    subtitleSupport: 'S/0 por usuario',
    cantidadUsuariosPlanEmprendedor: 3,
    cantidadUsuariosPlanPlanilla: 4,
    cantidadUsuariosPlanCTIRoads: 4,
  }),
  props: {
    nuevoCliente: Boolean,
  },
  watch: {
    cantidadUsuariosPlanPlanilla() {
      this.changeRangePlanPlanilla();
    },
    cantidadUsuariosPlanCTIRoads() {
      this.changeRangePlanCTIRoads();
    },
  },
  computed: {
    ...mapState([
      'planVigenteActual',
      'personalCompleto',
      'personalActivo',
      'nuevoRegistro',
    ]),
    cantidadMinimaUsuariosPlanPlanilla() {
      let cantidadMinimaDeUsuarios = 4;
      if (
        this.personalActivo &&
        this.personalActivo.length >= cantidadMinimaDeUsuarios
      ) {
        cantidadMinimaDeUsuarios = this.personalActivo.length;
      }

      return cantidadMinimaDeUsuarios;
    },
    cantidadMinimaUsuariosPlanCTIRoads() {
      let cantidadMinimaDeUsuarios = 4;

      if (
        this.personalActivo &&
        this.personalActivo.length >= cantidadMinimaDeUsuarios
      ) {
        cantidadMinimaDeUsuarios = this.personalActivo.length;
      }
      return cantidadMinimaDeUsuarios;
    },
    // precioPlanPlanilla() {
    //   return this.cantidadUsuariosPlanPlanilla * 5.9;
    // },
    // precioPlanCTIRoads() {
    //   return this.cantidadUsuariosPlanCTIRoads * 9.9;
    // },
  },
  mounted() {
    this.$nextTick(() => {
      this.precioPorUsuarioPlanPlanilla = 5.9;
      this.precioPorUsuarioPlanCTIRoads = 9.9;
      this.precioPlanPlanilla = this.cantidadUsuariosPlanPlanilla * 5.9;
      this.precioPlanCTIRoads = this.cantidadUsuariosPlanCTIRoads * 9.9;
    });
  },
  methods: {
    storeNewSelectedPlan(tipo_plan) {
      // if (!this.precioPlanPlanilla || !this.precioPlanCTIRoads) {
      //   console.log('alerta');
      //   return;
      // }
      // console.log(tipo_plan, this.precioPlanPlanilla, this.precioPlanCTIRoads);
      let plan = {
        tipo_plan,
        monto:
          tipo_plan === 'CTIEmprendedor'
            ? 0
            : tipo_plan === 'CTIPlanilla'
            ? Number(this.precioPlanPlanilla.toFixed(2))
            : Number(this.precioPlanCTIRoads.toFixed(2)),
        plazo: 1, //plazo en meses
        usuarios_habilitados:
          tipo_plan === 'CTIEmprendedor'
            ? 3
            : tipo_plan === 'CTIPlanilla'
            ? this.cantidadUsuariosPlanPlanilla
            : this.cantidadUsuariosPlanCTIRoads,
        precio_por_usuario:
          tipo_plan === 'CTIEmprendedor'
            ? 0
            : tipo_plan === 'CTIPlanilla'
            ? Number(this.precioPorUsuarioPlanPlanilla.toFixed(2))
            : Number(this.precioPorUsuarioPlanCTIRoads.toFixed(2)),
      };
      this.$store.state.planSeleccionado = plan;
      this.nuevoCliente ? this.irAlRegistro() : this.irAlMetodoDePago();
    },
    irAlRegistro() {
      this.$store.state.showKickOffPlans = false;
      this.$router.push('/new-user');
    },
    irAlMetodoDePago() {
      this.$store.state.showKickOffPlans = false;
      if (this.$route.name !== 'Payment') {
        this.$router.push('/payment');
      }
    },
    showPlanAlert() {
      this.$swal.fire({
        title: `Atención`,
        text: 'Debe !',
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    showUsersAlert() {
      this.$swal.fire({
        title: `Para gestionar más de 500 usuarios debe ponerse en contacto con ventas@ctiprocesos.com`,
        text: 'Gracias!',
        icon: 'warning',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    },
    changeRangePlanPlanilla() {
      if (this.cantidadUsuariosPlanPlanilla > 500) this.showUsersAlert();
      this.precioPlanPlanilla = this.cantidadUsuariosPlanPlanilla * 5.9;
      this.precioPorUsuarioPlanPlanilla = 5.9;
    },
    changeRangePlanCTIRoads() {
      if (this.cantidadUsuariosPlanCTIRoads > 500) this.showUsersAlert();
      this.precioPlanCTIRoads = this.cantidadUsuariosPlanCTIRoads * 9.9;
      this.precioPorUsuarioPlanCTIRoads = 9.9;
      // this.subtitleSupport = 'S/3.90 por usuario + IGV';
      // }
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'src/styles/main.scss';
.titulo-plan {
  color: $stage-azul-electrico-base !important;
}
.v-text-field__slot {
  input {
    text-align: center !important;
  }
}
.centered-input {
  text-align: center !important;
}
.v-card {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-self: center;
  width: 390px !important;
  height: 450px;
  margin: 100px auto !important;
  padding: 20px;
  box-shadow: -2px 7px 50px 10px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
}
.v-text-field {
  margin: 20px auto;
  width: 100px;
  font-size: 25px;
  text-align: center !important;
  align-content: center;
}
.v-card__actions {
  margin: auto;
}
div.v-card__title {
  font-size: 28px;
  color: $main;
  margin: 30px auto !important;
}
div.v-card__subtitle {
  font-size: 24px;
}
</style>
