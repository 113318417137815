import fetch from 'isomorphic-fetch';

const ENDPOINT_URL = process.env.VUE_APP_OBTENER_TOKEN_CIP;
async function getCipToken() {
  return await fetch(ENDPOINT_URL, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((item) => item.json());
}
export { getCipToken };
