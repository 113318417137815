import fetch from 'isomorphic-fetch';

let ENDPOINT_URL = process.env.VUE_APP_AGREGAR_UNIDAD;
async function addUnit(date, ruta, item) {
  const bodyParams = {
    fecha_programacion: date,
    codigo_ruta: ruta,
    token: localStorage.getItem('token'),
    _id: item._id,
    placa: item.placa,
    tipo_unidad: item.tipo_unidad,
  };

  let body = JSON.stringify(bodyParams);

  return await fetch(ENDPOINT_URL, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body,
  }).then((item) => item.json());
}
export { addUnit };
