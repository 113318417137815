import { jsPDF } from 'jspdf';

function toPdf(template, filename) {
  var doc = new jsPDF();
  doc.setFontSize(12);
  doc.text([template], 10, 25, { maxWidth: 160, align: 'left' });
  doc.save(filename);
}

function htmlToPdf(template, filename) {
  var doc = new jsPDF();
  console.log(template);

  doc.html(template, {
    callback: function(doc) {
      doc.save(filename);
    },
  });
}
export { toPdf, htmlToPdf };
