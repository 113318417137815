<template>
  <v-card>
    <v-card-title
      class="text-h5"
      style="color: teal; font-weight: bold; justify-content: center !important"
    >
      {{ cliente.datos_empresa.razon_social }}
    </v-card-title>

    <v-card-text class="container-datos">
      <v-row>
        <v-col style="font-weight: bold"> RUC: {{ cliente.ruc }}</v-col>
        <v-spacer></v-spacer>
        <v-col style="font-weight: bold">
          BOLETA DE PAGO
        </v-col>
        <v-col></v-col>
        <v-spacer></v-spacer>
      </v-row>

      <!-- DATOS PERSONALES -->
      <v-data-table
        dense
        disable-pagination
        :headers="headersDatosPersonales"
        :items="datosPersonales"
        hide-default-footer
        class="elevation-1"
        style="border: 1px solid #000; max-width: 750px !important"
      ></v-data-table>

      <!-- DATOS LABORALES -->
      <v-data-table
        dense
        disable-pagination
        :headers="headersDatosLaborales"
        :items="datosLaborales"
        hide-default-footer
        class="elevation-1"
        style="border: 1px solid #000; max-width: 750px !important; margin-top: 4px"
      ></v-data-table>

      <!-- DATOS JORNADAS -->
      <v-data-table
        dense
        disable-pagination
        :headers="headersDatosJornadas"
        :items="datosJornadas"
        hide-default-footer
        class="elevation-1"
        style="border: 1px solid #000; max-width: 750px !important; margin-top: 4px"
      ></v-data-table>

      <!-- DETALLE DE LA REMUNERACION -->
      <v-data-table
        dense
        disable-pagination
        :headers="headersDetalleRemuneracion"
        :items="detalleRemuneracion"
        hide-default-footer
        class="elevation-1"
        style="border: 1px solid #000; max-width: 750px !important; margin-top: 4px"
      >
      </v-data-table>

      <!-- NETO A PAGAR -->
      <v-row
        style="color: teal; 
        font-weight: bold; 
        background-color: Gainsboro; 
        border: 1px solid #000; 
        margin-top: 15px; 
        margin-left: 15px; 
        max-width: 720px !important;
        align-content: center !important"
      >
        <v-spacer></v-spacer>
        <p style="color: teal; font-weight: bold">
          Son: {{ netoEnLetras.toLowerCase() }}
        </p>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <p style="color: teal; font-weight: bold">
          NETO A PAGAR:
        </p>
        <v-spacer></v-spacer>
        <p style="color: black; font-weight: bold">
          {{ `S/. ${netoAPagar}` }}
        </p>
        <v-spacer></v-spacer>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapState } from 'vuex';
import numeroALetras from '@/utils/convertirNumerosALetras.js';

export default {
  name: 'FormatoBoletaMensual',
  data: () => ({
    headersDatosPersonales: [
      {
        text: 'Tipo documento',
        value: 'tipoDocumento',
        width: '170px',
      },
      {
        text: 'Nro. documento',
        value: 'nroDocumento',
        width: '150px',
      },
      {
        text: 'Sueldo básico',
        value: 'remuneracionMensual',
        width: '110px',
      },
      { text: 'Apellidos y nombres', value: 'nombre', width: '325px' },
      { text: 'Situación', value: 'situacion', width: '140px' },
    ],
    headersDatosLaborales: [
      { text: 'Fecha de ingreso', value: 'fechaIngreso' },
      { text: 'Tipo trabajador', value: 'tipoTrabajador' },
      // { text: 'Tipo trabajador', value: 'tipoTrabajador', width: '135px' },
      { text: 'Régimen pensionario', value: 'regimen', width: '230px' },
      { text: 'Periodo', value: 'periodo', width: '110px' },
      { text: 'CUSPP', value: 'cuspp', width: '135px' },
    ],
    headersDatosJornadas: [
      {
        text: 'Días laborados',
        value: 'diasLaborados',
        width: '58px',
        align: 'center',
      },
      {
        text: 'Días no laborados',
        value: 'diasNoLaborados',
        width: '58px',
        align: 'center',
      },
      {
        text: 'Días subsidiados',
        value: 'diasSubsidiados',
        width: '58px',
        align: 'center',
      },
      {
        text: 'Horas ordinarias',
        value: 'horasOrdinarias',
        width: '58px',
        align: 'center',
      },
      {
        text: 'Horas sobretiempo',
        value: 'horasSobretiempo',
        width: '58px',
        align: 'center',
      },
      { text: 'Cargo', value: 'cargo', width: '140px' },
    ],
    headersDetalleRemuneracion: [
      { text: 'Código PDT', value: 'codigoPdt', width: '95px !important' },
      {
        text: 'Descripción código',
        value: 'descripcionPdt',
        width: '205px !important',
      },
      // { text: 'Tasa %', value: 'tasa', width: '100px' },
      {
        text: 'Ingresos',
        value: 'ingreso',
        width: '90px !important',
        align: 'center',
      },
      {
        text: 'Descuentos',
        value: 'descuento',
        width: '90px !important',
        align: 'center',
      },
      {
        text: 'Aportes trabajador',
        value: 'aporteTrabajador',
        width: '110px !important',
        align: 'center',
      },
      {
        text: 'Aportes empleador',
        value: 'aporteEmpleador',
        width: '110px !important',
        align: 'center',
      },
    ],
  }),
  props: {
    persona: Object,
  },
  computed: {
    ...mapState(['conceptosSunat', 'planillaSeleccionada', 'cliente']),
    netoAPagar() {
      let neto = 0;
      if (this.persona && this.persona.doi) {
        this.persona.ingresos_por_concepto.map((ingreso) => {
          neto = neto + ingreso.monto;
          return ingreso;
        });
        this.persona.descuentos_por_concepto.map((descuento) => {
          neto = neto - descuento.monto;
          return descuento;
        });
      }
      return Number(neto).toFixed(2);
    },
    netoEnLetras() {
      let neto = 0;
      if (this.persona && this.persona.doi) {
        this.persona.ingresos_por_concepto.map((ingreso) => {
          neto = neto + ingreso.monto;
          return ingreso;
        });
        this.persona.descuentos_por_concepto.map((descuento) => {
          neto = neto - descuento.monto;
          return descuento;
        });
      }

      return numeroALetras(neto);
    },
    datosPersonales() {
      const datos = [
        {
          tipoDocumento: this.persona.tipoDocumento,
          nroDocumento: this.persona.doi,
          remuneracionMensual: this.persona.remuneracion_mensual,
          nombre: this.persona.nombre,
          situacion: 'ACTIVO',
        },
      ];

      return datos;
    },
    datosLaborales() {
      const datos = [
        {
          fechaIngreso: this.persona.fecha_ingreso,
          tipoTrabajador: this.persona.tipoTrabajador,
          regimen: this.cliente.datos_empresa.regimen_laboral,
          periodo: this.planillaSeleccionada.periodo,
          cuspp: this.persona.cuspp,
        },
      ];

      return datos;
    },
    datosJornadas() {
      const datos = [
        {
          diasLaborados: this.persona.cantidad_dias_efectivos,
          diasNoLaborados: this.persona.info_dias
            ? this.persona.info_dias.filter(
                (dia) =>
                  dia.tipo_jornada !== 'A' && // falta validar tipos de jornada permiso con goce de haber por ejemplo
                  dia.tipo_jornada !== 'Asistencia' &&
                  dia.tipo_jornada !== 'T' &&
                  dia.tipo_jornada !== 'Tardanza' &&
                  dia.tipo_jornada !== 'D' &&
                  dia.tipo_jornada !== 'Descanso' &&
                  dia.tipo_jornada !== 'DM' &&
                  dia.tipo_jornada !== 'Descanso médico'
              ).length
            : 0,
          diasSubsidiados: this.persona.info_dias
            ? this.persona.info_dias.filter(
                (dia) =>
                  dia.tipo_jornada === 'DM' ||
                  dia.tipo_jornada === 'Descanso médico'
              ).length
            : 0, // por ahora se está considerando sólo descansos médicos
          horasOrdinarias: 0, // por ahora es 0 hasta verificar el cálculo
          horasSobretiempo: 0, // por ahora es 0 hasta verificar el cálculo
          cargo: this.persona.cargo,
        },
      ];

      return datos;
    },
    detalleRemuneracion() {
      if (
        !this.persona ||
        !this.persona.ingresos_por_concepto ||
        !this.persona.descuentos_por_concepto ||
        !this.persona.aportes_empleador_por_concepto
      ) {
        return [];
      }
      const datos = [];

      //buscar ingresos del trabajador
      this.persona.ingresos_por_concepto.map((ingreso) => {
        //buscar la descripcion del concepto
        const conceptoEncontrado = this.conceptosSunat.find(
          (concepto) => concepto.codigo === ingreso.codigo
        );
        if (conceptoEncontrado) {
          let filaDetalle = {
            codigoPdt: ingreso.codigo,
            descripcionPdt: conceptoEncontrado.nombre,
            ingreso: Number(ingreso.monto).toFixed(2),
            // descuento: 0,
            // aporteTrabajador: 0,
            // aporteEmpleador: 0,
          };

          datos.push(filaDetalle);
        }
        return ingreso;
      });

      //buscar descuentos y aportes del trabajador
      this.persona.descuentos_por_concepto.map((descuento) => {
        //buscar la descripcion del concepto
        const conceptoEncontrado = this.conceptosSunat.find(
          (concepto) => concepto.codigo === descuento.codigo
        );
        if (conceptoEncontrado) {
          let filaDetalle = {
            codigoPdt: descuento.codigo,
            descripcionPdt: conceptoEncontrado.nombre,
          };
          //se obtienen todos los conceptos que son aportes del empleador (codigos 600), lo demás son descuentos
          if (
            Number(conceptoEncontrado.codigo) > 599 &&
            Number(conceptoEncontrado.codigo) < 701
          ) {
            filaDetalle.aporteTrabajador = Number(descuento.monto).toFixed(2);
          } else {
            filaDetalle.descuento = Number(descuento.monto).toFixed(2);
          }

          datos.push(filaDetalle);
        }
        return descuento;
      });

      //buscar aportes del empleador
      this.persona.aportes_empleador_por_concepto.map((aporte) => {
        const conceptoEncontrado = this.conceptosSunat.find(
          (concepto) => concepto.codigo === aporte.codigo
        );
        if (conceptoEncontrado) {
          let filaDetalle = {
            codigoPdt: aporte.codigo,
            descripcionPdt: conceptoEncontrado.nombre,
            // ingreso: 0,
            // descuento: 0,
            // aporteTrabajador: 0,
            aporteEmpleador: Number(aporte.monto).toFixed(2),
          };

          datos.push(filaDetalle);
        }
        return aporte;
      });

      return datos;
    },
  },
  mounted() {
    this.parametroAsignacionFamiliar = this.cliente.datos_empresa.parametros.find(
      (parametro) => parametro.concepto === 'ASIGNACION FAMILIAR'
    );
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/main.scss';
.v-data-table::v-deep th {
  border: 1px solid #0f501d3d;
  text-align: left;
  padding: 8px;
  overflow: auto;
  font-size: 14px !important;
  font-weight: bold !important;
  color: teal !important;
}
.v-data-table::v-deep td {
  text-align: left;
  font-size: 12px !important;
}
// .container-datos {
//   width: 770px !important;
// }
// .container-neto {
//   margin-top: 20px !important;
// }
</style>
